import { useReactiveVar } from "@apollo/client";
import { Grid, Image } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Txt } from "src/components/atoms/Txt";
import { EmailLoginForm } from "src/components/molecules/EmailLoginForm";
import { authErrorVar } from "src/providers/UserProvider";
import { ErrorWithCode } from "src/utils/ErrorWithCode";
import { isMetamaskBrowser } from "src/utils/isMetamaskBrowser";
import { MetamaskBrowserDisclaimer } from "../../../molecules/MetamaskBrowserDisclaimer";
import { SocialMediaLoginButton } from "./SocialMediaLoginButton";

const getAuthErrorMessage = (authError: Error) => {
  if (authError instanceof ErrorWithCode) {
    switch (authError.code) {
      case "user-provider/account-not-found":
        return "Account not found: Please install Phaver app to register.";
      case "user-provider/profile-not-found":
        return "Profile not found: Please finnish registration in Phaver app.";
      case "sign-in-with/android-facebook-login-deprecated":
        return "Facebook login supported only in desktop browser.";
    }
  }

  return "Something went wrong. Try to login to Phaver app first.";
};

export const LoginForm: FC = () => {
  const { t } = useTranslation();
  const authError = useReactiveVar(authErrorVar);

  return (
    <Grid p={8} gap={8} maxW="600px" w="100%" m="auto">
      <Grid justifyItems="center">
        <Image src="/logos/phaver-logo.svg" width={182} alt="Phaver" mb={10} />
      </Grid>
      <Txt textStyle="p2">{t("LoginForm.DisclaimerText")}</Txt>
      <Txt textStyle="h5" color="primaryPurple" textTransform="uppercase">
        {t("LogIn")}
      </Txt>
      <Grid gap={8} autoFlow="column" justifyContent="center">
        {!isMetamaskBrowser() && <SocialMediaLoginButton provider="google" />}
        {!isMetamaskBrowser() && <SocialMediaLoginButton provider="facebook" />}
        <SocialMediaLoginButton provider="apple" />
      </Grid>
      {isMetamaskBrowser() && <MetamaskBrowserDisclaimer />}
      {!!authError && (
        <Txt textStyle="p1" color="errorRed">
          {getAuthErrorMessage(authError)}
        </Txt>
      )}
      <Txt textStyle="h4">{t("LoginForm.OrWith")}</Txt>
      <EmailLoginForm />
    </Grid>
  );
};

export default LoginForm;
