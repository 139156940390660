import { extendTheme, ThemeConfig } from "@chakra-ui/react";

import { colors } from "./colors";
import { components } from "./components";
import { fonts } from "./fonts";
import { textStyles } from "src/lib/theme/textStyles";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const gradients = {
  phaverGradient: "linear(to-br, purple.800, black)",
};

export const theme = extendTheme({
  config,
  textStyles,
  components,
  fonts,
  colors,
});
