import { Stack, useBreakpointValue, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Button } from "src/components/atoms/Button";
import { Heading } from "src/components/atoms/Heading";
import { Image } from "src/components/atoms/Image";
import { Txt } from "src/components/atoms/Txt";
import { useSignInLensContext } from "src/contexts/SignInLensContext";
import { AccountAddressBox } from "../../molecules/AccountAddressBox";

export const NoProfiles: FC = () => {
  const { reset } = useSignInLensContext();
  const { t } = useTranslation();
  const width = useBreakpointValue({ base: 100, sm: 200 });

  return (
    <>
      <Stack spacing={8} maxW="600px" mx="auto" alignItems="center">
        <AccountAddressBox />
        <Image
          color="white"
          src="/images/sadface.svg"
          alt={t("Phaver")}
          width={width}
          height={width}
        />
        <Heading color="lensLightgreen">{t("UhOh")}</Heading>
        <Txt px={8} textStyle="p1" color="white">
          {t("SelectProfile.NoProfiles.Description1")}
          <br />
          <br />
          {t("SelectProfile.NoProfiles.Description2")}
        </Txt>
      </Stack>
      <VStack
        position={{ base: "sticky", md: "absolute" }}
        bottom={0}
        left={0}
        right={0}
        bg="componentBg"
        py={3}
      >
        <Button onClick={reset}>{t("DisconnectAndTakeMeBack")}</Button>
      </VStack>
    </>
  );
};
