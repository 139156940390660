/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defineStyleConfig } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "base",
  },
  variants: {
    rounded: {
      px: 2,
      py: 2,
      rounded: "full",
      color: "white",
      bg: "#A297FF",
    },
    unstyled: {
      p: "0",
      bg: "transparent",
      color: "transparent",
    },
  },
  defaultProps: {
    variant: "rounded",
  },
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const Icon = defineStyleConfig({
  baseStyle: {
    color: "red.500",
  },
  defaultProps: {},
});

export const components = {
  Button,
  Icon,
};
