import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from "@chakra-ui/react";
import * as React from "react";
import { textStyles } from "src/lib/theme/textStyles";

type TxtProps = Omit<ChakraTextProps, "fontSize" | "textStyle"> & {
  textStyle?: keyof typeof textStyles;
};

export const Txt: React.FC<TxtProps> = ({ textStyle = "p1", ...props }) => {
  return (
    <ChakraText
      textStyle={textStyle}
      textAlign="center"
      color="white"
      {...props}
    />
  );
};
