import { Flex, Image, Box, useBreakpointValue } from "@chakra-ui/react";
import { Button } from "src/components/atoms/Button";
import { Txt } from "src/components/atoms/Txt";
import { getAuth } from "firebase/auth";
import { FC } from "react";
import { useCurrentUserContext } from "src/providers/UserProvider";
import { Link } from "../atoms/Link";

export const Header: FC = () => {
  const user = useCurrentUserContext();
  const width = useBreakpointValue({ base: 90, sm: 150 });
  const px = useBreakpointValue({ base: 4, sm: 8 });

  return (
    <Flex alignItems="center" p={4} pb={8}>
      <Box flex={1}>
        <Link href="/">
          <Image
            color="white"
            src="/logos/phaver-logo.svg"
            width={width}
            alt="Phaver"
          />
        </Link>
      </Box>
      <Txt textAlign="right" textStyle="p2" mr={4}>
        {user.profile.name}
        <br />@{user.profile.username}
      </Txt>
      <Button
        px={px}
        onClick={() => {
          void getAuth().signOut();
        }}
      >
        Log out
      </Button>
    </Flex>
  );
};
