/* eslint-disable @typescript-eslint/no-explicit-any */
import { providers, Signer, TypedDataField, utils } from "ethers";

export interface EIP712StandardDomain {
  name: string;
  version: string;
  chainId?: number;
  verifyingContract: string;
}

export interface EIP712PolygonDomain {
  name: string;
  version: string;
  verifyingContract: string;
  salt: string;
}

export type EIP712Domain = EIP712StandardDomain | EIP712PolygonDomain;

/**
 * Handles wallet connect 2.0 missing eth_signTypedData_v4 method
 * extracted from @thirdweb-dev [packages/sdk/src/evm/common/sign.ts](https://github.com/thirdweb-dev/js/blob/8a389f1295d2bf726059997ea0ca10cf0424f2a2/packages/sdk/src/evm/common/sign.ts#L33C27-L33C27)
 */
export async function signTypedDataInternal(
  signer: Signer,
  domain: EIP712Domain,
  types: Record<string, Array<TypedDataField>>,
  message: Record<string, any>
) {
  const provider = signer?.provider as providers.JsonRpcProvider;
  if (!provider) {
    throw new Error("missing provider");
  }

  const payload: unknown = utils._TypedDataEncoder.getPayload(
    domain,
    types,
    message
  );

  let signature = "";
  const signerAddress = (await signer.getAddress()).toLowerCase();

  // an indirect way for accessing walletconnect's underlying provider
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if ((provider as any)?.provider?.isWalletConnect) {
    signature = (await provider.send("eth_signTypedData", [
      (await signer.getAddress()).toLowerCase(),
      JSON.stringify(payload),
    ])) as string;
  } else {
    try {
      signature = await (signer as providers.JsonRpcSigner)._signTypedData(
        domain,
        types,
        message
      );
    } catch (err) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        (err as any)?.message?.includes(
          "Method eth_signTypedData_v4 not supported"
        )
      ) {
        signature = (await provider.send("eth_signTypedData", [
          signerAddress,
          JSON.stringify(payload),
        ])) as string;
      } else {
        // magic.link signer only supports this way
        // eslint-disable-next-line no-useless-catch
        try {
          await provider.send("eth_signTypedData_v4", [
            signerAddress,
            JSON.stringify(payload),
          ]);
        } catch (finalErr) {
          throw finalErr;
        }
      }
    }
  }

  // fix ledger live where signature result in v = 0, 1. ethers magically fix it in split/join.
  return {
    payload,
    signature: utils.joinSignature(utils.splitSignature(signature)),
  };
}
