import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { Fonts } from "src/components/atoms/Fonts";
import { Layout } from "src/components/atoms/Layout";
import { Header } from "src/components/molecules/Header";
import { SignInLensProvider } from "src/contexts/SignInLensContext";
import { client } from "src/lib/apollo";
import { theme } from "src/lib/theme";
import { CurrentUserProvider } from "src/providers/UserProvider";
import { ThirdwebProvider } from "thirdweb/react";
import SEO from "../../next-seo.config";

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <DefaultSeo {...SEO} />
      <GoogleAnalytics trackPageViews />
      <ThirdwebProvider>
        <ApolloProvider client={client}>
          <ChakraProvider theme={theme}>
            <Fonts />
            <Layout>
              <CurrentUserProvider>
                <SignInLensProvider>
                  <Header />
                  <Component {...pageProps} />
                </SignInLensProvider>
              </CurrentUserProvider>
            </Layout>
          </ChakraProvider>
        </ApolloProvider>
      </ThirdwebProvider>
    </>
  );
};

export default appWithTranslation(App);
