import { VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Button } from "src/components/atoms/Button";
import { Image } from "src/components/atoms/Image";
import { Loading } from "src/components/atoms/Loading";
import { Txt } from "src/components/atoms/Txt";
import { SelectedProfile } from "src/components/templates/SelectProfile/SelectedProfile";
import { useSignInLensContext } from "src/contexts/SignInLensContext";
import {
  useLensApiAuthenticateMutation,
  useLensApiChallengeQuery
} from "src/gql/generated";
import { useLogEvent } from "src/lib/tracking";
import { unawaited } from "src/lib/unawaited";
import { Step } from "src/pages/sign-in-lens";
import { isExpectedChainId } from "src/utils/isExpectedChainId";
import { useActiveAccount, useActiveWalletChain } from "thirdweb/react";

export const SignInLensApiStep: FC = () => {
  useLogEvent({ action: "sign_in_lens_api" });
  const { t } = useTranslation();
  const { setStep, lensProfile } = useSignInLensContext();
  const signer = useActiveAccount()
  const chainId = useActiveWalletChain()?.id;
  const address = signer?.address
  const { data, loading } = useLensApiChallengeQuery({
    variables: {
      address: address ?? "",
      lensProfileId: lensProfile?.lensProfileId || "",
    },
    fetchPolicy: "no-cache",
  });
  const [mutate, { loading: mutationLoading }] = useLensApiAuthenticateMutation(
    {
      onCompleted: () => setStep(Step.SET_LENS_DISPATCHER),
    }
  );

  const signInLens = async () => {
    if (!data?.lensApiChallenge || !address) return;
    const msgParams = data.lensApiChallenge.challenge;
    const sig = await signer.signMessage({ message: msgParams });
    await mutate({ variables: { address, sig } });
  };

  if (loading) return <Loading />;

  if (!lensProfile) return null;

  return (
    <>
      <VStack spacing={8} px={4} maxW="600px" mx="auto">
        <Image
          width={172}
          height={172}
          src="/images/lens-logo.png"
          alt="Lens Logo"
        />
        <Txt textStyle="h2">{t("SignInLens.SignInLensApiTitle")}</Txt>
        <Txt textStyle="p2">{t("SignInLens.SignInLensApiDescription")}</Txt>
        <SelectedProfile {...lensProfile} />
      </VStack>
      <VStack
        position={{ base: "sticky", md: "absolute" }}
        bottom={0}
        left={0}
        right={0}
        bg="componentBg"
        py={3}
      >
        <Button
          isLoading={mutationLoading}
          isDisabled={!isExpectedChainId(chainId)}
          onClick={() => unawaited(signInLens())}
        >
          {t("SignInLens.SignInLensApiButtonText")}
        </Button>
      </VStack>
    </>
  );
};
