import { Flex } from "@chakra-ui/react";
import { FC } from "react";
import { thirdwebClient } from "src/lib/thirdweb";
import { useLogEvent } from "src/lib/tracking";
import { ConnectButton, useActiveWallet } from "thirdweb/react";
import { createWallet, walletConnect } from "thirdweb/wallets";
import SEO from "../../../../next-seo.config";


const wallets = [
  createWallet("me.rainbow"),
  createWallet("io.metamask"),
  walletConnect(),
  createWallet("com.trustwallet.app"),
  createWallet("io.zerion.wallet"),
];

type ConnectButtonsProps = {
  hideShadow?: boolean;
};

export const ConnectButtons: FC<ConnectButtonsProps> = () => {
  // TODO: is this needed as only resolves to metamask and walletconnect?
  const walletInstance = useActiveWallet();
  const wallet = walletInstance?.id;
  useLogEvent(
    {
      action: "connect_wallets",
      payload: { wallet },
    },
    [wallet]
  );

  return (
    <Flex gap={6} alignItems="center" justifyContent="center" h="100%">
      <ConnectButton
        appMetadata={{
          name: "Phaver",
          description: SEO.title,
          logoUrl: "https://connect.phaver.com/logos/phaver-logo.svg",
          url: "https://phaver.com",
        }}
        client={thirdwebClient}
        wallets={wallets}
        theme="dark"
        connectModal={{ size: "compact" }}
      />
    </Flex>
  );
};
