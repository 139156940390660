import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Grid } from "@chakra-ui/react";
import {
  getAuth,
  signInWithEmailAndPassword
} from "@firebase/auth";
import * as Sentry from "@sentry/nextjs";
import { FirebaseError } from "firebase/app";
import { inMemoryPersistence } from "firebase/auth";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Button } from "src/components/atoms/Button";
import { FormLabel } from "src/components/atoms/FormLabel";
import { Input } from "src/components/atoms/Input";
import { authErrorVar } from "src/providers/UserProvider";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup.string().trim().email().required().default(""),
  password: yup.string().trim().required().default(""),
});

type FormikLoginValues = yup.Asserts<typeof validationSchema>;

const initialValues: FormikLoginValues = {
  email: "",
  password: "",
};

const onSubmit = async (
  { email, password }: FormikLoginValues,
  { setFieldError }: FormikHelpers<FormikLoginValues>
) => {
  try {
    authErrorVar(null);
    const auth = getAuth();
    await auth.setPersistence(inMemoryPersistence);
    await signInWithEmailAndPassword(auth, email.trim(), password);
  } catch (error) {
    if (error instanceof FirebaseError) {
      switch (error.code) {
        case "auth/wrong-password":
          setFieldError("password", "Wrong password");
          return;
        case "auth/user-not-found":
          setFieldError(
            "email",
            "Account not found: You may have registered with a different login method or email."
          );
          return;
        case "auth/account-exists-with-different-credential":
          setFieldError(
            "email",
            "An account already exists: Try signing in with a different login method."
          );
          return;
      }
      Sentry.captureException(error);
      setFieldError("email", "Something went wrong.");
    }
  }
};

export const EmailLoginForm: React.FC = () => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(form) => (
        <Form>
          <Grid gap="4">
            <Field name="email">
              {({ field }: FieldProps<FormikLoginValues["email"]>) => (
                <FormControl
                  isInvalid={!!form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email:</FormLabel>
                  <Input {...field} id="email" placeholder="email" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field }: FieldProps<FormikLoginValues["password"]>) => (
                <FormControl
                  isInvalid={!!form.errors.password && form.touched.password}
                >
                  <FormLabel htmlFor="password">Password:</FormLabel>
                  <Input
                    {...field}
                    id="password"
                    placeholder="password"
                    type="password"
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              justifySelf="center"
              isLoading={form.isSubmitting}
              type="submit"
            >
              Log in
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
