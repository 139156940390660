import { Avatar, Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Button } from "src/components/atoms/Button";
import { Heading } from "src/components/atoms/Heading";
import { proxiedImageUrl } from "src/components/atoms/Image";
import { Txt } from "src/components/atoms/Txt";
import { AccountAddressBox } from "src/components/molecules/AccountAddressBox";
import { NEXT_PUBLIC_CHAIN_ID } from "src/config";
import { useSignInLensContext } from "src/contexts/SignInLensContext";
import { useSetDispatcher } from "src/hook/useSetDispatcher";
import { useLogEvent } from "src/lib/tracking";
import { unawaited } from "src/lib/unawaited";
import { Step } from "src/pages/sign-in-lens";
import { SetDispatcherSuccess } from "../SetPhaverDispatcher/SetDispatcherSuccess";

export const SetLensDispatcherStep: FC = () => {
  useLogEvent({ action: "set_lens_dispatcher_step" });
  const { lensProfile, setStep } = useSignInLensContext();
  const { t } = useTranslation();

  const { loading, loadingStep, data, error, setDispatcher } =
    useSetDispatcher();

  if (data) {
    return <SetDispatcherSuccess />;
  }

  if (!lensProfile) return null;

  const handle = `@${lensProfile.lensHandle}`;
  const image = lensProfile?.image;
  const src = proxiedImageUrl({ image, width: 200, height: 200 });
  const opacity = loading || error ? 0.5 : undefined;

  return (
    <Stack p={4} gap={8} alignItems="center" maxW="600px" mx="auto">
      <AccountAddressBox expectectedChainId={NEXT_PUBLIC_CHAIN_ID} />
      <Heading opacity={opacity}>
        {t("SignInLens.SetLensDispatcherTitle")}
      </Heading>
      <Txt opacity={opacity} textStyle="p2">
        {t("SignInLens.SetLensDispatcherDescription")}
      </Txt>
      <Flex
        opacity={opacity}
        width="100%"
        rounded="xl"
        py={2}
        px={4}
        bg="primaryPurpleGlassEffect"
        alignItems="center"
      >
        <Avatar
          borderWidth="2px"
          borderStyle="solid"
          borderColor="lensLightgreen"
          name={handle}
          src={src}
        />
        <Txt ml={4} textAlign="left" textStyle="p1" flex={1}>
          {handle}
        </Txt>
      </Flex>
      <Box>
        {loadingStep === "execute" && (
          <Txt textStyle="p1">{t("SetPhaverDispatcher.LoadingText")}</Txt>
        )}
        {!!error && (
          <Txt textStyle="p1" color="errorRed">
            {t("SetPhaverDispatcher.ErrorText")}
          </Txt>
        )}
        <VStack
          position={{ base: "sticky", md: "absolute" }}
          bottom={0}
          left={0}
          right={0}
          bg="componentBg"
          py={3}
        >
          <Flex gap={4} rounded="2xl" justifyContent="center">
            <Button
              disabled={loading}
              bg="none"
              color="lightGray"
              onClick={() => {
                setStep(Step.SELECT_LENS_PROFILE);
              }}
            >
              {t("GoBack")}
            </Button>
            <Button
              isLoading={loading}
              onClick={() => {
                unawaited(
                  setDispatcher({
                    lensProfileId: lensProfile.lensProfileId,
                  })
                );
              }}
            >
              {t("SetDispatcher")}
            </Button>
          </Flex>
        </VStack>
      </Box>
    </Stack>
  );
};
