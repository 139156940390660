import { Box, Stack, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Button } from "src/components/atoms/Button";
import { Loading } from "src/components/atoms/Loading";
import { Txt } from "src/components/atoms/Txt";
import { NEXT_PUBLIC_CHAIN_ID } from "src/config";
import { useSignInLensContext } from "src/contexts/SignInLensContext";
import { useLensProfilesOwnedByAccountQueryQuery } from "src/gql/generated";
import { useLogEvent } from "src/lib/tracking";
import { Step } from "src/pages/sign-in-lens";
import { useCurrentUserContext } from "src/providers/UserProvider";
import { isExpectedChainId } from "src/utils/isExpectedChainId";
import { isNonNullable } from "src/utils/isNonNullable";
import { useActiveAccount, useActiveWalletChain } from "thirdweb/react";
import { AccountAddressBox } from "../../molecules/AccountAddressBox";
import { NoProfiles } from "../SelectProfile/NoProfiles";
import { SelectProfileButton } from "../SelectProfile/SelectProfileButton";

export const SelectLensProfileStep: FC = () => {
  const chainId = useActiveWalletChain()?.id;
  useLogEvent(
    {
      action: "select_lens_profile_step",
      payload: { chainId },
    },
    [chainId]
  );
  const user = useCurrentUserContext();
  const { setStep, lensProfile } = useSignInLensContext();
  const address = useActiveAccount()?.address ?? "";

  const { data: profileData, loading } =
    useLensProfilesOwnedByAccountQueryQuery({
      variables: { account: address },
      skip: !address,
    });

  const lensProfiles =
    profileData?.lensProfilesOwnedByAccount
      .map(({ lensProfile }) => lensProfile)
      .filter(isNonNullable) || [];

  const { t } = useTranslation();

  const currentLensProfile = user.profile.lensProfile || undefined;

  if (loading) return <Loading />;

  if (lensProfiles.length === 0) {
    return <NoProfiles />;
  }

  return (
    <>
      <Stack spacing={8} px={4} maxW="600px" mx="auto">
        <AccountAddressBox expectectedChainId={NEXT_PUBLIC_CHAIN_ID} />
        <Txt textStyle="h2" color="white">
          {t("SignInLens.SelectLensProfileTitle")}
        </Txt>
        {currentLensProfile && (
          <Txt textStyle="p1" color="errorRed">
            {t("SelectProfile.Description")}
          </Txt>
        )}
        <Box px={4}>
          {lensProfiles.map((lensProfile) => (
            <SelectProfileButton key={lensProfile.id} {...lensProfile} />
          ))}
        </Box>
      </Stack>
      <VStack
        position={{ base: "sticky", md: "absolute" }}
        bottom={0}
        left={0}
        right={0}
        bg="componentBg"
        py={3}
      >
        <Button
          isDisabled={!lensProfile || !isExpectedChainId(chainId)}
          onClick={() => {
            setStep(Step.SIGN_IN_LENS_API);
          }}
        >
          {t("Continue")}
        </Button>
      </VStack>
    </>
  );
};
