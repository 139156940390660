export const colors = {
  white: "#FAFAFA",
  black: "#000000",
  primaryBg: "#000000",
  componentBg: "#171717",
  headerBg: "#171717",
  headerGlassEffect: "#2D2D2D8A",
  glassBorder: "#ffffff0d",
  highlights: "#28262E",
  lightGray: "#949494",
  darkGray: "#404040",
  lightPurple: "#DFDDF4",
  phaverPurple: "#5F53C7",
  secondaryPurple: "#C3BCF9",
  primaryPurple: "#A297FF",
  primaryPurpleGlassEffect: "#A297FF80",
  lightGreen: "#B6F0B4",
  primaryGreen: "#15A105",
  errorRed: "#F3264B",
  transparent: "transparent",
  backdrop: "#000000",
  lensLightgreen: "#ABFE2C",
  lensLightgreenBackground: "#abfe2c14",
  lensDarkgreen: "#00501E",
  walletConnectBlue: "#3B99FB",
};

export type Color = keyof typeof colors;
