import { makeVar, useApolloClient } from "@apollo/client";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import * as Sentry from "@sentry/nextjs";
import { User } from "firebase/auth";
import { useRouter } from "next/router";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LoginForm from "src/components/organisms/Forms/LoginForm";
import {
  AccountFragment,
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  LensCheckRelayOutput,
} from "src/gql/generated";
import { unawaited } from "src/lib/unawaited";
import { ErrorWithCode } from "src/utils/ErrorWithCode";
import { toError } from "src/utils/toError";
import { useActiveWallet, useDisconnect } from "thirdweb/react";

/**
 * This is used for tracking auth state errors that aren't accesible directly from login promises
 */
export const authErrorVar = makeVar<Error | null>(null);

export type CurrentUser = Omit<AccountFragment, "profile"> & {
  profile: NonNullable<AccountFragment["profile"]>;
  lensCheckRelay: LensCheckRelayOutput;
};

const CurrentUserContext = createContext<CurrentUser>(
  null as unknown as CurrentUser
);

export const CurrentUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const client = useApolloClient();
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);
  const [user, setUser] = useState<CurrentUser | null>(null);
  const wallet = useActiveWallet();

  // disconnect is not stable
  const { disconnect } = useDisconnect();
  const disconnectRef = useRef(disconnect);
  disconnectRef.current = disconnect;

  // router is not stable
  const router = useRouter();
  const routerRef = useRef(router);
  routerRef.current = router;

  useEffect(() => {
    const resetUser = async (firebaseUser: User | null) => {
      try {
        authErrorVar(null);
        if (firebaseUser) {
          const response = await client.query<
            CurrentUserQuery,
            CurrentUserQueryVariables
          >({
            query: CurrentUserDocument,
            variables: { id: firebaseUser.uid },
          });

          if (!response.data.account) {
            throw new ErrorWithCode("user-provider/account-not-found");
          }
          const { profile, ...account } = response.data.account;
          if (!profile) {
            throw new ErrorWithCode("user-provider/profile-not-found");
          }

          const { lensCheckRelay } = response.data;
          setUser({ ...account, profile, lensCheckRelay });
        } else {
          if (wallet) {
            disconnectRef.current(wallet);
            setUser(null);
            unawaited(routerRef.current.push("/"));
          }
        }
        setFirebaseLoaded(true);
      } catch (error) {
        Sentry.captureException(error);
        await getAuth().signOut();
        authErrorVar(toError(error));
      }
    };

    const unsub = onAuthStateChanged(getAuth(), (firebaseUser) => {
      void resetUser(firebaseUser);
    });

    return unsub;
  }, [client, routerRef, wallet]);

  if (!firebaseLoaded) return null;
  if (!user) return <LoginForm />;

  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUserContext = (): CurrentUser => {
  const user = useContext(CurrentUserContext);
  if (user === undefined) {
    throw new Error(
      "useCurrentUser must be used within a CurrentUserProvider!"
    );
  }
  return user;
};
