import { Center, Spinner } from "@chakra-ui/react";
import { FC } from "react";

export const Loading: FC = () => {
  return (
    <Center flex={1}>
      <Spinner />
    </Center>
  );
};
