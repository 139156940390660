// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import versionJson from "./public/version.json";
import {
  NEXT_PUBLIC_PHAVER_ENV,
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENABLED,
  NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
} from "src/config";

Sentry.init({
  dsn: NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
  environment: NEXT_PUBLIC_PHAVER_ENV,
  enabled: NEXT_PUBLIC_SENTRY_ENABLED,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

Sentry.setTag("gitCommit", versionJson.commit);
Sentry.setTag("gitCommitDate", versionJson.commitDate);
