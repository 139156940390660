import { Avatar } from "@chakra-ui/react";
import { FC } from "react";
import { Button } from "src/components/atoms/Button";
import { proxiedImageUrl } from "src/components/atoms/Image";
import { Txt } from "src/components/atoms/Txt";
import { useSignInLensContext } from "src/contexts/SignInLensContext";
import { LensProfileFragment } from "src/gql/generated";
import { useCurrentUserContext } from "src/providers/UserProvider";

export type SelectProfileButtonProps = LensProfileFragment;
export const SelectProfileButton: FC<SelectProfileButtonProps> = (props) => {
  const { lensProfileId, image, lensHandle } = props;
  const { setLensProfile, lensProfile } = useSignInLensContext();
  const user = useCurrentUserContext();

  const currentLensProfile = user.profile.lensProfile || undefined;
  const isDisabled =
    currentLensProfile && currentLensProfile.lensProfileId !== lensProfileId;
  const isSelected = lensProfile?.lensProfileId === lensProfileId;

  const src = proxiedImageUrl({
    image,
    width: 200,
    height: 200,
  });

  return (
    <Button
      rounded="xl"
      px={4}
      py={8}
      bg={isSelected ? "primaryPurpleGlassEffect" : "none"}
      textTransform="initial"
      w="100%"
      onClick={() => {
        setLensProfile(props);
      }}
      leftIcon={
        <Avatar
          borderStyle="solid"
          borderWidth="2px"
          borderColor="lensLightgreen"
          name={lensHandle}
          src={src}
        />
      }
      isDisabled={isDisabled}
    >
      <Txt ml={4} textAlign="left" textStyle="p1" flex={1}>
        {`@${lensHandle}`}
      </Txt>
    </Button>
  );
};
