import {
  // eslint-disable-next-line no-restricted-imports
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from "@chakra-ui/react";
import * as React from "react";
import { textStyles } from "src/lib/theme/textStyles";

type HeadingProps = Omit<ChakraHeadingProps, "fontSize"> & {
  fontSize?: keyof typeof textStyles;
};

export const Heading: React.FC<HeadingProps> = ({
  fontSize = "h3",
  ...props
}) => {
  return (
    <ChakraHeading
      fontSize={textStyles[fontSize].fontSize}
      color="white"
      {...props}
    />
  );
};
