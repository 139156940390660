import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "@firebase/auth";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED,
  NEXT_PUBLIC_FIREBASE_APP_CHECK_DEBUG_TOKEN,
  NEXT_PUBLIC_FIREBASE_APP_CHECK_TOKEN_HEADER_ENABLED,
} from "../config";
export { getAuth } from "firebase/auth";
import {
  AppCheck,
  getToken,
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import * as Sentry from "@sentry/nextjs";

const apiKey = NEXT_PUBLIC_FIREBASE_API_KEY;
const authDomain = NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
const projectId = NEXT_PUBLIC_FIREBASE_PROJECT_ID;
const databaseURL = `https://${projectId}.firebaseio.com`;
const storageBucket = `${projectId}.appspot.com`;
const messagingSenderId = NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
const appId = NEXT_PUBLIC_FIREBASE_APP_ID;
const measurementId = NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;

// new firebase sdk is more three shaking focused so import any necessary stuff directly where used
// hot-reload on expo causes FirebaseError: Firebase: Firebase App named '[DEFAULT]' already exists (app/duplicate-app).
const app = getApps().length
  ? getApp()
  : initializeApp({
      apiKey,
      authDomain,
      databaseURL,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId,
    });

let appCheck: AppCheck | null = null;
if (NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED && typeof window !== "undefined") {
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(NEXT_PUBLIC_RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
  });

  if (NEXT_PUBLIC_FIREBASE_APP_CHECK_DEBUG_TOKEN) {
    Object.assign(window, {
      FIREBASE_APPCHECK_DEBUG_TOKEN: NEXT_PUBLIC_FIREBASE_APP_CHECK_DEBUG_TOKEN,
    });
  }
}

export type SupportedProvider = "google" | "facebook" | "apple";

export const getSupportedProvider = (
  provider: SupportedProvider
): GoogleAuthProvider | FacebookAuthProvider | OAuthProvider => {
  switch (provider) {
    case "google":
      return new GoogleAuthProvider();
    case "facebook":
      return new FacebookAuthProvider();
    case "apple":
      return new OAuthProvider("apple.com");
  }
};

export const getIdToken = (forceRefresh?: boolean) => {
  return (
    getAuth().currentUser?.getIdToken(forceRefresh) ??
    Promise.resolve(undefined)
  );
};

export const getAppCheckToken = async () => {
  if (
    !NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED ||
    !NEXT_PUBLIC_FIREBASE_APP_CHECK_TOKEN_HEADER_ENABLED
  ) {
    return;
  }

  try {
    if (!appCheck) {
      throw new Error("AppCheck not initialized"); // should never happen
    }
    const { token } = await getToken(appCheck);
    return token;
  } catch (error) {
    // this will probably be noisy in production as chinese will fail this but no other way to see what errors the prod version will throw
    // after running this a while let's ignore in sentry and log only in console
    Sentry.captureException(error);
  }
};
