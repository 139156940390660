import { GetStaticProps, NextPage } from "next";
import { useSignInLensContext } from "../contexts/SignInLensContext";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ConnectWalletStep } from "../components/templates/SignInLens/ConnectWalletStep";
import { InitialStep } from "../components/templates/SignInLens/InitialStep";
import { SelectLensProfileStep } from "../components/templates/SignInLens/SelectLensProfileStep";
import { SetLensDispatcherStep } from "../components/templates/SignInLens/SetLensDispatcherStep";
import { SignInLensApiStep } from "../components/templates/SignInLens/SignInLensApiStep";

export enum Step {
  INITIAL,
  CONNECT_WALLET,
  SELECT_LENS_PROFILE,
  SIGN_IN_LENS_API,
  SET_LENS_DISPATCHER,
}

const SignInLensPage: NextPage = () => {
  const { step } = useSignInLensContext();
  switch (step) {
    case Step.INITIAL:
      return <InitialStep />;
    case Step.CONNECT_WALLET:
      return <ConnectWalletStep />;
    case Step.SELECT_LENS_PROFILE:
      return <SelectLensProfileStep />;
    case Step.SIGN_IN_LENS_API:
      return <SignInLensApiStep />;
    case Step.SET_LENS_DISPATCHER:
      return <SetLensDispatcherStep />;
  }
};

export const getStaticProps: GetStaticProps = async ({ locale = "en" }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["connect"])),
    },
  };
};

export default SignInLensPage;
