export const gradients = {
  blue: {
    overflow: "hidden",
    border: "2px solid transparent",
    backgroundImage:
      "linear-gradient(#000000DD, #000000DD), radial-gradient(circle at left top, rgba(111, 142, 255, 0.6),rgba(23, 23, 23, 0.5),rgba(23, 23, 23, 0.5), rgba(111, 142, 255, 0.6))",
    backgroundClip: "padding-box, border-box",
    style: {
      backgroundOrigin: "border-box",
      backdropFilter: "blur(8px)",
      WebkitBackdropFilter: "blur(8px)",
    },
  },
  lens: {
    overflow: "hidden",
    border: "2px solid transparent",
    backgroundImage:
      "linear-gradient(#000000DD, #000000DD), radial-gradient(circle at left top, #ABFE2C,rgba(23, 23, 23, 0.5),rgba(23, 23, 23, 0.5), #ABFE2C)",
    backgroundClip: "padding-box, border-box",
    style: {
      backgroundOrigin: "border-box",
      backdropFilter: "blur(8px)",
      WebkitBackdropFilter: "blur(8px)",
    },
  },
  cyberconnect: {
    overflow: "hidden",
    border: "2px solid transparent",
    backgroundImage:
      "linear-gradient(#000000DD, #000000DD), radial-gradient(circle at left top, #d4d1d1,rgba(23, 23, 23, 0.5),rgba(23, 23, 23, 0.5), #d4d1d1)",
    backgroundClip: "padding-box, border-box",
    style: {
      backgroundOrigin: "border-box",
      backdropFilter: "blur(8px)",
      WebkitBackdropFilter: "blur(8px)",
    },
  },
};
