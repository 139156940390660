import { ImageProps as ChakraImageProps } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";
import {
  NEXT_PUBLIC_PHAVER_IMGPROXY_BASE_URL,
  NEXT_PUBLIC_PHAVER_IMGPROXY_DEV_BASE_URL,
  NEXT_PUBLIC_PHAVER_IMG_DEV_BUCKET,
} from "src/config";
import Imgproxy, { WatermarkPosition } from "imgproxy";
import { GravityType } from "imgproxy/dist/types";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { FC } from "react";
import { ImageFragment } from "src/gql/generated";

type getImgProxyClientParams = {
  bucket: string;
};

export const getImgProxyClient = ({
  bucket,
}: getImgProxyClientParams): Imgproxy => {
  const isDev = bucket === NEXT_PUBLIC_PHAVER_IMG_DEV_BUCKET;

  const baseUrl = isDev
    ? NEXT_PUBLIC_PHAVER_IMGPROXY_DEV_BASE_URL
    : NEXT_PUBLIC_PHAVER_IMGPROXY_BASE_URL;

  return new Imgproxy({
    baseUrl,
    encode: true,
  });
};

export type ProxiedImageUrlParams = {
  image: ImageFragment | undefined | null;
  width?: number;
  height?: number;
  hasWatermark?: boolean;
  fallbackUrl?: string;
};

export const proxiedImageUrl = ({
  image,
  width = 800,
  height = 800,
  hasWatermark = false,
  fallbackUrl = "/images/profilepic.svg",
}: ProxiedImageUrlParams): string => {
  if (!image) return fallbackUrl;

  const { bucket } = image;
  const url = getImgProxyClient({ bucket })
    .builder()
    .resize("fill", width, height)
    .gravity(GravityType.smart)
    .blur(hasWatermark ? 10 : 0)
    .watermark(
      hasWatermark ? 1.0 : 0.0,
      WatermarkPosition.south_west,
      { x: 50, y: 50 },
      0.4
    )
    .generateUrl(image.filename, "jpg");

  return url;
};

const ChakraNextImage = chakra(NextImage, {
  baseStyle: { maxH: 120, maxW: 120 },
  shouldForwardProp: (prop) =>
    ["width", "height", "src", "alt", "layout"].includes(prop),
});

type ImageProps = ChakraImageProps &
  Omit<NextImageProps, "src"> & {
    src?: string;
    image?: ImageFragment | null;
  };

export const Image: FC<ImageProps> = ({
  src,
  image,
  width,
  height,
  ...props
}) => {
  return (
    <ChakraNextImage
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      src={src ? src : proxiedImageUrl({ image })}
      width={width}
      height={height}
      {...props}
    />
  );
};
