import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
} from "@chakra-ui/react";
import * as React from "react";
import { textStyles } from "src/lib/theme/textStyles";

type FormLabelProps = Omit<ChakraFormLabelProps, "fontSize" | "textStyles"> & {
  fontSize?: keyof typeof textStyles;
};

export const FormLabel: React.FC<FormLabelProps> = ({
  fontSize = "h4",
  ...props
}) => {
  return (
    <ChakraFormLabel
      fontSize={textStyles[fontSize].fontSize}
      color="primaryPurple"
      border="none"
      {...props}
    />
  );
};
