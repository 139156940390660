import { Avatar, HStack } from "@chakra-ui/react";
import { FC } from "react";
import { proxiedImageUrl } from "src/components/atoms/Image";
import { Txt } from "src/components/atoms/Txt";
import { LensProfileFragment } from "src/gql/generated";

export type SelectedProfileProps = LensProfileFragment;

export const SelectedProfile: FC<SelectedProfileProps> = ({
  image,
  lensHandle,
}) => {
  const src = proxiedImageUrl({
    image,
    width: 200,
    height: 200,
  });

  return (
    <HStack rounded="xl" px={4} py={4} bg={"none"} textTransform="initial">
      <Avatar
        borderStyle="solid"
        borderWidth="2px"
        borderColor="lensLightgreen"
        name={lensHandle}
        src={src}
      />
      <Txt pl={2} textAlign="left" textStyle="p1" flex={1}>
        {`@${lensHandle}`}
      </Txt>
    </HStack>
  );
};
