import { Grid, GridProps } from "@chakra-ui/react";
import { FC } from "react";
import { gradients } from "src/lib/theme/gradients";

export type CardProps = GridProps;

export const Card: FC<CardProps> = (props) => (
  <Grid
    h="100%"
    w="100%"
    bg="headerGlassEffect"
    borderColor="glassBorder"
    borderWidth="1px"
    rounded="3xl"
    alignContent="space-between"
    backdropBlur={20}
    {...gradients.blue}
    {...props}
  />
);
