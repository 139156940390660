import { Box, Grid, HStack, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Button } from "src/components/atoms/Button";
import { Card } from "src/components/atoms/Card";
import { Image } from "src/components/atoms/Image";
import { Txt } from "src/components/atoms/Txt";
import { useSignInLensContext } from "src/contexts/SignInLensContext";
import { useLogEvent } from "src/lib/tracking";
import { Step } from "src/pages/sign-in-lens";

export const InitialStep: FC = () => {
  useLogEvent({ action: "initial_step" });
  const { setStep } = useSignInLensContext();
  const { t } = useTranslation();
  return (
    <>
      <VStack>
        <Grid
          p={6}
          pb={24}
          justifyItems="center"
          gap={6}
          maxW="600px"
          mx="auto"
        >
          <Image
            width={172}
            height={172}
            src="/images/lens-logo.png"
            alt="Lens Logo"
          />
          <Txt textStyle="h2">{t("SignInLens.CompleteTheseStepsTitle")}</Txt>
          <Card>
            <VStack p={6} gap={6}>
              <HStack w="100%">
                <Box
                  p={1}
                  w={8}
                  h={8}
                  bg="lensLightgreenBackground"
                  color="lensLightgreen"
                  rounded="full"
                  textAlign="center"
                >
                  1.
                </Box>
                <Txt variant="p1">
                  {t("SignInLens.CompleteTheseStepsListItemOne")}
                </Txt>
              </HStack>
              <HStack w="100%">
                <Box
                  p={1}
                  w={8}
                  h={8}
                  bg="lensLightgreenBackground"
                  color="lensLightgreen"
                  rounded="full"
                  textAlign="center"
                >
                  2.
                </Box>
                <Txt variant="p1">
                  {t("SignInLens.CompleteTheseStepsListItemTwo")}
                </Txt>
              </HStack>
              <HStack w="100%">
                <Box
                  p={1}
                  w={8}
                  h={8}
                  bg="lensLightgreenBackground"
                  color="lensLightgreen"
                  rounded="full"
                  textAlign="center"
                >
                  3.
                </Box>
                <Txt variant="p1">
                  {t("SignInLens.CompleteTheseStepsListItemThree")}
                </Txt>
              </HStack>
              <HStack w="100%">
                <Box
                  p={1}
                  w={8}
                  h={8}
                  bg="lensLightgreenBackground"
                  color="lensLightgreen"
                  rounded="full"
                  textAlign="center"
                >
                  4.
                </Box>
                <Txt variant="p1">
                  {t("SignInLens.CompleteTheseStepsListItemFour")}
                </Txt>
              </HStack>
            </VStack>
          </Card>
        </Grid>
      </VStack>
      <VStack
        position={{ base: "sticky", md: "absolute" }}
        bottom={0}
        left={0}
        right={0}
        bg="componentBg"
        py={3}
      >
        <Button onClick={() => setStep(Step.CONNECT_WALLET)}>
          {t("Continue")}
        </Button>
      </VStack>
    </>
  );
};
