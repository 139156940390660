export const textStyles = {
  h1: {
    fontSize: ["26px", "72px"],
    fontWeight: "700",
    lineHeight: "100%",
  },
  h2: {
    fontSize: ["26px", "36px"],
    fontWeight: "700",
    lineHeight: "120%",
  },
  h3: {
    fontSize: ["18px", "32px"],
    fontWeight: "500",
    lineHeight: "120%",
  },
  h4: {
    fontSize: ["15px", "26px"],
    fontWeight: "700",
    lineHeight: "120%",
  },
  h5: {
    fontSize: ["15px", "30px"],
    fontWeight: "700",
    lineHeight: "120%",
    textTransform: "uppercase",
  },
  h6: {
    fontSize: ["12px", "22px"],
    fontWeight: "700",
    lineHeight: "120%",
  },
  p1: {
    fontSize: ["14px", "26px"],
    fontWeight: "500",
    lineHeight: "120%",
  },
  p2: {
    fontSize: ["12px", "16px"],
    fontWeight: "500",
    lineHeight: "120%",
  },
  p3: {
    fontSize: ["11px", "15px"],
    fontWeight: "500",
    lineHeight: "120%",
  },
};
