import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";
import * as React from "react";
import { textStyles } from "src/lib/theme/textStyles";

type ButtonProps = Omit<ChakraButtonProps, "fontSize"> & {
  fontSize?: keyof typeof textStyles;
};

export const Button: React.FC<ButtonProps> = ({
  fontSize = "h6",
  ...props
}) => {
  return (
    <ChakraButton
      fontSize={textStyles[fontSize].fontSize}
      bg="phaverPurple"
      px={8}
      py={4}
      {...props}
    />
  );
};
