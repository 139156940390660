const url = "https://connect.phaver.com/";
const title = "The Gateway to Web3 Social";
const description = "Connect your wallet to Phaver.";
const adSmall = "https://phaver.com/seo_small.png";
const adMedium = "https://phaver.com/seo_medium.png";
const adLarge = "https://phaver.com/seo_large.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title,
  titleTemplate: "%s | Phaver",
  description,
  canonical: url,
  openGraph: {
    type: "website",
    url,
    title,
    description,
    images: [
      {
        url: adSmall,
        width: 750,
        height: 393,
        alt: title,
      },
      {
        url: adMedium,
        width: 1125,
        height: 589,
        alt: title,
      },
      {
        url: adLarge,
        width: 1500,
        height: 785,
        alt: title,
      },
    ],
  },
  facebook: {
    appId: "861634317572536",
  },
  twitter: {
    handle: "@phaverapp",
    site: "@https://phaver.com/",
    cardType: "summary_large_image",
  },
  languageAlternate: [
    {
      hrefLang: "fi-FI",
      href: "https://connect.phaver.com",
    },
    {
      hrefLang: "en-EN",
      href: "https://connect.phaver.com",
    },
  ],
  additionalLinkTags: [
    {
      rel: "icon",
      href: "https://phaver.com/favicon.png",
    },
  ],
};
