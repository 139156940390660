import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bytea: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AccountReferralAggregationOutput = {
  __typename?: 'AccountReferralAggregationOutput';
  leveledUpCount: Scalars['Int'];
  signedUpCount: Scalars['Int'];
  totalPoints: Scalars['Int'];
};

export enum AccountReferralMode {
  LeveledUp = 'leveledUp',
  NotLeveledUp = 'notLeveledUp',
  SignedUp = 'signedUp'
}

export type AccountReferralsResult = {
  __typename?: 'AccountReferralsResult';
  id: Scalars['String'];
  profile?: Maybe<Profiles>;
};

export type AddCustomClaimsOutput = {
  __typename?: 'AddCustomClaimsOutput';
  jwt: Scalars['String'];
};

export type AirdropType = {
  __typename?: 'AirdropType';
  season1?: Maybe<Scalars['String']>;
};

export type AnimaStatus = {
  __typename?: 'AnimaStatus';
  canVerifyAgain: Scalars['Boolean'];
  hasAnima: Scalars['Boolean'];
  profileId: Scalars['String'];
  remainingDays: Scalars['Int'];
  verificationUrl: Scalars['String'];
  willReceiveCred: Scalars['Boolean'];
};

export enum AsyncStatus {
  Fulfilled = 'fulfilled',
  Initialised = 'initialised',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type BanUserOutput = {
  __typename?: 'BanUserOutput';
  count: Scalars['Int'];
  status: Scalars['String'];
  updatedProfileIds: Array<UpdatedProfileId>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export enum CcFollowType {
  Followers = 'followers',
  Following = 'following'
}

export type CcPageInfo = {
  __typename?: 'CcPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type CcProfile = {
  __typename?: 'CcProfile';
  avatar: Scalars['String'];
  ccProfile?: Maybe<Cc_Profiles>;
  displayName: Scalars['String'];
  handle: Scalars['String'];
  pageInfo?: Maybe<CcPageInfo>;
};

export type CcProfileBuildDataOutput = {
  __typename?: 'CcProfileBuildDataOutput';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type CcProfileExecuteOutput = {
  __typename?: 'CcProfileExecuteOutput';
  status: Scalars['String'];
};

export type CcProfilesOwnedByAccountOutput = {
  __typename?: 'CcProfilesOwnedByAccountOutput';
  avatar: Scalars['String'];
  chainId: Scalars['Int'];
  handle: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  profileId: Scalars['Int'];
};

export type ChangeTopicOutput = {
  __typename?: 'ChangeTopicOutput';
  status: Scalars['String'];
};

export type CheckLensHandleAvailabilityOutput = {
  __typename?: 'CheckLensHandleAvailabilityOutput';
  handle: Scalars['String'];
  isAvailable: Scalars['Boolean'];
};

export type CityList = {
  __typename?: 'CityList';
  city?: Maybe<Cities>;
  distance: Scalars['Float'];
  id: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

export type ClaimYieldOutput = {
  __typename?: 'ClaimYieldOutput';
  claimedCred: Scalars['Int'];
  claimedPoints: Scalars['Int'];
  credClaimedAt: Scalars['timestamptz'];
  credScore: Scalars['Int'];
  level: Scalars['Int'];
  previousCredScore: Scalars['Int'];
  previousLevel: Scalars['Int'];
  previousQualifyingCredScore: Scalars['Int'];
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
  qualifyingCredScore: Scalars['Int'];
};

export type CollectPostOutput = {
  __typename?: 'CollectPostOutput';
  collectNFTId: Scalars['String'];
  lensPost?: Maybe<Lens_Posts>;
  lensProfileId: Scalars['String'];
  pubId: Scalars['String'];
};

export type ConnectNftsBuildDataOutput = {
  __typename?: 'ConnectNftsBuildDataOutput';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type ConnectNftsExecuteOutput = {
  __typename?: 'ConnectNftsExecuteOutput';
  status: Scalars['String'];
};

export type ConnectWalletBuildDataOutput = {
  __typename?: 'ConnectWalletBuildDataOutput';
  message: Scalars['String'];
};

export type ConnectWalletExecuteOutput = {
  __typename?: 'ConnectWalletExecuteOutput';
  address: Scalars['String'];
  wallet?: Maybe<Wallets>;
};

export type ConnectWithTokenproofOutput = {
  __typename?: 'ConnectWithTokenproofOutput';
  account?: Maybe<Scalars['String']>;
  appLink?: Maybe<Scalars['String']>;
  nonce: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  status: TokenproofStatus;
};

export type ContractWithOwnedNfts = {
  __typename?: 'ContractWithOwnedNfts';
  chainId: Scalars['Int'];
  contractAddress: Scalars['String'];
  id: Scalars['Int'];
  ownedNfts: Array<Nft>;
  verifiedNftProject?: Maybe<Verified_Nft_Projects>;
};

export type CountryByIpOutput = {
  __typename?: 'CountryByIpOutput';
  countryIsoCode?: Maybe<Scalars['String']>;
};

export type CreateAdminPortalTenantOutput = {
  __typename?: 'CreateAdminPortalTenantOutput';
  id: Scalars['String'];
};

export type CreateCommentInput = {
  content: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
};

export type CreateCommentOutput = {
  __typename?: 'CreateCommentOutput';
  comment?: Maybe<Recommendation_Comments>;
  farcasterError?: Maybe<HasuraErrorCode>;
  id: Scalars['String'];
  lensError?: Maybe<HasuraErrorCode>;
};

export type CreateCommunityCategoryInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  translationKey: Scalars['String'];
};

export type CreateCommunityCategoryOutput = {
  __typename?: 'CreateCommunityCategoryOutput';
  categoryId: Scalars['String'];
};

export type CreateCommunityInput = {
  bannerImageId?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  isHidden: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  language: Scalars['String'];
  longDescription: Scalars['String'];
  name: Scalars['String'];
  nftProjectIds: Array<Scalars['Int']>;
  shortDescription: Scalars['String'];
  thumbnailImageId?: InputMaybe<Scalars['String']>;
};

export type CreateCommunityOutput = {
  __typename?: 'CreateCommunityOutput';
  community?: Maybe<Communities>;
  communityId: Scalars['String'];
};

export type CreateFarcasterAccountBuildDataOutput = {
  __typename?: 'CreateFarcasterAccountBuildDataOutput';
  message: Scalars['String'];
};

export type CreateFarcasterAccountExecuteOutput = {
  __typename?: 'CreateFarcasterAccountExecuteOutput';
  success: Scalars['Boolean'];
};

export type CreateSupportTicketOutput = {
  __typename?: 'CreateSupportTicketOutput';
  createdAt: Scalars['timestamptz'];
};

export type DeleteCommunityCategoriesOutput = {
  __typename?: 'DeleteCommunityCategoriesOutput';
  count: Scalars['Int'];
};

export type DeleteOutput = {
  __typename?: 'DeleteOutput';
  count: Scalars['Int'];
  status: Scalars['String'];
};

export type DisconnectXmtpWalletOutput = {
  __typename?: 'DisconnectXmtpWalletOutput';
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type DummyHasuraErrorCodeGeneratorOutput = {
  __typename?: 'DummyHasuraErrorCodeGeneratorOutput';
  errorCode?: Maybe<HasuraErrorCode>;
};

export type EditCommunityCategoryInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  imageId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  translationKey: Scalars['String'];
};

export type EditCommunityCategoryOutput = {
  __typename?: 'EditCommunityCategoryOutput';
  categoryId: Scalars['String'];
};

export type EditCommunityInput = {
  bannerImageId?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  communityId: Scalars['String'];
  isHidden: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  language: Scalars['String'];
  longDescription: Scalars['String'];
  name: Scalars['String'];
  nftProjectIds: Array<Scalars['Int']>;
  shortDescription: Scalars['String'];
  thumbnailImageId?: InputMaybe<Scalars['String']>;
};

export type EditCommunityOutput = {
  __typename?: 'EditCommunityOutput';
  community?: Maybe<Communities>;
  communityId: Scalars['String'];
};

export type EditProfileInput = {
  cityId: Scalars['String'];
  coverImageId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  facebook: Scalars['String'];
  imageId?: InputMaybe<Scalars['String']>;
  instagram: Scalars['String'];
  linkedin: Scalars['String'];
  name: Scalars['String'];
  pinterest: Scalars['String'];
  profileId: Scalars['String'];
  snapchat: Scalars['String'];
  telegram: Scalars['String'];
  tiktok: Scalars['String'];
  twitter: Scalars['String'];
  username: Scalars['String'];
  website: Scalars['String'];
  youtube: Scalars['String'];
};

export type EditProfileOutput = {
  __typename?: 'EditProfileOutput';
  profileId: Scalars['String'];
};

export type EventLog = {
  __typename?: 'EventLog';
  comment?: Maybe<Recommendation_Comments>;
  commentId?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  name: Scalars['String'];
  payload?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  profile?: Maybe<Profiles>;
  profileId?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Recommendations>;
  recommendationId?: Maybe<Scalars['String']>;
  src: Scalars['String'];
  timestamp: Scalars['timestamptz'];
  uid?: Maybe<Scalars['String']>;
};

export type EventPoint = {
  __typename?: 'EventPoint';
  eventPoints: Scalars['Int'];
  eventPointsCurrent: Scalars['Int'];
  profileId: Scalars['String'];
};

export type FarcasterAuthExecuteOutput = {
  __typename?: 'FarcasterAuthExecuteOutput';
  publicKey: Scalars['String'];
  signer?: Maybe<Farcaster_Signers>;
};

export type FarcasterAuthInitOutput = {
  __typename?: 'FarcasterAuthInitOutput';
  authorizationUrl: Scalars['String'];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

export enum FollowType {
  Farcaster = 'farcaster',
  Lens = 'lens',
  Phaver = 'phaver'
}

export type FrameActionButtonInput = {
  action: FrameActionTypeEnum;
  idx: Scalars['Int'];
  postUrl?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
};

export type FrameActionDetailsInput = {
  button: FrameActionButtonInput;
  inputText?: InputMaybe<Scalars['String']>;
  postUrl: Scalars['String'];
  serializedState?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type FrameActionOutput = {
  __typename?: 'FrameActionOutput';
  location?: Maybe<Scalars['String']>;
  nextFrame?: Maybe<NextFrame>;
};

export enum FrameActionTypeEnum {
  Link = 'link',
  Mint = 'mint',
  Post = 'post',
  PostRedirect = 'post_redirect',
  Tx = 'tx'
}

export enum FrameMethodEnum {
  Anonymous = 'anonymous',
  Farcaster = 'farcaster',
  Lens = 'lens'
}

export type GeneratePostPreviewOutput = {
  __typename?: 'GeneratePostPreviewOutput';
  description: Scalars['String'];
  linkPreview?: Maybe<LinkPreview>;
  tags: Array<PreviewTag>;
};

export type HandleReportOutput = {
  __typename?: 'HandleReportOutput';
  reportId: Scalars['String'];
  status: Scalars['String'];
};

export enum HasuraErrorCode {
  UniqueKeyViolation = 'UniqueKeyViolation',
  UnknownError = 'UnknownError',
  ApiAuthError = 'api_authError',
  ApiLocked = 'api_locked',
  ApiUnknown = 'api_unknown',
  CampaignAlreadyParticipated = 'campaign_alreadyParticipated',
  CommentNoOnlyPhaver = 'comment_noOnlyPhaver',
  CommunityDoesNotExist = 'community_doesNotExist',
  CreatePostProfileDoesNotOwnVideo = 'createPost_profileDoesNotOwnVideo',
  CreatePostVideoDoesNotExist = 'createPost_videoDoesNotExist',
  CreatePostVideoUploadFailed = 'createPost_videoUploadFailed',
  EligibilityAlreadyHasOne = 'eligibility_alreadyHasOne',
  EligibilityCredLevelTooLow = 'eligibility_credLevelTooLow',
  EligibilityNotEligible = 'eligibility_notEligible',
  FarcasterInviteAlreadyHasProfile = 'farcasterInvite_alreadyHasProfile',
  FarcasterInviteAlreadyRequestedInvite = 'farcasterInvite_alreadyRequestedInvite',
  FarcasterInviteFnameNotAvailable = 'farcasterInvite_fnameNotAvailable',
  FarcasterInviteInvalidSignature = 'farcasterInvite_invalidSignature',
  FarcasterInviteLockDenied = 'farcasterInvite_lockDenied',
  FarcasterInviteWalletHasFid = 'farcasterInvite_walletHasFid',
  FarcasterAnotherProfileAlreadyConnected = 'farcaster_anotherProfileAlreadyConnected',
  FarcasterApiError = 'farcaster_apiError',
  FarcasterCastHashNotFound = 'farcaster_castHashNotFound',
  FarcasterProfileNotFound = 'farcaster_profileNotFound',
  FarcasterSignerNotApproved = 'farcaster_signerNotApproved',
  FarcasterSignerNotFound = 'farcaster_signerNotFound',
  JoinCommunityAlreadyMember = 'joinCommunity_alreadyMember',
  JoinCommunityDoNotOwnNft = 'joinCommunity_doNotOwnNft',
  LeaveCommunityNotMember = 'leaveCommunity_notMember',
  LensApiChallengeWrongOwner = 'lensApiChallenge_wrongOwner',
  LensInviteAlreadyHasLensInvite = 'lensInvite_alreadyHasLensInvite',
  LensInviteAlreadyRequestedLensInvite = 'lensInvite_alreadyRequestedLensInvite',
  LensInviteBadHandle = 'lensInvite_badHandle',
  LensInviteHandleTaken = 'lensInvite_handleTaken',
  LensInviteLockDenied = 'lensInvite_lockDenied',
  LensInviteNoInvitesLeft = 'lensInvite_noInvitesLeft',
  LensInviteTransactionFailed = 'lensInvite_transactionFailed',
  LensApiError = 'lens_apiError',
  LensLockDenied = 'lens_lockDenied',
  LensNoQuotaLeft = 'lens_noQuotaLeft',
  LensProfileNotFound = 'lens_profileNotFound',
  LensRatelimited = 'lens_ratelimited',
  LensSetupIncomplete = 'lens_setupIncomplete',
  LivepeerAssetMissingInVideoDetails = 'livepeer_assetMissingInVideoDetails',
  LivepeerMetadataMissingInPlaybackInfo = 'livepeer_metadataMissingInPlaybackInfo',
  PostNotFulfilled = 'post_notFulfilled',
  ProfileNotFound = 'profile_notFound',
  StakesNonLeft = 'stakes_nonLeft',
  TokenproofNonceNotFound = 'tokenproof_nonceNotFound',
  UserTimedOut = 'user_timedOut',
  WalletInvalidSignature = 'wallet_invalidSignature',
  WalletNotAlreadyConnected = 'wallet_notAlreadyConnected',
  WalletXmtpWalletDoesNotExist = 'wallet_xmtpWalletDoesNotExist',
  ZodParseError = 'zod_parseError'
}

export type IndexPowerBadgeUsersOutput = {
  __typename?: 'IndexPowerBadgeUsersOutput';
  deleted: Scalars['Int'];
  fetchedAt: Scalars['timestamptz'];
  inserted: Scalars['Int'];
  newFidsToIndexContentFrom: Scalars['Int'];
  updated: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type JoinCommunityOutput = {
  __typename?: 'JoinCommunityOutput';
  community?: Maybe<Communities>;
  communityId: Scalars['String'];
  communityMembership?: Maybe<Community_Members>;
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
};

export type LeaveCommunityOutput = {
  __typename?: 'LeaveCommunityOutput';
  community?: Maybe<Communities>;
  communityId: Scalars['String'];
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LinkInput = {
  url: Scalars['String'];
};

export type LinkPreview = {
  __typename?: 'LinkPreview';
  frame?: Maybe<NextFrame>;
  link?: Maybe<Link>;
  url: Scalars['String'];
};

export enum ListYieldRewardOutputType {
  Nft = 'nft',
  Post = 'post',
  Stake = 'stake',
  TotalNft = 'total_nft',
  TotalPost = 'total_post',
  TotalStake = 'total_stake'
}

export enum ListYieldRewardType {
  Nft = 'nft',
  Post = 'post',
  Stake = 'stake',
  Total = 'total'
}

export type ListYieldRow = {
  __typename?: 'ListYieldRow';
  credYield: Scalars['Int'];
  nft?: Maybe<Connected_Nfts>;
  pointsYield: Scalars['Int'];
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  profileId: Scalars['String'];
  rewardDate: Scalars['String'];
  rewardType: ListYieldRewardOutputType;
  sorting: Scalars['Int'];
  sourceId?: Maybe<Scalars['String']>;
  stake?: Maybe<Daily_Staking>;
  stakeId?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  yieldSource?: Maybe<Yield_Sources>;
};

export type MocaConnectOutput = {
  __typename?: 'MocaConnectOutput';
  mocaId?: Maybe<Scalars['String']>;
  mocaRedirectUrl?: Maybe<Scalars['String']>;
};

export type NextFrame = {
  __typename?: 'NextFrame';
  buttons: Array<NextFrameButton>;
  imageAspectRatio?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  inputText?: Maybe<Scalars['String']>;
  postUrl?: Maybe<Scalars['String']>;
  serializedState?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type NextFrameButton = {
  __typename?: 'NextFrameButton';
  action?: Maybe<FrameActionTypeEnum>;
  idx: Scalars['Int'];
  label: Scalars['String'];
  postUrl?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type Nft = {
  __typename?: 'Nft';
  chainId: Scalars['Int'];
  contractAddress: Scalars['String'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
  tokenId: Scalars['String'];
};

export enum NftConnectEnum {
  All = 'ALL',
  Requested = 'REQUESTED'
}

export type NftList = {
  chainId: Scalars['Int'];
  nftContractAddress: Scalars['String'];
  nftId: Scalars['String'];
};

export type ParticipateCampaignWithSigOutput = {
  __typename?: 'ParticipateCampaignWithSigOutput';
  address: Scalars['String'];
  campaignId: Scalars['Int'];
  status: AsyncStatus;
  transactionHashUrl?: Maybe<Scalars['String']>;
};

export type PerkClaimOutput = {
  __typename?: 'PerkClaimOutput';
  status: Scalars['String'];
};

export type PerkDeleteOutput = {
  __typename?: 'PerkDeleteOutput';
  status: Scalars['String'];
};

export type PerkOutput = {
  __typename?: 'PerkOutput';
  availability?: Maybe<Scalars['Int']>;
  availabilityClaimedCount: Scalars['Int'];
  id: Scalars['Int'];
  isEligible: Scalars['Boolean'];
  minCredLevel?: Maybe<Scalars['Int']>;
  nftProject?: Maybe<Verified_Nft_Projects>;
  perkData?: Maybe<Perks>;
  pointCost?: Maybe<Scalars['Int']>;
  requiredSocialHolding?: Maybe<Scalars['String']>;
  status: PerkStatus;
  verifiedNftProjectsId?: Maybe<Scalars['Int']>;
};

export enum PerkStatus {
  Available = 'AVAILABLE',
  Claimed = 'CLAIMED',
  Expired = 'EXPIRED'
}

export type PerkUpsertInput = {
  availability?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  minCredLevel?: InputMaybe<Scalars['Int']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  requiredSocialHolding?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  verifiedNftProjectsId?: InputMaybe<Scalars['Int']>;
};

export type PerkUpsertOutput = {
  __typename?: 'PerkUpsertOutput';
  id: Scalars['Int'];
  perk?: Maybe<Perks>;
};

export type PhairDropEligibilityResponse = {
  __typename?: 'PhairDropEligibilityResponse';
  airdrop?: Maybe<AirdropType>;
  credLevel: Scalars['Int'];
  eligibleAmount: Scalars['Int'];
  hasFarcasterProfile: Scalars['Boolean'];
  hasLensProfile: Scalars['Boolean'];
  hasPhaverUpConnected: Scalars['Boolean'];
  hasSocialSummerConnected: Scalars['Boolean'];
  id: Scalars['String'];
  isFarcasterPowerUser: Scalars['Boolean'];
  isVerifiedCreator: Scalars['Boolean'];
  isVerifiedUser: Scalars['Boolean'];
  pointsAvailable: Scalars['Int'];
  profileImageUrl?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export enum PinType {
  Other = 'other',
  Social = 'social'
}

export type PostInput = {
  communityId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  imageIds?: InputMaybe<Array<Scalars['String']>>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  pointCost?: InputMaybe<Scalars['Int']>;
};

export type PostReactionResult = {
  __typename?: 'PostReactionResult';
  post?: Maybe<Recommendations>;
  postId: Scalars['String'];
  status: Scalars['String'];
};

export type PreviewTag = {
  __typename?: 'PreviewTag';
  matchedText: Scalars['String'];
  originalText: Scalars['String'];
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
};

export type ProfileByUsernameOutput = {
  __typename?: 'ProfileByUsernameOutput';
  id?: Maybe<Scalars['String']>;
  profile?: Maybe<Profiles>;
};

export type ProfileLatestCommunitiesPostedToReply = {
  __typename?: 'ProfileLatestCommunitiesPostedToReply';
  community?: Maybe<Communities>;
  communityId: Scalars['String'];
  sorting: Scalars['Int'];
};

export type QuotaFollows = {
  __typename?: 'QuotaFollows';
  available: Scalars['Int'];
  maximum: Scalars['Int'];
  monthlyAvailable: Scalars['Int'];
  monthlyMaximum: Scalars['Int'];
  quotaType: QuotaType;
};

export type QuotaLensApi = {
  __typename?: 'QuotaLensApi';
  dailyAvailable: Scalars['Int'];
  dailyMaximum: Scalars['Int'];
  hourlyAvailable: Scalars['Int'];
  hourlyMaximum: Scalars['Int'];
  quotaType: QuotaType;
};

export type QuotaPublications = {
  __typename?: 'QuotaPublications';
  available: Scalars['Int'];
  maximum: Scalars['Int'];
  quotaType: QuotaType;
};

export type QuotaReply = {
  __typename?: 'QuotaReply';
  lensApiMomoka?: Maybe<QuotaLensApi>;
  lensApiOnchain?: Maybe<QuotaLensApi>;
  lensFollows: QuotaFollows;
  lensPublications: QuotaPublications;
  phaverFollows: QuotaFollows;
};

export enum QuotaType {
  Farcaster = 'farcaster',
  Lens = 'lens',
  LensApiMomoka = 'lensApiMomoka',
  LensApiOnchain = 'lensApiOnchain',
  Phaver = 'phaver'
}

export enum RefetchFrameIdentifierType {
  Id = 'id',
  Url = 'url'
}

export type RefetchFrameOutput = {
  __typename?: 'RefetchFrameOutput';
  frame?: Maybe<Frames>;
  frameId: Scalars['String'];
};

export type RefetchMissingNftImagesResult = {
  __typename?: 'RefetchMissingNftImagesResult';
  createdAfter: Scalars['timestamptz'];
  refetchFail: Scalars['Int'];
  refetchSuccess: Scalars['Int'];
};

export enum RefreshAnimaStatus {
  All = 'all',
  None = 'none',
  Unknown = 'unknown'
}

export type RefreshMaterialViewOutput = {
  __typename?: 'RefreshMaterialViewOutput';
  status: Scalars['String'];
};

export enum RefreshableMaterializedView {
  CommunitiesTrending = 'communitiesTrending',
  CreatorsActive = 'creatorsActive',
  PostScores7d = 'postScores7d',
  UsersMostFollowed = 'usersMostFollowed',
  UsersMostFollowedOnLens = 'usersMostFollowedOnLens',
  UsersTrending = 'usersTrending'
}

export type ResolveWalletsOutput = {
  __typename?: 'ResolveWalletsOutput';
  resolves?: Maybe<Array<Maybe<ResolveWalletsOutputResolves>>>;
  status: Scalars['String'];
};

export type ResolveWalletsOutputDelegation = {
  __typename?: 'ResolveWalletsOutputDelegation';
  amount?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  delegateAddress?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  rights?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type ResolveWalletsOutputResolves = {
  __typename?: 'ResolveWalletsOutputResolves';
  delegations?: Maybe<Array<Maybe<ResolveWalletsOutputDelegation>>>;
  eps?: Maybe<Array<Maybe<Scalars['String']>>>;
  walletAddress: Scalars['String'];
};

export type ResultList = {
  __typename?: 'ResultList';
  city?: Maybe<Cities>;
  community?: Maybe<Communities>;
  id: Scalars['String'];
  profile?: Maybe<Profiles>;
  sorting: Scalars['Int'];
};

export type RetryCommentOutput = {
  __typename?: 'RetryCommentOutput';
  comment?: Maybe<Recommendation_Comments>;
  commentId: Scalars['String'];
};

export type SearchFarcasterChannelsOutput = {
  __typename?: 'SearchFarcasterChannelsOutput';
  description?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SetBlockOutput = {
  __typename?: 'SetBlockOutput';
  block: Scalars['Boolean'];
  profileId: Scalars['String'];
};

export type SetCommunityModeratorsOutput = {
  __typename?: 'SetCommunityModeratorsOutput';
  count: Scalars['Int'];
  status: Scalars['String'];
};

export type SetFrenOutput = {
  __typename?: 'SetFrenOutput';
  profileId: Scalars['String'];
  status: Scalars['String'];
};

export type SetLevelOutput = {
  __typename?: 'SetLevelOutput';
  profileId: Scalars['String'];
};

export type SetPrimaryWalletOutput = {
  __typename?: 'SetPrimaryWalletOutput';
  walletAddress: Scalars['String'];
};

export type SetProfileBadgeOutput = {
  __typename?: 'SetProfileBadgeOutput';
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
};

export type SetProfileVerificationOutput = {
  __typename?: 'SetProfileVerificationOutput';
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
};

export type SetVerifiedOutput = {
  __typename?: 'SetVerifiedOutput';
  profileId: Scalars['String'];
  status: Scalars['String'];
};

export type SetXmtpWalletOutput = {
  __typename?: 'SetXmtpWalletOutput';
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type ShadowBanTopicOutput = {
  __typename?: 'ShadowBanTopicOutput';
  status: Scalars['String'];
};

export type ShadowBanUserOutput = {
  __typename?: 'ShadowBanUserOutput';
  status: Scalars['String'];
};

export type SortedCommunityCategoryResult = {
  __typename?: 'SortedCommunityCategoryResult';
  category?: Maybe<Community_Categories>;
  categoryId: Scalars['String'];
  sortedCommunities: Array<Maybe<SortedCommunitiesResults>>;
};

export type SortedLensAppTopic = {
  __typename?: 'SortedLensAppTopic';
  id: Scalars['String'];
  sorting: Scalars['Int'];
  topic?: Maybe<Communities>;
};

export type SortedPost = {
  __typename?: 'SortedPost';
  id: Scalars['String'];
  post?: Maybe<Recommendations>;
  sorting: Scalars['Int'];
};

export enum SortedPostsFilter {
  FollowedOnly = 'followedOnly',
  FromCreatorsOnly = 'fromCreatorsOnly',
  LensPost = 'lensPost',
  MyCommunitiesOnly = 'myCommunitiesOnly',
  None = 'none',
  SuperPostOnly = 'superPostOnly'
}

export enum SortedPostsMode {
  Latest = 'latest',
  MostMirrored = 'mostMirrored',
  MostStaked = 'mostStaked',
  MostUpvoted = 'mostUpvoted',
  Recommended = 'recommended'
}

export enum SortedUsersFilter {
  CreatorsOnly = 'creatorsOnly',
  CreatorsPostedIn24Hours = 'creatorsPostedIn24Hours',
  FrensOnly = 'frensOnly',
  LensOnly = 'lensOnly',
  None = 'none',
  VerifiedOnly = 'verifiedOnly'
}

export enum SortedUsersSort {
  Random = 'Random'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export enum SupportTicketTag {
  Feedback = 'feedback',
  RequestAccountRemoval = 'requestAccountRemoval',
  RequestInviteCode = 'requestInviteCode'
}

export type SyncTokenResult = {
  __typename?: 'SyncTokenResult';
  forceRefresh: Scalars['Boolean'];
  isProfileSetupDone: Scalars['Boolean'];
  requireCaptcha: Scalars['Boolean'];
};

export type TempDevLoadImagesForFacasterProfilesOutput = {
  __typename?: 'TempDevLoadImagesForFacasterProfilesOutput';
  count?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export enum TimeRangeOption {
  AllTime = 'ALL_TIME',
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type TimeoutUserOutput = {
  __typename?: 'TimeoutUserOutput';
  status: Scalars['String'];
};

export type TogglePinPostOutput = {
  __typename?: 'TogglePinPostOutput';
  pinType: PinType;
  postId: Scalars['String'];
};

export enum TokenproofStatus {
  Authenticated = 'authenticated',
  Initialized = 'initialized',
  Rejected = 'rejected'
}

export type TranslateCommentOutput = {
  __typename?: 'TranslateCommentOutput';
  translatedDescription: Scalars['String'];
};

export type TranslatePostOutput = {
  __typename?: 'TranslatePostOutput';
  translatedDescription: Scalars['String'];
};

export type TriggerTaskDoneOutput = {
  __typename?: 'TriggerTaskDoneOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type UnreadNotificationsCount = {
  __typename?: 'UnreadNotificationsCount';
  profileId: Scalars['String'];
  unreadNotificationsCount: Scalars['Int'];
};

export type UpdatePostInput = {
  description: Scalars['String'];
  discountCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  imageId?: InputMaybe<Scalars['String']>;
  imageIds?: InputMaybe<Array<Scalars['String']>>;
  linkId?: InputMaybe<Scalars['String']>;
};

export type UpdatedProfileId = {
  __typename?: 'UpdatedProfileId';
  id: Scalars['String'];
  profile?: Maybe<Profiles>;
};

export type UploadImageFromUrlOutput = {
  __typename?: 'UploadImageFromUrlOutput';
  id: Scalars['String'];
  uploadImageFromUrl?: Maybe<Images>;
};

export type UserRankingOutput = {
  __typename?: 'UserRankingOutput';
  rankBySocialHoldings?: Maybe<Scalars['Int']>;
};

export type ValidateLinkOutput = {
  __typename?: 'ValidateLinkOutput';
  displayLink: Scalars['String'];
  link: Scalars['String'];
  success: Scalars['Boolean'];
};

export type VideoUrlOutput = {
  __typename?: 'VideoUrlOutput';
  success: Scalars['Boolean'];
  uploadUrl: Scalars['String'];
  videoId: Scalars['String'];
};

export enum Vote {
  Downvote = 'downvote',
  Reset = 'reset',
  Upvote = 'upvote'
}

export type VoteCommentOutput = {
  __typename?: 'VoteCommentOutput';
  comment?: Maybe<Recommendation_Comments>;
  commentId: Scalars['String'];
  vote: Vote;
};

export type VotePostOutput = {
  __typename?: 'VotePostOutput';
  post?: Maybe<Recommendations>;
  postId: Scalars['String'];
  vote: Vote;
};

/** columns and relationships of "account" */
export type Account = {
  __typename?: 'account';
  address: Scalars['String'];
  id: Scalars['String'];
  phaverWallet: Array<Wallets>;
  phaverWallet_aggregate: Wallets_Aggregate;
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "account" */
export type AccountPhaverWalletArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountPhaverWallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};

/** aggregated selection of "account" */
export type Account_Aggregate = {
  __typename?: 'account_aggregate';
  aggregate?: Maybe<Account_Aggregate_Fields>;
  nodes: Array<Account>;
};

/** aggregate fields of "account" */
export type Account_Aggregate_Fields = {
  __typename?: 'account_aggregate_fields';
  avg?: Maybe<Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Max_Fields>;
  min?: Maybe<Account_Min_Fields>;
  stddev?: Maybe<Account_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Sum_Fields>;
  var_pop?: Maybe<Account_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Var_Samp_Fields>;
  variance?: Maybe<Account_Variance_Fields>;
};


/** aggregate fields of "account" */
export type Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Avg_Fields = {
  __typename?: 'account_avg_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type Account_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Bool_Exp>>;
  _not?: InputMaybe<Account_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  totalBalance?: InputMaybe<Numeric_Comparison_Exp>;
  totalBalanceMA?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_54115ee388cdb6d86bb4bf5b2ea = 'PK_54115ee388cdb6d86bb4bf5b2ea'
}

/** input type for inserting data into table "account" */
export type Account_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Max_Fields = {
  __typename?: 'account_max_fields';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Account_Min_Fields = {
  __typename?: 'account_min_fields';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "account" */
export type Account_Mutation_Response = {
  __typename?: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
};

/** on_conflict condition type for table "account" */
export type Account_On_Conflict = {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: InputMaybe<Account_Bool_Exp>;
};

/** Ordering options when selecting data from "account". */
export type Account_Order_By = {
  address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalBalance?: InputMaybe<Order_By>;
  totalBalanceMA?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account */
export type Account_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account" */
export type Account_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Stddev_Fields = {
  __typename?: 'account_stddev_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_pop on columns */
export type Account_Stddev_Pop_Fields = {
  __typename?: 'account_stddev_pop_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_samp on columns */
export type Account_Stddev_Samp_Fields = {
  __typename?: 'account_stddev_samp_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "account" */
export type Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Account_Sum_Fields = {
  __typename?: 'account_sum_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "account_types" */
export type Account_Types = {
  __typename?: 'account_types';
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "account_types" */
export type Account_TypesAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "account_types" */
export type Account_TypesAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** aggregated selection of "account_types" */
export type Account_Types_Aggregate = {
  __typename?: 'account_types_aggregate';
  aggregate?: Maybe<Account_Types_Aggregate_Fields>;
  nodes: Array<Account_Types>;
};

/** aggregate fields of "account_types" */
export type Account_Types_Aggregate_Fields = {
  __typename?: 'account_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Types_Max_Fields>;
  min?: Maybe<Account_Types_Min_Fields>;
};


/** aggregate fields of "account_types" */
export type Account_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account_types". All fields are combined with a logical 'AND'. */
export type Account_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Types_Bool_Exp>>;
  _not?: InputMaybe<Account_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Types_Bool_Exp>>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_types" */
export enum Account_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountTypesPkey = 'account_types_pkey'
}

export enum Account_Types_Enum {
  Farcaster = 'farcaster',
  Lens = 'lens',
  Moderator = 'moderator',
  User = 'user'
}

/** Boolean expression to compare columns of type "account_types_enum". All fields are combined with logical 'AND'. */
export type Account_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Account_Types_Enum>;
  _in?: InputMaybe<Array<Account_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Account_Types_Enum>;
  _nin?: InputMaybe<Array<Account_Types_Enum>>;
};

/** input type for inserting data into table "account_types" */
export type Account_Types_Insert_Input = {
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Types_Max_Fields = {
  __typename?: 'account_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Types_Min_Fields = {
  __typename?: 'account_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account_types" */
export type Account_Types_Mutation_Response = {
  __typename?: 'account_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Types>;
};

/** input type for inserting object relation for remote table "account_types" */
export type Account_Types_Obj_Rel_Insert_Input = {
  data: Account_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Types_On_Conflict>;
};

/** on_conflict condition type for table "account_types" */
export type Account_Types_On_Conflict = {
  constraint: Account_Types_Constraint;
  update_columns?: Array<Account_Types_Update_Column>;
  where?: InputMaybe<Account_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "account_types". */
export type Account_Types_Order_By = {
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_types */
export type Account_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "account_types" */
export enum Account_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "account_types" */
export type Account_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "account_types" */
export type Account_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "account_types" */
export enum Account_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Account_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Types_Bool_Exp;
};

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Var_Pop_Fields = {
  __typename?: 'account_var_pop_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** aggregate var_samp on columns */
export type Account_Var_Samp_Fields = {
  __typename?: 'account_var_samp_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** aggregate variance on columns */
export type Account_Variance_Fields = {
  __typename?: 'account_variance_fields';
  /** A computed field, executes function "get_adjusted_total_balance" */
  totalBalance?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "get_adjusted_total_balance_ma" */
  totalBalanceMA?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'accounts';
  /** An object relationship */
  account_type: Account_Types;
  apexp?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  emailVerified?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  flags: Array<Flags>;
  /** An aggregate relationship */
  flags_aggregate: Flags_Aggregate;
  id: Scalars['String'];
  lang: Scalars['String'];
  /** An object relationship */
  lockedBalance?: Maybe<Locked_Balances>;
  /** An array relationship */
  notificationSettings: Array<Notification_Settings>;
  /** An aggregate relationship */
  notificationSettings_aggregate: Notification_Settings_Aggregate;
  notificationsReadAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  profile?: Maybe<Profiles>;
  /** An array relationship */
  pushTokens: Array<Push_Tokens>;
  /** An aggregate relationship */
  pushTokens_aggregate: Push_Tokens_Aggregate;
  referralBonusGivenAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  referrals: Array<Accounts>;
  /** An aggregate relationship */
  referrals_aggregate: Accounts_Aggregate;
  /** An object relationship */
  referredBy?: Maybe<Accounts>;
  referredByAccountId?: Maybe<Scalars['String']>;
  /** An array relationship */
  reward_claims: Array<Reward_Claims>;
  /** An aggregate relationship */
  reward_claims_aggregate: Reward_Claims_Aggregate;
  type: Account_Types_Enum;
  unverifiedEmail: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  wallets: Array<Wallets>;
  /** An aggregate relationship */
  wallets_aggregate: Wallets_Aggregate;
};


/** columns and relationships of "accounts" */
export type AccountsFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsNotificationSettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsNotificationSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsPushTokensArgs = {
  distinct_on?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Push_Tokens_Order_By>>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsPushTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Push_Tokens_Order_By>>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsReferralsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsReferrals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsReward_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsReward_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsWalletsArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename?: 'accounts_aggregate';
  aggregate?: Maybe<Accounts_Aggregate_Fields>;
  nodes: Array<Accounts>;
};

export type Accounts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Accounts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Accounts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Accounts_Aggregate_Bool_Exp_Count>;
};

export type Accounts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Accounts_Select_Column_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Accounts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Accounts_Select_Column_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename?: 'accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Max_Fields>;
  min?: Maybe<Accounts_Min_Fields>;
};


/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Accounts_Max_Order_By>;
  min?: InputMaybe<Accounts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Bool_Exp>>;
  _not?: InputMaybe<Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Accounts_Bool_Exp>>;
  account_type?: InputMaybe<Account_Types_Bool_Exp>;
  apexp?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  emailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  flags?: InputMaybe<Flags_Bool_Exp>;
  flags_aggregate?: InputMaybe<Flags_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  lockedBalance?: InputMaybe<Locked_Balances_Bool_Exp>;
  notificationSettings?: InputMaybe<Notification_Settings_Bool_Exp>;
  notificationSettings_aggregate?: InputMaybe<Notification_Settings_Aggregate_Bool_Exp>;
  notificationsReadAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  pushTokens?: InputMaybe<Push_Tokens_Bool_Exp>;
  pushTokens_aggregate?: InputMaybe<Push_Tokens_Aggregate_Bool_Exp>;
  referralBonusGivenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  referrals?: InputMaybe<Accounts_Bool_Exp>;
  referrals_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  referredBy?: InputMaybe<Accounts_Bool_Exp>;
  referredByAccountId?: InputMaybe<String_Comparison_Exp>;
  reward_claims?: InputMaybe<Reward_Claims_Bool_Exp>;
  reward_claims_aggregate?: InputMaybe<Reward_Claims_Aggregate_Bool_Exp>;
  type?: InputMaybe<Account_Types_Enum_Comparison_Exp>;
  unverifiedEmail?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  wallets?: InputMaybe<Wallets_Bool_Exp>;
  wallets_aggregate?: InputMaybe<Wallets_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey'
}

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  account_type?: InputMaybe<Account_Types_Obj_Rel_Insert_Input>;
  apexp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  flags?: InputMaybe<Flags_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  lockedBalance?: InputMaybe<Locked_Balances_Obj_Rel_Insert_Input>;
  notificationSettings?: InputMaybe<Notification_Settings_Arr_Rel_Insert_Input>;
  notificationsReadAt?: InputMaybe<Scalars['timestamptz']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  pushTokens?: InputMaybe<Push_Tokens_Arr_Rel_Insert_Input>;
  referralBonusGivenAt?: InputMaybe<Scalars['timestamptz']>;
  referrals?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  referredBy?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  referredByAccountId?: InputMaybe<Scalars['String']>;
  reward_claims?: InputMaybe<Reward_Claims_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Account_Types_Enum>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wallets?: InputMaybe<Wallets_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename?: 'accounts_max_fields';
  apexp?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  notificationsReadAt?: Maybe<Scalars['timestamptz']>;
  referralBonusGivenAt?: Maybe<Scalars['timestamptz']>;
  referredByAccountId?: Maybe<Scalars['String']>;
  unverifiedEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  apexp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  notificationsReadAt?: InputMaybe<Order_By>;
  referralBonusGivenAt?: InputMaybe<Order_By>;
  referredByAccountId?: InputMaybe<Order_By>;
  unverifiedEmail?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename?: 'accounts_min_fields';
  apexp?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  notificationsReadAt?: Maybe<Scalars['timestamptz']>;
  referralBonusGivenAt?: Maybe<Scalars['timestamptz']>;
  referredByAccountId?: Maybe<Scalars['String']>;
  unverifiedEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  apexp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  notificationsReadAt?: InputMaybe<Order_By>;
  referralBonusGivenAt?: InputMaybe<Order_By>;
  referredByAccountId?: InputMaybe<Order_By>;
  unverifiedEmail?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** on_conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint;
  update_columns?: Array<Accounts_Update_Column>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  account_type?: InputMaybe<Account_Types_Order_By>;
  apexp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailVerified?: InputMaybe<Order_By>;
  flags_aggregate?: InputMaybe<Flags_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  lockedBalance?: InputMaybe<Locked_Balances_Order_By>;
  notificationSettings_aggregate?: InputMaybe<Notification_Settings_Aggregate_Order_By>;
  notificationsReadAt?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  pushTokens_aggregate?: InputMaybe<Push_Tokens_Aggregate_Order_By>;
  referralBonusGivenAt?: InputMaybe<Order_By>;
  referrals_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  referredBy?: InputMaybe<Accounts_Order_By>;
  referredByAccountId?: InputMaybe<Order_By>;
  reward_claims_aggregate?: InputMaybe<Reward_Claims_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  unverifiedEmail?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  wallets_aggregate?: InputMaybe<Wallets_Aggregate_Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  Apexp = 'apexp',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationsReadAt = 'notificationsReadAt',
  /** column name */
  ReferralBonusGivenAt = 'referralBonusGivenAt',
  /** column name */
  ReferredByAccountId = 'referredByAccountId',
  /** column name */
  Type = 'type',
  /** column name */
  UnverifiedEmail = 'unverifiedEmail',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "accounts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "accounts" */
export enum Accounts_Select_Column_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EmailVerified = 'emailVerified'
}

/** select "accounts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "accounts" */
export enum Accounts_Select_Column_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EmailVerified = 'emailVerified'
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  apexp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  notificationsReadAt?: InputMaybe<Scalars['timestamptz']>;
  referralBonusGivenAt?: InputMaybe<Scalars['timestamptz']>;
  referredByAccountId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Account_Types_Enum>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "accounts" */
export type Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Stream_Cursor_Value_Input = {
  apexp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  notificationsReadAt?: InputMaybe<Scalars['timestamptz']>;
  referralBonusGivenAt?: InputMaybe<Scalars['timestamptz']>;
  referredByAccountId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Account_Types_Enum>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  Apexp = 'apexp',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationsReadAt = 'notificationsReadAt',
  /** column name */
  ReferralBonusGivenAt = 'referralBonusGivenAt',
  /** column name */
  ReferredByAccountId = 'referredByAccountId',
  /** column name */
  Type = 'type',
  /** column name */
  UnverifiedEmail = 'unverifiedEmail',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Accounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounts_Bool_Exp;
};

/** columns and relationships of "admin_portal_whitelist" */
export type Admin_Portal_Whitelist = {
  __typename?: 'admin_portal_whitelist';
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_Aggregate = {
  __typename?: 'admin_portal_whitelist_aggregate';
  aggregate?: Maybe<Admin_Portal_Whitelist_Aggregate_Fields>;
  nodes: Array<Admin_Portal_Whitelist>;
};

/** aggregate fields of "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_Aggregate_Fields = {
  __typename?: 'admin_portal_whitelist_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Admin_Portal_Whitelist_Max_Fields>;
  min?: Maybe<Admin_Portal_Whitelist_Min_Fields>;
};


/** aggregate fields of "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Admin_Portal_Whitelist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "admin_portal_whitelist". All fields are combined with a logical 'AND'. */
export type Admin_Portal_Whitelist_Bool_Exp = {
  _and?: InputMaybe<Array<Admin_Portal_Whitelist_Bool_Exp>>;
  _not?: InputMaybe<Admin_Portal_Whitelist_Bool_Exp>;
  _or?: InputMaybe<Array<Admin_Portal_Whitelist_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "admin_portal_whitelist" */
export enum Admin_Portal_Whitelist_Constraint {
  /** unique or primary key constraint on columns "email" */
  AdminPortalWhitelistEmailUnique = 'admin_portal_whitelist_email_unique',
  /** unique or primary key constraint on columns "id" */
  AdminPortalWhitelistPkey = 'admin_portal_whitelist_pkey'
}

/** input type for inserting data into table "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Admin_Portal_Whitelist_Max_Fields = {
  __typename?: 'admin_portal_whitelist_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Admin_Portal_Whitelist_Min_Fields = {
  __typename?: 'admin_portal_whitelist_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_Mutation_Response = {
  __typename?: 'admin_portal_whitelist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin_Portal_Whitelist>;
};

/** on_conflict condition type for table "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_On_Conflict = {
  constraint: Admin_Portal_Whitelist_Constraint;
  update_columns?: Array<Admin_Portal_Whitelist_Update_Column>;
  where?: InputMaybe<Admin_Portal_Whitelist_Bool_Exp>;
};

/** Ordering options when selecting data from "admin_portal_whitelist". */
export type Admin_Portal_Whitelist_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: admin_portal_whitelist */
export type Admin_Portal_Whitelist_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "admin_portal_whitelist" */
export enum Admin_Portal_Whitelist_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "admin_portal_whitelist" */
export type Admin_Portal_Whitelist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Admin_Portal_Whitelist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Admin_Portal_Whitelist_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "admin_portal_whitelist" */
export enum Admin_Portal_Whitelist_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Admin_Portal_Whitelist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Admin_Portal_Whitelist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Admin_Portal_Whitelist_Bool_Exp;
};

/** columns and relationships of "airdrop" */
export type Airdrop = {
  __typename?: 'airdrop';
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  season1?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "airdrop" */
export type Airdrop_Aggregate = {
  __typename?: 'airdrop_aggregate';
  aggregate?: Maybe<Airdrop_Aggregate_Fields>;
  nodes: Array<Airdrop>;
};

/** aggregate fields of "airdrop" */
export type Airdrop_Aggregate_Fields = {
  __typename?: 'airdrop_aggregate_fields';
  avg?: Maybe<Airdrop_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Airdrop_Max_Fields>;
  min?: Maybe<Airdrop_Min_Fields>;
  stddev?: Maybe<Airdrop_Stddev_Fields>;
  stddev_pop?: Maybe<Airdrop_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Airdrop_Stddev_Samp_Fields>;
  sum?: Maybe<Airdrop_Sum_Fields>;
  var_pop?: Maybe<Airdrop_Var_Pop_Fields>;
  var_samp?: Maybe<Airdrop_Var_Samp_Fields>;
  variance?: Maybe<Airdrop_Variance_Fields>;
};


/** aggregate fields of "airdrop" */
export type Airdrop_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Airdrop_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Airdrop_Avg_Fields = {
  __typename?: 'airdrop_avg_fields';
  season1?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "airdrop". All fields are combined with a logical 'AND'. */
export type Airdrop_Bool_Exp = {
  _and?: InputMaybe<Array<Airdrop_Bool_Exp>>;
  _not?: InputMaybe<Airdrop_Bool_Exp>;
  _or?: InputMaybe<Array<Airdrop_Bool_Exp>>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  season1?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "airdrop" */
export enum Airdrop_Constraint {
  /** unique or primary key constraint on columns "profile_id" */
  AirdropPkey = 'airdrop_pkey'
}

/** input type for incrementing numeric columns in table "airdrop" */
export type Airdrop_Inc_Input = {
  season1?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "airdrop" */
export type Airdrop_Insert_Input = {
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  season1?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Airdrop_Max_Fields = {
  __typename?: 'airdrop_max_fields';
  profileId?: Maybe<Scalars['String']>;
  season1?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Airdrop_Min_Fields = {
  __typename?: 'airdrop_min_fields';
  profileId?: Maybe<Scalars['String']>;
  season1?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "airdrop" */
export type Airdrop_Mutation_Response = {
  __typename?: 'airdrop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Airdrop>;
};

/** input type for inserting object relation for remote table "airdrop" */
export type Airdrop_Obj_Rel_Insert_Input = {
  data: Airdrop_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Airdrop_On_Conflict>;
};

/** on_conflict condition type for table "airdrop" */
export type Airdrop_On_Conflict = {
  constraint: Airdrop_Constraint;
  update_columns?: Array<Airdrop_Update_Column>;
  where?: InputMaybe<Airdrop_Bool_Exp>;
};

/** Ordering options when selecting data from "airdrop". */
export type Airdrop_Order_By = {
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  season1?: InputMaybe<Order_By>;
};

/** primary key columns input for table: airdrop */
export type Airdrop_Pk_Columns_Input = {
  profileId: Scalars['String'];
};

/** select columns of table "airdrop" */
export enum Airdrop_Select_Column {
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Season1 = 'season1'
}

/** input type for updating data in table "airdrop" */
export type Airdrop_Set_Input = {
  profileId?: InputMaybe<Scalars['String']>;
  season1?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Airdrop_Stddev_Fields = {
  __typename?: 'airdrop_stddev_fields';
  season1?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Airdrop_Stddev_Pop_Fields = {
  __typename?: 'airdrop_stddev_pop_fields';
  season1?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Airdrop_Stddev_Samp_Fields = {
  __typename?: 'airdrop_stddev_samp_fields';
  season1?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "airdrop" */
export type Airdrop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Airdrop_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Airdrop_Stream_Cursor_Value_Input = {
  profileId?: InputMaybe<Scalars['String']>;
  season1?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Airdrop_Sum_Fields = {
  __typename?: 'airdrop_sum_fields';
  season1?: Maybe<Scalars['numeric']>;
};

/** update columns of table "airdrop" */
export enum Airdrop_Update_Column {
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Season1 = 'season1'
}

export type Airdrop_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Airdrop_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Airdrop_Set_Input>;
  /** filter the rows which have to be updated */
  where: Airdrop_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Airdrop_Var_Pop_Fields = {
  __typename?: 'airdrop_var_pop_fields';
  season1?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Airdrop_Var_Samp_Fields = {
  __typename?: 'airdrop_var_samp_fields';
  season1?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Airdrop_Variance_Fields = {
  __typename?: 'airdrop_variance_fields';
  season1?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "async_status" */
export type Async_Status = {
  __typename?: 'async_status';
  /** An array relationship */
  images: Array<Images>;
  /** An aggregate relationship */
  images_aggregate: Images_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "async_status" */
export type Async_StatusImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "async_status" */
export type Async_StatusImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** aggregated selection of "async_status" */
export type Async_Status_Aggregate = {
  __typename?: 'async_status_aggregate';
  aggregate?: Maybe<Async_Status_Aggregate_Fields>;
  nodes: Array<Async_Status>;
};

/** aggregate fields of "async_status" */
export type Async_Status_Aggregate_Fields = {
  __typename?: 'async_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Async_Status_Max_Fields>;
  min?: Maybe<Async_Status_Min_Fields>;
};


/** aggregate fields of "async_status" */
export type Async_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Async_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "async_status". All fields are combined with a logical 'AND'. */
export type Async_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Async_Status_Bool_Exp>>;
  _not?: InputMaybe<Async_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Async_Status_Bool_Exp>>;
  images?: InputMaybe<Images_Bool_Exp>;
  images_aggregate?: InputMaybe<Images_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "async_status" */
export enum Async_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  AsyncStatusPkey = 'async_status_pkey'
}

export enum Async_Status_Enum {
  Fulfilled = 'fulfilled',
  Initialised = 'initialised',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "async_status_enum". All fields are combined with logical 'AND'. */
export type Async_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Async_Status_Enum>;
  _in?: InputMaybe<Array<Async_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Async_Status_Enum>;
  _nin?: InputMaybe<Array<Async_Status_Enum>>;
};

/** input type for inserting data into table "async_status" */
export type Async_Status_Insert_Input = {
  images?: InputMaybe<Images_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Async_Status_Max_Fields = {
  __typename?: 'async_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Async_Status_Min_Fields = {
  __typename?: 'async_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "async_status" */
export type Async_Status_Mutation_Response = {
  __typename?: 'async_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Async_Status>;
};

/** input type for inserting object relation for remote table "async_status" */
export type Async_Status_Obj_Rel_Insert_Input = {
  data: Async_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Async_Status_On_Conflict>;
};

/** on_conflict condition type for table "async_status" */
export type Async_Status_On_Conflict = {
  constraint: Async_Status_Constraint;
  update_columns?: Array<Async_Status_Update_Column>;
  where?: InputMaybe<Async_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "async_status". */
export type Async_Status_Order_By = {
  images_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: async_status */
export type Async_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "async_status" */
export enum Async_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "async_status" */
export type Async_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "async_status" */
export type Async_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Async_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Async_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "async_status" */
export enum Async_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Async_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Async_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Async_Status_Bool_Exp;
};

/** columns and relationships of "audios" */
export type Audios = {
  __typename?: 'audios';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  mimeType: Scalars['String'];
  profileId: Scalars['String'];
  sourceUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "audios" */
export type AudiosMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "audios" */
export type AudiosMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};

/** aggregated selection of "audios" */
export type Audios_Aggregate = {
  __typename?: 'audios_aggregate';
  aggregate?: Maybe<Audios_Aggregate_Fields>;
  nodes: Array<Audios>;
};

/** aggregate fields of "audios" */
export type Audios_Aggregate_Fields = {
  __typename?: 'audios_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audios_Max_Fields>;
  min?: Maybe<Audios_Min_Fields>;
};


/** aggregate fields of "audios" */
export type Audios_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audios_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "audios". All fields are combined with a logical 'AND'. */
export type Audios_Bool_Exp = {
  _and?: InputMaybe<Array<Audios_Bool_Exp>>;
  _not?: InputMaybe<Audios_Bool_Exp>;
  _or?: InputMaybe<Array<Audios_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  medias?: InputMaybe<Medias_Bool_Exp>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Bool_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  sourceUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "audios" */
export enum Audios_Constraint {
  /** unique or primary key constraint on columns "id" */
  AudiosPkey = 'audios_pkey'
}

/** input type for inserting data into table "audios" */
export type Audios_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  medias?: InputMaybe<Medias_Arr_Rel_Insert_Input>;
  mimeType?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Audios_Max_Fields = {
  __typename?: 'audios_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Audios_Min_Fields = {
  __typename?: 'audios_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "audios" */
export type Audios_Mutation_Response = {
  __typename?: 'audios_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audios>;
};

/** input type for inserting object relation for remote table "audios" */
export type Audios_Obj_Rel_Insert_Input = {
  data: Audios_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Audios_On_Conflict>;
};

/** on_conflict condition type for table "audios" */
export type Audios_On_Conflict = {
  constraint: Audios_Constraint;
  update_columns?: Array<Audios_Update_Column>;
  where?: InputMaybe<Audios_Bool_Exp>;
};

/** Ordering options when selecting data from "audios". */
export type Audios_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Order_By>;
  mimeType?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  sourceUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audios */
export type Audios_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "audios" */
export enum Audios_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  SourceUrl = 'sourceUrl',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "audios" */
export type Audios_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "audios" */
export type Audios_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audios_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audios_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "audios" */
export enum Audios_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  SourceUrl = 'sourceUrl',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Audios_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audios_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audios_Bool_Exp;
};

/** columns and relationships of "badge_types" */
export type Badge_Types = {
  __typename?: 'badge_types';
  value: Scalars['String'];
};

/** aggregated selection of "badge_types" */
export type Badge_Types_Aggregate = {
  __typename?: 'badge_types_aggregate';
  aggregate?: Maybe<Badge_Types_Aggregate_Fields>;
  nodes: Array<Badge_Types>;
};

/** aggregate fields of "badge_types" */
export type Badge_Types_Aggregate_Fields = {
  __typename?: 'badge_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Badge_Types_Max_Fields>;
  min?: Maybe<Badge_Types_Min_Fields>;
};


/** aggregate fields of "badge_types" */
export type Badge_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Badge_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "badge_types". All fields are combined with a logical 'AND'. */
export type Badge_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Badge_Types_Bool_Exp>>;
  _not?: InputMaybe<Badge_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Badge_Types_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "badge_types" */
export enum Badge_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  BadgeTypesPkey = 'badge_types_pkey'
}

export enum Badge_Types_Enum {
  Contributor = 'contributor',
  Frens = 'frens'
}

/** Boolean expression to compare columns of type "badge_types_enum". All fields are combined with logical 'AND'. */
export type Badge_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Badge_Types_Enum>;
  _in?: InputMaybe<Array<Badge_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Badge_Types_Enum>;
  _nin?: InputMaybe<Array<Badge_Types_Enum>>;
};

/** input type for inserting data into table "badge_types" */
export type Badge_Types_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Badge_Types_Max_Fields = {
  __typename?: 'badge_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Badge_Types_Min_Fields = {
  __typename?: 'badge_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "badge_types" */
export type Badge_Types_Mutation_Response = {
  __typename?: 'badge_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Badge_Types>;
};

/** on_conflict condition type for table "badge_types" */
export type Badge_Types_On_Conflict = {
  constraint: Badge_Types_Constraint;
  update_columns?: Array<Badge_Types_Update_Column>;
  where?: InputMaybe<Badge_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "badge_types". */
export type Badge_Types_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: badge_types */
export type Badge_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "badge_types" */
export enum Badge_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "badge_types" */
export type Badge_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "badge_types" */
export type Badge_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Badge_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Badge_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "badge_types" */
export enum Badge_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Badge_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Badge_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Badge_Types_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "blocked_profiles" */
export type Blocked_Profiles = {
  __typename?: 'blocked_profiles';
  /** An object relationship */
  blockedProfile: Profiles;
  blocked_profile_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  profile: Profiles;
  profile_id: Scalars['String'];
};

/** aggregated selection of "blocked_profiles" */
export type Blocked_Profiles_Aggregate = {
  __typename?: 'blocked_profiles_aggregate';
  aggregate?: Maybe<Blocked_Profiles_Aggregate_Fields>;
  nodes: Array<Blocked_Profiles>;
};

export type Blocked_Profiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Blocked_Profiles_Aggregate_Bool_Exp_Count>;
};

export type Blocked_Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Blocked_Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "blocked_profiles" */
export type Blocked_Profiles_Aggregate_Fields = {
  __typename?: 'blocked_profiles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blocked_Profiles_Max_Fields>;
  min?: Maybe<Blocked_Profiles_Min_Fields>;
};


/** aggregate fields of "blocked_profiles" */
export type Blocked_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blocked_profiles" */
export type Blocked_Profiles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Blocked_Profiles_Max_Order_By>;
  min?: InputMaybe<Blocked_Profiles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "blocked_profiles" */
export type Blocked_Profiles_Arr_Rel_Insert_Input = {
  data: Array<Blocked_Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Blocked_Profiles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "blocked_profiles". All fields are combined with a logical 'AND'. */
export type Blocked_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Blocked_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Blocked_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Blocked_Profiles_Bool_Exp>>;
  blockedProfile?: InputMaybe<Profiles_Bool_Exp>;
  blocked_profile_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profile_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "blocked_profiles" */
export enum Blocked_Profiles_Constraint {
  /** unique or primary key constraint on columns "profile_id", "blocked_profile_id" */
  BlockedProfilesBlockedProfileIdProfileIdUnique = 'blocked_profiles_blocked_profile_id_profile_id_unique',
  /** unique or primary key constraint on columns "profile_id", "blocked_profile_id" */
  BlockedProfilesPkey = 'blocked_profiles_pkey'
}

/** input type for inserting data into table "blocked_profiles" */
export type Blocked_Profiles_Insert_Input = {
  blockedProfile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  blocked_profile_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Blocked_Profiles_Max_Fields = {
  __typename?: 'blocked_profiles_max_fields';
  blocked_profile_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  profile_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "blocked_profiles" */
export type Blocked_Profiles_Max_Order_By = {
  blocked_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Blocked_Profiles_Min_Fields = {
  __typename?: 'blocked_profiles_min_fields';
  blocked_profile_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  profile_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "blocked_profiles" */
export type Blocked_Profiles_Min_Order_By = {
  blocked_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "blocked_profiles" */
export type Blocked_Profiles_Mutation_Response = {
  __typename?: 'blocked_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blocked_Profiles>;
};

/** on_conflict condition type for table "blocked_profiles" */
export type Blocked_Profiles_On_Conflict = {
  constraint: Blocked_Profiles_Constraint;
  update_columns?: Array<Blocked_Profiles_Update_Column>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "blocked_profiles". */
export type Blocked_Profiles_Order_By = {
  blockedProfile?: InputMaybe<Profiles_Order_By>;
  blocked_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blocked_profiles */
export type Blocked_Profiles_Pk_Columns_Input = {
  blocked_profile_id: Scalars['String'];
  profile_id: Scalars['String'];
};

/** select columns of table "blocked_profiles" */
export enum Blocked_Profiles_Select_Column {
  /** column name */
  BlockedProfileId = 'blocked_profile_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProfileId = 'profile_id'
}

/** input type for updating data in table "blocked_profiles" */
export type Blocked_Profiles_Set_Input = {
  blocked_profile_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  profile_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "blocked_profiles" */
export type Blocked_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blocked_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blocked_Profiles_Stream_Cursor_Value_Input = {
  blocked_profile_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  profile_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "blocked_profiles" */
export enum Blocked_Profiles_Update_Column {
  /** column name */
  BlockedProfileId = 'blocked_profile_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProfileId = 'profile_id'
}

export type Blocked_Profiles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blocked_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Blocked_Profiles_Bool_Exp;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']>;
  _gt?: InputMaybe<Scalars['bytea']>;
  _gte?: InputMaybe<Scalars['bytea']>;
  _in?: InputMaybe<Array<Scalars['bytea']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bytea']>;
  _lte?: InputMaybe<Scalars['bytea']>;
  _neq?: InputMaybe<Scalars['bytea']>;
  _nin?: InputMaybe<Array<Scalars['bytea']>>;
};

/** columns and relationships of "campaign_signatures" */
export type Campaign_Signatures = {
  __typename?: 'campaign_signatures';
  address: Scalars['String'];
  campaignId: Scalars['Int'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  message: Scalars['String'];
  queueId?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  status: Async_Status_Enum;
  transactionHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "campaign_signatures" */
export type Campaign_Signatures_Aggregate = {
  __typename?: 'campaign_signatures_aggregate';
  aggregate?: Maybe<Campaign_Signatures_Aggregate_Fields>;
  nodes: Array<Campaign_Signatures>;
};

/** aggregate fields of "campaign_signatures" */
export type Campaign_Signatures_Aggregate_Fields = {
  __typename?: 'campaign_signatures_aggregate_fields';
  avg?: Maybe<Campaign_Signatures_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Campaign_Signatures_Max_Fields>;
  min?: Maybe<Campaign_Signatures_Min_Fields>;
  stddev?: Maybe<Campaign_Signatures_Stddev_Fields>;
  stddev_pop?: Maybe<Campaign_Signatures_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Campaign_Signatures_Stddev_Samp_Fields>;
  sum?: Maybe<Campaign_Signatures_Sum_Fields>;
  var_pop?: Maybe<Campaign_Signatures_Var_Pop_Fields>;
  var_samp?: Maybe<Campaign_Signatures_Var_Samp_Fields>;
  variance?: Maybe<Campaign_Signatures_Variance_Fields>;
};


/** aggregate fields of "campaign_signatures" */
export type Campaign_Signatures_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Signatures_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Campaign_Signatures_Avg_Fields = {
  __typename?: 'campaign_signatures_avg_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "campaign_signatures". All fields are combined with a logical 'AND'. */
export type Campaign_Signatures_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Signatures_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Signatures_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Signatures_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  campaignId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  queueId?: InputMaybe<String_Comparison_Exp>;
  signature?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Async_Status_Enum_Comparison_Exp>;
  transactionHash?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_signatures" */
export enum Campaign_Signatures_Constraint {
  /** unique or primary key constraint on columns "address", "campaign_id" */
  CampaignSignaturesCampaignIdAddress = 'campaign_signatures_campaign_id_address',
  /** unique or primary key constraint on columns "id" */
  CampaignSignaturesPkey = 'campaign_signatures_pkey'
}

/** input type for incrementing numeric columns in table "campaign_signatures" */
export type Campaign_Signatures_Inc_Input = {
  campaignId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "campaign_signatures" */
export type Campaign_Signatures_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  queueId?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  transactionHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Signatures_Max_Fields = {
  __typename?: 'campaign_signatures_max_fields';
  address?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  transactionHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Campaign_Signatures_Min_Fields = {
  __typename?: 'campaign_signatures_min_fields';
  address?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  transactionHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "campaign_signatures" */
export type Campaign_Signatures_Mutation_Response = {
  __typename?: 'campaign_signatures_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Signatures>;
};

/** on_conflict condition type for table "campaign_signatures" */
export type Campaign_Signatures_On_Conflict = {
  constraint: Campaign_Signatures_Constraint;
  update_columns?: Array<Campaign_Signatures_Update_Column>;
  where?: InputMaybe<Campaign_Signatures_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_signatures". */
export type Campaign_Signatures_Order_By = {
  address?: InputMaybe<Order_By>;
  campaignId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  queueId?: InputMaybe<Order_By>;
  signature?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transactionHash?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_signatures */
export type Campaign_Signatures_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "campaign_signatures" */
export enum Campaign_Signatures_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  QueueId = 'queueId',
  /** column name */
  Signature = 'signature',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionHash = 'transactionHash',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "campaign_signatures" */
export type Campaign_Signatures_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  queueId?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  transactionHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Campaign_Signatures_Stddev_Fields = {
  __typename?: 'campaign_signatures_stddev_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Campaign_Signatures_Stddev_Pop_Fields = {
  __typename?: 'campaign_signatures_stddev_pop_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Campaign_Signatures_Stddev_Samp_Fields = {
  __typename?: 'campaign_signatures_stddev_samp_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "campaign_signatures" */
export type Campaign_Signatures_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaign_Signatures_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaign_Signatures_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  queueId?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  transactionHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Campaign_Signatures_Sum_Fields = {
  __typename?: 'campaign_signatures_sum_fields';
  campaignId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "campaign_signatures" */
export enum Campaign_Signatures_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  QueueId = 'queueId',
  /** column name */
  Signature = 'signature',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionHash = 'transactionHash',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Campaign_Signatures_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Campaign_Signatures_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaign_Signatures_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaign_Signatures_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Campaign_Signatures_Var_Pop_Fields = {
  __typename?: 'campaign_signatures_var_pop_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Campaign_Signatures_Var_Samp_Fields = {
  __typename?: 'campaign_signatures_var_samp_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Campaign_Signatures_Variance_Fields = {
  __typename?: 'campaign_signatures_variance_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "cc_profiles" */
export type Cc_Profiles = {
  __typename?: 'cc_profiles';
  ccProfileId: Scalars['Int'];
  chainId: Scalars['Int'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  handle: Scalars['String'];
  id: Scalars['String'];
  namespaceContract: Scalars['String'];
  namespaceName: Scalars['String'];
  ownerAddress: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profiles>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "cc_profiles" */
export type Cc_Profiles_Aggregate = {
  __typename?: 'cc_profiles_aggregate';
  aggregate?: Maybe<Cc_Profiles_Aggregate_Fields>;
  nodes: Array<Cc_Profiles>;
};

export type Cc_Profiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cc_Profiles_Aggregate_Bool_Exp_Count>;
};

export type Cc_Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cc_Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cc_profiles" */
export type Cc_Profiles_Aggregate_Fields = {
  __typename?: 'cc_profiles_aggregate_fields';
  avg?: Maybe<Cc_Profiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cc_Profiles_Max_Fields>;
  min?: Maybe<Cc_Profiles_Min_Fields>;
  stddev?: Maybe<Cc_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Cc_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cc_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Cc_Profiles_Sum_Fields>;
  var_pop?: Maybe<Cc_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Cc_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Cc_Profiles_Variance_Fields>;
};


/** aggregate fields of "cc_profiles" */
export type Cc_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cc_profiles" */
export type Cc_Profiles_Aggregate_Order_By = {
  avg?: InputMaybe<Cc_Profiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cc_Profiles_Max_Order_By>;
  min?: InputMaybe<Cc_Profiles_Min_Order_By>;
  stddev?: InputMaybe<Cc_Profiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cc_Profiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cc_Profiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cc_Profiles_Sum_Order_By>;
  var_pop?: InputMaybe<Cc_Profiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cc_Profiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Cc_Profiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cc_profiles" */
export type Cc_Profiles_Arr_Rel_Insert_Input = {
  data: Array<Cc_Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cc_Profiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Cc_Profiles_Avg_Fields = {
  __typename?: 'cc_profiles_avg_fields';
  ccProfileId?: Maybe<Scalars['Float']>;
  chainId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cc_profiles" */
export type Cc_Profiles_Avg_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cc_profiles". All fields are combined with a logical 'AND'. */
export type Cc_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Cc_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Cc_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Cc_Profiles_Bool_Exp>>;
  ccProfileId?: InputMaybe<Int_Comparison_Exp>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  handle?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  namespaceContract?: InputMaybe<String_Comparison_Exp>;
  namespaceName?: InputMaybe<String_Comparison_Exp>;
  ownerAddress?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cc_profiles" */
export enum Cc_Profiles_Constraint {
  /** unique or primary key constraint on columns "cc_profile_id" */
  CcProfilesCcProfileIdUnique = 'cc_profiles_cc_profile_id_unique',
  /** unique or primary key constraint on columns "handle" */
  CcProfilesHandleUnique = 'cc_profiles_handle_unique',
  /** unique or primary key constraint on columns "id" */
  CcProfilesPkey = 'cc_profiles_pkey'
}

/** input type for incrementing numeric columns in table "cc_profiles" */
export type Cc_Profiles_Inc_Input = {
  ccProfileId?: InputMaybe<Scalars['Int']>;
  chainId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "cc_profiles" */
export type Cc_Profiles_Insert_Input = {
  ccProfileId?: InputMaybe<Scalars['Int']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  namespaceContract?: InputMaybe<Scalars['String']>;
  namespaceName?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cc_Profiles_Max_Fields = {
  __typename?: 'cc_profiles_max_fields';
  ccProfileId?: Maybe<Scalars['Int']>;
  chainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  namespaceContract?: Maybe<Scalars['String']>;
  namespaceName?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "cc_profiles" */
export type Cc_Profiles_Max_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  namespaceContract?: InputMaybe<Order_By>;
  namespaceName?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cc_Profiles_Min_Fields = {
  __typename?: 'cc_profiles_min_fields';
  ccProfileId?: Maybe<Scalars['Int']>;
  chainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  namespaceContract?: Maybe<Scalars['String']>;
  namespaceName?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "cc_profiles" */
export type Cc_Profiles_Min_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  namespaceContract?: InputMaybe<Order_By>;
  namespaceName?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cc_profiles" */
export type Cc_Profiles_Mutation_Response = {
  __typename?: 'cc_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cc_Profiles>;
};

/** on_conflict condition type for table "cc_profiles" */
export type Cc_Profiles_On_Conflict = {
  constraint: Cc_Profiles_Constraint;
  update_columns?: Array<Cc_Profiles_Update_Column>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "cc_profiles". */
export type Cc_Profiles_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  namespaceContract?: InputMaybe<Order_By>;
  namespaceName?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cc_profiles */
export type Cc_Profiles_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "cc_profiles" */
export enum Cc_Profiles_Select_Column {
  /** column name */
  CcProfileId = 'ccProfileId',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  NamespaceContract = 'namespaceContract',
  /** column name */
  NamespaceName = 'namespaceName',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "cc_profiles" */
export type Cc_Profiles_Set_Input = {
  ccProfileId?: InputMaybe<Scalars['Int']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  namespaceContract?: InputMaybe<Scalars['String']>;
  namespaceName?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Cc_Profiles_Stddev_Fields = {
  __typename?: 'cc_profiles_stddev_fields';
  ccProfileId?: Maybe<Scalars['Float']>;
  chainId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cc_profiles" */
export type Cc_Profiles_Stddev_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cc_Profiles_Stddev_Pop_Fields = {
  __typename?: 'cc_profiles_stddev_pop_fields';
  ccProfileId?: Maybe<Scalars['Float']>;
  chainId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cc_profiles" */
export type Cc_Profiles_Stddev_Pop_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cc_Profiles_Stddev_Samp_Fields = {
  __typename?: 'cc_profiles_stddev_samp_fields';
  ccProfileId?: Maybe<Scalars['Float']>;
  chainId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cc_profiles" */
export type Cc_Profiles_Stddev_Samp_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cc_profiles" */
export type Cc_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cc_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cc_Profiles_Stream_Cursor_Value_Input = {
  ccProfileId?: InputMaybe<Scalars['Int']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  namespaceContract?: InputMaybe<Scalars['String']>;
  namespaceName?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Cc_Profiles_Sum_Fields = {
  __typename?: 'cc_profiles_sum_fields';
  ccProfileId?: Maybe<Scalars['Int']>;
  chainId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cc_profiles" */
export type Cc_Profiles_Sum_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** update columns of table "cc_profiles" */
export enum Cc_Profiles_Update_Column {
  /** column name */
  CcProfileId = 'ccProfileId',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  NamespaceContract = 'namespaceContract',
  /** column name */
  NamespaceName = 'namespaceName',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Cc_Profiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cc_Profiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cc_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cc_Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cc_Profiles_Var_Pop_Fields = {
  __typename?: 'cc_profiles_var_pop_fields';
  ccProfileId?: Maybe<Scalars['Float']>;
  chainId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cc_profiles" */
export type Cc_Profiles_Var_Pop_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cc_Profiles_Var_Samp_Fields = {
  __typename?: 'cc_profiles_var_samp_fields';
  ccProfileId?: Maybe<Scalars['Float']>;
  chainId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cc_profiles" */
export type Cc_Profiles_Var_Samp_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cc_Profiles_Variance_Fields = {
  __typename?: 'cc_profiles_variance_fields';
  ccProfileId?: Maybe<Scalars['Float']>;
  chainId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cc_profiles" */
export type Cc_Profiles_Variance_Order_By = {
  ccProfileId?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
};

/** columns and relationships of "cities" */
export type Cities = {
  __typename?: 'cities';
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  geometry?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  isoCode: Scalars['String'];
  nameEn: Scalars['String'];
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  /** An object relationship */
  subdivision: Subdivisions;
  subdivisionId: Scalars['String'];
};


/** columns and relationships of "cities" */
export type CitiesCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "cities" */
export type CitiesCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "cities" */
export type CitiesGeometryArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "cities" */
export type CitiesProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "cities" */
export type CitiesProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};

/** aggregated selection of "cities" */
export type Cities_Aggregate = {
  __typename?: 'cities_aggregate';
  aggregate?: Maybe<Cities_Aggregate_Fields>;
  nodes: Array<Cities>;
};

export type Cities_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cities_Aggregate_Bool_Exp_Count>;
};

export type Cities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cities" */
export type Cities_Aggregate_Fields = {
  __typename?: 'cities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cities_Max_Fields>;
  min?: Maybe<Cities_Min_Fields>;
};


/** aggregate fields of "cities" */
export type Cities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cities" */
export type Cities_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cities_Max_Order_By>;
  min?: InputMaybe<Cities_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cities_Append_Input = {
  geometry?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "cities" */
export type Cities_Arr_Rel_Insert_Input = {
  data: Array<Cities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "cities". All fields are combined with a logical 'AND'. */
export type Cities_Bool_Exp = {
  _and?: InputMaybe<Array<Cities_Bool_Exp>>;
  _not?: InputMaybe<Cities_Bool_Exp>;
  _or?: InputMaybe<Array<Cities_Bool_Exp>>;
  communities?: InputMaybe<Communities_Bool_Exp>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Bool_Exp>;
  geometry?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isoCode?: InputMaybe<String_Comparison_Exp>;
  nameEn?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Profiles_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Bool_Exp>;
  subdivision?: InputMaybe<Subdivisions_Bool_Exp>;
  subdivisionId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cities" */
export enum Cities_Constraint {
  /** unique or primary key constraint on columns "id" */
  CitiesPkey = 'cities_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cities_Delete_At_Path_Input = {
  geometry?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cities_Delete_Elem_Input = {
  geometry?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cities_Delete_Key_Input = {
  geometry?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "cities" */
export type Cities_Insert_Input = {
  communities?: InputMaybe<Communities_Arr_Rel_Insert_Input>;
  geometry?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Profiles_Arr_Rel_Insert_Input>;
  subdivision?: InputMaybe<Subdivisions_Obj_Rel_Insert_Input>;
  subdivisionId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cities_Max_Fields = {
  __typename?: 'cities_max_fields';
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  subdivisionId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cities" */
export type Cities_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
  subdivisionId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cities_Min_Fields = {
  __typename?: 'cities_min_fields';
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  subdivisionId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cities" */
export type Cities_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
  subdivisionId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cities" */
export type Cities_Mutation_Response = {
  __typename?: 'cities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cities>;
};

/** input type for inserting object relation for remote table "cities" */
export type Cities_Obj_Rel_Insert_Input = {
  data: Cities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Cities_On_Conflict>;
};

/** on_conflict condition type for table "cities" */
export type Cities_On_Conflict = {
  constraint: Cities_Constraint;
  update_columns?: Array<Cities_Update_Column>;
  where?: InputMaybe<Cities_Bool_Exp>;
};

/** Ordering options when selecting data from "cities". */
export type Cities_Order_By = {
  communities_aggregate?: InputMaybe<Communities_Aggregate_Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Order_By>;
  subdivision?: InputMaybe<Subdivisions_Order_By>;
  subdivisionId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cities */
export type Cities_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cities_Prepend_Input = {
  geometry?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "cities" */
export enum Cities_Select_Column {
  /** column name */
  Geometry = 'geometry',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  SubdivisionId = 'subdivisionId'
}

/** input type for updating data in table "cities" */
export type Cities_Set_Input = {
  geometry?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  subdivisionId?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "cities" */
export type Cities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cities_Stream_Cursor_Value_Input = {
  geometry?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  subdivisionId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cities" */
export enum Cities_Update_Column {
  /** column name */
  Geometry = 'geometry',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  SubdivisionId = 'subdivisionId'
}

export type Cities_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cities_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cities_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cities_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cities_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cities_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cities_Bool_Exp;
};

/** columns and relationships of "claimed_yield" */
export type Claimed_Yield = {
  __typename?: 'claimed_yield';
  createdAt: Scalars['timestamptz'];
  credYield: Scalars['Int'];
  credYieldBase?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  date: Scalars['date'];
  id: Scalars['uuid'];
  kind: Scalars['String'];
  /** An object relationship */
  nft?: Maybe<Connected_Nfts>;
  pointsYield: Scalars['Int'];
  pointsYieldBase?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  source_id?: Maybe<Scalars['Int']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenOrdinal?: Maybe<Scalars['Int']>;
  /** An object relationship */
  yieldSource?: Maybe<Yield_Sources>;
};

/** aggregated selection of "claimed_yield" */
export type Claimed_Yield_Aggregate = {
  __typename?: 'claimed_yield_aggregate';
  aggregate?: Maybe<Claimed_Yield_Aggregate_Fields>;
  nodes: Array<Claimed_Yield>;
};

export type Claimed_Yield_Aggregate_Bool_Exp = {
  count?: InputMaybe<Claimed_Yield_Aggregate_Bool_Exp_Count>;
};

export type Claimed_Yield_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Claimed_Yield_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "claimed_yield" */
export type Claimed_Yield_Aggregate_Fields = {
  __typename?: 'claimed_yield_aggregate_fields';
  avg?: Maybe<Claimed_Yield_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Claimed_Yield_Max_Fields>;
  min?: Maybe<Claimed_Yield_Min_Fields>;
  stddev?: Maybe<Claimed_Yield_Stddev_Fields>;
  stddev_pop?: Maybe<Claimed_Yield_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Claimed_Yield_Stddev_Samp_Fields>;
  sum?: Maybe<Claimed_Yield_Sum_Fields>;
  var_pop?: Maybe<Claimed_Yield_Var_Pop_Fields>;
  var_samp?: Maybe<Claimed_Yield_Var_Samp_Fields>;
  variance?: Maybe<Claimed_Yield_Variance_Fields>;
};


/** aggregate fields of "claimed_yield" */
export type Claimed_Yield_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "claimed_yield" */
export type Claimed_Yield_Aggregate_Order_By = {
  avg?: InputMaybe<Claimed_Yield_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Claimed_Yield_Max_Order_By>;
  min?: InputMaybe<Claimed_Yield_Min_Order_By>;
  stddev?: InputMaybe<Claimed_Yield_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Claimed_Yield_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Claimed_Yield_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Claimed_Yield_Sum_Order_By>;
  var_pop?: InputMaybe<Claimed_Yield_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Claimed_Yield_Var_Samp_Order_By>;
  variance?: InputMaybe<Claimed_Yield_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "claimed_yield" */
export type Claimed_Yield_Arr_Rel_Insert_Input = {
  data: Array<Claimed_Yield_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Claimed_Yield_On_Conflict>;
};

/** aggregate avg on columns */
export type Claimed_Yield_Avg_Fields = {
  __typename?: 'claimed_yield_avg_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldBase?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldBase?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
  tokenOrdinal?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "claimed_yield" */
export type Claimed_Yield_Avg_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "claimed_yield". All fields are combined with a logical 'AND'. */
export type Claimed_Yield_Bool_Exp = {
  _and?: InputMaybe<Array<Claimed_Yield_Bool_Exp>>;
  _not?: InputMaybe<Claimed_Yield_Bool_Exp>;
  _or?: InputMaybe<Array<Claimed_Yield_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credYield?: InputMaybe<Int_Comparison_Exp>;
  credYieldBase?: InputMaybe<Int_Comparison_Exp>;
  credYieldRatio?: InputMaybe<Float_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  nft?: InputMaybe<Connected_Nfts_Bool_Exp>;
  pointsYield?: InputMaybe<Int_Comparison_Exp>;
  pointsYieldBase?: InputMaybe<Int_Comparison_Exp>;
  pointsYieldRatio?: InputMaybe<Float_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  source_id?: InputMaybe<Int_Comparison_Exp>;
  tokenId?: InputMaybe<String_Comparison_Exp>;
  tokenOrdinal?: InputMaybe<Int_Comparison_Exp>;
  yieldSource?: InputMaybe<Yield_Sources_Bool_Exp>;
};

/** columns and relationships of "claimed_yield_by_project" */
export type Claimed_Yield_By_Project = {
  __typename?: 'claimed_yield_by_project';
  chainId?: Maybe<Scalars['Int']>;
  claimedAt?: Maybe<Scalars['timestamptz']>;
  contractAddress?: Maybe<Scalars['String']>;
  credYieldEach?: Maybe<Scalars['Int']>;
  credYieldTotal?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  /** An object relationship */
  nftProject?: Maybe<Verified_Nft_Projects>;
  pointsYieldEach?: Maybe<Scalars['Int']>;
  pointsYieldTotal?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  tokens?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "claimed_yield_by_project" */
export type Claimed_Yield_By_Project_Aggregate = {
  __typename?: 'claimed_yield_by_project_aggregate';
  aggregate?: Maybe<Claimed_Yield_By_Project_Aggregate_Fields>;
  nodes: Array<Claimed_Yield_By_Project>;
};

/** aggregate fields of "claimed_yield_by_project" */
export type Claimed_Yield_By_Project_Aggregate_Fields = {
  __typename?: 'claimed_yield_by_project_aggregate_fields';
  avg?: Maybe<Claimed_Yield_By_Project_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Claimed_Yield_By_Project_Max_Fields>;
  min?: Maybe<Claimed_Yield_By_Project_Min_Fields>;
  stddev?: Maybe<Claimed_Yield_By_Project_Stddev_Fields>;
  stddev_pop?: Maybe<Claimed_Yield_By_Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Claimed_Yield_By_Project_Stddev_Samp_Fields>;
  sum?: Maybe<Claimed_Yield_By_Project_Sum_Fields>;
  var_pop?: Maybe<Claimed_Yield_By_Project_Var_Pop_Fields>;
  var_samp?: Maybe<Claimed_Yield_By_Project_Var_Samp_Fields>;
  variance?: Maybe<Claimed_Yield_By_Project_Variance_Fields>;
};


/** aggregate fields of "claimed_yield_by_project" */
export type Claimed_Yield_By_Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Claimed_Yield_By_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Claimed_Yield_By_Project_Avg_Fields = {
  __typename?: 'claimed_yield_by_project_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldEach?: Maybe<Scalars['Float']>;
  credYieldTotal?: Maybe<Scalars['Float']>;
  pointsYieldEach?: Maybe<Scalars['Float']>;
  pointsYieldTotal?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "claimed_yield_by_project". All fields are combined with a logical 'AND'. */
export type Claimed_Yield_By_Project_Bool_Exp = {
  _and?: InputMaybe<Array<Claimed_Yield_By_Project_Bool_Exp>>;
  _not?: InputMaybe<Claimed_Yield_By_Project_Bool_Exp>;
  _or?: InputMaybe<Array<Claimed_Yield_By_Project_Bool_Exp>>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  claimedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  contractAddress?: InputMaybe<String_Comparison_Exp>;
  credYieldEach?: InputMaybe<Int_Comparison_Exp>;
  credYieldTotal?: InputMaybe<Int_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  nftProject?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  pointsYieldEach?: InputMaybe<Int_Comparison_Exp>;
  pointsYieldTotal?: InputMaybe<Int_Comparison_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  tokens?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Claimed_Yield_By_Project_Max_Fields = {
  __typename?: 'claimed_yield_by_project_max_fields';
  chainId?: Maybe<Scalars['Int']>;
  claimedAt?: Maybe<Scalars['timestamptz']>;
  contractAddress?: Maybe<Scalars['String']>;
  credYieldEach?: Maybe<Scalars['Int']>;
  credYieldTotal?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  pointsYieldEach?: Maybe<Scalars['Int']>;
  pointsYieldTotal?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  tokens?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Claimed_Yield_By_Project_Min_Fields = {
  __typename?: 'claimed_yield_by_project_min_fields';
  chainId?: Maybe<Scalars['Int']>;
  claimedAt?: Maybe<Scalars['timestamptz']>;
  contractAddress?: Maybe<Scalars['String']>;
  credYieldEach?: Maybe<Scalars['Int']>;
  credYieldTotal?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  pointsYieldEach?: Maybe<Scalars['Int']>;
  pointsYieldTotal?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  tokens?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "claimed_yield_by_project". */
export type Claimed_Yield_By_Project_Order_By = {
  chainId?: InputMaybe<Order_By>;
  claimedAt?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  credYieldEach?: InputMaybe<Order_By>;
  credYieldTotal?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  nftProject?: InputMaybe<Verified_Nft_Projects_Order_By>;
  pointsYieldEach?: InputMaybe<Order_By>;
  pointsYieldTotal?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  tokens?: InputMaybe<Order_By>;
};

/** select columns of table "claimed_yield_by_project" */
export enum Claimed_Yield_By_Project_Select_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  ClaimedAt = 'claimedAt',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CredYieldEach = 'credYieldEach',
  /** column name */
  CredYieldTotal = 'credYieldTotal',
  /** column name */
  Date = 'date',
  /** column name */
  PointsYieldEach = 'pointsYieldEach',
  /** column name */
  PointsYieldTotal = 'pointsYieldTotal',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Tokens = 'tokens'
}

/** aggregate stddev on columns */
export type Claimed_Yield_By_Project_Stddev_Fields = {
  __typename?: 'claimed_yield_by_project_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldEach?: Maybe<Scalars['Float']>;
  credYieldTotal?: Maybe<Scalars['Float']>;
  pointsYieldEach?: Maybe<Scalars['Float']>;
  pointsYieldTotal?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Claimed_Yield_By_Project_Stddev_Pop_Fields = {
  __typename?: 'claimed_yield_by_project_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldEach?: Maybe<Scalars['Float']>;
  credYieldTotal?: Maybe<Scalars['Float']>;
  pointsYieldEach?: Maybe<Scalars['Float']>;
  pointsYieldTotal?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Claimed_Yield_By_Project_Stddev_Samp_Fields = {
  __typename?: 'claimed_yield_by_project_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldEach?: Maybe<Scalars['Float']>;
  credYieldTotal?: Maybe<Scalars['Float']>;
  pointsYieldEach?: Maybe<Scalars['Float']>;
  pointsYieldTotal?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "claimed_yield_by_project" */
export type Claimed_Yield_By_Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Claimed_Yield_By_Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Claimed_Yield_By_Project_Stream_Cursor_Value_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  claimedAt?: InputMaybe<Scalars['timestamptz']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  credYieldEach?: InputMaybe<Scalars['Int']>;
  credYieldTotal?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['date']>;
  pointsYieldEach?: InputMaybe<Scalars['Int']>;
  pointsYieldTotal?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  tokens?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Claimed_Yield_By_Project_Sum_Fields = {
  __typename?: 'claimed_yield_by_project_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  credYieldEach?: Maybe<Scalars['Int']>;
  credYieldTotal?: Maybe<Scalars['Int']>;
  pointsYieldEach?: Maybe<Scalars['Int']>;
  pointsYieldTotal?: Maybe<Scalars['Int']>;
  tokens?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Claimed_Yield_By_Project_Var_Pop_Fields = {
  __typename?: 'claimed_yield_by_project_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldEach?: Maybe<Scalars['Float']>;
  credYieldTotal?: Maybe<Scalars['Float']>;
  pointsYieldEach?: Maybe<Scalars['Float']>;
  pointsYieldTotal?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Claimed_Yield_By_Project_Var_Samp_Fields = {
  __typename?: 'claimed_yield_by_project_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldEach?: Maybe<Scalars['Float']>;
  credYieldTotal?: Maybe<Scalars['Float']>;
  pointsYieldEach?: Maybe<Scalars['Float']>;
  pointsYieldTotal?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Claimed_Yield_By_Project_Variance_Fields = {
  __typename?: 'claimed_yield_by_project_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldEach?: Maybe<Scalars['Float']>;
  credYieldTotal?: Maybe<Scalars['Float']>;
  pointsYieldEach?: Maybe<Scalars['Float']>;
  pointsYieldTotal?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "claimed_yield" */
export enum Claimed_Yield_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClaimedYieldPkey = 'claimed_yield_pkey',
  /** unique or primary key constraint on columns "profile_id", "token_id", "date" */
  ClaimedYieldUniqueToken = 'claimed_yield_unique_token'
}

/** input type for incrementing numeric columns in table "claimed_yield" */
export type Claimed_Yield_Inc_Input = {
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldBase?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldBase?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  source_id?: InputMaybe<Scalars['Int']>;
  tokenOrdinal?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "claimed_yield" */
export type Claimed_Yield_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldBase?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  nft?: InputMaybe<Connected_Nfts_Obj_Rel_Insert_Input>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldBase?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['Int']>;
  tokenId?: InputMaybe<Scalars['String']>;
  tokenOrdinal?: InputMaybe<Scalars['Int']>;
  yieldSource?: InputMaybe<Yield_Sources_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Claimed_Yield_Max_Fields = {
  __typename?: 'claimed_yield_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYield?: Maybe<Scalars['Int']>;
  credYieldBase?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  pointsYield?: Maybe<Scalars['Int']>;
  pointsYieldBase?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['Int']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenOrdinal?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "claimed_yield" */
export type Claimed_Yield_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Claimed_Yield_Min_Fields = {
  __typename?: 'claimed_yield_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYield?: Maybe<Scalars['Int']>;
  credYieldBase?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  pointsYield?: Maybe<Scalars['Int']>;
  pointsYieldBase?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['Int']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenOrdinal?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "claimed_yield" */
export type Claimed_Yield_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "claimed_yield" */
export type Claimed_Yield_Mutation_Response = {
  __typename?: 'claimed_yield_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Claimed_Yield>;
};

/** on_conflict condition type for table "claimed_yield" */
export type Claimed_Yield_On_Conflict = {
  constraint: Claimed_Yield_Constraint;
  update_columns?: Array<Claimed_Yield_Update_Column>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};

/** Ordering options when selecting data from "claimed_yield". */
export type Claimed_Yield_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  nft?: InputMaybe<Connected_Nfts_Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
  yieldSource?: InputMaybe<Yield_Sources_Order_By>;
};

/** primary key columns input for table: claimed_yield */
export type Claimed_Yield_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "claimed_yield" */
export enum Claimed_Yield_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredYield = 'credYield',
  /** column name */
  CredYieldBase = 'credYieldBase',
  /** column name */
  CredYieldRatio = 'credYieldRatio',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  PointsYield = 'pointsYield',
  /** column name */
  PointsYieldBase = 'pointsYieldBase',
  /** column name */
  PointsYieldRatio = 'pointsYieldRatio',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  TokenOrdinal = 'tokenOrdinal'
}

/** input type for updating data in table "claimed_yield" */
export type Claimed_Yield_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldBase?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldBase?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  profileId?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['Int']>;
  tokenId?: InputMaybe<Scalars['String']>;
  tokenOrdinal?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Claimed_Yield_Stddev_Fields = {
  __typename?: 'claimed_yield_stddev_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldBase?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldBase?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
  tokenOrdinal?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "claimed_yield" */
export type Claimed_Yield_Stddev_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Claimed_Yield_Stddev_Pop_Fields = {
  __typename?: 'claimed_yield_stddev_pop_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldBase?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldBase?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
  tokenOrdinal?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "claimed_yield" */
export type Claimed_Yield_Stddev_Pop_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Claimed_Yield_Stddev_Samp_Fields = {
  __typename?: 'claimed_yield_stddev_samp_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldBase?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldBase?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
  tokenOrdinal?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "claimed_yield" */
export type Claimed_Yield_Stddev_Samp_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "claimed_yield" */
export type Claimed_Yield_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Claimed_Yield_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Claimed_Yield_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldBase?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldBase?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  profileId?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['Int']>;
  tokenId?: InputMaybe<Scalars['String']>;
  tokenOrdinal?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Claimed_Yield_Sum_Fields = {
  __typename?: 'claimed_yield_sum_fields';
  credYield?: Maybe<Scalars['Int']>;
  credYieldBase?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Int']>;
  pointsYieldBase?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Int']>;
  tokenOrdinal?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "claimed_yield" */
export type Claimed_Yield_Sum_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** update columns of table "claimed_yield" */
export enum Claimed_Yield_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredYield = 'credYield',
  /** column name */
  CredYieldBase = 'credYieldBase',
  /** column name */
  CredYieldRatio = 'credYieldRatio',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  PointsYield = 'pointsYield',
  /** column name */
  PointsYieldBase = 'pointsYieldBase',
  /** column name */
  PointsYieldRatio = 'pointsYieldRatio',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  TokenOrdinal = 'tokenOrdinal'
}

export type Claimed_Yield_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Claimed_Yield_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Claimed_Yield_Set_Input>;
  /** filter the rows which have to be updated */
  where: Claimed_Yield_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Claimed_Yield_Var_Pop_Fields = {
  __typename?: 'claimed_yield_var_pop_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldBase?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldBase?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
  tokenOrdinal?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "claimed_yield" */
export type Claimed_Yield_Var_Pop_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Claimed_Yield_Var_Samp_Fields = {
  __typename?: 'claimed_yield_var_samp_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldBase?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldBase?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
  tokenOrdinal?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "claimed_yield" */
export type Claimed_Yield_Var_Samp_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Claimed_Yield_Variance_Fields = {
  __typename?: 'claimed_yield_variance_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldBase?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldBase?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
  tokenOrdinal?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "claimed_yield" */
export type Claimed_Yield_Variance_Order_By = {
  credYield?: InputMaybe<Order_By>;
  credYieldBase?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldBase?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tokenOrdinal?: InputMaybe<Order_By>;
};

/** columns and relationships of "comment_aggregates" */
export type Comment_Aggregates = {
  __typename?: 'comment_aggregates';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  recommendation_comment: Recommendation_Comments;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  votes: Scalars['Int'];
};

/** aggregated selection of "comment_aggregates" */
export type Comment_Aggregates_Aggregate = {
  __typename?: 'comment_aggregates_aggregate';
  aggregate?: Maybe<Comment_Aggregates_Aggregate_Fields>;
  nodes: Array<Comment_Aggregates>;
};

/** aggregate fields of "comment_aggregates" */
export type Comment_Aggregates_Aggregate_Fields = {
  __typename?: 'comment_aggregates_aggregate_fields';
  avg?: Maybe<Comment_Aggregates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Comment_Aggregates_Max_Fields>;
  min?: Maybe<Comment_Aggregates_Min_Fields>;
  stddev?: Maybe<Comment_Aggregates_Stddev_Fields>;
  stddev_pop?: Maybe<Comment_Aggregates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comment_Aggregates_Stddev_Samp_Fields>;
  sum?: Maybe<Comment_Aggregates_Sum_Fields>;
  var_pop?: Maybe<Comment_Aggregates_Var_Pop_Fields>;
  var_samp?: Maybe<Comment_Aggregates_Var_Samp_Fields>;
  variance?: Maybe<Comment_Aggregates_Variance_Fields>;
};


/** aggregate fields of "comment_aggregates" */
export type Comment_Aggregates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Aggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Comment_Aggregates_Avg_Fields = {
  __typename?: 'comment_aggregates_avg_fields';
  votes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "comment_aggregates". All fields are combined with a logical 'AND'. */
export type Comment_Aggregates_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Aggregates_Bool_Exp>>;
  _not?: InputMaybe<Comment_Aggregates_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Aggregates_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  recommendation_comment?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  votes?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_aggregates" */
export enum Comment_Aggregates_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentAggregatesPkey = 'comment_aggregates_pkey'
}

/** input type for incrementing numeric columns in table "comment_aggregates" */
export type Comment_Aggregates_Inc_Input = {
  votes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "comment_aggregates" */
export type Comment_Aggregates_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  recommendation_comment?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Comment_Aggregates_Max_Fields = {
  __typename?: 'comment_aggregates_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  votes?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Comment_Aggregates_Min_Fields = {
  __typename?: 'comment_aggregates_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  votes?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "comment_aggregates" */
export type Comment_Aggregates_Mutation_Response = {
  __typename?: 'comment_aggregates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_Aggregates>;
};

/** input type for inserting object relation for remote table "comment_aggregates" */
export type Comment_Aggregates_Obj_Rel_Insert_Input = {
  data: Comment_Aggregates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Aggregates_On_Conflict>;
};

/** on_conflict condition type for table "comment_aggregates" */
export type Comment_Aggregates_On_Conflict = {
  constraint: Comment_Aggregates_Constraint;
  update_columns?: Array<Comment_Aggregates_Update_Column>;
  where?: InputMaybe<Comment_Aggregates_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_aggregates". */
export type Comment_Aggregates_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recommendation_comment?: InputMaybe<Recommendation_Comments_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  votes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_aggregates */
export type Comment_Aggregates_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "comment_aggregates" */
export enum Comment_Aggregates_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Votes = 'votes'
}

/** input type for updating data in table "comment_aggregates" */
export type Comment_Aggregates_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Comment_Aggregates_Stddev_Fields = {
  __typename?: 'comment_aggregates_stddev_fields';
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Comment_Aggregates_Stddev_Pop_Fields = {
  __typename?: 'comment_aggregates_stddev_pop_fields';
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Comment_Aggregates_Stddev_Samp_Fields = {
  __typename?: 'comment_aggregates_stddev_samp_fields';
  votes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "comment_aggregates" */
export type Comment_Aggregates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Aggregates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Aggregates_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Comment_Aggregates_Sum_Fields = {
  __typename?: 'comment_aggregates_sum_fields';
  votes?: Maybe<Scalars['Int']>;
};

/** update columns of table "comment_aggregates" */
export enum Comment_Aggregates_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Votes = 'votes'
}

export type Comment_Aggregates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Comment_Aggregates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Aggregates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Aggregates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Comment_Aggregates_Var_Pop_Fields = {
  __typename?: 'comment_aggregates_var_pop_fields';
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Comment_Aggregates_Var_Samp_Fields = {
  __typename?: 'comment_aggregates_var_samp_fields';
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Comment_Aggregates_Variance_Fields = {
  __typename?: 'comment_aggregates_variance_fields';
  votes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "comment_profile_tags" */
export type Comment_Profile_Tags = {
  __typename?: 'comment_profile_tags';
  /** An object relationship */
  comment: Recommendation_Comments;
  commentId: Scalars['String'];
  extFcName?: Maybe<Scalars['String']>;
  extFcTokenId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  matchedText: Scalars['String'];
  originalText: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profiles>;
  profileId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "comment_profile_tags" */
export type Comment_Profile_Tags_Aggregate = {
  __typename?: 'comment_profile_tags_aggregate';
  aggregate?: Maybe<Comment_Profile_Tags_Aggregate_Fields>;
  nodes: Array<Comment_Profile_Tags>;
};

export type Comment_Profile_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Comment_Profile_Tags_Aggregate_Bool_Exp_Count>;
};

export type Comment_Profile_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comment_profile_tags" */
export type Comment_Profile_Tags_Aggregate_Fields = {
  __typename?: 'comment_profile_tags_aggregate_fields';
  avg?: Maybe<Comment_Profile_Tags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Comment_Profile_Tags_Max_Fields>;
  min?: Maybe<Comment_Profile_Tags_Min_Fields>;
  stddev?: Maybe<Comment_Profile_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Comment_Profile_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comment_Profile_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Comment_Profile_Tags_Sum_Fields>;
  var_pop?: Maybe<Comment_Profile_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Comment_Profile_Tags_Var_Samp_Fields>;
  variance?: Maybe<Comment_Profile_Tags_Variance_Fields>;
};


/** aggregate fields of "comment_profile_tags" */
export type Comment_Profile_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "comment_profile_tags" */
export type Comment_Profile_Tags_Aggregate_Order_By = {
  avg?: InputMaybe<Comment_Profile_Tags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Profile_Tags_Max_Order_By>;
  min?: InputMaybe<Comment_Profile_Tags_Min_Order_By>;
  stddev?: InputMaybe<Comment_Profile_Tags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Comment_Profile_Tags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Comment_Profile_Tags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Comment_Profile_Tags_Sum_Order_By>;
  var_pop?: InputMaybe<Comment_Profile_Tags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Comment_Profile_Tags_Var_Samp_Order_By>;
  variance?: InputMaybe<Comment_Profile_Tags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comment_profile_tags" */
export type Comment_Profile_Tags_Arr_Rel_Insert_Input = {
  data: Array<Comment_Profile_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Profile_Tags_On_Conflict>;
};

/** aggregate avg on columns */
export type Comment_Profile_Tags_Avg_Fields = {
  __typename?: 'comment_profile_tags_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comment_profile_tags". All fields are combined with a logical 'AND'. */
export type Comment_Profile_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Profile_Tags_Bool_Exp>>;
  _not?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Profile_Tags_Bool_Exp>>;
  comment?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  commentId?: InputMaybe<String_Comparison_Exp>;
  extFcName?: InputMaybe<String_Comparison_Exp>;
  extFcTokenId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  matchedText?: InputMaybe<String_Comparison_Exp>;
  originalText?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_profile_tags" */
export enum Comment_Profile_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentProfileTagsPkey = 'comment_profile_tags_pkey'
}

/** input type for incrementing numeric columns in table "comment_profile_tags" */
export type Comment_Profile_Tags_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "comment_profile_tags" */
export type Comment_Profile_Tags_Insert_Input = {
  comment?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  commentId?: InputMaybe<Scalars['String']>;
  extFcName?: InputMaybe<Scalars['String']>;
  extFcTokenId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  matchedText?: InputMaybe<Scalars['String']>;
  originalText?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Comment_Profile_Tags_Max_Fields = {
  __typename?: 'comment_profile_tags_max_fields';
  commentId?: Maybe<Scalars['String']>;
  extFcName?: Maybe<Scalars['String']>;
  extFcTokenId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  matchedText?: Maybe<Scalars['String']>;
  originalText?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Max_Order_By = {
  commentId?: InputMaybe<Order_By>;
  extFcName?: InputMaybe<Order_By>;
  extFcTokenId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matchedText?: InputMaybe<Order_By>;
  originalText?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Profile_Tags_Min_Fields = {
  __typename?: 'comment_profile_tags_min_fields';
  commentId?: Maybe<Scalars['String']>;
  extFcName?: Maybe<Scalars['String']>;
  extFcTokenId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  matchedText?: Maybe<Scalars['String']>;
  originalText?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Min_Order_By = {
  commentId?: InputMaybe<Order_By>;
  extFcName?: InputMaybe<Order_By>;
  extFcTokenId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matchedText?: InputMaybe<Order_By>;
  originalText?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_profile_tags" */
export type Comment_Profile_Tags_Mutation_Response = {
  __typename?: 'comment_profile_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_Profile_Tags>;
};

/** on_conflict condition type for table "comment_profile_tags" */
export type Comment_Profile_Tags_On_Conflict = {
  constraint: Comment_Profile_Tags_Constraint;
  update_columns?: Array<Comment_Profile_Tags_Update_Column>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_profile_tags". */
export type Comment_Profile_Tags_Order_By = {
  comment?: InputMaybe<Recommendation_Comments_Order_By>;
  commentId?: InputMaybe<Order_By>;
  extFcName?: InputMaybe<Order_By>;
  extFcTokenId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matchedText?: InputMaybe<Order_By>;
  originalText?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_profile_tags */
export type Comment_Profile_Tags_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "comment_profile_tags" */
export enum Comment_Profile_Tags_Select_Column {
  /** column name */
  CommentId = 'commentId',
  /** column name */
  ExtFcName = 'extFcName',
  /** column name */
  ExtFcTokenId = 'extFcTokenId',
  /** column name */
  Id = 'id',
  /** column name */
  MatchedText = 'matchedText',
  /** column name */
  OriginalText = 'originalText',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "comment_profile_tags" */
export type Comment_Profile_Tags_Set_Input = {
  commentId?: InputMaybe<Scalars['String']>;
  extFcName?: InputMaybe<Scalars['String']>;
  extFcTokenId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  matchedText?: InputMaybe<Scalars['String']>;
  originalText?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Comment_Profile_Tags_Stddev_Fields = {
  __typename?: 'comment_profile_tags_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comment_Profile_Tags_Stddev_Pop_Fields = {
  __typename?: 'comment_profile_tags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comment_Profile_Tags_Stddev_Samp_Fields = {
  __typename?: 'comment_profile_tags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "comment_profile_tags" */
export type Comment_Profile_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Profile_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Profile_Tags_Stream_Cursor_Value_Input = {
  commentId?: InputMaybe<Scalars['String']>;
  extFcName?: InputMaybe<Scalars['String']>;
  extFcTokenId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  matchedText?: InputMaybe<Scalars['String']>;
  originalText?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Comment_Profile_Tags_Sum_Fields = {
  __typename?: 'comment_profile_tags_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "comment_profile_tags" */
export enum Comment_Profile_Tags_Update_Column {
  /** column name */
  CommentId = 'commentId',
  /** column name */
  ExtFcName = 'extFcName',
  /** column name */
  ExtFcTokenId = 'extFcTokenId',
  /** column name */
  Id = 'id',
  /** column name */
  MatchedText = 'matchedText',
  /** column name */
  OriginalText = 'originalText',
  /** column name */
  ProfileId = 'profileId'
}

export type Comment_Profile_Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Comment_Profile_Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Profile_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Profile_Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Comment_Profile_Tags_Var_Pop_Fields = {
  __typename?: 'comment_profile_tags_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comment_Profile_Tags_Var_Samp_Fields = {
  __typename?: 'comment_profile_tags_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Comment_Profile_Tags_Variance_Fields = {
  __typename?: 'comment_profile_tags_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "comment_profile_tags" */
export type Comment_Profile_Tags_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "comment_votes" */
export type Comment_Votes = {
  __typename?: 'comment_votes';
  /** An object relationship */
  comment: Recommendation_Comments;
  commentId: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  lensReaction?: Maybe<Lens_Reactions>;
  lensReactionId?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "comment_votes" */
export type Comment_Votes_Aggregate = {
  __typename?: 'comment_votes_aggregate';
  aggregate?: Maybe<Comment_Votes_Aggregate_Fields>;
  nodes: Array<Comment_Votes>;
};

export type Comment_Votes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Comment_Votes_Aggregate_Bool_Exp_Count>;
};

export type Comment_Votes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Comment_Votes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comment_votes" */
export type Comment_Votes_Aggregate_Fields = {
  __typename?: 'comment_votes_aggregate_fields';
  avg?: Maybe<Comment_Votes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Comment_Votes_Max_Fields>;
  min?: Maybe<Comment_Votes_Min_Fields>;
  stddev?: Maybe<Comment_Votes_Stddev_Fields>;
  stddev_pop?: Maybe<Comment_Votes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comment_Votes_Stddev_Samp_Fields>;
  sum?: Maybe<Comment_Votes_Sum_Fields>;
  var_pop?: Maybe<Comment_Votes_Var_Pop_Fields>;
  var_samp?: Maybe<Comment_Votes_Var_Samp_Fields>;
  variance?: Maybe<Comment_Votes_Variance_Fields>;
};


/** aggregate fields of "comment_votes" */
export type Comment_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "comment_votes" */
export type Comment_Votes_Aggregate_Order_By = {
  avg?: InputMaybe<Comment_Votes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Votes_Max_Order_By>;
  min?: InputMaybe<Comment_Votes_Min_Order_By>;
  stddev?: InputMaybe<Comment_Votes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Comment_Votes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Comment_Votes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Comment_Votes_Sum_Order_By>;
  var_pop?: InputMaybe<Comment_Votes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Comment_Votes_Var_Samp_Order_By>;
  variance?: InputMaybe<Comment_Votes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comment_votes" */
export type Comment_Votes_Arr_Rel_Insert_Input = {
  data: Array<Comment_Votes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Votes_On_Conflict>;
};

/** aggregate avg on columns */
export type Comment_Votes_Avg_Fields = {
  __typename?: 'comment_votes_avg_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "comment_votes" */
export type Comment_Votes_Avg_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comment_votes". All fields are combined with a logical 'AND'. */
export type Comment_Votes_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Votes_Bool_Exp>>;
  _not?: InputMaybe<Comment_Votes_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Votes_Bool_Exp>>;
  comment?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  commentId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  farcaster_reaction_id?: InputMaybe<Int_Comparison_Exp>;
  lensReaction?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lensReactionId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_votes" */
export enum Comment_Votes_Constraint {
  /** unique or primary key constraint on columns "lens_reaction_id" */
  CommentVotesLensReactionIdUnique = 'comment_votes_lens_reaction_id_unique',
  /** unique or primary key constraint on columns "comment_id", "profile_id" */
  CommentVotesPkey = 'comment_votes_pkey'
}

/** input type for incrementing numeric columns in table "comment_votes" */
export type Comment_Votes_Inc_Input = {
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "comment_votes" */
export type Comment_Votes_Insert_Input = {
  comment?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  lensReaction?: InputMaybe<Lens_Reactions_Obj_Rel_Insert_Input>;
  lensReactionId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Comment_Votes_Max_Fields = {
  __typename?: 'comment_votes_max_fields';
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  lensReactionId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "comment_votes" */
export type Comment_Votes_Max_Order_By = {
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  farcaster_reaction_id?: InputMaybe<Order_By>;
  lensReactionId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Votes_Min_Fields = {
  __typename?: 'comment_votes_min_fields';
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  lensReactionId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "comment_votes" */
export type Comment_Votes_Min_Order_By = {
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  farcaster_reaction_id?: InputMaybe<Order_By>;
  lensReactionId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_votes" */
export type Comment_Votes_Mutation_Response = {
  __typename?: 'comment_votes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_Votes>;
};

/** input type for inserting object relation for remote table "comment_votes" */
export type Comment_Votes_Obj_Rel_Insert_Input = {
  data: Comment_Votes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Votes_On_Conflict>;
};

/** on_conflict condition type for table "comment_votes" */
export type Comment_Votes_On_Conflict = {
  constraint: Comment_Votes_Constraint;
  update_columns?: Array<Comment_Votes_Update_Column>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_votes". */
export type Comment_Votes_Order_By = {
  comment?: InputMaybe<Recommendation_Comments_Order_By>;
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  farcaster_reaction_id?: InputMaybe<Order_By>;
  lensReaction?: InputMaybe<Lens_Reactions_Order_By>;
  lensReactionId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_votes */
export type Comment_Votes_Pk_Columns_Input = {
  commentId: Scalars['String'];
  profileId: Scalars['String'];
};

/** select columns of table "comment_votes" */
export enum Comment_Votes_Select_Column {
  /** column name */
  CommentId = 'commentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FarcasterReactionId = 'farcaster_reaction_id',
  /** column name */
  LensReactionId = 'lensReactionId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "comment_votes" */
export type Comment_Votes_Set_Input = {
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  lensReactionId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Comment_Votes_Stddev_Fields = {
  __typename?: 'comment_votes_stddev_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "comment_votes" */
export type Comment_Votes_Stddev_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comment_Votes_Stddev_Pop_Fields = {
  __typename?: 'comment_votes_stddev_pop_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "comment_votes" */
export type Comment_Votes_Stddev_Pop_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comment_Votes_Stddev_Samp_Fields = {
  __typename?: 'comment_votes_stddev_samp_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "comment_votes" */
export type Comment_Votes_Stddev_Samp_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "comment_votes" */
export type Comment_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Votes_Stream_Cursor_Value_Input = {
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  lensReactionId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Comment_Votes_Sum_Fields = {
  __typename?: 'comment_votes_sum_fields';
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "comment_votes" */
export type Comment_Votes_Sum_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "comment_votes" */
export enum Comment_Votes_Update_Column {
  /** column name */
  CommentId = 'commentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FarcasterReactionId = 'farcaster_reaction_id',
  /** column name */
  LensReactionId = 'lensReactionId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type Comment_Votes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Comment_Votes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Votes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Votes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Comment_Votes_Var_Pop_Fields = {
  __typename?: 'comment_votes_var_pop_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "comment_votes" */
export type Comment_Votes_Var_Pop_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comment_Votes_Var_Samp_Fields = {
  __typename?: 'comment_votes_var_samp_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "comment_votes" */
export type Comment_Votes_Var_Samp_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Comment_Votes_Variance_Fields = {
  __typename?: 'comment_votes_variance_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "comment_votes" */
export type Comment_Votes_Variance_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "communities" */
export type Communities = {
  __typename?: 'communities';
  /** An object relationship */
  city?: Maybe<Cities>;
  cityId?: Maybe<Scalars['String']>;
  /** An object relationship */
  communityAggregates?: Maybe<Community_Aggregates>;
  /** An object relationship */
  communityCategory: Community_Categories;
  communityCategoryId: Scalars['String'];
  /** An array relationship */
  communityMembers: Array<Community_Members>;
  /** An aggregate relationship */
  communityMembers_aggregate: Community_Members_Aggregate;
  /** An object relationship */
  community_staffpick?: Maybe<Community_Staffpicks>;
  /** An object relationship */
  continent?: Maybe<Continents>;
  continentId?: Maybe<Scalars['String']>;
  /** An object relationship */
  country?: Maybe<Countries>;
  countryId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  isHidden: Scalars['Boolean'];
  isMember?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_user_community_moderator" */
  isModerator?: Maybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  language: Language_Isocodes_Enum;
  /** An object relationship */
  language_isocode: Language_Isocodes;
  /** An array relationship */
  lens_appid_mappings: Array<Lens_Appid_Mappings>;
  /** An aggregate relationship */
  lens_appid_mappings_aggregate: Lens_Appid_Mappings_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  nftProjects: Array<Community_Nft_Projects>;
  /** An aggregate relationship */
  nftProjects_aggregate: Community_Nft_Projects_Aggregate;
  /** An array relationship */
  posts: Array<Recommendations>;
  /** An aggregate relationship */
  posts_aggregate: Recommendations_Aggregate;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  publishedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  rss_feeds: Array<Rss_Feeds>;
  /** An aggregate relationship */
  rss_feeds_aggregate: Rss_Feeds_Aggregate;
  sb_at?: Maybe<Scalars['timestamptz']>;
  shortDescription: Scalars['String'];
  /** An object relationship */
  subdivision?: Maybe<Subdivisions>;
  subdivisionId?: Maybe<Scalars['String']>;
  /** An object relationship */
  thumbnailImage?: Maybe<Images>;
  thumbnailImageId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  verifiedNftProjects: Array<Community_Nft_Projects>;
  /** An aggregate relationship */
  verifiedNftProjects_aggregate: Community_Nft_Projects_Aggregate;
};


/** columns and relationships of "communities" */
export type CommunitiesCommunityMembersArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesCommunityMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesLens_Appid_MappingsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Appid_Mappings_Order_By>>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesLens_Appid_Mappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Appid_Mappings_Order_By>>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesNftProjectsArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesNftProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesPostsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesRss_FeedsArgs = {
  distinct_on?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rss_Feeds_Order_By>>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesRss_Feeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rss_Feeds_Order_By>>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesVerifiedNftProjectsArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


/** columns and relationships of "communities" */
export type CommunitiesVerifiedNftProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};

/** aggregated selection of "communities" */
export type Communities_Aggregate = {
  __typename?: 'communities_aggregate';
  aggregate?: Maybe<Communities_Aggregate_Fields>;
  nodes: Array<Communities>;
};

export type Communities_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Communities_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Communities_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Communities_Aggregate_Bool_Exp_Count>;
};

export type Communities_Aggregate_Bool_Exp_Bool_And = {
  arguments: Communities_Select_Column_Communities_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Communities_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Communities_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Communities_Select_Column_Communities_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Communities_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Communities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Communities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Communities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "communities" */
export type Communities_Aggregate_Fields = {
  __typename?: 'communities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Communities_Max_Fields>;
  min?: Maybe<Communities_Min_Fields>;
};


/** aggregate fields of "communities" */
export type Communities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "communities" */
export type Communities_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Communities_Max_Order_By>;
  min?: InputMaybe<Communities_Min_Order_By>;
};

/** input type for inserting array relation for remote table "communities" */
export type Communities_Arr_Rel_Insert_Input = {
  data: Array<Communities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Communities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "communities". All fields are combined with a logical 'AND'. */
export type Communities_Bool_Exp = {
  _and?: InputMaybe<Array<Communities_Bool_Exp>>;
  _not?: InputMaybe<Communities_Bool_Exp>;
  _or?: InputMaybe<Array<Communities_Bool_Exp>>;
  city?: InputMaybe<Cities_Bool_Exp>;
  cityId?: InputMaybe<String_Comparison_Exp>;
  communityAggregates?: InputMaybe<Community_Aggregates_Bool_Exp>;
  communityCategory?: InputMaybe<Community_Categories_Bool_Exp>;
  communityCategoryId?: InputMaybe<String_Comparison_Exp>;
  communityMembers?: InputMaybe<Community_Members_Bool_Exp>;
  communityMembers_aggregate?: InputMaybe<Community_Members_Aggregate_Bool_Exp>;
  community_staffpick?: InputMaybe<Community_Staffpicks_Bool_Exp>;
  continent?: InputMaybe<Continents_Bool_Exp>;
  continentId?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Countries_Bool_Exp>;
  countryId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  isHidden?: InputMaybe<Boolean_Comparison_Exp>;
  isMember?: InputMaybe<Boolean_Comparison_Exp>;
  isModerator?: InputMaybe<Boolean_Comparison_Exp>;
  isPrivate?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<Language_Isocodes_Enum_Comparison_Exp>;
  language_isocode?: InputMaybe<Language_Isocodes_Bool_Exp>;
  lens_appid_mappings?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
  lens_appid_mappings_aggregate?: InputMaybe<Lens_Appid_Mappings_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nftProjects?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
  nftProjects_aggregate?: InputMaybe<Community_Nft_Projects_Aggregate_Bool_Exp>;
  posts?: InputMaybe<Recommendations_Bool_Exp>;
  posts_aggregate?: InputMaybe<Recommendations_Aggregate_Bool_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  publishedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  rss_feeds?: InputMaybe<Rss_Feeds_Bool_Exp>;
  rss_feeds_aggregate?: InputMaybe<Rss_Feeds_Aggregate_Bool_Exp>;
  sb_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  shortDescription?: InputMaybe<String_Comparison_Exp>;
  subdivision?: InputMaybe<Subdivisions_Bool_Exp>;
  subdivisionId?: InputMaybe<String_Comparison_Exp>;
  thumbnailImage?: InputMaybe<Images_Bool_Exp>;
  thumbnailImageId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  verifiedNftProjects?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
  verifiedNftProjects_aggregate?: InputMaybe<Community_Nft_Projects_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "communities" */
export enum Communities_Constraint {
  /** unique or primary key constraint on columns  */
  CommunitiesNameUnique = 'communities_name_unique',
  /** unique or primary key constraint on columns "id" */
  CommunitiesPkey = 'communities_pkey'
}

/** input type for inserting data into table "communities" */
export type Communities_Insert_Input = {
  city?: InputMaybe<Cities_Obj_Rel_Insert_Input>;
  cityId?: InputMaybe<Scalars['String']>;
  communityAggregates?: InputMaybe<Community_Aggregates_Obj_Rel_Insert_Input>;
  communityCategory?: InputMaybe<Community_Categories_Obj_Rel_Insert_Input>;
  communityCategoryId?: InputMaybe<Scalars['String']>;
  communityMembers?: InputMaybe<Community_Members_Arr_Rel_Insert_Input>;
  community_staffpick?: InputMaybe<Community_Staffpicks_Obj_Rel_Insert_Input>;
  continent?: InputMaybe<Continents_Obj_Rel_Insert_Input>;
  continentId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Countries_Obj_Rel_Insert_Input>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language_Isocodes_Enum>;
  language_isocode?: InputMaybe<Language_Isocodes_Obj_Rel_Insert_Input>;
  lens_appid_mappings?: InputMaybe<Lens_Appid_Mappings_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  nftProjects?: InputMaybe<Community_Nft_Projects_Arr_Rel_Insert_Input>;
  posts?: InputMaybe<Recommendations_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  rss_feeds?: InputMaybe<Rss_Feeds_Arr_Rel_Insert_Input>;
  sb_at?: InputMaybe<Scalars['timestamptz']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  subdivision?: InputMaybe<Subdivisions_Obj_Rel_Insert_Input>;
  subdivisionId?: InputMaybe<Scalars['String']>;
  thumbnailImage?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  thumbnailImageId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjects?: InputMaybe<Community_Nft_Projects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Communities_Max_Fields = {
  __typename?: 'communities_max_fields';
  cityId?: Maybe<Scalars['String']>;
  communityCategoryId?: Maybe<Scalars['String']>;
  continentId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  sb_at?: Maybe<Scalars['timestamptz']>;
  shortDescription?: Maybe<Scalars['String']>;
  subdivisionId?: Maybe<Scalars['String']>;
  thumbnailImageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "communities" */
export type Communities_Max_Order_By = {
  cityId?: InputMaybe<Order_By>;
  communityCategoryId?: InputMaybe<Order_By>;
  continentId?: InputMaybe<Order_By>;
  countryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  sb_at?: InputMaybe<Order_By>;
  shortDescription?: InputMaybe<Order_By>;
  subdivisionId?: InputMaybe<Order_By>;
  thumbnailImageId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Communities_Min_Fields = {
  __typename?: 'communities_min_fields';
  cityId?: Maybe<Scalars['String']>;
  communityCategoryId?: Maybe<Scalars['String']>;
  continentId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  sb_at?: Maybe<Scalars['timestamptz']>;
  shortDescription?: Maybe<Scalars['String']>;
  subdivisionId?: Maybe<Scalars['String']>;
  thumbnailImageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "communities" */
export type Communities_Min_Order_By = {
  cityId?: InputMaybe<Order_By>;
  communityCategoryId?: InputMaybe<Order_By>;
  continentId?: InputMaybe<Order_By>;
  countryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  sb_at?: InputMaybe<Order_By>;
  shortDescription?: InputMaybe<Order_By>;
  subdivisionId?: InputMaybe<Order_By>;
  thumbnailImageId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "communities" */
export type Communities_Mutation_Response = {
  __typename?: 'communities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Communities>;
};

/** input type for inserting object relation for remote table "communities" */
export type Communities_Obj_Rel_Insert_Input = {
  data: Communities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Communities_On_Conflict>;
};

/** on_conflict condition type for table "communities" */
export type Communities_On_Conflict = {
  constraint: Communities_Constraint;
  update_columns?: Array<Communities_Update_Column>;
  where?: InputMaybe<Communities_Bool_Exp>;
};

/** Ordering options when selecting data from "communities". */
export type Communities_Order_By = {
  city?: InputMaybe<Cities_Order_By>;
  cityId?: InputMaybe<Order_By>;
  communityAggregates?: InputMaybe<Community_Aggregates_Order_By>;
  communityCategory?: InputMaybe<Community_Categories_Order_By>;
  communityCategoryId?: InputMaybe<Order_By>;
  communityMembers_aggregate?: InputMaybe<Community_Members_Aggregate_Order_By>;
  community_staffpick?: InputMaybe<Community_Staffpicks_Order_By>;
  continent?: InputMaybe<Continents_Order_By>;
  continentId?: InputMaybe<Order_By>;
  country?: InputMaybe<Countries_Order_By>;
  countryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  isHidden?: InputMaybe<Order_By>;
  isMember?: InputMaybe<Order_By>;
  isModerator?: InputMaybe<Order_By>;
  isPrivate?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  language_isocode?: InputMaybe<Language_Isocodes_Order_By>;
  lens_appid_mappings_aggregate?: InputMaybe<Lens_Appid_Mappings_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  nftProjects_aggregate?: InputMaybe<Community_Nft_Projects_Aggregate_Order_By>;
  posts_aggregate?: InputMaybe<Recommendations_Aggregate_Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  rss_feeds_aggregate?: InputMaybe<Rss_Feeds_Aggregate_Order_By>;
  sb_at?: InputMaybe<Order_By>;
  shortDescription?: InputMaybe<Order_By>;
  subdivision?: InputMaybe<Subdivisions_Order_By>;
  subdivisionId?: InputMaybe<Order_By>;
  thumbnailImage?: InputMaybe<Images_Order_By>;
  thumbnailImageId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  verifiedNftProjects_aggregate?: InputMaybe<Community_Nft_Projects_Aggregate_Order_By>;
};

/** primary key columns input for table: communities */
export type Communities_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "communities" */
export enum Communities_Select_Column {
  /** column name */
  CityId = 'cityId',
  /** column name */
  CommunityCategoryId = 'communityCategoryId',
  /** column name */
  ContinentId = 'continentId',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsPrivate = 'isPrivate',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  SbAt = 'sb_at',
  /** column name */
  ShortDescription = 'shortDescription',
  /** column name */
  SubdivisionId = 'subdivisionId',
  /** column name */
  ThumbnailImageId = 'thumbnailImageId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "communities_aggregate_bool_exp_bool_and_arguments_columns" columns of table "communities" */
export enum Communities_Select_Column_Communities_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsPrivate = 'isPrivate'
}

/** select "communities_aggregate_bool_exp_bool_or_arguments_columns" columns of table "communities" */
export enum Communities_Select_Column_Communities_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsPrivate = 'isPrivate'
}

/** input type for updating data in table "communities" */
export type Communities_Set_Input = {
  cityId?: InputMaybe<Scalars['String']>;
  communityCategoryId?: InputMaybe<Scalars['String']>;
  continentId?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language_Isocodes_Enum>;
  name?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  sb_at?: InputMaybe<Scalars['timestamptz']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  subdivisionId?: InputMaybe<Scalars['String']>;
  thumbnailImageId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "communities" */
export type Communities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Communities_Stream_Cursor_Value_Input = {
  cityId?: InputMaybe<Scalars['String']>;
  communityCategoryId?: InputMaybe<Scalars['String']>;
  continentId?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language_Isocodes_Enum>;
  name?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  sb_at?: InputMaybe<Scalars['timestamptz']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  subdivisionId?: InputMaybe<Scalars['String']>;
  thumbnailImageId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "communities" */
export enum Communities_Update_Column {
  /** column name */
  CityId = 'cityId',
  /** column name */
  CommunityCategoryId = 'communityCategoryId',
  /** column name */
  ContinentId = 'continentId',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsPrivate = 'isPrivate',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  SbAt = 'sb_at',
  /** column name */
  ShortDescription = 'shortDescription',
  /** column name */
  SubdivisionId = 'subdivisionId',
  /** column name */
  ThumbnailImageId = 'thumbnailImageId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Communities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Communities_Bool_Exp;
};

/** columns and relationships of "community_aggregates" */
export type Community_Aggregates = {
  __typename?: 'community_aggregates';
  communityId: Scalars['String'];
  memberCount?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "community_aggregates" */
export type Community_Aggregates_Aggregate = {
  __typename?: 'community_aggregates_aggregate';
  aggregate?: Maybe<Community_Aggregates_Aggregate_Fields>;
  nodes: Array<Community_Aggregates>;
};

/** aggregate fields of "community_aggregates" */
export type Community_Aggregates_Aggregate_Fields = {
  __typename?: 'community_aggregates_aggregate_fields';
  avg?: Maybe<Community_Aggregates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Community_Aggregates_Max_Fields>;
  min?: Maybe<Community_Aggregates_Min_Fields>;
  stddev?: Maybe<Community_Aggregates_Stddev_Fields>;
  stddev_pop?: Maybe<Community_Aggregates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Community_Aggregates_Stddev_Samp_Fields>;
  sum?: Maybe<Community_Aggregates_Sum_Fields>;
  var_pop?: Maybe<Community_Aggregates_Var_Pop_Fields>;
  var_samp?: Maybe<Community_Aggregates_Var_Samp_Fields>;
  variance?: Maybe<Community_Aggregates_Variance_Fields>;
};


/** aggregate fields of "community_aggregates" */
export type Community_Aggregates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Aggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Community_Aggregates_Avg_Fields = {
  __typename?: 'community_aggregates_avg_fields';
  memberCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "community_aggregates". All fields are combined with a logical 'AND'. */
export type Community_Aggregates_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Aggregates_Bool_Exp>>;
  _not?: InputMaybe<Community_Aggregates_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Aggregates_Bool_Exp>>;
  communityId?: InputMaybe<String_Comparison_Exp>;
  memberCount?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_aggregates" */
export enum Community_Aggregates_Constraint {
  /** unique or primary key constraint on columns "community_id" */
  CommunityAggregatesPkey = 'community_aggregates_pkey'
}

/** input type for incrementing numeric columns in table "community_aggregates" */
export type Community_Aggregates_Inc_Input = {
  memberCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "community_aggregates" */
export type Community_Aggregates_Insert_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  memberCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Community_Aggregates_Max_Fields = {
  __typename?: 'community_aggregates_max_fields';
  communityId?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Community_Aggregates_Min_Fields = {
  __typename?: 'community_aggregates_min_fields';
  communityId?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "community_aggregates" */
export type Community_Aggregates_Mutation_Response = {
  __typename?: 'community_aggregates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Aggregates>;
};

/** input type for inserting object relation for remote table "community_aggregates" */
export type Community_Aggregates_Obj_Rel_Insert_Input = {
  data: Community_Aggregates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Aggregates_On_Conflict>;
};

/** on_conflict condition type for table "community_aggregates" */
export type Community_Aggregates_On_Conflict = {
  constraint: Community_Aggregates_Constraint;
  update_columns?: Array<Community_Aggregates_Update_Column>;
  where?: InputMaybe<Community_Aggregates_Bool_Exp>;
};

/** Ordering options when selecting data from "community_aggregates". */
export type Community_Aggregates_Order_By = {
  communityId?: InputMaybe<Order_By>;
  memberCount?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_aggregates */
export type Community_Aggregates_Pk_Columns_Input = {
  communityId: Scalars['String'];
};

/** select columns of table "community_aggregates" */
export enum Community_Aggregates_Select_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  MemberCount = 'memberCount'
}

/** input type for updating data in table "community_aggregates" */
export type Community_Aggregates_Set_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  memberCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Community_Aggregates_Stddev_Fields = {
  __typename?: 'community_aggregates_stddev_fields';
  memberCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Community_Aggregates_Stddev_Pop_Fields = {
  __typename?: 'community_aggregates_stddev_pop_fields';
  memberCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Community_Aggregates_Stddev_Samp_Fields = {
  __typename?: 'community_aggregates_stddev_samp_fields';
  memberCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "community_aggregates" */
export type Community_Aggregates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Aggregates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Aggregates_Stream_Cursor_Value_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  memberCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Community_Aggregates_Sum_Fields = {
  __typename?: 'community_aggregates_sum_fields';
  memberCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "community_aggregates" */
export enum Community_Aggregates_Update_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  MemberCount = 'memberCount'
}

export type Community_Aggregates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Community_Aggregates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Aggregates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Aggregates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Community_Aggregates_Var_Pop_Fields = {
  __typename?: 'community_aggregates_var_pop_fields';
  memberCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Community_Aggregates_Var_Samp_Fields = {
  __typename?: 'community_aggregates_var_samp_fields';
  memberCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Community_Aggregates_Variance_Fields = {
  __typename?: 'community_aggregates_variance_fields';
  memberCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "community_categories" */
export type Community_Categories = {
  __typename?: 'community_categories';
  bgColor?: Maybe<Scalars['String']>;
  /** An array relationship */
  children: Array<Community_Categories>;
  /** An aggregate relationship */
  children_aggregate: Community_Categories_Aggregate;
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  image_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  parent?: Maybe<Community_Categories>;
  parentId?: Maybe<Scalars['String']>;
  translationKey?: Maybe<Scalars['String']>;
};


/** columns and relationships of "community_categories" */
export type Community_CategoriesChildrenArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


/** columns and relationships of "community_categories" */
export type Community_CategoriesChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


/** columns and relationships of "community_categories" */
export type Community_CategoriesCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "community_categories" */
export type Community_CategoriesCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};

/** aggregated selection of "community_categories" */
export type Community_Categories_Aggregate = {
  __typename?: 'community_categories_aggregate';
  aggregate?: Maybe<Community_Categories_Aggregate_Fields>;
  nodes: Array<Community_Categories>;
};

export type Community_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Community_Categories_Aggregate_Bool_Exp_Count>;
};

export type Community_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Community_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Community_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "community_categories" */
export type Community_Categories_Aggregate_Fields = {
  __typename?: 'community_categories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Community_Categories_Max_Fields>;
  min?: Maybe<Community_Categories_Min_Fields>;
};


/** aggregate fields of "community_categories" */
export type Community_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "community_categories" */
export type Community_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Community_Categories_Max_Order_By>;
  min?: InputMaybe<Community_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "community_categories" */
export type Community_Categories_Arr_Rel_Insert_Input = {
  data: Array<Community_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "community_categories". All fields are combined with a logical 'AND'. */
export type Community_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Categories_Bool_Exp>>;
  _not?: InputMaybe<Community_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Categories_Bool_Exp>>;
  bgColor?: InputMaybe<String_Comparison_Exp>;
  children?: InputMaybe<Community_Categories_Bool_Exp>;
  children_aggregate?: InputMaybe<Community_Categories_Aggregate_Bool_Exp>;
  communities?: InputMaybe<Communities_Bool_Exp>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  image_id?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Community_Categories_Bool_Exp>;
  parentId?: InputMaybe<String_Comparison_Exp>;
  translationKey?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_categories" */
export enum Community_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommunityCategoriesPkey = 'community_categories_pkey'
}

/** input type for inserting data into table "community_categories" */
export type Community_Categories_Insert_Input = {
  bgColor?: InputMaybe<Scalars['String']>;
  children?: InputMaybe<Community_Categories_Arr_Rel_Insert_Input>;
  communities?: InputMaybe<Communities_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  image_id?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Community_Categories_Obj_Rel_Insert_Input>;
  parentId?: InputMaybe<Scalars['String']>;
  translationKey?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Community_Categories_Max_Fields = {
  __typename?: 'community_categories_max_fields';
  bgColor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  translationKey?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "community_categories" */
export type Community_Categories_Max_Order_By = {
  bgColor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  translationKey?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Community_Categories_Min_Fields = {
  __typename?: 'community_categories_min_fields';
  bgColor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  translationKey?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "community_categories" */
export type Community_Categories_Min_Order_By = {
  bgColor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  translationKey?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "community_categories" */
export type Community_Categories_Mutation_Response = {
  __typename?: 'community_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Categories>;
};

/** input type for inserting object relation for remote table "community_categories" */
export type Community_Categories_Obj_Rel_Insert_Input = {
  data: Community_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Categories_On_Conflict>;
};

/** on_conflict condition type for table "community_categories" */
export type Community_Categories_On_Conflict = {
  constraint: Community_Categories_Constraint;
  update_columns?: Array<Community_Categories_Update_Column>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "community_categories". */
export type Community_Categories_Order_By = {
  bgColor?: InputMaybe<Order_By>;
  children_aggregate?: InputMaybe<Community_Categories_Aggregate_Order_By>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  image_id?: InputMaybe<Order_By>;
  parent?: InputMaybe<Community_Categories_Order_By>;
  parentId?: InputMaybe<Order_By>;
  translationKey?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_categories */
export type Community_Categories_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "community_categories" */
export enum Community_Categories_Select_Column {
  /** column name */
  BgColor = 'bgColor',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  TranslationKey = 'translationKey'
}

/** input type for updating data in table "community_categories" */
export type Community_Categories_Set_Input = {
  bgColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image_id?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  translationKey?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "community_categories" */
export type Community_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Categories_Stream_Cursor_Value_Input = {
  bgColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image_id?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  translationKey?: InputMaybe<Scalars['String']>;
};

/** update columns of table "community_categories" */
export enum Community_Categories_Update_Column {
  /** column name */
  BgColor = 'bgColor',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  TranslationKey = 'translationKey'
}

export type Community_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Categories_Bool_Exp;
};

/** columns and relationships of "community_content_collections" */
export type Community_Content_Collections = {
  __typename?: 'community_content_collections';
  /** An object relationship */
  communityContent: Community_Contents;
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "community_content_collections" */
export type Community_Content_Collections_Aggregate = {
  __typename?: 'community_content_collections_aggregate';
  aggregate?: Maybe<Community_Content_Collections_Aggregate_Fields>;
  nodes: Array<Community_Content_Collections>;
};

/** aggregate fields of "community_content_collections" */
export type Community_Content_Collections_Aggregate_Fields = {
  __typename?: 'community_content_collections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Community_Content_Collections_Max_Fields>;
  min?: Maybe<Community_Content_Collections_Min_Fields>;
};


/** aggregate fields of "community_content_collections" */
export type Community_Content_Collections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Content_Collections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "community_content_collections". All fields are combined with a logical 'AND'. */
export type Community_Content_Collections_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Content_Collections_Bool_Exp>>;
  _not?: InputMaybe<Community_Content_Collections_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Content_Collections_Bool_Exp>>;
  communityContent?: InputMaybe<Community_Contents_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_content_collections" */
export enum Community_Content_Collections_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommunityContentCollectionsPkey = 'community_content_collections_pkey'
}

/** input type for inserting data into table "community_content_collections" */
export type Community_Content_Collections_Insert_Input = {
  communityContent?: InputMaybe<Community_Contents_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Community_Content_Collections_Max_Fields = {
  __typename?: 'community_content_collections_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Community_Content_Collections_Min_Fields = {
  __typename?: 'community_content_collections_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "community_content_collections" */
export type Community_Content_Collections_Mutation_Response = {
  __typename?: 'community_content_collections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Content_Collections>;
};

/** input type for inserting object relation for remote table "community_content_collections" */
export type Community_Content_Collections_Obj_Rel_Insert_Input = {
  data: Community_Content_Collections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Content_Collections_On_Conflict>;
};

/** on_conflict condition type for table "community_content_collections" */
export type Community_Content_Collections_On_Conflict = {
  constraint: Community_Content_Collections_Constraint;
  update_columns?: Array<Community_Content_Collections_Update_Column>;
  where?: InputMaybe<Community_Content_Collections_Bool_Exp>;
};

/** Ordering options when selecting data from "community_content_collections". */
export type Community_Content_Collections_Order_By = {
  communityContent?: InputMaybe<Community_Contents_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_content_collections */
export type Community_Content_Collections_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "community_content_collections" */
export enum Community_Content_Collections_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "community_content_collections" */
export type Community_Content_Collections_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "community_content_collections" */
export type Community_Content_Collections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Content_Collections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Content_Collections_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "community_content_collections" */
export enum Community_Content_Collections_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Community_Content_Collections_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Content_Collections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Content_Collections_Bool_Exp;
};

/** columns and relationships of "community_content_questions" */
export type Community_Content_Questions = {
  __typename?: 'community_content_questions';
  /** An object relationship */
  communityContent: Community_Contents;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "community_content_questions" */
export type Community_Content_Questions_Aggregate = {
  __typename?: 'community_content_questions_aggregate';
  aggregate?: Maybe<Community_Content_Questions_Aggregate_Fields>;
  nodes: Array<Community_Content_Questions>;
};

/** aggregate fields of "community_content_questions" */
export type Community_Content_Questions_Aggregate_Fields = {
  __typename?: 'community_content_questions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Community_Content_Questions_Max_Fields>;
  min?: Maybe<Community_Content_Questions_Min_Fields>;
};


/** aggregate fields of "community_content_questions" */
export type Community_Content_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Content_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "community_content_questions". All fields are combined with a logical 'AND'. */
export type Community_Content_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Content_Questions_Bool_Exp>>;
  _not?: InputMaybe<Community_Content_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Content_Questions_Bool_Exp>>;
  communityContent?: InputMaybe<Community_Contents_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_content_questions" */
export enum Community_Content_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommunityContentQuestionsPkey = 'community_content_questions_pkey'
}

/** input type for inserting data into table "community_content_questions" */
export type Community_Content_Questions_Insert_Input = {
  communityContent?: InputMaybe<Community_Contents_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Community_Content_Questions_Max_Fields = {
  __typename?: 'community_content_questions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Community_Content_Questions_Min_Fields = {
  __typename?: 'community_content_questions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "community_content_questions" */
export type Community_Content_Questions_Mutation_Response = {
  __typename?: 'community_content_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Content_Questions>;
};

/** input type for inserting object relation for remote table "community_content_questions" */
export type Community_Content_Questions_Obj_Rel_Insert_Input = {
  data: Community_Content_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Content_Questions_On_Conflict>;
};

/** on_conflict condition type for table "community_content_questions" */
export type Community_Content_Questions_On_Conflict = {
  constraint: Community_Content_Questions_Constraint;
  update_columns?: Array<Community_Content_Questions_Update_Column>;
  where?: InputMaybe<Community_Content_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "community_content_questions". */
export type Community_Content_Questions_Order_By = {
  communityContent?: InputMaybe<Community_Contents_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_content_questions */
export type Community_Content_Questions_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "community_content_questions" */
export enum Community_Content_Questions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "community_content_questions" */
export type Community_Content_Questions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "community_content_questions" */
export type Community_Content_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Content_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Content_Questions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "community_content_questions" */
export enum Community_Content_Questions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Community_Content_Questions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Content_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Content_Questions_Bool_Exp;
};

/** columns and relationships of "community_content_vote_scores" */
export type Community_Content_Vote_Scores = {
  __typename?: 'community_content_vote_scores';
  communityContentId?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "community_content_vote_scores" */
export type Community_Content_Vote_Scores_Aggregate = {
  __typename?: 'community_content_vote_scores_aggregate';
  aggregate?: Maybe<Community_Content_Vote_Scores_Aggregate_Fields>;
  nodes: Array<Community_Content_Vote_Scores>;
};

/** aggregate fields of "community_content_vote_scores" */
export type Community_Content_Vote_Scores_Aggregate_Fields = {
  __typename?: 'community_content_vote_scores_aggregate_fields';
  avg?: Maybe<Community_Content_Vote_Scores_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Community_Content_Vote_Scores_Max_Fields>;
  min?: Maybe<Community_Content_Vote_Scores_Min_Fields>;
  stddev?: Maybe<Community_Content_Vote_Scores_Stddev_Fields>;
  stddev_pop?: Maybe<Community_Content_Vote_Scores_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Community_Content_Vote_Scores_Stddev_Samp_Fields>;
  sum?: Maybe<Community_Content_Vote_Scores_Sum_Fields>;
  var_pop?: Maybe<Community_Content_Vote_Scores_Var_Pop_Fields>;
  var_samp?: Maybe<Community_Content_Vote_Scores_Var_Samp_Fields>;
  variance?: Maybe<Community_Content_Vote_Scores_Variance_Fields>;
};


/** aggregate fields of "community_content_vote_scores" */
export type Community_Content_Vote_Scores_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Content_Vote_Scores_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Community_Content_Vote_Scores_Avg_Fields = {
  __typename?: 'community_content_vote_scores_avg_fields';
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "community_content_vote_scores". All fields are combined with a logical 'AND'. */
export type Community_Content_Vote_Scores_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Content_Vote_Scores_Bool_Exp>>;
  _not?: InputMaybe<Community_Content_Vote_Scores_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Content_Vote_Scores_Bool_Exp>>;
  communityContentId?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "community_content_vote_scores" */
export type Community_Content_Vote_Scores_Insert_Input = {
  communityContentId?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Community_Content_Vote_Scores_Max_Fields = {
  __typename?: 'community_content_vote_scores_max_fields';
  communityContentId?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Community_Content_Vote_Scores_Min_Fields = {
  __typename?: 'community_content_vote_scores_min_fields';
  communityContentId?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "community_content_vote_scores" */
export type Community_Content_Vote_Scores_Obj_Rel_Insert_Input = {
  data: Community_Content_Vote_Scores_Insert_Input;
};

/** Ordering options when selecting data from "community_content_vote_scores". */
export type Community_Content_Vote_Scores_Order_By = {
  communityContentId?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** select columns of table "community_content_vote_scores" */
export enum Community_Content_Vote_Scores_Select_Column {
  /** column name */
  CommunityContentId = 'communityContentId',
  /** column name */
  Score = 'score'
}

/** aggregate stddev on columns */
export type Community_Content_Vote_Scores_Stddev_Fields = {
  __typename?: 'community_content_vote_scores_stddev_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Community_Content_Vote_Scores_Stddev_Pop_Fields = {
  __typename?: 'community_content_vote_scores_stddev_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Community_Content_Vote_Scores_Stddev_Samp_Fields = {
  __typename?: 'community_content_vote_scores_stddev_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "community_content_vote_scores" */
export type Community_Content_Vote_Scores_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Content_Vote_Scores_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Content_Vote_Scores_Stream_Cursor_Value_Input = {
  communityContentId?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Community_Content_Vote_Scores_Sum_Fields = {
  __typename?: 'community_content_vote_scores_sum_fields';
  score?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Community_Content_Vote_Scores_Var_Pop_Fields = {
  __typename?: 'community_content_vote_scores_var_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Community_Content_Vote_Scores_Var_Samp_Fields = {
  __typename?: 'community_content_vote_scores_var_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Community_Content_Vote_Scores_Variance_Fields = {
  __typename?: 'community_content_vote_scores_variance_fields';
  score?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "community_content_votes" */
export type Community_Content_Votes = {
  __typename?: 'community_content_votes';
  communityContentId: Scalars['String'];
  /** An object relationship */
  community_content: Community_Contents;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  value: Scalars['Int'];
};

/** aggregated selection of "community_content_votes" */
export type Community_Content_Votes_Aggregate = {
  __typename?: 'community_content_votes_aggregate';
  aggregate?: Maybe<Community_Content_Votes_Aggregate_Fields>;
  nodes: Array<Community_Content_Votes>;
};

export type Community_Content_Votes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Community_Content_Votes_Aggregate_Bool_Exp_Count>;
};

export type Community_Content_Votes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Community_Content_Votes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "community_content_votes" */
export type Community_Content_Votes_Aggregate_Fields = {
  __typename?: 'community_content_votes_aggregate_fields';
  avg?: Maybe<Community_Content_Votes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Community_Content_Votes_Max_Fields>;
  min?: Maybe<Community_Content_Votes_Min_Fields>;
  stddev?: Maybe<Community_Content_Votes_Stddev_Fields>;
  stddev_pop?: Maybe<Community_Content_Votes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Community_Content_Votes_Stddev_Samp_Fields>;
  sum?: Maybe<Community_Content_Votes_Sum_Fields>;
  var_pop?: Maybe<Community_Content_Votes_Var_Pop_Fields>;
  var_samp?: Maybe<Community_Content_Votes_Var_Samp_Fields>;
  variance?: Maybe<Community_Content_Votes_Variance_Fields>;
};


/** aggregate fields of "community_content_votes" */
export type Community_Content_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "community_content_votes" */
export type Community_Content_Votes_Aggregate_Order_By = {
  avg?: InputMaybe<Community_Content_Votes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Community_Content_Votes_Max_Order_By>;
  min?: InputMaybe<Community_Content_Votes_Min_Order_By>;
  stddev?: InputMaybe<Community_Content_Votes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Community_Content_Votes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Community_Content_Votes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Community_Content_Votes_Sum_Order_By>;
  var_pop?: InputMaybe<Community_Content_Votes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Community_Content_Votes_Var_Samp_Order_By>;
  variance?: InputMaybe<Community_Content_Votes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "community_content_votes" */
export type Community_Content_Votes_Arr_Rel_Insert_Input = {
  data: Array<Community_Content_Votes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Content_Votes_On_Conflict>;
};

/** aggregate avg on columns */
export type Community_Content_Votes_Avg_Fields = {
  __typename?: 'community_content_votes_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "community_content_votes" */
export type Community_Content_Votes_Avg_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "community_content_votes". All fields are combined with a logical 'AND'. */
export type Community_Content_Votes_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Content_Votes_Bool_Exp>>;
  _not?: InputMaybe<Community_Content_Votes_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Content_Votes_Bool_Exp>>;
  communityContentId?: InputMaybe<String_Comparison_Exp>;
  community_content?: InputMaybe<Community_Contents_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_content_votes" */
export enum Community_Content_Votes_Constraint {
  /** unique or primary key constraint on columns "profile_id", "community_content_id" */
  CommunityContentVotesPkey = 'community_content_votes_pkey'
}

/** input type for incrementing numeric columns in table "community_content_votes" */
export type Community_Content_Votes_Inc_Input = {
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "community_content_votes" */
export type Community_Content_Votes_Insert_Input = {
  communityContentId?: InputMaybe<Scalars['String']>;
  community_content?: InputMaybe<Community_Contents_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Community_Content_Votes_Max_Fields = {
  __typename?: 'community_content_votes_max_fields';
  communityContentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  profileId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "community_content_votes" */
export type Community_Content_Votes_Max_Order_By = {
  communityContentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Community_Content_Votes_Min_Fields = {
  __typename?: 'community_content_votes_min_fields';
  communityContentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  profileId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "community_content_votes" */
export type Community_Content_Votes_Min_Order_By = {
  communityContentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "community_content_votes" */
export type Community_Content_Votes_Mutation_Response = {
  __typename?: 'community_content_votes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Content_Votes>;
};

/** input type for inserting object relation for remote table "community_content_votes" */
export type Community_Content_Votes_Obj_Rel_Insert_Input = {
  data: Community_Content_Votes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Content_Votes_On_Conflict>;
};

/** on_conflict condition type for table "community_content_votes" */
export type Community_Content_Votes_On_Conflict = {
  constraint: Community_Content_Votes_Constraint;
  update_columns?: Array<Community_Content_Votes_Update_Column>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "community_content_votes". */
export type Community_Content_Votes_Order_By = {
  communityContentId?: InputMaybe<Order_By>;
  community_content?: InputMaybe<Community_Contents_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_content_votes */
export type Community_Content_Votes_Pk_Columns_Input = {
  communityContentId: Scalars['String'];
  profileId: Scalars['String'];
};

/** select columns of table "community_content_votes" */
export enum Community_Content_Votes_Select_Column {
  /** column name */
  CommunityContentId = 'communityContentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "community_content_votes" */
export type Community_Content_Votes_Set_Input = {
  communityContentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  profileId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Community_Content_Votes_Stddev_Fields = {
  __typename?: 'community_content_votes_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "community_content_votes" */
export type Community_Content_Votes_Stddev_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Community_Content_Votes_Stddev_Pop_Fields = {
  __typename?: 'community_content_votes_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "community_content_votes" */
export type Community_Content_Votes_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Community_Content_Votes_Stddev_Samp_Fields = {
  __typename?: 'community_content_votes_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "community_content_votes" */
export type Community_Content_Votes_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "community_content_votes" */
export type Community_Content_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Content_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Content_Votes_Stream_Cursor_Value_Input = {
  communityContentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  profileId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Community_Content_Votes_Sum_Fields = {
  __typename?: 'community_content_votes_sum_fields';
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "community_content_votes" */
export type Community_Content_Votes_Sum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** update columns of table "community_content_votes" */
export enum Community_Content_Votes_Update_Column {
  /** column name */
  CommunityContentId = 'communityContentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Community_Content_Votes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Community_Content_Votes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Content_Votes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Content_Votes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Community_Content_Votes_Var_Pop_Fields = {
  __typename?: 'community_content_votes_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "community_content_votes" */
export type Community_Content_Votes_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Community_Content_Votes_Var_Samp_Fields = {
  __typename?: 'community_content_votes_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "community_content_votes" */
export type Community_Content_Votes_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Community_Content_Votes_Variance_Fields = {
  __typename?: 'community_content_votes_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "community_content_votes" */
export type Community_Content_Votes_Variance_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "community_contents" */
export type Community_Contents = {
  __typename?: 'community_contents';
  /** An object relationship */
  collection?: Maybe<Community_Content_Collections>;
  communityId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  /** An object relationship */
  question?: Maybe<Community_Content_Questions>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  userVote?: Maybe<Community_Content_Votes>;
  /** An object relationship */
  voteScore?: Maybe<Community_Content_Vote_Scores>;
  /** An array relationship */
  votes: Array<Community_Content_Votes>;
  /** An aggregate relationship */
  votes_aggregate: Community_Content_Votes_Aggregate;
};


/** columns and relationships of "community_contents" */
export type Community_ContentsVotesArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Votes_Order_By>>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};


/** columns and relationships of "community_contents" */
export type Community_ContentsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Votes_Order_By>>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};

/** aggregated selection of "community_contents" */
export type Community_Contents_Aggregate = {
  __typename?: 'community_contents_aggregate';
  aggregate?: Maybe<Community_Contents_Aggregate_Fields>;
  nodes: Array<Community_Contents>;
};

/** aggregate fields of "community_contents" */
export type Community_Contents_Aggregate_Fields = {
  __typename?: 'community_contents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Community_Contents_Max_Fields>;
  min?: Maybe<Community_Contents_Min_Fields>;
};


/** aggregate fields of "community_contents" */
export type Community_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "community_contents". All fields are combined with a logical 'AND'. */
export type Community_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Contents_Bool_Exp>>;
  _not?: InputMaybe<Community_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Contents_Bool_Exp>>;
  collection?: InputMaybe<Community_Content_Collections_Bool_Exp>;
  communityId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  question?: InputMaybe<Community_Content_Questions_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userVote?: InputMaybe<Community_Content_Votes_Bool_Exp>;
  voteScore?: InputMaybe<Community_Content_Vote_Scores_Bool_Exp>;
  votes?: InputMaybe<Community_Content_Votes_Bool_Exp>;
  votes_aggregate?: InputMaybe<Community_Content_Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "community_contents" */
export enum Community_Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommunityContentsPkey = 'community_contents_pkey'
}

/** input type for inserting data into table "community_contents" */
export type Community_Contents_Insert_Input = {
  collection?: InputMaybe<Community_Content_Collections_Obj_Rel_Insert_Input>;
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Community_Content_Questions_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userVote?: InputMaybe<Community_Content_Votes_Obj_Rel_Insert_Input>;
  voteScore?: InputMaybe<Community_Content_Vote_Scores_Obj_Rel_Insert_Input>;
  votes?: InputMaybe<Community_Content_Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Community_Contents_Max_Fields = {
  __typename?: 'community_contents_max_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Community_Contents_Min_Fields = {
  __typename?: 'community_contents_min_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "community_contents" */
export type Community_Contents_Mutation_Response = {
  __typename?: 'community_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Contents>;
};

/** input type for inserting object relation for remote table "community_contents" */
export type Community_Contents_Obj_Rel_Insert_Input = {
  data: Community_Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Contents_On_Conflict>;
};

/** on_conflict condition type for table "community_contents" */
export type Community_Contents_On_Conflict = {
  constraint: Community_Contents_Constraint;
  update_columns?: Array<Community_Contents_Update_Column>;
  where?: InputMaybe<Community_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "community_contents". */
export type Community_Contents_Order_By = {
  collection?: InputMaybe<Community_Content_Collections_Order_By>;
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  question?: InputMaybe<Community_Content_Questions_Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userVote?: InputMaybe<Community_Content_Votes_Order_By>;
  voteScore?: InputMaybe<Community_Content_Vote_Scores_Order_By>;
  votes_aggregate?: InputMaybe<Community_Content_Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: community_contents */
export type Community_Contents_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "community_contents" */
export enum Community_Contents_Select_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "community_contents" */
export type Community_Contents_Set_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "community_contents" */
export type Community_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Contents_Stream_Cursor_Value_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "community_contents" */
export enum Community_Contents_Update_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Community_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Contents_Bool_Exp;
};

/** columns and relationships of "community_members" */
export type Community_Members = {
  __typename?: 'community_members';
  /** An object relationship */
  community: Communities;
  communityId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  lastVisited: Scalars['timestamptz'];
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  /** An object relationship */
  role_type: Role_Types;
  userRole: Role_Types_Enum;
};

/** aggregated selection of "community_members" */
export type Community_Members_Aggregate = {
  __typename?: 'community_members_aggregate';
  aggregate?: Maybe<Community_Members_Aggregate_Fields>;
  nodes: Array<Community_Members>;
};

export type Community_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Community_Members_Aggregate_Bool_Exp_Count>;
};

export type Community_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Community_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Community_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "community_members" */
export type Community_Members_Aggregate_Fields = {
  __typename?: 'community_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Community_Members_Max_Fields>;
  min?: Maybe<Community_Members_Min_Fields>;
};


/** aggregate fields of "community_members" */
export type Community_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "community_members" */
export type Community_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Community_Members_Max_Order_By>;
  min?: InputMaybe<Community_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "community_members" */
export type Community_Members_Arr_Rel_Insert_Input = {
  data: Array<Community_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "community_members". All fields are combined with a logical 'AND'. */
export type Community_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Members_Bool_Exp>>;
  _not?: InputMaybe<Community_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Members_Bool_Exp>>;
  community?: InputMaybe<Communities_Bool_Exp>;
  communityId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastVisited?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  role_type?: InputMaybe<Role_Types_Bool_Exp>;
  userRole?: InputMaybe<Role_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_members" */
export enum Community_Members_Constraint {
  /** unique or primary key constraint on columns "community_id", "profile_id" */
  CommunityMembersPkey = 'community_members_pkey'
}

/** input type for inserting data into table "community_members" */
export type Community_Members_Insert_Input = {
  community?: InputMaybe<Communities_Obj_Rel_Insert_Input>;
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  lastVisited?: InputMaybe<Scalars['timestamptz']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  role_type?: InputMaybe<Role_Types_Obj_Rel_Insert_Input>;
  userRole?: InputMaybe<Role_Types_Enum>;
};

/** aggregate max on columns */
export type Community_Members_Max_Fields = {
  __typename?: 'community_members_max_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  lastVisited?: Maybe<Scalars['timestamptz']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "community_members" */
export type Community_Members_Max_Order_By = {
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  lastVisited?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Community_Members_Min_Fields = {
  __typename?: 'community_members_min_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  lastVisited?: Maybe<Scalars['timestamptz']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "community_members" */
export type Community_Members_Min_Order_By = {
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  lastVisited?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "community_members" */
export type Community_Members_Mutation_Response = {
  __typename?: 'community_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Members>;
};

/** on_conflict condition type for table "community_members" */
export type Community_Members_On_Conflict = {
  constraint: Community_Members_Constraint;
  update_columns?: Array<Community_Members_Update_Column>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "community_members". */
export type Community_Members_Order_By = {
  community?: InputMaybe<Communities_Order_By>;
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  lastVisited?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  role_type?: InputMaybe<Role_Types_Order_By>;
  userRole?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_members */
export type Community_Members_Pk_Columns_Input = {
  communityId: Scalars['String'];
  profileId: Scalars['String'];
};

/** select columns of table "community_members" */
export enum Community_Members_Select_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  LastVisited = 'lastVisited',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UserRole = 'userRole'
}

/** input type for updating data in table "community_members" */
export type Community_Members_Set_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  lastVisited?: InputMaybe<Scalars['timestamptz']>;
  profileId?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<Role_Types_Enum>;
};

/** Streaming cursor of the table "community_members" */
export type Community_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Members_Stream_Cursor_Value_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  lastVisited?: InputMaybe<Scalars['timestamptz']>;
  profileId?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<Role_Types_Enum>;
};

/** update columns of table "community_members" */
export enum Community_Members_Update_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  LastVisited = 'lastVisited',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UserRole = 'userRole'
}

export type Community_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Members_Bool_Exp;
};

/** columns and relationships of "community_nft_projects" */
export type Community_Nft_Projects = {
  __typename?: 'community_nft_projects';
  communityId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  verifiedNftProject: Verified_Nft_Projects;
  verifiedNftProjectId: Scalars['Int'];
};

/** aggregated selection of "community_nft_projects" */
export type Community_Nft_Projects_Aggregate = {
  __typename?: 'community_nft_projects_aggregate';
  aggregate?: Maybe<Community_Nft_Projects_Aggregate_Fields>;
  nodes: Array<Community_Nft_Projects>;
};

export type Community_Nft_Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Community_Nft_Projects_Aggregate_Bool_Exp_Count>;
};

export type Community_Nft_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "community_nft_projects" */
export type Community_Nft_Projects_Aggregate_Fields = {
  __typename?: 'community_nft_projects_aggregate_fields';
  avg?: Maybe<Community_Nft_Projects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Community_Nft_Projects_Max_Fields>;
  min?: Maybe<Community_Nft_Projects_Min_Fields>;
  stddev?: Maybe<Community_Nft_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Community_Nft_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Community_Nft_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Community_Nft_Projects_Sum_Fields>;
  var_pop?: Maybe<Community_Nft_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Community_Nft_Projects_Var_Samp_Fields>;
  variance?: Maybe<Community_Nft_Projects_Variance_Fields>;
};


/** aggregate fields of "community_nft_projects" */
export type Community_Nft_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "community_nft_projects" */
export type Community_Nft_Projects_Aggregate_Order_By = {
  avg?: InputMaybe<Community_Nft_Projects_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Community_Nft_Projects_Max_Order_By>;
  min?: InputMaybe<Community_Nft_Projects_Min_Order_By>;
  stddev?: InputMaybe<Community_Nft_Projects_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Community_Nft_Projects_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Community_Nft_Projects_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Community_Nft_Projects_Sum_Order_By>;
  var_pop?: InputMaybe<Community_Nft_Projects_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Community_Nft_Projects_Var_Samp_Order_By>;
  variance?: InputMaybe<Community_Nft_Projects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "community_nft_projects" */
export type Community_Nft_Projects_Arr_Rel_Insert_Input = {
  data: Array<Community_Nft_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Nft_Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Community_Nft_Projects_Avg_Fields = {
  __typename?: 'community_nft_projects_avg_fields';
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Avg_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "community_nft_projects". All fields are combined with a logical 'AND'. */
export type Community_Nft_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Nft_Projects_Bool_Exp>>;
  _not?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Nft_Projects_Bool_Exp>>;
  communityId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  verifiedNftProject?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  verifiedNftProjectId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_nft_projects" */
export enum Community_Nft_Projects_Constraint {
  /** unique or primary key constraint on columns "community_id", "verified_nft_project_id" */
  CommunityNftProjectsPkey = 'community_nft_projects_pkey'
}

/** input type for incrementing numeric columns in table "community_nft_projects" */
export type Community_Nft_Projects_Inc_Input = {
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "community_nft_projects" */
export type Community_Nft_Projects_Insert_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProject?: InputMaybe<Verified_Nft_Projects_Obj_Rel_Insert_Input>;
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Community_Nft_Projects_Max_Fields = {
  __typename?: 'community_nft_projects_max_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Max_Order_By = {
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Community_Nft_Projects_Min_Fields = {
  __typename?: 'community_nft_projects_min_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Min_Order_By = {
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "community_nft_projects" */
export type Community_Nft_Projects_Mutation_Response = {
  __typename?: 'community_nft_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Nft_Projects>;
};

/** on_conflict condition type for table "community_nft_projects" */
export type Community_Nft_Projects_On_Conflict = {
  constraint: Community_Nft_Projects_Constraint;
  update_columns?: Array<Community_Nft_Projects_Update_Column>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "community_nft_projects". */
export type Community_Nft_Projects_Order_By = {
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  verifiedNftProject?: InputMaybe<Verified_Nft_Projects_Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_nft_projects */
export type Community_Nft_Projects_Pk_Columns_Input = {
  communityId: Scalars['String'];
  verifiedNftProjectId: Scalars['Int'];
};

/** select columns of table "community_nft_projects" */
export enum Community_Nft_Projects_Select_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  VerifiedNftProjectId = 'verifiedNftProjectId'
}

/** input type for updating data in table "community_nft_projects" */
export type Community_Nft_Projects_Set_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Community_Nft_Projects_Stddev_Fields = {
  __typename?: 'community_nft_projects_stddev_fields';
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Stddev_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Community_Nft_Projects_Stddev_Pop_Fields = {
  __typename?: 'community_nft_projects_stddev_pop_fields';
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Stddev_Pop_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Community_Nft_Projects_Stddev_Samp_Fields = {
  __typename?: 'community_nft_projects_stddev_samp_fields';
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Stddev_Samp_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "community_nft_projects" */
export type Community_Nft_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Nft_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Nft_Projects_Stream_Cursor_Value_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Community_Nft_Projects_Sum_Fields = {
  __typename?: 'community_nft_projects_sum_fields';
  verifiedNftProjectId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Sum_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** update columns of table "community_nft_projects" */
export enum Community_Nft_Projects_Update_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  VerifiedNftProjectId = 'verifiedNftProjectId'
}

export type Community_Nft_Projects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Community_Nft_Projects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Nft_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Nft_Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Community_Nft_Projects_Var_Pop_Fields = {
  __typename?: 'community_nft_projects_var_pop_fields';
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Var_Pop_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Community_Nft_Projects_Var_Samp_Fields = {
  __typename?: 'community_nft_projects_var_samp_fields';
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Var_Samp_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Community_Nft_Projects_Variance_Fields = {
  __typename?: 'community_nft_projects_variance_fields';
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "community_nft_projects" */
export type Community_Nft_Projects_Variance_Order_By = {
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** columns and relationships of "community_staffpicks" */
export type Community_Staffpicks = {
  __typename?: 'community_staffpicks';
  /** An object relationship */
  community?: Maybe<Communities>;
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "community_staffpicks" */
export type Community_Staffpicks_Aggregate = {
  __typename?: 'community_staffpicks_aggregate';
  aggregate?: Maybe<Community_Staffpicks_Aggregate_Fields>;
  nodes: Array<Community_Staffpicks>;
};

/** aggregate fields of "community_staffpicks" */
export type Community_Staffpicks_Aggregate_Fields = {
  __typename?: 'community_staffpicks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Community_Staffpicks_Max_Fields>;
  min?: Maybe<Community_Staffpicks_Min_Fields>;
};


/** aggregate fields of "community_staffpicks" */
export type Community_Staffpicks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_Staffpicks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "community_staffpicks". All fields are combined with a logical 'AND'. */
export type Community_Staffpicks_Bool_Exp = {
  _and?: InputMaybe<Array<Community_Staffpicks_Bool_Exp>>;
  _not?: InputMaybe<Community_Staffpicks_Bool_Exp>;
  _or?: InputMaybe<Array<Community_Staffpicks_Bool_Exp>>;
  community?: InputMaybe<Communities_Bool_Exp>;
  communityId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "community_staffpicks" */
export enum Community_Staffpicks_Constraint {
  /** unique or primary key constraint on columns "community_id" */
  CommunityStaffpicksCommunityIdUnique = 'community_staffpicks_community_id_unique',
  /** unique or primary key constraint on columns "id" */
  CommunityStaffpicksPkey = 'community_staffpicks_pkey'
}

/** input type for inserting data into table "community_staffpicks" */
export type Community_Staffpicks_Insert_Input = {
  community?: InputMaybe<Communities_Obj_Rel_Insert_Input>;
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Community_Staffpicks_Max_Fields = {
  __typename?: 'community_staffpicks_max_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Community_Staffpicks_Min_Fields = {
  __typename?: 'community_staffpicks_min_fields';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "community_staffpicks" */
export type Community_Staffpicks_Mutation_Response = {
  __typename?: 'community_staffpicks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Community_Staffpicks>;
};

/** input type for inserting object relation for remote table "community_staffpicks" */
export type Community_Staffpicks_Obj_Rel_Insert_Input = {
  data: Community_Staffpicks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Community_Staffpicks_On_Conflict>;
};

/** on_conflict condition type for table "community_staffpicks" */
export type Community_Staffpicks_On_Conflict = {
  constraint: Community_Staffpicks_Constraint;
  update_columns?: Array<Community_Staffpicks_Update_Column>;
  where?: InputMaybe<Community_Staffpicks_Bool_Exp>;
};

/** Ordering options when selecting data from "community_staffpicks". */
export type Community_Staffpicks_Order_By = {
  community?: InputMaybe<Communities_Order_By>;
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: community_staffpicks */
export type Community_Staffpicks_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "community_staffpicks" */
export enum Community_Staffpicks_Select_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "community_staffpicks" */
export type Community_Staffpicks_Set_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "community_staffpicks" */
export type Community_Staffpicks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Community_Staffpicks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Community_Staffpicks_Stream_Cursor_Value_Input = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "community_staffpicks" */
export enum Community_Staffpicks_Update_Column {
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Community_Staffpicks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Community_Staffpicks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Community_Staffpicks_Bool_Exp;
};

/** columns and relationships of "connected_nfts" */
export type Connected_Nfts = {
  __typename?: 'connected_nfts';
  chainId: Scalars['Int'];
  /** An array relationship */
  claimed_yields: Array<Claimed_Yield>;
  /** An aggregate relationship */
  claimed_yields_aggregate: Claimed_Yield_Aggregate;
  contractAddress: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  disconnectedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  nftImageAddress?: Maybe<Scalars['String']>;
  nftName?: Maybe<Scalars['String']>;
  nftTokenId: Scalars['String'];
  nftTokenUri?: Maybe<Scalars['String']>;
  nftType?: Maybe<Scalars['String']>;
  nft_description?: Maybe<Scalars['String']>;
  nft_image_id?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  quarantineTill?: Maybe<Scalars['timestamptz']>;
  sbt: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  verifiedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  verifiedNftProject?: Maybe<Verified_Nft_Projects>;
  verifiedNftProjectId: Scalars['Int'];
  walletAddress: Scalars['String'];
  /** An object relationship */
  walletsResolve?: Maybe<Wallets_Resolves>;
  walletsResolvesId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "connected_nfts" */
export type Connected_NftsClaimed_YieldsArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_Order_By>>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};


/** columns and relationships of "connected_nfts" */
export type Connected_NftsClaimed_Yields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_Order_By>>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};


/** columns and relationships of "connected_nfts" */
export type Connected_NftsProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "connected_nfts" */
export type Connected_NftsProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};

/** aggregated selection of "connected_nfts" */
export type Connected_Nfts_Aggregate = {
  __typename?: 'connected_nfts_aggregate';
  aggregate?: Maybe<Connected_Nfts_Aggregate_Fields>;
  nodes: Array<Connected_Nfts>;
};

export type Connected_Nfts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Connected_Nfts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Connected_Nfts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Connected_Nfts_Aggregate_Bool_Exp_Count>;
};

export type Connected_Nfts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Connected_Nfts_Select_Column_Connected_Nfts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Connected_Nfts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Connected_Nfts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Connected_Nfts_Select_Column_Connected_Nfts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Connected_Nfts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Connected_Nfts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Connected_Nfts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "connected_nfts" */
export type Connected_Nfts_Aggregate_Fields = {
  __typename?: 'connected_nfts_aggregate_fields';
  avg?: Maybe<Connected_Nfts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Connected_Nfts_Max_Fields>;
  min?: Maybe<Connected_Nfts_Min_Fields>;
  stddev?: Maybe<Connected_Nfts_Stddev_Fields>;
  stddev_pop?: Maybe<Connected_Nfts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Connected_Nfts_Stddev_Samp_Fields>;
  sum?: Maybe<Connected_Nfts_Sum_Fields>;
  var_pop?: Maybe<Connected_Nfts_Var_Pop_Fields>;
  var_samp?: Maybe<Connected_Nfts_Var_Samp_Fields>;
  variance?: Maybe<Connected_Nfts_Variance_Fields>;
};


/** aggregate fields of "connected_nfts" */
export type Connected_Nfts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "connected_nfts" */
export type Connected_Nfts_Aggregate_Order_By = {
  avg?: InputMaybe<Connected_Nfts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Connected_Nfts_Max_Order_By>;
  min?: InputMaybe<Connected_Nfts_Min_Order_By>;
  stddev?: InputMaybe<Connected_Nfts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Connected_Nfts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Connected_Nfts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Connected_Nfts_Sum_Order_By>;
  var_pop?: InputMaybe<Connected_Nfts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Connected_Nfts_Var_Samp_Order_By>;
  variance?: InputMaybe<Connected_Nfts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "connected_nfts" */
export type Connected_Nfts_Arr_Rel_Insert_Input = {
  data: Array<Connected_Nfts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Connected_Nfts_On_Conflict>;
};

/** aggregate avg on columns */
export type Connected_Nfts_Avg_Fields = {
  __typename?: 'connected_nfts_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "connected_nfts" */
export type Connected_Nfts_Avg_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "connected_nfts". All fields are combined with a logical 'AND'. */
export type Connected_Nfts_Bool_Exp = {
  _and?: InputMaybe<Array<Connected_Nfts_Bool_Exp>>;
  _not?: InputMaybe<Connected_Nfts_Bool_Exp>;
  _or?: InputMaybe<Array<Connected_Nfts_Bool_Exp>>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  claimed_yields?: InputMaybe<Claimed_Yield_Bool_Exp>;
  claimed_yields_aggregate?: InputMaybe<Claimed_Yield_Aggregate_Bool_Exp>;
  contractAddress?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  disconnectedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  nftImageAddress?: InputMaybe<String_Comparison_Exp>;
  nftName?: InputMaybe<String_Comparison_Exp>;
  nftTokenId?: InputMaybe<String_Comparison_Exp>;
  nftTokenUri?: InputMaybe<String_Comparison_Exp>;
  nftType?: InputMaybe<String_Comparison_Exp>;
  nft_description?: InputMaybe<String_Comparison_Exp>;
  nft_image_id?: InputMaybe<String_Comparison_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Profiles_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Bool_Exp>;
  quarantineTill?: InputMaybe<Timestamptz_Comparison_Exp>;
  sbt?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  verifiedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  verifiedNftProject?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  verifiedNftProjectId?: InputMaybe<Int_Comparison_Exp>;
  walletAddress?: InputMaybe<String_Comparison_Exp>;
  walletsResolve?: InputMaybe<Wallets_Resolves_Bool_Exp>;
  walletsResolvesId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "connected_nfts" */
export enum Connected_Nfts_Constraint {
  /** unique or primary key constraint on columns "verified_nft_project_id", "nft_token_id" */
  ConnectedNftsNftUnique = 'connected_nfts_nft_unique',
  /** unique or primary key constraint on columns "id" */
  ConnectedNftsPkey = 'connected_nfts_pkey'
}

/** input type for incrementing numeric columns in table "connected_nfts" */
export type Connected_Nfts_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "connected_nfts" */
export type Connected_Nfts_Insert_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  claimed_yields?: InputMaybe<Claimed_Yield_Arr_Rel_Insert_Input>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  disconnectedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  nftImageAddress?: InputMaybe<Scalars['String']>;
  nftName?: InputMaybe<Scalars['String']>;
  nftTokenId?: InputMaybe<Scalars['String']>;
  nftTokenUri?: InputMaybe<Scalars['String']>;
  nftType?: InputMaybe<Scalars['String']>;
  nft_description?: InputMaybe<Scalars['String']>;
  nft_image_id?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Profiles_Arr_Rel_Insert_Input>;
  quarantineTill?: InputMaybe<Scalars['timestamptz']>;
  sbt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProject?: InputMaybe<Verified_Nft_Projects_Obj_Rel_Insert_Input>;
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
  walletAddress?: InputMaybe<Scalars['String']>;
  walletsResolve?: InputMaybe<Wallets_Resolves_Obj_Rel_Insert_Input>;
  walletsResolvesId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Connected_Nfts_Max_Fields = {
  __typename?: 'connected_nfts_max_fields';
  chainId?: Maybe<Scalars['Int']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  disconnectedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  nftImageAddress?: Maybe<Scalars['String']>;
  nftName?: Maybe<Scalars['String']>;
  nftTokenId?: Maybe<Scalars['String']>;
  nftTokenUri?: Maybe<Scalars['String']>;
  nftType?: Maybe<Scalars['String']>;
  nft_description?: Maybe<Scalars['String']>;
  nft_image_id?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  quarantineTill?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  verifiedAt?: Maybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: Maybe<Scalars['Int']>;
  walletAddress?: Maybe<Scalars['String']>;
  walletsResolvesId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "connected_nfts" */
export type Connected_Nfts_Max_Order_By = {
  chainId?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  disconnectedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nftImageAddress?: InputMaybe<Order_By>;
  nftName?: InputMaybe<Order_By>;
  nftTokenId?: InputMaybe<Order_By>;
  nftTokenUri?: InputMaybe<Order_By>;
  nftType?: InputMaybe<Order_By>;
  nft_description?: InputMaybe<Order_By>;
  nft_image_id?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  quarantineTill?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  verifiedAt?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
  walletAddress?: InputMaybe<Order_By>;
  walletsResolvesId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Connected_Nfts_Min_Fields = {
  __typename?: 'connected_nfts_min_fields';
  chainId?: Maybe<Scalars['Int']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  disconnectedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  nftImageAddress?: Maybe<Scalars['String']>;
  nftName?: Maybe<Scalars['String']>;
  nftTokenId?: Maybe<Scalars['String']>;
  nftTokenUri?: Maybe<Scalars['String']>;
  nftType?: Maybe<Scalars['String']>;
  nft_description?: Maybe<Scalars['String']>;
  nft_image_id?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  quarantineTill?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  verifiedAt?: Maybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: Maybe<Scalars['Int']>;
  walletAddress?: Maybe<Scalars['String']>;
  walletsResolvesId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "connected_nfts" */
export type Connected_Nfts_Min_Order_By = {
  chainId?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  disconnectedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nftImageAddress?: InputMaybe<Order_By>;
  nftName?: InputMaybe<Order_By>;
  nftTokenId?: InputMaybe<Order_By>;
  nftTokenUri?: InputMaybe<Order_By>;
  nftType?: InputMaybe<Order_By>;
  nft_description?: InputMaybe<Order_By>;
  nft_image_id?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  quarantineTill?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  verifiedAt?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
  walletAddress?: InputMaybe<Order_By>;
  walletsResolvesId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "connected_nfts" */
export type Connected_Nfts_Mutation_Response = {
  __typename?: 'connected_nfts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Connected_Nfts>;
};

/** input type for inserting object relation for remote table "connected_nfts" */
export type Connected_Nfts_Obj_Rel_Insert_Input = {
  data: Connected_Nfts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Connected_Nfts_On_Conflict>;
};

/** on_conflict condition type for table "connected_nfts" */
export type Connected_Nfts_On_Conflict = {
  constraint: Connected_Nfts_Constraint;
  update_columns?: Array<Connected_Nfts_Update_Column>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};

/** Ordering options when selecting data from "connected_nfts". */
export type Connected_Nfts_Order_By = {
  chainId?: InputMaybe<Order_By>;
  claimed_yields_aggregate?: InputMaybe<Claimed_Yield_Aggregate_Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  disconnectedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  nftImageAddress?: InputMaybe<Order_By>;
  nftName?: InputMaybe<Order_By>;
  nftTokenId?: InputMaybe<Order_By>;
  nftTokenUri?: InputMaybe<Order_By>;
  nftType?: InputMaybe<Order_By>;
  nft_description?: InputMaybe<Order_By>;
  nft_image_id?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Order_By>;
  quarantineTill?: InputMaybe<Order_By>;
  sbt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  verifiedAt?: InputMaybe<Order_By>;
  verifiedNftProject?: InputMaybe<Verified_Nft_Projects_Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
  walletAddress?: InputMaybe<Order_By>;
  walletsResolve?: InputMaybe<Wallets_Resolves_Order_By>;
  walletsResolvesId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: connected_nfts */
export type Connected_Nfts_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "connected_nfts" */
export enum Connected_Nfts_Select_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisconnectedAt = 'disconnectedAt',
  /** column name */
  Id = 'id',
  /** column name */
  NftImageAddress = 'nftImageAddress',
  /** column name */
  NftName = 'nftName',
  /** column name */
  NftTokenId = 'nftTokenId',
  /** column name */
  NftTokenUri = 'nftTokenUri',
  /** column name */
  NftType = 'nftType',
  /** column name */
  NftDescription = 'nft_description',
  /** column name */
  NftImageId = 'nft_image_id',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  QuarantineTill = 'quarantineTill',
  /** column name */
  Sbt = 'sbt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VerifiedAt = 'verifiedAt',
  /** column name */
  VerifiedNftProjectId = 'verifiedNftProjectId',
  /** column name */
  WalletAddress = 'walletAddress',
  /** column name */
  WalletsResolvesId = 'walletsResolvesId'
}

/** select "connected_nfts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "connected_nfts" */
export enum Connected_Nfts_Select_Column_Connected_Nfts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Sbt = 'sbt'
}

/** select "connected_nfts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "connected_nfts" */
export enum Connected_Nfts_Select_Column_Connected_Nfts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Sbt = 'sbt'
}

/** input type for updating data in table "connected_nfts" */
export type Connected_Nfts_Set_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  disconnectedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  nftImageAddress?: InputMaybe<Scalars['String']>;
  nftName?: InputMaybe<Scalars['String']>;
  nftTokenId?: InputMaybe<Scalars['String']>;
  nftTokenUri?: InputMaybe<Scalars['String']>;
  nftType?: InputMaybe<Scalars['String']>;
  nft_description?: InputMaybe<Scalars['String']>;
  nft_image_id?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  quarantineTill?: InputMaybe<Scalars['timestamptz']>;
  sbt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
  walletAddress?: InputMaybe<Scalars['String']>;
  walletsResolvesId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Connected_Nfts_Stddev_Fields = {
  __typename?: 'connected_nfts_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "connected_nfts" */
export type Connected_Nfts_Stddev_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Connected_Nfts_Stddev_Pop_Fields = {
  __typename?: 'connected_nfts_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "connected_nfts" */
export type Connected_Nfts_Stddev_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Connected_Nfts_Stddev_Samp_Fields = {
  __typename?: 'connected_nfts_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "connected_nfts" */
export type Connected_Nfts_Stddev_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "connected_nfts" */
export type Connected_Nfts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Connected_Nfts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Connected_Nfts_Stream_Cursor_Value_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  disconnectedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  nftImageAddress?: InputMaybe<Scalars['String']>;
  nftName?: InputMaybe<Scalars['String']>;
  nftTokenId?: InputMaybe<Scalars['String']>;
  nftTokenUri?: InputMaybe<Scalars['String']>;
  nftType?: InputMaybe<Scalars['String']>;
  nft_description?: InputMaybe<Scalars['String']>;
  nft_image_id?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  quarantineTill?: InputMaybe<Scalars['timestamptz']>;
  sbt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjectId?: InputMaybe<Scalars['Int']>;
  walletAddress?: InputMaybe<Scalars['String']>;
  walletsResolvesId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Connected_Nfts_Sum_Fields = {
  __typename?: 'connected_nfts_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  verifiedNftProjectId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "connected_nfts" */
export type Connected_Nfts_Sum_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** update columns of table "connected_nfts" */
export enum Connected_Nfts_Update_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisconnectedAt = 'disconnectedAt',
  /** column name */
  Id = 'id',
  /** column name */
  NftImageAddress = 'nftImageAddress',
  /** column name */
  NftName = 'nftName',
  /** column name */
  NftTokenId = 'nftTokenId',
  /** column name */
  NftTokenUri = 'nftTokenUri',
  /** column name */
  NftType = 'nftType',
  /** column name */
  NftDescription = 'nft_description',
  /** column name */
  NftImageId = 'nft_image_id',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  QuarantineTill = 'quarantineTill',
  /** column name */
  Sbt = 'sbt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VerifiedAt = 'verifiedAt',
  /** column name */
  VerifiedNftProjectId = 'verifiedNftProjectId',
  /** column name */
  WalletAddress = 'walletAddress',
  /** column name */
  WalletsResolvesId = 'walletsResolvesId'
}

export type Connected_Nfts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Connected_Nfts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Connected_Nfts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Connected_Nfts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Connected_Nfts_Var_Pop_Fields = {
  __typename?: 'connected_nfts_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "connected_nfts" */
export type Connected_Nfts_Var_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Connected_Nfts_Var_Samp_Fields = {
  __typename?: 'connected_nfts_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "connected_nfts" */
export type Connected_Nfts_Var_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Connected_Nfts_Variance_Fields = {
  __typename?: 'connected_nfts_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  verifiedNftProjectId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "connected_nfts" */
export type Connected_Nfts_Variance_Order_By = {
  chainId?: InputMaybe<Order_By>;
  verifiedNftProjectId?: InputMaybe<Order_By>;
};

/** columns and relationships of "continents" */
export type Continents = {
  __typename?: 'continents';
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  /** An array relationship */
  countries: Array<Countries>;
  /** An aggregate relationship */
  countries_aggregate: Countries_Aggregate;
  id: Scalars['String'];
  isoCode: Scalars['String'];
  nameEn: Scalars['String'];
};


/** columns and relationships of "continents" */
export type ContinentsCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "continents" */
export type ContinentsCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "continents" */
export type ContinentsCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


/** columns and relationships of "continents" */
export type ContinentsCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

/** aggregated selection of "continents" */
export type Continents_Aggregate = {
  __typename?: 'continents_aggregate';
  aggregate?: Maybe<Continents_Aggregate_Fields>;
  nodes: Array<Continents>;
};

/** aggregate fields of "continents" */
export type Continents_Aggregate_Fields = {
  __typename?: 'continents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Continents_Max_Fields>;
  min?: Maybe<Continents_Min_Fields>;
};


/** aggregate fields of "continents" */
export type Continents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Continents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "continents". All fields are combined with a logical 'AND'. */
export type Continents_Bool_Exp = {
  _and?: InputMaybe<Array<Continents_Bool_Exp>>;
  _not?: InputMaybe<Continents_Bool_Exp>;
  _or?: InputMaybe<Array<Continents_Bool_Exp>>;
  communities?: InputMaybe<Communities_Bool_Exp>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Bool_Exp>;
  countries?: InputMaybe<Countries_Bool_Exp>;
  countries_aggregate?: InputMaybe<Countries_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isoCode?: InputMaybe<String_Comparison_Exp>;
  nameEn?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "continents" */
export enum Continents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContinentsPkey = 'continents_pkey'
}

/** input type for inserting data into table "continents" */
export type Continents_Insert_Input = {
  communities?: InputMaybe<Communities_Arr_Rel_Insert_Input>;
  countries?: InputMaybe<Countries_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Continents_Max_Fields = {
  __typename?: 'continents_max_fields';
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Continents_Min_Fields = {
  __typename?: 'continents_min_fields';
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "continents" */
export type Continents_Mutation_Response = {
  __typename?: 'continents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Continents>;
};

/** input type for inserting object relation for remote table "continents" */
export type Continents_Obj_Rel_Insert_Input = {
  data: Continents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Continents_On_Conflict>;
};

/** on_conflict condition type for table "continents" */
export type Continents_On_Conflict = {
  constraint: Continents_Constraint;
  update_columns?: Array<Continents_Update_Column>;
  where?: InputMaybe<Continents_Bool_Exp>;
};

/** Ordering options when selecting data from "continents". */
export type Continents_Order_By = {
  communities_aggregate?: InputMaybe<Communities_Aggregate_Order_By>;
  countries_aggregate?: InputMaybe<Countries_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
};

/** primary key columns input for table: continents */
export type Continents_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "continents" */
export enum Continents_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn'
}

/** input type for updating data in table "continents" */
export type Continents_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "continents" */
export type Continents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Continents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Continents_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** update columns of table "continents" */
export enum Continents_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn'
}

export type Continents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Continents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Continents_Bool_Exp;
};

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'countries';
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  /** An object relationship */
  continent: Continents;
  continentId: Scalars['String'];
  id: Scalars['String'];
  isoCode: Scalars['String'];
  nameEn: Scalars['String'];
  /** An array relationship */
  subdivision: Array<Subdivisions>;
  /** An aggregate relationship */
  subdivision_aggregate: Subdivisions_Aggregate;
};


/** columns and relationships of "countries" */
export type CountriesCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "countries" */
export type CountriesCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "countries" */
export type CountriesSubdivisionArgs = {
  distinct_on?: InputMaybe<Array<Subdivisions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subdivisions_Order_By>>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};


/** columns and relationships of "countries" */
export type CountriesSubdivision_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subdivisions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subdivisions_Order_By>>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};

/** aggregated selection of "countries" */
export type Countries_Aggregate = {
  __typename?: 'countries_aggregate';
  aggregate?: Maybe<Countries_Aggregate_Fields>;
  nodes: Array<Countries>;
};

export type Countries_Aggregate_Bool_Exp = {
  count?: InputMaybe<Countries_Aggregate_Bool_Exp_Count>;
};

export type Countries_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Countries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Countries_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "countries" */
export type Countries_Aggregate_Fields = {
  __typename?: 'countries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Countries_Max_Fields>;
  min?: Maybe<Countries_Min_Fields>;
};


/** aggregate fields of "countries" */
export type Countries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Countries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "countries" */
export type Countries_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Countries_Max_Order_By>;
  min?: InputMaybe<Countries_Min_Order_By>;
};

/** input type for inserting array relation for remote table "countries" */
export type Countries_Arr_Rel_Insert_Input = {
  data: Array<Countries_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: InputMaybe<Array<Countries_Bool_Exp>>;
  _not?: InputMaybe<Countries_Bool_Exp>;
  _or?: InputMaybe<Array<Countries_Bool_Exp>>;
  communities?: InputMaybe<Communities_Bool_Exp>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Bool_Exp>;
  continent?: InputMaybe<Continents_Bool_Exp>;
  continentId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isoCode?: InputMaybe<String_Comparison_Exp>;
  nameEn?: InputMaybe<String_Comparison_Exp>;
  subdivision?: InputMaybe<Subdivisions_Bool_Exp>;
  subdivision_aggregate?: InputMaybe<Subdivisions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "countries" */
export enum Countries_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountriesPkey = 'countries_pkey'
}

/** input type for inserting data into table "countries" */
export type Countries_Insert_Input = {
  communities?: InputMaybe<Communities_Arr_Rel_Insert_Input>;
  continent?: InputMaybe<Continents_Obj_Rel_Insert_Input>;
  continentId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  subdivision?: InputMaybe<Subdivisions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Countries_Max_Fields = {
  __typename?: 'countries_max_fields';
  continentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "countries" */
export type Countries_Max_Order_By = {
  continentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Countries_Min_Fields = {
  __typename?: 'countries_min_fields';
  continentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "countries" */
export type Countries_Min_Order_By = {
  continentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "countries" */
export type Countries_Mutation_Response = {
  __typename?: 'countries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** input type for inserting object relation for remote table "countries" */
export type Countries_Obj_Rel_Insert_Input = {
  data: Countries_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};

/** on_conflict condition type for table "countries" */
export type Countries_On_Conflict = {
  constraint: Countries_Constraint;
  update_columns?: Array<Countries_Update_Column>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

/** Ordering options when selecting data from "countries". */
export type Countries_Order_By = {
  communities_aggregate?: InputMaybe<Communities_Aggregate_Order_By>;
  continent?: InputMaybe<Continents_Order_By>;
  continentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
  subdivision_aggregate?: InputMaybe<Subdivisions_Aggregate_Order_By>;
};

/** primary key columns input for table: countries */
export type Countries_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "countries" */
export enum Countries_Select_Column {
  /** column name */
  ContinentId = 'continentId',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn'
}

/** input type for updating data in table "countries" */
export type Countries_Set_Input = {
  continentId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "countries" */
export type Countries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Countries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Countries_Stream_Cursor_Value_Input = {
  continentId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** update columns of table "countries" */
export enum Countries_Update_Column {
  /** column name */
  ContinentId = 'continentId',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn'
}

export type Countries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Countries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Countries_Bool_Exp;
};

export type CreatePostResult = {
  __typename?: 'createPostResult';
  farcasterError?: Maybe<HasuraErrorCode>;
  lensError?: Maybe<HasuraErrorCode>;
  post?: Maybe<Recommendations>;
  postId: Scalars['String'];
  status: Scalars['String'];
};

/** columns and relationships of "cred_scores" */
export type Cred_Scores = {
  __typename?: 'cred_scores';
  createdAt: Scalars['timestamptz'];
  credClaimedAt?: Maybe<Scalars['timestamptz']>;
  credScore: Scalars['Int'];
  initialQualificationStart: Scalars['timestamptz'];
  latestCredClaimed?: Maybe<Scalars['Int']>;
  level: Scalars['Int'];
  /** An object relationship */
  levelByLevel: Levels;
  nextQualificationStart: Scalars['timestamptz'];
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  qualifyingCredScore: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "cred_scores" */
export type Cred_Scores_Aggregate = {
  __typename?: 'cred_scores_aggregate';
  aggregate?: Maybe<Cred_Scores_Aggregate_Fields>;
  nodes: Array<Cred_Scores>;
};

export type Cred_Scores_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cred_Scores_Aggregate_Bool_Exp_Count>;
};

export type Cred_Scores_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cred_Scores_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cred_scores" */
export type Cred_Scores_Aggregate_Fields = {
  __typename?: 'cred_scores_aggregate_fields';
  avg?: Maybe<Cred_Scores_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cred_Scores_Max_Fields>;
  min?: Maybe<Cred_Scores_Min_Fields>;
  stddev?: Maybe<Cred_Scores_Stddev_Fields>;
  stddev_pop?: Maybe<Cred_Scores_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cred_Scores_Stddev_Samp_Fields>;
  sum?: Maybe<Cred_Scores_Sum_Fields>;
  var_pop?: Maybe<Cred_Scores_Var_Pop_Fields>;
  var_samp?: Maybe<Cred_Scores_Var_Samp_Fields>;
  variance?: Maybe<Cred_Scores_Variance_Fields>;
};


/** aggregate fields of "cred_scores" */
export type Cred_Scores_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cred_scores" */
export type Cred_Scores_Aggregate_Order_By = {
  avg?: InputMaybe<Cred_Scores_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cred_Scores_Max_Order_By>;
  min?: InputMaybe<Cred_Scores_Min_Order_By>;
  stddev?: InputMaybe<Cred_Scores_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cred_Scores_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cred_Scores_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cred_Scores_Sum_Order_By>;
  var_pop?: InputMaybe<Cred_Scores_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cred_Scores_Var_Samp_Order_By>;
  variance?: InputMaybe<Cred_Scores_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cred_scores" */
export type Cred_Scores_Arr_Rel_Insert_Input = {
  data: Array<Cred_Scores_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cred_Scores_On_Conflict>;
};

/** aggregate avg on columns */
export type Cred_Scores_Avg_Fields = {
  __typename?: 'cred_scores_avg_fields';
  credScore?: Maybe<Scalars['Float']>;
  latestCredClaimed?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  qualifyingCredScore?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cred_scores" */
export type Cred_Scores_Avg_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cred_scores". All fields are combined with a logical 'AND'. */
export type Cred_Scores_Bool_Exp = {
  _and?: InputMaybe<Array<Cred_Scores_Bool_Exp>>;
  _not?: InputMaybe<Cred_Scores_Bool_Exp>;
  _or?: InputMaybe<Array<Cred_Scores_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credClaimedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credScore?: InputMaybe<Int_Comparison_Exp>;
  initialQualificationStart?: InputMaybe<Timestamptz_Comparison_Exp>;
  latestCredClaimed?: InputMaybe<Int_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  levelByLevel?: InputMaybe<Levels_Bool_Exp>;
  nextQualificationStart?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  qualifyingCredScore?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cred_scores" */
export enum Cred_Scores_Constraint {
  /** unique or primary key constraint on columns "profile_id" */
  CredScoresPkey = 'cred_scores_pkey'
}

/** input type for incrementing numeric columns in table "cred_scores" */
export type Cred_Scores_Inc_Input = {
  credScore?: InputMaybe<Scalars['Int']>;
  latestCredClaimed?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  qualifyingCredScore?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "cred_scores" */
export type Cred_Scores_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credClaimedAt?: InputMaybe<Scalars['timestamptz']>;
  credScore?: InputMaybe<Scalars['Int']>;
  initialQualificationStart?: InputMaybe<Scalars['timestamptz']>;
  latestCredClaimed?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  levelByLevel?: InputMaybe<Levels_Obj_Rel_Insert_Input>;
  nextQualificationStart?: InputMaybe<Scalars['timestamptz']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  qualifyingCredScore?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cred_Scores_Max_Fields = {
  __typename?: 'cred_scores_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credClaimedAt?: Maybe<Scalars['timestamptz']>;
  credScore?: Maybe<Scalars['Int']>;
  initialQualificationStart?: Maybe<Scalars['timestamptz']>;
  latestCredClaimed?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  nextQualificationStart?: Maybe<Scalars['timestamptz']>;
  profileId?: Maybe<Scalars['String']>;
  qualifyingCredScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "cred_scores" */
export type Cred_Scores_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credClaimedAt?: InputMaybe<Order_By>;
  credScore?: InputMaybe<Order_By>;
  initialQualificationStart?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  nextQualificationStart?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cred_Scores_Min_Fields = {
  __typename?: 'cred_scores_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credClaimedAt?: Maybe<Scalars['timestamptz']>;
  credScore?: Maybe<Scalars['Int']>;
  initialQualificationStart?: Maybe<Scalars['timestamptz']>;
  latestCredClaimed?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  nextQualificationStart?: Maybe<Scalars['timestamptz']>;
  profileId?: Maybe<Scalars['String']>;
  qualifyingCredScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "cred_scores" */
export type Cred_Scores_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credClaimedAt?: InputMaybe<Order_By>;
  credScore?: InputMaybe<Order_By>;
  initialQualificationStart?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  nextQualificationStart?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cred_scores" */
export type Cred_Scores_Mutation_Response = {
  __typename?: 'cred_scores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cred_Scores>;
};

/** on_conflict condition type for table "cred_scores" */
export type Cred_Scores_On_Conflict = {
  constraint: Cred_Scores_Constraint;
  update_columns?: Array<Cred_Scores_Update_Column>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};

/** Ordering options when selecting data from "cred_scores". */
export type Cred_Scores_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credClaimedAt?: InputMaybe<Order_By>;
  credScore?: InputMaybe<Order_By>;
  initialQualificationStart?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  levelByLevel?: InputMaybe<Levels_Order_By>;
  nextQualificationStart?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cred_scores */
export type Cred_Scores_Pk_Columns_Input = {
  profileId: Scalars['String'];
};

/** select columns of table "cred_scores" */
export enum Cred_Scores_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredClaimedAt = 'credClaimedAt',
  /** column name */
  CredScore = 'credScore',
  /** column name */
  InitialQualificationStart = 'initialQualificationStart',
  /** column name */
  LatestCredClaimed = 'latestCredClaimed',
  /** column name */
  Level = 'level',
  /** column name */
  NextQualificationStart = 'nextQualificationStart',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  QualifyingCredScore = 'qualifyingCredScore',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "cred_scores" */
export type Cred_Scores_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credClaimedAt?: InputMaybe<Scalars['timestamptz']>;
  credScore?: InputMaybe<Scalars['Int']>;
  initialQualificationStart?: InputMaybe<Scalars['timestamptz']>;
  latestCredClaimed?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  nextQualificationStart?: InputMaybe<Scalars['timestamptz']>;
  profileId?: InputMaybe<Scalars['String']>;
  qualifyingCredScore?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Cred_Scores_Stddev_Fields = {
  __typename?: 'cred_scores_stddev_fields';
  credScore?: Maybe<Scalars['Float']>;
  latestCredClaimed?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  qualifyingCredScore?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cred_scores" */
export type Cred_Scores_Stddev_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cred_Scores_Stddev_Pop_Fields = {
  __typename?: 'cred_scores_stddev_pop_fields';
  credScore?: Maybe<Scalars['Float']>;
  latestCredClaimed?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  qualifyingCredScore?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cred_scores" */
export type Cred_Scores_Stddev_Pop_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cred_Scores_Stddev_Samp_Fields = {
  __typename?: 'cred_scores_stddev_samp_fields';
  credScore?: Maybe<Scalars['Float']>;
  latestCredClaimed?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  qualifyingCredScore?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cred_scores" */
export type Cred_Scores_Stddev_Samp_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cred_scores" */
export type Cred_Scores_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cred_Scores_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cred_Scores_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credClaimedAt?: InputMaybe<Scalars['timestamptz']>;
  credScore?: InputMaybe<Scalars['Int']>;
  initialQualificationStart?: InputMaybe<Scalars['timestamptz']>;
  latestCredClaimed?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  nextQualificationStart?: InputMaybe<Scalars['timestamptz']>;
  profileId?: InputMaybe<Scalars['String']>;
  qualifyingCredScore?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Cred_Scores_Sum_Fields = {
  __typename?: 'cred_scores_sum_fields';
  credScore?: Maybe<Scalars['Int']>;
  latestCredClaimed?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  qualifyingCredScore?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cred_scores" */
export type Cred_Scores_Sum_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** update columns of table "cred_scores" */
export enum Cred_Scores_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredClaimedAt = 'credClaimedAt',
  /** column name */
  CredScore = 'credScore',
  /** column name */
  InitialQualificationStart = 'initialQualificationStart',
  /** column name */
  LatestCredClaimed = 'latestCredClaimed',
  /** column name */
  Level = 'level',
  /** column name */
  NextQualificationStart = 'nextQualificationStart',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  QualifyingCredScore = 'qualifyingCredScore',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Cred_Scores_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cred_Scores_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cred_Scores_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cred_Scores_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cred_Scores_Var_Pop_Fields = {
  __typename?: 'cred_scores_var_pop_fields';
  credScore?: Maybe<Scalars['Float']>;
  latestCredClaimed?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  qualifyingCredScore?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cred_scores" */
export type Cred_Scores_Var_Pop_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cred_Scores_Var_Samp_Fields = {
  __typename?: 'cred_scores_var_samp_fields';
  credScore?: Maybe<Scalars['Float']>;
  latestCredClaimed?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  qualifyingCredScore?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cred_scores" */
export type Cred_Scores_Var_Samp_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cred_Scores_Variance_Fields = {
  __typename?: 'cred_scores_variance_fields';
  credScore?: Maybe<Scalars['Float']>;
  latestCredClaimed?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  qualifyingCredScore?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cred_scores" */
export type Cred_Scores_Variance_Order_By = {
  credScore?: InputMaybe<Order_By>;
  latestCredClaimed?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  qualifyingCredScore?: InputMaybe<Order_By>;
};

/** columns and relationships of "currency_isocodes" */
export type Currency_Isocodes = {
  __typename?: 'currency_isocodes';
  value: Scalars['String'];
};

/** aggregated selection of "currency_isocodes" */
export type Currency_Isocodes_Aggregate = {
  __typename?: 'currency_isocodes_aggregate';
  aggregate?: Maybe<Currency_Isocodes_Aggregate_Fields>;
  nodes: Array<Currency_Isocodes>;
};

/** aggregate fields of "currency_isocodes" */
export type Currency_Isocodes_Aggregate_Fields = {
  __typename?: 'currency_isocodes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Currency_Isocodes_Max_Fields>;
  min?: Maybe<Currency_Isocodes_Min_Fields>;
};


/** aggregate fields of "currency_isocodes" */
export type Currency_Isocodes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_Isocodes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "currency_isocodes". All fields are combined with a logical 'AND'. */
export type Currency_Isocodes_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_Isocodes_Bool_Exp>>;
  _not?: InputMaybe<Currency_Isocodes_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_Isocodes_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency_isocodes" */
export enum Currency_Isocodes_Constraint {
  /** unique or primary key constraint on columns "value" */
  CurrencyIsocodesPkey = 'currency_isocodes_pkey'
}

/** input type for inserting data into table "currency_isocodes" */
export type Currency_Isocodes_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Currency_Isocodes_Max_Fields = {
  __typename?: 'currency_isocodes_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Currency_Isocodes_Min_Fields = {
  __typename?: 'currency_isocodes_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "currency_isocodes" */
export type Currency_Isocodes_Mutation_Response = {
  __typename?: 'currency_isocodes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency_Isocodes>;
};

/** on_conflict condition type for table "currency_isocodes" */
export type Currency_Isocodes_On_Conflict = {
  constraint: Currency_Isocodes_Constraint;
  update_columns?: Array<Currency_Isocodes_Update_Column>;
  where?: InputMaybe<Currency_Isocodes_Bool_Exp>;
};

/** Ordering options when selecting data from "currency_isocodes". */
export type Currency_Isocodes_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency_isocodes */
export type Currency_Isocodes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "currency_isocodes" */
export enum Currency_Isocodes_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "currency_isocodes" */
export type Currency_Isocodes_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "currency_isocodes" */
export type Currency_Isocodes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_Isocodes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_Isocodes_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "currency_isocodes" */
export enum Currency_Isocodes_Update_Column {
  /** column name */
  Value = 'value'
}

export type Currency_Isocodes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_Isocodes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currency_Isocodes_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export type DailyStakingQuotaReply = {
  __typename?: 'dailyStakingQuotaReply';
  available: Scalars['Int'];
  maximum: Scalars['Int'];
};

export type DailyStakingUseReply = {
  __typename?: 'dailyStakingUseReply';
  available: Scalars['Int'];
  dailyStaking?: Maybe<Daily_Staking>;
  id: Scalars['String'];
  maximum: Scalars['Int'];
};

/** columns and relationships of "daily_staking" */
export type Daily_Staking = {
  __typename?: 'daily_staking';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
};

/** aggregated selection of "daily_staking" */
export type Daily_Staking_Aggregate = {
  __typename?: 'daily_staking_aggregate';
  aggregate?: Maybe<Daily_Staking_Aggregate_Fields>;
  nodes: Array<Daily_Staking>;
};

export type Daily_Staking_Aggregate_Bool_Exp = {
  count?: InputMaybe<Daily_Staking_Aggregate_Bool_Exp_Count>;
};

export type Daily_Staking_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Daily_Staking_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "daily_staking" */
export type Daily_Staking_Aggregate_Fields = {
  __typename?: 'daily_staking_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Daily_Staking_Max_Fields>;
  min?: Maybe<Daily_Staking_Min_Fields>;
};


/** aggregate fields of "daily_staking" */
export type Daily_Staking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "daily_staking" */
export type Daily_Staking_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Daily_Staking_Max_Order_By>;
  min?: InputMaybe<Daily_Staking_Min_Order_By>;
};

/** input type for inserting array relation for remote table "daily_staking" */
export type Daily_Staking_Arr_Rel_Insert_Input = {
  data: Array<Daily_Staking_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Daily_Staking_On_Conflict>;
};

/** Boolean expression to filter rows from the table "daily_staking". All fields are combined with a logical 'AND'. */
export type Daily_Staking_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Staking_Bool_Exp>>;
  _not?: InputMaybe<Daily_Staking_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Staking_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "daily_staking" */
export enum Daily_Staking_Constraint {
  /** unique or primary key constraint on columns "id" */
  DailyStakingPkey = 'daily_staking_pkey',
  /** unique or primary key constraint on columns "profile_id", "post_id" */
  DailyStakingPostIdProfileIdUnique = 'daily_staking_post_id_profile_id_unique'
}

/** input type for inserting data into table "daily_staking" */
export type Daily_Staking_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Daily_Staking_Max_Fields = {
  __typename?: 'daily_staking_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "daily_staking" */
export type Daily_Staking_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Daily_Staking_Min_Fields = {
  __typename?: 'daily_staking_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "daily_staking" */
export type Daily_Staking_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "daily_staking" */
export type Daily_Staking_Mutation_Response = {
  __typename?: 'daily_staking_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Daily_Staking>;
};

/** input type for inserting object relation for remote table "daily_staking" */
export type Daily_Staking_Obj_Rel_Insert_Input = {
  data: Daily_Staking_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Daily_Staking_On_Conflict>;
};

/** on_conflict condition type for table "daily_staking" */
export type Daily_Staking_On_Conflict = {
  constraint: Daily_Staking_Constraint;
  update_columns?: Array<Daily_Staking_Update_Column>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};

/** Ordering options when selecting data from "daily_staking". */
export type Daily_Staking_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: daily_staking */
export type Daily_Staking_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "daily_staking" */
export enum Daily_Staking_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "daily_staking" */
export type Daily_Staking_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "daily_staking" */
export type Daily_Staking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Staking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Staking_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "daily_staking" */
export enum Daily_Staking_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId'
}

export type Daily_Staking_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Daily_Staking_Set_Input>;
  /** filter the rows which have to be updated */
  where: Daily_Staking_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export enum DispatcherTypes {
  LensApi = 'lensApi',
  Phaver = 'phaver'
}

/** columns and relationships of "embedded_urls" */
export type Embedded_Urls = {
  __typename?: 'embedded_urls';
  content_length?: Maybe<Scalars['Int']>;
  content_type: Scalars['String'];
  created_at: Scalars['timestamp'];
  id: Scalars['Int'];
  is_frame: Scalars['Boolean'];
  updated_at: Scalars['timestamp'];
  url: Scalars['String'];
  url_hash?: Maybe<Scalars['bytea']>;
};

/** aggregated selection of "embedded_urls" */
export type Embedded_Urls_Aggregate = {
  __typename?: 'embedded_urls_aggregate';
  aggregate?: Maybe<Embedded_Urls_Aggregate_Fields>;
  nodes: Array<Embedded_Urls>;
};

/** aggregate fields of "embedded_urls" */
export type Embedded_Urls_Aggregate_Fields = {
  __typename?: 'embedded_urls_aggregate_fields';
  avg?: Maybe<Embedded_Urls_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Embedded_Urls_Max_Fields>;
  min?: Maybe<Embedded_Urls_Min_Fields>;
  stddev?: Maybe<Embedded_Urls_Stddev_Fields>;
  stddev_pop?: Maybe<Embedded_Urls_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Embedded_Urls_Stddev_Samp_Fields>;
  sum?: Maybe<Embedded_Urls_Sum_Fields>;
  var_pop?: Maybe<Embedded_Urls_Var_Pop_Fields>;
  var_samp?: Maybe<Embedded_Urls_Var_Samp_Fields>;
  variance?: Maybe<Embedded_Urls_Variance_Fields>;
};


/** aggregate fields of "embedded_urls" */
export type Embedded_Urls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Embedded_Urls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Embedded_Urls_Avg_Fields = {
  __typename?: 'embedded_urls_avg_fields';
  content_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "embedded_urls". All fields are combined with a logical 'AND'. */
export type Embedded_Urls_Bool_Exp = {
  _and?: InputMaybe<Array<Embedded_Urls_Bool_Exp>>;
  _not?: InputMaybe<Embedded_Urls_Bool_Exp>;
  _or?: InputMaybe<Array<Embedded_Urls_Bool_Exp>>;
  content_length?: InputMaybe<Int_Comparison_Exp>;
  content_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_frame?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  url_hash?: InputMaybe<Bytea_Comparison_Exp>;
};

/** unique or primary key constraints on table "embedded_urls" */
export enum Embedded_Urls_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmbeddedUrlsPkey = 'embedded_urls_pkey',
  /** unique or primary key constraint on columns "url_hash" */
  EmbeddedUrlsUrlHashUnique = 'embedded_urls_url_hash_unique'
}

/** input type for incrementing numeric columns in table "embedded_urls" */
export type Embedded_Urls_Inc_Input = {
  content_length?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "embedded_urls" */
export type Embedded_Urls_Insert_Input = {
  content_length?: InputMaybe<Scalars['Int']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_frame?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Embedded_Urls_Max_Fields = {
  __typename?: 'embedded_urls_max_fields';
  content_length?: Maybe<Scalars['Int']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Embedded_Urls_Min_Fields = {
  __typename?: 'embedded_urls_min_fields';
  content_length?: Maybe<Scalars['Int']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "embedded_urls" */
export type Embedded_Urls_Mutation_Response = {
  __typename?: 'embedded_urls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Embedded_Urls>;
};

/** on_conflict condition type for table "embedded_urls" */
export type Embedded_Urls_On_Conflict = {
  constraint: Embedded_Urls_Constraint;
  update_columns?: Array<Embedded_Urls_Update_Column>;
  where?: InputMaybe<Embedded_Urls_Bool_Exp>;
};

/** Ordering options when selecting data from "embedded_urls". */
export type Embedded_Urls_Order_By = {
  content_length?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_frame?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  url_hash?: InputMaybe<Order_By>;
};

/** primary key columns input for table: embedded_urls */
export type Embedded_Urls_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "embedded_urls" */
export enum Embedded_Urls_Select_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsFrame = 'is_frame',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UrlHash = 'url_hash'
}

/** input type for updating data in table "embedded_urls" */
export type Embedded_Urls_Set_Input = {
  content_length?: InputMaybe<Scalars['Int']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_frame?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Embedded_Urls_Stddev_Fields = {
  __typename?: 'embedded_urls_stddev_fields';
  content_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Embedded_Urls_Stddev_Pop_Fields = {
  __typename?: 'embedded_urls_stddev_pop_fields';
  content_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Embedded_Urls_Stddev_Samp_Fields = {
  __typename?: 'embedded_urls_stddev_samp_fields';
  content_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "embedded_urls" */
export type Embedded_Urls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Embedded_Urls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Embedded_Urls_Stream_Cursor_Value_Input = {
  content_length?: InputMaybe<Scalars['Int']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_frame?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
  url_hash?: InputMaybe<Scalars['bytea']>;
};

/** aggregate sum on columns */
export type Embedded_Urls_Sum_Fields = {
  __typename?: 'embedded_urls_sum_fields';
  content_length?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "embedded_urls" */
export enum Embedded_Urls_Update_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsFrame = 'is_frame',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Embedded_Urls_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Embedded_Urls_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Embedded_Urls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Embedded_Urls_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Embedded_Urls_Var_Pop_Fields = {
  __typename?: 'embedded_urls_var_pop_fields';
  content_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Embedded_Urls_Var_Samp_Fields = {
  __typename?: 'embedded_urls_var_samp_fields';
  content_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Embedded_Urls_Variance_Fields = {
  __typename?: 'embedded_urls_variance_fields';
  content_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "farcaster_casts" */
export type Farcaster_Casts = {
  __typename?: 'farcaster_casts';
  comment_id?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  embeds: Scalars['jsonb'];
  fid: Scalars['String'];
  fromPhaver: Scalars['Boolean'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mentions: Scalars['jsonb'];
  mentionsPositions: Scalars['jsonb'];
  parentFid?: Maybe<Scalars['Int']>;
  parentHash?: Maybe<Scalars['String']>;
  parentUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  rootParentHash?: Maybe<Scalars['String']>;
  rootParentUrl?: Maybe<Scalars['String']>;
  status: Async_Status_Enum;
  text: Scalars['String'];
  timestamp: Scalars['timestamptz'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "farcaster_casts" */
export type Farcaster_CastsEmbedsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "farcaster_casts" */
export type Farcaster_CastsMentionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "farcaster_casts" */
export type Farcaster_CastsMentionsPositionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "farcaster_casts" */
export type Farcaster_Casts_Aggregate = {
  __typename?: 'farcaster_casts_aggregate';
  aggregate?: Maybe<Farcaster_Casts_Aggregate_Fields>;
  nodes: Array<Farcaster_Casts>;
};

export type Farcaster_Casts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Farcaster_Casts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Farcaster_Casts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Farcaster_Casts_Aggregate_Bool_Exp_Count>;
};

export type Farcaster_Casts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Farcaster_Casts_Select_Column_Farcaster_Casts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Farcaster_Casts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Farcaster_Casts_Select_Column_Farcaster_Casts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Farcaster_Casts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "farcaster_casts" */
export type Farcaster_Casts_Aggregate_Fields = {
  __typename?: 'farcaster_casts_aggregate_fields';
  avg?: Maybe<Farcaster_Casts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Farcaster_Casts_Max_Fields>;
  min?: Maybe<Farcaster_Casts_Min_Fields>;
  stddev?: Maybe<Farcaster_Casts_Stddev_Fields>;
  stddev_pop?: Maybe<Farcaster_Casts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Farcaster_Casts_Stddev_Samp_Fields>;
  sum?: Maybe<Farcaster_Casts_Sum_Fields>;
  var_pop?: Maybe<Farcaster_Casts_Var_Pop_Fields>;
  var_samp?: Maybe<Farcaster_Casts_Var_Samp_Fields>;
  variance?: Maybe<Farcaster_Casts_Variance_Fields>;
};


/** aggregate fields of "farcaster_casts" */
export type Farcaster_Casts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "farcaster_casts" */
export type Farcaster_Casts_Aggregate_Order_By = {
  avg?: InputMaybe<Farcaster_Casts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Farcaster_Casts_Max_Order_By>;
  min?: InputMaybe<Farcaster_Casts_Min_Order_By>;
  stddev?: InputMaybe<Farcaster_Casts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Farcaster_Casts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Farcaster_Casts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Farcaster_Casts_Sum_Order_By>;
  var_pop?: InputMaybe<Farcaster_Casts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Farcaster_Casts_Var_Samp_Order_By>;
  variance?: InputMaybe<Farcaster_Casts_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Farcaster_Casts_Append_Input = {
  embeds?: InputMaybe<Scalars['jsonb']>;
  mentions?: InputMaybe<Scalars['jsonb']>;
  mentionsPositions?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "farcaster_casts" */
export type Farcaster_Casts_Arr_Rel_Insert_Input = {
  data: Array<Farcaster_Casts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Farcaster_Casts_On_Conflict>;
};

/** aggregate avg on columns */
export type Farcaster_Casts_Avg_Fields = {
  __typename?: 'farcaster_casts_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parentFid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "farcaster_casts". All fields are combined with a logical 'AND'. */
export type Farcaster_Casts_Bool_Exp = {
  _and?: InputMaybe<Array<Farcaster_Casts_Bool_Exp>>;
  _not?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  _or?: InputMaybe<Array<Farcaster_Casts_Bool_Exp>>;
  comment_id?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  embeds?: InputMaybe<Jsonb_Comparison_Exp>;
  fid?: InputMaybe<String_Comparison_Exp>;
  fromPhaver?: InputMaybe<Boolean_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mentions?: InputMaybe<Jsonb_Comparison_Exp>;
  mentionsPositions?: InputMaybe<Jsonb_Comparison_Exp>;
  parentFid?: InputMaybe<Int_Comparison_Exp>;
  parentHash?: InputMaybe<String_Comparison_Exp>;
  parentUrl?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  rootParentHash?: InputMaybe<String_Comparison_Exp>;
  rootParentUrl?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Async_Status_Enum_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "farcaster_casts" */
export enum Farcaster_Casts_Constraint {
  /** unique or primary key constraint on columns "hash" */
  FarcasterCastHashIdx = 'farcaster_cast_hash_idx',
  /** unique or primary key constraint on columns "id" */
  FarcasterCastsPkey = 'farcaster_casts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Farcaster_Casts_Delete_At_Path_Input = {
  embeds?: InputMaybe<Array<Scalars['String']>>;
  mentions?: InputMaybe<Array<Scalars['String']>>;
  mentionsPositions?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Farcaster_Casts_Delete_Elem_Input = {
  embeds?: InputMaybe<Scalars['Int']>;
  mentions?: InputMaybe<Scalars['Int']>;
  mentionsPositions?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Farcaster_Casts_Delete_Key_Input = {
  embeds?: InputMaybe<Scalars['String']>;
  mentions?: InputMaybe<Scalars['String']>;
  mentionsPositions?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "farcaster_casts" */
export type Farcaster_Casts_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  parentFid?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "farcaster_casts" */
export type Farcaster_Casts_Insert_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  embeds?: InputMaybe<Scalars['jsonb']>;
  fid?: InputMaybe<Scalars['String']>;
  fromPhaver?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mentions?: InputMaybe<Scalars['jsonb']>;
  mentionsPositions?: InputMaybe<Scalars['jsonb']>;
  parentFid?: InputMaybe<Scalars['Int']>;
  parentHash?: InputMaybe<Scalars['String']>;
  parentUrl?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  rootParentHash?: InputMaybe<Scalars['String']>;
  rootParentUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Farcaster_Casts_Max_Fields = {
  __typename?: 'farcaster_casts_max_fields';
  comment_id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  parentFid?: Maybe<Scalars['Int']>;
  parentHash?: Maybe<Scalars['String']>;
  parentUrl?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  rootParentHash?: Maybe<Scalars['String']>;
  rootParentUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  fid?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
  parentHash?: InputMaybe<Order_By>;
  parentUrl?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  rootParentHash?: InputMaybe<Order_By>;
  rootParentUrl?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Farcaster_Casts_Min_Fields = {
  __typename?: 'farcaster_casts_min_fields';
  comment_id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  parentFid?: Maybe<Scalars['Int']>;
  parentHash?: Maybe<Scalars['String']>;
  parentUrl?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  rootParentHash?: Maybe<Scalars['String']>;
  rootParentUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  fid?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
  parentHash?: InputMaybe<Order_By>;
  parentUrl?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  rootParentHash?: InputMaybe<Order_By>;
  rootParentUrl?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "farcaster_casts" */
export type Farcaster_Casts_Mutation_Response = {
  __typename?: 'farcaster_casts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Farcaster_Casts>;
};

/** input type for inserting object relation for remote table "farcaster_casts" */
export type Farcaster_Casts_Obj_Rel_Insert_Input = {
  data: Farcaster_Casts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Farcaster_Casts_On_Conflict>;
};

/** on_conflict condition type for table "farcaster_casts" */
export type Farcaster_Casts_On_Conflict = {
  constraint: Farcaster_Casts_Constraint;
  update_columns?: Array<Farcaster_Casts_Update_Column>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};

/** Ordering options when selecting data from "farcaster_casts". */
export type Farcaster_Casts_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  embeds?: InputMaybe<Order_By>;
  fid?: InputMaybe<Order_By>;
  fromPhaver?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentions?: InputMaybe<Order_By>;
  mentionsPositions?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
  parentHash?: InputMaybe<Order_By>;
  parentUrl?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  rootParentHash?: InputMaybe<Order_By>;
  rootParentUrl?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farcaster_casts */
export type Farcaster_Casts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Farcaster_Casts_Prepend_Input = {
  embeds?: InputMaybe<Scalars['jsonb']>;
  mentions?: InputMaybe<Scalars['jsonb']>;
  mentionsPositions?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "farcaster_casts" */
export enum Farcaster_Casts_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Embeds = 'embeds',
  /** column name */
  Fid = 'fid',
  /** column name */
  FromPhaver = 'fromPhaver',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Mentions = 'mentions',
  /** column name */
  MentionsPositions = 'mentionsPositions',
  /** column name */
  ParentFid = 'parentFid',
  /** column name */
  ParentHash = 'parentHash',
  /** column name */
  ParentUrl = 'parentUrl',
  /** column name */
  PostId = 'postId',
  /** column name */
  RootParentHash = 'rootParentHash',
  /** column name */
  RootParentUrl = 'rootParentUrl',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "farcaster_casts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "farcaster_casts" */
export enum Farcaster_Casts_Select_Column_Farcaster_Casts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FromPhaver = 'fromPhaver'
}

/** select "farcaster_casts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "farcaster_casts" */
export enum Farcaster_Casts_Select_Column_Farcaster_Casts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FromPhaver = 'fromPhaver'
}

/** input type for updating data in table "farcaster_casts" */
export type Farcaster_Casts_Set_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  embeds?: InputMaybe<Scalars['jsonb']>;
  fid?: InputMaybe<Scalars['String']>;
  fromPhaver?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mentions?: InputMaybe<Scalars['jsonb']>;
  mentionsPositions?: InputMaybe<Scalars['jsonb']>;
  parentFid?: InputMaybe<Scalars['Int']>;
  parentHash?: InputMaybe<Scalars['String']>;
  parentUrl?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  rootParentHash?: InputMaybe<Scalars['String']>;
  rootParentUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Farcaster_Casts_Stddev_Fields = {
  __typename?: 'farcaster_casts_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parentFid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Farcaster_Casts_Stddev_Pop_Fields = {
  __typename?: 'farcaster_casts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parentFid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Farcaster_Casts_Stddev_Samp_Fields = {
  __typename?: 'farcaster_casts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parentFid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "farcaster_casts" */
export type Farcaster_Casts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farcaster_Casts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farcaster_Casts_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  embeds?: InputMaybe<Scalars['jsonb']>;
  fid?: InputMaybe<Scalars['String']>;
  fromPhaver?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mentions?: InputMaybe<Scalars['jsonb']>;
  mentionsPositions?: InputMaybe<Scalars['jsonb']>;
  parentFid?: InputMaybe<Scalars['Int']>;
  parentHash?: InputMaybe<Scalars['String']>;
  parentUrl?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  rootParentHash?: InputMaybe<Scalars['String']>;
  rootParentUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Farcaster_Casts_Sum_Fields = {
  __typename?: 'farcaster_casts_sum_fields';
  id?: Maybe<Scalars['Int']>;
  parentFid?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** update columns of table "farcaster_casts" */
export enum Farcaster_Casts_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Embeds = 'embeds',
  /** column name */
  Fid = 'fid',
  /** column name */
  FromPhaver = 'fromPhaver',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Mentions = 'mentions',
  /** column name */
  MentionsPositions = 'mentionsPositions',
  /** column name */
  ParentFid = 'parentFid',
  /** column name */
  ParentHash = 'parentHash',
  /** column name */
  ParentUrl = 'parentUrl',
  /** column name */
  PostId = 'postId',
  /** column name */
  RootParentHash = 'rootParentHash',
  /** column name */
  RootParentUrl = 'rootParentUrl',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Farcaster_Casts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Farcaster_Casts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Farcaster_Casts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Farcaster_Casts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Farcaster_Casts_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Farcaster_Casts_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Farcaster_Casts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farcaster_Casts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farcaster_Casts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Farcaster_Casts_Var_Pop_Fields = {
  __typename?: 'farcaster_casts_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parentFid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Farcaster_Casts_Var_Samp_Fields = {
  __typename?: 'farcaster_casts_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parentFid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Farcaster_Casts_Variance_Fields = {
  __typename?: 'farcaster_casts_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parentFid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "farcaster_casts" */
export type Farcaster_Casts_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parentFid?: InputMaybe<Order_By>;
};

/** columns and relationships of "farcaster_invites" */
export type Farcaster_Invites = {
  __typename?: 'farcaster_invites';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fid: Scalars['String'];
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  signerUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  wallet: Wallets;
  walletAddress: Scalars['String'];
};

/** aggregated selection of "farcaster_invites" */
export type Farcaster_Invites_Aggregate = {
  __typename?: 'farcaster_invites_aggregate';
  aggregate?: Maybe<Farcaster_Invites_Aggregate_Fields>;
  nodes: Array<Farcaster_Invites>;
};

/** aggregate fields of "farcaster_invites" */
export type Farcaster_Invites_Aggregate_Fields = {
  __typename?: 'farcaster_invites_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Farcaster_Invites_Max_Fields>;
  min?: Maybe<Farcaster_Invites_Min_Fields>;
};


/** aggregate fields of "farcaster_invites" */
export type Farcaster_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farcaster_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "farcaster_invites". All fields are combined with a logical 'AND'. */
export type Farcaster_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<Farcaster_Invites_Bool_Exp>>;
  _not?: InputMaybe<Farcaster_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<Farcaster_Invites_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fid?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  signerUuid?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  wallet?: InputMaybe<Wallets_Bool_Exp>;
  walletAddress?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "farcaster_invites" */
export enum Farcaster_Invites_Constraint {
  /** unique or primary key constraint on columns "profile_id" */
  FarcasterInvitesPkey = 'farcaster_invites_pkey'
}

/** input type for inserting data into table "farcaster_invites" */
export type Farcaster_Invites_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  signerUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  wallet?: InputMaybe<Wallets_Obj_Rel_Insert_Input>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Farcaster_Invites_Max_Fields = {
  __typename?: 'farcaster_invites_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  signerUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Farcaster_Invites_Min_Fields = {
  __typename?: 'farcaster_invites_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  signerUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "farcaster_invites" */
export type Farcaster_Invites_Mutation_Response = {
  __typename?: 'farcaster_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Farcaster_Invites>;
};

/** on_conflict condition type for table "farcaster_invites" */
export type Farcaster_Invites_On_Conflict = {
  constraint: Farcaster_Invites_Constraint;
  update_columns?: Array<Farcaster_Invites_Update_Column>;
  where?: InputMaybe<Farcaster_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "farcaster_invites". */
export type Farcaster_Invites_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fid?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  signerUuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Wallets_Order_By>;
  walletAddress?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farcaster_invites */
export type Farcaster_Invites_Pk_Columns_Input = {
  profileId: Scalars['String'];
};

/** select columns of table "farcaster_invites" */
export enum Farcaster_Invites_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  SignerUuid = 'signerUuid',
  /** column name */
  Status = 'status',
  /** column name */
  WalletAddress = 'walletAddress'
}

/** input type for updating data in table "farcaster_invites" */
export type Farcaster_Invites_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  signerUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "farcaster_invites" */
export type Farcaster_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farcaster_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farcaster_Invites_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  signerUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** update columns of table "farcaster_invites" */
export enum Farcaster_Invites_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  SignerUuid = 'signerUuid',
  /** column name */
  Status = 'status',
  /** column name */
  WalletAddress = 'walletAddress'
}

export type Farcaster_Invites_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farcaster_Invites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farcaster_Invites_Bool_Exp;
};

/** columns and relationships of "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users = {
  __typename?: 'farcaster_power_badge_users';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fetchedAt?: Maybe<Scalars['timestamptz']>;
  fid: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Aggregate = {
  __typename?: 'farcaster_power_badge_users_aggregate';
  aggregate?: Maybe<Farcaster_Power_Badge_Users_Aggregate_Fields>;
  nodes: Array<Farcaster_Power_Badge_Users>;
};

/** aggregate fields of "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Aggregate_Fields = {
  __typename?: 'farcaster_power_badge_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Farcaster_Power_Badge_Users_Max_Fields>;
  min?: Maybe<Farcaster_Power_Badge_Users_Min_Fields>;
};


/** aggregate fields of "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farcaster_Power_Badge_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "farcaster_power_badge_users". All fields are combined with a logical 'AND'. */
export type Farcaster_Power_Badge_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Farcaster_Power_Badge_Users_Bool_Exp>>;
  _not?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Farcaster_Power_Badge_Users_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fetchedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fid?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "farcaster_power_badge_users" */
export enum Farcaster_Power_Badge_Users_Constraint {
  /** unique or primary key constraint on columns "fid" */
  FarcasterPowerBadgeUsersPkey = 'farcaster_power_badge_users_pkey'
}

/** input type for inserting data into table "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fetchedAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Farcaster_Power_Badge_Users_Max_Fields = {
  __typename?: 'farcaster_power_badge_users_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fetchedAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Farcaster_Power_Badge_Users_Min_Fields = {
  __typename?: 'farcaster_power_badge_users_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fetchedAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Mutation_Response = {
  __typename?: 'farcaster_power_badge_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Farcaster_Power_Badge_Users>;
};

/** input type for inserting object relation for remote table "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Obj_Rel_Insert_Input = {
  data: Farcaster_Power_Badge_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Farcaster_Power_Badge_Users_On_Conflict>;
};

/** on_conflict condition type for table "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_On_Conflict = {
  constraint: Farcaster_Power_Badge_Users_Constraint;
  update_columns?: Array<Farcaster_Power_Badge_Users_Update_Column>;
  where?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "farcaster_power_badge_users". */
export type Farcaster_Power_Badge_Users_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fetchedAt?: InputMaybe<Order_By>;
  fid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farcaster_power_badge_users */
export type Farcaster_Power_Badge_Users_Pk_Columns_Input = {
  fid: Scalars['String'];
};

/** select columns of table "farcaster_power_badge_users" */
export enum Farcaster_Power_Badge_Users_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FetchedAt = 'fetchedAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fetchedAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "farcaster_power_badge_users" */
export type Farcaster_Power_Badge_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farcaster_Power_Badge_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farcaster_Power_Badge_Users_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fetchedAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "farcaster_power_badge_users" */
export enum Farcaster_Power_Badge_Users_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FetchedAt = 'fetchedAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Farcaster_Power_Badge_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farcaster_Power_Badge_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farcaster_Power_Badge_Users_Bool_Exp;
};

/** columns and relationships of "farcaster_profiles" */
export type Farcaster_Profiles = {
  __typename?: 'farcaster_profiles';
  bio: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdAtBlockMumber?: Maybe<Scalars['Int']>;
  createdAtBlockTimestamp?: Maybe<Scalars['timestamptz']>;
  displayName: Scalars['String'];
  /** An object relationship */
  farcasterSigner?: Maybe<Farcaster_Signers>;
  image: Scalars['String'];
  imageId?: Maybe<Scalars['String']>;
  /** An object relationship */
  imageRel?: Maybe<Images>;
  index_content: Scalars['Boolean'];
  isUserFollowing?: Maybe<Scalars['Boolean']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Int']>;
  lastUpdatedAtBlockTimestamp?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  phaverProfileId: Scalars['String'];
  /** An object relationship */
  powerBadgeUser?: Maybe<Farcaster_Power_Badge_Users>;
  /** An object relationship */
  profile: Profiles;
  signerPublicKey?: Maybe<Scalars['String']>;
  tokenAddress?: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
  tokenUri?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "farcaster_profiles" */
export type Farcaster_Profiles_Aggregate = {
  __typename?: 'farcaster_profiles_aggregate';
  aggregate?: Maybe<Farcaster_Profiles_Aggregate_Fields>;
  nodes: Array<Farcaster_Profiles>;
};

/** aggregate fields of "farcaster_profiles" */
export type Farcaster_Profiles_Aggregate_Fields = {
  __typename?: 'farcaster_profiles_aggregate_fields';
  avg?: Maybe<Farcaster_Profiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Farcaster_Profiles_Max_Fields>;
  min?: Maybe<Farcaster_Profiles_Min_Fields>;
  stddev?: Maybe<Farcaster_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Farcaster_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Farcaster_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Farcaster_Profiles_Sum_Fields>;
  var_pop?: Maybe<Farcaster_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Farcaster_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Farcaster_Profiles_Variance_Fields>;
};


/** aggregate fields of "farcaster_profiles" */
export type Farcaster_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farcaster_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Farcaster_Profiles_Avg_Fields = {
  __typename?: 'farcaster_profiles_avg_fields';
  createdAtBlockMumber?: Maybe<Scalars['Float']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "farcaster_profiles". All fields are combined with a logical 'AND'. */
export type Farcaster_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Farcaster_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Farcaster_Profiles_Bool_Exp>>;
  bio?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAtBlockMumber?: InputMaybe<Int_Comparison_Exp>;
  createdAtBlockTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  farcasterSigner?: InputMaybe<Farcaster_Signers_Bool_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  imageRel?: InputMaybe<Images_Bool_Exp>;
  index_content?: InputMaybe<Boolean_Comparison_Exp>;
  isUserFollowing?: InputMaybe<Boolean_Comparison_Exp>;
  lastUpdatedAtBlockNumber?: InputMaybe<Int_Comparison_Exp>;
  lastUpdatedAtBlockTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phaverProfileId?: InputMaybe<String_Comparison_Exp>;
  powerBadgeUser?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  signerPublicKey?: InputMaybe<String_Comparison_Exp>;
  tokenAddress?: InputMaybe<String_Comparison_Exp>;
  tokenId?: InputMaybe<String_Comparison_Exp>;
  tokenUri?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "farcaster_profiles" */
export enum Farcaster_Profiles_Constraint {
  /** unique or primary key constraint on columns "phaver_profile_id" */
  FarcasterProfilesPhaverProfileIdUnique = 'farcaster_profiles_phaver_profile_id_unique',
  /** unique or primary key constraint on columns "token_id" */
  FarcasterProfilesPkey = 'farcaster_profiles_pkey'
}

/** input type for incrementing numeric columns in table "farcaster_profiles" */
export type Farcaster_Profiles_Inc_Input = {
  createdAtBlockMumber?: InputMaybe<Scalars['Int']>;
  lastUpdatedAtBlockNumber?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "farcaster_profiles" */
export type Farcaster_Profiles_Insert_Input = {
  bio?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdAtBlockMumber?: InputMaybe<Scalars['Int']>;
  createdAtBlockTimestamp?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  farcasterSigner?: InputMaybe<Farcaster_Signers_Obj_Rel_Insert_Input>;
  image?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  imageRel?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  index_content?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedAtBlockNumber?: InputMaybe<Scalars['Int']>;
  lastUpdatedAtBlockTimestamp?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  phaverProfileId?: InputMaybe<Scalars['String']>;
  powerBadgeUser?: InputMaybe<Farcaster_Power_Badge_Users_Obj_Rel_Insert_Input>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  signerPublicKey?: InputMaybe<Scalars['String']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  tokenUri?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Farcaster_Profiles_Max_Fields = {
  __typename?: 'farcaster_profiles_max_fields';
  bio?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdAtBlockMumber?: Maybe<Scalars['Int']>;
  createdAtBlockTimestamp?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Int']>;
  lastUpdatedAtBlockTimestamp?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  phaverProfileId?: Maybe<Scalars['String']>;
  signerPublicKey?: Maybe<Scalars['String']>;
  tokenAddress?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Farcaster_Profiles_Min_Fields = {
  __typename?: 'farcaster_profiles_min_fields';
  bio?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdAtBlockMumber?: Maybe<Scalars['Int']>;
  createdAtBlockTimestamp?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Int']>;
  lastUpdatedAtBlockTimestamp?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  phaverProfileId?: Maybe<Scalars['String']>;
  signerPublicKey?: Maybe<Scalars['String']>;
  tokenAddress?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "farcaster_profiles" */
export type Farcaster_Profiles_Mutation_Response = {
  __typename?: 'farcaster_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Farcaster_Profiles>;
};

/** input type for inserting object relation for remote table "farcaster_profiles" */
export type Farcaster_Profiles_Obj_Rel_Insert_Input = {
  data: Farcaster_Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Farcaster_Profiles_On_Conflict>;
};

/** on_conflict condition type for table "farcaster_profiles" */
export type Farcaster_Profiles_On_Conflict = {
  constraint: Farcaster_Profiles_Constraint;
  update_columns?: Array<Farcaster_Profiles_Update_Column>;
  where?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "farcaster_profiles". */
export type Farcaster_Profiles_Order_By = {
  bio?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdAtBlockMumber?: InputMaybe<Order_By>;
  createdAtBlockTimestamp?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  farcasterSigner?: InputMaybe<Farcaster_Signers_Order_By>;
  image?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  imageRel?: InputMaybe<Images_Order_By>;
  index_content?: InputMaybe<Order_By>;
  isUserFollowing?: InputMaybe<Order_By>;
  lastUpdatedAtBlockNumber?: InputMaybe<Order_By>;
  lastUpdatedAtBlockTimestamp?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phaverProfileId?: InputMaybe<Order_By>;
  powerBadgeUser?: InputMaybe<Farcaster_Power_Badge_Users_Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  signerPublicKey?: InputMaybe<Order_By>;
  tokenAddress?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  tokenUri?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farcaster_profiles */
export type Farcaster_Profiles_Pk_Columns_Input = {
  tokenId: Scalars['String'];
};

/** select columns of table "farcaster_profiles" */
export enum Farcaster_Profiles_Select_Column {
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedAtBlockMumber = 'createdAtBlockMumber',
  /** column name */
  CreatedAtBlockTimestamp = 'createdAtBlockTimestamp',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Image = 'image',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IndexContent = 'index_content',
  /** column name */
  LastUpdatedAtBlockNumber = 'lastUpdatedAtBlockNumber',
  /** column name */
  LastUpdatedAtBlockTimestamp = 'lastUpdatedAtBlockTimestamp',
  /** column name */
  Name = 'name',
  /** column name */
  PhaverProfileId = 'phaverProfileId',
  /** column name */
  SignerPublicKey = 'signerPublicKey',
  /** column name */
  TokenAddress = 'tokenAddress',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  TokenUri = 'tokenUri',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "farcaster_profiles" */
export type Farcaster_Profiles_Set_Input = {
  bio?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdAtBlockMumber?: InputMaybe<Scalars['Int']>;
  createdAtBlockTimestamp?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  index_content?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedAtBlockNumber?: InputMaybe<Scalars['Int']>;
  lastUpdatedAtBlockTimestamp?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  phaverProfileId?: InputMaybe<Scalars['String']>;
  signerPublicKey?: InputMaybe<Scalars['String']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  tokenUri?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Farcaster_Profiles_Stddev_Fields = {
  __typename?: 'farcaster_profiles_stddev_fields';
  createdAtBlockMumber?: Maybe<Scalars['Float']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Farcaster_Profiles_Stddev_Pop_Fields = {
  __typename?: 'farcaster_profiles_stddev_pop_fields';
  createdAtBlockMumber?: Maybe<Scalars['Float']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Farcaster_Profiles_Stddev_Samp_Fields = {
  __typename?: 'farcaster_profiles_stddev_samp_fields';
  createdAtBlockMumber?: Maybe<Scalars['Float']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "farcaster_profiles" */
export type Farcaster_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farcaster_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farcaster_Profiles_Stream_Cursor_Value_Input = {
  bio?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdAtBlockMumber?: InputMaybe<Scalars['Int']>;
  createdAtBlockTimestamp?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  index_content?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedAtBlockNumber?: InputMaybe<Scalars['Int']>;
  lastUpdatedAtBlockTimestamp?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  phaverProfileId?: InputMaybe<Scalars['String']>;
  signerPublicKey?: InputMaybe<Scalars['String']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  tokenUri?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Farcaster_Profiles_Sum_Fields = {
  __typename?: 'farcaster_profiles_sum_fields';
  createdAtBlockMumber?: Maybe<Scalars['Int']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Int']>;
};

/** update columns of table "farcaster_profiles" */
export enum Farcaster_Profiles_Update_Column {
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedAtBlockMumber = 'createdAtBlockMumber',
  /** column name */
  CreatedAtBlockTimestamp = 'createdAtBlockTimestamp',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Image = 'image',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IndexContent = 'index_content',
  /** column name */
  LastUpdatedAtBlockNumber = 'lastUpdatedAtBlockNumber',
  /** column name */
  LastUpdatedAtBlockTimestamp = 'lastUpdatedAtBlockTimestamp',
  /** column name */
  Name = 'name',
  /** column name */
  PhaverProfileId = 'phaverProfileId',
  /** column name */
  SignerPublicKey = 'signerPublicKey',
  /** column name */
  TokenAddress = 'tokenAddress',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  TokenUri = 'tokenUri',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type Farcaster_Profiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Farcaster_Profiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farcaster_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farcaster_Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Farcaster_Profiles_Var_Pop_Fields = {
  __typename?: 'farcaster_profiles_var_pop_fields';
  createdAtBlockMumber?: Maybe<Scalars['Float']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Farcaster_Profiles_Var_Samp_Fields = {
  __typename?: 'farcaster_profiles_var_samp_fields';
  createdAtBlockMumber?: Maybe<Scalars['Float']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Farcaster_Profiles_Variance_Fields = {
  __typename?: 'farcaster_profiles_variance_fields';
  createdAtBlockMumber?: Maybe<Scalars['Float']>;
  lastUpdatedAtBlockNumber?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "farcaster_reactions" */
export type Farcaster_Reactions = {
  __typename?: 'farcaster_reactions';
  comment_id?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  farcasterCast?: Maybe<Farcaster_Casts>;
  fid: Scalars['String'];
  fromPhaver: Scalars['Boolean'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  reactionType: Scalars['smallint'];
  status: Async_Status_Enum;
  target_cast_fid?: Maybe<Scalars['String']>;
  target_cast_hash?: Maybe<Scalars['String']>;
  target_url?: Maybe<Scalars['String']>;
  timestamp: Scalars['timestamptz'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "farcaster_reactions" */
export type Farcaster_Reactions_Aggregate = {
  __typename?: 'farcaster_reactions_aggregate';
  aggregate?: Maybe<Farcaster_Reactions_Aggregate_Fields>;
  nodes: Array<Farcaster_Reactions>;
};

/** aggregate fields of "farcaster_reactions" */
export type Farcaster_Reactions_Aggregate_Fields = {
  __typename?: 'farcaster_reactions_aggregate_fields';
  avg?: Maybe<Farcaster_Reactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Farcaster_Reactions_Max_Fields>;
  min?: Maybe<Farcaster_Reactions_Min_Fields>;
  stddev?: Maybe<Farcaster_Reactions_Stddev_Fields>;
  stddev_pop?: Maybe<Farcaster_Reactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Farcaster_Reactions_Stddev_Samp_Fields>;
  sum?: Maybe<Farcaster_Reactions_Sum_Fields>;
  var_pop?: Maybe<Farcaster_Reactions_Var_Pop_Fields>;
  var_samp?: Maybe<Farcaster_Reactions_Var_Samp_Fields>;
  variance?: Maybe<Farcaster_Reactions_Variance_Fields>;
};


/** aggregate fields of "farcaster_reactions" */
export type Farcaster_Reactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farcaster_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Farcaster_Reactions_Avg_Fields = {
  __typename?: 'farcaster_reactions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  reactionType?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "farcaster_reactions". All fields are combined with a logical 'AND'. */
export type Farcaster_Reactions_Bool_Exp = {
  _and?: InputMaybe<Array<Farcaster_Reactions_Bool_Exp>>;
  _not?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
  _or?: InputMaybe<Array<Farcaster_Reactions_Bool_Exp>>;
  comment_id?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  fid?: InputMaybe<String_Comparison_Exp>;
  fromPhaver?: InputMaybe<Boolean_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  reactionType?: InputMaybe<Smallint_Comparison_Exp>;
  status?: InputMaybe<Async_Status_Enum_Comparison_Exp>;
  target_cast_fid?: InputMaybe<String_Comparison_Exp>;
  target_cast_hash?: InputMaybe<String_Comparison_Exp>;
  target_url?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "farcaster_reactions" */
export enum Farcaster_Reactions_Constraint {
  /** unique or primary key constraint on columns "hash" */
  FarcasterReactionsHashIndex = 'farcaster_reactions_hash_index',
  /** unique or primary key constraint on columns "reaction_type", "target_cast_hash", "fid" */
  FarcasterReactionsMergeConflictIndex = 'farcaster_reactions_merge_conflict_index',
  /** unique or primary key constraint on columns "id" */
  FarcasterReactionsPkey = 'farcaster_reactions_pkey'
}

/** input type for incrementing numeric columns in table "farcaster_reactions" */
export type Farcaster_Reactions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  reactionType?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "farcaster_reactions" */
export type Farcaster_Reactions_Insert_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Obj_Rel_Insert_Input>;
  fid?: InputMaybe<Scalars['String']>;
  fromPhaver?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Async_Status_Enum>;
  target_cast_fid?: InputMaybe<Scalars['String']>;
  target_cast_hash?: InputMaybe<Scalars['String']>;
  target_url?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Farcaster_Reactions_Max_Fields = {
  __typename?: 'farcaster_reactions_max_fields';
  comment_id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  reactionType?: Maybe<Scalars['smallint']>;
  target_cast_fid?: Maybe<Scalars['String']>;
  target_cast_hash?: Maybe<Scalars['String']>;
  target_url?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Farcaster_Reactions_Min_Fields = {
  __typename?: 'farcaster_reactions_min_fields';
  comment_id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  reactionType?: Maybe<Scalars['smallint']>;
  target_cast_fid?: Maybe<Scalars['String']>;
  target_cast_hash?: Maybe<Scalars['String']>;
  target_url?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "farcaster_reactions" */
export type Farcaster_Reactions_Mutation_Response = {
  __typename?: 'farcaster_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Farcaster_Reactions>;
};

/** input type for inserting object relation for remote table "farcaster_reactions" */
export type Farcaster_Reactions_Obj_Rel_Insert_Input = {
  data: Farcaster_Reactions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Farcaster_Reactions_On_Conflict>;
};

/** on_conflict condition type for table "farcaster_reactions" */
export type Farcaster_Reactions_On_Conflict = {
  constraint: Farcaster_Reactions_Constraint;
  update_columns?: Array<Farcaster_Reactions_Update_Column>;
  where?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
};

/** Ordering options when selecting data from "farcaster_reactions". */
export type Farcaster_Reactions_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Order_By>;
  fid?: InputMaybe<Order_By>;
  fromPhaver?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  reactionType?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  target_cast_fid?: InputMaybe<Order_By>;
  target_cast_hash?: InputMaybe<Order_By>;
  target_url?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farcaster_reactions */
export type Farcaster_Reactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "farcaster_reactions" */
export enum Farcaster_Reactions_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  FromPhaver = 'fromPhaver',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ReactionType = 'reactionType',
  /** column name */
  Status = 'status',
  /** column name */
  TargetCastFid = 'target_cast_fid',
  /** column name */
  TargetCastHash = 'target_cast_hash',
  /** column name */
  TargetUrl = 'target_url',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "farcaster_reactions" */
export type Farcaster_Reactions_Set_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  fromPhaver?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Async_Status_Enum>;
  target_cast_fid?: InputMaybe<Scalars['String']>;
  target_cast_hash?: InputMaybe<Scalars['String']>;
  target_url?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Farcaster_Reactions_Stddev_Fields = {
  __typename?: 'farcaster_reactions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  reactionType?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Farcaster_Reactions_Stddev_Pop_Fields = {
  __typename?: 'farcaster_reactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reactionType?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Farcaster_Reactions_Stddev_Samp_Fields = {
  __typename?: 'farcaster_reactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reactionType?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "farcaster_reactions" */
export type Farcaster_Reactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farcaster_Reactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farcaster_Reactions_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  fromPhaver?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Async_Status_Enum>;
  target_cast_fid?: InputMaybe<Scalars['String']>;
  target_cast_hash?: InputMaybe<Scalars['String']>;
  target_url?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Farcaster_Reactions_Sum_Fields = {
  __typename?: 'farcaster_reactions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  reactionType?: Maybe<Scalars['smallint']>;
};

/** update columns of table "farcaster_reactions" */
export enum Farcaster_Reactions_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  FromPhaver = 'fromPhaver',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ReactionType = 'reactionType',
  /** column name */
  Status = 'status',
  /** column name */
  TargetCastFid = 'target_cast_fid',
  /** column name */
  TargetCastHash = 'target_cast_hash',
  /** column name */
  TargetUrl = 'target_url',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Farcaster_Reactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Farcaster_Reactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farcaster_Reactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farcaster_Reactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Farcaster_Reactions_Var_Pop_Fields = {
  __typename?: 'farcaster_reactions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reactionType?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Farcaster_Reactions_Var_Samp_Fields = {
  __typename?: 'farcaster_reactions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reactionType?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Farcaster_Reactions_Variance_Fields = {
  __typename?: 'farcaster_reactions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  reactionType?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "farcaster_signers" */
export type Farcaster_Signers = {
  __typename?: 'farcaster_signers';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  farcasterProfile?: Maybe<Farcaster_Profiles>;
  fid?: Maybe<Scalars['String']>;
  /** An object relationship */
  phaverProfile: Profiles;
  phaverProfileId: Scalars['String'];
  publicKey: Scalars['String'];
  signerApprovalUrl?: Maybe<Scalars['String']>;
  signerUuid: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "farcaster_signers" */
export type Farcaster_Signers_Aggregate = {
  __typename?: 'farcaster_signers_aggregate';
  aggregate?: Maybe<Farcaster_Signers_Aggregate_Fields>;
  nodes: Array<Farcaster_Signers>;
};

/** aggregate fields of "farcaster_signers" */
export type Farcaster_Signers_Aggregate_Fields = {
  __typename?: 'farcaster_signers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Farcaster_Signers_Max_Fields>;
  min?: Maybe<Farcaster_Signers_Min_Fields>;
};


/** aggregate fields of "farcaster_signers" */
export type Farcaster_Signers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farcaster_Signers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "farcaster_signers". All fields are combined with a logical 'AND'. */
export type Farcaster_Signers_Bool_Exp = {
  _and?: InputMaybe<Array<Farcaster_Signers_Bool_Exp>>;
  _not?: InputMaybe<Farcaster_Signers_Bool_Exp>;
  _or?: InputMaybe<Array<Farcaster_Signers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  farcasterProfile?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
  fid?: InputMaybe<String_Comparison_Exp>;
  phaverProfile?: InputMaybe<Profiles_Bool_Exp>;
  phaverProfileId?: InputMaybe<String_Comparison_Exp>;
  publicKey?: InputMaybe<String_Comparison_Exp>;
  signerApprovalUrl?: InputMaybe<String_Comparison_Exp>;
  signerUuid?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "farcaster_signers" */
export enum Farcaster_Signers_Constraint {
  /** unique or primary key constraint on columns "public_key" */
  FarcasterProfilesSignerUnique = 'farcaster_profiles_signer_unique',
  /** unique or primary key constraint on columns "signer_uuid" */
  FarcasterSignersPkey = 'farcaster_signers_pkey',
  /** unique or primary key constraint on columns "public_key" */
  FarcasterSignersPublicKeyUnique = 'farcaster_signers_public_key_unique'
}

/** input type for inserting data into table "farcaster_signers" */
export type Farcaster_Signers_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  farcasterProfile?: InputMaybe<Farcaster_Profiles_Obj_Rel_Insert_Input>;
  fid?: InputMaybe<Scalars['String']>;
  phaverProfile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  phaverProfileId?: InputMaybe<Scalars['String']>;
  publicKey?: InputMaybe<Scalars['String']>;
  signerApprovalUrl?: InputMaybe<Scalars['String']>;
  signerUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Farcaster_Signers_Max_Fields = {
  __typename?: 'farcaster_signers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  phaverProfileId?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  signerApprovalUrl?: Maybe<Scalars['String']>;
  signerUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Farcaster_Signers_Min_Fields = {
  __typename?: 'farcaster_signers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fid?: Maybe<Scalars['String']>;
  phaverProfileId?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  signerApprovalUrl?: Maybe<Scalars['String']>;
  signerUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "farcaster_signers" */
export type Farcaster_Signers_Mutation_Response = {
  __typename?: 'farcaster_signers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Farcaster_Signers>;
};

/** input type for inserting object relation for remote table "farcaster_signers" */
export type Farcaster_Signers_Obj_Rel_Insert_Input = {
  data: Farcaster_Signers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Farcaster_Signers_On_Conflict>;
};

/** on_conflict condition type for table "farcaster_signers" */
export type Farcaster_Signers_On_Conflict = {
  constraint: Farcaster_Signers_Constraint;
  update_columns?: Array<Farcaster_Signers_Update_Column>;
  where?: InputMaybe<Farcaster_Signers_Bool_Exp>;
};

/** Ordering options when selecting data from "farcaster_signers". */
export type Farcaster_Signers_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  farcasterProfile?: InputMaybe<Farcaster_Profiles_Order_By>;
  fid?: InputMaybe<Order_By>;
  phaverProfile?: InputMaybe<Profiles_Order_By>;
  phaverProfileId?: InputMaybe<Order_By>;
  publicKey?: InputMaybe<Order_By>;
  signerApprovalUrl?: InputMaybe<Order_By>;
  signerUuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farcaster_signers */
export type Farcaster_Signers_Pk_Columns_Input = {
  signerUuid: Scalars['String'];
};

/** select columns of table "farcaster_signers" */
export enum Farcaster_Signers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  PhaverProfileId = 'phaverProfileId',
  /** column name */
  PublicKey = 'publicKey',
  /** column name */
  SignerApprovalUrl = 'signerApprovalUrl',
  /** column name */
  SignerUuid = 'signerUuid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "farcaster_signers" */
export type Farcaster_Signers_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  phaverProfileId?: InputMaybe<Scalars['String']>;
  publicKey?: InputMaybe<Scalars['String']>;
  signerApprovalUrl?: InputMaybe<Scalars['String']>;
  signerUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "farcaster_signers" */
export type Farcaster_Signers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farcaster_Signers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farcaster_Signers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fid?: InputMaybe<Scalars['String']>;
  phaverProfileId?: InputMaybe<Scalars['String']>;
  publicKey?: InputMaybe<Scalars['String']>;
  signerApprovalUrl?: InputMaybe<Scalars['String']>;
  signerUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "farcaster_signers" */
export enum Farcaster_Signers_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fid = 'fid',
  /** column name */
  PhaverProfileId = 'phaverProfileId',
  /** column name */
  PublicKey = 'publicKey',
  /** column name */
  SignerApprovalUrl = 'signerApprovalUrl',
  /** column name */
  SignerUuid = 'signerUuid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Farcaster_Signers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farcaster_Signers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farcaster_Signers_Bool_Exp;
};

/** columns and relationships of "flag_types" */
export type Flag_Types = {
  __typename?: 'flag_types';
  /** An array relationship */
  flags: Array<Flags>;
  /** An aggregate relationship */
  flags_aggregate: Flags_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "flag_types" */
export type Flag_TypesFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


/** columns and relationships of "flag_types" */
export type Flag_TypesFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

/** aggregated selection of "flag_types" */
export type Flag_Types_Aggregate = {
  __typename?: 'flag_types_aggregate';
  aggregate?: Maybe<Flag_Types_Aggregate_Fields>;
  nodes: Array<Flag_Types>;
};

/** aggregate fields of "flag_types" */
export type Flag_Types_Aggregate_Fields = {
  __typename?: 'flag_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Flag_Types_Max_Fields>;
  min?: Maybe<Flag_Types_Min_Fields>;
};


/** aggregate fields of "flag_types" */
export type Flag_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flag_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "flag_types". All fields are combined with a logical 'AND'. */
export type Flag_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Flag_Types_Bool_Exp>>;
  _not?: InputMaybe<Flag_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Flag_Types_Bool_Exp>>;
  flags?: InputMaybe<Flags_Bool_Exp>;
  flags_aggregate?: InputMaybe<Flags_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "flag_types" */
export enum Flag_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  FlagTypesPkey = 'flag_types_pkey'
}

export enum Flag_Types_Enum {
  CommunityWelcomeDismissed = 'communityWelcomeDismissed',
  CongratsOn1stCommunityDismissed = 'congratsOn1stCommunityDismissed',
  FirstDailyStakeDismissed = 'firstDailyStakeDismissed',
  FirstPostGuidelinesDismissed = 'firstPostGuidelinesDismissed',
  FirstTimeLensFeed = 'firstTimeLensFeed',
  OnboardingAskYourFirstQuestion = 'onboardingAskYourFirstQuestion',
  OnboardingCompleteYourProfileBio = 'onboardingCompleteYourProfileBio',
  OnboardingContributeToACollection = 'onboardingContributeToACollection',
  OnboardingEarnYourFirstExpertPoints = 'onboardingEarnYourFirstExpertPoints',
  OnboardingSidebarStepClosed = 'onboardingSidebarStepClosed',
  ProfileSetupDone = 'profileSetupDone',
  TestFlag = 'testFlag'
}

/** Boolean expression to compare columns of type "flag_types_enum". All fields are combined with logical 'AND'. */
export type Flag_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Flag_Types_Enum>;
  _in?: InputMaybe<Array<Flag_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Flag_Types_Enum>;
  _nin?: InputMaybe<Array<Flag_Types_Enum>>;
};

/** input type for inserting data into table "flag_types" */
export type Flag_Types_Insert_Input = {
  flags?: InputMaybe<Flags_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Flag_Types_Max_Fields = {
  __typename?: 'flag_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Flag_Types_Min_Fields = {
  __typename?: 'flag_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "flag_types" */
export type Flag_Types_Mutation_Response = {
  __typename?: 'flag_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Flag_Types>;
};

/** input type for inserting object relation for remote table "flag_types" */
export type Flag_Types_Obj_Rel_Insert_Input = {
  data: Flag_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Flag_Types_On_Conflict>;
};

/** on_conflict condition type for table "flag_types" */
export type Flag_Types_On_Conflict = {
  constraint: Flag_Types_Constraint;
  update_columns?: Array<Flag_Types_Update_Column>;
  where?: InputMaybe<Flag_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "flag_types". */
export type Flag_Types_Order_By = {
  flags_aggregate?: InputMaybe<Flags_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flag_types */
export type Flag_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "flag_types" */
export enum Flag_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "flag_types" */
export type Flag_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "flag_types" */
export type Flag_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Flag_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Flag_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "flag_types" */
export enum Flag_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Flag_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Flag_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Flag_Types_Bool_Exp;
};

/** columns and relationships of "flags" */
export type Flags = {
  __typename?: 'flags';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  enabled: Scalars['Boolean'];
  flagType: Flag_Types_Enum;
  /** An object relationship */
  flagTypeByFlagType: Flag_Types;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "flags" */
export type Flags_Aggregate = {
  __typename?: 'flags_aggregate';
  aggregate?: Maybe<Flags_Aggregate_Fields>;
  nodes: Array<Flags>;
};

export type Flags_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Flags_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Flags_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Flags_Aggregate_Bool_Exp_Count>;
};

export type Flags_Aggregate_Bool_Exp_Bool_And = {
  arguments: Flags_Select_Column_Flags_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Flags_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Flags_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Flags_Select_Column_Flags_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Flags_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "flags" */
export type Flags_Aggregate_Fields = {
  __typename?: 'flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Flags_Max_Fields>;
  min?: Maybe<Flags_Min_Fields>;
};


/** aggregate fields of "flags" */
export type Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flags" */
export type Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flags_Max_Order_By>;
  min?: InputMaybe<Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "flags" */
export type Flags_Arr_Rel_Insert_Input = {
  data: Array<Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "flags". All fields are combined with a logical 'AND'. */
export type Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Flags_Bool_Exp>>;
  _not?: InputMaybe<Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Flags_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  flagType?: InputMaybe<Flag_Types_Enum_Comparison_Exp>;
  flagTypeByFlagType?: InputMaybe<Flag_Types_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "flags" */
export enum Flags_Constraint {
  /** unique or primary key constraint on columns "account_id", "flag_type" */
  FlagsPkey = 'flags_pkey'
}

/** input type for inserting data into table "flags" */
export type Flags_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  flagType?: InputMaybe<Flag_Types_Enum>;
  flagTypeByFlagType?: InputMaybe<Flag_Types_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Flags_Max_Fields = {
  __typename?: 'flags_max_fields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "flags" */
export type Flags_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flags_Min_Fields = {
  __typename?: 'flags_min_fields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "flags" */
export type Flags_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "flags" */
export type Flags_Mutation_Response = {
  __typename?: 'flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Flags>;
};

/** on_conflict condition type for table "flags" */
export type Flags_On_Conflict = {
  constraint: Flags_Constraint;
  update_columns?: Array<Flags_Update_Column>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "flags". */
export type Flags_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  flagType?: InputMaybe<Order_By>;
  flagTypeByFlagType?: InputMaybe<Flag_Types_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flags */
export type Flags_Pk_Columns_Input = {
  accountId: Scalars['String'];
  flagType: Flag_Types_Enum;
};

/** select columns of table "flags" */
export enum Flags_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FlagType = 'flagType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "flags_aggregate_bool_exp_bool_and_arguments_columns" columns of table "flags" */
export enum Flags_Select_Column_Flags_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** select "flags_aggregate_bool_exp_bool_or_arguments_columns" columns of table "flags" */
export enum Flags_Select_Column_Flags_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** input type for updating data in table "flags" */
export type Flags_Set_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  flagType?: InputMaybe<Flag_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "flags" */
export type Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Flags_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  flagType?: InputMaybe<Flag_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "flags" */
export enum Flags_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FlagType = 'flagType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Flags_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "followers" */
export type Followers = {
  __typename?: 'followers';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  followedProfile: Profiles;
  followedProfileId: Scalars['String'];
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
};

/** aggregated selection of "followers" */
export type Followers_Aggregate = {
  __typename?: 'followers_aggregate';
  aggregate?: Maybe<Followers_Aggregate_Fields>;
  nodes: Array<Followers>;
};

export type Followers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Followers_Aggregate_Bool_Exp_Count>;
};

export type Followers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Followers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Followers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "followers" */
export type Followers_Aggregate_Fields = {
  __typename?: 'followers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Followers_Max_Fields>;
  min?: Maybe<Followers_Min_Fields>;
};


/** aggregate fields of "followers" */
export type Followers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Followers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "followers" */
export type Followers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Followers_Max_Order_By>;
  min?: InputMaybe<Followers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "followers" */
export type Followers_Arr_Rel_Insert_Input = {
  data: Array<Followers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Followers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "followers". All fields are combined with a logical 'AND'. */
export type Followers_Bool_Exp = {
  _and?: InputMaybe<Array<Followers_Bool_Exp>>;
  _not?: InputMaybe<Followers_Bool_Exp>;
  _or?: InputMaybe<Array<Followers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  followedProfile?: InputMaybe<Profiles_Bool_Exp>;
  followedProfileId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "followers" */
export enum Followers_Constraint {
  /** unique or primary key constraint on columns "profile_id", "followed_profile_id" */
  FollowersPkey = 'followers_pkey'
}

/** input type for inserting data into table "followers" */
export type Followers_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  followedProfile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  followedProfileId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Followers_Max_Fields = {
  __typename?: 'followers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  followedProfileId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "followers" */
export type Followers_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  followedProfileId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Followers_Min_Fields = {
  __typename?: 'followers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  followedProfileId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "followers" */
export type Followers_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  followedProfileId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "followers" */
export type Followers_Mutation_Response = {
  __typename?: 'followers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Followers>;
};

/** on_conflict condition type for table "followers" */
export type Followers_On_Conflict = {
  constraint: Followers_Constraint;
  update_columns?: Array<Followers_Update_Column>;
  where?: InputMaybe<Followers_Bool_Exp>;
};

/** Ordering options when selecting data from "followers". */
export type Followers_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  followedProfile?: InputMaybe<Profiles_Order_By>;
  followedProfileId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: followers */
export type Followers_Pk_Columns_Input = {
  followedProfileId: Scalars['String'];
  profileId: Scalars['String'];
};

/** select columns of table "followers" */
export enum Followers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FollowedProfileId = 'followedProfileId',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "followers" */
export type Followers_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  followedProfileId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "followers" */
export type Followers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Followers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Followers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  followedProfileId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "followers" */
export enum Followers_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FollowedProfileId = 'followedProfileId',
  /** column name */
  ProfileId = 'profileId'
}

export type Followers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Followers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Followers_Bool_Exp;
};

/** columns and relationships of "frame_action" */
export type Frame_Action = {
  __typename?: 'frame_action';
  value: Scalars['String'];
};

/** aggregated selection of "frame_action" */
export type Frame_Action_Aggregate = {
  __typename?: 'frame_action_aggregate';
  aggregate?: Maybe<Frame_Action_Aggregate_Fields>;
  nodes: Array<Frame_Action>;
};

/** aggregate fields of "frame_action" */
export type Frame_Action_Aggregate_Fields = {
  __typename?: 'frame_action_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Frame_Action_Max_Fields>;
  min?: Maybe<Frame_Action_Min_Fields>;
};


/** aggregate fields of "frame_action" */
export type Frame_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frame_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "frame_action". All fields are combined with a logical 'AND'. */
export type Frame_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Frame_Action_Bool_Exp>>;
  _not?: InputMaybe<Frame_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Frame_Action_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "frame_action" */
export enum Frame_Action_Constraint {
  /** unique or primary key constraint on columns "value" */
  FrameActionPkey = 'frame_action_pkey'
}

export enum Frame_Action_Enum {
  Link = 'link',
  Mint = 'mint',
  Post = 'post',
  PostRedirect = 'post_redirect',
  Tx = 'tx'
}

/** Boolean expression to compare columns of type "frame_action_enum". All fields are combined with logical 'AND'. */
export type Frame_Action_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Frame_Action_Enum>;
  _in?: InputMaybe<Array<Frame_Action_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Frame_Action_Enum>;
  _nin?: InputMaybe<Array<Frame_Action_Enum>>;
};

/** input type for inserting data into table "frame_action" */
export type Frame_Action_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Frame_Action_Max_Fields = {
  __typename?: 'frame_action_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Frame_Action_Min_Fields = {
  __typename?: 'frame_action_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "frame_action" */
export type Frame_Action_Mutation_Response = {
  __typename?: 'frame_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Frame_Action>;
};

/** on_conflict condition type for table "frame_action" */
export type Frame_Action_On_Conflict = {
  constraint: Frame_Action_Constraint;
  update_columns?: Array<Frame_Action_Update_Column>;
  where?: InputMaybe<Frame_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "frame_action". */
export type Frame_Action_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: frame_action */
export type Frame_Action_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "frame_action" */
export enum Frame_Action_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "frame_action" */
export type Frame_Action_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "frame_action" */
export type Frame_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frame_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frame_Action_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "frame_action" */
export enum Frame_Action_Update_Column {
  /** column name */
  Value = 'value'
}

export type Frame_Action_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frame_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frame_Action_Bool_Exp;
};

/** columns and relationships of "frame_buttons" */
export type Frame_Buttons = {
  __typename?: 'frame_buttons';
  action?: Maybe<Frame_Action_Enum>;
  /** An object relationship */
  frame?: Maybe<Frames>;
  frameId?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  idx: Scalars['Int'];
  label: Scalars['String'];
  postUrl?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

/** aggregated selection of "frame_buttons" */
export type Frame_Buttons_Aggregate = {
  __typename?: 'frame_buttons_aggregate';
  aggregate?: Maybe<Frame_Buttons_Aggregate_Fields>;
  nodes: Array<Frame_Buttons>;
};

export type Frame_Buttons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Frame_Buttons_Aggregate_Bool_Exp_Count>;
};

export type Frame_Buttons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Frame_Buttons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "frame_buttons" */
export type Frame_Buttons_Aggregate_Fields = {
  __typename?: 'frame_buttons_aggregate_fields';
  avg?: Maybe<Frame_Buttons_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Frame_Buttons_Max_Fields>;
  min?: Maybe<Frame_Buttons_Min_Fields>;
  stddev?: Maybe<Frame_Buttons_Stddev_Fields>;
  stddev_pop?: Maybe<Frame_Buttons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Frame_Buttons_Stddev_Samp_Fields>;
  sum?: Maybe<Frame_Buttons_Sum_Fields>;
  var_pop?: Maybe<Frame_Buttons_Var_Pop_Fields>;
  var_samp?: Maybe<Frame_Buttons_Var_Samp_Fields>;
  variance?: Maybe<Frame_Buttons_Variance_Fields>;
};


/** aggregate fields of "frame_buttons" */
export type Frame_Buttons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "frame_buttons" */
export type Frame_Buttons_Aggregate_Order_By = {
  avg?: InputMaybe<Frame_Buttons_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Frame_Buttons_Max_Order_By>;
  min?: InputMaybe<Frame_Buttons_Min_Order_By>;
  stddev?: InputMaybe<Frame_Buttons_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Frame_Buttons_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Frame_Buttons_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Frame_Buttons_Sum_Order_By>;
  var_pop?: InputMaybe<Frame_Buttons_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Frame_Buttons_Var_Samp_Order_By>;
  variance?: InputMaybe<Frame_Buttons_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "frame_buttons" */
export type Frame_Buttons_Arr_Rel_Insert_Input = {
  data: Array<Frame_Buttons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Frame_Buttons_On_Conflict>;
};

/** aggregate avg on columns */
export type Frame_Buttons_Avg_Fields = {
  __typename?: 'frame_buttons_avg_fields';
  id?: Maybe<Scalars['Float']>;
  idx?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "frame_buttons" */
export type Frame_Buttons_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "frame_buttons". All fields are combined with a logical 'AND'. */
export type Frame_Buttons_Bool_Exp = {
  _and?: InputMaybe<Array<Frame_Buttons_Bool_Exp>>;
  _not?: InputMaybe<Frame_Buttons_Bool_Exp>;
  _or?: InputMaybe<Array<Frame_Buttons_Bool_Exp>>;
  action?: InputMaybe<Frame_Action_Enum_Comparison_Exp>;
  frame?: InputMaybe<Frames_Bool_Exp>;
  frameId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  idx?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  postUrl?: InputMaybe<String_Comparison_Exp>;
  target?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "frame_buttons" */
export enum Frame_Buttons_Constraint {
  /** unique or primary key constraint on columns "idx", "frame_id" */
  FrameButtonsFrameIdIdxKey = 'frame_buttons_frame_id_idx_key',
  /** unique or primary key constraint on columns "id" */
  FrameButtonsPkey = 'frame_buttons_pkey'
}

/** input type for incrementing numeric columns in table "frame_buttons" */
export type Frame_Buttons_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  idx?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "frame_buttons" */
export type Frame_Buttons_Insert_Input = {
  action?: InputMaybe<Frame_Action_Enum>;
  frame?: InputMaybe<Frames_Obj_Rel_Insert_Input>;
  frameId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  idx?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  postUrl?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Frame_Buttons_Max_Fields = {
  __typename?: 'frame_buttons_max_fields';
  frameId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  idx?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  postUrl?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "frame_buttons" */
export type Frame_Buttons_Max_Order_By = {
  frameId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  postUrl?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Frame_Buttons_Min_Fields = {
  __typename?: 'frame_buttons_min_fields';
  frameId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  idx?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  postUrl?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "frame_buttons" */
export type Frame_Buttons_Min_Order_By = {
  frameId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  postUrl?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "frame_buttons" */
export type Frame_Buttons_Mutation_Response = {
  __typename?: 'frame_buttons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Frame_Buttons>;
};

/** on_conflict condition type for table "frame_buttons" */
export type Frame_Buttons_On_Conflict = {
  constraint: Frame_Buttons_Constraint;
  update_columns?: Array<Frame_Buttons_Update_Column>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};

/** Ordering options when selecting data from "frame_buttons". */
export type Frame_Buttons_Order_By = {
  action?: InputMaybe<Order_By>;
  frame?: InputMaybe<Frames_Order_By>;
  frameId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  postUrl?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
};

/** primary key columns input for table: frame_buttons */
export type Frame_Buttons_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "frame_buttons" */
export enum Frame_Buttons_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  FrameId = 'frameId',
  /** column name */
  Id = 'id',
  /** column name */
  Idx = 'idx',
  /** column name */
  Label = 'label',
  /** column name */
  PostUrl = 'postUrl',
  /** column name */
  Target = 'target'
}

/** input type for updating data in table "frame_buttons" */
export type Frame_Buttons_Set_Input = {
  action?: InputMaybe<Frame_Action_Enum>;
  frameId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  idx?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  postUrl?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Frame_Buttons_Stddev_Fields = {
  __typename?: 'frame_buttons_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  idx?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "frame_buttons" */
export type Frame_Buttons_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Frame_Buttons_Stddev_Pop_Fields = {
  __typename?: 'frame_buttons_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  idx?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "frame_buttons" */
export type Frame_Buttons_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Frame_Buttons_Stddev_Samp_Fields = {
  __typename?: 'frame_buttons_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  idx?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "frame_buttons" */
export type Frame_Buttons_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "frame_buttons" */
export type Frame_Buttons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frame_Buttons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frame_Buttons_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Frame_Action_Enum>;
  frameId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  idx?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  postUrl?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Frame_Buttons_Sum_Fields = {
  __typename?: 'frame_buttons_sum_fields';
  id?: Maybe<Scalars['Int']>;
  idx?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "frame_buttons" */
export type Frame_Buttons_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** update columns of table "frame_buttons" */
export enum Frame_Buttons_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  FrameId = 'frameId',
  /** column name */
  Id = 'id',
  /** column name */
  Idx = 'idx',
  /** column name */
  Label = 'label',
  /** column name */
  PostUrl = 'postUrl',
  /** column name */
  Target = 'target'
}

export type Frame_Buttons_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Frame_Buttons_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frame_Buttons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frame_Buttons_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Frame_Buttons_Var_Pop_Fields = {
  __typename?: 'frame_buttons_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  idx?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "frame_buttons" */
export type Frame_Buttons_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Frame_Buttons_Var_Samp_Fields = {
  __typename?: 'frame_buttons_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  idx?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "frame_buttons" */
export type Frame_Buttons_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Frame_Buttons_Variance_Fields = {
  __typename?: 'frame_buttons_variance_fields';
  id?: Maybe<Scalars['Float']>;
  idx?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "frame_buttons" */
export type Frame_Buttons_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  idx?: InputMaybe<Order_By>;
};

/** columns and relationships of "frames" */
export type Frames = {
  __typename?: 'frames';
  accepts?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  buttons: Array<Frame_Buttons>;
  /** An aggregate relationship */
  buttons_aggregate: Frame_Buttons_Aggregate;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageAspectRatio?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  inputText?: Maybe<Scalars['String']>;
  isFcFrame?: Maybe<Scalars['Boolean']>;
  isOpenFrame?: Maybe<Scalars['Boolean']>;
  ogImageUrl: Scalars['String'];
  postUrl?: Maybe<Scalars['String']>;
  refetch_requested_at?: Maybe<Scalars['timestamptz']>;
  updatedAt: Scalars['timestamp'];
  url: Scalars['String'];
  urlHash?: Maybe<Scalars['bytea']>;
  version: Scalars['String'];
};


/** columns and relationships of "frames" */
export type FramesAcceptsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "frames" */
export type FramesButtonsArgs = {
  distinct_on?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Buttons_Order_By>>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};


/** columns and relationships of "frames" */
export type FramesButtons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Buttons_Order_By>>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};

/** aggregated selection of "frames" */
export type Frames_Aggregate = {
  __typename?: 'frames_aggregate';
  aggregate?: Maybe<Frames_Aggregate_Fields>;
  nodes: Array<Frames>;
};

/** aggregate fields of "frames" */
export type Frames_Aggregate_Fields = {
  __typename?: 'frames_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Frames_Max_Fields>;
  min?: Maybe<Frames_Min_Fields>;
};


/** aggregate fields of "frames" */
export type Frames_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frames_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Frames_Append_Input = {
  accepts?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "frames". All fields are combined with a logical 'AND'. */
export type Frames_Bool_Exp = {
  _and?: InputMaybe<Array<Frames_Bool_Exp>>;
  _not?: InputMaybe<Frames_Bool_Exp>;
  _or?: InputMaybe<Array<Frames_Bool_Exp>>;
  accepts?: InputMaybe<Jsonb_Comparison_Exp>;
  buttons?: InputMaybe<Frame_Buttons_Bool_Exp>;
  buttons_aggregate?: InputMaybe<Frame_Buttons_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageAspectRatio?: InputMaybe<String_Comparison_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  imageUrl?: InputMaybe<String_Comparison_Exp>;
  inputText?: InputMaybe<String_Comparison_Exp>;
  isFcFrame?: InputMaybe<Boolean_Comparison_Exp>;
  isOpenFrame?: InputMaybe<Boolean_Comparison_Exp>;
  ogImageUrl?: InputMaybe<String_Comparison_Exp>;
  postUrl?: InputMaybe<String_Comparison_Exp>;
  refetch_requested_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  urlHash?: InputMaybe<Bytea_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "frames" */
export enum Frames_Constraint {
  /** unique or primary key constraint on columns "id" */
  FramesPkey = 'frames_pkey',
  /** unique or primary key constraint on columns "url_hash" */
  FramesUrlHashUnique = 'frames_url_hash_unique'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Frames_Delete_At_Path_Input = {
  accepts?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Frames_Delete_Elem_Input = {
  accepts?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Frames_Delete_Key_Input = {
  accepts?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "frames" */
export type Frames_Insert_Input = {
  accepts?: InputMaybe<Scalars['jsonb']>;
  buttons?: InputMaybe<Frame_Buttons_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageAspectRatio?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  inputText?: InputMaybe<Scalars['String']>;
  isFcFrame?: InputMaybe<Scalars['Boolean']>;
  isOpenFrame?: InputMaybe<Scalars['Boolean']>;
  ogImageUrl?: InputMaybe<Scalars['String']>;
  postUrl?: InputMaybe<Scalars['String']>;
  refetch_requested_at?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Frames_Max_Fields = {
  __typename?: 'frames_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  imageAspectRatio?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  inputText?: Maybe<Scalars['String']>;
  ogImageUrl?: Maybe<Scalars['String']>;
  postUrl?: Maybe<Scalars['String']>;
  refetch_requested_at?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Frames_Min_Fields = {
  __typename?: 'frames_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  imageAspectRatio?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  inputText?: Maybe<Scalars['String']>;
  ogImageUrl?: Maybe<Scalars['String']>;
  postUrl?: Maybe<Scalars['String']>;
  refetch_requested_at?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "frames" */
export type Frames_Mutation_Response = {
  __typename?: 'frames_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Frames>;
};

/** input type for inserting object relation for remote table "frames" */
export type Frames_Obj_Rel_Insert_Input = {
  data: Frames_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Frames_On_Conflict>;
};

/** on_conflict condition type for table "frames" */
export type Frames_On_Conflict = {
  constraint: Frames_Constraint;
  update_columns?: Array<Frames_Update_Column>;
  where?: InputMaybe<Frames_Bool_Exp>;
};

/** Ordering options when selecting data from "frames". */
export type Frames_Order_By = {
  accepts?: InputMaybe<Order_By>;
  buttons_aggregate?: InputMaybe<Frame_Buttons_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageAspectRatio?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  inputText?: InputMaybe<Order_By>;
  isFcFrame?: InputMaybe<Order_By>;
  isOpenFrame?: InputMaybe<Order_By>;
  ogImageUrl?: InputMaybe<Order_By>;
  postUrl?: InputMaybe<Order_By>;
  refetch_requested_at?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  urlHash?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: frames */
export type Frames_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Frames_Prepend_Input = {
  accepts?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "frames" */
export enum Frames_Select_Column {
  /** column name */
  Accepts = 'accepts',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageAspectRatio = 'imageAspectRatio',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  InputText = 'inputText',
  /** column name */
  IsFcFrame = 'isFcFrame',
  /** column name */
  IsOpenFrame = 'isOpenFrame',
  /** column name */
  OgImageUrl = 'ogImageUrl',
  /** column name */
  PostUrl = 'postUrl',
  /** column name */
  RefetchRequestedAt = 'refetch_requested_at',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UrlHash = 'urlHash',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "frames" */
export type Frames_Set_Input = {
  accepts?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageAspectRatio?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  inputText?: InputMaybe<Scalars['String']>;
  isFcFrame?: InputMaybe<Scalars['Boolean']>;
  isOpenFrame?: InputMaybe<Scalars['Boolean']>;
  ogImageUrl?: InputMaybe<Scalars['String']>;
  postUrl?: InputMaybe<Scalars['String']>;
  refetch_requested_at?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "frames" */
export type Frames_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frames_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frames_Stream_Cursor_Value_Input = {
  accepts?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageAspectRatio?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  inputText?: InputMaybe<Scalars['String']>;
  isFcFrame?: InputMaybe<Scalars['Boolean']>;
  isOpenFrame?: InputMaybe<Scalars['Boolean']>;
  ogImageUrl?: InputMaybe<Scalars['String']>;
  postUrl?: InputMaybe<Scalars['String']>;
  refetch_requested_at?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
  urlHash?: InputMaybe<Scalars['bytea']>;
  version?: InputMaybe<Scalars['String']>;
};

/** update columns of table "frames" */
export enum Frames_Update_Column {
  /** column name */
  Accepts = 'accepts',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageAspectRatio = 'imageAspectRatio',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  InputText = 'inputText',
  /** column name */
  IsFcFrame = 'isFcFrame',
  /** column name */
  IsOpenFrame = 'isOpenFrame',
  /** column name */
  OgImageUrl = 'ogImageUrl',
  /** column name */
  PostUrl = 'postUrl',
  /** column name */
  RefetchRequestedAt = 'refetch_requested_at',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version'
}

export type Frames_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Frames_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Frames_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Frames_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Frames_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Frames_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frames_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frames_Bool_Exp;
};

/** columns and relationships of "images" */
export type Images = {
  __typename?: 'images';
  /** An object relationship */
  async_status: Async_Status;
  blurhash: Scalars['String'];
  bucket: Scalars['String'];
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  /** An array relationship */
  community_categories: Array<Community_Categories>;
  /** An aggregate relationship */
  community_categories_aggregate: Community_Categories_Aggregate;
  /** An array relationship */
  connected_nfts: Array<Connected_Nfts>;
  /** An aggregate relationship */
  connected_nfts_aggregate: Connected_Nfts_Aggregate;
  contentType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  filename: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['String'];
  /** An array relationship */
  items: Array<Items>;
  /** An aggregate relationship */
  items_aggregate: Items_Aggregate;
  /** An array relationship */
  lens_profiles: Array<Lens_Profiles>;
  /** An aggregate relationship */
  lens_profiles_aggregate: Lens_Profiles_Aggregate;
  /** An array relationship */
  links: Array<Links>;
  /** An aggregate relationship */
  links_aggregate: Links_Aggregate;
  loop?: Maybe<Scalars['Int']>;
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  pages?: Maybe<Scalars['Int']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An array relationship */
  profilesByImageId: Array<Profiles>;
  /** An aggregate relationship */
  profilesByImageId_aggregate: Profiles_Aggregate;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  /** An array relationship */
  recommendation_comments: Array<Recommendation_Comments>;
  /** An aggregate relationship */
  recommendation_comments_aggregate: Recommendation_Comments_Aggregate;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: Recommendations_Aggregate;
  /** An array relationship */
  rewardBrandsByLogoImageId: Array<Reward_Brands>;
  /** An aggregate relationship */
  rewardBrandsByLogoImageId_aggregate: Reward_Brands_Aggregate;
  /** An array relationship */
  reward_brands: Array<Reward_Brands>;
  /** An aggregate relationship */
  reward_brands_aggregate: Reward_Brands_Aggregate;
  /** An array relationship */
  reward_types: Array<Reward_Types>;
  /** An aggregate relationship */
  reward_types_aggregate: Reward_Types_Aggregate;
  size: Scalars['Int'];
  /** A computed field, executes function "get_valid_source_url" */
  sourceUrl?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  status: Async_Status_Enum;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  verified_nft_projects: Array<Verified_Nft_Projects>;
  /** An aggregate relationship */
  verified_nft_projects_aggregate: Verified_Nft_Projects_Aggregate;
  width: Scalars['Int'];
};


/** columns and relationships of "images" */
export type ImagesCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesCommunity_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesCommunity_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesConnected_NftsArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesConnected_Nfts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesLens_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesLens_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesLinksArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesProfilesByImageIdArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesProfilesByImageId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesRecommendation_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesRecommendation_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesRecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesRecommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesRewardBrandsByLogoImageIdArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesRewardBrandsByLogoImageId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesReward_BrandsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesReward_Brands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesReward_TypesArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesReward_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesVerified_Nft_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesVerified_Nft_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};

/** aggregated selection of "images" */
export type Images_Aggregate = {
  __typename?: 'images_aggregate';
  aggregate?: Maybe<Images_Aggregate_Fields>;
  nodes: Array<Images>;
};

export type Images_Aggregate_Bool_Exp = {
  count?: InputMaybe<Images_Aggregate_Bool_Exp_Count>;
};

export type Images_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Images_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "images" */
export type Images_Aggregate_Fields = {
  __typename?: 'images_aggregate_fields';
  avg?: Maybe<Images_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Images_Max_Fields>;
  min?: Maybe<Images_Min_Fields>;
  stddev?: Maybe<Images_Stddev_Fields>;
  stddev_pop?: Maybe<Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Images_Stddev_Samp_Fields>;
  sum?: Maybe<Images_Sum_Fields>;
  var_pop?: Maybe<Images_Var_Pop_Fields>;
  var_samp?: Maybe<Images_Var_Samp_Fields>;
  variance?: Maybe<Images_Variance_Fields>;
};


/** aggregate fields of "images" */
export type Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "images" */
export type Images_Aggregate_Order_By = {
  avg?: InputMaybe<Images_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Images_Max_Order_By>;
  min?: InputMaybe<Images_Min_Order_By>;
  stddev?: InputMaybe<Images_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Images_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Images_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Images_Sum_Order_By>;
  var_pop?: InputMaybe<Images_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Images_Var_Samp_Order_By>;
  variance?: InputMaybe<Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "images" */
export type Images_Arr_Rel_Insert_Input = {
  data: Array<Images_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Images_Avg_Fields = {
  __typename?: 'images_avg_fields';
  height?: Maybe<Scalars['Float']>;
  loop?: Maybe<Scalars['Float']>;
  pages?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "images" */
export type Images_Avg_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type Images_Bool_Exp = {
  _and?: InputMaybe<Array<Images_Bool_Exp>>;
  _not?: InputMaybe<Images_Bool_Exp>;
  _or?: InputMaybe<Array<Images_Bool_Exp>>;
  async_status?: InputMaybe<Async_Status_Bool_Exp>;
  blurhash?: InputMaybe<String_Comparison_Exp>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  communities?: InputMaybe<Communities_Bool_Exp>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Bool_Exp>;
  community_categories?: InputMaybe<Community_Categories_Bool_Exp>;
  community_categories_aggregate?: InputMaybe<Community_Categories_Aggregate_Bool_Exp>;
  connected_nfts?: InputMaybe<Connected_Nfts_Bool_Exp>;
  connected_nfts_aggregate?: InputMaybe<Connected_Nfts_Aggregate_Bool_Exp>;
  contentType?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  items?: InputMaybe<Items_Bool_Exp>;
  items_aggregate?: InputMaybe<Items_Aggregate_Bool_Exp>;
  lens_profiles?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_profiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Bool_Exp>;
  links?: InputMaybe<Links_Bool_Exp>;
  links_aggregate?: InputMaybe<Links_Aggregate_Bool_Exp>;
  loop?: InputMaybe<Int_Comparison_Exp>;
  medias?: InputMaybe<Medias_Bool_Exp>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Bool_Exp>;
  pages?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Profiles_Bool_Exp>;
  profilesByImageId?: InputMaybe<Profiles_Bool_Exp>;
  profilesByImageId_aggregate?: InputMaybe<Profiles_Aggregate_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Bool_Exp>;
  recommendation_comments?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  recommendation_comments_aggregate?: InputMaybe<Recommendation_Comments_Aggregate_Bool_Exp>;
  recommendations?: InputMaybe<Recommendations_Bool_Exp>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Bool_Exp>;
  rewardBrandsByLogoImageId?: InputMaybe<Reward_Brands_Bool_Exp>;
  rewardBrandsByLogoImageId_aggregate?: InputMaybe<Reward_Brands_Aggregate_Bool_Exp>;
  reward_brands?: InputMaybe<Reward_Brands_Bool_Exp>;
  reward_brands_aggregate?: InputMaybe<Reward_Brands_Aggregate_Bool_Exp>;
  reward_types?: InputMaybe<Reward_Types_Bool_Exp>;
  reward_types_aggregate?: InputMaybe<Reward_Types_Aggregate_Bool_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  sourceUrl?: InputMaybe<String_Comparison_Exp>;
  source_url?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Async_Status_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  verified_nft_projects?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  verified_nft_projects_aggregate?: InputMaybe<Verified_Nft_Projects_Aggregate_Bool_Exp>;
  width?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "images" */
export enum Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImagesPkey = 'images_pkey'
}

/** input type for incrementing numeric columns in table "images" */
export type Images_Inc_Input = {
  height?: InputMaybe<Scalars['Int']>;
  loop?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "images" */
export type Images_Insert_Input = {
  async_status?: InputMaybe<Async_Status_Obj_Rel_Insert_Input>;
  blurhash?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  communities?: InputMaybe<Communities_Arr_Rel_Insert_Input>;
  community_categories?: InputMaybe<Community_Categories_Arr_Rel_Insert_Input>;
  connected_nfts?: InputMaybe<Connected_Nfts_Arr_Rel_Insert_Input>;
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  filename?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Items_Arr_Rel_Insert_Input>;
  lens_profiles?: InputMaybe<Lens_Profiles_Arr_Rel_Insert_Input>;
  links?: InputMaybe<Links_Arr_Rel_Insert_Input>;
  loop?: InputMaybe<Scalars['Int']>;
  medias?: InputMaybe<Medias_Arr_Rel_Insert_Input>;
  pages?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Profiles_Arr_Rel_Insert_Input>;
  profilesByImageId?: InputMaybe<Profiles_Arr_Rel_Insert_Input>;
  recommendation_comments?: InputMaybe<Recommendation_Comments_Arr_Rel_Insert_Input>;
  recommendations?: InputMaybe<Recommendations_Arr_Rel_Insert_Input>;
  rewardBrandsByLogoImageId?: InputMaybe<Reward_Brands_Arr_Rel_Insert_Input>;
  reward_brands?: InputMaybe<Reward_Brands_Arr_Rel_Insert_Input>;
  reward_types?: InputMaybe<Reward_Types_Arr_Rel_Insert_Input>;
  size?: InputMaybe<Scalars['Int']>;
  source_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verified_nft_projects?: InputMaybe<Verified_Nft_Projects_Arr_Rel_Insert_Input>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Images_Max_Fields = {
  __typename?: 'images_max_fields';
  blurhash?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  loop?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_valid_source_url" */
  sourceUrl?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "images" */
export type Images_Max_Order_By = {
  blurhash?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  contentType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  source_url?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Images_Min_Fields = {
  __typename?: 'images_min_fields';
  blurhash?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  loop?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_valid_source_url" */
  sourceUrl?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "images" */
export type Images_Min_Order_By = {
  blurhash?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  contentType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  source_url?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "images" */
export type Images_Mutation_Response = {
  __typename?: 'images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Images>;
};

/** input type for inserting object relation for remote table "images" */
export type Images_Obj_Rel_Insert_Input = {
  data: Images_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Images_On_Conflict>;
};

/** on_conflict condition type for table "images" */
export type Images_On_Conflict = {
  constraint: Images_Constraint;
  update_columns?: Array<Images_Update_Column>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** Ordering options when selecting data from "images". */
export type Images_Order_By = {
  async_status?: InputMaybe<Async_Status_Order_By>;
  blurhash?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Order_By>;
  community_categories_aggregate?: InputMaybe<Community_Categories_Aggregate_Order_By>;
  connected_nfts_aggregate?: InputMaybe<Connected_Nfts_Aggregate_Order_By>;
  contentType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  items_aggregate?: InputMaybe<Items_Aggregate_Order_By>;
  lens_profiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Order_By>;
  links_aggregate?: InputMaybe<Links_Aggregate_Order_By>;
  loop?: InputMaybe<Order_By>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Order_By>;
  pages?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  profilesByImageId_aggregate?: InputMaybe<Profiles_Aggregate_Order_By>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Order_By>;
  recommendation_comments_aggregate?: InputMaybe<Recommendation_Comments_Aggregate_Order_By>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Order_By>;
  rewardBrandsByLogoImageId_aggregate?: InputMaybe<Reward_Brands_Aggregate_Order_By>;
  reward_brands_aggregate?: InputMaybe<Reward_Brands_Aggregate_Order_By>;
  reward_types_aggregate?: InputMaybe<Reward_Types_Aggregate_Order_By>;
  size?: InputMaybe<Order_By>;
  sourceUrl?: InputMaybe<Order_By>;
  source_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  verified_nft_projects_aggregate?: InputMaybe<Verified_Nft_Projects_Aggregate_Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: images */
export type Images_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "images" */
export enum Images_Select_Column {
  /** column name */
  Blurhash = 'blurhash',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  ContentType = 'contentType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Filename = 'filename',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Loop = 'loop',
  /** column name */
  Pages = 'pages',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Size = 'size',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "images" */
export type Images_Set_Input = {
  blurhash?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  filename?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  loop?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  source_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Images_Stddev_Fields = {
  __typename?: 'images_stddev_fields';
  height?: Maybe<Scalars['Float']>;
  loop?: Maybe<Scalars['Float']>;
  pages?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "images" */
export type Images_Stddev_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Images_Stddev_Pop_Fields = {
  __typename?: 'images_stddev_pop_fields';
  height?: Maybe<Scalars['Float']>;
  loop?: Maybe<Scalars['Float']>;
  pages?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "images" */
export type Images_Stddev_Pop_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Images_Stddev_Samp_Fields = {
  __typename?: 'images_stddev_samp_fields';
  height?: Maybe<Scalars['Float']>;
  loop?: Maybe<Scalars['Float']>;
  pages?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "images" */
export type Images_Stddev_Samp_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "images" */
export type Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Images_Stream_Cursor_Value_Input = {
  blurhash?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  filename?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  loop?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  source_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Async_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Images_Sum_Fields = {
  __typename?: 'images_sum_fields';
  height?: Maybe<Scalars['Int']>;
  loop?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "images" */
export type Images_Sum_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** update columns of table "images" */
export enum Images_Update_Column {
  /** column name */
  Blurhash = 'blurhash',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  ContentType = 'contentType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Filename = 'filename',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Loop = 'loop',
  /** column name */
  Pages = 'pages',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Size = 'size',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Width = 'width'
}

export type Images_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Images_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Images_Set_Input>;
  /** filter the rows which have to be updated */
  where: Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Images_Var_Pop_Fields = {
  __typename?: 'images_var_pop_fields';
  height?: Maybe<Scalars['Float']>;
  loop?: Maybe<Scalars['Float']>;
  pages?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "images" */
export type Images_Var_Pop_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Images_Var_Samp_Fields = {
  __typename?: 'images_var_samp_fields';
  height?: Maybe<Scalars['Float']>;
  loop?: Maybe<Scalars['Float']>;
  pages?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "images" */
export type Images_Var_Samp_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Images_Variance_Fields = {
  __typename?: 'images_variance_fields';
  height?: Maybe<Scalars['Float']>;
  loop?: Maybe<Scalars['Float']>;
  pages?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "images" */
export type Images_Variance_Order_By = {
  height?: InputMaybe<Order_By>;
  loop?: InputMaybe<Order_By>;
  pages?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** columns and relationships of "item_others" */
export type Item_Others = {
  __typename?: 'item_others';
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  item: Items;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "item_others" */
export type Item_Others_Aggregate = {
  __typename?: 'item_others_aggregate';
  aggregate?: Maybe<Item_Others_Aggregate_Fields>;
  nodes: Array<Item_Others>;
};

/** aggregate fields of "item_others" */
export type Item_Others_Aggregate_Fields = {
  __typename?: 'item_others_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Item_Others_Max_Fields>;
  min?: Maybe<Item_Others_Min_Fields>;
};


/** aggregate fields of "item_others" */
export type Item_Others_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Others_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "item_others". All fields are combined with a logical 'AND'. */
export type Item_Others_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Others_Bool_Exp>>;
  _not?: InputMaybe<Item_Others_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Others_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_others" */
export enum Item_Others_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemOthersPkey = 'item_others_pkey'
}

/** input type for inserting data into table "item_others" */
export type Item_Others_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Item_Others_Max_Fields = {
  __typename?: 'item_others_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Item_Others_Min_Fields = {
  __typename?: 'item_others_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "item_others" */
export type Item_Others_Mutation_Response = {
  __typename?: 'item_others_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Others>;
};

/** input type for inserting object relation for remote table "item_others" */
export type Item_Others_Obj_Rel_Insert_Input = {
  data: Item_Others_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Others_On_Conflict>;
};

/** on_conflict condition type for table "item_others" */
export type Item_Others_On_Conflict = {
  constraint: Item_Others_Constraint;
  update_columns?: Array<Item_Others_Update_Column>;
  where?: InputMaybe<Item_Others_Bool_Exp>;
};

/** Ordering options when selecting data from "item_others". */
export type Item_Others_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_others */
export type Item_Others_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "item_others" */
export enum Item_Others_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "item_others" */
export type Item_Others_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "item_others" */
export type Item_Others_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Others_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Others_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "item_others" */
export enum Item_Others_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Item_Others_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Others_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Others_Bool_Exp;
};

/** columns and relationships of "item_types" */
export type Item_Types = {
  __typename?: 'item_types';
  /** An array relationship */
  items: Array<Items>;
  /** An aggregate relationship */
  items_aggregate: Items_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "item_types" */
export type Item_TypesItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


/** columns and relationships of "item_types" */
export type Item_TypesItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};

/** aggregated selection of "item_types" */
export type Item_Types_Aggregate = {
  __typename?: 'item_types_aggregate';
  aggregate?: Maybe<Item_Types_Aggregate_Fields>;
  nodes: Array<Item_Types>;
};

/** aggregate fields of "item_types" */
export type Item_Types_Aggregate_Fields = {
  __typename?: 'item_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Item_Types_Max_Fields>;
  min?: Maybe<Item_Types_Min_Fields>;
};


/** aggregate fields of "item_types" */
export type Item_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "item_types". All fields are combined with a logical 'AND'. */
export type Item_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Types_Bool_Exp>>;
  _not?: InputMaybe<Item_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Types_Bool_Exp>>;
  items?: InputMaybe<Items_Bool_Exp>;
  items_aggregate?: InputMaybe<Items_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_types" */
export enum Item_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  ItemTypesPkey = 'item_types_pkey'
}

export enum Item_Types_Enum {
  App = 'app',
  Other = 'other',
  Place = 'place',
  Product = 'product'
}

/** Boolean expression to compare columns of type "item_types_enum". All fields are combined with logical 'AND'. */
export type Item_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Item_Types_Enum>;
  _in?: InputMaybe<Array<Item_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Item_Types_Enum>;
  _nin?: InputMaybe<Array<Item_Types_Enum>>;
};

/** input type for inserting data into table "item_types" */
export type Item_Types_Insert_Input = {
  items?: InputMaybe<Items_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Item_Types_Max_Fields = {
  __typename?: 'item_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Item_Types_Min_Fields = {
  __typename?: 'item_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "item_types" */
export type Item_Types_Mutation_Response = {
  __typename?: 'item_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Types>;
};

/** input type for inserting object relation for remote table "item_types" */
export type Item_Types_Obj_Rel_Insert_Input = {
  data: Item_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Types_On_Conflict>;
};

/** on_conflict condition type for table "item_types" */
export type Item_Types_On_Conflict = {
  constraint: Item_Types_Constraint;
  update_columns?: Array<Item_Types_Update_Column>;
  where?: InputMaybe<Item_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "item_types". */
export type Item_Types_Order_By = {
  items_aggregate?: InputMaybe<Items_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_types */
export type Item_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "item_types" */
export enum Item_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "item_types" */
export type Item_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "item_types" */
export type Item_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "item_types" */
export enum Item_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Item_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Types_Bool_Exp;
};

/** columns and relationships of "items" */
export type Items = {
  __typename?: 'items';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  /** An object relationship */
  item_type: Item_Types;
  link: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  other?: Maybe<Item_Others>;
  phaverDiscountCode: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profiles>;
  profileId?: Maybe<Scalars['String']>;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: Recommendations_Aggregate;
  type: Item_Types_Enum;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "items" */
export type ItemsRecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsRecommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};

/** aggregated selection of "items" */
export type Items_Aggregate = {
  __typename?: 'items_aggregate';
  aggregate?: Maybe<Items_Aggregate_Fields>;
  nodes: Array<Items>;
};

export type Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Items_Aggregate_Bool_Exp_Count>;
};

export type Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items" */
export type Items_Aggregate_Fields = {
  __typename?: 'items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Max_Fields>;
  min?: Maybe<Items_Min_Fields>;
};


/** aggregate fields of "items" */
export type Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "items" */
export type Items_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Items_Max_Order_By>;
  min?: InputMaybe<Items_Min_Order_By>;
};

/** input type for inserting array relation for remote table "items" */
export type Items_Arr_Rel_Insert_Input = {
  data: Array<Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "items". All fields are combined with a logical 'AND'. */
export type Items_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Bool_Exp>>;
  _not?: InputMaybe<Items_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  item_type?: InputMaybe<Item_Types_Bool_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  other?: InputMaybe<Item_Others_Bool_Exp>;
  phaverDiscountCode?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  recommendations?: InputMaybe<Recommendations_Bool_Exp>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Bool_Exp>;
  type?: InputMaybe<Item_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "items" */
export enum Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemsPkey = 'items_pkey'
}

/** input type for inserting data into table "items" */
export type Items_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  item_type?: InputMaybe<Item_Types_Obj_Rel_Insert_Input>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Item_Others_Obj_Rel_Insert_Input>;
  phaverDiscountCode?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  recommendations?: InputMaybe<Recommendations_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Item_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Items_Max_Fields = {
  __typename?: 'items_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phaverDiscountCode?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "items" */
export type Items_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phaverDiscountCode?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_Min_Fields = {
  __typename?: 'items_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phaverDiscountCode?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "items" */
export type Items_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phaverDiscountCode?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items" */
export type Items_Mutation_Response = {
  __typename?: 'items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items>;
};

/** input type for inserting object relation for remote table "items" */
export type Items_Obj_Rel_Insert_Input = {
  data: Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** on_conflict condition type for table "items" */
export type Items_On_Conflict = {
  constraint: Items_Constraint;
  update_columns?: Array<Items_Update_Column>;
  where?: InputMaybe<Items_Bool_Exp>;
};

/** Ordering options when selecting data from "items". */
export type Items_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Item_Types_Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  other?: InputMaybe<Item_Others_Order_By>;
  phaverDiscountCode?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items */
export type Items_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "items" */
export enum Items_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  PhaverDiscountCode = 'phaverDiscountCode',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "items" */
export type Items_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phaverDiscountCode?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Item_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "items" */
export type Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phaverDiscountCode?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Item_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "items" */
export enum Items_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  PhaverDiscountCode = 'phaverDiscountCode',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "knex_migrations" */
export type Knex_Migrations = {
  __typename?: 'knex_migrations';
  batch?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  migration_time?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "knex_migrations" */
export type Knex_Migrations_Aggregate = {
  __typename?: 'knex_migrations_aggregate';
  aggregate?: Maybe<Knex_Migrations_Aggregate_Fields>;
  nodes: Array<Knex_Migrations>;
};

/** aggregate fields of "knex_migrations" */
export type Knex_Migrations_Aggregate_Fields = {
  __typename?: 'knex_migrations_aggregate_fields';
  avg?: Maybe<Knex_Migrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Knex_Migrations_Max_Fields>;
  min?: Maybe<Knex_Migrations_Min_Fields>;
  stddev?: Maybe<Knex_Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Knex_Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Knex_Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Knex_Migrations_Sum_Fields>;
  var_pop?: Maybe<Knex_Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Knex_Migrations_Var_Samp_Fields>;
  variance?: Maybe<Knex_Migrations_Variance_Fields>;
};


/** aggregate fields of "knex_migrations" */
export type Knex_Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Knex_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Knex_Migrations_Avg_Fields = {
  __typename?: 'knex_migrations_avg_fields';
  batch?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "knex_migrations". All fields are combined with a logical 'AND'. */
export type Knex_Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Knex_Migrations_Bool_Exp>>;
  _not?: InputMaybe<Knex_Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Knex_Migrations_Bool_Exp>>;
  batch?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  migration_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "knex_migrations" */
export enum Knex_Migrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  KnexMigrationsPkey = 'knex_migrations_pkey'
}

/** input type for incrementing numeric columns in table "knex_migrations" */
export type Knex_Migrations_Inc_Input = {
  batch?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "knex_migrations" */
export type Knex_Migrations_Insert_Input = {
  batch?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  migration_time?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "knex_migrations_lock" */
export type Knex_Migrations_Lock = {
  __typename?: 'knex_migrations_lock';
  index: Scalars['Int'];
  is_locked?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "knex_migrations_lock" */
export type Knex_Migrations_Lock_Aggregate = {
  __typename?: 'knex_migrations_lock_aggregate';
  aggregate?: Maybe<Knex_Migrations_Lock_Aggregate_Fields>;
  nodes: Array<Knex_Migrations_Lock>;
};

/** aggregate fields of "knex_migrations_lock" */
export type Knex_Migrations_Lock_Aggregate_Fields = {
  __typename?: 'knex_migrations_lock_aggregate_fields';
  avg?: Maybe<Knex_Migrations_Lock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Knex_Migrations_Lock_Max_Fields>;
  min?: Maybe<Knex_Migrations_Lock_Min_Fields>;
  stddev?: Maybe<Knex_Migrations_Lock_Stddev_Fields>;
  stddev_pop?: Maybe<Knex_Migrations_Lock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Knex_Migrations_Lock_Stddev_Samp_Fields>;
  sum?: Maybe<Knex_Migrations_Lock_Sum_Fields>;
  var_pop?: Maybe<Knex_Migrations_Lock_Var_Pop_Fields>;
  var_samp?: Maybe<Knex_Migrations_Lock_Var_Samp_Fields>;
  variance?: Maybe<Knex_Migrations_Lock_Variance_Fields>;
};


/** aggregate fields of "knex_migrations_lock" */
export type Knex_Migrations_Lock_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Knex_Migrations_Lock_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Knex_Migrations_Lock_Avg_Fields = {
  __typename?: 'knex_migrations_lock_avg_fields';
  index?: Maybe<Scalars['Float']>;
  is_locked?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "knex_migrations_lock". All fields are combined with a logical 'AND'. */
export type Knex_Migrations_Lock_Bool_Exp = {
  _and?: InputMaybe<Array<Knex_Migrations_Lock_Bool_Exp>>;
  _not?: InputMaybe<Knex_Migrations_Lock_Bool_Exp>;
  _or?: InputMaybe<Array<Knex_Migrations_Lock_Bool_Exp>>;
  index?: InputMaybe<Int_Comparison_Exp>;
  is_locked?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "knex_migrations_lock" */
export enum Knex_Migrations_Lock_Constraint {
  /** unique or primary key constraint on columns "index" */
  KnexMigrationsLockPkey = 'knex_migrations_lock_pkey'
}

/** input type for incrementing numeric columns in table "knex_migrations_lock" */
export type Knex_Migrations_Lock_Inc_Input = {
  index?: InputMaybe<Scalars['Int']>;
  is_locked?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "knex_migrations_lock" */
export type Knex_Migrations_Lock_Insert_Input = {
  index?: InputMaybe<Scalars['Int']>;
  is_locked?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Knex_Migrations_Lock_Max_Fields = {
  __typename?: 'knex_migrations_lock_max_fields';
  index?: Maybe<Scalars['Int']>;
  is_locked?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Knex_Migrations_Lock_Min_Fields = {
  __typename?: 'knex_migrations_lock_min_fields';
  index?: Maybe<Scalars['Int']>;
  is_locked?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "knex_migrations_lock" */
export type Knex_Migrations_Lock_Mutation_Response = {
  __typename?: 'knex_migrations_lock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Knex_Migrations_Lock>;
};

/** on_conflict condition type for table "knex_migrations_lock" */
export type Knex_Migrations_Lock_On_Conflict = {
  constraint: Knex_Migrations_Lock_Constraint;
  update_columns?: Array<Knex_Migrations_Lock_Update_Column>;
  where?: InputMaybe<Knex_Migrations_Lock_Bool_Exp>;
};

/** Ordering options when selecting data from "knex_migrations_lock". */
export type Knex_Migrations_Lock_Order_By = {
  index?: InputMaybe<Order_By>;
  is_locked?: InputMaybe<Order_By>;
};

/** primary key columns input for table: knex_migrations_lock */
export type Knex_Migrations_Lock_Pk_Columns_Input = {
  index: Scalars['Int'];
};

/** select columns of table "knex_migrations_lock" */
export enum Knex_Migrations_Lock_Select_Column {
  /** column name */
  Index = 'index',
  /** column name */
  IsLocked = 'is_locked'
}

/** input type for updating data in table "knex_migrations_lock" */
export type Knex_Migrations_Lock_Set_Input = {
  index?: InputMaybe<Scalars['Int']>;
  is_locked?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Knex_Migrations_Lock_Stddev_Fields = {
  __typename?: 'knex_migrations_lock_stddev_fields';
  index?: Maybe<Scalars['Float']>;
  is_locked?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Knex_Migrations_Lock_Stddev_Pop_Fields = {
  __typename?: 'knex_migrations_lock_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
  is_locked?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Knex_Migrations_Lock_Stddev_Samp_Fields = {
  __typename?: 'knex_migrations_lock_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
  is_locked?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "knex_migrations_lock" */
export type Knex_Migrations_Lock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Knex_Migrations_Lock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Knex_Migrations_Lock_Stream_Cursor_Value_Input = {
  index?: InputMaybe<Scalars['Int']>;
  is_locked?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Knex_Migrations_Lock_Sum_Fields = {
  __typename?: 'knex_migrations_lock_sum_fields';
  index?: Maybe<Scalars['Int']>;
  is_locked?: Maybe<Scalars['Int']>;
};

/** update columns of table "knex_migrations_lock" */
export enum Knex_Migrations_Lock_Update_Column {
  /** column name */
  Index = 'index',
  /** column name */
  IsLocked = 'is_locked'
}

export type Knex_Migrations_Lock_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Knex_Migrations_Lock_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Knex_Migrations_Lock_Set_Input>;
  /** filter the rows which have to be updated */
  where: Knex_Migrations_Lock_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Knex_Migrations_Lock_Var_Pop_Fields = {
  __typename?: 'knex_migrations_lock_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
  is_locked?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Knex_Migrations_Lock_Var_Samp_Fields = {
  __typename?: 'knex_migrations_lock_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
  is_locked?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Knex_Migrations_Lock_Variance_Fields = {
  __typename?: 'knex_migrations_lock_variance_fields';
  index?: Maybe<Scalars['Float']>;
  is_locked?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Knex_Migrations_Max_Fields = {
  __typename?: 'knex_migrations_max_fields';
  batch?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  migration_time?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Knex_Migrations_Min_Fields = {
  __typename?: 'knex_migrations_min_fields';
  batch?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  migration_time?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "knex_migrations" */
export type Knex_Migrations_Mutation_Response = {
  __typename?: 'knex_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Knex_Migrations>;
};

/** on_conflict condition type for table "knex_migrations" */
export type Knex_Migrations_On_Conflict = {
  constraint: Knex_Migrations_Constraint;
  update_columns?: Array<Knex_Migrations_Update_Column>;
  where?: InputMaybe<Knex_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "knex_migrations". */
export type Knex_Migrations_Order_By = {
  batch?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  migration_time?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: knex_migrations */
export type Knex_Migrations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "knex_migrations" */
export enum Knex_Migrations_Select_Column {
  /** column name */
  Batch = 'batch',
  /** column name */
  Id = 'id',
  /** column name */
  MigrationTime = 'migration_time',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "knex_migrations" */
export type Knex_Migrations_Set_Input = {
  batch?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  migration_time?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Knex_Migrations_Stddev_Fields = {
  __typename?: 'knex_migrations_stddev_fields';
  batch?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Knex_Migrations_Stddev_Pop_Fields = {
  __typename?: 'knex_migrations_stddev_pop_fields';
  batch?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Knex_Migrations_Stddev_Samp_Fields = {
  __typename?: 'knex_migrations_stddev_samp_fields';
  batch?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "knex_migrations" */
export type Knex_Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Knex_Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Knex_Migrations_Stream_Cursor_Value_Input = {
  batch?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  migration_time?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Knex_Migrations_Sum_Fields = {
  __typename?: 'knex_migrations_sum_fields';
  batch?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "knex_migrations" */
export enum Knex_Migrations_Update_Column {
  /** column name */
  Batch = 'batch',
  /** column name */
  Id = 'id',
  /** column name */
  MigrationTime = 'migration_time',
  /** column name */
  Name = 'name'
}

export type Knex_Migrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Knex_Migrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Knex_Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Knex_Migrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Knex_Migrations_Var_Pop_Fields = {
  __typename?: 'knex_migrations_var_pop_fields';
  batch?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Knex_Migrations_Var_Samp_Fields = {
  __typename?: 'knex_migrations_var_samp_fields';
  batch?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Knex_Migrations_Variance_Fields = {
  __typename?: 'knex_migrations_variance_fields';
  batch?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "language_isocodes" */
export type Language_Isocodes = {
  __typename?: 'language_isocodes';
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: Recommendations_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "language_isocodes" */
export type Language_IsocodesCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "language_isocodes" */
export type Language_IsocodesCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "language_isocodes" */
export type Language_IsocodesRecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "language_isocodes" */
export type Language_IsocodesRecommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};

/** aggregated selection of "language_isocodes" */
export type Language_Isocodes_Aggregate = {
  __typename?: 'language_isocodes_aggregate';
  aggregate?: Maybe<Language_Isocodes_Aggregate_Fields>;
  nodes: Array<Language_Isocodes>;
};

/** aggregate fields of "language_isocodes" */
export type Language_Isocodes_Aggregate_Fields = {
  __typename?: 'language_isocodes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Language_Isocodes_Max_Fields>;
  min?: Maybe<Language_Isocodes_Min_Fields>;
};


/** aggregate fields of "language_isocodes" */
export type Language_Isocodes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Language_Isocodes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "language_isocodes". All fields are combined with a logical 'AND'. */
export type Language_Isocodes_Bool_Exp = {
  _and?: InputMaybe<Array<Language_Isocodes_Bool_Exp>>;
  _not?: InputMaybe<Language_Isocodes_Bool_Exp>;
  _or?: InputMaybe<Array<Language_Isocodes_Bool_Exp>>;
  communities?: InputMaybe<Communities_Bool_Exp>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Bool_Exp>;
  recommendations?: InputMaybe<Recommendations_Bool_Exp>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "language_isocodes" */
export enum Language_Isocodes_Constraint {
  /** unique or primary key constraint on columns "value" */
  LanguageIsocodesPkey = 'language_isocodes_pkey'
}

export enum Language_Isocodes_Enum {
  Aa = 'aa',
  Ab = 'ab',
  Ae = 'ae',
  Af = 'af',
  Ak = 'ak',
  Am = 'am',
  An = 'an',
  Ar = 'ar',
  As = 'as',
  Av = 'av',
  Ay = 'ay',
  Az = 'az',
  Ba = 'ba',
  Be = 'be',
  Bg = 'bg',
  Bh = 'bh',
  Bi = 'bi',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ce = 'ce',
  Ch = 'ch',
  Co = 'co',
  Cr = 'cr',
  Cs = 'cs',
  Cu = 'cu',
  Cv = 'cv',
  Cy = 'cy',
  Da = 'da',
  De = 'de',
  Dv = 'dv',
  Dz = 'dz',
  Ee = 'ee',
  El = 'el',
  En = 'en',
  Eo = 'eo',
  Es = 'es',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Ff = 'ff',
  Fi = 'fi',
  Fj = 'fj',
  Fo = 'fo',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  Gn = 'gn',
  Gu = 'gu',
  Gv = 'gv',
  Ha = 'ha',
  He = 'he',
  Hi = 'hi',
  Ho = 'ho',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Hy = 'hy',
  Hz = 'hz',
  Ia = 'ia',
  Id = 'id',
  Ie = 'ie',
  Ig = 'ig',
  Ii = 'ii',
  Ik = 'ik',
  Io = 'io',
  Is = 'is',
  It = 'it',
  Iu = 'iu',
  Ja = 'ja',
  Jv = 'jv',
  Ka = 'ka',
  Kg = 'kg',
  Ki = 'ki',
  Kj = 'kj',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Kr = 'kr',
  Ks = 'ks',
  Ku = 'ku',
  Kv = 'kv',
  Kw = 'kw',
  Ky = 'ky',
  La = 'la',
  Lb = 'lb',
  Lg = 'lg',
  Li = 'li',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Mg = 'mg',
  Mh = 'mh',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Na = 'na',
  Nb = 'nb',
  Nd = 'nd',
  Ne = 'ne',
  Ng = 'ng',
  Nl = 'nl',
  Nn = 'nn',
  No = 'no',
  Nr = 'nr',
  Nv = 'nv',
  Ny = 'ny',
  Oc = 'oc',
  Oj = 'oj',
  Om = 'om',
  Or = 'or',
  Os = 'os',
  Pa = 'pa',
  Pi = 'pi',
  Pl = 'pl',
  Ps = 'ps',
  Pt = 'pt',
  Qu = 'qu',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sc = 'sc',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  Tl = 'tl',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Ts = 'ts',
  Tt = 'tt',
  Tw = 'tw',
  Ty = 'ty',
  Ug = 'ug',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Ve = 've',
  Vi = 'vi',
  Vo = 'vo',
  Wa = 'wa',
  Wo = 'wo',
  Xh = 'xh',
  Yi = 'yi',
  Yo = 'yo',
  Za = 'za',
  Zh = 'zh',
  Zu = 'zu'
}

/** Boolean expression to compare columns of type "language_isocodes_enum". All fields are combined with logical 'AND'. */
export type Language_Isocodes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Language_Isocodes_Enum>;
  _in?: InputMaybe<Array<Language_Isocodes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Language_Isocodes_Enum>;
  _nin?: InputMaybe<Array<Language_Isocodes_Enum>>;
};

/** input type for inserting data into table "language_isocodes" */
export type Language_Isocodes_Insert_Input = {
  communities?: InputMaybe<Communities_Arr_Rel_Insert_Input>;
  recommendations?: InputMaybe<Recommendations_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Language_Isocodes_Max_Fields = {
  __typename?: 'language_isocodes_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Language_Isocodes_Min_Fields = {
  __typename?: 'language_isocodes_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "language_isocodes" */
export type Language_Isocodes_Mutation_Response = {
  __typename?: 'language_isocodes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Language_Isocodes>;
};

/** input type for inserting object relation for remote table "language_isocodes" */
export type Language_Isocodes_Obj_Rel_Insert_Input = {
  data: Language_Isocodes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Language_Isocodes_On_Conflict>;
};

/** on_conflict condition type for table "language_isocodes" */
export type Language_Isocodes_On_Conflict = {
  constraint: Language_Isocodes_Constraint;
  update_columns?: Array<Language_Isocodes_Update_Column>;
  where?: InputMaybe<Language_Isocodes_Bool_Exp>;
};

/** Ordering options when selecting data from "language_isocodes". */
export type Language_Isocodes_Order_By = {
  communities_aggregate?: InputMaybe<Communities_Aggregate_Order_By>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: language_isocodes */
export type Language_Isocodes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "language_isocodes" */
export enum Language_Isocodes_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "language_isocodes" */
export type Language_Isocodes_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "language_isocodes" */
export type Language_Isocodes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Language_Isocodes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Language_Isocodes_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "language_isocodes" */
export enum Language_Isocodes_Update_Column {
  /** column name */
  Value = 'value'
}

export type Language_Isocodes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Language_Isocodes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Language_Isocodes_Bool_Exp;
};

export type LensApiAuthenticateOutput = {
  __typename?: 'lensApiAuthenticateOutput';
  status: Scalars['String'];
};

export type LensApiChallengeOutput = {
  __typename?: 'lensApiChallengeOutput';
  challenge: Scalars['String'];
};

export type LensCheckRelayOutput = {
  __typename?: 'lensCheckRelayOutput';
  apiLogin: Scalars['Boolean'];
  connectedLensProfile: Scalars['Boolean'];
  isLensApiDispatcherSet: Scalars['Boolean'];
  isPhaverDispatcherSet: Scalars['Boolean'];
  sponsoredByLens: Scalars['Boolean'];
};

export type LensCreateProfileOutput = {
  __typename?: 'lensCreateProfileOutput';
  lensProfile?: Maybe<Lens_Profiles>;
  lensProfileId: Scalars['String'];
};

export type LensProfilesOwnedByAccountOutput = {
  __typename?: 'lensProfilesOwnedByAccountOutput';
  lensProfile?: Maybe<Lens_Profiles>;
  lensProfileId: Scalars['String'];
};

export type LensSetDispatcherBuildDataOutput = {
  __typename?: 'lensSetDispatcherBuildDataOutput';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type LensSetDispatcherExecuteOutput = {
  __typename?: 'lensSetDispatcherExecuteOutput';
  status: Scalars['String'];
};

/** columns and relationships of "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens = {
  __typename?: 'lens_api_access_tokens';
  address: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  lens_profile_id: Scalars['String'];
  token: Scalars['String'];
  token_expiration_time: Scalars['timestamptz'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_Aggregate = {
  __typename?: 'lens_api_access_tokens_aggregate';
  aggregate?: Maybe<Lens_Api_Access_Tokens_Aggregate_Fields>;
  nodes: Array<Lens_Api_Access_Tokens>;
};

/** aggregate fields of "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_Aggregate_Fields = {
  __typename?: 'lens_api_access_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Api_Access_Tokens_Max_Fields>;
  min?: Maybe<Lens_Api_Access_Tokens_Min_Fields>;
};


/** aggregate fields of "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Api_Access_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lens_api_access_tokens". All fields are combined with a logical 'AND'. */
export type Lens_Api_Access_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Api_Access_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Lens_Api_Access_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Api_Access_Tokens_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  lens_profile_id?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  token_expiration_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_api_access_tokens" */
export enum Lens_Api_Access_Tokens_Constraint {
  /** unique or primary key constraint on columns "lens_profile_id", "address" */
  LensApiAccessTokensPkey = 'lens_api_access_tokens_pkey'
}

/** input type for inserting data into table "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_expiration_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Api_Access_Tokens_Max_Fields = {
  __typename?: 'lens_api_access_tokens_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  token_expiration_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Lens_Api_Access_Tokens_Min_Fields = {
  __typename?: 'lens_api_access_tokens_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  token_expiration_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_Mutation_Response = {
  __typename?: 'lens_api_access_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Api_Access_Tokens>;
};

/** on_conflict condition type for table "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_On_Conflict = {
  constraint: Lens_Api_Access_Tokens_Constraint;
  update_columns?: Array<Lens_Api_Access_Tokens_Update_Column>;
  where?: InputMaybe<Lens_Api_Access_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_api_access_tokens". */
export type Lens_Api_Access_Tokens_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  token_expiration_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_api_access_tokens */
export type Lens_Api_Access_Tokens_Pk_Columns_Input = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};

/** select columns of table "lens_api_access_tokens" */
export enum Lens_Api_Access_Tokens_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  TokenExpirationTime = 'token_expiration_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_expiration_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lens_api_access_tokens" */
export type Lens_Api_Access_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Api_Access_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Api_Access_Tokens_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_expiration_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "lens_api_access_tokens" */
export enum Lens_Api_Access_Tokens_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  TokenExpirationTime = 'token_expiration_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Lens_Api_Access_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Api_Access_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Api_Access_Tokens_Bool_Exp;
};

/** columns and relationships of "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens = {
  __typename?: 'lens_api_refresh_tokens';
  address: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  lensProfiles: Array<Lens_Profiles>;
  /** An aggregate relationship */
  lensProfiles_aggregate: Lens_Profiles_Aggregate;
  lens_profile_id: Scalars['String'];
  token: Scalars['String'];
  token_expiration_time: Scalars['timestamptz'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_TokensLensProfilesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


/** columns and relationships of "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_TokensLensProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};

/** aggregated selection of "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Aggregate = {
  __typename?: 'lens_api_refresh_tokens_aggregate';
  aggregate?: Maybe<Lens_Api_Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Lens_Api_Refresh_Tokens>;
};

/** aggregate fields of "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Aggregate_Fields = {
  __typename?: 'lens_api_refresh_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Api_Refresh_Tokens_Max_Fields>;
  min?: Maybe<Lens_Api_Refresh_Tokens_Min_Fields>;
};


/** aggregate fields of "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lens_api_refresh_tokens". All fields are combined with a logical 'AND'. */
export type Lens_Api_Refresh_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  lensProfiles?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lensProfiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Bool_Exp>;
  lens_profile_id?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  token_expiration_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_api_refresh_tokens" */
export enum Lens_Api_Refresh_Tokens_Constraint {
  /** unique or primary key constraint on columns "lens_profile_id", "address" */
  LensApiRefreshTokensPkey = 'lens_api_refresh_tokens_pkey'
}

/** input type for inserting data into table "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lensProfiles?: InputMaybe<Lens_Profiles_Arr_Rel_Insert_Input>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_expiration_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Api_Refresh_Tokens_Max_Fields = {
  __typename?: 'lens_api_refresh_tokens_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  token_expiration_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Lens_Api_Refresh_Tokens_Min_Fields = {
  __typename?: 'lens_api_refresh_tokens_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  token_expiration_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Mutation_Response = {
  __typename?: 'lens_api_refresh_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Api_Refresh_Tokens>;
};

/** input type for inserting object relation for remote table "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Obj_Rel_Insert_Input = {
  data: Lens_Api_Refresh_Tokens_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Api_Refresh_Tokens_On_Conflict>;
};

/** on_conflict condition type for table "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_On_Conflict = {
  constraint: Lens_Api_Refresh_Tokens_Constraint;
  update_columns?: Array<Lens_Api_Refresh_Tokens_Update_Column>;
  where?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_api_refresh_tokens". */
export type Lens_Api_Refresh_Tokens_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  lensProfiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  token_expiration_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_api_refresh_tokens */
export type Lens_Api_Refresh_Tokens_Pk_Columns_Input = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};

/** select columns of table "lens_api_refresh_tokens" */
export enum Lens_Api_Refresh_Tokens_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  TokenExpirationTime = 'token_expiration_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_expiration_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lens_api_refresh_tokens" */
export type Lens_Api_Refresh_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Api_Refresh_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Api_Refresh_Tokens_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_expiration_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "lens_api_refresh_tokens" */
export enum Lens_Api_Refresh_Tokens_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  TokenExpirationTime = 'token_expiration_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Lens_Api_Refresh_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Api_Refresh_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Api_Refresh_Tokens_Bool_Exp;
};

/** columns and relationships of "lens_appid_mappings" */
export type Lens_Appid_Mappings = {
  __typename?: 'lens_appid_mappings';
  app_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  topic_id: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lens_appid_mappings" */
export type Lens_Appid_Mappings_Aggregate = {
  __typename?: 'lens_appid_mappings_aggregate';
  aggregate?: Maybe<Lens_Appid_Mappings_Aggregate_Fields>;
  nodes: Array<Lens_Appid_Mappings>;
};

export type Lens_Appid_Mappings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lens_Appid_Mappings_Aggregate_Bool_Exp_Count>;
};

export type Lens_Appid_Mappings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lens_appid_mappings" */
export type Lens_Appid_Mappings_Aggregate_Fields = {
  __typename?: 'lens_appid_mappings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Appid_Mappings_Max_Fields>;
  min?: Maybe<Lens_Appid_Mappings_Min_Fields>;
};


/** aggregate fields of "lens_appid_mappings" */
export type Lens_Appid_Mappings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lens_Appid_Mappings_Max_Order_By>;
  min?: InputMaybe<Lens_Appid_Mappings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Arr_Rel_Insert_Input = {
  data: Array<Lens_Appid_Mappings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Appid_Mappings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lens_appid_mappings". All fields are combined with a logical 'AND'. */
export type Lens_Appid_Mappings_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Appid_Mappings_Bool_Exp>>;
  _not?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Appid_Mappings_Bool_Exp>>;
  app_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  topic_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_appid_mappings" */
export enum Lens_Appid_Mappings_Constraint {
  /** unique or primary key constraint on columns "app_id" */
  LensAppidMappingsPkey = 'lens_appid_mappings_pkey'
}

/** input type for inserting data into table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Insert_Input = {
  app_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Appid_Mappings_Max_Fields = {
  __typename?: 'lens_appid_mappings_max_fields';
  app_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  topic_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Max_Order_By = {
  app_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lens_Appid_Mappings_Min_Fields = {
  __typename?: 'lens_appid_mappings_min_fields';
  app_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  topic_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Min_Order_By = {
  app_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Mutation_Response = {
  __typename?: 'lens_appid_mappings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Appid_Mappings>;
};

/** on_conflict condition type for table "lens_appid_mappings" */
export type Lens_Appid_Mappings_On_Conflict = {
  constraint: Lens_Appid_Mappings_Constraint;
  update_columns?: Array<Lens_Appid_Mappings_Update_Column>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_appid_mappings". */
export type Lens_Appid_Mappings_Order_By = {
  app_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_appid_mappings */
export type Lens_Appid_Mappings_Pk_Columns_Input = {
  app_id: Scalars['String'];
};

/** select columns of table "lens_appid_mappings" */
export enum Lens_Appid_Mappings_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Set_Input = {
  app_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lens_appid_mappings" */
export type Lens_Appid_Mappings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Appid_Mappings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Appid_Mappings_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "lens_appid_mappings" */
export enum Lens_Appid_Mappings_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Lens_Appid_Mappings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Appid_Mappings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Appid_Mappings_Bool_Exp;
};

/** columns and relationships of "lens_collects" */
export type Lens_Collects = {
  __typename?: 'lens_collects';
  collectNftId?: Maybe<Scalars['String']>;
  /** An object relationship */
  collector?: Maybe<Lens_Profiles>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  lensPost?: Maybe<Lens_Posts>;
  /** An object relationship */
  lensProfile: Lens_Profiles;
  lensProfileId: Scalars['String'];
  lensPubId?: Maybe<Scalars['String']>;
  /** An object relationship */
  lens_tx_status_enum: Lens_Tx_Status_Enum;
  ownerAddress: Scalars['String'];
  status: Lens_Tx_Status_Enum_Enum;
  transferBlockNumber?: Maybe<Scalars['Int']>;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lens_collects" */
export type Lens_Collects_Aggregate = {
  __typename?: 'lens_collects_aggregate';
  aggregate?: Maybe<Lens_Collects_Aggregate_Fields>;
  nodes: Array<Lens_Collects>;
};

export type Lens_Collects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lens_Collects_Aggregate_Bool_Exp_Count>;
};

export type Lens_Collects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Collects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lens_collects" */
export type Lens_Collects_Aggregate_Fields = {
  __typename?: 'lens_collects_aggregate_fields';
  avg?: Maybe<Lens_Collects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lens_Collects_Max_Fields>;
  min?: Maybe<Lens_Collects_Min_Fields>;
  stddev?: Maybe<Lens_Collects_Stddev_Fields>;
  stddev_pop?: Maybe<Lens_Collects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lens_Collects_Stddev_Samp_Fields>;
  sum?: Maybe<Lens_Collects_Sum_Fields>;
  var_pop?: Maybe<Lens_Collects_Var_Pop_Fields>;
  var_samp?: Maybe<Lens_Collects_Var_Samp_Fields>;
  variance?: Maybe<Lens_Collects_Variance_Fields>;
};


/** aggregate fields of "lens_collects" */
export type Lens_Collects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lens_collects" */
export type Lens_Collects_Aggregate_Order_By = {
  avg?: InputMaybe<Lens_Collects_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lens_Collects_Max_Order_By>;
  min?: InputMaybe<Lens_Collects_Min_Order_By>;
  stddev?: InputMaybe<Lens_Collects_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lens_Collects_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lens_Collects_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lens_Collects_Sum_Order_By>;
  var_pop?: InputMaybe<Lens_Collects_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lens_Collects_Var_Samp_Order_By>;
  variance?: InputMaybe<Lens_Collects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lens_collects" */
export type Lens_Collects_Arr_Rel_Insert_Input = {
  data: Array<Lens_Collects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Collects_On_Conflict>;
};

/** aggregate avg on columns */
export type Lens_Collects_Avg_Fields = {
  __typename?: 'lens_collects_avg_fields';
  transferBlockNumber?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lens_collects" */
export type Lens_Collects_Avg_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lens_collects". All fields are combined with a logical 'AND'. */
export type Lens_Collects_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Collects_Bool_Exp>>;
  _not?: InputMaybe<Lens_Collects_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Collects_Bool_Exp>>;
  collectNftId?: InputMaybe<String_Comparison_Exp>;
  collector?: InputMaybe<Lens_Profiles_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorCode?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lensPost?: InputMaybe<Lens_Posts_Bool_Exp>;
  lensProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lensProfileId?: InputMaybe<String_Comparison_Exp>;
  lensPubId?: InputMaybe<String_Comparison_Exp>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
  ownerAddress?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum_Comparison_Exp>;
  transferBlockNumber?: InputMaybe<Int_Comparison_Exp>;
  txId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_collects" */
export enum Lens_Collects_Constraint {
  /** unique or primary key constraint on columns "lens_profile_id", "lens_pub_id", "collect_nft_id" */
  LensCollectsLensProfileIdLensPubIdCollectNftIdUnique = 'lens_collects_lens_profile_id_lens_pub_id_collect_nft_id_unique',
  /** unique or primary key constraint on columns "id" */
  LensCollectsPkey = 'lens_collects_pkey'
}

/** input type for incrementing numeric columns in table "lens_collects" */
export type Lens_Collects_Inc_Input = {
  transferBlockNumber?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lens_collects" */
export type Lens_Collects_Insert_Input = {
  collectNftId?: InputMaybe<Scalars['String']>;
  collector?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lensPost?: InputMaybe<Lens_Posts_Obj_Rel_Insert_Input>;
  lensProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensPubId?: InputMaybe<Scalars['String']>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Obj_Rel_Insert_Input>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transferBlockNumber?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Collects_Max_Fields = {
  __typename?: 'lens_collects_max_fields';
  collectNftId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  lensPubId?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  transferBlockNumber?: Maybe<Scalars['Int']>;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lens_collects" */
export type Lens_Collects_Max_Order_By = {
  collectNftId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensPubId?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  transferBlockNumber?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lens_Collects_Min_Fields = {
  __typename?: 'lens_collects_min_fields';
  collectNftId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  lensPubId?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  transferBlockNumber?: Maybe<Scalars['Int']>;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lens_collects" */
export type Lens_Collects_Min_Order_By = {
  collectNftId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensPubId?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  transferBlockNumber?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lens_collects" */
export type Lens_Collects_Mutation_Response = {
  __typename?: 'lens_collects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Collects>;
};

/** on_conflict condition type for table "lens_collects" */
export type Lens_Collects_On_Conflict = {
  constraint: Lens_Collects_Constraint;
  update_columns?: Array<Lens_Collects_Update_Column>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_collects". */
export type Lens_Collects_Order_By = {
  collectNftId?: InputMaybe<Order_By>;
  collector?: InputMaybe<Lens_Profiles_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensPost?: InputMaybe<Lens_Posts_Order_By>;
  lensProfile?: InputMaybe<Lens_Profiles_Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensPubId?: InputMaybe<Order_By>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transferBlockNumber?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_collects */
export type Lens_Collects_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "lens_collects" */
export enum Lens_Collects_Select_Column {
  /** column name */
  CollectNftId = 'collectNftId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  Id = 'id',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  LensPubId = 'lensPubId',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  Status = 'status',
  /** column name */
  TransferBlockNumber = 'transferBlockNumber',
  /** column name */
  TxId = 'txId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "lens_collects" */
export type Lens_Collects_Set_Input = {
  collectNftId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensPubId?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transferBlockNumber?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lens_Collects_Stddev_Fields = {
  __typename?: 'lens_collects_stddev_fields';
  transferBlockNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lens_collects" */
export type Lens_Collects_Stddev_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lens_Collects_Stddev_Pop_Fields = {
  __typename?: 'lens_collects_stddev_pop_fields';
  transferBlockNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lens_collects" */
export type Lens_Collects_Stddev_Pop_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lens_Collects_Stddev_Samp_Fields = {
  __typename?: 'lens_collects_stddev_samp_fields';
  transferBlockNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lens_collects" */
export type Lens_Collects_Stddev_Samp_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lens_collects" */
export type Lens_Collects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Collects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Collects_Stream_Cursor_Value_Input = {
  collectNftId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensPubId?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transferBlockNumber?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Lens_Collects_Sum_Fields = {
  __typename?: 'lens_collects_sum_fields';
  transferBlockNumber?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lens_collects" */
export type Lens_Collects_Sum_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** update columns of table "lens_collects" */
export enum Lens_Collects_Update_Column {
  /** column name */
  CollectNftId = 'collectNftId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  Id = 'id',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  LensPubId = 'lensPubId',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  Status = 'status',
  /** column name */
  TransferBlockNumber = 'transferBlockNumber',
  /** column name */
  TxId = 'txId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Lens_Collects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lens_Collects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Collects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Collects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lens_Collects_Var_Pop_Fields = {
  __typename?: 'lens_collects_var_pop_fields';
  transferBlockNumber?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lens_collects" */
export type Lens_Collects_Var_Pop_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lens_Collects_Var_Samp_Fields = {
  __typename?: 'lens_collects_var_samp_fields';
  transferBlockNumber?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lens_collects" */
export type Lens_Collects_Var_Samp_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lens_Collects_Variance_Fields = {
  __typename?: 'lens_collects_variance_fields';
  transferBlockNumber?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lens_collects" */
export type Lens_Collects_Variance_Order_By = {
  transferBlockNumber?: InputMaybe<Order_By>;
};

/** columns and relationships of "lens_custodial_accounts" */
export type Lens_Custodial_Accounts = {
  __typename?: 'lens_custodial_accounts';
  address: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_private_key: Scalars['String'];
  /** An object relationship */
  lensProfile?: Maybe<Lens_Profiles>;
  /** An array relationship */
  lens_profiles: Array<Lens_Profiles>;
  /** An aggregate relationship */
  lens_profiles_aggregate: Lens_Profiles_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "lens_custodial_accounts" */
export type Lens_Custodial_AccountsLens_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


/** columns and relationships of "lens_custodial_accounts" */
export type Lens_Custodial_AccountsLens_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};

/** aggregated selection of "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Aggregate = {
  __typename?: 'lens_custodial_accounts_aggregate';
  aggregate?: Maybe<Lens_Custodial_Accounts_Aggregate_Fields>;
  nodes: Array<Lens_Custodial_Accounts>;
};

/** aggregate fields of "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Aggregate_Fields = {
  __typename?: 'lens_custodial_accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Custodial_Accounts_Max_Fields>;
  min?: Maybe<Lens_Custodial_Accounts_Min_Fields>;
};


/** aggregate fields of "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Custodial_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lens_custodial_accounts". All fields are combined with a logical 'AND'. */
export type Lens_Custodial_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Custodial_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Custodial_Accounts_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  encrypted_private_key?: InputMaybe<String_Comparison_Exp>;
  lensProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_profiles?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_profiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_custodial_accounts" */
export enum Lens_Custodial_Accounts_Constraint {
  /** unique or primary key constraint on columns "address" */
  LensCustodialAccountsPkey = 'lens_custodial_accounts_pkey'
}

/** input type for inserting data into table "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_private_key?: InputMaybe<Scalars['String']>;
  lensProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lens_profiles?: InputMaybe<Lens_Profiles_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Custodial_Accounts_Max_Fields = {
  __typename?: 'lens_custodial_accounts_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_private_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Lens_Custodial_Accounts_Min_Fields = {
  __typename?: 'lens_custodial_accounts_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_private_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Mutation_Response = {
  __typename?: 'lens_custodial_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Custodial_Accounts>;
};

/** input type for inserting object relation for remote table "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Obj_Rel_Insert_Input = {
  data: Lens_Custodial_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Custodial_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_On_Conflict = {
  constraint: Lens_Custodial_Accounts_Constraint;
  update_columns?: Array<Lens_Custodial_Accounts_Update_Column>;
  where?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_custodial_accounts". */
export type Lens_Custodial_Accounts_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  encrypted_private_key?: InputMaybe<Order_By>;
  lensProfile?: InputMaybe<Lens_Profiles_Order_By>;
  lens_profiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_custodial_accounts */
export type Lens_Custodial_Accounts_Pk_Columns_Input = {
  address: Scalars['String'];
};

/** select columns of table "lens_custodial_accounts" */
export enum Lens_Custodial_Accounts_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EncryptedPrivateKey = 'encrypted_private_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_private_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lens_custodial_accounts" */
export type Lens_Custodial_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Custodial_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Custodial_Accounts_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_private_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "lens_custodial_accounts" */
export enum Lens_Custodial_Accounts_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EncryptedPrivateKey = 'encrypted_private_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Lens_Custodial_Accounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Custodial_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Custodial_Accounts_Bool_Exp;
};

/** columns and relationships of "lens_events" */
export type Lens_Events = {
  __typename?: 'lens_events';
  address?: Maybe<Scalars['String']>;
  args?: Maybe<Scalars['json']>;
  block_hash?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  event_signature?: Maybe<Scalars['String']>;
  log_index: Scalars['Int'];
  raw_event?: Maybe<Scalars['json']>;
  removed?: Maybe<Scalars['Boolean']>;
  topics?: Maybe<Scalars['json']>;
  transaction_hash: Scalars['String'];
  transaction_index?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "lens_events" */
export type Lens_EventsArgsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "lens_events" */
export type Lens_EventsRaw_EventArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "lens_events" */
export type Lens_EventsTopicsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "lens_events" */
export type Lens_Events_Aggregate = {
  __typename?: 'lens_events_aggregate';
  aggregate?: Maybe<Lens_Events_Aggregate_Fields>;
  nodes: Array<Lens_Events>;
};

/** aggregate fields of "lens_events" */
export type Lens_Events_Aggregate_Fields = {
  __typename?: 'lens_events_aggregate_fields';
  avg?: Maybe<Lens_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lens_Events_Max_Fields>;
  min?: Maybe<Lens_Events_Min_Fields>;
  stddev?: Maybe<Lens_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Lens_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lens_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Lens_Events_Sum_Fields>;
  var_pop?: Maybe<Lens_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Lens_Events_Var_Samp_Fields>;
  variance?: Maybe<Lens_Events_Variance_Fields>;
};


/** aggregate fields of "lens_events" */
export type Lens_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lens_Events_Avg_Fields = {
  __typename?: 'lens_events_avg_fields';
  block_number?: Maybe<Scalars['Float']>;
  log_index?: Maybe<Scalars['Float']>;
  transaction_index?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lens_events". All fields are combined with a logical 'AND'. */
export type Lens_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Events_Bool_Exp>>;
  _not?: InputMaybe<Lens_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Events_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  args?: InputMaybe<Json_Comparison_Exp>;
  block_hash?: InputMaybe<String_Comparison_Exp>;
  block_number?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<String_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  event_signature?: InputMaybe<String_Comparison_Exp>;
  log_index?: InputMaybe<Int_Comparison_Exp>;
  raw_event?: InputMaybe<Json_Comparison_Exp>;
  removed?: InputMaybe<Boolean_Comparison_Exp>;
  topics?: InputMaybe<Json_Comparison_Exp>;
  transaction_hash?: InputMaybe<String_Comparison_Exp>;
  transaction_index?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_events" */
export enum Lens_Events_Constraint {
  /** unique or primary key constraint on columns "transaction_hash", "log_index" */
  LensEventsPkey = 'lens_events_pkey'
}

/** input type for incrementing numeric columns in table "lens_events" */
export type Lens_Events_Inc_Input = {
  block_number?: InputMaybe<Scalars['Int']>;
  log_index?: InputMaybe<Scalars['Int']>;
  transaction_index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lens_events" */
export type Lens_Events_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  args?: InputMaybe<Scalars['json']>;
  block_hash?: InputMaybe<Scalars['String']>;
  block_number?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  event_signature?: InputMaybe<Scalars['String']>;
  log_index?: InputMaybe<Scalars['Int']>;
  raw_event?: InputMaybe<Scalars['json']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  topics?: InputMaybe<Scalars['json']>;
  transaction_hash?: InputMaybe<Scalars['String']>;
  transaction_index?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lens_Events_Max_Fields = {
  __typename?: 'lens_events_max_fields';
  address?: Maybe<Scalars['String']>;
  block_hash?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  event_signature?: Maybe<Scalars['String']>;
  log_index?: Maybe<Scalars['Int']>;
  transaction_hash?: Maybe<Scalars['String']>;
  transaction_index?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Lens_Events_Min_Fields = {
  __typename?: 'lens_events_min_fields';
  address?: Maybe<Scalars['String']>;
  block_hash?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  event_signature?: Maybe<Scalars['String']>;
  log_index?: Maybe<Scalars['Int']>;
  transaction_hash?: Maybe<Scalars['String']>;
  transaction_index?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "lens_events" */
export type Lens_Events_Mutation_Response = {
  __typename?: 'lens_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Events>;
};

/** on_conflict condition type for table "lens_events" */
export type Lens_Events_On_Conflict = {
  constraint: Lens_Events_Constraint;
  update_columns?: Array<Lens_Events_Update_Column>;
  where?: InputMaybe<Lens_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_events". */
export type Lens_Events_Order_By = {
  address?: InputMaybe<Order_By>;
  args?: InputMaybe<Order_By>;
  block_hash?: InputMaybe<Order_By>;
  block_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  event_signature?: InputMaybe<Order_By>;
  log_index?: InputMaybe<Order_By>;
  raw_event?: InputMaybe<Order_By>;
  removed?: InputMaybe<Order_By>;
  topics?: InputMaybe<Order_By>;
  transaction_hash?: InputMaybe<Order_By>;
  transaction_index?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_events */
export type Lens_Events_Pk_Columns_Input = {
  log_index: Scalars['Int'];
  transaction_hash: Scalars['String'];
};

/** select columns of table "lens_events" */
export enum Lens_Events_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Args = 'args',
  /** column name */
  BlockHash = 'block_hash',
  /** column name */
  BlockNumber = 'block_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Event = 'event',
  /** column name */
  EventSignature = 'event_signature',
  /** column name */
  LogIndex = 'log_index',
  /** column name */
  RawEvent = 'raw_event',
  /** column name */
  Removed = 'removed',
  /** column name */
  Topics = 'topics',
  /** column name */
  TransactionHash = 'transaction_hash',
  /** column name */
  TransactionIndex = 'transaction_index'
}

/** input type for updating data in table "lens_events" */
export type Lens_Events_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  args?: InputMaybe<Scalars['json']>;
  block_hash?: InputMaybe<Scalars['String']>;
  block_number?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  event_signature?: InputMaybe<Scalars['String']>;
  log_index?: InputMaybe<Scalars['Int']>;
  raw_event?: InputMaybe<Scalars['json']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  topics?: InputMaybe<Scalars['json']>;
  transaction_hash?: InputMaybe<Scalars['String']>;
  transaction_index?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lens_Events_Stddev_Fields = {
  __typename?: 'lens_events_stddev_fields';
  block_number?: Maybe<Scalars['Float']>;
  log_index?: Maybe<Scalars['Float']>;
  transaction_index?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lens_Events_Stddev_Pop_Fields = {
  __typename?: 'lens_events_stddev_pop_fields';
  block_number?: Maybe<Scalars['Float']>;
  log_index?: Maybe<Scalars['Float']>;
  transaction_index?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lens_Events_Stddev_Samp_Fields = {
  __typename?: 'lens_events_stddev_samp_fields';
  block_number?: Maybe<Scalars['Float']>;
  log_index?: Maybe<Scalars['Float']>;
  transaction_index?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lens_events" */
export type Lens_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Events_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  args?: InputMaybe<Scalars['json']>;
  block_hash?: InputMaybe<Scalars['String']>;
  block_number?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  event_signature?: InputMaybe<Scalars['String']>;
  log_index?: InputMaybe<Scalars['Int']>;
  raw_event?: InputMaybe<Scalars['json']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  topics?: InputMaybe<Scalars['json']>;
  transaction_hash?: InputMaybe<Scalars['String']>;
  transaction_index?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lens_Events_Sum_Fields = {
  __typename?: 'lens_events_sum_fields';
  block_number?: Maybe<Scalars['Int']>;
  log_index?: Maybe<Scalars['Int']>;
  transaction_index?: Maybe<Scalars['Int']>;
};

/** update columns of table "lens_events" */
export enum Lens_Events_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Args = 'args',
  /** column name */
  BlockHash = 'block_hash',
  /** column name */
  BlockNumber = 'block_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Event = 'event',
  /** column name */
  EventSignature = 'event_signature',
  /** column name */
  LogIndex = 'log_index',
  /** column name */
  RawEvent = 'raw_event',
  /** column name */
  Removed = 'removed',
  /** column name */
  Topics = 'topics',
  /** column name */
  TransactionHash = 'transaction_hash',
  /** column name */
  TransactionIndex = 'transaction_index'
}

export type Lens_Events_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lens_Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lens_Events_Var_Pop_Fields = {
  __typename?: 'lens_events_var_pop_fields';
  block_number?: Maybe<Scalars['Float']>;
  log_index?: Maybe<Scalars['Float']>;
  transaction_index?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lens_Events_Var_Samp_Fields = {
  __typename?: 'lens_events_var_samp_fields';
  block_number?: Maybe<Scalars['Float']>;
  log_index?: Maybe<Scalars['Float']>;
  transaction_index?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lens_Events_Variance_Fields = {
  __typename?: 'lens_events_variance_fields';
  block_number?: Maybe<Scalars['Float']>;
  log_index?: Maybe<Scalars['Float']>;
  transaction_index?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lens_followers" */
export type Lens_Followers = {
  __typename?: 'lens_followers';
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  follow_nft_id?: Maybe<Scalars['String']>;
  followedLensProfileId: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  lensFollowedProfile: Lens_Profiles;
  /** An object relationship */
  lensProfile: Lens_Profiles;
  lensProfileId?: Maybe<Scalars['String']>;
  /** An array relationship */
  lensProfiles: Array<Lens_Profiles>;
  /** An aggregate relationship */
  lensProfiles_aggregate: Lens_Profiles_Aggregate;
  /** An object relationship */
  lens_tx_status_enum: Lens_Tx_Status_Enum;
  owner_address?: Maybe<Scalars['String']>;
  status: Lens_Tx_Status_Enum_Enum;
  transfer_block_number: Scalars['Int'];
  txId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "lens_followers" */
export type Lens_FollowersLensProfilesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


/** columns and relationships of "lens_followers" */
export type Lens_FollowersLensProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};

/** aggregated selection of "lens_followers" */
export type Lens_Followers_Aggregate = {
  __typename?: 'lens_followers_aggregate';
  aggregate?: Maybe<Lens_Followers_Aggregate_Fields>;
  nodes: Array<Lens_Followers>;
};

export type Lens_Followers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lens_Followers_Aggregate_Bool_Exp_Count>;
};

export type Lens_Followers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Followers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lens_followers" */
export type Lens_Followers_Aggregate_Fields = {
  __typename?: 'lens_followers_aggregate_fields';
  avg?: Maybe<Lens_Followers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lens_Followers_Max_Fields>;
  min?: Maybe<Lens_Followers_Min_Fields>;
  stddev?: Maybe<Lens_Followers_Stddev_Fields>;
  stddev_pop?: Maybe<Lens_Followers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lens_Followers_Stddev_Samp_Fields>;
  sum?: Maybe<Lens_Followers_Sum_Fields>;
  var_pop?: Maybe<Lens_Followers_Var_Pop_Fields>;
  var_samp?: Maybe<Lens_Followers_Var_Samp_Fields>;
  variance?: Maybe<Lens_Followers_Variance_Fields>;
};


/** aggregate fields of "lens_followers" */
export type Lens_Followers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lens_followers" */
export type Lens_Followers_Aggregate_Order_By = {
  avg?: InputMaybe<Lens_Followers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lens_Followers_Max_Order_By>;
  min?: InputMaybe<Lens_Followers_Min_Order_By>;
  stddev?: InputMaybe<Lens_Followers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lens_Followers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lens_Followers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lens_Followers_Sum_Order_By>;
  var_pop?: InputMaybe<Lens_Followers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lens_Followers_Var_Samp_Order_By>;
  variance?: InputMaybe<Lens_Followers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lens_followers" */
export type Lens_Followers_Arr_Rel_Insert_Input = {
  data: Array<Lens_Followers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Followers_On_Conflict>;
};

/** aggregate avg on columns */
export type Lens_Followers_Avg_Fields = {
  __typename?: 'lens_followers_avg_fields';
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lens_followers" */
export type Lens_Followers_Avg_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lens_followers". All fields are combined with a logical 'AND'. */
export type Lens_Followers_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Followers_Bool_Exp>>;
  _not?: InputMaybe<Lens_Followers_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Followers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorCode?: InputMaybe<String_Comparison_Exp>;
  follow_nft_id?: InputMaybe<String_Comparison_Exp>;
  followedLensProfileId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lensFollowedProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lensProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lensProfileId?: InputMaybe<String_Comparison_Exp>;
  lensProfiles?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lensProfiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Bool_Exp>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum_Comparison_Exp>;
  transfer_block_number?: InputMaybe<Int_Comparison_Exp>;
  txId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_followers" */
export enum Lens_Followers_Constraint {
  /** unique or primary key constraint on columns "id" */
  LensFollowersPkey = 'lens_followers_pkey',
  /** unique or primary key constraint on columns "followed_lens_profile_id", "follow_nft_id" */
  LensFollowersUniqueIndex = 'lens_followers_unique_index'
}

/** input type for incrementing numeric columns in table "lens_followers" */
export type Lens_Followers_Inc_Input = {
  transfer_block_number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lens_followers" */
export type Lens_Followers_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  follow_nft_id?: InputMaybe<Scalars['String']>;
  followedLensProfileId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lensFollowedProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lensProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensProfiles?: InputMaybe<Lens_Profiles_Arr_Rel_Insert_Input>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Obj_Rel_Insert_Input>;
  owner_address?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transfer_block_number?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lens_Followers_Max_Fields = {
  __typename?: 'lens_followers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  follow_nft_id?: Maybe<Scalars['String']>;
  followedLensProfileId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  transfer_block_number?: Maybe<Scalars['Int']>;
  txId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lens_followers" */
export type Lens_Followers_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  follow_nft_id?: InputMaybe<Order_By>;
  followedLensProfileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lens_Followers_Min_Fields = {
  __typename?: 'lens_followers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  follow_nft_id?: Maybe<Scalars['String']>;
  followedLensProfileId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  transfer_block_number?: Maybe<Scalars['Int']>;
  txId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lens_followers" */
export type Lens_Followers_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  follow_nft_id?: InputMaybe<Order_By>;
  followedLensProfileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lens_followers" */
export type Lens_Followers_Mutation_Response = {
  __typename?: 'lens_followers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Followers>;
};

/** on_conflict condition type for table "lens_followers" */
export type Lens_Followers_On_Conflict = {
  constraint: Lens_Followers_Constraint;
  update_columns?: Array<Lens_Followers_Update_Column>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_followers". */
export type Lens_Followers_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  follow_nft_id?: InputMaybe<Order_By>;
  followedLensProfileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensFollowedProfile?: InputMaybe<Lens_Profiles_Order_By>;
  lensProfile?: InputMaybe<Lens_Profiles_Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensProfiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Order_By>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Order_By>;
  owner_address?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_followers */
export type Lens_Followers_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "lens_followers" */
export enum Lens_Followers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  FollowNftId = 'follow_nft_id',
  /** column name */
  FollowedLensProfileId = 'followedLensProfileId',
  /** column name */
  Id = 'id',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  Status = 'status',
  /** column name */
  TransferBlockNumber = 'transfer_block_number',
  /** column name */
  TxId = 'txId'
}

/** input type for updating data in table "lens_followers" */
export type Lens_Followers_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  follow_nft_id?: InputMaybe<Scalars['String']>;
  followedLensProfileId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transfer_block_number?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lens_Followers_Stddev_Fields = {
  __typename?: 'lens_followers_stddev_fields';
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lens_followers" */
export type Lens_Followers_Stddev_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lens_Followers_Stddev_Pop_Fields = {
  __typename?: 'lens_followers_stddev_pop_fields';
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lens_followers" */
export type Lens_Followers_Stddev_Pop_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lens_Followers_Stddev_Samp_Fields = {
  __typename?: 'lens_followers_stddev_samp_fields';
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lens_followers" */
export type Lens_Followers_Stddev_Samp_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lens_followers" */
export type Lens_Followers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Followers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Followers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  follow_nft_id?: InputMaybe<Scalars['String']>;
  followedLensProfileId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transfer_block_number?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lens_Followers_Sum_Fields = {
  __typename?: 'lens_followers_sum_fields';
  transfer_block_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lens_followers" */
export type Lens_Followers_Sum_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** update columns of table "lens_followers" */
export enum Lens_Followers_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  FollowNftId = 'follow_nft_id',
  /** column name */
  FollowedLensProfileId = 'followedLensProfileId',
  /** column name */
  Id = 'id',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  Status = 'status',
  /** column name */
  TransferBlockNumber = 'transfer_block_number',
  /** column name */
  TxId = 'txId'
}

export type Lens_Followers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lens_Followers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Followers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Followers_Bool_Exp;
};

/** columns and relationships of "lens_followers_v2" */
export type Lens_Followers_V2 = {
  __typename?: 'lens_followers_v2';
  created_at?: Maybe<Scalars['timestamptz']>;
  error_code?: Maybe<Scalars['String']>;
  follow_nft_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  followedLensProfile: Lens_Profiles;
  followed_lens_profile_id: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  lensProfile: Lens_Profiles;
  lens_profile_id: Scalars['String'];
  origin_phaver: Scalars['Boolean'];
  status: Lens_Tx_Status_Enum_Enum;
  tx_id?: Maybe<Scalars['String']>;
  unfollow_tx_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lens_followers_v2" */
export type Lens_Followers_V2_Aggregate = {
  __typename?: 'lens_followers_v2_aggregate';
  aggregate?: Maybe<Lens_Followers_V2_Aggregate_Fields>;
  nodes: Array<Lens_Followers_V2>;
};

export type Lens_Followers_V2_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lens_Followers_V2_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lens_Followers_V2_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lens_Followers_V2_Aggregate_Bool_Exp_Count>;
};

export type Lens_Followers_V2_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lens_Followers_V2_Select_Column_Lens_Followers_V2_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lens_Followers_V2_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lens_Followers_V2_Select_Column_Lens_Followers_V2_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lens_Followers_V2_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lens_followers_v2" */
export type Lens_Followers_V2_Aggregate_Fields = {
  __typename?: 'lens_followers_v2_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Followers_V2_Max_Fields>;
  min?: Maybe<Lens_Followers_V2_Min_Fields>;
};


/** aggregate fields of "lens_followers_v2" */
export type Lens_Followers_V2_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lens_followers_v2" */
export type Lens_Followers_V2_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lens_Followers_V2_Max_Order_By>;
  min?: InputMaybe<Lens_Followers_V2_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lens_followers_v2" */
export type Lens_Followers_V2_Arr_Rel_Insert_Input = {
  data: Array<Lens_Followers_V2_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Followers_V2_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lens_followers_v2". All fields are combined with a logical 'AND'. */
export type Lens_Followers_V2_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Followers_V2_Bool_Exp>>;
  _not?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Followers_V2_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error_code?: InputMaybe<String_Comparison_Exp>;
  follow_nft_id?: InputMaybe<String_Comparison_Exp>;
  followedLensProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  followed_lens_profile_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lensProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_profile_id?: InputMaybe<String_Comparison_Exp>;
  origin_phaver?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum_Comparison_Exp>;
  tx_id?: InputMaybe<String_Comparison_Exp>;
  unfollow_tx_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_followers_v2" */
export enum Lens_Followers_V2_Constraint {
  /** unique or primary key constraint on columns "id" */
  LensFollowersV2Pkey = 'lens_followers_v2_pkey'
}

/** input type for inserting data into table "lens_followers_v2" */
export type Lens_Followers_V2_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error_code?: InputMaybe<Scalars['String']>;
  follow_nft_id?: InputMaybe<Scalars['String']>;
  followedLensProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  followed_lens_profile_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lensProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  origin_phaver?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  tx_id?: InputMaybe<Scalars['String']>;
  unfollow_tx_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Followers_V2_Max_Fields = {
  __typename?: 'lens_followers_v2_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error_code?: Maybe<Scalars['String']>;
  follow_nft_id?: Maybe<Scalars['String']>;
  followed_lens_profile_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  tx_id?: Maybe<Scalars['String']>;
  unfollow_tx_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lens_followers_v2" */
export type Lens_Followers_V2_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  follow_nft_id?: InputMaybe<Order_By>;
  followed_lens_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  tx_id?: InputMaybe<Order_By>;
  unfollow_tx_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lens_Followers_V2_Min_Fields = {
  __typename?: 'lens_followers_v2_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error_code?: Maybe<Scalars['String']>;
  follow_nft_id?: Maybe<Scalars['String']>;
  followed_lens_profile_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  tx_id?: Maybe<Scalars['String']>;
  unfollow_tx_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lens_followers_v2" */
export type Lens_Followers_V2_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  follow_nft_id?: InputMaybe<Order_By>;
  followed_lens_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  tx_id?: InputMaybe<Order_By>;
  unfollow_tx_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lens_followers_v2" */
export type Lens_Followers_V2_Mutation_Response = {
  __typename?: 'lens_followers_v2_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Followers_V2>;
};

/** on_conflict condition type for table "lens_followers_v2" */
export type Lens_Followers_V2_On_Conflict = {
  constraint: Lens_Followers_V2_Constraint;
  update_columns?: Array<Lens_Followers_V2_Update_Column>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_followers_v2". */
export type Lens_Followers_V2_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  follow_nft_id?: InputMaybe<Order_By>;
  followedLensProfile?: InputMaybe<Lens_Profiles_Order_By>;
  followed_lens_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensProfile?: InputMaybe<Lens_Profiles_Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  origin_phaver?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tx_id?: InputMaybe<Order_By>;
  unfollow_tx_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_followers_v2 */
export type Lens_Followers_V2_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "lens_followers_v2" */
export enum Lens_Followers_V2_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  FollowNftId = 'follow_nft_id',
  /** column name */
  FollowedLensProfileId = 'followed_lens_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  OriginPhaver = 'origin_phaver',
  /** column name */
  Status = 'status',
  /** column name */
  TxId = 'tx_id',
  /** column name */
  UnfollowTxId = 'unfollow_tx_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "lens_followers_v2_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lens_followers_v2" */
export enum Lens_Followers_V2_Select_Column_Lens_Followers_V2_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  OriginPhaver = 'origin_phaver'
}

/** select "lens_followers_v2_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lens_followers_v2" */
export enum Lens_Followers_V2_Select_Column_Lens_Followers_V2_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  OriginPhaver = 'origin_phaver'
}

/** input type for updating data in table "lens_followers_v2" */
export type Lens_Followers_V2_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error_code?: InputMaybe<Scalars['String']>;
  follow_nft_id?: InputMaybe<Scalars['String']>;
  followed_lens_profile_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  origin_phaver?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  tx_id?: InputMaybe<Scalars['String']>;
  unfollow_tx_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lens_followers_v2" */
export type Lens_Followers_V2_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Followers_V2_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Followers_V2_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error_code?: InputMaybe<Scalars['String']>;
  follow_nft_id?: InputMaybe<Scalars['String']>;
  followed_lens_profile_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  origin_phaver?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  tx_id?: InputMaybe<Scalars['String']>;
  unfollow_tx_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "lens_followers_v2" */
export enum Lens_Followers_V2_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  FollowNftId = 'follow_nft_id',
  /** column name */
  FollowedLensProfileId = 'followed_lens_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  OriginPhaver = 'origin_phaver',
  /** column name */
  Status = 'status',
  /** column name */
  TxId = 'tx_id',
  /** column name */
  UnfollowTxId = 'unfollow_tx_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Lens_Followers_V2_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Followers_V2_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Followers_V2_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lens_Followers_Var_Pop_Fields = {
  __typename?: 'lens_followers_var_pop_fields';
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lens_followers" */
export type Lens_Followers_Var_Pop_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lens_Followers_Var_Samp_Fields = {
  __typename?: 'lens_followers_var_samp_fields';
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lens_followers" */
export type Lens_Followers_Var_Samp_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lens_Followers_Variance_Fields = {
  __typename?: 'lens_followers_variance_fields';
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lens_followers" */
export type Lens_Followers_Variance_Order_By = {
  transfer_block_number?: InputMaybe<Order_By>;
};

/** columns and relationships of "lens_indexer" */
export type Lens_Indexer = {
  __typename?: 'lens_indexer';
  created_at?: Maybe<Scalars['timestamptz']>;
  key: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  value: Scalars['Int'];
};

/** aggregated selection of "lens_indexer" */
export type Lens_Indexer_Aggregate = {
  __typename?: 'lens_indexer_aggregate';
  aggregate?: Maybe<Lens_Indexer_Aggregate_Fields>;
  nodes: Array<Lens_Indexer>;
};

/** aggregate fields of "lens_indexer" */
export type Lens_Indexer_Aggregate_Fields = {
  __typename?: 'lens_indexer_aggregate_fields';
  avg?: Maybe<Lens_Indexer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lens_Indexer_Max_Fields>;
  min?: Maybe<Lens_Indexer_Min_Fields>;
  stddev?: Maybe<Lens_Indexer_Stddev_Fields>;
  stddev_pop?: Maybe<Lens_Indexer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lens_Indexer_Stddev_Samp_Fields>;
  sum?: Maybe<Lens_Indexer_Sum_Fields>;
  var_pop?: Maybe<Lens_Indexer_Var_Pop_Fields>;
  var_samp?: Maybe<Lens_Indexer_Var_Samp_Fields>;
  variance?: Maybe<Lens_Indexer_Variance_Fields>;
};


/** aggregate fields of "lens_indexer" */
export type Lens_Indexer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Indexer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lens_Indexer_Avg_Fields = {
  __typename?: 'lens_indexer_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lens_indexer". All fields are combined with a logical 'AND'. */
export type Lens_Indexer_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Indexer_Bool_Exp>>;
  _not?: InputMaybe<Lens_Indexer_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Indexer_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_indexer" */
export enum Lens_Indexer_Constraint {
  /** unique or primary key constraint on columns "key" */
  LensIndexerPkey = 'lens_indexer_pkey'
}

/** input type for incrementing numeric columns in table "lens_indexer" */
export type Lens_Indexer_Inc_Input = {
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lens_indexer" */
export type Lens_Indexer_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lens_Indexer_Max_Fields = {
  __typename?: 'lens_indexer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Lens_Indexer_Min_Fields = {
  __typename?: 'lens_indexer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "lens_indexer" */
export type Lens_Indexer_Mutation_Response = {
  __typename?: 'lens_indexer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Indexer>;
};

/** on_conflict condition type for table "lens_indexer" */
export type Lens_Indexer_On_Conflict = {
  constraint: Lens_Indexer_Constraint;
  update_columns?: Array<Lens_Indexer_Update_Column>;
  where?: InputMaybe<Lens_Indexer_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_indexer". */
export type Lens_Indexer_Order_By = {
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_indexer */
export type Lens_Indexer_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "lens_indexer" */
export enum Lens_Indexer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "lens_indexer" */
export type Lens_Indexer_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lens_Indexer_Stddev_Fields = {
  __typename?: 'lens_indexer_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lens_Indexer_Stddev_Pop_Fields = {
  __typename?: 'lens_indexer_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lens_Indexer_Stddev_Samp_Fields = {
  __typename?: 'lens_indexer_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lens_indexer" */
export type Lens_Indexer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Indexer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Indexer_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lens_Indexer_Sum_Fields = {
  __typename?: 'lens_indexer_sum_fields';
  value?: Maybe<Scalars['Int']>;
};

/** update columns of table "lens_indexer" */
export enum Lens_Indexer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Lens_Indexer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lens_Indexer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Indexer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Indexer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lens_Indexer_Var_Pop_Fields = {
  __typename?: 'lens_indexer_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lens_Indexer_Var_Samp_Fields = {
  __typename?: 'lens_indexer_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lens_Indexer_Variance_Fields = {
  __typename?: 'lens_indexer_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lens_invites" */
export type Lens_Invites = {
  __typename?: 'lens_invites';
  createdAt?: Maybe<Scalars['timestamptz']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['Int']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  status: Scalars['String'];
  tx_hash?: Maybe<Scalars['String']>;
  /** An object relationship */
  wallet: Wallets;
  walletAddress: Scalars['String'];
};

/** aggregated selection of "lens_invites" */
export type Lens_Invites_Aggregate = {
  __typename?: 'lens_invites_aggregate';
  aggregate?: Maybe<Lens_Invites_Aggregate_Fields>;
  nodes: Array<Lens_Invites>;
};

/** aggregate fields of "lens_invites" */
export type Lens_Invites_Aggregate_Fields = {
  __typename?: 'lens_invites_aggregate_fields';
  avg?: Maybe<Lens_Invites_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lens_Invites_Max_Fields>;
  min?: Maybe<Lens_Invites_Min_Fields>;
  stddev?: Maybe<Lens_Invites_Stddev_Fields>;
  stddev_pop?: Maybe<Lens_Invites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lens_Invites_Stddev_Samp_Fields>;
  sum?: Maybe<Lens_Invites_Sum_Fields>;
  var_pop?: Maybe<Lens_Invites_Var_Pop_Fields>;
  var_samp?: Maybe<Lens_Invites_Var_Samp_Fields>;
  variance?: Maybe<Lens_Invites_Variance_Fields>;
};


/** aggregate fields of "lens_invites" */
export type Lens_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lens_Invites_Avg_Fields = {
  __typename?: 'lens_invites_avg_fields';
  nonce?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lens_invites". All fields are combined with a logical 'AND'. */
export type Lens_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Invites_Bool_Exp>>;
  _not?: InputMaybe<Lens_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Invites_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  lens_profile_id?: InputMaybe<String_Comparison_Exp>;
  nonce?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tx_hash?: InputMaybe<String_Comparison_Exp>;
  wallet?: InputMaybe<Wallets_Bool_Exp>;
  walletAddress?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_invites" */
export enum Lens_Invites_Constraint {
  /** unique or primary key constraint on columns "profile_id" */
  LensInvitesPkey = 'lens_invites_pkey'
}

/** input type for incrementing numeric columns in table "lens_invites" */
export type Lens_Invites_Inc_Input = {
  nonce?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lens_invites" */
export type Lens_Invites_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  nonce?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tx_hash?: InputMaybe<Scalars['String']>;
  wallet?: InputMaybe<Wallets_Obj_Rel_Insert_Input>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lens_Invites_Max_Fields = {
  __typename?: 'lens_invites_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tx_hash?: Maybe<Scalars['String']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lens_Invites_Min_Fields = {
  __typename?: 'lens_invites_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tx_hash?: Maybe<Scalars['String']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lens_invites" */
export type Lens_Invites_Mutation_Response = {
  __typename?: 'lens_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Invites>;
};

/** on_conflict condition type for table "lens_invites" */
export type Lens_Invites_On_Conflict = {
  constraint: Lens_Invites_Constraint;
  update_columns?: Array<Lens_Invites_Update_Column>;
  where?: InputMaybe<Lens_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_invites". */
export type Lens_Invites_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  nonce?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tx_hash?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Wallets_Order_By>;
  walletAddress?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_invites */
export type Lens_Invites_Pk_Columns_Input = {
  profileId: Scalars['String'];
};

/** select columns of table "lens_invites" */
export enum Lens_Invites_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  Nonce = 'nonce',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Status = 'status',
  /** column name */
  TxHash = 'tx_hash',
  /** column name */
  WalletAddress = 'walletAddress'
}

/** input type for updating data in table "lens_invites" */
export type Lens_Invites_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  nonce?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tx_hash?: InputMaybe<Scalars['String']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lens_Invites_Stddev_Fields = {
  __typename?: 'lens_invites_stddev_fields';
  nonce?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lens_Invites_Stddev_Pop_Fields = {
  __typename?: 'lens_invites_stddev_pop_fields';
  nonce?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lens_Invites_Stddev_Samp_Fields = {
  __typename?: 'lens_invites_stddev_samp_fields';
  nonce?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lens_invites" */
export type Lens_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Invites_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  nonce?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tx_hash?: InputMaybe<Scalars['String']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lens_Invites_Sum_Fields = {
  __typename?: 'lens_invites_sum_fields';
  nonce?: Maybe<Scalars['Int']>;
};

/** update columns of table "lens_invites" */
export enum Lens_Invites_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  Nonce = 'nonce',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Status = 'status',
  /** column name */
  TxHash = 'tx_hash',
  /** column name */
  WalletAddress = 'walletAddress'
}

export type Lens_Invites_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lens_Invites_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Invites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Invites_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lens_Invites_Var_Pop_Fields = {
  __typename?: 'lens_invites_var_pop_fields';
  nonce?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lens_Invites_Var_Samp_Fields = {
  __typename?: 'lens_invites_var_samp_fields';
  nonce?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lens_Invites_Variance_Fields = {
  __typename?: 'lens_invites_variance_fields';
  nonce?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types = {
  __typename?: 'lens_post_lens_pub_types';
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_TypesLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_TypesLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};

/** aggregated selection of "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Aggregate = {
  __typename?: 'lens_post_lens_pub_types_aggregate';
  aggregate?: Maybe<Lens_Post_Lens_Pub_Types_Aggregate_Fields>;
  nodes: Array<Lens_Post_Lens_Pub_Types>;
};

/** aggregate fields of "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Aggregate_Fields = {
  __typename?: 'lens_post_lens_pub_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Post_Lens_Pub_Types_Max_Fields>;
  min?: Maybe<Lens_Post_Lens_Pub_Types_Min_Fields>;
};


/** aggregate fields of "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lens_post_lens_pub_types". All fields are combined with a logical 'AND'. */
export type Lens_Post_Lens_Pub_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Bool_Exp>>;
  _not?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Bool_Exp>>;
  lens_posts?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_post_lens_pub_types" */
export enum Lens_Post_Lens_Pub_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  LensPostLensPubTypesPkey = 'lens_post_lens_pub_types_pkey'
}

export enum Lens_Post_Lens_Pub_Types_Enum {
  Comment = 'Comment',
  Mirror = 'Mirror',
  Post = 'Post',
  Quote = 'Quote'
}

/** Boolean expression to compare columns of type "lens_post_lens_pub_types_enum". All fields are combined with logical 'AND'. */
export type Lens_Post_Lens_Pub_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Lens_Post_Lens_Pub_Types_Enum>;
  _in?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Lens_Post_Lens_Pub_Types_Enum>;
  _nin?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Enum>>;
};

/** input type for inserting data into table "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Insert_Input = {
  lens_posts?: InputMaybe<Lens_Posts_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lens_Post_Lens_Pub_Types_Max_Fields = {
  __typename?: 'lens_post_lens_pub_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lens_Post_Lens_Pub_Types_Min_Fields = {
  __typename?: 'lens_post_lens_pub_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Mutation_Response = {
  __typename?: 'lens_post_lens_pub_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Post_Lens_Pub_Types>;
};

/** input type for inserting object relation for remote table "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Obj_Rel_Insert_Input = {
  data: Lens_Post_Lens_Pub_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Post_Lens_Pub_Types_On_Conflict>;
};

/** on_conflict condition type for table "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_On_Conflict = {
  constraint: Lens_Post_Lens_Pub_Types_Constraint;
  update_columns?: Array<Lens_Post_Lens_Pub_Types_Update_Column>;
  where?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_post_lens_pub_types". */
export type Lens_Post_Lens_Pub_Types_Order_By = {
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_post_lens_pub_types */
export type Lens_Post_Lens_Pub_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "lens_post_lens_pub_types" */
export enum Lens_Post_Lens_Pub_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lens_post_lens_pub_types" */
export type Lens_Post_Lens_Pub_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Post_Lens_Pub_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Post_Lens_Pub_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lens_post_lens_pub_types" */
export enum Lens_Post_Lens_Pub_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Lens_Post_Lens_Pub_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Post_Lens_Pub_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Post_Lens_Pub_Types_Bool_Exp;
};

/** columns and relationships of "lens_posts" */
export type Lens_Posts = {
  __typename?: 'lens_posts';
  app_id?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['bigint']>;
  collectModule?: Maybe<Scalars['String']>;
  collectModuleReturnData?: Maybe<Scalars['String']>;
  /** An object relationship */
  comment?: Maybe<Recommendation_Comments>;
  commentId?: Maybe<Scalars['String']>;
  contentUri?: Maybe<Scalars['String']>;
  content_metadata?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  da_id_full?: Maybe<Scalars['String']>;
  da_proofs?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  internalPublicationId?: Maybe<Scalars['String']>;
  isCollected?: Maybe<Scalars['Boolean']>;
  isMomoka: Scalars['Boolean'];
  /** An array relationship */
  lensCollects: Array<Lens_Collects>;
  /** An aggregate relationship */
  lensCollects_aggregate: Lens_Collects_Aggregate;
  lensDaId?: Maybe<Scalars['String']>;
  /** An object relationship */
  lensProfile: Lens_Profiles;
  lensProfileId: Scalars['String'];
  lensPubId?: Maybe<Scalars['String']>;
  lensPubIdPointed?: Maybe<Scalars['String']>;
  lensPubType: Lens_Post_Lens_Pub_Types_Enum;
  lens_api_tx_id?: Maybe<Scalars['String']>;
  lens_da_id_pointed?: Maybe<Scalars['String']>;
  /** An object relationship */
  lens_post?: Maybe<Lens_Posts>;
  /** An object relationship */
  lens_post_lens_pub_type: Lens_Post_Lens_Pub_Types;
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  /** An object relationship */
  lens_profile?: Maybe<Lens_Profiles>;
  lens_profile_id_pointed?: Maybe<Scalars['String']>;
  /** An array relationship */
  lens_reactions: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lens_reactions_aggregate: Lens_Reactions_Aggregate;
  /** An object relationship */
  lens_tx_status_enum: Lens_Tx_Status_Enum;
  /** An object relationship */
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  referenceModule?: Maybe<Scalars['String']>;
  referenceModuleReturnData?: Maybe<Scalars['String']>;
  status: Lens_Tx_Status_Enum_Enum;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "lens_posts" */
export type Lens_PostsContent_MetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "lens_posts" */
export type Lens_PostsLensCollectsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


/** columns and relationships of "lens_posts" */
export type Lens_PostsLensCollects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


/** columns and relationships of "lens_posts" */
export type Lens_PostsLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_posts" */
export type Lens_PostsLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_posts" */
export type Lens_PostsLens_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "lens_posts" */
export type Lens_PostsLens_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};

/** aggregated selection of "lens_posts" */
export type Lens_Posts_Aggregate = {
  __typename?: 'lens_posts_aggregate';
  aggregate?: Maybe<Lens_Posts_Aggregate_Fields>;
  nodes: Array<Lens_Posts>;
};

export type Lens_Posts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp_Count>;
};

export type Lens_Posts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lens_Posts_Select_Column_Lens_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Posts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lens_Posts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lens_Posts_Select_Column_Lens_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Posts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lens_Posts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Posts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lens_posts" */
export type Lens_Posts_Aggregate_Fields = {
  __typename?: 'lens_posts_aggregate_fields';
  avg?: Maybe<Lens_Posts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lens_Posts_Max_Fields>;
  min?: Maybe<Lens_Posts_Min_Fields>;
  stddev?: Maybe<Lens_Posts_Stddev_Fields>;
  stddev_pop?: Maybe<Lens_Posts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lens_Posts_Stddev_Samp_Fields>;
  sum?: Maybe<Lens_Posts_Sum_Fields>;
  var_pop?: Maybe<Lens_Posts_Var_Pop_Fields>;
  var_samp?: Maybe<Lens_Posts_Var_Samp_Fields>;
  variance?: Maybe<Lens_Posts_Variance_Fields>;
};


/** aggregate fields of "lens_posts" */
export type Lens_Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lens_posts" */
export type Lens_Posts_Aggregate_Order_By = {
  avg?: InputMaybe<Lens_Posts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lens_Posts_Max_Order_By>;
  min?: InputMaybe<Lens_Posts_Min_Order_By>;
  stddev?: InputMaybe<Lens_Posts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lens_Posts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lens_Posts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lens_Posts_Sum_Order_By>;
  var_pop?: InputMaybe<Lens_Posts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lens_Posts_Var_Samp_Order_By>;
  variance?: InputMaybe<Lens_Posts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lens_posts" */
export type Lens_Posts_Arr_Rel_Insert_Input = {
  data: Array<Lens_Posts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Posts_On_Conflict>;
};

/** aggregate avg on columns */
export type Lens_Posts_Avg_Fields = {
  __typename?: 'lens_posts_avg_fields';
  block_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lens_posts" */
export type Lens_Posts_Avg_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lens_posts". All fields are combined with a logical 'AND'. */
export type Lens_Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Posts_Bool_Exp>>;
  _not?: InputMaybe<Lens_Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Posts_Bool_Exp>>;
  app_id?: InputMaybe<String_Comparison_Exp>;
  block_number?: InputMaybe<Bigint_Comparison_Exp>;
  collectModule?: InputMaybe<String_Comparison_Exp>;
  collectModuleReturnData?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  commentId?: InputMaybe<String_Comparison_Exp>;
  contentUri?: InputMaybe<String_Comparison_Exp>;
  content_metadata?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  da_id_full?: InputMaybe<String_Comparison_Exp>;
  da_proofs?: InputMaybe<String_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorCode?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  internalPublicationId?: InputMaybe<String_Comparison_Exp>;
  isCollected?: InputMaybe<Boolean_Comparison_Exp>;
  isMomoka?: InputMaybe<Boolean_Comparison_Exp>;
  lensCollects?: InputMaybe<Lens_Collects_Bool_Exp>;
  lensCollects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Bool_Exp>;
  lensDaId?: InputMaybe<String_Comparison_Exp>;
  lensProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lensProfileId?: InputMaybe<String_Comparison_Exp>;
  lensPubId?: InputMaybe<String_Comparison_Exp>;
  lensPubIdPointed?: InputMaybe<String_Comparison_Exp>;
  lensPubType?: InputMaybe<Lens_Post_Lens_Pub_Types_Enum_Comparison_Exp>;
  lens_api_tx_id?: InputMaybe<String_Comparison_Exp>;
  lens_da_id_pointed?: InputMaybe<String_Comparison_Exp>;
  lens_post?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_post_lens_pub_type?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
  lens_posts?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp>;
  lens_profile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_profile_id_pointed?: InputMaybe<String_Comparison_Exp>;
  lens_reactions?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Bool_Exp>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  referenceModule?: InputMaybe<String_Comparison_Exp>;
  referenceModuleReturnData?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum_Comparison_Exp>;
  txId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_posts" */
export enum Lens_Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  LensPostsPkey = 'lens_posts_pkey',
  /** unique or primary key constraint on columns "lens_profile_id", "lens_pub_id" */
  LensPostsUniqueChainIndex = 'lens_posts_unique_chain_index',
  /** unique or primary key constraint on columns "lens_profile_id", "lens_da_id", "lens_pub_id" */
  LensPostsUniqueDaIndex = 'lens_posts_unique_da_index'
}

/** input type for incrementing numeric columns in table "lens_posts" */
export type Lens_Posts_Inc_Input = {
  block_number?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "lens_posts" */
export type Lens_Posts_Insert_Input = {
  app_id?: InputMaybe<Scalars['String']>;
  block_number?: InputMaybe<Scalars['bigint']>;
  collectModule?: InputMaybe<Scalars['String']>;
  collectModuleReturnData?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  commentId?: InputMaybe<Scalars['String']>;
  contentUri?: InputMaybe<Scalars['String']>;
  content_metadata?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  da_id_full?: InputMaybe<Scalars['String']>;
  da_proofs?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isMomoka?: InputMaybe<Scalars['Boolean']>;
  lensCollects?: InputMaybe<Lens_Collects_Arr_Rel_Insert_Input>;
  lensDaId?: InputMaybe<Scalars['String']>;
  lensProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensPubId?: InputMaybe<Scalars['String']>;
  lensPubIdPointed?: InputMaybe<Scalars['String']>;
  lensPubType?: InputMaybe<Lens_Post_Lens_Pub_Types_Enum>;
  lens_api_tx_id?: InputMaybe<Scalars['String']>;
  lens_da_id_pointed?: InputMaybe<Scalars['String']>;
  lens_post?: InputMaybe<Lens_Posts_Obj_Rel_Insert_Input>;
  lens_post_lens_pub_type?: InputMaybe<Lens_Post_Lens_Pub_Types_Obj_Rel_Insert_Input>;
  lens_posts?: InputMaybe<Lens_Posts_Arr_Rel_Insert_Input>;
  lens_profile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lens_profile_id_pointed?: InputMaybe<Scalars['String']>;
  lens_reactions?: InputMaybe<Lens_Reactions_Arr_Rel_Insert_Input>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Obj_Rel_Insert_Input>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  referenceModule?: InputMaybe<Scalars['String']>;
  referenceModuleReturnData?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Posts_Max_Fields = {
  __typename?: 'lens_posts_max_fields';
  app_id?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['bigint']>;
  collectModule?: Maybe<Scalars['String']>;
  collectModuleReturnData?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  contentUri?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  da_id_full?: Maybe<Scalars['String']>;
  da_proofs?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalPublicationId?: Maybe<Scalars['String']>;
  lensDaId?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  lensPubId?: Maybe<Scalars['String']>;
  lensPubIdPointed?: Maybe<Scalars['String']>;
  lens_api_tx_id?: Maybe<Scalars['String']>;
  lens_da_id_pointed?: Maybe<Scalars['String']>;
  lens_profile_id_pointed?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  referenceModule?: Maybe<Scalars['String']>;
  referenceModuleReturnData?: Maybe<Scalars['String']>;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lens_posts" */
export type Lens_Posts_Max_Order_By = {
  app_id?: InputMaybe<Order_By>;
  block_number?: InputMaybe<Order_By>;
  collectModule?: InputMaybe<Order_By>;
  collectModuleReturnData?: InputMaybe<Order_By>;
  commentId?: InputMaybe<Order_By>;
  contentUri?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  da_id_full?: InputMaybe<Order_By>;
  da_proofs?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalPublicationId?: InputMaybe<Order_By>;
  lensDaId?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensPubId?: InputMaybe<Order_By>;
  lensPubIdPointed?: InputMaybe<Order_By>;
  lens_api_tx_id?: InputMaybe<Order_By>;
  lens_da_id_pointed?: InputMaybe<Order_By>;
  lens_profile_id_pointed?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  referenceModule?: InputMaybe<Order_By>;
  referenceModuleReturnData?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lens_Posts_Min_Fields = {
  __typename?: 'lens_posts_min_fields';
  app_id?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['bigint']>;
  collectModule?: Maybe<Scalars['String']>;
  collectModuleReturnData?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  contentUri?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  da_id_full?: Maybe<Scalars['String']>;
  da_proofs?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  errorCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalPublicationId?: Maybe<Scalars['String']>;
  lensDaId?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  lensPubId?: Maybe<Scalars['String']>;
  lensPubIdPointed?: Maybe<Scalars['String']>;
  lens_api_tx_id?: Maybe<Scalars['String']>;
  lens_da_id_pointed?: Maybe<Scalars['String']>;
  lens_profile_id_pointed?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  referenceModule?: Maybe<Scalars['String']>;
  referenceModuleReturnData?: Maybe<Scalars['String']>;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lens_posts" */
export type Lens_Posts_Min_Order_By = {
  app_id?: InputMaybe<Order_By>;
  block_number?: InputMaybe<Order_By>;
  collectModule?: InputMaybe<Order_By>;
  collectModuleReturnData?: InputMaybe<Order_By>;
  commentId?: InputMaybe<Order_By>;
  contentUri?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  da_id_full?: InputMaybe<Order_By>;
  da_proofs?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalPublicationId?: InputMaybe<Order_By>;
  lensDaId?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensPubId?: InputMaybe<Order_By>;
  lensPubIdPointed?: InputMaybe<Order_By>;
  lens_api_tx_id?: InputMaybe<Order_By>;
  lens_da_id_pointed?: InputMaybe<Order_By>;
  lens_profile_id_pointed?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  referenceModule?: InputMaybe<Order_By>;
  referenceModuleReturnData?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lens_posts" */
export type Lens_Posts_Mutation_Response = {
  __typename?: 'lens_posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Posts>;
};

/** input type for inserting object relation for remote table "lens_posts" */
export type Lens_Posts_Obj_Rel_Insert_Input = {
  data: Lens_Posts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Posts_On_Conflict>;
};

/** on_conflict condition type for table "lens_posts" */
export type Lens_Posts_On_Conflict = {
  constraint: Lens_Posts_Constraint;
  update_columns?: Array<Lens_Posts_Update_Column>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_posts". */
export type Lens_Posts_Order_By = {
  app_id?: InputMaybe<Order_By>;
  block_number?: InputMaybe<Order_By>;
  collectModule?: InputMaybe<Order_By>;
  collectModuleReturnData?: InputMaybe<Order_By>;
  comment?: InputMaybe<Recommendation_Comments_Order_By>;
  commentId?: InputMaybe<Order_By>;
  contentUri?: InputMaybe<Order_By>;
  content_metadata?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  da_id_full?: InputMaybe<Order_By>;
  da_proofs?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalPublicationId?: InputMaybe<Order_By>;
  isCollected?: InputMaybe<Order_By>;
  isMomoka?: InputMaybe<Order_By>;
  lensCollects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Order_By>;
  lensDaId?: InputMaybe<Order_By>;
  lensProfile?: InputMaybe<Lens_Profiles_Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensPubId?: InputMaybe<Order_By>;
  lensPubIdPointed?: InputMaybe<Order_By>;
  lensPubType?: InputMaybe<Order_By>;
  lens_api_tx_id?: InputMaybe<Order_By>;
  lens_da_id_pointed?: InputMaybe<Order_By>;
  lens_post?: InputMaybe<Lens_Posts_Order_By>;
  lens_post_lens_pub_type?: InputMaybe<Lens_Post_Lens_Pub_Types_Order_By>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Order_By>;
  lens_profile?: InputMaybe<Lens_Profiles_Order_By>;
  lens_profile_id_pointed?: InputMaybe<Order_By>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Order_By>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  referenceModule?: InputMaybe<Order_By>;
  referenceModuleReturnData?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_posts */
export type Lens_Posts_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "lens_posts" */
export enum Lens_Posts_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  BlockNumber = 'block_number',
  /** column name */
  CollectModule = 'collectModule',
  /** column name */
  CollectModuleReturnData = 'collectModuleReturnData',
  /** column name */
  CommentId = 'commentId',
  /** column name */
  ContentUri = 'contentUri',
  /** column name */
  ContentMetadata = 'content_metadata',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DaIdFull = 'da_id_full',
  /** column name */
  DaProofs = 'da_proofs',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  Id = 'id',
  /** column name */
  InternalPublicationId = 'internalPublicationId',
  /** column name */
  IsMomoka = 'isMomoka',
  /** column name */
  LensDaId = 'lensDaId',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  LensPubId = 'lensPubId',
  /** column name */
  LensPubIdPointed = 'lensPubIdPointed',
  /** column name */
  LensPubType = 'lensPubType',
  /** column name */
  LensApiTxId = 'lens_api_tx_id',
  /** column name */
  LensDaIdPointed = 'lens_da_id_pointed',
  /** column name */
  LensProfileIdPointed = 'lens_profile_id_pointed',
  /** column name */
  PostId = 'postId',
  /** column name */
  ReferenceModule = 'referenceModule',
  /** column name */
  ReferenceModuleReturnData = 'referenceModuleReturnData',
  /** column name */
  Status = 'status',
  /** column name */
  TxId = 'txId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "lens_posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lens_posts" */
export enum Lens_Posts_Select_Column_Lens_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsMomoka = 'isMomoka'
}

/** select "lens_posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lens_posts" */
export enum Lens_Posts_Select_Column_Lens_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsMomoka = 'isMomoka'
}

/** input type for updating data in table "lens_posts" */
export type Lens_Posts_Set_Input = {
  app_id?: InputMaybe<Scalars['String']>;
  block_number?: InputMaybe<Scalars['bigint']>;
  collectModule?: InputMaybe<Scalars['String']>;
  collectModuleReturnData?: InputMaybe<Scalars['String']>;
  commentId?: InputMaybe<Scalars['String']>;
  contentUri?: InputMaybe<Scalars['String']>;
  content_metadata?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  da_id_full?: InputMaybe<Scalars['String']>;
  da_proofs?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isMomoka?: InputMaybe<Scalars['Boolean']>;
  lensDaId?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensPubId?: InputMaybe<Scalars['String']>;
  lensPubIdPointed?: InputMaybe<Scalars['String']>;
  lensPubType?: InputMaybe<Lens_Post_Lens_Pub_Types_Enum>;
  lens_api_tx_id?: InputMaybe<Scalars['String']>;
  lens_da_id_pointed?: InputMaybe<Scalars['String']>;
  lens_profile_id_pointed?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  referenceModule?: InputMaybe<Scalars['String']>;
  referenceModuleReturnData?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lens_Posts_Stddev_Fields = {
  __typename?: 'lens_posts_stddev_fields';
  block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lens_posts" */
export type Lens_Posts_Stddev_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lens_Posts_Stddev_Pop_Fields = {
  __typename?: 'lens_posts_stddev_pop_fields';
  block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lens_posts" */
export type Lens_Posts_Stddev_Pop_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lens_Posts_Stddev_Samp_Fields = {
  __typename?: 'lens_posts_stddev_samp_fields';
  block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lens_posts" */
export type Lens_Posts_Stddev_Samp_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lens_posts" */
export type Lens_Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Posts_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['String']>;
  block_number?: InputMaybe<Scalars['bigint']>;
  collectModule?: InputMaybe<Scalars['String']>;
  collectModuleReturnData?: InputMaybe<Scalars['String']>;
  commentId?: InputMaybe<Scalars['String']>;
  contentUri?: InputMaybe<Scalars['String']>;
  content_metadata?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  da_id_full?: InputMaybe<Scalars['String']>;
  da_proofs?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  errorCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  internalPublicationId?: InputMaybe<Scalars['String']>;
  isMomoka?: InputMaybe<Scalars['Boolean']>;
  lensDaId?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensPubId?: InputMaybe<Scalars['String']>;
  lensPubIdPointed?: InputMaybe<Scalars['String']>;
  lensPubType?: InputMaybe<Lens_Post_Lens_Pub_Types_Enum>;
  lens_api_tx_id?: InputMaybe<Scalars['String']>;
  lens_da_id_pointed?: InputMaybe<Scalars['String']>;
  lens_profile_id_pointed?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  referenceModule?: InputMaybe<Scalars['String']>;
  referenceModuleReturnData?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Lens_Posts_Sum_Fields = {
  __typename?: 'lens_posts_sum_fields';
  block_number?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "lens_posts" */
export type Lens_Posts_Sum_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** update columns of table "lens_posts" */
export enum Lens_Posts_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  BlockNumber = 'block_number',
  /** column name */
  CollectModule = 'collectModule',
  /** column name */
  CollectModuleReturnData = 'collectModuleReturnData',
  /** column name */
  CommentId = 'commentId',
  /** column name */
  ContentUri = 'contentUri',
  /** column name */
  ContentMetadata = 'content_metadata',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DaIdFull = 'da_id_full',
  /** column name */
  DaProofs = 'da_proofs',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  Id = 'id',
  /** column name */
  IsMomoka = 'isMomoka',
  /** column name */
  LensDaId = 'lensDaId',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  LensPubId = 'lensPubId',
  /** column name */
  LensPubIdPointed = 'lensPubIdPointed',
  /** column name */
  LensPubType = 'lensPubType',
  /** column name */
  LensApiTxId = 'lens_api_tx_id',
  /** column name */
  LensDaIdPointed = 'lens_da_id_pointed',
  /** column name */
  LensProfileIdPointed = 'lens_profile_id_pointed',
  /** column name */
  PostId = 'postId',
  /** column name */
  ReferenceModule = 'referenceModule',
  /** column name */
  ReferenceModuleReturnData = 'referenceModuleReturnData',
  /** column name */
  Status = 'status',
  /** column name */
  TxId = 'txId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Lens_Posts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lens_Posts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Posts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Posts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lens_Posts_Var_Pop_Fields = {
  __typename?: 'lens_posts_var_pop_fields';
  block_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lens_posts" */
export type Lens_Posts_Var_Pop_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lens_Posts_Var_Samp_Fields = {
  __typename?: 'lens_posts_var_samp_fields';
  block_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lens_posts" */
export type Lens_Posts_Var_Samp_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lens_Posts_Variance_Fields = {
  __typename?: 'lens_posts_variance_fields';
  block_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lens_posts" */
export type Lens_Posts_Variance_Order_By = {
  block_number?: InputMaybe<Order_By>;
};

/** columns and relationships of "lens_profiles" */
export type Lens_Profiles = {
  __typename?: 'lens_profiles';
  createdAt?: Maybe<Scalars['timestamptz']>;
  custodial_account_address?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dispatcherAddress?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  followModule?: Maybe<Scalars['String']>;
  follow_module_block_number: Scalars['Int'];
  handle_link_block_number: Scalars['Int'];
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  image_block_number: Scalars['Int'];
  /** A computed field, executes function "is_lens_follow_pending" */
  isFollowPending?: Maybe<Scalars['Boolean']>;
  isUserFollowing?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lensApiRefreshTokens?: Maybe<Lens_Api_Refresh_Tokens>;
  /** An object relationship */
  lensCustodialAccount?: Maybe<Lens_Custodial_Accounts>;
  /** An array relationship */
  lensFollowers: Array<Lens_Followers_V2>;
  /** An aggregate relationship */
  lensFollowers_aggregate: Lens_Followers_V2_Aggregate;
  /** An array relationship */
  lensFollows: Array<Lens_Followers_V2>;
  /** An aggregate relationship */
  lensFollows_aggregate: Lens_Followers_V2_Aggregate;
  lensHandle: Scalars['String'];
  /** An array relationship */
  lensPosts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lensPosts_aggregate: Lens_Posts_Aggregate;
  lensProfileId: Scalars['String'];
  /** An array relationship */
  lensReactionsByLensProfileIdPointed: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lensReactionsByLensProfileIdPointed_aggregate: Lens_Reactions_Aggregate;
  /** An array relationship */
  lens_collects: Array<Lens_Collects>;
  /** An aggregate relationship */
  lens_collects_aggregate: Lens_Collects_Aggregate;
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  /** An array relationship */
  lens_reactions: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lens_reactions_aggregate: Lens_Reactions_Aggregate;
  /** An object relationship */
  lens_tx_status_enum: Lens_Tx_Status_Enum;
  /** An array relationship */
  ownedLensCollects: Array<Lens_Collects>;
  /** An aggregate relationship */
  ownedLensCollects_aggregate: Lens_Collects_Aggregate;
  ownerAddress?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile?: Maybe<Profiles>;
  profileId?: Maybe<Scalars['String']>;
  status: Lens_Tx_Status_Enum_Enum;
  transfer_block_number: Scalars['Int'];
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensFollowersArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensFollowers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensFollowsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensFollows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensPostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensReactionsByLensProfileIdPointedArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLensReactionsByLensProfileIdPointed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLens_CollectsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLens_Collects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLens_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesLens_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesOwnedLensCollectsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


/** columns and relationships of "lens_profiles" */
export type Lens_ProfilesOwnedLensCollects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};

/** aggregated selection of "lens_profiles" */
export type Lens_Profiles_Aggregate = {
  __typename?: 'lens_profiles_aggregate';
  aggregate?: Maybe<Lens_Profiles_Aggregate_Fields>;
  nodes: Array<Lens_Profiles>;
};

export type Lens_Profiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lens_Profiles_Aggregate_Bool_Exp_Count>;
};

export type Lens_Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lens_profiles" */
export type Lens_Profiles_Aggregate_Fields = {
  __typename?: 'lens_profiles_aggregate_fields';
  avg?: Maybe<Lens_Profiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lens_Profiles_Max_Fields>;
  min?: Maybe<Lens_Profiles_Min_Fields>;
  stddev?: Maybe<Lens_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Lens_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lens_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Lens_Profiles_Sum_Fields>;
  var_pop?: Maybe<Lens_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Lens_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Lens_Profiles_Variance_Fields>;
};


/** aggregate fields of "lens_profiles" */
export type Lens_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lens_profiles" */
export type Lens_Profiles_Aggregate_Order_By = {
  avg?: InputMaybe<Lens_Profiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lens_Profiles_Max_Order_By>;
  min?: InputMaybe<Lens_Profiles_Min_Order_By>;
  stddev?: InputMaybe<Lens_Profiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lens_Profiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lens_Profiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lens_Profiles_Sum_Order_By>;
  var_pop?: InputMaybe<Lens_Profiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lens_Profiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Lens_Profiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lens_profiles" */
export type Lens_Profiles_Arr_Rel_Insert_Input = {
  data: Array<Lens_Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Profiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Lens_Profiles_Avg_Fields = {
  __typename?: 'lens_profiles_avg_fields';
  follow_module_block_number?: Maybe<Scalars['Float']>;
  handle_link_block_number?: Maybe<Scalars['Float']>;
  image_block_number?: Maybe<Scalars['Float']>;
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lens_profiles" */
export type Lens_Profiles_Avg_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lens_profiles". All fields are combined with a logical 'AND'. */
export type Lens_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Lens_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Profiles_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  custodial_account_address?: InputMaybe<String_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dispatcherAddress?: InputMaybe<String_Comparison_Exp>;
  errorCode?: InputMaybe<String_Comparison_Exp>;
  followModule?: InputMaybe<String_Comparison_Exp>;
  follow_module_block_number?: InputMaybe<Int_Comparison_Exp>;
  handle_link_block_number?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  image_block_number?: InputMaybe<Int_Comparison_Exp>;
  isFollowPending?: InputMaybe<Boolean_Comparison_Exp>;
  isUserFollowing?: InputMaybe<Boolean_Comparison_Exp>;
  lensApiRefreshTokens?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
  lensCustodialAccount?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
  lensFollowers?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
  lensFollowers_aggregate?: InputMaybe<Lens_Followers_V2_Aggregate_Bool_Exp>;
  lensFollows?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
  lensFollows_aggregate?: InputMaybe<Lens_Followers_V2_Aggregate_Bool_Exp>;
  lensHandle?: InputMaybe<String_Comparison_Exp>;
  lensPosts?: InputMaybe<Lens_Posts_Bool_Exp>;
  lensPosts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp>;
  lensProfileId?: InputMaybe<String_Comparison_Exp>;
  lensReactionsByLensProfileIdPointed?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lensReactionsByLensProfileIdPointed_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Bool_Exp>;
  lens_collects?: InputMaybe<Lens_Collects_Bool_Exp>;
  lens_collects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Bool_Exp>;
  lens_posts?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp>;
  lens_reactions?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Bool_Exp>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
  ownedLensCollects?: InputMaybe<Lens_Collects_Bool_Exp>;
  ownedLensCollects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Bool_Exp>;
  ownerAddress?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum_Comparison_Exp>;
  transfer_block_number?: InputMaybe<Int_Comparison_Exp>;
  txId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_profiles" */
export enum Lens_Profiles_Constraint {
  /** unique or primary key constraint on columns "profile_id" */
  LensPhaverProfileIdUnique = 'lens_phaver_profile_id_unique',
  /** unique or primary key constraint on columns "lens_profile_id" */
  LensProfilesLensProfileIdUnique = 'lens_profiles_lens_profile_id_unique',
  /** unique or primary key constraint on columns "id" */
  LensProfilesPkey = 'lens_profiles_pkey'
}

/** input type for incrementing numeric columns in table "lens_profiles" */
export type Lens_Profiles_Inc_Input = {
  follow_module_block_number?: InputMaybe<Scalars['Int']>;
  handle_link_block_number?: InputMaybe<Scalars['Int']>;
  image_block_number?: InputMaybe<Scalars['Int']>;
  transfer_block_number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lens_profiles" */
export type Lens_Profiles_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  custodial_account_address?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  dispatcherAddress?: InputMaybe<Scalars['String']>;
  errorCode?: InputMaybe<Scalars['String']>;
  followModule?: InputMaybe<Scalars['String']>;
  follow_module_block_number?: InputMaybe<Scalars['Int']>;
  handle_link_block_number?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  image_block_number?: InputMaybe<Scalars['Int']>;
  lensApiRefreshTokens?: InputMaybe<Lens_Api_Refresh_Tokens_Obj_Rel_Insert_Input>;
  lensCustodialAccount?: InputMaybe<Lens_Custodial_Accounts_Obj_Rel_Insert_Input>;
  lensFollowers?: InputMaybe<Lens_Followers_V2_Arr_Rel_Insert_Input>;
  lensFollows?: InputMaybe<Lens_Followers_V2_Arr_Rel_Insert_Input>;
  lensHandle?: InputMaybe<Scalars['String']>;
  lensPosts?: InputMaybe<Lens_Posts_Arr_Rel_Insert_Input>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  lensReactionsByLensProfileIdPointed?: InputMaybe<Lens_Reactions_Arr_Rel_Insert_Input>;
  lens_collects?: InputMaybe<Lens_Collects_Arr_Rel_Insert_Input>;
  lens_posts?: InputMaybe<Lens_Posts_Arr_Rel_Insert_Input>;
  lens_reactions?: InputMaybe<Lens_Reactions_Arr_Rel_Insert_Input>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Obj_Rel_Insert_Input>;
  ownedLensCollects?: InputMaybe<Lens_Collects_Arr_Rel_Insert_Input>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transfer_block_number?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lens_Profiles_Max_Fields = {
  __typename?: 'lens_profiles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  custodial_account_address?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dispatcherAddress?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  followModule?: Maybe<Scalars['String']>;
  follow_module_block_number?: Maybe<Scalars['Int']>;
  handle_link_block_number?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  image_block_number?: Maybe<Scalars['Int']>;
  lensHandle?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  transfer_block_number?: Maybe<Scalars['Int']>;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lens_profiles" */
export type Lens_Profiles_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  custodial_account_address?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  dispatcherAddress?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  followModule?: InputMaybe<Order_By>;
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  lensHandle?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lens_Profiles_Min_Fields = {
  __typename?: 'lens_profiles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  custodial_account_address?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dispatcherAddress?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  followModule?: Maybe<Scalars['String']>;
  follow_module_block_number?: Maybe<Scalars['Int']>;
  handle_link_block_number?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  image_block_number?: Maybe<Scalars['Int']>;
  lensHandle?: Maybe<Scalars['String']>;
  lensProfileId?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  transfer_block_number?: Maybe<Scalars['Int']>;
  txId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lens_profiles" */
export type Lens_Profiles_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  custodial_account_address?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  dispatcherAddress?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  followModule?: InputMaybe<Order_By>;
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  lensHandle?: InputMaybe<Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lens_profiles" */
export type Lens_Profiles_Mutation_Response = {
  __typename?: 'lens_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Profiles>;
};

/** input type for inserting object relation for remote table "lens_profiles" */
export type Lens_Profiles_Obj_Rel_Insert_Input = {
  data: Lens_Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Profiles_On_Conflict>;
};

/** on_conflict condition type for table "lens_profiles" */
export type Lens_Profiles_On_Conflict = {
  constraint: Lens_Profiles_Constraint;
  update_columns?: Array<Lens_Profiles_Update_Column>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_profiles". */
export type Lens_Profiles_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  custodial_account_address?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  dispatcherAddress?: InputMaybe<Order_By>;
  errorCode?: InputMaybe<Order_By>;
  followModule?: InputMaybe<Order_By>;
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  isFollowPending?: InputMaybe<Order_By>;
  isUserFollowing?: InputMaybe<Order_By>;
  lensApiRefreshTokens?: InputMaybe<Lens_Api_Refresh_Tokens_Order_By>;
  lensCustodialAccount?: InputMaybe<Lens_Custodial_Accounts_Order_By>;
  lensFollowers_aggregate?: InputMaybe<Lens_Followers_V2_Aggregate_Order_By>;
  lensFollows_aggregate?: InputMaybe<Lens_Followers_V2_Aggregate_Order_By>;
  lensHandle?: InputMaybe<Order_By>;
  lensPosts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Order_By>;
  lensProfileId?: InputMaybe<Order_By>;
  lensReactionsByLensProfileIdPointed_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Order_By>;
  lens_collects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Order_By>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Order_By>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Order_By>;
  lens_tx_status_enum?: InputMaybe<Lens_Tx_Status_Enum_Order_By>;
  ownedLensCollects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_profiles */
export type Lens_Profiles_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "lens_profiles" */
export enum Lens_Profiles_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustodialAccountAddress = 'custodial_account_address',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DispatcherAddress = 'dispatcherAddress',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  FollowModule = 'followModule',
  /** column name */
  FollowModuleBlockNumber = 'follow_module_block_number',
  /** column name */
  HandleLinkBlockNumber = 'handle_link_block_number',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  ImageBlockNumber = 'image_block_number',
  /** column name */
  LensHandle = 'lensHandle',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Status = 'status',
  /** column name */
  TransferBlockNumber = 'transfer_block_number',
  /** column name */
  TxId = 'txId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "lens_profiles" */
export type Lens_Profiles_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  custodial_account_address?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  dispatcherAddress?: InputMaybe<Scalars['String']>;
  errorCode?: InputMaybe<Scalars['String']>;
  followModule?: InputMaybe<Scalars['String']>;
  follow_module_block_number?: InputMaybe<Scalars['Int']>;
  handle_link_block_number?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  image_block_number?: InputMaybe<Scalars['Int']>;
  lensHandle?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transfer_block_number?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lens_Profiles_Stddev_Fields = {
  __typename?: 'lens_profiles_stddev_fields';
  follow_module_block_number?: Maybe<Scalars['Float']>;
  handle_link_block_number?: Maybe<Scalars['Float']>;
  image_block_number?: Maybe<Scalars['Float']>;
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lens_profiles" */
export type Lens_Profiles_Stddev_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lens_Profiles_Stddev_Pop_Fields = {
  __typename?: 'lens_profiles_stddev_pop_fields';
  follow_module_block_number?: Maybe<Scalars['Float']>;
  handle_link_block_number?: Maybe<Scalars['Float']>;
  image_block_number?: Maybe<Scalars['Float']>;
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lens_profiles" */
export type Lens_Profiles_Stddev_Pop_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lens_Profiles_Stddev_Samp_Fields = {
  __typename?: 'lens_profiles_stddev_samp_fields';
  follow_module_block_number?: Maybe<Scalars['Float']>;
  handle_link_block_number?: Maybe<Scalars['Float']>;
  image_block_number?: Maybe<Scalars['Float']>;
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lens_profiles" */
export type Lens_Profiles_Stddev_Samp_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lens_profiles" */
export type Lens_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Profiles_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  custodial_account_address?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  dispatcherAddress?: InputMaybe<Scalars['String']>;
  errorCode?: InputMaybe<Scalars['String']>;
  followModule?: InputMaybe<Scalars['String']>;
  follow_module_block_number?: InputMaybe<Scalars['Int']>;
  handle_link_block_number?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  image_block_number?: InputMaybe<Scalars['Int']>;
  lensHandle?: InputMaybe<Scalars['String']>;
  lensProfileId?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  transfer_block_number?: InputMaybe<Scalars['Int']>;
  txId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Lens_Profiles_Sum_Fields = {
  __typename?: 'lens_profiles_sum_fields';
  follow_module_block_number?: Maybe<Scalars['Int']>;
  handle_link_block_number?: Maybe<Scalars['Int']>;
  image_block_number?: Maybe<Scalars['Int']>;
  transfer_block_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lens_profiles" */
export type Lens_Profiles_Sum_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** update columns of table "lens_profiles" */
export enum Lens_Profiles_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustodialAccountAddress = 'custodial_account_address',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DispatcherAddress = 'dispatcherAddress',
  /** column name */
  ErrorCode = 'errorCode',
  /** column name */
  FollowModule = 'followModule',
  /** column name */
  FollowModuleBlockNumber = 'follow_module_block_number',
  /** column name */
  HandleLinkBlockNumber = 'handle_link_block_number',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  ImageBlockNumber = 'image_block_number',
  /** column name */
  LensHandle = 'lensHandle',
  /** column name */
  LensProfileId = 'lensProfileId',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Status = 'status',
  /** column name */
  TransferBlockNumber = 'transfer_block_number',
  /** column name */
  TxId = 'txId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Lens_Profiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lens_Profiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lens_Profiles_Var_Pop_Fields = {
  __typename?: 'lens_profiles_var_pop_fields';
  follow_module_block_number?: Maybe<Scalars['Float']>;
  handle_link_block_number?: Maybe<Scalars['Float']>;
  image_block_number?: Maybe<Scalars['Float']>;
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lens_profiles" */
export type Lens_Profiles_Var_Pop_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lens_Profiles_Var_Samp_Fields = {
  __typename?: 'lens_profiles_var_samp_fields';
  follow_module_block_number?: Maybe<Scalars['Float']>;
  handle_link_block_number?: Maybe<Scalars['Float']>;
  image_block_number?: Maybe<Scalars['Float']>;
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lens_profiles" */
export type Lens_Profiles_Var_Samp_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lens_Profiles_Variance_Fields = {
  __typename?: 'lens_profiles_variance_fields';
  follow_module_block_number?: Maybe<Scalars['Float']>;
  handle_link_block_number?: Maybe<Scalars['Float']>;
  image_block_number?: Maybe<Scalars['Float']>;
  transfer_block_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lens_profiles" */
export type Lens_Profiles_Variance_Order_By = {
  follow_module_block_number?: InputMaybe<Order_By>;
  handle_link_block_number?: InputMaybe<Order_By>;
  image_block_number?: InputMaybe<Order_By>;
  transfer_block_number?: InputMaybe<Order_By>;
};

/** columns and relationships of "lens_reaction_types" */
export type Lens_Reaction_Types = {
  __typename?: 'lens_reaction_types';
  /** An array relationship */
  lens_reactions: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lens_reactions_aggregate: Lens_Reactions_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "lens_reaction_types" */
export type Lens_Reaction_TypesLens_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "lens_reaction_types" */
export type Lens_Reaction_TypesLens_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};

/** aggregated selection of "lens_reaction_types" */
export type Lens_Reaction_Types_Aggregate = {
  __typename?: 'lens_reaction_types_aggregate';
  aggregate?: Maybe<Lens_Reaction_Types_Aggregate_Fields>;
  nodes: Array<Lens_Reaction_Types>;
};

/** aggregate fields of "lens_reaction_types" */
export type Lens_Reaction_Types_Aggregate_Fields = {
  __typename?: 'lens_reaction_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Reaction_Types_Max_Fields>;
  min?: Maybe<Lens_Reaction_Types_Min_Fields>;
};


/** aggregate fields of "lens_reaction_types" */
export type Lens_Reaction_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Reaction_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lens_reaction_types". All fields are combined with a logical 'AND'. */
export type Lens_Reaction_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Reaction_Types_Bool_Exp>>;
  _not?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Reaction_Types_Bool_Exp>>;
  lens_reactions?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_reaction_types" */
export enum Lens_Reaction_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  LensReactionTypesPkey = 'lens_reaction_types_pkey'
}

/** input type for inserting data into table "lens_reaction_types" */
export type Lens_Reaction_Types_Insert_Input = {
  lens_reactions?: InputMaybe<Lens_Reactions_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lens_Reaction_Types_Max_Fields = {
  __typename?: 'lens_reaction_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lens_Reaction_Types_Min_Fields = {
  __typename?: 'lens_reaction_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lens_reaction_types" */
export type Lens_Reaction_Types_Mutation_Response = {
  __typename?: 'lens_reaction_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Reaction_Types>;
};

/** input type for inserting object relation for remote table "lens_reaction_types" */
export type Lens_Reaction_Types_Obj_Rel_Insert_Input = {
  data: Lens_Reaction_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Reaction_Types_On_Conflict>;
};

/** on_conflict condition type for table "lens_reaction_types" */
export type Lens_Reaction_Types_On_Conflict = {
  constraint: Lens_Reaction_Types_Constraint;
  update_columns?: Array<Lens_Reaction_Types_Update_Column>;
  where?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_reaction_types". */
export type Lens_Reaction_Types_Order_By = {
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_reaction_types */
export type Lens_Reaction_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "lens_reaction_types" */
export enum Lens_Reaction_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "lens_reaction_types" */
export type Lens_Reaction_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lens_reaction_types" */
export type Lens_Reaction_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Reaction_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Reaction_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lens_reaction_types" */
export enum Lens_Reaction_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Lens_Reaction_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Reaction_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Reaction_Types_Bool_Exp;
};

/** columns and relationships of "lens_reactions" */
export type Lens_Reactions = {
  __typename?: 'lens_reactions';
  comment_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  comment_vote?: Maybe<Comment_Votes>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  lensProfileByLensProfileIdPointed: Lens_Profiles;
  lens_da_id_pointed?: Maybe<Scalars['String']>;
  /** An object relationship */
  lens_post?: Maybe<Lens_Posts>;
  /** An object relationship */
  lens_profile: Lens_Profiles;
  lens_profile_id: Scalars['String'];
  lens_profile_id_pointed: Scalars['String'];
  lens_pub_id_pointed: Scalars['String'];
  /** An object relationship */
  lens_reaction_type: Lens_Reaction_Types;
  post_id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  /** An object relationship */
  recommendation?: Maybe<Recommendations>;
  /** An object relationship */
  recommendation_comment?: Maybe<Recommendation_Comments>;
  /** An array relationship */
  recommendation_votes: Array<Recommendation_Votes>;
  /** An aggregate relationship */
  recommendation_votes_aggregate: Recommendation_Votes_Aggregate;
};


/** columns and relationships of "lens_reactions" */
export type Lens_ReactionsRecommendation_VotesArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};


/** columns and relationships of "lens_reactions" */
export type Lens_ReactionsRecommendation_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};

/** aggregated selection of "lens_reactions" */
export type Lens_Reactions_Aggregate = {
  __typename?: 'lens_reactions_aggregate';
  aggregate?: Maybe<Lens_Reactions_Aggregate_Fields>;
  nodes: Array<Lens_Reactions>;
};

export type Lens_Reactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lens_Reactions_Aggregate_Bool_Exp_Count>;
};

export type Lens_Reactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lens_Reactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lens_reactions" */
export type Lens_Reactions_Aggregate_Fields = {
  __typename?: 'lens_reactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Reactions_Max_Fields>;
  min?: Maybe<Lens_Reactions_Min_Fields>;
};


/** aggregate fields of "lens_reactions" */
export type Lens_Reactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lens_reactions" */
export type Lens_Reactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lens_Reactions_Max_Order_By>;
  min?: InputMaybe<Lens_Reactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lens_reactions" */
export type Lens_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Lens_Reactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Reactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lens_reactions". All fields are combined with a logical 'AND'. */
export type Lens_Reactions_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Reactions_Bool_Exp>>;
  _not?: InputMaybe<Lens_Reactions_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Reactions_Bool_Exp>>;
  comment_id?: InputMaybe<String_Comparison_Exp>;
  comment_vote?: InputMaybe<Comment_Votes_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lensProfileByLensProfileIdPointed?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_da_id_pointed?: InputMaybe<String_Comparison_Exp>;
  lens_post?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_profile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_profile_id?: InputMaybe<String_Comparison_Exp>;
  lens_profile_id_pointed?: InputMaybe<String_Comparison_Exp>;
  lens_pub_id_pointed?: InputMaybe<String_Comparison_Exp>;
  lens_reaction_type?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
  post_id?: InputMaybe<String_Comparison_Exp>;
  reaction?: InputMaybe<String_Comparison_Exp>;
  recommendation?: InputMaybe<Recommendations_Bool_Exp>;
  recommendation_comment?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  recommendation_votes?: InputMaybe<Recommendation_Votes_Bool_Exp>;
  recommendation_votes_aggregate?: InputMaybe<Recommendation_Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "lens_reactions" */
export enum Lens_Reactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  LensReactionsPkey = 'lens_reactions_pkey',
  /** unique or primary key constraint on columns "lens_profile_id_pointed", "lens_profile_id", "lens_pub_id_pointed", "reaction" */
  ReactionUniqueChain = 'reaction_unique_chain',
  /** unique or primary key constraint on columns "lens_profile_id_pointed", "lens_profile_id", "lens_da_id_pointed", "lens_pub_id_pointed", "reaction" */
  ReactionUniqueDa = 'reaction_unique_da'
}

/** input type for inserting data into table "lens_reactions" */
export type Lens_Reactions_Insert_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  comment_vote?: InputMaybe<Comment_Votes_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lensProfileByLensProfileIdPointed?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lens_da_id_pointed?: InputMaybe<Scalars['String']>;
  lens_post?: InputMaybe<Lens_Posts_Obj_Rel_Insert_Input>;
  lens_profile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  lens_profile_id_pointed?: InputMaybe<Scalars['String']>;
  lens_pub_id_pointed?: InputMaybe<Scalars['String']>;
  lens_reaction_type?: InputMaybe<Lens_Reaction_Types_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<Scalars['String']>;
  recommendation?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  recommendation_comment?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  recommendation_votes?: InputMaybe<Recommendation_Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lens_Reactions_Max_Fields = {
  __typename?: 'lens_reactions_max_fields';
  comment_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_da_id_pointed?: Maybe<Scalars['String']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  lens_profile_id_pointed?: Maybe<Scalars['String']>;
  lens_pub_id_pointed?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lens_reactions" */
export type Lens_Reactions_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lens_da_id_pointed?: InputMaybe<Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  lens_profile_id_pointed?: InputMaybe<Order_By>;
  lens_pub_id_pointed?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  reaction?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lens_Reactions_Min_Fields = {
  __typename?: 'lens_reactions_min_fields';
  comment_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_da_id_pointed?: Maybe<Scalars['String']>;
  lens_profile_id?: Maybe<Scalars['String']>;
  lens_profile_id_pointed?: Maybe<Scalars['String']>;
  lens_pub_id_pointed?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lens_reactions" */
export type Lens_Reactions_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lens_da_id_pointed?: InputMaybe<Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  lens_profile_id_pointed?: InputMaybe<Order_By>;
  lens_pub_id_pointed?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  reaction?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lens_reactions" */
export type Lens_Reactions_Mutation_Response = {
  __typename?: 'lens_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Reactions>;
};

/** input type for inserting object relation for remote table "lens_reactions" */
export type Lens_Reactions_Obj_Rel_Insert_Input = {
  data: Lens_Reactions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Reactions_On_Conflict>;
};

/** on_conflict condition type for table "lens_reactions" */
export type Lens_Reactions_On_Conflict = {
  constraint: Lens_Reactions_Constraint;
  update_columns?: Array<Lens_Reactions_Update_Column>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_reactions". */
export type Lens_Reactions_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  comment_vote?: InputMaybe<Comment_Votes_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lensProfileByLensProfileIdPointed?: InputMaybe<Lens_Profiles_Order_By>;
  lens_da_id_pointed?: InputMaybe<Order_By>;
  lens_post?: InputMaybe<Lens_Posts_Order_By>;
  lens_profile?: InputMaybe<Lens_Profiles_Order_By>;
  lens_profile_id?: InputMaybe<Order_By>;
  lens_profile_id_pointed?: InputMaybe<Order_By>;
  lens_pub_id_pointed?: InputMaybe<Order_By>;
  lens_reaction_type?: InputMaybe<Lens_Reaction_Types_Order_By>;
  post_id?: InputMaybe<Order_By>;
  reaction?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Recommendations_Order_By>;
  recommendation_comment?: InputMaybe<Recommendation_Comments_Order_By>;
  recommendation_votes_aggregate?: InputMaybe<Recommendation_Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: lens_reactions */
export type Lens_Reactions_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "lens_reactions" */
export enum Lens_Reactions_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LensDaIdPointed = 'lens_da_id_pointed',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  LensProfileIdPointed = 'lens_profile_id_pointed',
  /** column name */
  LensPubIdPointed = 'lens_pub_id_pointed',
  /** column name */
  PostId = 'post_id',
  /** column name */
  Reaction = 'reaction'
}

/** input type for updating data in table "lens_reactions" */
export type Lens_Reactions_Set_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lens_da_id_pointed?: InputMaybe<Scalars['String']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  lens_profile_id_pointed?: InputMaybe<Scalars['String']>;
  lens_pub_id_pointed?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lens_reactions" */
export type Lens_Reactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Reactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Reactions_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lens_da_id_pointed?: InputMaybe<Scalars['String']>;
  lens_profile_id?: InputMaybe<Scalars['String']>;
  lens_profile_id_pointed?: InputMaybe<Scalars['String']>;
  lens_pub_id_pointed?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lens_reactions" */
export enum Lens_Reactions_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LensDaIdPointed = 'lens_da_id_pointed',
  /** column name */
  LensProfileId = 'lens_profile_id',
  /** column name */
  LensProfileIdPointed = 'lens_profile_id_pointed',
  /** column name */
  LensPubIdPointed = 'lens_pub_id_pointed',
  /** column name */
  PostId = 'post_id',
  /** column name */
  Reaction = 'reaction'
}

export type Lens_Reactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Reactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Reactions_Bool_Exp;
};

/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum = {
  __typename?: 'lens_tx_status_enum';
  /** An array relationship */
  lens_collects: Array<Lens_Collects>;
  /** An aggregate relationship */
  lens_collects_aggregate: Lens_Collects_Aggregate;
  /** An array relationship */
  lens_followers: Array<Lens_Followers>;
  /** An aggregate relationship */
  lens_followers_aggregate: Lens_Followers_Aggregate;
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  /** An array relationship */
  lens_profiles: Array<Lens_Profiles>;
  /** An aggregate relationship */
  lens_profiles_aggregate: Lens_Profiles_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_CollectsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_Collects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_Order_By>>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_Followers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_Order_By>>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


/** columns and relationships of "lens_tx_status_enum" */
export type Lens_Tx_Status_EnumLens_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};

/** aggregated selection of "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Aggregate = {
  __typename?: 'lens_tx_status_enum_aggregate';
  aggregate?: Maybe<Lens_Tx_Status_Enum_Aggregate_Fields>;
  nodes: Array<Lens_Tx_Status_Enum>;
};

/** aggregate fields of "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Aggregate_Fields = {
  __typename?: 'lens_tx_status_enum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lens_Tx_Status_Enum_Max_Fields>;
  min?: Maybe<Lens_Tx_Status_Enum_Min_Fields>;
};


/** aggregate fields of "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lens_Tx_Status_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lens_tx_status_enum". All fields are combined with a logical 'AND'. */
export type Lens_Tx_Status_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<Lens_Tx_Status_Enum_Bool_Exp>>;
  _not?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<Lens_Tx_Status_Enum_Bool_Exp>>;
  lens_collects?: InputMaybe<Lens_Collects_Bool_Exp>;
  lens_collects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Bool_Exp>;
  lens_followers?: InputMaybe<Lens_Followers_Bool_Exp>;
  lens_followers_aggregate?: InputMaybe<Lens_Followers_Aggregate_Bool_Exp>;
  lens_posts?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp>;
  lens_profiles?: InputMaybe<Lens_Profiles_Bool_Exp>;
  lens_profiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lens_tx_status_enum" */
export enum Lens_Tx_Status_Enum_Constraint {
  /** unique or primary key constraint on columns "value" */
  LensTxStatusEnumPkey = 'lens_tx_status_enum_pkey'
}

export enum Lens_Tx_Status_Enum_Enum {
  Fulfilled = 'fulfilled',
  Initialised = 'initialised',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "lens_tx_status_enum_enum". All fields are combined with logical 'AND'. */
export type Lens_Tx_Status_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  _in?: InputMaybe<Array<Lens_Tx_Status_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Lens_Tx_Status_Enum_Enum>;
  _nin?: InputMaybe<Array<Lens_Tx_Status_Enum_Enum>>;
};

/** input type for inserting data into table "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Insert_Input = {
  lens_collects?: InputMaybe<Lens_Collects_Arr_Rel_Insert_Input>;
  lens_followers?: InputMaybe<Lens_Followers_Arr_Rel_Insert_Input>;
  lens_posts?: InputMaybe<Lens_Posts_Arr_Rel_Insert_Input>;
  lens_profiles?: InputMaybe<Lens_Profiles_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lens_Tx_Status_Enum_Max_Fields = {
  __typename?: 'lens_tx_status_enum_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lens_Tx_Status_Enum_Min_Fields = {
  __typename?: 'lens_tx_status_enum_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Mutation_Response = {
  __typename?: 'lens_tx_status_enum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lens_Tx_Status_Enum>;
};

/** input type for inserting object relation for remote table "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Obj_Rel_Insert_Input = {
  data: Lens_Tx_Status_Enum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lens_Tx_Status_Enum_On_Conflict>;
};

/** on_conflict condition type for table "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_On_Conflict = {
  constraint: Lens_Tx_Status_Enum_Constraint;
  update_columns?: Array<Lens_Tx_Status_Enum_Update_Column>;
  where?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
};

/** Ordering options when selecting data from "lens_tx_status_enum". */
export type Lens_Tx_Status_Enum_Order_By = {
  lens_collects_aggregate?: InputMaybe<Lens_Collects_Aggregate_Order_By>;
  lens_followers_aggregate?: InputMaybe<Lens_Followers_Aggregate_Order_By>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Order_By>;
  lens_profiles_aggregate?: InputMaybe<Lens_Profiles_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lens_tx_status_enum */
export type Lens_Tx_Status_Enum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "lens_tx_status_enum" */
export enum Lens_Tx_Status_Enum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lens_tx_status_enum" */
export type Lens_Tx_Status_Enum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lens_Tx_Status_Enum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lens_Tx_Status_Enum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lens_tx_status_enum" */
export enum Lens_Tx_Status_Enum_Update_Column {
  /** column name */
  Value = 'value'
}

export type Lens_Tx_Status_Enum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lens_Tx_Status_Enum_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lens_Tx_Status_Enum_Bool_Exp;
};

/** columns and relationships of "levels" */
export type Levels = {
  __typename?: 'levels';
  createdAt: Scalars['timestamptz'];
  credScoreRequired: Scalars['Int'];
  /** An array relationship */
  cred_scores: Array<Cred_Scores>;
  /** An aggregate relationship */
  cred_scores_aggregate: Cred_Scores_Aggregate;
  level: Scalars['Int'];
  maximumStakes: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "levels" */
export type LevelsCred_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cred_Scores_Order_By>>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};


/** columns and relationships of "levels" */
export type LevelsCred_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cred_Scores_Order_By>>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};

/** aggregated selection of "levels" */
export type Levels_Aggregate = {
  __typename?: 'levels_aggregate';
  aggregate?: Maybe<Levels_Aggregate_Fields>;
  nodes: Array<Levels>;
};

/** aggregate fields of "levels" */
export type Levels_Aggregate_Fields = {
  __typename?: 'levels_aggregate_fields';
  avg?: Maybe<Levels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Levels_Max_Fields>;
  min?: Maybe<Levels_Min_Fields>;
  stddev?: Maybe<Levels_Stddev_Fields>;
  stddev_pop?: Maybe<Levels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Levels_Stddev_Samp_Fields>;
  sum?: Maybe<Levels_Sum_Fields>;
  var_pop?: Maybe<Levels_Var_Pop_Fields>;
  var_samp?: Maybe<Levels_Var_Samp_Fields>;
  variance?: Maybe<Levels_Variance_Fields>;
};


/** aggregate fields of "levels" */
export type Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Levels_Avg_Fields = {
  __typename?: 'levels_avg_fields';
  credScoreRequired?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  maximumStakes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "levels". All fields are combined with a logical 'AND'. */
export type Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Levels_Bool_Exp>>;
  _not?: InputMaybe<Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Levels_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credScoreRequired?: InputMaybe<Int_Comparison_Exp>;
  cred_scores?: InputMaybe<Cred_Scores_Bool_Exp>;
  cred_scores_aggregate?: InputMaybe<Cred_Scores_Aggregate_Bool_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  maximumStakes?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "levels" */
export enum Levels_Constraint {
  /** unique or primary key constraint on columns "level" */
  LevelsPkey = 'levels_pkey'
}

/** input type for incrementing numeric columns in table "levels" */
export type Levels_Inc_Input = {
  credScoreRequired?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  maximumStakes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "levels" */
export type Levels_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credScoreRequired?: InputMaybe<Scalars['Int']>;
  cred_scores?: InputMaybe<Cred_Scores_Arr_Rel_Insert_Input>;
  level?: InputMaybe<Scalars['Int']>;
  maximumStakes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Levels_Max_Fields = {
  __typename?: 'levels_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credScoreRequired?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  maximumStakes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Levels_Min_Fields = {
  __typename?: 'levels_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credScoreRequired?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  maximumStakes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "levels" */
export type Levels_Mutation_Response = {
  __typename?: 'levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Levels>;
};

/** input type for inserting object relation for remote table "levels" */
export type Levels_Obj_Rel_Insert_Input = {
  data: Levels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};

/** on_conflict condition type for table "levels" */
export type Levels_On_Conflict = {
  constraint: Levels_Constraint;
  update_columns?: Array<Levels_Update_Column>;
  where?: InputMaybe<Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "levels". */
export type Levels_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credScoreRequired?: InputMaybe<Order_By>;
  cred_scores_aggregate?: InputMaybe<Cred_Scores_Aggregate_Order_By>;
  level?: InputMaybe<Order_By>;
  maximumStakes?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: levels */
export type Levels_Pk_Columns_Input = {
  level: Scalars['Int'];
};

/** select columns of table "levels" */
export enum Levels_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredScoreRequired = 'credScoreRequired',
  /** column name */
  Level = 'level',
  /** column name */
  MaximumStakes = 'maximumStakes',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "levels" */
export type Levels_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credScoreRequired?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  maximumStakes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Levels_Stddev_Fields = {
  __typename?: 'levels_stddev_fields';
  credScoreRequired?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  maximumStakes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Levels_Stddev_Pop_Fields = {
  __typename?: 'levels_stddev_pop_fields';
  credScoreRequired?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  maximumStakes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Levels_Stddev_Samp_Fields = {
  __typename?: 'levels_stddev_samp_fields';
  credScoreRequired?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  maximumStakes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "levels" */
export type Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Levels_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credScoreRequired?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  maximumStakes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Levels_Sum_Fields = {
  __typename?: 'levels_sum_fields';
  credScoreRequired?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  maximumStakes?: Maybe<Scalars['Int']>;
};

/** update columns of table "levels" */
export enum Levels_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredScoreRequired = 'credScoreRequired',
  /** column name */
  Level = 'level',
  /** column name */
  MaximumStakes = 'maximumStakes',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Levels_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Levels_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Levels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Levels_Var_Pop_Fields = {
  __typename?: 'levels_var_pop_fields';
  credScoreRequired?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  maximumStakes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Levels_Var_Samp_Fields = {
  __typename?: 'levels_var_samp_fields';
  credScoreRequired?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  maximumStakes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Levels_Variance_Fields = {
  __typename?: 'levels_variance_fields';
  credScoreRequired?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  maximumStakes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "links" */
export type Links = {
  __typename?: 'links';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  /** An object relationship */
  recommendation?: Maybe<Recommendations>;
  recommendationId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url: Scalars['String'];
};

/** aggregated selection of "links" */
export type Links_Aggregate = {
  __typename?: 'links_aggregate';
  aggregate?: Maybe<Links_Aggregate_Fields>;
  nodes: Array<Links>;
};

export type Links_Aggregate_Bool_Exp = {
  count?: InputMaybe<Links_Aggregate_Bool_Exp_Count>;
};

export type Links_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Links_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "links" */
export type Links_Aggregate_Fields = {
  __typename?: 'links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Links_Max_Fields>;
  min?: Maybe<Links_Min_Fields>;
};


/** aggregate fields of "links" */
export type Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "links" */
export type Links_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Links_Max_Order_By>;
  min?: InputMaybe<Links_Min_Order_By>;
};

/** input type for inserting array relation for remote table "links" */
export type Links_Arr_Rel_Insert_Input = {
  data: Array<Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Links_On_Conflict>;
};

/** Boolean expression to filter rows from the table "links". All fields are combined with a logical 'AND'. */
export type Links_Bool_Exp = {
  _and?: InputMaybe<Array<Links_Bool_Exp>>;
  _not?: InputMaybe<Links_Bool_Exp>;
  _or?: InputMaybe<Array<Links_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  recommendation?: InputMaybe<Recommendations_Bool_Exp>;
  recommendationId?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "links" */
export enum Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  LinksPkey = 'links_pkey'
}

/** input type for inserting data into table "links" */
export type Links_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  recommendation?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  recommendationId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Links_Max_Fields = {
  __typename?: 'links_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  recommendationId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "links" */
export type Links_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Links_Min_Fields = {
  __typename?: 'links_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  recommendationId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "links" */
export type Links_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "links" */
export type Links_Mutation_Response = {
  __typename?: 'links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Links>;
};

/** input type for inserting object relation for remote table "links" */
export type Links_Obj_Rel_Insert_Input = {
  data: Links_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Links_On_Conflict>;
};

/** on_conflict condition type for table "links" */
export type Links_On_Conflict = {
  constraint: Links_Constraint;
  update_columns?: Array<Links_Update_Column>;
  where?: InputMaybe<Links_Bool_Exp>;
};

/** Ordering options when selecting data from "links". */
export type Links_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Recommendations_Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: links */
export type Links_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "links" */
export enum Links_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "links" */
export type Links_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  recommendationId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "links" */
export type Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Links_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  recommendationId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "links" */
export enum Links_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type Links_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Links_Bool_Exp;
};

/** columns and relationships of "locked_balances" */
export type Locked_Balances = {
  __typename?: 'locked_balances';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId: Scalars['String'];
  balance: Scalars['Int'];
};

/** aggregated selection of "locked_balances" */
export type Locked_Balances_Aggregate = {
  __typename?: 'locked_balances_aggregate';
  aggregate?: Maybe<Locked_Balances_Aggregate_Fields>;
  nodes: Array<Locked_Balances>;
};

/** aggregate fields of "locked_balances" */
export type Locked_Balances_Aggregate_Fields = {
  __typename?: 'locked_balances_aggregate_fields';
  avg?: Maybe<Locked_Balances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Locked_Balances_Max_Fields>;
  min?: Maybe<Locked_Balances_Min_Fields>;
  stddev?: Maybe<Locked_Balances_Stddev_Fields>;
  stddev_pop?: Maybe<Locked_Balances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locked_Balances_Stddev_Samp_Fields>;
  sum?: Maybe<Locked_Balances_Sum_Fields>;
  var_pop?: Maybe<Locked_Balances_Var_Pop_Fields>;
  var_samp?: Maybe<Locked_Balances_Var_Samp_Fields>;
  variance?: Maybe<Locked_Balances_Variance_Fields>;
};


/** aggregate fields of "locked_balances" */
export type Locked_Balances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locked_Balances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Locked_Balances_Avg_Fields = {
  __typename?: 'locked_balances_avg_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "locked_balances". All fields are combined with a logical 'AND'. */
export type Locked_Balances_Bool_Exp = {
  _and?: InputMaybe<Array<Locked_Balances_Bool_Exp>>;
  _not?: InputMaybe<Locked_Balances_Bool_Exp>;
  _or?: InputMaybe<Array<Locked_Balances_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  balance?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "locked_balances" */
export enum Locked_Balances_Constraint {
  /** unique or primary key constraint on columns "account_id" */
  LockedBalancesPkey = 'locked_balances_pkey'
}

/** input type for incrementing numeric columns in table "locked_balances" */
export type Locked_Balances_Inc_Input = {
  balance?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "locked_balances" */
export type Locked_Balances_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']>;
  balance?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Locked_Balances_Max_Fields = {
  __typename?: 'locked_balances_max_fields';
  accountId?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Locked_Balances_Min_Fields = {
  __typename?: 'locked_balances_min_fields';
  accountId?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "locked_balances" */
export type Locked_Balances_Mutation_Response = {
  __typename?: 'locked_balances_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locked_Balances>;
};

/** input type for inserting object relation for remote table "locked_balances" */
export type Locked_Balances_Obj_Rel_Insert_Input = {
  data: Locked_Balances_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locked_Balances_On_Conflict>;
};

/** on_conflict condition type for table "locked_balances" */
export type Locked_Balances_On_Conflict = {
  constraint: Locked_Balances_Constraint;
  update_columns?: Array<Locked_Balances_Update_Column>;
  where?: InputMaybe<Locked_Balances_Bool_Exp>;
};

/** Ordering options when selecting data from "locked_balances". */
export type Locked_Balances_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locked_balances */
export type Locked_Balances_Pk_Columns_Input = {
  accountId: Scalars['String'];
};

/** select columns of table "locked_balances" */
export enum Locked_Balances_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Balance = 'balance'
}

/** input type for updating data in table "locked_balances" */
export type Locked_Balances_Set_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  balance?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Locked_Balances_Stddev_Fields = {
  __typename?: 'locked_balances_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Locked_Balances_Stddev_Pop_Fields = {
  __typename?: 'locked_balances_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Locked_Balances_Stddev_Samp_Fields = {
  __typename?: 'locked_balances_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "locked_balances" */
export type Locked_Balances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locked_Balances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locked_Balances_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  balance?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Locked_Balances_Sum_Fields = {
  __typename?: 'locked_balances_sum_fields';
  balance?: Maybe<Scalars['Int']>;
};

/** update columns of table "locked_balances" */
export enum Locked_Balances_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Balance = 'balance'
}

export type Locked_Balances_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Locked_Balances_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locked_Balances_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locked_Balances_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locked_Balances_Var_Pop_Fields = {
  __typename?: 'locked_balances_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Locked_Balances_Var_Samp_Fields = {
  __typename?: 'locked_balances_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Locked_Balances_Variance_Fields = {
  __typename?: 'locked_balances_variance_fields';
  balance?: Maybe<Scalars['Float']>;
};

export enum ManualEventTypes {
  CustomPushNotification = 'custom_push_notification',
  PhaverAppVersion = 'phaver_app_version',
  PhaverNewFeature = 'phaver_new_feature',
  PointCorrection = 'point_correction',
  PointRedemption = 'point_redemption',
  PointReward = 'point_reward'
}

/** columns and relationships of "matview_communities_trending" */
export type Matview_Communities_Trending = {
  __typename?: 'matview_communities_trending';
  id?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "matview_communities_trending" */
export type Matview_Communities_Trending_Aggregate = {
  __typename?: 'matview_communities_trending_aggregate';
  aggregate?: Maybe<Matview_Communities_Trending_Aggregate_Fields>;
  nodes: Array<Matview_Communities_Trending>;
};

/** aggregate fields of "matview_communities_trending" */
export type Matview_Communities_Trending_Aggregate_Fields = {
  __typename?: 'matview_communities_trending_aggregate_fields';
  avg?: Maybe<Matview_Communities_Trending_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matview_Communities_Trending_Max_Fields>;
  min?: Maybe<Matview_Communities_Trending_Min_Fields>;
  stddev?: Maybe<Matview_Communities_Trending_Stddev_Fields>;
  stddev_pop?: Maybe<Matview_Communities_Trending_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matview_Communities_Trending_Stddev_Samp_Fields>;
  sum?: Maybe<Matview_Communities_Trending_Sum_Fields>;
  var_pop?: Maybe<Matview_Communities_Trending_Var_Pop_Fields>;
  var_samp?: Maybe<Matview_Communities_Trending_Var_Samp_Fields>;
  variance?: Maybe<Matview_Communities_Trending_Variance_Fields>;
};


/** aggregate fields of "matview_communities_trending" */
export type Matview_Communities_Trending_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matview_Communities_Trending_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matview_Communities_Trending_Avg_Fields = {
  __typename?: 'matview_communities_trending_avg_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matview_communities_trending". All fields are combined with a logical 'AND'. */
export type Matview_Communities_Trending_Bool_Exp = {
  _and?: InputMaybe<Array<Matview_Communities_Trending_Bool_Exp>>;
  _not?: InputMaybe<Matview_Communities_Trending_Bool_Exp>;
  _or?: InputMaybe<Array<Matview_Communities_Trending_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  sorting?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matview_Communities_Trending_Max_Fields = {
  __typename?: 'matview_communities_trending_max_fields';
  id?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Matview_Communities_Trending_Min_Fields = {
  __typename?: 'matview_communities_trending_min_fields';
  id?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "matview_communities_trending". */
export type Matview_Communities_Trending_Order_By = {
  id?: InputMaybe<Order_By>;
  sorting?: InputMaybe<Order_By>;
};

/** select columns of table "matview_communities_trending" */
export enum Matview_Communities_Trending_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Sorting = 'sorting'
}

/** aggregate stddev on columns */
export type Matview_Communities_Trending_Stddev_Fields = {
  __typename?: 'matview_communities_trending_stddev_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matview_Communities_Trending_Stddev_Pop_Fields = {
  __typename?: 'matview_communities_trending_stddev_pop_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matview_Communities_Trending_Stddev_Samp_Fields = {
  __typename?: 'matview_communities_trending_stddev_samp_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matview_communities_trending" */
export type Matview_Communities_Trending_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matview_Communities_Trending_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matview_Communities_Trending_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Matview_Communities_Trending_Sum_Fields = {
  __typename?: 'matview_communities_trending_sum_fields';
  sorting?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Matview_Communities_Trending_Var_Pop_Fields = {
  __typename?: 'matview_communities_trending_var_pop_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matview_Communities_Trending_Var_Samp_Fields = {
  __typename?: 'matview_communities_trending_var_samp_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matview_Communities_Trending_Variance_Fields = {
  __typename?: 'matview_communities_trending_variance_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matview_creators_active" */
export type Matview_Creators_Active = {
  __typename?: 'matview_creators_active';
  id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matview_creators_active" */
export type Matview_Creators_Active_Aggregate = {
  __typename?: 'matview_creators_active_aggregate';
  aggregate?: Maybe<Matview_Creators_Active_Aggregate_Fields>;
  nodes: Array<Matview_Creators_Active>;
};

/** aggregate fields of "matview_creators_active" */
export type Matview_Creators_Active_Aggregate_Fields = {
  __typename?: 'matview_creators_active_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Matview_Creators_Active_Max_Fields>;
  min?: Maybe<Matview_Creators_Active_Min_Fields>;
};


/** aggregate fields of "matview_creators_active" */
export type Matview_Creators_Active_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matview_Creators_Active_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "matview_creators_active". All fields are combined with a logical 'AND'. */
export type Matview_Creators_Active_Bool_Exp = {
  _and?: InputMaybe<Array<Matview_Creators_Active_Bool_Exp>>;
  _not?: InputMaybe<Matview_Creators_Active_Bool_Exp>;
  _or?: InputMaybe<Array<Matview_Creators_Active_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matview_Creators_Active_Max_Fields = {
  __typename?: 'matview_creators_active_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matview_Creators_Active_Min_Fields = {
  __typename?: 'matview_creators_active_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "matview_creators_active". */
export type Matview_Creators_Active_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** select columns of table "matview_creators_active" */
export enum Matview_Creators_Active_Select_Column {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "matview_creators_active" */
export type Matview_Creators_Active_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matview_Creators_Active_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matview_Creators_Active_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "matview_post_scores_7d" */
export type Matview_Post_Scores_7d = {
  __typename?: 'matview_post_scores_7d';
  community_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_stakes?: Maybe<Scalars['bigint']>;
  post_id?: Maybe<Scalars['String']>;
  presorting?: Maybe<Scalars['numeric']>;
  profile_id?: Maybe<Scalars['String']>;
  votes?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "matview_post_scores_7d" */
export type Matview_Post_Scores_7d_Aggregate = {
  __typename?: 'matview_post_scores_7d_aggregate';
  aggregate?: Maybe<Matview_Post_Scores_7d_Aggregate_Fields>;
  nodes: Array<Matview_Post_Scores_7d>;
};

/** aggregate fields of "matview_post_scores_7d" */
export type Matview_Post_Scores_7d_Aggregate_Fields = {
  __typename?: 'matview_post_scores_7d_aggregate_fields';
  avg?: Maybe<Matview_Post_Scores_7d_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matview_Post_Scores_7d_Max_Fields>;
  min?: Maybe<Matview_Post_Scores_7d_Min_Fields>;
  stddev?: Maybe<Matview_Post_Scores_7d_Stddev_Fields>;
  stddev_pop?: Maybe<Matview_Post_Scores_7d_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matview_Post_Scores_7d_Stddev_Samp_Fields>;
  sum?: Maybe<Matview_Post_Scores_7d_Sum_Fields>;
  var_pop?: Maybe<Matview_Post_Scores_7d_Var_Pop_Fields>;
  var_samp?: Maybe<Matview_Post_Scores_7d_Var_Samp_Fields>;
  variance?: Maybe<Matview_Post_Scores_7d_Variance_Fields>;
};


/** aggregate fields of "matview_post_scores_7d" */
export type Matview_Post_Scores_7d_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matview_Post_Scores_7d_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matview_Post_Scores_7d_Avg_Fields = {
  __typename?: 'matview_post_scores_7d_avg_fields';
  daily_stakes?: Maybe<Scalars['Float']>;
  presorting?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matview_post_scores_7d". All fields are combined with a logical 'AND'. */
export type Matview_Post_Scores_7d_Bool_Exp = {
  _and?: InputMaybe<Array<Matview_Post_Scores_7d_Bool_Exp>>;
  _not?: InputMaybe<Matview_Post_Scores_7d_Bool_Exp>;
  _or?: InputMaybe<Array<Matview_Post_Scores_7d_Bool_Exp>>;
  community_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  daily_stakes?: InputMaybe<Bigint_Comparison_Exp>;
  post_id?: InputMaybe<String_Comparison_Exp>;
  presorting?: InputMaybe<Numeric_Comparison_Exp>;
  profile_id?: InputMaybe<String_Comparison_Exp>;
  votes?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matview_Post_Scores_7d_Max_Fields = {
  __typename?: 'matview_post_scores_7d_max_fields';
  community_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_stakes?: Maybe<Scalars['bigint']>;
  post_id?: Maybe<Scalars['String']>;
  presorting?: Maybe<Scalars['numeric']>;
  profile_id?: Maybe<Scalars['String']>;
  votes?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Matview_Post_Scores_7d_Min_Fields = {
  __typename?: 'matview_post_scores_7d_min_fields';
  community_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_stakes?: Maybe<Scalars['bigint']>;
  post_id?: Maybe<Scalars['String']>;
  presorting?: Maybe<Scalars['numeric']>;
  profile_id?: Maybe<Scalars['String']>;
  votes?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "matview_post_scores_7d". */
export type Matview_Post_Scores_7d_Order_By = {
  community_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  daily_stakes?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  presorting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  votes?: InputMaybe<Order_By>;
};

/** select columns of table "matview_post_scores_7d" */
export enum Matview_Post_Scores_7d_Select_Column {
  /** column name */
  CommunityId = 'community_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DailyStakes = 'daily_stakes',
  /** column name */
  PostId = 'post_id',
  /** column name */
  Presorting = 'presorting',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Votes = 'votes'
}

/** aggregate stddev on columns */
export type Matview_Post_Scores_7d_Stddev_Fields = {
  __typename?: 'matview_post_scores_7d_stddev_fields';
  daily_stakes?: Maybe<Scalars['Float']>;
  presorting?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matview_Post_Scores_7d_Stddev_Pop_Fields = {
  __typename?: 'matview_post_scores_7d_stddev_pop_fields';
  daily_stakes?: Maybe<Scalars['Float']>;
  presorting?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matview_Post_Scores_7d_Stddev_Samp_Fields = {
  __typename?: 'matview_post_scores_7d_stddev_samp_fields';
  daily_stakes?: Maybe<Scalars['Float']>;
  presorting?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matview_post_scores_7d" */
export type Matview_Post_Scores_7d_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matview_Post_Scores_7d_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matview_Post_Scores_7d_Stream_Cursor_Value_Input = {
  community_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  daily_stakes?: InputMaybe<Scalars['bigint']>;
  post_id?: InputMaybe<Scalars['String']>;
  presorting?: InputMaybe<Scalars['numeric']>;
  profile_id?: InputMaybe<Scalars['String']>;
  votes?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Matview_Post_Scores_7d_Sum_Fields = {
  __typename?: 'matview_post_scores_7d_sum_fields';
  daily_stakes?: Maybe<Scalars['bigint']>;
  presorting?: Maybe<Scalars['numeric']>;
  votes?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Matview_Post_Scores_7d_Var_Pop_Fields = {
  __typename?: 'matview_post_scores_7d_var_pop_fields';
  daily_stakes?: Maybe<Scalars['Float']>;
  presorting?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matview_Post_Scores_7d_Var_Samp_Fields = {
  __typename?: 'matview_post_scores_7d_var_samp_fields';
  daily_stakes?: Maybe<Scalars['Float']>;
  presorting?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matview_Post_Scores_7d_Variance_Fields = {
  __typename?: 'matview_post_scores_7d_variance_fields';
  daily_stakes?: Maybe<Scalars['Float']>;
  presorting?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matview_users_most_follow" */
export type Matview_Users_Most_Follow = {
  __typename?: 'matview_users_most_follow';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matview_users_most_follow" */
export type Matview_Users_Most_Follow_Aggregate = {
  __typename?: 'matview_users_most_follow_aggregate';
  aggregate?: Maybe<Matview_Users_Most_Follow_Aggregate_Fields>;
  nodes: Array<Matview_Users_Most_Follow>;
};

/** aggregate fields of "matview_users_most_follow" */
export type Matview_Users_Most_Follow_Aggregate_Fields = {
  __typename?: 'matview_users_most_follow_aggregate_fields';
  avg?: Maybe<Matview_Users_Most_Follow_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matview_Users_Most_Follow_Max_Fields>;
  min?: Maybe<Matview_Users_Most_Follow_Min_Fields>;
  stddev?: Maybe<Matview_Users_Most_Follow_Stddev_Fields>;
  stddev_pop?: Maybe<Matview_Users_Most_Follow_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matview_Users_Most_Follow_Stddev_Samp_Fields>;
  sum?: Maybe<Matview_Users_Most_Follow_Sum_Fields>;
  var_pop?: Maybe<Matview_Users_Most_Follow_Var_Pop_Fields>;
  var_samp?: Maybe<Matview_Users_Most_Follow_Var_Samp_Fields>;
  variance?: Maybe<Matview_Users_Most_Follow_Variance_Fields>;
};


/** aggregate fields of "matview_users_most_follow" */
export type Matview_Users_Most_Follow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matview_Users_Most_Follow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matview_Users_Most_Follow_Avg_Fields = {
  __typename?: 'matview_users_most_follow_avg_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matview_users_most_follow". All fields are combined with a logical 'AND'. */
export type Matview_Users_Most_Follow_Bool_Exp = {
  _and?: InputMaybe<Array<Matview_Users_Most_Follow_Bool_Exp>>;
  _not?: InputMaybe<Matview_Users_Most_Follow_Bool_Exp>;
  _or?: InputMaybe<Array<Matview_Users_Most_Follow_Bool_Exp>>;
  badge?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lens_handle?: InputMaybe<String_Comparison_Exp>;
  sorting?: InputMaybe<Int_Comparison_Exp>;
  verification?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "matview_users_most_follow_lens" */
export type Matview_Users_Most_Follow_Lens = {
  __typename?: 'matview_users_most_follow_lens';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matview_users_most_follow_lens" */
export type Matview_Users_Most_Follow_Lens_Aggregate = {
  __typename?: 'matview_users_most_follow_lens_aggregate';
  aggregate?: Maybe<Matview_Users_Most_Follow_Lens_Aggregate_Fields>;
  nodes: Array<Matview_Users_Most_Follow_Lens>;
};

/** aggregate fields of "matview_users_most_follow_lens" */
export type Matview_Users_Most_Follow_Lens_Aggregate_Fields = {
  __typename?: 'matview_users_most_follow_lens_aggregate_fields';
  avg?: Maybe<Matview_Users_Most_Follow_Lens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matview_Users_Most_Follow_Lens_Max_Fields>;
  min?: Maybe<Matview_Users_Most_Follow_Lens_Min_Fields>;
  stddev?: Maybe<Matview_Users_Most_Follow_Lens_Stddev_Fields>;
  stddev_pop?: Maybe<Matview_Users_Most_Follow_Lens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matview_Users_Most_Follow_Lens_Stddev_Samp_Fields>;
  sum?: Maybe<Matview_Users_Most_Follow_Lens_Sum_Fields>;
  var_pop?: Maybe<Matview_Users_Most_Follow_Lens_Var_Pop_Fields>;
  var_samp?: Maybe<Matview_Users_Most_Follow_Lens_Var_Samp_Fields>;
  variance?: Maybe<Matview_Users_Most_Follow_Lens_Variance_Fields>;
};


/** aggregate fields of "matview_users_most_follow_lens" */
export type Matview_Users_Most_Follow_Lens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matview_Users_Most_Follow_Lens_Avg_Fields = {
  __typename?: 'matview_users_most_follow_lens_avg_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matview_users_most_follow_lens". All fields are combined with a logical 'AND'. */
export type Matview_Users_Most_Follow_Lens_Bool_Exp = {
  _and?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Bool_Exp>>;
  _not?: InputMaybe<Matview_Users_Most_Follow_Lens_Bool_Exp>;
  _or?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Bool_Exp>>;
  badge?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lens_handle?: InputMaybe<String_Comparison_Exp>;
  sorting?: InputMaybe<Int_Comparison_Exp>;
  verification?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matview_Users_Most_Follow_Lens_Max_Fields = {
  __typename?: 'matview_users_most_follow_lens_max_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matview_Users_Most_Follow_Lens_Min_Fields = {
  __typename?: 'matview_users_most_follow_lens_min_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "matview_users_most_follow_lens". */
export type Matview_Users_Most_Follow_Lens_Order_By = {
  badge?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lens_handle?: InputMaybe<Order_By>;
  sorting?: InputMaybe<Order_By>;
  verification?: InputMaybe<Order_By>;
};

/** select columns of table "matview_users_most_follow_lens" */
export enum Matview_Users_Most_Follow_Lens_Select_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LensHandle = 'lens_handle',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  Verification = 'verification'
}

/** aggregate stddev on columns */
export type Matview_Users_Most_Follow_Lens_Stddev_Fields = {
  __typename?: 'matview_users_most_follow_lens_stddev_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matview_Users_Most_Follow_Lens_Stddev_Pop_Fields = {
  __typename?: 'matview_users_most_follow_lens_stddev_pop_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matview_Users_Most_Follow_Lens_Stddev_Samp_Fields = {
  __typename?: 'matview_users_most_follow_lens_stddev_samp_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matview_users_most_follow_lens" */
export type Matview_Users_Most_Follow_Lens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matview_Users_Most_Follow_Lens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matview_Users_Most_Follow_Lens_Stream_Cursor_Value_Input = {
  badge?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lens_handle?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  verification?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matview_Users_Most_Follow_Lens_Sum_Fields = {
  __typename?: 'matview_users_most_follow_lens_sum_fields';
  sorting?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Matview_Users_Most_Follow_Lens_Var_Pop_Fields = {
  __typename?: 'matview_users_most_follow_lens_var_pop_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matview_Users_Most_Follow_Lens_Var_Samp_Fields = {
  __typename?: 'matview_users_most_follow_lens_var_samp_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matview_Users_Most_Follow_Lens_Variance_Fields = {
  __typename?: 'matview_users_most_follow_lens_variance_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Matview_Users_Most_Follow_Max_Fields = {
  __typename?: 'matview_users_most_follow_max_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matview_Users_Most_Follow_Min_Fields = {
  __typename?: 'matview_users_most_follow_min_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "matview_users_most_follow". */
export type Matview_Users_Most_Follow_Order_By = {
  badge?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lens_handle?: InputMaybe<Order_By>;
  sorting?: InputMaybe<Order_By>;
  verification?: InputMaybe<Order_By>;
};

/** select columns of table "matview_users_most_follow" */
export enum Matview_Users_Most_Follow_Select_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LensHandle = 'lens_handle',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  Verification = 'verification'
}

/** aggregate stddev on columns */
export type Matview_Users_Most_Follow_Stddev_Fields = {
  __typename?: 'matview_users_most_follow_stddev_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matview_Users_Most_Follow_Stddev_Pop_Fields = {
  __typename?: 'matview_users_most_follow_stddev_pop_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matview_Users_Most_Follow_Stddev_Samp_Fields = {
  __typename?: 'matview_users_most_follow_stddev_samp_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matview_users_most_follow" */
export type Matview_Users_Most_Follow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matview_Users_Most_Follow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matview_Users_Most_Follow_Stream_Cursor_Value_Input = {
  badge?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lens_handle?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  verification?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matview_Users_Most_Follow_Sum_Fields = {
  __typename?: 'matview_users_most_follow_sum_fields';
  sorting?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Matview_Users_Most_Follow_Var_Pop_Fields = {
  __typename?: 'matview_users_most_follow_var_pop_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matview_Users_Most_Follow_Var_Samp_Fields = {
  __typename?: 'matview_users_most_follow_var_samp_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matview_Users_Most_Follow_Variance_Fields = {
  __typename?: 'matview_users_most_follow_variance_fields';
  sorting?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matview_users_trending" */
export type Matview_Users_Trending = {
  __typename?: 'matview_users_trending';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  post_count?: Maybe<Scalars['bigint']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matview_users_trending" */
export type Matview_Users_Trending_Aggregate = {
  __typename?: 'matview_users_trending_aggregate';
  aggregate?: Maybe<Matview_Users_Trending_Aggregate_Fields>;
  nodes: Array<Matview_Users_Trending>;
};

/** aggregate fields of "matview_users_trending" */
export type Matview_Users_Trending_Aggregate_Fields = {
  __typename?: 'matview_users_trending_aggregate_fields';
  avg?: Maybe<Matview_Users_Trending_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matview_Users_Trending_Max_Fields>;
  min?: Maybe<Matview_Users_Trending_Min_Fields>;
  stddev?: Maybe<Matview_Users_Trending_Stddev_Fields>;
  stddev_pop?: Maybe<Matview_Users_Trending_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matview_Users_Trending_Stddev_Samp_Fields>;
  sum?: Maybe<Matview_Users_Trending_Sum_Fields>;
  var_pop?: Maybe<Matview_Users_Trending_Var_Pop_Fields>;
  var_samp?: Maybe<Matview_Users_Trending_Var_Samp_Fields>;
  variance?: Maybe<Matview_Users_Trending_Variance_Fields>;
};


/** aggregate fields of "matview_users_trending" */
export type Matview_Users_Trending_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matview_Users_Trending_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matview_Users_Trending_Avg_Fields = {
  __typename?: 'matview_users_trending_avg_fields';
  post_count?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matview_users_trending". All fields are combined with a logical 'AND'. */
export type Matview_Users_Trending_Bool_Exp = {
  _and?: InputMaybe<Array<Matview_Users_Trending_Bool_Exp>>;
  _not?: InputMaybe<Matview_Users_Trending_Bool_Exp>;
  _or?: InputMaybe<Array<Matview_Users_Trending_Bool_Exp>>;
  badge?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lens_handle?: InputMaybe<String_Comparison_Exp>;
  post_count?: InputMaybe<Bigint_Comparison_Exp>;
  sorting?: InputMaybe<Int_Comparison_Exp>;
  verification?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matview_Users_Trending_Max_Fields = {
  __typename?: 'matview_users_trending_max_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  post_count?: Maybe<Scalars['bigint']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matview_Users_Trending_Min_Fields = {
  __typename?: 'matview_users_trending_min_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lens_handle?: Maybe<Scalars['String']>;
  post_count?: Maybe<Scalars['bigint']>;
  sorting?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "matview_users_trending". */
export type Matview_Users_Trending_Order_By = {
  badge?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lens_handle?: InputMaybe<Order_By>;
  post_count?: InputMaybe<Order_By>;
  sorting?: InputMaybe<Order_By>;
  verification?: InputMaybe<Order_By>;
};

/** select columns of table "matview_users_trending" */
export enum Matview_Users_Trending_Select_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LensHandle = 'lens_handle',
  /** column name */
  PostCount = 'post_count',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  Verification = 'verification'
}

/** aggregate stddev on columns */
export type Matview_Users_Trending_Stddev_Fields = {
  __typename?: 'matview_users_trending_stddev_fields';
  post_count?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matview_Users_Trending_Stddev_Pop_Fields = {
  __typename?: 'matview_users_trending_stddev_pop_fields';
  post_count?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matview_Users_Trending_Stddev_Samp_Fields = {
  __typename?: 'matview_users_trending_stddev_samp_fields';
  post_count?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matview_users_trending" */
export type Matview_Users_Trending_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matview_Users_Trending_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matview_Users_Trending_Stream_Cursor_Value_Input = {
  badge?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lens_handle?: InputMaybe<Scalars['String']>;
  post_count?: InputMaybe<Scalars['bigint']>;
  sorting?: InputMaybe<Scalars['Int']>;
  verification?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matview_Users_Trending_Sum_Fields = {
  __typename?: 'matview_users_trending_sum_fields';
  post_count?: Maybe<Scalars['bigint']>;
  sorting?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Matview_Users_Trending_Var_Pop_Fields = {
  __typename?: 'matview_users_trending_var_pop_fields';
  post_count?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matview_Users_Trending_Var_Samp_Fields = {
  __typename?: 'matview_users_trending_var_samp_fields';
  post_count?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matview_Users_Trending_Variance_Fields = {
  __typename?: 'matview_users_trending_variance_fields';
  post_count?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "medias" */
export type Medias = {
  __typename?: 'medias';
  /** An object relationship */
  audio?: Maybe<Audios>;
  audioId?: Maybe<Scalars['String']>;
  /** An object relationship */
  comment?: Maybe<Recommendation_Comments>;
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  frame?: Maybe<Frames>;
  frame_id?: Maybe<Scalars['uuid']>;
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  /** An object relationship */
  link?: Maybe<Links>;
  link_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  video?: Maybe<Videos>;
  videoId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "medias" */
export type Medias_Aggregate = {
  __typename?: 'medias_aggregate';
  aggregate?: Maybe<Medias_Aggregate_Fields>;
  nodes: Array<Medias>;
};

export type Medias_Aggregate_Bool_Exp = {
  count?: InputMaybe<Medias_Aggregate_Bool_Exp_Count>;
};

export type Medias_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Medias_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Medias_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "medias" */
export type Medias_Aggregate_Fields = {
  __typename?: 'medias_aggregate_fields';
  avg?: Maybe<Medias_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Medias_Max_Fields>;
  min?: Maybe<Medias_Min_Fields>;
  stddev?: Maybe<Medias_Stddev_Fields>;
  stddev_pop?: Maybe<Medias_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Medias_Stddev_Samp_Fields>;
  sum?: Maybe<Medias_Sum_Fields>;
  var_pop?: Maybe<Medias_Var_Pop_Fields>;
  var_samp?: Maybe<Medias_Var_Samp_Fields>;
  variance?: Maybe<Medias_Variance_Fields>;
};


/** aggregate fields of "medias" */
export type Medias_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medias_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "medias" */
export type Medias_Aggregate_Order_By = {
  avg?: InputMaybe<Medias_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Medias_Max_Order_By>;
  min?: InputMaybe<Medias_Min_Order_By>;
  stddev?: InputMaybe<Medias_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Medias_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Medias_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Medias_Sum_Order_By>;
  var_pop?: InputMaybe<Medias_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Medias_Var_Samp_Order_By>;
  variance?: InputMaybe<Medias_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "medias" */
export type Medias_Arr_Rel_Insert_Input = {
  data: Array<Medias_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Medias_On_Conflict>;
};

/** aggregate avg on columns */
export type Medias_Avg_Fields = {
  __typename?: 'medias_avg_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "medias" */
export type Medias_Avg_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "medias". All fields are combined with a logical 'AND'. */
export type Medias_Bool_Exp = {
  _and?: InputMaybe<Array<Medias_Bool_Exp>>;
  _not?: InputMaybe<Medias_Bool_Exp>;
  _or?: InputMaybe<Array<Medias_Bool_Exp>>;
  audio?: InputMaybe<Audios_Bool_Exp>;
  audioId?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  commentId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  frame?: InputMaybe<Frames_Bool_Exp>;
  frame_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  link?: InputMaybe<Links_Bool_Exp>;
  link_id?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "medias" */
export enum Medias_Constraint {
  /** unique or primary key constraint on columns "comment_id", "index" */
  MediasCommentsUniqueIndex = 'medias_comments_unique_index',
  /** unique or primary key constraint on columns "id" */
  MediasPkey = 'medias_pkey',
  /** unique or primary key constraint on columns "index", "post_id" */
  MediasPostsUniqueIndex = 'medias_posts_unique_index'
}

/** input type for incrementing numeric columns in table "medias" */
export type Medias_Inc_Input = {
  index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "medias" */
export type Medias_Insert_Input = {
  audio?: InputMaybe<Audios_Obj_Rel_Insert_Input>;
  audioId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  frame?: InputMaybe<Frames_Obj_Rel_Insert_Input>;
  frame_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Links_Obj_Rel_Insert_Input>;
  link_id?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Medias_Max_Fields = {
  __typename?: 'medias_max_fields';
  audioId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  frame_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  link_id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  videoId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "medias" */
export type Medias_Max_Order_By = {
  audioId?: InputMaybe<Order_By>;
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  frame_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Medias_Min_Fields = {
  __typename?: 'medias_min_fields';
  audioId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  frame_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  link_id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  videoId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "medias" */
export type Medias_Min_Order_By = {
  audioId?: InputMaybe<Order_By>;
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  frame_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  link_id?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "medias" */
export type Medias_Mutation_Response = {
  __typename?: 'medias_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medias>;
};

/** on_conflict condition type for table "medias" */
export type Medias_On_Conflict = {
  constraint: Medias_Constraint;
  update_columns?: Array<Medias_Update_Column>;
  where?: InputMaybe<Medias_Bool_Exp>;
};

/** Ordering options when selecting data from "medias". */
export type Medias_Order_By = {
  audio?: InputMaybe<Audios_Order_By>;
  audioId?: InputMaybe<Order_By>;
  comment?: InputMaybe<Recommendation_Comments_Order_By>;
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  frame?: InputMaybe<Frames_Order_By>;
  frame_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  link?: InputMaybe<Links_Order_By>;
  link_id?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medias */
export type Medias_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "medias" */
export enum Medias_Select_Column {
  /** column name */
  AudioId = 'audioId',
  /** column name */
  CommentId = 'commentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FrameId = 'frame_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Index = 'index',
  /** column name */
  LinkId = 'link_id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "medias" */
export type Medias_Set_Input = {
  audioId?: InputMaybe<Scalars['String']>;
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  frame_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  link_id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  videoId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Medias_Stddev_Fields = {
  __typename?: 'medias_stddev_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "medias" */
export type Medias_Stddev_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Medias_Stddev_Pop_Fields = {
  __typename?: 'medias_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "medias" */
export type Medias_Stddev_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Medias_Stddev_Samp_Fields = {
  __typename?: 'medias_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "medias" */
export type Medias_Stddev_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "medias" */
export type Medias_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medias_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medias_Stream_Cursor_Value_Input = {
  audioId?: InputMaybe<Scalars['String']>;
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  frame_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  link_id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  videoId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Medias_Sum_Fields = {
  __typename?: 'medias_sum_fields';
  index?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "medias" */
export type Medias_Sum_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** update columns of table "medias" */
export enum Medias_Update_Column {
  /** column name */
  AudioId = 'audioId',
  /** column name */
  CommentId = 'commentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FrameId = 'frame_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Index = 'index',
  /** column name */
  LinkId = 'link_id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VideoId = 'videoId'
}

export type Medias_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Medias_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medias_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medias_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Medias_Var_Pop_Fields = {
  __typename?: 'medias_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "medias" */
export type Medias_Var_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Medias_Var_Samp_Fields = {
  __typename?: 'medias_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "medias" */
export type Medias_Var_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Medias_Variance_Fields = {
  __typename?: 'medias_variance_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "medias" */
export type Medias_Variance_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** columns and relationships of "moca_ids" */
export type Moca_Ids = {
  __typename?: 'moca_ids';
  createdAt: Scalars['timestamptz'];
  mocaId: Scalars['String'];
  /** An object relationship */
  moca_ids_profile: Profiles;
  profileId: Scalars['String'];
};

/** aggregated selection of "moca_ids" */
export type Moca_Ids_Aggregate = {
  __typename?: 'moca_ids_aggregate';
  aggregate?: Maybe<Moca_Ids_Aggregate_Fields>;
  nodes: Array<Moca_Ids>;
};

/** aggregate fields of "moca_ids" */
export type Moca_Ids_Aggregate_Fields = {
  __typename?: 'moca_ids_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Moca_Ids_Max_Fields>;
  min?: Maybe<Moca_Ids_Min_Fields>;
};


/** aggregate fields of "moca_ids" */
export type Moca_Ids_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Moca_Ids_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "moca_ids". All fields are combined with a logical 'AND'. */
export type Moca_Ids_Bool_Exp = {
  _and?: InputMaybe<Array<Moca_Ids_Bool_Exp>>;
  _not?: InputMaybe<Moca_Ids_Bool_Exp>;
  _or?: InputMaybe<Array<Moca_Ids_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  mocaId?: InputMaybe<String_Comparison_Exp>;
  moca_ids_profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "moca_ids" */
export enum Moca_Ids_Constraint {
  /** unique or primary key constraint on columns "moca_id" */
  MocaIdsPkey = 'moca_ids_pkey',
  /** unique or primary key constraint on columns "profile_id" */
  MocaIdsProfileIdUnique = 'moca_ids_profile_id_unique'
}

/** input type for inserting data into table "moca_ids" */
export type Moca_Ids_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  mocaId?: InputMaybe<Scalars['String']>;
  moca_ids_profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Moca_Ids_Max_Fields = {
  __typename?: 'moca_ids_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  mocaId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Moca_Ids_Min_Fields = {
  __typename?: 'moca_ids_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  mocaId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "moca_ids" */
export type Moca_Ids_Mutation_Response = {
  __typename?: 'moca_ids_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Moca_Ids>;
};

/** on_conflict condition type for table "moca_ids" */
export type Moca_Ids_On_Conflict = {
  constraint: Moca_Ids_Constraint;
  update_columns?: Array<Moca_Ids_Update_Column>;
  where?: InputMaybe<Moca_Ids_Bool_Exp>;
};

/** Ordering options when selecting data from "moca_ids". */
export type Moca_Ids_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  mocaId?: InputMaybe<Order_By>;
  moca_ids_profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: moca_ids */
export type Moca_Ids_Pk_Columns_Input = {
  mocaId: Scalars['String'];
};

/** select columns of table "moca_ids" */
export enum Moca_Ids_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  MocaId = 'mocaId',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "moca_ids" */
export type Moca_Ids_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  mocaId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "moca_ids" */
export type Moca_Ids_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Moca_Ids_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Moca_Ids_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  mocaId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "moca_ids" */
export enum Moca_Ids_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  MocaId = 'mocaId',
  /** column name */
  ProfileId = 'profileId'
}

export type Moca_Ids_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Moca_Ids_Set_Input>;
  /** filter the rows which have to be updated */
  where: Moca_Ids_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  addAdminPortalCustomClaims?: Maybe<AddCustomClaimsOutput>;
  banUser: BanUserOutput;
  ccProfileBuildData: CcProfileBuildDataOutput;
  ccProfileExecute: CcProfileExecuteOutput;
  /** changeTopic */
  changeTopic?: Maybe<ChangeTopicOutput>;
  claimYield?: Maybe<ClaimYieldOutput>;
  collectPost?: Maybe<CollectPostOutput>;
  connectNftsBuildData: ConnectNftsBuildDataOutput;
  connectNftsExecute: ConnectNftsExecuteOutput;
  connectWalletBuildData: ConnectWalletBuildDataOutput;
  connectWalletExecute: ConnectWalletExecuteOutput;
  connectWithTokenproof?: Maybe<ConnectWithTokenproofOutput>;
  createAdminPortalTenant?: Maybe<CreateAdminPortalTenantOutput>;
  /** createComment */
  createComment?: Maybe<CreateCommentOutput>;
  createCommunity: CreateCommunityOutput;
  createCommunityCategory: CreateCommunityCategoryOutput;
  /** createFarcasterAccountExecute */
  createFarcasterAccountExecute: CreateFarcasterAccountExecuteOutput;
  createPost: CreatePostResult;
  createSupportTicket?: Maybe<CreateSupportTicketOutput>;
  dailyStakingUse?: Maybe<DailyStakingUseReply>;
  deleteComment?: Maybe<DeleteOutput>;
  deleteCommunity?: Maybe<DeleteOutput>;
  deleteCommunityCategories: DeleteCommunityCategoriesOutput;
  deleteRecommendation?: Maybe<DeleteOutput>;
  deleteUser?: Maybe<DeleteOutput>;
  deleteUserWipe?: Maybe<DeleteOutput>;
  /** delete data from the table: "account_types" */
  delete_account_types?: Maybe<Account_Types_Mutation_Response>;
  /** delete single row from the table: "account_types" */
  delete_account_types_by_pk?: Maybe<Account_Types>;
  /** delete data from the table: "accounts" */
  delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "admin_portal_whitelist" */
  delete_admin_portal_whitelist?: Maybe<Admin_Portal_Whitelist_Mutation_Response>;
  /** delete single row from the table: "admin_portal_whitelist" */
  delete_admin_portal_whitelist_by_pk?: Maybe<Admin_Portal_Whitelist>;
  /** delete data from the table: "airdrop" */
  delete_airdrop?: Maybe<Airdrop_Mutation_Response>;
  /** delete single row from the table: "airdrop" */
  delete_airdrop_by_pk?: Maybe<Airdrop>;
  /** delete data from the table: "async_status" */
  delete_async_status?: Maybe<Async_Status_Mutation_Response>;
  /** delete single row from the table: "async_status" */
  delete_async_status_by_pk?: Maybe<Async_Status>;
  /** delete data from the table: "audios" */
  delete_audios?: Maybe<Audios_Mutation_Response>;
  /** delete single row from the table: "audios" */
  delete_audios_by_pk?: Maybe<Audios>;
  /** delete data from the table: "badge_types" */
  delete_badge_types?: Maybe<Badge_Types_Mutation_Response>;
  /** delete single row from the table: "badge_types" */
  delete_badge_types_by_pk?: Maybe<Badge_Types>;
  /** delete data from the table: "blocked_profiles" */
  delete_blocked_profiles?: Maybe<Blocked_Profiles_Mutation_Response>;
  /** delete single row from the table: "blocked_profiles" */
  delete_blocked_profiles_by_pk?: Maybe<Blocked_Profiles>;
  /** delete data from the table: "campaign_signatures" */
  delete_campaign_signatures?: Maybe<Campaign_Signatures_Mutation_Response>;
  /** delete single row from the table: "campaign_signatures" */
  delete_campaign_signatures_by_pk?: Maybe<Campaign_Signatures>;
  /** delete data from the table: "cc_profiles" */
  delete_cc_profiles?: Maybe<Cc_Profiles_Mutation_Response>;
  /** delete single row from the table: "cc_profiles" */
  delete_cc_profiles_by_pk?: Maybe<Cc_Profiles>;
  /** delete data from the table: "cities" */
  delete_cities?: Maybe<Cities_Mutation_Response>;
  /** delete single row from the table: "cities" */
  delete_cities_by_pk?: Maybe<Cities>;
  /** delete data from the table: "claimed_yield" */
  delete_claimed_yield?: Maybe<Claimed_Yield_Mutation_Response>;
  /** delete single row from the table: "claimed_yield" */
  delete_claimed_yield_by_pk?: Maybe<Claimed_Yield>;
  /** delete data from the table: "comment_aggregates" */
  delete_comment_aggregates?: Maybe<Comment_Aggregates_Mutation_Response>;
  /** delete single row from the table: "comment_aggregates" */
  delete_comment_aggregates_by_pk?: Maybe<Comment_Aggregates>;
  /** delete data from the table: "comment_profile_tags" */
  delete_comment_profile_tags?: Maybe<Comment_Profile_Tags_Mutation_Response>;
  /** delete single row from the table: "comment_profile_tags" */
  delete_comment_profile_tags_by_pk?: Maybe<Comment_Profile_Tags>;
  /** delete data from the table: "comment_votes" */
  delete_comment_votes?: Maybe<Comment_Votes_Mutation_Response>;
  /** delete single row from the table: "comment_votes" */
  delete_comment_votes_by_pk?: Maybe<Comment_Votes>;
  /** delete data from the table: "communities" */
  delete_communities?: Maybe<Communities_Mutation_Response>;
  /** delete single row from the table: "communities" */
  delete_communities_by_pk?: Maybe<Communities>;
  /** delete data from the table: "community_aggregates" */
  delete_community_aggregates?: Maybe<Community_Aggregates_Mutation_Response>;
  /** delete single row from the table: "community_aggregates" */
  delete_community_aggregates_by_pk?: Maybe<Community_Aggregates>;
  /** delete data from the table: "community_categories" */
  delete_community_categories?: Maybe<Community_Categories_Mutation_Response>;
  /** delete single row from the table: "community_categories" */
  delete_community_categories_by_pk?: Maybe<Community_Categories>;
  /** delete data from the table: "community_content_collections" */
  delete_community_content_collections?: Maybe<Community_Content_Collections_Mutation_Response>;
  /** delete single row from the table: "community_content_collections" */
  delete_community_content_collections_by_pk?: Maybe<Community_Content_Collections>;
  /** delete data from the table: "community_content_questions" */
  delete_community_content_questions?: Maybe<Community_Content_Questions_Mutation_Response>;
  /** delete single row from the table: "community_content_questions" */
  delete_community_content_questions_by_pk?: Maybe<Community_Content_Questions>;
  /** delete data from the table: "community_content_votes" */
  delete_community_content_votes?: Maybe<Community_Content_Votes_Mutation_Response>;
  /** delete single row from the table: "community_content_votes" */
  delete_community_content_votes_by_pk?: Maybe<Community_Content_Votes>;
  /** delete data from the table: "community_contents" */
  delete_community_contents?: Maybe<Community_Contents_Mutation_Response>;
  /** delete single row from the table: "community_contents" */
  delete_community_contents_by_pk?: Maybe<Community_Contents>;
  /** delete data from the table: "community_members" */
  delete_community_members?: Maybe<Community_Members_Mutation_Response>;
  /** delete single row from the table: "community_members" */
  delete_community_members_by_pk?: Maybe<Community_Members>;
  /** delete data from the table: "community_nft_projects" */
  delete_community_nft_projects?: Maybe<Community_Nft_Projects_Mutation_Response>;
  /** delete single row from the table: "community_nft_projects" */
  delete_community_nft_projects_by_pk?: Maybe<Community_Nft_Projects>;
  /** delete data from the table: "community_staffpicks" */
  delete_community_staffpicks?: Maybe<Community_Staffpicks_Mutation_Response>;
  /** delete single row from the table: "community_staffpicks" */
  delete_community_staffpicks_by_pk?: Maybe<Community_Staffpicks>;
  /** delete data from the table: "connected_nfts" */
  delete_connected_nfts?: Maybe<Connected_Nfts_Mutation_Response>;
  /** delete single row from the table: "connected_nfts" */
  delete_connected_nfts_by_pk?: Maybe<Connected_Nfts>;
  /** delete data from the table: "continents" */
  delete_continents?: Maybe<Continents_Mutation_Response>;
  /** delete single row from the table: "continents" */
  delete_continents_by_pk?: Maybe<Continents>;
  /** delete data from the table: "countries" */
  delete_countries?: Maybe<Countries_Mutation_Response>;
  /** delete single row from the table: "countries" */
  delete_countries_by_pk?: Maybe<Countries>;
  /** delete data from the table: "cred_scores" */
  delete_cred_scores?: Maybe<Cred_Scores_Mutation_Response>;
  /** delete single row from the table: "cred_scores" */
  delete_cred_scores_by_pk?: Maybe<Cred_Scores>;
  /** delete data from the table: "currency_isocodes" */
  delete_currency_isocodes?: Maybe<Currency_Isocodes_Mutation_Response>;
  /** delete single row from the table: "currency_isocodes" */
  delete_currency_isocodes_by_pk?: Maybe<Currency_Isocodes>;
  /** delete data from the table: "daily_staking" */
  delete_daily_staking?: Maybe<Daily_Staking_Mutation_Response>;
  /** delete single row from the table: "daily_staking" */
  delete_daily_staking_by_pk?: Maybe<Daily_Staking>;
  /** delete data from the table: "embedded_urls" */
  delete_embedded_urls?: Maybe<Embedded_Urls_Mutation_Response>;
  /** delete single row from the table: "embedded_urls" */
  delete_embedded_urls_by_pk?: Maybe<Embedded_Urls>;
  /** delete data from the table: "farcaster_casts" */
  delete_farcaster_casts?: Maybe<Farcaster_Casts_Mutation_Response>;
  /** delete single row from the table: "farcaster_casts" */
  delete_farcaster_casts_by_pk?: Maybe<Farcaster_Casts>;
  /** delete data from the table: "farcaster_invites" */
  delete_farcaster_invites?: Maybe<Farcaster_Invites_Mutation_Response>;
  /** delete single row from the table: "farcaster_invites" */
  delete_farcaster_invites_by_pk?: Maybe<Farcaster_Invites>;
  /** delete data from the table: "farcaster_power_badge_users" */
  delete_farcaster_power_badge_users?: Maybe<Farcaster_Power_Badge_Users_Mutation_Response>;
  /** delete single row from the table: "farcaster_power_badge_users" */
  delete_farcaster_power_badge_users_by_pk?: Maybe<Farcaster_Power_Badge_Users>;
  /** delete data from the table: "farcaster_profiles" */
  delete_farcaster_profiles?: Maybe<Farcaster_Profiles_Mutation_Response>;
  /** delete single row from the table: "farcaster_profiles" */
  delete_farcaster_profiles_by_pk?: Maybe<Farcaster_Profiles>;
  /** delete data from the table: "farcaster_reactions" */
  delete_farcaster_reactions?: Maybe<Farcaster_Reactions_Mutation_Response>;
  /** delete single row from the table: "farcaster_reactions" */
  delete_farcaster_reactions_by_pk?: Maybe<Farcaster_Reactions>;
  /** delete data from the table: "farcaster_signers" */
  delete_farcaster_signers?: Maybe<Farcaster_Signers_Mutation_Response>;
  /** delete single row from the table: "farcaster_signers" */
  delete_farcaster_signers_by_pk?: Maybe<Farcaster_Signers>;
  /** delete data from the table: "flag_types" */
  delete_flag_types?: Maybe<Flag_Types_Mutation_Response>;
  /** delete single row from the table: "flag_types" */
  delete_flag_types_by_pk?: Maybe<Flag_Types>;
  /** delete data from the table: "flags" */
  delete_flags?: Maybe<Flags_Mutation_Response>;
  /** delete single row from the table: "flags" */
  delete_flags_by_pk?: Maybe<Flags>;
  /** delete data from the table: "followers" */
  delete_followers?: Maybe<Followers_Mutation_Response>;
  /** delete single row from the table: "followers" */
  delete_followers_by_pk?: Maybe<Followers>;
  /** delete data from the table: "frame_action" */
  delete_frame_action?: Maybe<Frame_Action_Mutation_Response>;
  /** delete single row from the table: "frame_action" */
  delete_frame_action_by_pk?: Maybe<Frame_Action>;
  /** delete data from the table: "frame_buttons" */
  delete_frame_buttons?: Maybe<Frame_Buttons_Mutation_Response>;
  /** delete single row from the table: "frame_buttons" */
  delete_frame_buttons_by_pk?: Maybe<Frame_Buttons>;
  /** delete data from the table: "frames" */
  delete_frames?: Maybe<Frames_Mutation_Response>;
  /** delete single row from the table: "frames" */
  delete_frames_by_pk?: Maybe<Frames>;
  /** delete data from the table: "images" */
  delete_images?: Maybe<Images_Mutation_Response>;
  /** delete single row from the table: "images" */
  delete_images_by_pk?: Maybe<Images>;
  /** delete data from the table: "item_others" */
  delete_item_others?: Maybe<Item_Others_Mutation_Response>;
  /** delete single row from the table: "item_others" */
  delete_item_others_by_pk?: Maybe<Item_Others>;
  /** delete data from the table: "item_types" */
  delete_item_types?: Maybe<Item_Types_Mutation_Response>;
  /** delete single row from the table: "item_types" */
  delete_item_types_by_pk?: Maybe<Item_Types>;
  /** delete data from the table: "items" */
  delete_items?: Maybe<Items_Mutation_Response>;
  /** delete single row from the table: "items" */
  delete_items_by_pk?: Maybe<Items>;
  /** delete data from the table: "knex_migrations" */
  delete_knex_migrations?: Maybe<Knex_Migrations_Mutation_Response>;
  /** delete single row from the table: "knex_migrations" */
  delete_knex_migrations_by_pk?: Maybe<Knex_Migrations>;
  /** delete data from the table: "knex_migrations_lock" */
  delete_knex_migrations_lock?: Maybe<Knex_Migrations_Lock_Mutation_Response>;
  /** delete single row from the table: "knex_migrations_lock" */
  delete_knex_migrations_lock_by_pk?: Maybe<Knex_Migrations_Lock>;
  /** delete data from the table: "language_isocodes" */
  delete_language_isocodes?: Maybe<Language_Isocodes_Mutation_Response>;
  /** delete single row from the table: "language_isocodes" */
  delete_language_isocodes_by_pk?: Maybe<Language_Isocodes>;
  /** delete data from the table: "lens_api_access_tokens" */
  delete_lens_api_access_tokens?: Maybe<Lens_Api_Access_Tokens_Mutation_Response>;
  /** delete single row from the table: "lens_api_access_tokens" */
  delete_lens_api_access_tokens_by_pk?: Maybe<Lens_Api_Access_Tokens>;
  /** delete data from the table: "lens_api_refresh_tokens" */
  delete_lens_api_refresh_tokens?: Maybe<Lens_Api_Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "lens_api_refresh_tokens" */
  delete_lens_api_refresh_tokens_by_pk?: Maybe<Lens_Api_Refresh_Tokens>;
  /** delete data from the table: "lens_appid_mappings" */
  delete_lens_appid_mappings?: Maybe<Lens_Appid_Mappings_Mutation_Response>;
  /** delete single row from the table: "lens_appid_mappings" */
  delete_lens_appid_mappings_by_pk?: Maybe<Lens_Appid_Mappings>;
  /** delete data from the table: "lens_collects" */
  delete_lens_collects?: Maybe<Lens_Collects_Mutation_Response>;
  /** delete single row from the table: "lens_collects" */
  delete_lens_collects_by_pk?: Maybe<Lens_Collects>;
  /** delete data from the table: "lens_custodial_accounts" */
  delete_lens_custodial_accounts?: Maybe<Lens_Custodial_Accounts_Mutation_Response>;
  /** delete single row from the table: "lens_custodial_accounts" */
  delete_lens_custodial_accounts_by_pk?: Maybe<Lens_Custodial_Accounts>;
  /** delete data from the table: "lens_events" */
  delete_lens_events?: Maybe<Lens_Events_Mutation_Response>;
  /** delete single row from the table: "lens_events" */
  delete_lens_events_by_pk?: Maybe<Lens_Events>;
  /** delete data from the table: "lens_followers" */
  delete_lens_followers?: Maybe<Lens_Followers_Mutation_Response>;
  /** delete single row from the table: "lens_followers" */
  delete_lens_followers_by_pk?: Maybe<Lens_Followers>;
  /** delete data from the table: "lens_followers_v2" */
  delete_lens_followers_v2?: Maybe<Lens_Followers_V2_Mutation_Response>;
  /** delete single row from the table: "lens_followers_v2" */
  delete_lens_followers_v2_by_pk?: Maybe<Lens_Followers_V2>;
  /** delete data from the table: "lens_indexer" */
  delete_lens_indexer?: Maybe<Lens_Indexer_Mutation_Response>;
  /** delete single row from the table: "lens_indexer" */
  delete_lens_indexer_by_pk?: Maybe<Lens_Indexer>;
  /** delete data from the table: "lens_invites" */
  delete_lens_invites?: Maybe<Lens_Invites_Mutation_Response>;
  /** delete single row from the table: "lens_invites" */
  delete_lens_invites_by_pk?: Maybe<Lens_Invites>;
  /** delete data from the table: "lens_post_lens_pub_types" */
  delete_lens_post_lens_pub_types?: Maybe<Lens_Post_Lens_Pub_Types_Mutation_Response>;
  /** delete single row from the table: "lens_post_lens_pub_types" */
  delete_lens_post_lens_pub_types_by_pk?: Maybe<Lens_Post_Lens_Pub_Types>;
  /** delete data from the table: "lens_posts" */
  delete_lens_posts?: Maybe<Lens_Posts_Mutation_Response>;
  /** delete single row from the table: "lens_posts" */
  delete_lens_posts_by_pk?: Maybe<Lens_Posts>;
  /** delete data from the table: "lens_profiles" */
  delete_lens_profiles?: Maybe<Lens_Profiles_Mutation_Response>;
  /** delete single row from the table: "lens_profiles" */
  delete_lens_profiles_by_pk?: Maybe<Lens_Profiles>;
  /** delete data from the table: "lens_reaction_types" */
  delete_lens_reaction_types?: Maybe<Lens_Reaction_Types_Mutation_Response>;
  /** delete single row from the table: "lens_reaction_types" */
  delete_lens_reaction_types_by_pk?: Maybe<Lens_Reaction_Types>;
  /** delete data from the table: "lens_reactions" */
  delete_lens_reactions?: Maybe<Lens_Reactions_Mutation_Response>;
  /** delete single row from the table: "lens_reactions" */
  delete_lens_reactions_by_pk?: Maybe<Lens_Reactions>;
  /** delete data from the table: "lens_tx_status_enum" */
  delete_lens_tx_status_enum?: Maybe<Lens_Tx_Status_Enum_Mutation_Response>;
  /** delete single row from the table: "lens_tx_status_enum" */
  delete_lens_tx_status_enum_by_pk?: Maybe<Lens_Tx_Status_Enum>;
  /** delete data from the table: "levels" */
  delete_levels?: Maybe<Levels_Mutation_Response>;
  /** delete single row from the table: "levels" */
  delete_levels_by_pk?: Maybe<Levels>;
  /** delete data from the table: "links" */
  delete_links?: Maybe<Links_Mutation_Response>;
  /** delete single row from the table: "links" */
  delete_links_by_pk?: Maybe<Links>;
  /** delete data from the table: "locked_balances" */
  delete_locked_balances?: Maybe<Locked_Balances_Mutation_Response>;
  /** delete single row from the table: "locked_balances" */
  delete_locked_balances_by_pk?: Maybe<Locked_Balances>;
  /** delete data from the table: "medias" */
  delete_medias?: Maybe<Medias_Mutation_Response>;
  /** delete single row from the table: "medias" */
  delete_medias_by_pk?: Maybe<Medias>;
  /** delete data from the table: "moca_ids" */
  delete_moca_ids?: Maybe<Moca_Ids_Mutation_Response>;
  /** delete single row from the table: "moca_ids" */
  delete_moca_ids_by_pk?: Maybe<Moca_Ids>;
  /** delete data from the table: "notification_setting_types" */
  delete_notification_setting_types?: Maybe<Notification_Setting_Types_Mutation_Response>;
  /** delete single row from the table: "notification_setting_types" */
  delete_notification_setting_types_by_pk?: Maybe<Notification_Setting_Types>;
  /** delete data from the table: "notification_settings" */
  delete_notification_settings?: Maybe<Notification_Settings_Mutation_Response>;
  /** delete single row from the table: "notification_settings" */
  delete_notification_settings_by_pk?: Maybe<Notification_Settings>;
  /** delete data from the table: "perks" */
  delete_perks?: Maybe<Perks_Mutation_Response>;
  /** delete single row from the table: "perks" */
  delete_perks_by_pk?: Maybe<Perks>;
  /** delete data from the table: "perks_claimed" */
  delete_perks_claimed?: Maybe<Perks_Claimed_Mutation_Response>;
  /** delete single row from the table: "perks_claimed" */
  delete_perks_claimed_by_pk?: Maybe<Perks_Claimed>;
  /** delete data from the table: "post_aggregates" */
  delete_post_aggregates?: Maybe<Post_Aggregates_Mutation_Response>;
  /** delete single row from the table: "post_aggregates" */
  delete_post_aggregates_by_pk?: Maybe<Post_Aggregates>;
  /** delete data from the table: "post_profile_tags" */
  delete_post_profile_tags?: Maybe<Post_Profile_Tags_Mutation_Response>;
  /** delete single row from the table: "post_profile_tags" */
  delete_post_profile_tags_by_pk?: Maybe<Post_Profile_Tags>;
  /** delete data from the table: "post_reaction_types" */
  delete_post_reaction_types?: Maybe<Post_Reaction_Types_Mutation_Response>;
  /** delete single row from the table: "post_reaction_types" */
  delete_post_reaction_types_by_pk?: Maybe<Post_Reaction_Types>;
  /** delete data from the table: "post_reactions" */
  delete_post_reactions?: Maybe<Post_Reactions_Mutation_Response>;
  /** delete single row from the table: "post_reactions" */
  delete_post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** delete data from the table: "profile_aggregates" */
  delete_profile_aggregates?: Maybe<Profile_Aggregates_Mutation_Response>;
  /** delete single row from the table: "profile_aggregates" */
  delete_profile_aggregates_by_pk?: Maybe<Profile_Aggregates>;
  /** delete data from the table: "profiles" */
  delete_profiles?: Maybe<Profiles_Mutation_Response>;
  /** delete single row from the table: "profiles" */
  delete_profiles_by_pk?: Maybe<Profiles>;
  /** delete data from the table: "push_tokens" */
  delete_push_tokens?: Maybe<Push_Tokens_Mutation_Response>;
  /** delete single row from the table: "push_tokens" */
  delete_push_tokens_by_pk?: Maybe<Push_Tokens>;
  /** delete data from the table: "recommendation_comments" */
  delete_recommendation_comments?: Maybe<Recommendation_Comments_Mutation_Response>;
  /** delete single row from the table: "recommendation_comments" */
  delete_recommendation_comments_by_pk?: Maybe<Recommendation_Comments>;
  /** delete data from the table: "recommendation_votes" */
  delete_recommendation_votes?: Maybe<Recommendation_Votes_Mutation_Response>;
  /** delete single row from the table: "recommendation_votes" */
  delete_recommendation_votes_by_pk?: Maybe<Recommendation_Votes>;
  /** delete data from the table: "recommendations" */
  delete_recommendations?: Maybe<Recommendations_Mutation_Response>;
  /** delete single row from the table: "recommendations" */
  delete_recommendations_by_pk?: Maybe<Recommendations>;
  /** delete data from the table: "reported_content" */
  delete_reported_content?: Maybe<Reported_Content_Mutation_Response>;
  /** delete single row from the table: "reported_content" */
  delete_reported_content_by_pk?: Maybe<Reported_Content>;
  /** delete data from the table: "reward_brands" */
  delete_reward_brands?: Maybe<Reward_Brands_Mutation_Response>;
  /** delete single row from the table: "reward_brands" */
  delete_reward_brands_by_pk?: Maybe<Reward_Brands>;
  /** delete data from the table: "reward_claims" */
  delete_reward_claims?: Maybe<Reward_Claims_Mutation_Response>;
  /** delete single row from the table: "reward_claims" */
  delete_reward_claims_by_pk?: Maybe<Reward_Claims>;
  /** delete data from the table: "reward_types" */
  delete_reward_types?: Maybe<Reward_Types_Mutation_Response>;
  /** delete single row from the table: "reward_types" */
  delete_reward_types_by_pk?: Maybe<Reward_Types>;
  /** delete data from the table: "role_types" */
  delete_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** delete single row from the table: "role_types" */
  delete_role_types_by_pk?: Maybe<Role_Types>;
  /** delete data from the table: "rss_feeds" */
  delete_rss_feeds?: Maybe<Rss_Feeds_Mutation_Response>;
  /** delete single row from the table: "rss_feeds" */
  delete_rss_feeds_by_pk?: Maybe<Rss_Feeds>;
  /** delete data from the table: "staking_reward_posters" */
  delete_staking_reward_posters?: Maybe<Staking_Reward_Posters_Mutation_Response>;
  /** delete single row from the table: "staking_reward_posters" */
  delete_staking_reward_posters_by_pk?: Maybe<Staking_Reward_Posters>;
  /** delete data from the table: "staking_reward_stakers" */
  delete_staking_reward_stakers?: Maybe<Staking_Reward_Stakers_Mutation_Response>;
  /** delete single row from the table: "staking_reward_stakers" */
  delete_staking_reward_stakers_by_pk?: Maybe<Staking_Reward_Stakers>;
  /** delete data from the table: "subdivisions" */
  delete_subdivisions?: Maybe<Subdivisions_Mutation_Response>;
  /** delete single row from the table: "subdivisions" */
  delete_subdivisions_by_pk?: Maybe<Subdivisions>;
  /** delete data from the table: "task_sections" */
  delete_task_sections?: Maybe<Task_Sections_Mutation_Response>;
  /** delete single row from the table: "task_sections" */
  delete_task_sections_by_pk?: Maybe<Task_Sections>;
  /** delete data from the table: "task_types" */
  delete_task_types?: Maybe<Task_Types_Mutation_Response>;
  /** delete single row from the table: "task_types" */
  delete_task_types_by_pk?: Maybe<Task_Types>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "tasks_done" */
  delete_tasks_done?: Maybe<Tasks_Done_Mutation_Response>;
  /** delete single row from the table: "tasks_done" */
  delete_tasks_done_by_pk?: Maybe<Tasks_Done>;
  /** delete data from the table: "verification_types" */
  delete_verification_types?: Maybe<Verification_Types_Mutation_Response>;
  /** delete single row from the table: "verification_types" */
  delete_verification_types_by_pk?: Maybe<Verification_Types>;
  /** delete data from the table: "verified_nft_project_kinds" */
  delete_verified_nft_project_kinds?: Maybe<Verified_Nft_Project_Kinds_Mutation_Response>;
  /** delete single row from the table: "verified_nft_project_kinds" */
  delete_verified_nft_project_kinds_by_pk?: Maybe<Verified_Nft_Project_Kinds>;
  /** delete data from the table: "verified_nft_projects" */
  delete_verified_nft_projects?: Maybe<Verified_Nft_Projects_Mutation_Response>;
  /** delete single row from the table: "verified_nft_projects" */
  delete_verified_nft_projects_by_pk?: Maybe<Verified_Nft_Projects>;
  /** delete data from the table: "videos" */
  delete_videos?: Maybe<Videos_Mutation_Response>;
  /** delete single row from the table: "videos" */
  delete_videos_by_pk?: Maybe<Videos>;
  /** delete data from the table: "wallets" */
  delete_wallets?: Maybe<Wallets_Mutation_Response>;
  /** delete single row from the table: "wallets" */
  delete_wallets_by_pk?: Maybe<Wallets>;
  /** delete data from the table: "wallets_resolves" */
  delete_wallets_resolves?: Maybe<Wallets_Resolves_Mutation_Response>;
  /** delete single row from the table: "wallets_resolves" */
  delete_wallets_resolves_by_pk?: Maybe<Wallets_Resolves>;
  /** delete data from the table: "yield_sources" */
  delete_yield_sources?: Maybe<Yield_Sources_Mutation_Response>;
  /** delete single row from the table: "yield_sources" */
  delete_yield_sources_by_pk?: Maybe<Yield_Sources>;
  disconnectXmtpWallet: DisconnectXmtpWalletOutput;
  editCommunity: EditCommunityOutput;
  editCommunityCategory: EditCommunityCategoryOutput;
  editProfile?: Maybe<EditProfileOutput>;
  farcasterAuthExecute?: Maybe<FarcasterAuthExecuteOutput>;
  farcasterAuthInit?: Maybe<FarcasterAuthInitOutput>;
  handleReport?: Maybe<HandleReportOutput>;
  indexPowerBadgeUsers: IndexPowerBadgeUsersOutput;
  /** insert data into the table: "account_types" */
  insert_account_types?: Maybe<Account_Types_Mutation_Response>;
  /** insert a single row into the table: "account_types" */
  insert_account_types_one?: Maybe<Account_Types>;
  /** insert data into the table: "accounts" */
  insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "accounts" */
  insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "admin_portal_whitelist" */
  insert_admin_portal_whitelist?: Maybe<Admin_Portal_Whitelist_Mutation_Response>;
  /** insert a single row into the table: "admin_portal_whitelist" */
  insert_admin_portal_whitelist_one?: Maybe<Admin_Portal_Whitelist>;
  /** insert data into the table: "airdrop" */
  insert_airdrop?: Maybe<Airdrop_Mutation_Response>;
  /** insert a single row into the table: "airdrop" */
  insert_airdrop_one?: Maybe<Airdrop>;
  /** insert data into the table: "async_status" */
  insert_async_status?: Maybe<Async_Status_Mutation_Response>;
  /** insert a single row into the table: "async_status" */
  insert_async_status_one?: Maybe<Async_Status>;
  /** insert data into the table: "audios" */
  insert_audios?: Maybe<Audios_Mutation_Response>;
  /** insert a single row into the table: "audios" */
  insert_audios_one?: Maybe<Audios>;
  /** insert data into the table: "badge_types" */
  insert_badge_types?: Maybe<Badge_Types_Mutation_Response>;
  /** insert a single row into the table: "badge_types" */
  insert_badge_types_one?: Maybe<Badge_Types>;
  /** insert data into the table: "blocked_profiles" */
  insert_blocked_profiles?: Maybe<Blocked_Profiles_Mutation_Response>;
  /** insert a single row into the table: "blocked_profiles" */
  insert_blocked_profiles_one?: Maybe<Blocked_Profiles>;
  /** insert data into the table: "campaign_signatures" */
  insert_campaign_signatures?: Maybe<Campaign_Signatures_Mutation_Response>;
  /** insert a single row into the table: "campaign_signatures" */
  insert_campaign_signatures_one?: Maybe<Campaign_Signatures>;
  /** insert data into the table: "cc_profiles" */
  insert_cc_profiles?: Maybe<Cc_Profiles_Mutation_Response>;
  /** insert a single row into the table: "cc_profiles" */
  insert_cc_profiles_one?: Maybe<Cc_Profiles>;
  /** insert data into the table: "cities" */
  insert_cities?: Maybe<Cities_Mutation_Response>;
  /** insert a single row into the table: "cities" */
  insert_cities_one?: Maybe<Cities>;
  /** insert data into the table: "claimed_yield" */
  insert_claimed_yield?: Maybe<Claimed_Yield_Mutation_Response>;
  /** insert a single row into the table: "claimed_yield" */
  insert_claimed_yield_one?: Maybe<Claimed_Yield>;
  /** insert data into the table: "comment_aggregates" */
  insert_comment_aggregates?: Maybe<Comment_Aggregates_Mutation_Response>;
  /** insert a single row into the table: "comment_aggregates" */
  insert_comment_aggregates_one?: Maybe<Comment_Aggregates>;
  /** insert data into the table: "comment_profile_tags" */
  insert_comment_profile_tags?: Maybe<Comment_Profile_Tags_Mutation_Response>;
  /** insert a single row into the table: "comment_profile_tags" */
  insert_comment_profile_tags_one?: Maybe<Comment_Profile_Tags>;
  /** insert data into the table: "comment_votes" */
  insert_comment_votes?: Maybe<Comment_Votes_Mutation_Response>;
  /** insert a single row into the table: "comment_votes" */
  insert_comment_votes_one?: Maybe<Comment_Votes>;
  /** insert data into the table: "communities" */
  insert_communities?: Maybe<Communities_Mutation_Response>;
  /** insert a single row into the table: "communities" */
  insert_communities_one?: Maybe<Communities>;
  /** insert data into the table: "community_aggregates" */
  insert_community_aggregates?: Maybe<Community_Aggregates_Mutation_Response>;
  /** insert a single row into the table: "community_aggregates" */
  insert_community_aggregates_one?: Maybe<Community_Aggregates>;
  /** insert data into the table: "community_categories" */
  insert_community_categories?: Maybe<Community_Categories_Mutation_Response>;
  /** insert a single row into the table: "community_categories" */
  insert_community_categories_one?: Maybe<Community_Categories>;
  /** insert data into the table: "community_content_collections" */
  insert_community_content_collections?: Maybe<Community_Content_Collections_Mutation_Response>;
  /** insert a single row into the table: "community_content_collections" */
  insert_community_content_collections_one?: Maybe<Community_Content_Collections>;
  /** insert data into the table: "community_content_questions" */
  insert_community_content_questions?: Maybe<Community_Content_Questions_Mutation_Response>;
  /** insert a single row into the table: "community_content_questions" */
  insert_community_content_questions_one?: Maybe<Community_Content_Questions>;
  /** insert data into the table: "community_content_votes" */
  insert_community_content_votes?: Maybe<Community_Content_Votes_Mutation_Response>;
  /** insert a single row into the table: "community_content_votes" */
  insert_community_content_votes_one?: Maybe<Community_Content_Votes>;
  /** insert data into the table: "community_contents" */
  insert_community_contents?: Maybe<Community_Contents_Mutation_Response>;
  /** insert a single row into the table: "community_contents" */
  insert_community_contents_one?: Maybe<Community_Contents>;
  /** insert data into the table: "community_members" */
  insert_community_members?: Maybe<Community_Members_Mutation_Response>;
  /** insert a single row into the table: "community_members" */
  insert_community_members_one?: Maybe<Community_Members>;
  /** insert data into the table: "community_nft_projects" */
  insert_community_nft_projects?: Maybe<Community_Nft_Projects_Mutation_Response>;
  /** insert a single row into the table: "community_nft_projects" */
  insert_community_nft_projects_one?: Maybe<Community_Nft_Projects>;
  /** insert data into the table: "community_staffpicks" */
  insert_community_staffpicks?: Maybe<Community_Staffpicks_Mutation_Response>;
  /** insert a single row into the table: "community_staffpicks" */
  insert_community_staffpicks_one?: Maybe<Community_Staffpicks>;
  /** insert data into the table: "connected_nfts" */
  insert_connected_nfts?: Maybe<Connected_Nfts_Mutation_Response>;
  /** insert a single row into the table: "connected_nfts" */
  insert_connected_nfts_one?: Maybe<Connected_Nfts>;
  /** insert data into the table: "continents" */
  insert_continents?: Maybe<Continents_Mutation_Response>;
  /** insert a single row into the table: "continents" */
  insert_continents_one?: Maybe<Continents>;
  /** insert data into the table: "countries" */
  insert_countries?: Maybe<Countries_Mutation_Response>;
  /** insert a single row into the table: "countries" */
  insert_countries_one?: Maybe<Countries>;
  /** insert data into the table: "cred_scores" */
  insert_cred_scores?: Maybe<Cred_Scores_Mutation_Response>;
  /** insert a single row into the table: "cred_scores" */
  insert_cred_scores_one?: Maybe<Cred_Scores>;
  /** insert data into the table: "currency_isocodes" */
  insert_currency_isocodes?: Maybe<Currency_Isocodes_Mutation_Response>;
  /** insert a single row into the table: "currency_isocodes" */
  insert_currency_isocodes_one?: Maybe<Currency_Isocodes>;
  /** insert data into the table: "daily_staking" */
  insert_daily_staking?: Maybe<Daily_Staking_Mutation_Response>;
  /** insert a single row into the table: "daily_staking" */
  insert_daily_staking_one?: Maybe<Daily_Staking>;
  /** insert data into the table: "embedded_urls" */
  insert_embedded_urls?: Maybe<Embedded_Urls_Mutation_Response>;
  /** insert a single row into the table: "embedded_urls" */
  insert_embedded_urls_one?: Maybe<Embedded_Urls>;
  /** insert data into the table: "farcaster_casts" */
  insert_farcaster_casts?: Maybe<Farcaster_Casts_Mutation_Response>;
  /** insert a single row into the table: "farcaster_casts" */
  insert_farcaster_casts_one?: Maybe<Farcaster_Casts>;
  /** insert data into the table: "farcaster_invites" */
  insert_farcaster_invites?: Maybe<Farcaster_Invites_Mutation_Response>;
  /** insert a single row into the table: "farcaster_invites" */
  insert_farcaster_invites_one?: Maybe<Farcaster_Invites>;
  /** insert data into the table: "farcaster_power_badge_users" */
  insert_farcaster_power_badge_users?: Maybe<Farcaster_Power_Badge_Users_Mutation_Response>;
  /** insert a single row into the table: "farcaster_power_badge_users" */
  insert_farcaster_power_badge_users_one?: Maybe<Farcaster_Power_Badge_Users>;
  /** insert data into the table: "farcaster_profiles" */
  insert_farcaster_profiles?: Maybe<Farcaster_Profiles_Mutation_Response>;
  /** insert a single row into the table: "farcaster_profiles" */
  insert_farcaster_profiles_one?: Maybe<Farcaster_Profiles>;
  /** insert data into the table: "farcaster_reactions" */
  insert_farcaster_reactions?: Maybe<Farcaster_Reactions_Mutation_Response>;
  /** insert a single row into the table: "farcaster_reactions" */
  insert_farcaster_reactions_one?: Maybe<Farcaster_Reactions>;
  /** insert data into the table: "farcaster_signers" */
  insert_farcaster_signers?: Maybe<Farcaster_Signers_Mutation_Response>;
  /** insert a single row into the table: "farcaster_signers" */
  insert_farcaster_signers_one?: Maybe<Farcaster_Signers>;
  /** insert data into the table: "flag_types" */
  insert_flag_types?: Maybe<Flag_Types_Mutation_Response>;
  /** insert a single row into the table: "flag_types" */
  insert_flag_types_one?: Maybe<Flag_Types>;
  /** insert data into the table: "flags" */
  insert_flags?: Maybe<Flags_Mutation_Response>;
  /** insert a single row into the table: "flags" */
  insert_flags_one?: Maybe<Flags>;
  /** insert data into the table: "followers" */
  insert_followers?: Maybe<Followers_Mutation_Response>;
  /** insert a single row into the table: "followers" */
  insert_followers_one?: Maybe<Followers>;
  /** insert data into the table: "frame_action" */
  insert_frame_action?: Maybe<Frame_Action_Mutation_Response>;
  /** insert a single row into the table: "frame_action" */
  insert_frame_action_one?: Maybe<Frame_Action>;
  /** insert data into the table: "frame_buttons" */
  insert_frame_buttons?: Maybe<Frame_Buttons_Mutation_Response>;
  /** insert a single row into the table: "frame_buttons" */
  insert_frame_buttons_one?: Maybe<Frame_Buttons>;
  /** insert data into the table: "frames" */
  insert_frames?: Maybe<Frames_Mutation_Response>;
  /** insert a single row into the table: "frames" */
  insert_frames_one?: Maybe<Frames>;
  /** insert data into the table: "images" */
  insert_images?: Maybe<Images_Mutation_Response>;
  /** insert a single row into the table: "images" */
  insert_images_one?: Maybe<Images>;
  /** insert data into the table: "item_others" */
  insert_item_others?: Maybe<Item_Others_Mutation_Response>;
  /** insert a single row into the table: "item_others" */
  insert_item_others_one?: Maybe<Item_Others>;
  /** insert data into the table: "item_types" */
  insert_item_types?: Maybe<Item_Types_Mutation_Response>;
  /** insert a single row into the table: "item_types" */
  insert_item_types_one?: Maybe<Item_Types>;
  /** insert data into the table: "items" */
  insert_items?: Maybe<Items_Mutation_Response>;
  /** insert a single row into the table: "items" */
  insert_items_one?: Maybe<Items>;
  /** insert data into the table: "knex_migrations" */
  insert_knex_migrations?: Maybe<Knex_Migrations_Mutation_Response>;
  /** insert data into the table: "knex_migrations_lock" */
  insert_knex_migrations_lock?: Maybe<Knex_Migrations_Lock_Mutation_Response>;
  /** insert a single row into the table: "knex_migrations_lock" */
  insert_knex_migrations_lock_one?: Maybe<Knex_Migrations_Lock>;
  /** insert a single row into the table: "knex_migrations" */
  insert_knex_migrations_one?: Maybe<Knex_Migrations>;
  /** insert data into the table: "language_isocodes" */
  insert_language_isocodes?: Maybe<Language_Isocodes_Mutation_Response>;
  /** insert a single row into the table: "language_isocodes" */
  insert_language_isocodes_one?: Maybe<Language_Isocodes>;
  /** insert data into the table: "lens_api_access_tokens" */
  insert_lens_api_access_tokens?: Maybe<Lens_Api_Access_Tokens_Mutation_Response>;
  /** insert a single row into the table: "lens_api_access_tokens" */
  insert_lens_api_access_tokens_one?: Maybe<Lens_Api_Access_Tokens>;
  /** insert data into the table: "lens_api_refresh_tokens" */
  insert_lens_api_refresh_tokens?: Maybe<Lens_Api_Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "lens_api_refresh_tokens" */
  insert_lens_api_refresh_tokens_one?: Maybe<Lens_Api_Refresh_Tokens>;
  /** insert data into the table: "lens_appid_mappings" */
  insert_lens_appid_mappings?: Maybe<Lens_Appid_Mappings_Mutation_Response>;
  /** insert a single row into the table: "lens_appid_mappings" */
  insert_lens_appid_mappings_one?: Maybe<Lens_Appid_Mappings>;
  /** insert data into the table: "lens_collects" */
  insert_lens_collects?: Maybe<Lens_Collects_Mutation_Response>;
  /** insert a single row into the table: "lens_collects" */
  insert_lens_collects_one?: Maybe<Lens_Collects>;
  /** insert data into the table: "lens_custodial_accounts" */
  insert_lens_custodial_accounts?: Maybe<Lens_Custodial_Accounts_Mutation_Response>;
  /** insert a single row into the table: "lens_custodial_accounts" */
  insert_lens_custodial_accounts_one?: Maybe<Lens_Custodial_Accounts>;
  /** insert data into the table: "lens_events" */
  insert_lens_events?: Maybe<Lens_Events_Mutation_Response>;
  /** insert a single row into the table: "lens_events" */
  insert_lens_events_one?: Maybe<Lens_Events>;
  /** insert data into the table: "lens_followers" */
  insert_lens_followers?: Maybe<Lens_Followers_Mutation_Response>;
  /** insert a single row into the table: "lens_followers" */
  insert_lens_followers_one?: Maybe<Lens_Followers>;
  /** insert data into the table: "lens_followers_v2" */
  insert_lens_followers_v2?: Maybe<Lens_Followers_V2_Mutation_Response>;
  /** insert a single row into the table: "lens_followers_v2" */
  insert_lens_followers_v2_one?: Maybe<Lens_Followers_V2>;
  /** insert data into the table: "lens_indexer" */
  insert_lens_indexer?: Maybe<Lens_Indexer_Mutation_Response>;
  /** insert a single row into the table: "lens_indexer" */
  insert_lens_indexer_one?: Maybe<Lens_Indexer>;
  /** insert data into the table: "lens_invites" */
  insert_lens_invites?: Maybe<Lens_Invites_Mutation_Response>;
  /** insert a single row into the table: "lens_invites" */
  insert_lens_invites_one?: Maybe<Lens_Invites>;
  /** insert data into the table: "lens_post_lens_pub_types" */
  insert_lens_post_lens_pub_types?: Maybe<Lens_Post_Lens_Pub_Types_Mutation_Response>;
  /** insert a single row into the table: "lens_post_lens_pub_types" */
  insert_lens_post_lens_pub_types_one?: Maybe<Lens_Post_Lens_Pub_Types>;
  /** insert data into the table: "lens_posts" */
  insert_lens_posts?: Maybe<Lens_Posts_Mutation_Response>;
  /** insert a single row into the table: "lens_posts" */
  insert_lens_posts_one?: Maybe<Lens_Posts>;
  /** insert data into the table: "lens_profiles" */
  insert_lens_profiles?: Maybe<Lens_Profiles_Mutation_Response>;
  /** insert a single row into the table: "lens_profiles" */
  insert_lens_profiles_one?: Maybe<Lens_Profiles>;
  /** insert data into the table: "lens_reaction_types" */
  insert_lens_reaction_types?: Maybe<Lens_Reaction_Types_Mutation_Response>;
  /** insert a single row into the table: "lens_reaction_types" */
  insert_lens_reaction_types_one?: Maybe<Lens_Reaction_Types>;
  /** insert data into the table: "lens_reactions" */
  insert_lens_reactions?: Maybe<Lens_Reactions_Mutation_Response>;
  /** insert a single row into the table: "lens_reactions" */
  insert_lens_reactions_one?: Maybe<Lens_Reactions>;
  /** insert data into the table: "lens_tx_status_enum" */
  insert_lens_tx_status_enum?: Maybe<Lens_Tx_Status_Enum_Mutation_Response>;
  /** insert a single row into the table: "lens_tx_status_enum" */
  insert_lens_tx_status_enum_one?: Maybe<Lens_Tx_Status_Enum>;
  /** insert data into the table: "levels" */
  insert_levels?: Maybe<Levels_Mutation_Response>;
  /** insert a single row into the table: "levels" */
  insert_levels_one?: Maybe<Levels>;
  /** insert data into the table: "links" */
  insert_links?: Maybe<Links_Mutation_Response>;
  /** insert a single row into the table: "links" */
  insert_links_one?: Maybe<Links>;
  /** insert data into the table: "locked_balances" */
  insert_locked_balances?: Maybe<Locked_Balances_Mutation_Response>;
  /** insert a single row into the table: "locked_balances" */
  insert_locked_balances_one?: Maybe<Locked_Balances>;
  /** insert data into the table: "medias" */
  insert_medias?: Maybe<Medias_Mutation_Response>;
  /** insert a single row into the table: "medias" */
  insert_medias_one?: Maybe<Medias>;
  /** insert data into the table: "moca_ids" */
  insert_moca_ids?: Maybe<Moca_Ids_Mutation_Response>;
  /** insert a single row into the table: "moca_ids" */
  insert_moca_ids_one?: Maybe<Moca_Ids>;
  /** insert data into the table: "notification_setting_types" */
  insert_notification_setting_types?: Maybe<Notification_Setting_Types_Mutation_Response>;
  /** insert a single row into the table: "notification_setting_types" */
  insert_notification_setting_types_one?: Maybe<Notification_Setting_Types>;
  /** insert data into the table: "notification_settings" */
  insert_notification_settings?: Maybe<Notification_Settings_Mutation_Response>;
  /** insert a single row into the table: "notification_settings" */
  insert_notification_settings_one?: Maybe<Notification_Settings>;
  /** insert data into the table: "perks" */
  insert_perks?: Maybe<Perks_Mutation_Response>;
  /** insert data into the table: "perks_claimed" */
  insert_perks_claimed?: Maybe<Perks_Claimed_Mutation_Response>;
  /** insert a single row into the table: "perks_claimed" */
  insert_perks_claimed_one?: Maybe<Perks_Claimed>;
  /** insert a single row into the table: "perks" */
  insert_perks_one?: Maybe<Perks>;
  /** insert data into the table: "post_aggregates" */
  insert_post_aggregates?: Maybe<Post_Aggregates_Mutation_Response>;
  /** insert a single row into the table: "post_aggregates" */
  insert_post_aggregates_one?: Maybe<Post_Aggregates>;
  /** insert data into the table: "post_profile_tags" */
  insert_post_profile_tags?: Maybe<Post_Profile_Tags_Mutation_Response>;
  /** insert a single row into the table: "post_profile_tags" */
  insert_post_profile_tags_one?: Maybe<Post_Profile_Tags>;
  /** insert data into the table: "post_reaction_types" */
  insert_post_reaction_types?: Maybe<Post_Reaction_Types_Mutation_Response>;
  /** insert a single row into the table: "post_reaction_types" */
  insert_post_reaction_types_one?: Maybe<Post_Reaction_Types>;
  /** insert data into the table: "post_reactions" */
  insert_post_reactions?: Maybe<Post_Reactions_Mutation_Response>;
  /** insert a single row into the table: "post_reactions" */
  insert_post_reactions_one?: Maybe<Post_Reactions>;
  /** insert data into the table: "profile_aggregates" */
  insert_profile_aggregates?: Maybe<Profile_Aggregates_Mutation_Response>;
  /** insert a single row into the table: "profile_aggregates" */
  insert_profile_aggregates_one?: Maybe<Profile_Aggregates>;
  /** insert data into the table: "profiles" */
  insert_profiles?: Maybe<Profiles_Mutation_Response>;
  /** insert a single row into the table: "profiles" */
  insert_profiles_one?: Maybe<Profiles>;
  /** insert data into the table: "push_tokens" */
  insert_push_tokens?: Maybe<Push_Tokens_Mutation_Response>;
  /** insert a single row into the table: "push_tokens" */
  insert_push_tokens_one?: Maybe<Push_Tokens>;
  /** insert data into the table: "recommendation_comments" */
  insert_recommendation_comments?: Maybe<Recommendation_Comments_Mutation_Response>;
  /** insert a single row into the table: "recommendation_comments" */
  insert_recommendation_comments_one?: Maybe<Recommendation_Comments>;
  /** insert data into the table: "recommendation_votes" */
  insert_recommendation_votes?: Maybe<Recommendation_Votes_Mutation_Response>;
  /** insert a single row into the table: "recommendation_votes" */
  insert_recommendation_votes_one?: Maybe<Recommendation_Votes>;
  /** insert data into the table: "recommendations" */
  insert_recommendations?: Maybe<Recommendations_Mutation_Response>;
  /** insert a single row into the table: "recommendations" */
  insert_recommendations_one?: Maybe<Recommendations>;
  /** insert data into the table: "reported_content" */
  insert_reported_content?: Maybe<Reported_Content_Mutation_Response>;
  /** insert a single row into the table: "reported_content" */
  insert_reported_content_one?: Maybe<Reported_Content>;
  /** insert data into the table: "reward_brands" */
  insert_reward_brands?: Maybe<Reward_Brands_Mutation_Response>;
  /** insert a single row into the table: "reward_brands" */
  insert_reward_brands_one?: Maybe<Reward_Brands>;
  /** insert data into the table: "reward_claims" */
  insert_reward_claims?: Maybe<Reward_Claims_Mutation_Response>;
  /** insert a single row into the table: "reward_claims" */
  insert_reward_claims_one?: Maybe<Reward_Claims>;
  /** insert data into the table: "reward_types" */
  insert_reward_types?: Maybe<Reward_Types_Mutation_Response>;
  /** insert a single row into the table: "reward_types" */
  insert_reward_types_one?: Maybe<Reward_Types>;
  /** insert data into the table: "role_types" */
  insert_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** insert a single row into the table: "role_types" */
  insert_role_types_one?: Maybe<Role_Types>;
  /** insert data into the table: "rss_feeds" */
  insert_rss_feeds?: Maybe<Rss_Feeds_Mutation_Response>;
  /** insert a single row into the table: "rss_feeds" */
  insert_rss_feeds_one?: Maybe<Rss_Feeds>;
  /** insert data into the table: "staking_reward_posters" */
  insert_staking_reward_posters?: Maybe<Staking_Reward_Posters_Mutation_Response>;
  /** insert a single row into the table: "staking_reward_posters" */
  insert_staking_reward_posters_one?: Maybe<Staking_Reward_Posters>;
  /** insert data into the table: "staking_reward_stakers" */
  insert_staking_reward_stakers?: Maybe<Staking_Reward_Stakers_Mutation_Response>;
  /** insert a single row into the table: "staking_reward_stakers" */
  insert_staking_reward_stakers_one?: Maybe<Staking_Reward_Stakers>;
  /** insert data into the table: "subdivisions" */
  insert_subdivisions?: Maybe<Subdivisions_Mutation_Response>;
  /** insert a single row into the table: "subdivisions" */
  insert_subdivisions_one?: Maybe<Subdivisions>;
  /** insert data into the table: "task_sections" */
  insert_task_sections?: Maybe<Task_Sections_Mutation_Response>;
  /** insert a single row into the table: "task_sections" */
  insert_task_sections_one?: Maybe<Task_Sections>;
  /** insert data into the table: "task_types" */
  insert_task_types?: Maybe<Task_Types_Mutation_Response>;
  /** insert a single row into the table: "task_types" */
  insert_task_types_one?: Maybe<Task_Types>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert data into the table: "tasks_done" */
  insert_tasks_done?: Maybe<Tasks_Done_Mutation_Response>;
  /** insert a single row into the table: "tasks_done" */
  insert_tasks_done_one?: Maybe<Tasks_Done>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "verification_types" */
  insert_verification_types?: Maybe<Verification_Types_Mutation_Response>;
  /** insert a single row into the table: "verification_types" */
  insert_verification_types_one?: Maybe<Verification_Types>;
  /** insert data into the table: "verified_nft_project_kinds" */
  insert_verified_nft_project_kinds?: Maybe<Verified_Nft_Project_Kinds_Mutation_Response>;
  /** insert a single row into the table: "verified_nft_project_kinds" */
  insert_verified_nft_project_kinds_one?: Maybe<Verified_Nft_Project_Kinds>;
  /** insert data into the table: "verified_nft_projects" */
  insert_verified_nft_projects?: Maybe<Verified_Nft_Projects_Mutation_Response>;
  /** insert a single row into the table: "verified_nft_projects" */
  insert_verified_nft_projects_one?: Maybe<Verified_Nft_Projects>;
  /** insert data into the table: "videos" */
  insert_videos?: Maybe<Videos_Mutation_Response>;
  /** insert a single row into the table: "videos" */
  insert_videos_one?: Maybe<Videos>;
  /** insert data into the table: "wallets" */
  insert_wallets?: Maybe<Wallets_Mutation_Response>;
  /** insert a single row into the table: "wallets" */
  insert_wallets_one?: Maybe<Wallets>;
  /** insert data into the table: "wallets_resolves" */
  insert_wallets_resolves?: Maybe<Wallets_Resolves_Mutation_Response>;
  /** insert a single row into the table: "wallets_resolves" */
  insert_wallets_resolves_one?: Maybe<Wallets_Resolves>;
  /** insert data into the table: "yield_sources" */
  insert_yield_sources?: Maybe<Yield_Sources_Mutation_Response>;
  /** insert a single row into the table: "yield_sources" */
  insert_yield_sources_one?: Maybe<Yield_Sources>;
  joinCommunity?: Maybe<JoinCommunityOutput>;
  leaveCommunity?: Maybe<LeaveCommunityOutput>;
  lensApiAuthenticate: LensApiAuthenticateOutput;
  lensCreateProfile?: Maybe<LensCreateProfileOutput>;
  lensSetDispatcherExecute: LensSetDispatcherExecuteOutput;
  /** mocaConnect */
  mocaConnect?: Maybe<MocaConnectOutput>;
  participateCampaignWithSig: ParticipateCampaignWithSigOutput;
  perkClaim?: Maybe<PerkClaimOutput>;
  perkDelete?: Maybe<PerkDeleteOutput>;
  perkUpsert?: Maybe<PerkUpsertOutput>;
  pointDrop?: Maybe<PointDropResult>;
  pointEvent?: Maybe<PointEventResult>;
  /** postFrameAction */
  postFrameAction?: Maybe<FrameActionOutput>;
  postReaction: PostReactionResult;
  refetchFrame?: Maybe<RefetchFrameOutput>;
  /** Admin action to fetch all currently missing Farcaster names from Airstack with option to fetch all instead of time limit clause. */
  refetchMissingFarcasterNames?: Maybe<RefetchMissingFarcasterNamesOutput>;
  refetchMissingNftImages: RefetchMissingNftImagesResult;
  /** Used to refresh selected materialized view */
  refreshMaterialView?: Maybe<RefreshMaterialViewOutput>;
  retryFarcasterComment?: Maybe<RetryCommentOutput>;
  retryLensComment?: Maybe<RetryCommentOutput>;
  rewardRedeem?: Maybe<RewardRedeemResult>;
  setBlock: SetBlockOutput;
  /** setCommunityModerators */
  setCommunityModerators?: Maybe<SetCommunityModeratorsOutput>;
  setFollow: SetFollowOutput;
  setFren?: Maybe<SetFrenOutput>;
  setLevel?: Maybe<SetLevelOutput>;
  setPrimaryWallet: SetPrimaryWalletOutput;
  setProfileBadge?: Maybe<SetProfileBadgeOutput>;
  setProfileVerification?: Maybe<SetProfileVerificationOutput>;
  setVerified?: Maybe<SetVerifiedOutput>;
  setXmtpWallet: SetXmtpWalletOutput;
  shadowBanTopic?: Maybe<ShadowBanTopicOutput>;
  /** deprecated by banUser action, only for backwards compatibilty */
  shadowBanUser?: Maybe<ShadowBanUserOutput>;
  squid?: Maybe<Squid_Mutation_Frontend>;
  syncToken?: Maybe<SyncTokenResult>;
  /** syncUserNFTs */
  syncUserNFTs: SyncUserNfTsOutput;
  tempDevLoadImagesForFacasterProfiles?: Maybe<TempDevLoadImagesForFacasterProfilesOutput>;
  timeoutUser?: Maybe<TimeoutUserOutput>;
  togglePinPost?: Maybe<TogglePinPostOutput>;
  translateComment?: Maybe<TranslateCommentOutput>;
  /** togglePinPost */
  translatePost?: Maybe<TranslatePostOutput>;
  triggerTaskDone?: Maybe<TriggerTaskDoneOutput>;
  updatePost: UpdatePostResult;
  /** update data of the table: "account_types" */
  update_account_types?: Maybe<Account_Types_Mutation_Response>;
  /** update single row of the table: "account_types" */
  update_account_types_by_pk?: Maybe<Account_Types>;
  /** update multiples rows of table: "account_types" */
  update_account_types_many?: Maybe<Array<Maybe<Account_Types_Mutation_Response>>>;
  /** update data of the table: "accounts" */
  update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  update_accounts_by_pk?: Maybe<Accounts>;
  /** update multiples rows of table: "accounts" */
  update_accounts_many?: Maybe<Array<Maybe<Accounts_Mutation_Response>>>;
  /** update data of the table: "admin_portal_whitelist" */
  update_admin_portal_whitelist?: Maybe<Admin_Portal_Whitelist_Mutation_Response>;
  /** update single row of the table: "admin_portal_whitelist" */
  update_admin_portal_whitelist_by_pk?: Maybe<Admin_Portal_Whitelist>;
  /** update multiples rows of table: "admin_portal_whitelist" */
  update_admin_portal_whitelist_many?: Maybe<Array<Maybe<Admin_Portal_Whitelist_Mutation_Response>>>;
  /** update data of the table: "airdrop" */
  update_airdrop?: Maybe<Airdrop_Mutation_Response>;
  /** update single row of the table: "airdrop" */
  update_airdrop_by_pk?: Maybe<Airdrop>;
  /** update multiples rows of table: "airdrop" */
  update_airdrop_many?: Maybe<Array<Maybe<Airdrop_Mutation_Response>>>;
  /** update data of the table: "async_status" */
  update_async_status?: Maybe<Async_Status_Mutation_Response>;
  /** update single row of the table: "async_status" */
  update_async_status_by_pk?: Maybe<Async_Status>;
  /** update multiples rows of table: "async_status" */
  update_async_status_many?: Maybe<Array<Maybe<Async_Status_Mutation_Response>>>;
  /** update data of the table: "audios" */
  update_audios?: Maybe<Audios_Mutation_Response>;
  /** update single row of the table: "audios" */
  update_audios_by_pk?: Maybe<Audios>;
  /** update multiples rows of table: "audios" */
  update_audios_many?: Maybe<Array<Maybe<Audios_Mutation_Response>>>;
  /** update data of the table: "badge_types" */
  update_badge_types?: Maybe<Badge_Types_Mutation_Response>;
  /** update single row of the table: "badge_types" */
  update_badge_types_by_pk?: Maybe<Badge_Types>;
  /** update multiples rows of table: "badge_types" */
  update_badge_types_many?: Maybe<Array<Maybe<Badge_Types_Mutation_Response>>>;
  /** update data of the table: "blocked_profiles" */
  update_blocked_profiles?: Maybe<Blocked_Profiles_Mutation_Response>;
  /** update single row of the table: "blocked_profiles" */
  update_blocked_profiles_by_pk?: Maybe<Blocked_Profiles>;
  /** update multiples rows of table: "blocked_profiles" */
  update_blocked_profiles_many?: Maybe<Array<Maybe<Blocked_Profiles_Mutation_Response>>>;
  /** update data of the table: "campaign_signatures" */
  update_campaign_signatures?: Maybe<Campaign_Signatures_Mutation_Response>;
  /** update single row of the table: "campaign_signatures" */
  update_campaign_signatures_by_pk?: Maybe<Campaign_Signatures>;
  /** update multiples rows of table: "campaign_signatures" */
  update_campaign_signatures_many?: Maybe<Array<Maybe<Campaign_Signatures_Mutation_Response>>>;
  /** update data of the table: "cc_profiles" */
  update_cc_profiles?: Maybe<Cc_Profiles_Mutation_Response>;
  /** update single row of the table: "cc_profiles" */
  update_cc_profiles_by_pk?: Maybe<Cc_Profiles>;
  /** update multiples rows of table: "cc_profiles" */
  update_cc_profiles_many?: Maybe<Array<Maybe<Cc_Profiles_Mutation_Response>>>;
  /** update data of the table: "cities" */
  update_cities?: Maybe<Cities_Mutation_Response>;
  /** update single row of the table: "cities" */
  update_cities_by_pk?: Maybe<Cities>;
  /** update multiples rows of table: "cities" */
  update_cities_many?: Maybe<Array<Maybe<Cities_Mutation_Response>>>;
  /** update data of the table: "claimed_yield" */
  update_claimed_yield?: Maybe<Claimed_Yield_Mutation_Response>;
  /** update single row of the table: "claimed_yield" */
  update_claimed_yield_by_pk?: Maybe<Claimed_Yield>;
  /** update multiples rows of table: "claimed_yield" */
  update_claimed_yield_many?: Maybe<Array<Maybe<Claimed_Yield_Mutation_Response>>>;
  /** update data of the table: "comment_aggregates" */
  update_comment_aggregates?: Maybe<Comment_Aggregates_Mutation_Response>;
  /** update single row of the table: "comment_aggregates" */
  update_comment_aggregates_by_pk?: Maybe<Comment_Aggregates>;
  /** update multiples rows of table: "comment_aggregates" */
  update_comment_aggregates_many?: Maybe<Array<Maybe<Comment_Aggregates_Mutation_Response>>>;
  /** update data of the table: "comment_profile_tags" */
  update_comment_profile_tags?: Maybe<Comment_Profile_Tags_Mutation_Response>;
  /** update single row of the table: "comment_profile_tags" */
  update_comment_profile_tags_by_pk?: Maybe<Comment_Profile_Tags>;
  /** update multiples rows of table: "comment_profile_tags" */
  update_comment_profile_tags_many?: Maybe<Array<Maybe<Comment_Profile_Tags_Mutation_Response>>>;
  /** update data of the table: "comment_votes" */
  update_comment_votes?: Maybe<Comment_Votes_Mutation_Response>;
  /** update single row of the table: "comment_votes" */
  update_comment_votes_by_pk?: Maybe<Comment_Votes>;
  /** update multiples rows of table: "comment_votes" */
  update_comment_votes_many?: Maybe<Array<Maybe<Comment_Votes_Mutation_Response>>>;
  /** update data of the table: "communities" */
  update_communities?: Maybe<Communities_Mutation_Response>;
  /** update single row of the table: "communities" */
  update_communities_by_pk?: Maybe<Communities>;
  /** update multiples rows of table: "communities" */
  update_communities_many?: Maybe<Array<Maybe<Communities_Mutation_Response>>>;
  /** update data of the table: "community_aggregates" */
  update_community_aggregates?: Maybe<Community_Aggregates_Mutation_Response>;
  /** update single row of the table: "community_aggregates" */
  update_community_aggregates_by_pk?: Maybe<Community_Aggregates>;
  /** update multiples rows of table: "community_aggregates" */
  update_community_aggregates_many?: Maybe<Array<Maybe<Community_Aggregates_Mutation_Response>>>;
  /** update data of the table: "community_categories" */
  update_community_categories?: Maybe<Community_Categories_Mutation_Response>;
  /** update single row of the table: "community_categories" */
  update_community_categories_by_pk?: Maybe<Community_Categories>;
  /** update multiples rows of table: "community_categories" */
  update_community_categories_many?: Maybe<Array<Maybe<Community_Categories_Mutation_Response>>>;
  /** update data of the table: "community_content_collections" */
  update_community_content_collections?: Maybe<Community_Content_Collections_Mutation_Response>;
  /** update single row of the table: "community_content_collections" */
  update_community_content_collections_by_pk?: Maybe<Community_Content_Collections>;
  /** update multiples rows of table: "community_content_collections" */
  update_community_content_collections_many?: Maybe<Array<Maybe<Community_Content_Collections_Mutation_Response>>>;
  /** update data of the table: "community_content_questions" */
  update_community_content_questions?: Maybe<Community_Content_Questions_Mutation_Response>;
  /** update single row of the table: "community_content_questions" */
  update_community_content_questions_by_pk?: Maybe<Community_Content_Questions>;
  /** update multiples rows of table: "community_content_questions" */
  update_community_content_questions_many?: Maybe<Array<Maybe<Community_Content_Questions_Mutation_Response>>>;
  /** update data of the table: "community_content_votes" */
  update_community_content_votes?: Maybe<Community_Content_Votes_Mutation_Response>;
  /** update single row of the table: "community_content_votes" */
  update_community_content_votes_by_pk?: Maybe<Community_Content_Votes>;
  /** update multiples rows of table: "community_content_votes" */
  update_community_content_votes_many?: Maybe<Array<Maybe<Community_Content_Votes_Mutation_Response>>>;
  /** update data of the table: "community_contents" */
  update_community_contents?: Maybe<Community_Contents_Mutation_Response>;
  /** update single row of the table: "community_contents" */
  update_community_contents_by_pk?: Maybe<Community_Contents>;
  /** update multiples rows of table: "community_contents" */
  update_community_contents_many?: Maybe<Array<Maybe<Community_Contents_Mutation_Response>>>;
  /** update data of the table: "community_members" */
  update_community_members?: Maybe<Community_Members_Mutation_Response>;
  /** update single row of the table: "community_members" */
  update_community_members_by_pk?: Maybe<Community_Members>;
  /** update multiples rows of table: "community_members" */
  update_community_members_many?: Maybe<Array<Maybe<Community_Members_Mutation_Response>>>;
  /** update data of the table: "community_nft_projects" */
  update_community_nft_projects?: Maybe<Community_Nft_Projects_Mutation_Response>;
  /** update single row of the table: "community_nft_projects" */
  update_community_nft_projects_by_pk?: Maybe<Community_Nft_Projects>;
  /** update multiples rows of table: "community_nft_projects" */
  update_community_nft_projects_many?: Maybe<Array<Maybe<Community_Nft_Projects_Mutation_Response>>>;
  /** update data of the table: "community_staffpicks" */
  update_community_staffpicks?: Maybe<Community_Staffpicks_Mutation_Response>;
  /** update single row of the table: "community_staffpicks" */
  update_community_staffpicks_by_pk?: Maybe<Community_Staffpicks>;
  /** update multiples rows of table: "community_staffpicks" */
  update_community_staffpicks_many?: Maybe<Array<Maybe<Community_Staffpicks_Mutation_Response>>>;
  /** update data of the table: "connected_nfts" */
  update_connected_nfts?: Maybe<Connected_Nfts_Mutation_Response>;
  /** update single row of the table: "connected_nfts" */
  update_connected_nfts_by_pk?: Maybe<Connected_Nfts>;
  /** update multiples rows of table: "connected_nfts" */
  update_connected_nfts_many?: Maybe<Array<Maybe<Connected_Nfts_Mutation_Response>>>;
  /** update data of the table: "continents" */
  update_continents?: Maybe<Continents_Mutation_Response>;
  /** update single row of the table: "continents" */
  update_continents_by_pk?: Maybe<Continents>;
  /** update multiples rows of table: "continents" */
  update_continents_many?: Maybe<Array<Maybe<Continents_Mutation_Response>>>;
  /** update data of the table: "countries" */
  update_countries?: Maybe<Countries_Mutation_Response>;
  /** update single row of the table: "countries" */
  update_countries_by_pk?: Maybe<Countries>;
  /** update multiples rows of table: "countries" */
  update_countries_many?: Maybe<Array<Maybe<Countries_Mutation_Response>>>;
  /** update data of the table: "cred_scores" */
  update_cred_scores?: Maybe<Cred_Scores_Mutation_Response>;
  /** update single row of the table: "cred_scores" */
  update_cred_scores_by_pk?: Maybe<Cred_Scores>;
  /** update multiples rows of table: "cred_scores" */
  update_cred_scores_many?: Maybe<Array<Maybe<Cred_Scores_Mutation_Response>>>;
  /** update data of the table: "currency_isocodes" */
  update_currency_isocodes?: Maybe<Currency_Isocodes_Mutation_Response>;
  /** update single row of the table: "currency_isocodes" */
  update_currency_isocodes_by_pk?: Maybe<Currency_Isocodes>;
  /** update multiples rows of table: "currency_isocodes" */
  update_currency_isocodes_many?: Maybe<Array<Maybe<Currency_Isocodes_Mutation_Response>>>;
  /** update data of the table: "daily_staking" */
  update_daily_staking?: Maybe<Daily_Staking_Mutation_Response>;
  /** update single row of the table: "daily_staking" */
  update_daily_staking_by_pk?: Maybe<Daily_Staking>;
  /** update multiples rows of table: "daily_staking" */
  update_daily_staking_many?: Maybe<Array<Maybe<Daily_Staking_Mutation_Response>>>;
  /** update data of the table: "embedded_urls" */
  update_embedded_urls?: Maybe<Embedded_Urls_Mutation_Response>;
  /** update single row of the table: "embedded_urls" */
  update_embedded_urls_by_pk?: Maybe<Embedded_Urls>;
  /** update multiples rows of table: "embedded_urls" */
  update_embedded_urls_many?: Maybe<Array<Maybe<Embedded_Urls_Mutation_Response>>>;
  /** update data of the table: "farcaster_casts" */
  update_farcaster_casts?: Maybe<Farcaster_Casts_Mutation_Response>;
  /** update single row of the table: "farcaster_casts" */
  update_farcaster_casts_by_pk?: Maybe<Farcaster_Casts>;
  /** update multiples rows of table: "farcaster_casts" */
  update_farcaster_casts_many?: Maybe<Array<Maybe<Farcaster_Casts_Mutation_Response>>>;
  /** update data of the table: "farcaster_invites" */
  update_farcaster_invites?: Maybe<Farcaster_Invites_Mutation_Response>;
  /** update single row of the table: "farcaster_invites" */
  update_farcaster_invites_by_pk?: Maybe<Farcaster_Invites>;
  /** update multiples rows of table: "farcaster_invites" */
  update_farcaster_invites_many?: Maybe<Array<Maybe<Farcaster_Invites_Mutation_Response>>>;
  /** update data of the table: "farcaster_power_badge_users" */
  update_farcaster_power_badge_users?: Maybe<Farcaster_Power_Badge_Users_Mutation_Response>;
  /** update single row of the table: "farcaster_power_badge_users" */
  update_farcaster_power_badge_users_by_pk?: Maybe<Farcaster_Power_Badge_Users>;
  /** update multiples rows of table: "farcaster_power_badge_users" */
  update_farcaster_power_badge_users_many?: Maybe<Array<Maybe<Farcaster_Power_Badge_Users_Mutation_Response>>>;
  /** update data of the table: "farcaster_profiles" */
  update_farcaster_profiles?: Maybe<Farcaster_Profiles_Mutation_Response>;
  /** update single row of the table: "farcaster_profiles" */
  update_farcaster_profiles_by_pk?: Maybe<Farcaster_Profiles>;
  /** update multiples rows of table: "farcaster_profiles" */
  update_farcaster_profiles_many?: Maybe<Array<Maybe<Farcaster_Profiles_Mutation_Response>>>;
  /** update data of the table: "farcaster_reactions" */
  update_farcaster_reactions?: Maybe<Farcaster_Reactions_Mutation_Response>;
  /** update single row of the table: "farcaster_reactions" */
  update_farcaster_reactions_by_pk?: Maybe<Farcaster_Reactions>;
  /** update multiples rows of table: "farcaster_reactions" */
  update_farcaster_reactions_many?: Maybe<Array<Maybe<Farcaster_Reactions_Mutation_Response>>>;
  /** update data of the table: "farcaster_signers" */
  update_farcaster_signers?: Maybe<Farcaster_Signers_Mutation_Response>;
  /** update single row of the table: "farcaster_signers" */
  update_farcaster_signers_by_pk?: Maybe<Farcaster_Signers>;
  /** update multiples rows of table: "farcaster_signers" */
  update_farcaster_signers_many?: Maybe<Array<Maybe<Farcaster_Signers_Mutation_Response>>>;
  /** update data of the table: "flag_types" */
  update_flag_types?: Maybe<Flag_Types_Mutation_Response>;
  /** update single row of the table: "flag_types" */
  update_flag_types_by_pk?: Maybe<Flag_Types>;
  /** update multiples rows of table: "flag_types" */
  update_flag_types_many?: Maybe<Array<Maybe<Flag_Types_Mutation_Response>>>;
  /** update data of the table: "flags" */
  update_flags?: Maybe<Flags_Mutation_Response>;
  /** update single row of the table: "flags" */
  update_flags_by_pk?: Maybe<Flags>;
  /** update multiples rows of table: "flags" */
  update_flags_many?: Maybe<Array<Maybe<Flags_Mutation_Response>>>;
  /** update data of the table: "followers" */
  update_followers?: Maybe<Followers_Mutation_Response>;
  /** update single row of the table: "followers" */
  update_followers_by_pk?: Maybe<Followers>;
  /** update multiples rows of table: "followers" */
  update_followers_many?: Maybe<Array<Maybe<Followers_Mutation_Response>>>;
  /** update data of the table: "frame_action" */
  update_frame_action?: Maybe<Frame_Action_Mutation_Response>;
  /** update single row of the table: "frame_action" */
  update_frame_action_by_pk?: Maybe<Frame_Action>;
  /** update multiples rows of table: "frame_action" */
  update_frame_action_many?: Maybe<Array<Maybe<Frame_Action_Mutation_Response>>>;
  /** update data of the table: "frame_buttons" */
  update_frame_buttons?: Maybe<Frame_Buttons_Mutation_Response>;
  /** update single row of the table: "frame_buttons" */
  update_frame_buttons_by_pk?: Maybe<Frame_Buttons>;
  /** update multiples rows of table: "frame_buttons" */
  update_frame_buttons_many?: Maybe<Array<Maybe<Frame_Buttons_Mutation_Response>>>;
  /** update data of the table: "frames" */
  update_frames?: Maybe<Frames_Mutation_Response>;
  /** update single row of the table: "frames" */
  update_frames_by_pk?: Maybe<Frames>;
  /** update multiples rows of table: "frames" */
  update_frames_many?: Maybe<Array<Maybe<Frames_Mutation_Response>>>;
  /** update data of the table: "images" */
  update_images?: Maybe<Images_Mutation_Response>;
  /** update single row of the table: "images" */
  update_images_by_pk?: Maybe<Images>;
  /** update multiples rows of table: "images" */
  update_images_many?: Maybe<Array<Maybe<Images_Mutation_Response>>>;
  /** update data of the table: "item_others" */
  update_item_others?: Maybe<Item_Others_Mutation_Response>;
  /** update single row of the table: "item_others" */
  update_item_others_by_pk?: Maybe<Item_Others>;
  /** update multiples rows of table: "item_others" */
  update_item_others_many?: Maybe<Array<Maybe<Item_Others_Mutation_Response>>>;
  /** update data of the table: "item_types" */
  update_item_types?: Maybe<Item_Types_Mutation_Response>;
  /** update single row of the table: "item_types" */
  update_item_types_by_pk?: Maybe<Item_Types>;
  /** update multiples rows of table: "item_types" */
  update_item_types_many?: Maybe<Array<Maybe<Item_Types_Mutation_Response>>>;
  /** update data of the table: "items" */
  update_items?: Maybe<Items_Mutation_Response>;
  /** update single row of the table: "items" */
  update_items_by_pk?: Maybe<Items>;
  /** update multiples rows of table: "items" */
  update_items_many?: Maybe<Array<Maybe<Items_Mutation_Response>>>;
  /** update data of the table: "knex_migrations" */
  update_knex_migrations?: Maybe<Knex_Migrations_Mutation_Response>;
  /** update single row of the table: "knex_migrations" */
  update_knex_migrations_by_pk?: Maybe<Knex_Migrations>;
  /** update data of the table: "knex_migrations_lock" */
  update_knex_migrations_lock?: Maybe<Knex_Migrations_Lock_Mutation_Response>;
  /** update single row of the table: "knex_migrations_lock" */
  update_knex_migrations_lock_by_pk?: Maybe<Knex_Migrations_Lock>;
  /** update multiples rows of table: "knex_migrations_lock" */
  update_knex_migrations_lock_many?: Maybe<Array<Maybe<Knex_Migrations_Lock_Mutation_Response>>>;
  /** update multiples rows of table: "knex_migrations" */
  update_knex_migrations_many?: Maybe<Array<Maybe<Knex_Migrations_Mutation_Response>>>;
  /** update data of the table: "language_isocodes" */
  update_language_isocodes?: Maybe<Language_Isocodes_Mutation_Response>;
  /** update single row of the table: "language_isocodes" */
  update_language_isocodes_by_pk?: Maybe<Language_Isocodes>;
  /** update multiples rows of table: "language_isocodes" */
  update_language_isocodes_many?: Maybe<Array<Maybe<Language_Isocodes_Mutation_Response>>>;
  /** update data of the table: "lens_api_access_tokens" */
  update_lens_api_access_tokens?: Maybe<Lens_Api_Access_Tokens_Mutation_Response>;
  /** update single row of the table: "lens_api_access_tokens" */
  update_lens_api_access_tokens_by_pk?: Maybe<Lens_Api_Access_Tokens>;
  /** update multiples rows of table: "lens_api_access_tokens" */
  update_lens_api_access_tokens_many?: Maybe<Array<Maybe<Lens_Api_Access_Tokens_Mutation_Response>>>;
  /** update data of the table: "lens_api_refresh_tokens" */
  update_lens_api_refresh_tokens?: Maybe<Lens_Api_Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "lens_api_refresh_tokens" */
  update_lens_api_refresh_tokens_by_pk?: Maybe<Lens_Api_Refresh_Tokens>;
  /** update multiples rows of table: "lens_api_refresh_tokens" */
  update_lens_api_refresh_tokens_many?: Maybe<Array<Maybe<Lens_Api_Refresh_Tokens_Mutation_Response>>>;
  /** update data of the table: "lens_appid_mappings" */
  update_lens_appid_mappings?: Maybe<Lens_Appid_Mappings_Mutation_Response>;
  /** update single row of the table: "lens_appid_mappings" */
  update_lens_appid_mappings_by_pk?: Maybe<Lens_Appid_Mappings>;
  /** update multiples rows of table: "lens_appid_mappings" */
  update_lens_appid_mappings_many?: Maybe<Array<Maybe<Lens_Appid_Mappings_Mutation_Response>>>;
  /** update data of the table: "lens_collects" */
  update_lens_collects?: Maybe<Lens_Collects_Mutation_Response>;
  /** update single row of the table: "lens_collects" */
  update_lens_collects_by_pk?: Maybe<Lens_Collects>;
  /** update multiples rows of table: "lens_collects" */
  update_lens_collects_many?: Maybe<Array<Maybe<Lens_Collects_Mutation_Response>>>;
  /** update data of the table: "lens_custodial_accounts" */
  update_lens_custodial_accounts?: Maybe<Lens_Custodial_Accounts_Mutation_Response>;
  /** update single row of the table: "lens_custodial_accounts" */
  update_lens_custodial_accounts_by_pk?: Maybe<Lens_Custodial_Accounts>;
  /** update multiples rows of table: "lens_custodial_accounts" */
  update_lens_custodial_accounts_many?: Maybe<Array<Maybe<Lens_Custodial_Accounts_Mutation_Response>>>;
  /** update data of the table: "lens_events" */
  update_lens_events?: Maybe<Lens_Events_Mutation_Response>;
  /** update single row of the table: "lens_events" */
  update_lens_events_by_pk?: Maybe<Lens_Events>;
  /** update multiples rows of table: "lens_events" */
  update_lens_events_many?: Maybe<Array<Maybe<Lens_Events_Mutation_Response>>>;
  /** update data of the table: "lens_followers" */
  update_lens_followers?: Maybe<Lens_Followers_Mutation_Response>;
  /** update single row of the table: "lens_followers" */
  update_lens_followers_by_pk?: Maybe<Lens_Followers>;
  /** update multiples rows of table: "lens_followers" */
  update_lens_followers_many?: Maybe<Array<Maybe<Lens_Followers_Mutation_Response>>>;
  /** update data of the table: "lens_followers_v2" */
  update_lens_followers_v2?: Maybe<Lens_Followers_V2_Mutation_Response>;
  /** update single row of the table: "lens_followers_v2" */
  update_lens_followers_v2_by_pk?: Maybe<Lens_Followers_V2>;
  /** update multiples rows of table: "lens_followers_v2" */
  update_lens_followers_v2_many?: Maybe<Array<Maybe<Lens_Followers_V2_Mutation_Response>>>;
  /** update data of the table: "lens_indexer" */
  update_lens_indexer?: Maybe<Lens_Indexer_Mutation_Response>;
  /** update single row of the table: "lens_indexer" */
  update_lens_indexer_by_pk?: Maybe<Lens_Indexer>;
  /** update multiples rows of table: "lens_indexer" */
  update_lens_indexer_many?: Maybe<Array<Maybe<Lens_Indexer_Mutation_Response>>>;
  /** update data of the table: "lens_invites" */
  update_lens_invites?: Maybe<Lens_Invites_Mutation_Response>;
  /** update single row of the table: "lens_invites" */
  update_lens_invites_by_pk?: Maybe<Lens_Invites>;
  /** update multiples rows of table: "lens_invites" */
  update_lens_invites_many?: Maybe<Array<Maybe<Lens_Invites_Mutation_Response>>>;
  /** update data of the table: "lens_post_lens_pub_types" */
  update_lens_post_lens_pub_types?: Maybe<Lens_Post_Lens_Pub_Types_Mutation_Response>;
  /** update single row of the table: "lens_post_lens_pub_types" */
  update_lens_post_lens_pub_types_by_pk?: Maybe<Lens_Post_Lens_Pub_Types>;
  /** update multiples rows of table: "lens_post_lens_pub_types" */
  update_lens_post_lens_pub_types_many?: Maybe<Array<Maybe<Lens_Post_Lens_Pub_Types_Mutation_Response>>>;
  /** update data of the table: "lens_posts" */
  update_lens_posts?: Maybe<Lens_Posts_Mutation_Response>;
  /** update single row of the table: "lens_posts" */
  update_lens_posts_by_pk?: Maybe<Lens_Posts>;
  /** update multiples rows of table: "lens_posts" */
  update_lens_posts_many?: Maybe<Array<Maybe<Lens_Posts_Mutation_Response>>>;
  /** update data of the table: "lens_profiles" */
  update_lens_profiles?: Maybe<Lens_Profiles_Mutation_Response>;
  /** update single row of the table: "lens_profiles" */
  update_lens_profiles_by_pk?: Maybe<Lens_Profiles>;
  /** update multiples rows of table: "lens_profiles" */
  update_lens_profiles_many?: Maybe<Array<Maybe<Lens_Profiles_Mutation_Response>>>;
  /** update data of the table: "lens_reaction_types" */
  update_lens_reaction_types?: Maybe<Lens_Reaction_Types_Mutation_Response>;
  /** update single row of the table: "lens_reaction_types" */
  update_lens_reaction_types_by_pk?: Maybe<Lens_Reaction_Types>;
  /** update multiples rows of table: "lens_reaction_types" */
  update_lens_reaction_types_many?: Maybe<Array<Maybe<Lens_Reaction_Types_Mutation_Response>>>;
  /** update data of the table: "lens_reactions" */
  update_lens_reactions?: Maybe<Lens_Reactions_Mutation_Response>;
  /** update single row of the table: "lens_reactions" */
  update_lens_reactions_by_pk?: Maybe<Lens_Reactions>;
  /** update multiples rows of table: "lens_reactions" */
  update_lens_reactions_many?: Maybe<Array<Maybe<Lens_Reactions_Mutation_Response>>>;
  /** update data of the table: "lens_tx_status_enum" */
  update_lens_tx_status_enum?: Maybe<Lens_Tx_Status_Enum_Mutation_Response>;
  /** update single row of the table: "lens_tx_status_enum" */
  update_lens_tx_status_enum_by_pk?: Maybe<Lens_Tx_Status_Enum>;
  /** update multiples rows of table: "lens_tx_status_enum" */
  update_lens_tx_status_enum_many?: Maybe<Array<Maybe<Lens_Tx_Status_Enum_Mutation_Response>>>;
  /** update data of the table: "levels" */
  update_levels?: Maybe<Levels_Mutation_Response>;
  /** update single row of the table: "levels" */
  update_levels_by_pk?: Maybe<Levels>;
  /** update multiples rows of table: "levels" */
  update_levels_many?: Maybe<Array<Maybe<Levels_Mutation_Response>>>;
  /** update data of the table: "links" */
  update_links?: Maybe<Links_Mutation_Response>;
  /** update single row of the table: "links" */
  update_links_by_pk?: Maybe<Links>;
  /** update multiples rows of table: "links" */
  update_links_many?: Maybe<Array<Maybe<Links_Mutation_Response>>>;
  /** update data of the table: "locked_balances" */
  update_locked_balances?: Maybe<Locked_Balances_Mutation_Response>;
  /** update single row of the table: "locked_balances" */
  update_locked_balances_by_pk?: Maybe<Locked_Balances>;
  /** update multiples rows of table: "locked_balances" */
  update_locked_balances_many?: Maybe<Array<Maybe<Locked_Balances_Mutation_Response>>>;
  /** update data of the table: "medias" */
  update_medias?: Maybe<Medias_Mutation_Response>;
  /** update single row of the table: "medias" */
  update_medias_by_pk?: Maybe<Medias>;
  /** update multiples rows of table: "medias" */
  update_medias_many?: Maybe<Array<Maybe<Medias_Mutation_Response>>>;
  /** update data of the table: "moca_ids" */
  update_moca_ids?: Maybe<Moca_Ids_Mutation_Response>;
  /** update single row of the table: "moca_ids" */
  update_moca_ids_by_pk?: Maybe<Moca_Ids>;
  /** update multiples rows of table: "moca_ids" */
  update_moca_ids_many?: Maybe<Array<Maybe<Moca_Ids_Mutation_Response>>>;
  /** update data of the table: "notification_setting_types" */
  update_notification_setting_types?: Maybe<Notification_Setting_Types_Mutation_Response>;
  /** update single row of the table: "notification_setting_types" */
  update_notification_setting_types_by_pk?: Maybe<Notification_Setting_Types>;
  /** update multiples rows of table: "notification_setting_types" */
  update_notification_setting_types_many?: Maybe<Array<Maybe<Notification_Setting_Types_Mutation_Response>>>;
  /** update data of the table: "notification_settings" */
  update_notification_settings?: Maybe<Notification_Settings_Mutation_Response>;
  /** update single row of the table: "notification_settings" */
  update_notification_settings_by_pk?: Maybe<Notification_Settings>;
  /** update multiples rows of table: "notification_settings" */
  update_notification_settings_many?: Maybe<Array<Maybe<Notification_Settings_Mutation_Response>>>;
  /** update data of the table: "perks" */
  update_perks?: Maybe<Perks_Mutation_Response>;
  /** update single row of the table: "perks" */
  update_perks_by_pk?: Maybe<Perks>;
  /** update data of the table: "perks_claimed" */
  update_perks_claimed?: Maybe<Perks_Claimed_Mutation_Response>;
  /** update single row of the table: "perks_claimed" */
  update_perks_claimed_by_pk?: Maybe<Perks_Claimed>;
  /** update multiples rows of table: "perks_claimed" */
  update_perks_claimed_many?: Maybe<Array<Maybe<Perks_Claimed_Mutation_Response>>>;
  /** update multiples rows of table: "perks" */
  update_perks_many?: Maybe<Array<Maybe<Perks_Mutation_Response>>>;
  /** update data of the table: "post_aggregates" */
  update_post_aggregates?: Maybe<Post_Aggregates_Mutation_Response>;
  /** update single row of the table: "post_aggregates" */
  update_post_aggregates_by_pk?: Maybe<Post_Aggregates>;
  /** update multiples rows of table: "post_aggregates" */
  update_post_aggregates_many?: Maybe<Array<Maybe<Post_Aggregates_Mutation_Response>>>;
  /** update data of the table: "post_profile_tags" */
  update_post_profile_tags?: Maybe<Post_Profile_Tags_Mutation_Response>;
  /** update single row of the table: "post_profile_tags" */
  update_post_profile_tags_by_pk?: Maybe<Post_Profile_Tags>;
  /** update multiples rows of table: "post_profile_tags" */
  update_post_profile_tags_many?: Maybe<Array<Maybe<Post_Profile_Tags_Mutation_Response>>>;
  /** update data of the table: "post_reaction_types" */
  update_post_reaction_types?: Maybe<Post_Reaction_Types_Mutation_Response>;
  /** update single row of the table: "post_reaction_types" */
  update_post_reaction_types_by_pk?: Maybe<Post_Reaction_Types>;
  /** update multiples rows of table: "post_reaction_types" */
  update_post_reaction_types_many?: Maybe<Array<Maybe<Post_Reaction_Types_Mutation_Response>>>;
  /** update data of the table: "post_reactions" */
  update_post_reactions?: Maybe<Post_Reactions_Mutation_Response>;
  /** update single row of the table: "post_reactions" */
  update_post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** update multiples rows of table: "post_reactions" */
  update_post_reactions_many?: Maybe<Array<Maybe<Post_Reactions_Mutation_Response>>>;
  /** update data of the table: "profile_aggregates" */
  update_profile_aggregates?: Maybe<Profile_Aggregates_Mutation_Response>;
  /** update single row of the table: "profile_aggregates" */
  update_profile_aggregates_by_pk?: Maybe<Profile_Aggregates>;
  /** update multiples rows of table: "profile_aggregates" */
  update_profile_aggregates_many?: Maybe<Array<Maybe<Profile_Aggregates_Mutation_Response>>>;
  /** update data of the table: "profiles" */
  update_profiles?: Maybe<Profiles_Mutation_Response>;
  /** update single row of the table: "profiles" */
  update_profiles_by_pk?: Maybe<Profiles>;
  /** update multiples rows of table: "profiles" */
  update_profiles_many?: Maybe<Array<Maybe<Profiles_Mutation_Response>>>;
  /** update data of the table: "push_tokens" */
  update_push_tokens?: Maybe<Push_Tokens_Mutation_Response>;
  /** update single row of the table: "push_tokens" */
  update_push_tokens_by_pk?: Maybe<Push_Tokens>;
  /** update multiples rows of table: "push_tokens" */
  update_push_tokens_many?: Maybe<Array<Maybe<Push_Tokens_Mutation_Response>>>;
  /** update data of the table: "recommendation_comments" */
  update_recommendation_comments?: Maybe<Recommendation_Comments_Mutation_Response>;
  /** update single row of the table: "recommendation_comments" */
  update_recommendation_comments_by_pk?: Maybe<Recommendation_Comments>;
  /** update multiples rows of table: "recommendation_comments" */
  update_recommendation_comments_many?: Maybe<Array<Maybe<Recommendation_Comments_Mutation_Response>>>;
  /** update data of the table: "recommendation_votes" */
  update_recommendation_votes?: Maybe<Recommendation_Votes_Mutation_Response>;
  /** update single row of the table: "recommendation_votes" */
  update_recommendation_votes_by_pk?: Maybe<Recommendation_Votes>;
  /** update multiples rows of table: "recommendation_votes" */
  update_recommendation_votes_many?: Maybe<Array<Maybe<Recommendation_Votes_Mutation_Response>>>;
  /** update data of the table: "recommendations" */
  update_recommendations?: Maybe<Recommendations_Mutation_Response>;
  /** update single row of the table: "recommendations" */
  update_recommendations_by_pk?: Maybe<Recommendations>;
  /** update multiples rows of table: "recommendations" */
  update_recommendations_many?: Maybe<Array<Maybe<Recommendations_Mutation_Response>>>;
  /** update data of the table: "reported_content" */
  update_reported_content?: Maybe<Reported_Content_Mutation_Response>;
  /** update single row of the table: "reported_content" */
  update_reported_content_by_pk?: Maybe<Reported_Content>;
  /** update multiples rows of table: "reported_content" */
  update_reported_content_many?: Maybe<Array<Maybe<Reported_Content_Mutation_Response>>>;
  /** update data of the table: "reward_brands" */
  update_reward_brands?: Maybe<Reward_Brands_Mutation_Response>;
  /** update single row of the table: "reward_brands" */
  update_reward_brands_by_pk?: Maybe<Reward_Brands>;
  /** update multiples rows of table: "reward_brands" */
  update_reward_brands_many?: Maybe<Array<Maybe<Reward_Brands_Mutation_Response>>>;
  /** update data of the table: "reward_claims" */
  update_reward_claims?: Maybe<Reward_Claims_Mutation_Response>;
  /** update single row of the table: "reward_claims" */
  update_reward_claims_by_pk?: Maybe<Reward_Claims>;
  /** update multiples rows of table: "reward_claims" */
  update_reward_claims_many?: Maybe<Array<Maybe<Reward_Claims_Mutation_Response>>>;
  /** update data of the table: "reward_types" */
  update_reward_types?: Maybe<Reward_Types_Mutation_Response>;
  /** update single row of the table: "reward_types" */
  update_reward_types_by_pk?: Maybe<Reward_Types>;
  /** update multiples rows of table: "reward_types" */
  update_reward_types_many?: Maybe<Array<Maybe<Reward_Types_Mutation_Response>>>;
  /** update data of the table: "role_types" */
  update_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** update single row of the table: "role_types" */
  update_role_types_by_pk?: Maybe<Role_Types>;
  /** update multiples rows of table: "role_types" */
  update_role_types_many?: Maybe<Array<Maybe<Role_Types_Mutation_Response>>>;
  /** update data of the table: "rss_feeds" */
  update_rss_feeds?: Maybe<Rss_Feeds_Mutation_Response>;
  /** update single row of the table: "rss_feeds" */
  update_rss_feeds_by_pk?: Maybe<Rss_Feeds>;
  /** update multiples rows of table: "rss_feeds" */
  update_rss_feeds_many?: Maybe<Array<Maybe<Rss_Feeds_Mutation_Response>>>;
  /** update data of the table: "staking_reward_posters" */
  update_staking_reward_posters?: Maybe<Staking_Reward_Posters_Mutation_Response>;
  /** update single row of the table: "staking_reward_posters" */
  update_staking_reward_posters_by_pk?: Maybe<Staking_Reward_Posters>;
  /** update multiples rows of table: "staking_reward_posters" */
  update_staking_reward_posters_many?: Maybe<Array<Maybe<Staking_Reward_Posters_Mutation_Response>>>;
  /** update data of the table: "staking_reward_stakers" */
  update_staking_reward_stakers?: Maybe<Staking_Reward_Stakers_Mutation_Response>;
  /** update single row of the table: "staking_reward_stakers" */
  update_staking_reward_stakers_by_pk?: Maybe<Staking_Reward_Stakers>;
  /** update multiples rows of table: "staking_reward_stakers" */
  update_staking_reward_stakers_many?: Maybe<Array<Maybe<Staking_Reward_Stakers_Mutation_Response>>>;
  /** update data of the table: "subdivisions" */
  update_subdivisions?: Maybe<Subdivisions_Mutation_Response>;
  /** update single row of the table: "subdivisions" */
  update_subdivisions_by_pk?: Maybe<Subdivisions>;
  /** update multiples rows of table: "subdivisions" */
  update_subdivisions_many?: Maybe<Array<Maybe<Subdivisions_Mutation_Response>>>;
  /** update data of the table: "task_sections" */
  update_task_sections?: Maybe<Task_Sections_Mutation_Response>;
  /** update single row of the table: "task_sections" */
  update_task_sections_by_pk?: Maybe<Task_Sections>;
  /** update multiples rows of table: "task_sections" */
  update_task_sections_many?: Maybe<Array<Maybe<Task_Sections_Mutation_Response>>>;
  /** update data of the table: "task_types" */
  update_task_types?: Maybe<Task_Types_Mutation_Response>;
  /** update single row of the table: "task_types" */
  update_task_types_by_pk?: Maybe<Task_Types>;
  /** update multiples rows of table: "task_types" */
  update_task_types_many?: Maybe<Array<Maybe<Task_Types_Mutation_Response>>>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update data of the table: "tasks_done" */
  update_tasks_done?: Maybe<Tasks_Done_Mutation_Response>;
  /** update single row of the table: "tasks_done" */
  update_tasks_done_by_pk?: Maybe<Tasks_Done>;
  /** update multiples rows of table: "tasks_done" */
  update_tasks_done_many?: Maybe<Array<Maybe<Tasks_Done_Mutation_Response>>>;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "verification_types" */
  update_verification_types?: Maybe<Verification_Types_Mutation_Response>;
  /** update single row of the table: "verification_types" */
  update_verification_types_by_pk?: Maybe<Verification_Types>;
  /** update multiples rows of table: "verification_types" */
  update_verification_types_many?: Maybe<Array<Maybe<Verification_Types_Mutation_Response>>>;
  /** update data of the table: "verified_nft_project_kinds" */
  update_verified_nft_project_kinds?: Maybe<Verified_Nft_Project_Kinds_Mutation_Response>;
  /** update single row of the table: "verified_nft_project_kinds" */
  update_verified_nft_project_kinds_by_pk?: Maybe<Verified_Nft_Project_Kinds>;
  /** update multiples rows of table: "verified_nft_project_kinds" */
  update_verified_nft_project_kinds_many?: Maybe<Array<Maybe<Verified_Nft_Project_Kinds_Mutation_Response>>>;
  /** update data of the table: "verified_nft_projects" */
  update_verified_nft_projects?: Maybe<Verified_Nft_Projects_Mutation_Response>;
  /** update single row of the table: "verified_nft_projects" */
  update_verified_nft_projects_by_pk?: Maybe<Verified_Nft_Projects>;
  /** update multiples rows of table: "verified_nft_projects" */
  update_verified_nft_projects_many?: Maybe<Array<Maybe<Verified_Nft_Projects_Mutation_Response>>>;
  /** update data of the table: "videos" */
  update_videos?: Maybe<Videos_Mutation_Response>;
  /** update single row of the table: "videos" */
  update_videos_by_pk?: Maybe<Videos>;
  /** update multiples rows of table: "videos" */
  update_videos_many?: Maybe<Array<Maybe<Videos_Mutation_Response>>>;
  /** update data of the table: "wallets" */
  update_wallets?: Maybe<Wallets_Mutation_Response>;
  /** update single row of the table: "wallets" */
  update_wallets_by_pk?: Maybe<Wallets>;
  /** update multiples rows of table: "wallets" */
  update_wallets_many?: Maybe<Array<Maybe<Wallets_Mutation_Response>>>;
  /** update data of the table: "wallets_resolves" */
  update_wallets_resolves?: Maybe<Wallets_Resolves_Mutation_Response>;
  /** update single row of the table: "wallets_resolves" */
  update_wallets_resolves_by_pk?: Maybe<Wallets_Resolves>;
  /** update multiples rows of table: "wallets_resolves" */
  update_wallets_resolves_many?: Maybe<Array<Maybe<Wallets_Resolves_Mutation_Response>>>;
  /** update data of the table: "yield_sources" */
  update_yield_sources?: Maybe<Yield_Sources_Mutation_Response>;
  /** update single row of the table: "yield_sources" */
  update_yield_sources_by_pk?: Maybe<Yield_Sources>;
  /** update multiples rows of table: "yield_sources" */
  update_yield_sources_many?: Maybe<Array<Maybe<Yield_Sources_Mutation_Response>>>;
  uploadImageFromUrl?: Maybe<UploadImageFromUrlOutput>;
  voteComment?: Maybe<VoteCommentOutput>;
  /** votePost */
  votePost?: Maybe<VotePostOutput>;
};


/** mutation root */
export type Mutation_RootAddAdminPortalCustomClaimsArgs = {
  jwt: Scalars['String'];
};


/** mutation root */
export type Mutation_RootBanUserArgs = {
  userIdToBan?: InputMaybe<Scalars['String']>;
  userIdsToBan?: InputMaybe<Array<Scalars['String']>>;
  value?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootCcProfileBuildDataArgs = {
  address: Scalars['String'];
  chainId: Scalars['Int'];
  handle: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCcProfileExecuteArgs = {
  sig: Scalars['String'];
};


/** mutation root */
export type Mutation_RootChangeTopicArgs = {
  postId: Scalars['String'];
  topicId?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCollectPostArgs = {
  lensProfileId: Scalars['String'];
  pubId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootConnectNftsBuildDataArgs = {
  address: Scalars['String'];
  connect?: InputMaybe<NftConnectEnum>;
  nfts?: InputMaybe<Array<NftList>>;
};


/** mutation root */
export type Mutation_RootConnectNftsExecuteArgs = {
  sig: Scalars['String'];
};


/** mutation root */
export type Mutation_RootConnectWalletBuildDataArgs = {
  address: Scalars['String'];
  chainId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootConnectWalletExecuteArgs = {
  sig: Scalars['String'];
};


/** mutation root */
export type Mutation_RootConnectWithTokenproofArgs = {
  nonce?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCreateCommentArgs = {
  comment: CreateCommentInput;
};


/** mutation root */
export type Mutation_RootCreateCommunityArgs = {
  params: CreateCommunityInput;
};


/** mutation root */
export type Mutation_RootCreateCommunityCategoryArgs = {
  params: CreateCommunityCategoryInput;
};


/** mutation root */
export type Mutation_RootCreateFarcasterAccountExecuteArgs = {
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreatePostArgs = {
  broadcastToFarcaster: Scalars['Boolean'];
  broadcastToLens: Scalars['Boolean'];
  farcasterChannelId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<LinkInput>;
  mirroredPostId?: InputMaybe<Scalars['String']>;
  post: PostInput;
  videoId?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCreateSupportTicketArgs = {
  body: Scalars['String'];
  replyTo?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  tag: SupportTicketTag;
};


/** mutation root */
export type Mutation_RootDailyStakingUseArgs = {
  postId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteCommentArgs = {
  commentId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteCommunityArgs = {
  alsoDeleteRecommendations?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteCommunityCategoriesArgs = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  fallbackCategoryId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteRecommendationArgs = {
  recommendationId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  alsoDeleteComments?: InputMaybe<Scalars['Boolean']>;
  alsoDeleteCommunities?: InputMaybe<Scalars['Boolean']>;
  alsoDeleteCommunityContents?: InputMaybe<Scalars['Boolean']>;
  alsoDeleteItems?: InputMaybe<Scalars['Boolean']>;
  alsoDeleteRecommendations?: InputMaybe<Scalars['Boolean']>;
  alsoDeleteVotes?: InputMaybe<Scalars['Boolean']>;
  alsoUploadedImages?: InputMaybe<Scalars['Boolean']>;
  userId: Array<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootDeleteUserWipeArgs = {
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Account_TypesArgs = {
  where: Account_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Admin_Portal_WhitelistArgs = {
  where: Admin_Portal_Whitelist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_Portal_Whitelist_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AirdropArgs = {
  where: Airdrop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Airdrop_By_PkArgs = {
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Async_StatusArgs = {
  where: Async_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Async_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AudiosArgs = {
  where: Audios_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audios_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Badge_TypesArgs = {
  where: Badge_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badge_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Blocked_ProfilesArgs = {
  where: Blocked_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blocked_Profiles_By_PkArgs = {
  blocked_profile_id: Scalars['String'];
  profile_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_SignaturesArgs = {
  where: Campaign_Signatures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Signatures_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Cc_ProfilesArgs = {
  where: Cc_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cc_Profiles_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CitiesArgs = {
  where: Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cities_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Claimed_YieldArgs = {
  where: Claimed_Yield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Claimed_Yield_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Comment_AggregatesArgs = {
  where: Comment_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_Aggregates_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Comment_Profile_TagsArgs = {
  where: Comment_Profile_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_Profile_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Comment_VotesArgs = {
  where: Comment_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_Votes_By_PkArgs = {
  commentId: Scalars['String'];
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CommunitiesArgs = {
  where: Communities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Communities_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_AggregatesArgs = {
  where: Community_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Aggregates_By_PkArgs = {
  communityId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_CategoriesArgs = {
  where: Community_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Categories_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_Content_CollectionsArgs = {
  where: Community_Content_Collections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Content_Collections_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_Content_QuestionsArgs = {
  where: Community_Content_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Content_Questions_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_Content_VotesArgs = {
  where: Community_Content_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Content_Votes_By_PkArgs = {
  communityContentId: Scalars['String'];
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_ContentsArgs = {
  where: Community_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Contents_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_MembersArgs = {
  where: Community_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Members_By_PkArgs = {
  communityId: Scalars['String'];
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Community_Nft_ProjectsArgs = {
  where: Community_Nft_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Nft_Projects_By_PkArgs = {
  communityId: Scalars['String'];
  verifiedNftProjectId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Community_StaffpicksArgs = {
  where: Community_Staffpicks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Community_Staffpicks_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Connected_NftsArgs = {
  where: Connected_Nfts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Connected_Nfts_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ContinentsArgs = {
  where: Continents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Continents_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CountriesArgs = {
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Countries_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Cred_ScoresArgs = {
  where: Cred_Scores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cred_Scores_By_PkArgs = {
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Currency_IsocodesArgs = {
  where: Currency_Isocodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currency_Isocodes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Daily_StakingArgs = {
  where: Daily_Staking_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Daily_Staking_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Embedded_UrlsArgs = {
  where: Embedded_Urls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Embedded_Urls_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_CastsArgs = {
  where: Farcaster_Casts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_Casts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_InvitesArgs = {
  where: Farcaster_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_Invites_By_PkArgs = {
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_Power_Badge_UsersArgs = {
  where: Farcaster_Power_Badge_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_Power_Badge_Users_By_PkArgs = {
  fid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_ProfilesArgs = {
  where: Farcaster_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_Profiles_By_PkArgs = {
  tokenId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_ReactionsArgs = {
  where: Farcaster_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_SignersArgs = {
  where: Farcaster_Signers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farcaster_Signers_By_PkArgs = {
  signerUuid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Flag_TypesArgs = {
  where: Flag_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Flag_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FlagsArgs = {
  where: Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Flags_By_PkArgs = {
  accountId: Scalars['String'];
  flagType: Flag_Types_Enum;
};


/** mutation root */
export type Mutation_RootDelete_FollowersArgs = {
  where: Followers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Followers_By_PkArgs = {
  followedProfileId: Scalars['String'];
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Frame_ActionArgs = {
  where: Frame_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Frame_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Frame_ButtonsArgs = {
  where: Frame_Buttons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Frame_Buttons_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FramesArgs = {
  where: Frames_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Frames_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImagesArgs = {
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Images_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Item_OthersArgs = {
  where: Item_Others_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Item_Others_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Item_TypesArgs = {
  where: Item_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Item_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ItemsArgs = {
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Knex_MigrationsArgs = {
  where: Knex_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Knex_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Knex_Migrations_LockArgs = {
  where: Knex_Migrations_Lock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Knex_Migrations_Lock_By_PkArgs = {
  index: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Language_IsocodesArgs = {
  where: Language_Isocodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Language_Isocodes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Api_Access_TokensArgs = {
  where: Lens_Api_Access_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Api_Access_Tokens_By_PkArgs = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Api_Refresh_TokensArgs = {
  where: Lens_Api_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Api_Refresh_Tokens_By_PkArgs = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Appid_MappingsArgs = {
  where: Lens_Appid_Mappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Appid_Mappings_By_PkArgs = {
  app_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_CollectsArgs = {
  where: Lens_Collects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Collects_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Custodial_AccountsArgs = {
  where: Lens_Custodial_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Custodial_Accounts_By_PkArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_EventsArgs = {
  where: Lens_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Events_By_PkArgs = {
  log_index: Scalars['Int'];
  transaction_hash: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_FollowersArgs = {
  where: Lens_Followers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Followers_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Followers_V2Args = {
  where: Lens_Followers_V2_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Followers_V2_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_IndexerArgs = {
  where: Lens_Indexer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Indexer_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_InvitesArgs = {
  where: Lens_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Invites_By_PkArgs = {
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Post_Lens_Pub_TypesArgs = {
  where: Lens_Post_Lens_Pub_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Post_Lens_Pub_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_PostsArgs = {
  where: Lens_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Posts_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_ProfilesArgs = {
  where: Lens_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Profiles_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Reaction_TypesArgs = {
  where: Lens_Reaction_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Reaction_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_ReactionsArgs = {
  where: Lens_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Reactions_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lens_Tx_Status_EnumArgs = {
  where: Lens_Tx_Status_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lens_Tx_Status_Enum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_LevelsArgs = {
  where: Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Levels_By_PkArgs = {
  level: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LinksArgs = {
  where: Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Links_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Locked_BalancesArgs = {
  where: Locked_Balances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locked_Balances_By_PkArgs = {
  accountId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MediasArgs = {
  where: Medias_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Medias_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Moca_IdsArgs = {
  where: Moca_Ids_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Moca_Ids_By_PkArgs = {
  mocaId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_Setting_TypesArgs = {
  where: Notification_Setting_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Setting_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_SettingsArgs = {
  where: Notification_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Settings_By_PkArgs = {
  accountId: Scalars['String'];
  eventType: Notification_Setting_Types_Enum;
};


/** mutation root */
export type Mutation_RootDelete_PerksArgs = {
  where: Perks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Perks_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Perks_ClaimedArgs = {
  where: Perks_Claimed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Perks_Claimed_By_PkArgs = {
  perkId: Scalars['Int'];
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Post_AggregatesArgs = {
  where: Post_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Aggregates_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Post_Profile_TagsArgs = {
  where: Post_Profile_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Profile_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Post_Reaction_TypesArgs = {
  where: Post_Reaction_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Reaction_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Post_ReactionsArgs = {
  where: Post_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Reactions_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_AggregatesArgs = {
  where: Profile_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Aggregates_By_PkArgs = {
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProfilesArgs = {
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profiles_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Push_TokensArgs = {
  where: Push_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Push_Tokens_By_PkArgs = {
  accountId: Scalars['String'];
  expoPushToken: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_CommentsArgs = {
  where: Recommendation_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_Comments_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_VotesArgs = {
  where: Recommendation_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_Votes_By_PkArgs = {
  profileId: Scalars['String'];
  recommendationId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RecommendationsArgs = {
  where: Recommendations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recommendations_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reported_ContentArgs = {
  where: Reported_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reported_Content_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reward_BrandsArgs = {
  where: Reward_Brands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reward_Brands_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reward_ClaimsArgs = {
  where: Reward_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reward_Claims_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reward_TypesArgs = {
  where: Reward_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reward_Types_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Role_TypesArgs = {
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Rss_FeedsArgs = {
  where: Rss_Feeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rss_Feeds_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Staking_Reward_PostersArgs = {
  where: Staking_Reward_Posters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staking_Reward_Posters_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Staking_Reward_StakersArgs = {
  where: Staking_Reward_Stakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staking_Reward_Stakers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SubdivisionsArgs = {
  where: Subdivisions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subdivisions_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Task_SectionsArgs = {
  where: Task_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Sections_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Task_TypesArgs = {
  where: Task_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  taskType: Task_Types_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Tasks_DoneArgs = {
  where: Tasks_Done_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tasks_Done_By_PkArgs = {
  profileId: Scalars['String'];
  taskType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Verification_TypesArgs = {
  where: Verification_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verification_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Verified_Nft_Project_KindsArgs = {
  where: Verified_Nft_Project_Kinds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verified_Nft_Project_Kinds_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Verified_Nft_ProjectsArgs = {
  where: Verified_Nft_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verified_Nft_Projects_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VideosArgs = {
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Videos_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_WalletsArgs = {
  where: Wallets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wallets_By_PkArgs = {
  walletAddress: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wallets_ResolvesArgs = {
  where: Wallets_Resolves_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wallets_Resolves_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Yield_SourcesArgs = {
  where: Yield_Sources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Yield_Sources_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDisconnectXmtpWalletArgs = {
  walletAddress: Scalars['String'];
};


/** mutation root */
export type Mutation_RootEditCommunityArgs = {
  params: EditCommunityInput;
};


/** mutation root */
export type Mutation_RootEditCommunityCategoryArgs = {
  params: EditCommunityCategoryInput;
};


/** mutation root */
export type Mutation_RootEditProfileArgs = {
  params: EditProfileInput;
};


/** mutation root */
export type Mutation_RootFarcasterAuthExecuteArgs = {
  signerUuid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootHandleReportArgs = {
  reportId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Account_TypesArgs = {
  objects: Array<Account_Types_Insert_Input>;
  on_conflict?: InputMaybe<Account_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Types_OneArgs = {
  object: Account_Types_Insert_Input;
  on_conflict?: InputMaybe<Account_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: Array<Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_Portal_WhitelistArgs = {
  objects: Array<Admin_Portal_Whitelist_Insert_Input>;
  on_conflict?: InputMaybe<Admin_Portal_Whitelist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_Portal_Whitelist_OneArgs = {
  object: Admin_Portal_Whitelist_Insert_Input;
  on_conflict?: InputMaybe<Admin_Portal_Whitelist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AirdropArgs = {
  objects: Array<Airdrop_Insert_Input>;
  on_conflict?: InputMaybe<Airdrop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Airdrop_OneArgs = {
  object: Airdrop_Insert_Input;
  on_conflict?: InputMaybe<Airdrop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Async_StatusArgs = {
  objects: Array<Async_Status_Insert_Input>;
  on_conflict?: InputMaybe<Async_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Async_Status_OneArgs = {
  object: Async_Status_Insert_Input;
  on_conflict?: InputMaybe<Async_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AudiosArgs = {
  objects: Array<Audios_Insert_Input>;
  on_conflict?: InputMaybe<Audios_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audios_OneArgs = {
  object: Audios_Insert_Input;
  on_conflict?: InputMaybe<Audios_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_TypesArgs = {
  objects: Array<Badge_Types_Insert_Input>;
  on_conflict?: InputMaybe<Badge_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_Types_OneArgs = {
  object: Badge_Types_Insert_Input;
  on_conflict?: InputMaybe<Badge_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blocked_ProfilesArgs = {
  objects: Array<Blocked_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Blocked_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blocked_Profiles_OneArgs = {
  object: Blocked_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Blocked_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_SignaturesArgs = {
  objects: Array<Campaign_Signatures_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Signatures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Signatures_OneArgs = {
  object: Campaign_Signatures_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Signatures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cc_ProfilesArgs = {
  objects: Array<Cc_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Cc_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cc_Profiles_OneArgs = {
  object: Cc_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Cc_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CitiesArgs = {
  objects: Array<Cities_Insert_Input>;
  on_conflict?: InputMaybe<Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cities_OneArgs = {
  object: Cities_Insert_Input;
  on_conflict?: InputMaybe<Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Claimed_YieldArgs = {
  objects: Array<Claimed_Yield_Insert_Input>;
  on_conflict?: InputMaybe<Claimed_Yield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Claimed_Yield_OneArgs = {
  object: Claimed_Yield_Insert_Input;
  on_conflict?: InputMaybe<Claimed_Yield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_AggregatesArgs = {
  objects: Array<Comment_Aggregates_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Aggregates_OneArgs = {
  object: Comment_Aggregates_Insert_Input;
  on_conflict?: InputMaybe<Comment_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Profile_TagsArgs = {
  objects: Array<Comment_Profile_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Profile_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Profile_Tags_OneArgs = {
  object: Comment_Profile_Tags_Insert_Input;
  on_conflict?: InputMaybe<Comment_Profile_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_VotesArgs = {
  objects: Array<Comment_Votes_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Votes_OneArgs = {
  object: Comment_Votes_Insert_Input;
  on_conflict?: InputMaybe<Comment_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommunitiesArgs = {
  objects: Array<Communities_Insert_Input>;
  on_conflict?: InputMaybe<Communities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Communities_OneArgs = {
  object: Communities_Insert_Input;
  on_conflict?: InputMaybe<Communities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_AggregatesArgs = {
  objects: Array<Community_Aggregates_Insert_Input>;
  on_conflict?: InputMaybe<Community_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Aggregates_OneArgs = {
  object: Community_Aggregates_Insert_Input;
  on_conflict?: InputMaybe<Community_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_CategoriesArgs = {
  objects: Array<Community_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Community_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Categories_OneArgs = {
  object: Community_Categories_Insert_Input;
  on_conflict?: InputMaybe<Community_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Content_CollectionsArgs = {
  objects: Array<Community_Content_Collections_Insert_Input>;
  on_conflict?: InputMaybe<Community_Content_Collections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Content_Collections_OneArgs = {
  object: Community_Content_Collections_Insert_Input;
  on_conflict?: InputMaybe<Community_Content_Collections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Content_QuestionsArgs = {
  objects: Array<Community_Content_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Community_Content_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Content_Questions_OneArgs = {
  object: Community_Content_Questions_Insert_Input;
  on_conflict?: InputMaybe<Community_Content_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Content_VotesArgs = {
  objects: Array<Community_Content_Votes_Insert_Input>;
  on_conflict?: InputMaybe<Community_Content_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Content_Votes_OneArgs = {
  object: Community_Content_Votes_Insert_Input;
  on_conflict?: InputMaybe<Community_Content_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_ContentsArgs = {
  objects: Array<Community_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Community_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Contents_OneArgs = {
  object: Community_Contents_Insert_Input;
  on_conflict?: InputMaybe<Community_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_MembersArgs = {
  objects: Array<Community_Members_Insert_Input>;
  on_conflict?: InputMaybe<Community_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Members_OneArgs = {
  object: Community_Members_Insert_Input;
  on_conflict?: InputMaybe<Community_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Nft_ProjectsArgs = {
  objects: Array<Community_Nft_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Community_Nft_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Nft_Projects_OneArgs = {
  object: Community_Nft_Projects_Insert_Input;
  on_conflict?: InputMaybe<Community_Nft_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_StaffpicksArgs = {
  objects: Array<Community_Staffpicks_Insert_Input>;
  on_conflict?: InputMaybe<Community_Staffpicks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Community_Staffpicks_OneArgs = {
  object: Community_Staffpicks_Insert_Input;
  on_conflict?: InputMaybe<Community_Staffpicks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Connected_NftsArgs = {
  objects: Array<Connected_Nfts_Insert_Input>;
  on_conflict?: InputMaybe<Connected_Nfts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Connected_Nfts_OneArgs = {
  object: Connected_Nfts_Insert_Input;
  on_conflict?: InputMaybe<Connected_Nfts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContinentsArgs = {
  objects: Array<Continents_Insert_Input>;
  on_conflict?: InputMaybe<Continents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Continents_OneArgs = {
  object: Continents_Insert_Input;
  on_conflict?: InputMaybe<Continents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountriesArgs = {
  objects: Array<Countries_Insert_Input>;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Countries_OneArgs = {
  object: Countries_Insert_Input;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cred_ScoresArgs = {
  objects: Array<Cred_Scores_Insert_Input>;
  on_conflict?: InputMaybe<Cred_Scores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cred_Scores_OneArgs = {
  object: Cred_Scores_Insert_Input;
  on_conflict?: InputMaybe<Cred_Scores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_IsocodesArgs = {
  objects: Array<Currency_Isocodes_Insert_Input>;
  on_conflict?: InputMaybe<Currency_Isocodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_Isocodes_OneArgs = {
  object: Currency_Isocodes_Insert_Input;
  on_conflict?: InputMaybe<Currency_Isocodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_StakingArgs = {
  objects: Array<Daily_Staking_Insert_Input>;
  on_conflict?: InputMaybe<Daily_Staking_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_Staking_OneArgs = {
  object: Daily_Staking_Insert_Input;
  on_conflict?: InputMaybe<Daily_Staking_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Embedded_UrlsArgs = {
  objects: Array<Embedded_Urls_Insert_Input>;
  on_conflict?: InputMaybe<Embedded_Urls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Embedded_Urls_OneArgs = {
  object: Embedded_Urls_Insert_Input;
  on_conflict?: InputMaybe<Embedded_Urls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_CastsArgs = {
  objects: Array<Farcaster_Casts_Insert_Input>;
  on_conflict?: InputMaybe<Farcaster_Casts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_Casts_OneArgs = {
  object: Farcaster_Casts_Insert_Input;
  on_conflict?: InputMaybe<Farcaster_Casts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_InvitesArgs = {
  objects: Array<Farcaster_Invites_Insert_Input>;
  on_conflict?: InputMaybe<Farcaster_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_Invites_OneArgs = {
  object: Farcaster_Invites_Insert_Input;
  on_conflict?: InputMaybe<Farcaster_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_Power_Badge_UsersArgs = {
  objects: Array<Farcaster_Power_Badge_Users_Insert_Input>;
  on_conflict?: InputMaybe<Farcaster_Power_Badge_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_Power_Badge_Users_OneArgs = {
  object: Farcaster_Power_Badge_Users_Insert_Input;
  on_conflict?: InputMaybe<Farcaster_Power_Badge_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_ProfilesArgs = {
  objects: Array<Farcaster_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Farcaster_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_Profiles_OneArgs = {
  object: Farcaster_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Farcaster_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_ReactionsArgs = {
  objects: Array<Farcaster_Reactions_Insert_Input>;
  on_conflict?: InputMaybe<Farcaster_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_Reactions_OneArgs = {
  object: Farcaster_Reactions_Insert_Input;
  on_conflict?: InputMaybe<Farcaster_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_SignersArgs = {
  objects: Array<Farcaster_Signers_Insert_Input>;
  on_conflict?: InputMaybe<Farcaster_Signers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farcaster_Signers_OneArgs = {
  object: Farcaster_Signers_Insert_Input;
  on_conflict?: InputMaybe<Farcaster_Signers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flag_TypesArgs = {
  objects: Array<Flag_Types_Insert_Input>;
  on_conflict?: InputMaybe<Flag_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flag_Types_OneArgs = {
  object: Flag_Types_Insert_Input;
  on_conflict?: InputMaybe<Flag_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlagsArgs = {
  objects: Array<Flags_Insert_Input>;
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flags_OneArgs = {
  object: Flags_Insert_Input;
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FollowersArgs = {
  objects: Array<Followers_Insert_Input>;
  on_conflict?: InputMaybe<Followers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Followers_OneArgs = {
  object: Followers_Insert_Input;
  on_conflict?: InputMaybe<Followers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Frame_ActionArgs = {
  objects: Array<Frame_Action_Insert_Input>;
  on_conflict?: InputMaybe<Frame_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Frame_Action_OneArgs = {
  object: Frame_Action_Insert_Input;
  on_conflict?: InputMaybe<Frame_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Frame_ButtonsArgs = {
  objects: Array<Frame_Buttons_Insert_Input>;
  on_conflict?: InputMaybe<Frame_Buttons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Frame_Buttons_OneArgs = {
  object: Frame_Buttons_Insert_Input;
  on_conflict?: InputMaybe<Frame_Buttons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FramesArgs = {
  objects: Array<Frames_Insert_Input>;
  on_conflict?: InputMaybe<Frames_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Frames_OneArgs = {
  object: Frames_Insert_Input;
  on_conflict?: InputMaybe<Frames_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImagesArgs = {
  objects: Array<Images_Insert_Input>;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Images_OneArgs = {
  object: Images_Insert_Input;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Item_OthersArgs = {
  objects: Array<Item_Others_Insert_Input>;
  on_conflict?: InputMaybe<Item_Others_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Item_Others_OneArgs = {
  object: Item_Others_Insert_Input;
  on_conflict?: InputMaybe<Item_Others_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Item_TypesArgs = {
  objects: Array<Item_Types_Insert_Input>;
  on_conflict?: InputMaybe<Item_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Item_Types_OneArgs = {
  object: Item_Types_Insert_Input;
  on_conflict?: InputMaybe<Item_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItemsArgs = {
  objects: Array<Items_Insert_Input>;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_OneArgs = {
  object: Items_Insert_Input;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knex_MigrationsArgs = {
  objects: Array<Knex_Migrations_Insert_Input>;
  on_conflict?: InputMaybe<Knex_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knex_Migrations_LockArgs = {
  objects: Array<Knex_Migrations_Lock_Insert_Input>;
  on_conflict?: InputMaybe<Knex_Migrations_Lock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knex_Migrations_Lock_OneArgs = {
  object: Knex_Migrations_Lock_Insert_Input;
  on_conflict?: InputMaybe<Knex_Migrations_Lock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knex_Migrations_OneArgs = {
  object: Knex_Migrations_Insert_Input;
  on_conflict?: InputMaybe<Knex_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Language_IsocodesArgs = {
  objects: Array<Language_Isocodes_Insert_Input>;
  on_conflict?: InputMaybe<Language_Isocodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Language_Isocodes_OneArgs = {
  object: Language_Isocodes_Insert_Input;
  on_conflict?: InputMaybe<Language_Isocodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Api_Access_TokensArgs = {
  objects: Array<Lens_Api_Access_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Api_Access_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Api_Access_Tokens_OneArgs = {
  object: Lens_Api_Access_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Lens_Api_Access_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Api_Refresh_TokensArgs = {
  objects: Array<Lens_Api_Refresh_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Api_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Api_Refresh_Tokens_OneArgs = {
  object: Lens_Api_Refresh_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Lens_Api_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Appid_MappingsArgs = {
  objects: Array<Lens_Appid_Mappings_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Appid_Mappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Appid_Mappings_OneArgs = {
  object: Lens_Appid_Mappings_Insert_Input;
  on_conflict?: InputMaybe<Lens_Appid_Mappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_CollectsArgs = {
  objects: Array<Lens_Collects_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Collects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Collects_OneArgs = {
  object: Lens_Collects_Insert_Input;
  on_conflict?: InputMaybe<Lens_Collects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Custodial_AccountsArgs = {
  objects: Array<Lens_Custodial_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Custodial_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Custodial_Accounts_OneArgs = {
  object: Lens_Custodial_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Lens_Custodial_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_EventsArgs = {
  objects: Array<Lens_Events_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Events_OneArgs = {
  object: Lens_Events_Insert_Input;
  on_conflict?: InputMaybe<Lens_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_FollowersArgs = {
  objects: Array<Lens_Followers_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Followers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Followers_OneArgs = {
  object: Lens_Followers_Insert_Input;
  on_conflict?: InputMaybe<Lens_Followers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Followers_V2Args = {
  objects: Array<Lens_Followers_V2_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Followers_V2_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Followers_V2_OneArgs = {
  object: Lens_Followers_V2_Insert_Input;
  on_conflict?: InputMaybe<Lens_Followers_V2_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_IndexerArgs = {
  objects: Array<Lens_Indexer_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Indexer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Indexer_OneArgs = {
  object: Lens_Indexer_Insert_Input;
  on_conflict?: InputMaybe<Lens_Indexer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_InvitesArgs = {
  objects: Array<Lens_Invites_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Invites_OneArgs = {
  object: Lens_Invites_Insert_Input;
  on_conflict?: InputMaybe<Lens_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Post_Lens_Pub_TypesArgs = {
  objects: Array<Lens_Post_Lens_Pub_Types_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Post_Lens_Pub_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Post_Lens_Pub_Types_OneArgs = {
  object: Lens_Post_Lens_Pub_Types_Insert_Input;
  on_conflict?: InputMaybe<Lens_Post_Lens_Pub_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_PostsArgs = {
  objects: Array<Lens_Posts_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Posts_OneArgs = {
  object: Lens_Posts_Insert_Input;
  on_conflict?: InputMaybe<Lens_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_ProfilesArgs = {
  objects: Array<Lens_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Profiles_OneArgs = {
  object: Lens_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Lens_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Reaction_TypesArgs = {
  objects: Array<Lens_Reaction_Types_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Reaction_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Reaction_Types_OneArgs = {
  object: Lens_Reaction_Types_Insert_Input;
  on_conflict?: InputMaybe<Lens_Reaction_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_ReactionsArgs = {
  objects: Array<Lens_Reactions_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Reactions_OneArgs = {
  object: Lens_Reactions_Insert_Input;
  on_conflict?: InputMaybe<Lens_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Tx_Status_EnumArgs = {
  objects: Array<Lens_Tx_Status_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Lens_Tx_Status_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lens_Tx_Status_Enum_OneArgs = {
  object: Lens_Tx_Status_Enum_Insert_Input;
  on_conflict?: InputMaybe<Lens_Tx_Status_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LevelsArgs = {
  objects: Array<Levels_Insert_Input>;
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Levels_OneArgs = {
  object: Levels_Insert_Input;
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LinksArgs = {
  objects: Array<Links_Insert_Input>;
  on_conflict?: InputMaybe<Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Links_OneArgs = {
  object: Links_Insert_Input;
  on_conflict?: InputMaybe<Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locked_BalancesArgs = {
  objects: Array<Locked_Balances_Insert_Input>;
  on_conflict?: InputMaybe<Locked_Balances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locked_Balances_OneArgs = {
  object: Locked_Balances_Insert_Input;
  on_conflict?: InputMaybe<Locked_Balances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MediasArgs = {
  objects: Array<Medias_Insert_Input>;
  on_conflict?: InputMaybe<Medias_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Medias_OneArgs = {
  object: Medias_Insert_Input;
  on_conflict?: InputMaybe<Medias_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Moca_IdsArgs = {
  objects: Array<Moca_Ids_Insert_Input>;
  on_conflict?: InputMaybe<Moca_Ids_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Moca_Ids_OneArgs = {
  object: Moca_Ids_Insert_Input;
  on_conflict?: InputMaybe<Moca_Ids_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Setting_TypesArgs = {
  objects: Array<Notification_Setting_Types_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Setting_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Setting_Types_OneArgs = {
  object: Notification_Setting_Types_Insert_Input;
  on_conflict?: InputMaybe<Notification_Setting_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_SettingsArgs = {
  objects: Array<Notification_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Settings_OneArgs = {
  object: Notification_Settings_Insert_Input;
  on_conflict?: InputMaybe<Notification_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PerksArgs = {
  objects: Array<Perks_Insert_Input>;
  on_conflict?: InputMaybe<Perks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Perks_ClaimedArgs = {
  objects: Array<Perks_Claimed_Insert_Input>;
  on_conflict?: InputMaybe<Perks_Claimed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Perks_Claimed_OneArgs = {
  object: Perks_Claimed_Insert_Input;
  on_conflict?: InputMaybe<Perks_Claimed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Perks_OneArgs = {
  object: Perks_Insert_Input;
  on_conflict?: InputMaybe<Perks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_AggregatesArgs = {
  objects: Array<Post_Aggregates_Insert_Input>;
  on_conflict?: InputMaybe<Post_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Aggregates_OneArgs = {
  object: Post_Aggregates_Insert_Input;
  on_conflict?: InputMaybe<Post_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Profile_TagsArgs = {
  objects: Array<Post_Profile_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Post_Profile_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Profile_Tags_OneArgs = {
  object: Post_Profile_Tags_Insert_Input;
  on_conflict?: InputMaybe<Post_Profile_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Reaction_TypesArgs = {
  objects: Array<Post_Reaction_Types_Insert_Input>;
  on_conflict?: InputMaybe<Post_Reaction_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Reaction_Types_OneArgs = {
  object: Post_Reaction_Types_Insert_Input;
  on_conflict?: InputMaybe<Post_Reaction_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_ReactionsArgs = {
  objects: Array<Post_Reactions_Insert_Input>;
  on_conflict?: InputMaybe<Post_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Reactions_OneArgs = {
  object: Post_Reactions_Insert_Input;
  on_conflict?: InputMaybe<Post_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_AggregatesArgs = {
  objects: Array<Profile_Aggregates_Insert_Input>;
  on_conflict?: InputMaybe<Profile_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Aggregates_OneArgs = {
  object: Profile_Aggregates_Insert_Input;
  on_conflict?: InputMaybe<Profile_Aggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfilesArgs = {
  objects: Array<Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profiles_OneArgs = {
  object: Profiles_Insert_Input;
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Push_TokensArgs = {
  objects: Array<Push_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Push_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Push_Tokens_OneArgs = {
  object: Push_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Push_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_CommentsArgs = {
  objects: Array<Recommendation_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Recommendation_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_Comments_OneArgs = {
  object: Recommendation_Comments_Insert_Input;
  on_conflict?: InputMaybe<Recommendation_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_VotesArgs = {
  objects: Array<Recommendation_Votes_Insert_Input>;
  on_conflict?: InputMaybe<Recommendation_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_Votes_OneArgs = {
  object: Recommendation_Votes_Insert_Input;
  on_conflict?: InputMaybe<Recommendation_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RecommendationsArgs = {
  objects: Array<Recommendations_Insert_Input>;
  on_conflict?: InputMaybe<Recommendations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendations_OneArgs = {
  object: Recommendations_Insert_Input;
  on_conflict?: InputMaybe<Recommendations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reported_ContentArgs = {
  objects: Array<Reported_Content_Insert_Input>;
  on_conflict?: InputMaybe<Reported_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reported_Content_OneArgs = {
  object: Reported_Content_Insert_Input;
  on_conflict?: InputMaybe<Reported_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_BrandsArgs = {
  objects: Array<Reward_Brands_Insert_Input>;
  on_conflict?: InputMaybe<Reward_Brands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_Brands_OneArgs = {
  object: Reward_Brands_Insert_Input;
  on_conflict?: InputMaybe<Reward_Brands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_ClaimsArgs = {
  objects: Array<Reward_Claims_Insert_Input>;
  on_conflict?: InputMaybe<Reward_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_Claims_OneArgs = {
  object: Reward_Claims_Insert_Input;
  on_conflict?: InputMaybe<Reward_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_TypesArgs = {
  objects: Array<Reward_Types_Insert_Input>;
  on_conflict?: InputMaybe<Reward_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_Types_OneArgs = {
  object: Reward_Types_Insert_Input;
  on_conflict?: InputMaybe<Reward_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_TypesArgs = {
  objects: Array<Role_Types_Insert_Input>;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Types_OneArgs = {
  object: Role_Types_Insert_Input;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rss_FeedsArgs = {
  objects: Array<Rss_Feeds_Insert_Input>;
  on_conflict?: InputMaybe<Rss_Feeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rss_Feeds_OneArgs = {
  object: Rss_Feeds_Insert_Input;
  on_conflict?: InputMaybe<Rss_Feeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staking_Reward_PostersArgs = {
  objects: Array<Staking_Reward_Posters_Insert_Input>;
  on_conflict?: InputMaybe<Staking_Reward_Posters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staking_Reward_Posters_OneArgs = {
  object: Staking_Reward_Posters_Insert_Input;
  on_conflict?: InputMaybe<Staking_Reward_Posters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staking_Reward_StakersArgs = {
  objects: Array<Staking_Reward_Stakers_Insert_Input>;
  on_conflict?: InputMaybe<Staking_Reward_Stakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staking_Reward_Stakers_OneArgs = {
  object: Staking_Reward_Stakers_Insert_Input;
  on_conflict?: InputMaybe<Staking_Reward_Stakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubdivisionsArgs = {
  objects: Array<Subdivisions_Insert_Input>;
  on_conflict?: InputMaybe<Subdivisions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subdivisions_OneArgs = {
  object: Subdivisions_Insert_Input;
  on_conflict?: InputMaybe<Subdivisions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_SectionsArgs = {
  objects: Array<Task_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Task_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Sections_OneArgs = {
  object: Task_Sections_Insert_Input;
  on_conflict?: InputMaybe<Task_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_TypesArgs = {
  objects: Array<Task_Types_Insert_Input>;
  on_conflict?: InputMaybe<Task_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_Types_OneArgs = {
  object: Task_Types_Insert_Input;
  on_conflict?: InputMaybe<Task_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tasks_DoneArgs = {
  objects: Array<Tasks_Done_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Done_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tasks_Done_OneArgs = {
  object: Tasks_Done_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Done_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_TypesArgs = {
  objects: Array<Verification_Types_Insert_Input>;
  on_conflict?: InputMaybe<Verification_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_Types_OneArgs = {
  object: Verification_Types_Insert_Input;
  on_conflict?: InputMaybe<Verification_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verified_Nft_Project_KindsArgs = {
  objects: Array<Verified_Nft_Project_Kinds_Insert_Input>;
  on_conflict?: InputMaybe<Verified_Nft_Project_Kinds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verified_Nft_Project_Kinds_OneArgs = {
  object: Verified_Nft_Project_Kinds_Insert_Input;
  on_conflict?: InputMaybe<Verified_Nft_Project_Kinds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verified_Nft_ProjectsArgs = {
  objects: Array<Verified_Nft_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Verified_Nft_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verified_Nft_Projects_OneArgs = {
  object: Verified_Nft_Projects_Insert_Input;
  on_conflict?: InputMaybe<Verified_Nft_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VideosArgs = {
  objects: Array<Videos_Insert_Input>;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_OneArgs = {
  object: Videos_Insert_Input;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WalletsArgs = {
  objects: Array<Wallets_Insert_Input>;
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallets_OneArgs = {
  object: Wallets_Insert_Input;
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallets_ResolvesArgs = {
  objects: Array<Wallets_Resolves_Insert_Input>;
  on_conflict?: InputMaybe<Wallets_Resolves_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallets_Resolves_OneArgs = {
  object: Wallets_Resolves_Insert_Input;
  on_conflict?: InputMaybe<Wallets_Resolves_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Yield_SourcesArgs = {
  objects: Array<Yield_Sources_Insert_Input>;
  on_conflict?: InputMaybe<Yield_Sources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Yield_Sources_OneArgs = {
  object: Yield_Sources_Insert_Input;
  on_conflict?: InputMaybe<Yield_Sources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootJoinCommunityArgs = {
  communityId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootLeaveCommunityArgs = {
  communityId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootLensApiAuthenticateArgs = {
  address: Scalars['String'];
  sig: Scalars['String'];
};


/** mutation root */
export type Mutation_RootLensCreateProfileArgs = {
  handle: Scalars['String'];
  walletAddress: Scalars['String'];
};


/** mutation root */
export type Mutation_RootLensSetDispatcherExecuteArgs = {
  lensProfileId: Scalars['String'];
  sig: Scalars['String'];
};


/** mutation root */
export type Mutation_RootParticipateCampaignWithSigArgs = {
  address: Scalars['String'];
  campaignId: Scalars['Int'];
  message: Scalars['String'];
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPerkClaimArgs = {
  perkId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootPerkDeleteArgs = {
  perkId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootPerkUpsertArgs = {
  perk: PerkUpsertInput;
};


/** mutation root */
export type Mutation_RootPointDropArgs = {
  accountId: Array<InputMaybe<Scalars['String']>>;
  points: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootPointEventArgs = {
  accountId: Array<InputMaybe<Scalars['String']>>;
  awaitResponse?: InputMaybe<Scalars['Boolean']>;
  eventType: ManualEventTypes;
  note: Scalars['String'];
  points: Scalars['Int'];
  push: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootPostFrameActionArgs = {
  action: FrameActionDetailsInput;
  frameId: Scalars['String'];
  method: FrameMethodEnum;
  postId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPostReactionArgs = {
  postId: Scalars['String'];
  reactionType?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootRefetchFrameArgs = {
  identifier?: InputMaybe<Scalars['String']>;
  identifierType?: InputMaybe<RefetchFrameIdentifierType>;
};


/** mutation root */
export type Mutation_RootRefetchMissingFarcasterNamesArgs = {
  fetchAll?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootRefetchMissingNftImagesArgs = {
  createdAfter: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootRefreshMaterialViewArgs = {
  targetTable: RefreshableMaterializedView;
};


/** mutation root */
export type Mutation_RootRetryFarcasterCommentArgs = {
  commentId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRetryLensCommentArgs = {
  commentId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRewardRedeemArgs = {
  rewardTypeId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSetBlockArgs = {
  block: Scalars['Boolean'];
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSetCommunityModeratorsArgs = {
  communityIds?: InputMaybe<Array<Scalars['String']>>;
  isPromoting?: InputMaybe<Scalars['Boolean']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};


/** mutation root */
export type Mutation_RootSetFollowArgs = {
  follow: Scalars['Boolean'];
  followType?: InputMaybe<FollowType>;
  profileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSetFrenArgs = {
  id: Scalars['String'];
  value: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootSetLevelArgs = {
  id: Scalars['String'];
  value: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetPrimaryWalletArgs = {
  walletAddress: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSetProfileBadgeArgs = {
  id: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSetProfileVerificationArgs = {
  id: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSetVerifiedArgs = {
  id: Scalars['String'];
  value: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootSetXmtpWalletArgs = {
  walletAddress: Scalars['String'];
};


/** mutation root */
export type Mutation_RootShadowBanTopicArgs = {
  topicId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootShadowBanUserArgs = {
  userIdToBan: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSyncTokenArgs = {
  captchaResponse?: InputMaybe<Scalars['String']>;
  inviteCode?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTimeoutUserArgs = {
  days: Scalars['Int'];
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTogglePinPostArgs = {
  pinType: PinType;
  postId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTranslateCommentArgs = {
  commentId: Scalars['String'];
  toLanguage: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTranslatePostArgs = {
  postId: Scalars['String'];
  toLanguage: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTriggerTaskDoneArgs = {
  taskType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdatePostArgs = {
  link?: InputMaybe<LinkInput>;
  post: UpdatePostInput;
};


/** mutation root */
export type Mutation_RootUpdate_Account_TypesArgs = {
  _set?: InputMaybe<Account_Types_Set_Input>;
  where: Account_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Types_By_PkArgs = {
  _set?: InputMaybe<Account_Types_Set_Input>;
  pk_columns: Account_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Types_ManyArgs = {
  updates: Array<Account_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _set?: InputMaybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _set?: InputMaybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_ManyArgs = {
  updates: Array<Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Portal_WhitelistArgs = {
  _set?: InputMaybe<Admin_Portal_Whitelist_Set_Input>;
  where: Admin_Portal_Whitelist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Portal_Whitelist_By_PkArgs = {
  _set?: InputMaybe<Admin_Portal_Whitelist_Set_Input>;
  pk_columns: Admin_Portal_Whitelist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Portal_Whitelist_ManyArgs = {
  updates: Array<Admin_Portal_Whitelist_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AirdropArgs = {
  _inc?: InputMaybe<Airdrop_Inc_Input>;
  _set?: InputMaybe<Airdrop_Set_Input>;
  where: Airdrop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Airdrop_By_PkArgs = {
  _inc?: InputMaybe<Airdrop_Inc_Input>;
  _set?: InputMaybe<Airdrop_Set_Input>;
  pk_columns: Airdrop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Airdrop_ManyArgs = {
  updates: Array<Airdrop_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Async_StatusArgs = {
  _set?: InputMaybe<Async_Status_Set_Input>;
  where: Async_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Async_Status_By_PkArgs = {
  _set?: InputMaybe<Async_Status_Set_Input>;
  pk_columns: Async_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Async_Status_ManyArgs = {
  updates: Array<Async_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AudiosArgs = {
  _set?: InputMaybe<Audios_Set_Input>;
  where: Audios_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audios_By_PkArgs = {
  _set?: InputMaybe<Audios_Set_Input>;
  pk_columns: Audios_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audios_ManyArgs = {
  updates: Array<Audios_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_TypesArgs = {
  _set?: InputMaybe<Badge_Types_Set_Input>;
  where: Badge_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Types_By_PkArgs = {
  _set?: InputMaybe<Badge_Types_Set_Input>;
  pk_columns: Badge_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Types_ManyArgs = {
  updates: Array<Badge_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Blocked_ProfilesArgs = {
  _set?: InputMaybe<Blocked_Profiles_Set_Input>;
  where: Blocked_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blocked_Profiles_By_PkArgs = {
  _set?: InputMaybe<Blocked_Profiles_Set_Input>;
  pk_columns: Blocked_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blocked_Profiles_ManyArgs = {
  updates: Array<Blocked_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_SignaturesArgs = {
  _inc?: InputMaybe<Campaign_Signatures_Inc_Input>;
  _set?: InputMaybe<Campaign_Signatures_Set_Input>;
  where: Campaign_Signatures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Signatures_By_PkArgs = {
  _inc?: InputMaybe<Campaign_Signatures_Inc_Input>;
  _set?: InputMaybe<Campaign_Signatures_Set_Input>;
  pk_columns: Campaign_Signatures_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Signatures_ManyArgs = {
  updates: Array<Campaign_Signatures_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cc_ProfilesArgs = {
  _inc?: InputMaybe<Cc_Profiles_Inc_Input>;
  _set?: InputMaybe<Cc_Profiles_Set_Input>;
  where: Cc_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cc_Profiles_By_PkArgs = {
  _inc?: InputMaybe<Cc_Profiles_Inc_Input>;
  _set?: InputMaybe<Cc_Profiles_Set_Input>;
  pk_columns: Cc_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cc_Profiles_ManyArgs = {
  updates: Array<Cc_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CitiesArgs = {
  _append?: InputMaybe<Cities_Append_Input>;
  _delete_at_path?: InputMaybe<Cities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cities_Delete_Key_Input>;
  _prepend?: InputMaybe<Cities_Prepend_Input>;
  _set?: InputMaybe<Cities_Set_Input>;
  where: Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cities_By_PkArgs = {
  _append?: InputMaybe<Cities_Append_Input>;
  _delete_at_path?: InputMaybe<Cities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cities_Delete_Key_Input>;
  _prepend?: InputMaybe<Cities_Prepend_Input>;
  _set?: InputMaybe<Cities_Set_Input>;
  pk_columns: Cities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cities_ManyArgs = {
  updates: Array<Cities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Claimed_YieldArgs = {
  _inc?: InputMaybe<Claimed_Yield_Inc_Input>;
  _set?: InputMaybe<Claimed_Yield_Set_Input>;
  where: Claimed_Yield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Claimed_Yield_By_PkArgs = {
  _inc?: InputMaybe<Claimed_Yield_Inc_Input>;
  _set?: InputMaybe<Claimed_Yield_Set_Input>;
  pk_columns: Claimed_Yield_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Claimed_Yield_ManyArgs = {
  updates: Array<Claimed_Yield_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_AggregatesArgs = {
  _inc?: InputMaybe<Comment_Aggregates_Inc_Input>;
  _set?: InputMaybe<Comment_Aggregates_Set_Input>;
  where: Comment_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Aggregates_By_PkArgs = {
  _inc?: InputMaybe<Comment_Aggregates_Inc_Input>;
  _set?: InputMaybe<Comment_Aggregates_Set_Input>;
  pk_columns: Comment_Aggregates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Aggregates_ManyArgs = {
  updates: Array<Comment_Aggregates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Profile_TagsArgs = {
  _inc?: InputMaybe<Comment_Profile_Tags_Inc_Input>;
  _set?: InputMaybe<Comment_Profile_Tags_Set_Input>;
  where: Comment_Profile_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Profile_Tags_By_PkArgs = {
  _inc?: InputMaybe<Comment_Profile_Tags_Inc_Input>;
  _set?: InputMaybe<Comment_Profile_Tags_Set_Input>;
  pk_columns: Comment_Profile_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Profile_Tags_ManyArgs = {
  updates: Array<Comment_Profile_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_VotesArgs = {
  _inc?: InputMaybe<Comment_Votes_Inc_Input>;
  _set?: InputMaybe<Comment_Votes_Set_Input>;
  where: Comment_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Votes_By_PkArgs = {
  _inc?: InputMaybe<Comment_Votes_Inc_Input>;
  _set?: InputMaybe<Comment_Votes_Set_Input>;
  pk_columns: Comment_Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Votes_ManyArgs = {
  updates: Array<Comment_Votes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommunitiesArgs = {
  _set?: InputMaybe<Communities_Set_Input>;
  where: Communities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Communities_By_PkArgs = {
  _set?: InputMaybe<Communities_Set_Input>;
  pk_columns: Communities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Communities_ManyArgs = {
  updates: Array<Communities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_AggregatesArgs = {
  _inc?: InputMaybe<Community_Aggregates_Inc_Input>;
  _set?: InputMaybe<Community_Aggregates_Set_Input>;
  where: Community_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Aggregates_By_PkArgs = {
  _inc?: InputMaybe<Community_Aggregates_Inc_Input>;
  _set?: InputMaybe<Community_Aggregates_Set_Input>;
  pk_columns: Community_Aggregates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Aggregates_ManyArgs = {
  updates: Array<Community_Aggregates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_CategoriesArgs = {
  _set?: InputMaybe<Community_Categories_Set_Input>;
  where: Community_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Categories_By_PkArgs = {
  _set?: InputMaybe<Community_Categories_Set_Input>;
  pk_columns: Community_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Categories_ManyArgs = {
  updates: Array<Community_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_CollectionsArgs = {
  _set?: InputMaybe<Community_Content_Collections_Set_Input>;
  where: Community_Content_Collections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_Collections_By_PkArgs = {
  _set?: InputMaybe<Community_Content_Collections_Set_Input>;
  pk_columns: Community_Content_Collections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_Collections_ManyArgs = {
  updates: Array<Community_Content_Collections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_QuestionsArgs = {
  _set?: InputMaybe<Community_Content_Questions_Set_Input>;
  where: Community_Content_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_Questions_By_PkArgs = {
  _set?: InputMaybe<Community_Content_Questions_Set_Input>;
  pk_columns: Community_Content_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_Questions_ManyArgs = {
  updates: Array<Community_Content_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_VotesArgs = {
  _inc?: InputMaybe<Community_Content_Votes_Inc_Input>;
  _set?: InputMaybe<Community_Content_Votes_Set_Input>;
  where: Community_Content_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_Votes_By_PkArgs = {
  _inc?: InputMaybe<Community_Content_Votes_Inc_Input>;
  _set?: InputMaybe<Community_Content_Votes_Set_Input>;
  pk_columns: Community_Content_Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Content_Votes_ManyArgs = {
  updates: Array<Community_Content_Votes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_ContentsArgs = {
  _set?: InputMaybe<Community_Contents_Set_Input>;
  where: Community_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Contents_By_PkArgs = {
  _set?: InputMaybe<Community_Contents_Set_Input>;
  pk_columns: Community_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Contents_ManyArgs = {
  updates: Array<Community_Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_MembersArgs = {
  _set?: InputMaybe<Community_Members_Set_Input>;
  where: Community_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Members_By_PkArgs = {
  _set?: InputMaybe<Community_Members_Set_Input>;
  pk_columns: Community_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Members_ManyArgs = {
  updates: Array<Community_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Nft_ProjectsArgs = {
  _inc?: InputMaybe<Community_Nft_Projects_Inc_Input>;
  _set?: InputMaybe<Community_Nft_Projects_Set_Input>;
  where: Community_Nft_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Nft_Projects_By_PkArgs = {
  _inc?: InputMaybe<Community_Nft_Projects_Inc_Input>;
  _set?: InputMaybe<Community_Nft_Projects_Set_Input>;
  pk_columns: Community_Nft_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Nft_Projects_ManyArgs = {
  updates: Array<Community_Nft_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Community_StaffpicksArgs = {
  _set?: InputMaybe<Community_Staffpicks_Set_Input>;
  where: Community_Staffpicks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Staffpicks_By_PkArgs = {
  _set?: InputMaybe<Community_Staffpicks_Set_Input>;
  pk_columns: Community_Staffpicks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Community_Staffpicks_ManyArgs = {
  updates: Array<Community_Staffpicks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Connected_NftsArgs = {
  _inc?: InputMaybe<Connected_Nfts_Inc_Input>;
  _set?: InputMaybe<Connected_Nfts_Set_Input>;
  where: Connected_Nfts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Connected_Nfts_By_PkArgs = {
  _inc?: InputMaybe<Connected_Nfts_Inc_Input>;
  _set?: InputMaybe<Connected_Nfts_Set_Input>;
  pk_columns: Connected_Nfts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Connected_Nfts_ManyArgs = {
  updates: Array<Connected_Nfts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContinentsArgs = {
  _set?: InputMaybe<Continents_Set_Input>;
  where: Continents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Continents_By_PkArgs = {
  _set?: InputMaybe<Continents_Set_Input>;
  pk_columns: Continents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Continents_ManyArgs = {
  updates: Array<Continents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountriesArgs = {
  _set?: InputMaybe<Countries_Set_Input>;
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_By_PkArgs = {
  _set?: InputMaybe<Countries_Set_Input>;
  pk_columns: Countries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_ManyArgs = {
  updates: Array<Countries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cred_ScoresArgs = {
  _inc?: InputMaybe<Cred_Scores_Inc_Input>;
  _set?: InputMaybe<Cred_Scores_Set_Input>;
  where: Cred_Scores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cred_Scores_By_PkArgs = {
  _inc?: InputMaybe<Cred_Scores_Inc_Input>;
  _set?: InputMaybe<Cred_Scores_Set_Input>;
  pk_columns: Cred_Scores_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cred_Scores_ManyArgs = {
  updates: Array<Cred_Scores_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_IsocodesArgs = {
  _set?: InputMaybe<Currency_Isocodes_Set_Input>;
  where: Currency_Isocodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_Isocodes_By_PkArgs = {
  _set?: InputMaybe<Currency_Isocodes_Set_Input>;
  pk_columns: Currency_Isocodes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_Isocodes_ManyArgs = {
  updates: Array<Currency_Isocodes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_StakingArgs = {
  _set?: InputMaybe<Daily_Staking_Set_Input>;
  where: Daily_Staking_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Staking_By_PkArgs = {
  _set?: InputMaybe<Daily_Staking_Set_Input>;
  pk_columns: Daily_Staking_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Staking_ManyArgs = {
  updates: Array<Daily_Staking_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Embedded_UrlsArgs = {
  _inc?: InputMaybe<Embedded_Urls_Inc_Input>;
  _set?: InputMaybe<Embedded_Urls_Set_Input>;
  where: Embedded_Urls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Embedded_Urls_By_PkArgs = {
  _inc?: InputMaybe<Embedded_Urls_Inc_Input>;
  _set?: InputMaybe<Embedded_Urls_Set_Input>;
  pk_columns: Embedded_Urls_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Embedded_Urls_ManyArgs = {
  updates: Array<Embedded_Urls_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_CastsArgs = {
  _append?: InputMaybe<Farcaster_Casts_Append_Input>;
  _delete_at_path?: InputMaybe<Farcaster_Casts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Farcaster_Casts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Farcaster_Casts_Delete_Key_Input>;
  _inc?: InputMaybe<Farcaster_Casts_Inc_Input>;
  _prepend?: InputMaybe<Farcaster_Casts_Prepend_Input>;
  _set?: InputMaybe<Farcaster_Casts_Set_Input>;
  where: Farcaster_Casts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Casts_By_PkArgs = {
  _append?: InputMaybe<Farcaster_Casts_Append_Input>;
  _delete_at_path?: InputMaybe<Farcaster_Casts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Farcaster_Casts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Farcaster_Casts_Delete_Key_Input>;
  _inc?: InputMaybe<Farcaster_Casts_Inc_Input>;
  _prepend?: InputMaybe<Farcaster_Casts_Prepend_Input>;
  _set?: InputMaybe<Farcaster_Casts_Set_Input>;
  pk_columns: Farcaster_Casts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Casts_ManyArgs = {
  updates: Array<Farcaster_Casts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_InvitesArgs = {
  _set?: InputMaybe<Farcaster_Invites_Set_Input>;
  where: Farcaster_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Invites_By_PkArgs = {
  _set?: InputMaybe<Farcaster_Invites_Set_Input>;
  pk_columns: Farcaster_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Invites_ManyArgs = {
  updates: Array<Farcaster_Invites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Power_Badge_UsersArgs = {
  _set?: InputMaybe<Farcaster_Power_Badge_Users_Set_Input>;
  where: Farcaster_Power_Badge_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Power_Badge_Users_By_PkArgs = {
  _set?: InputMaybe<Farcaster_Power_Badge_Users_Set_Input>;
  pk_columns: Farcaster_Power_Badge_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Power_Badge_Users_ManyArgs = {
  updates: Array<Farcaster_Power_Badge_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_ProfilesArgs = {
  _inc?: InputMaybe<Farcaster_Profiles_Inc_Input>;
  _set?: InputMaybe<Farcaster_Profiles_Set_Input>;
  where: Farcaster_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Profiles_By_PkArgs = {
  _inc?: InputMaybe<Farcaster_Profiles_Inc_Input>;
  _set?: InputMaybe<Farcaster_Profiles_Set_Input>;
  pk_columns: Farcaster_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Profiles_ManyArgs = {
  updates: Array<Farcaster_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_ReactionsArgs = {
  _inc?: InputMaybe<Farcaster_Reactions_Inc_Input>;
  _set?: InputMaybe<Farcaster_Reactions_Set_Input>;
  where: Farcaster_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Reactions_By_PkArgs = {
  _inc?: InputMaybe<Farcaster_Reactions_Inc_Input>;
  _set?: InputMaybe<Farcaster_Reactions_Set_Input>;
  pk_columns: Farcaster_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Reactions_ManyArgs = {
  updates: Array<Farcaster_Reactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_SignersArgs = {
  _set?: InputMaybe<Farcaster_Signers_Set_Input>;
  where: Farcaster_Signers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Signers_By_PkArgs = {
  _set?: InputMaybe<Farcaster_Signers_Set_Input>;
  pk_columns: Farcaster_Signers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farcaster_Signers_ManyArgs = {
  updates: Array<Farcaster_Signers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Flag_TypesArgs = {
  _set?: InputMaybe<Flag_Types_Set_Input>;
  where: Flag_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Flag_Types_By_PkArgs = {
  _set?: InputMaybe<Flag_Types_Set_Input>;
  pk_columns: Flag_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Flag_Types_ManyArgs = {
  updates: Array<Flag_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FlagsArgs = {
  _set?: InputMaybe<Flags_Set_Input>;
  where: Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Flags_By_PkArgs = {
  _set?: InputMaybe<Flags_Set_Input>;
  pk_columns: Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Flags_ManyArgs = {
  updates: Array<Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FollowersArgs = {
  _set?: InputMaybe<Followers_Set_Input>;
  where: Followers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Followers_By_PkArgs = {
  _set?: InputMaybe<Followers_Set_Input>;
  pk_columns: Followers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Followers_ManyArgs = {
  updates: Array<Followers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Frame_ActionArgs = {
  _set?: InputMaybe<Frame_Action_Set_Input>;
  where: Frame_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Frame_Action_By_PkArgs = {
  _set?: InputMaybe<Frame_Action_Set_Input>;
  pk_columns: Frame_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Frame_Action_ManyArgs = {
  updates: Array<Frame_Action_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Frame_ButtonsArgs = {
  _inc?: InputMaybe<Frame_Buttons_Inc_Input>;
  _set?: InputMaybe<Frame_Buttons_Set_Input>;
  where: Frame_Buttons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Frame_Buttons_By_PkArgs = {
  _inc?: InputMaybe<Frame_Buttons_Inc_Input>;
  _set?: InputMaybe<Frame_Buttons_Set_Input>;
  pk_columns: Frame_Buttons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Frame_Buttons_ManyArgs = {
  updates: Array<Frame_Buttons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FramesArgs = {
  _append?: InputMaybe<Frames_Append_Input>;
  _delete_at_path?: InputMaybe<Frames_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Frames_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Frames_Delete_Key_Input>;
  _prepend?: InputMaybe<Frames_Prepend_Input>;
  _set?: InputMaybe<Frames_Set_Input>;
  where: Frames_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Frames_By_PkArgs = {
  _append?: InputMaybe<Frames_Append_Input>;
  _delete_at_path?: InputMaybe<Frames_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Frames_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Frames_Delete_Key_Input>;
  _prepend?: InputMaybe<Frames_Prepend_Input>;
  _set?: InputMaybe<Frames_Set_Input>;
  pk_columns: Frames_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Frames_ManyArgs = {
  updates: Array<Frames_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImagesArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Images_By_PkArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  pk_columns: Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Images_ManyArgs = {
  updates: Array<Images_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Item_OthersArgs = {
  _set?: InputMaybe<Item_Others_Set_Input>;
  where: Item_Others_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Item_Others_By_PkArgs = {
  _set?: InputMaybe<Item_Others_Set_Input>;
  pk_columns: Item_Others_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Item_Others_ManyArgs = {
  updates: Array<Item_Others_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Item_TypesArgs = {
  _set?: InputMaybe<Item_Types_Set_Input>;
  where: Item_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Item_Types_By_PkArgs = {
  _set?: InputMaybe<Item_Types_Set_Input>;
  pk_columns: Item_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Item_Types_ManyArgs = {
  updates: Array<Item_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ItemsArgs = {
  _set?: InputMaybe<Items_Set_Input>;
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_By_PkArgs = {
  _set?: InputMaybe<Items_Set_Input>;
  pk_columns: Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_ManyArgs = {
  updates: Array<Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Knex_MigrationsArgs = {
  _inc?: InputMaybe<Knex_Migrations_Inc_Input>;
  _set?: InputMaybe<Knex_Migrations_Set_Input>;
  where: Knex_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Knex_Migrations_By_PkArgs = {
  _inc?: InputMaybe<Knex_Migrations_Inc_Input>;
  _set?: InputMaybe<Knex_Migrations_Set_Input>;
  pk_columns: Knex_Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Knex_Migrations_LockArgs = {
  _inc?: InputMaybe<Knex_Migrations_Lock_Inc_Input>;
  _set?: InputMaybe<Knex_Migrations_Lock_Set_Input>;
  where: Knex_Migrations_Lock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Knex_Migrations_Lock_By_PkArgs = {
  _inc?: InputMaybe<Knex_Migrations_Lock_Inc_Input>;
  _set?: InputMaybe<Knex_Migrations_Lock_Set_Input>;
  pk_columns: Knex_Migrations_Lock_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Knex_Migrations_Lock_ManyArgs = {
  updates: Array<Knex_Migrations_Lock_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Knex_Migrations_ManyArgs = {
  updates: Array<Knex_Migrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Language_IsocodesArgs = {
  _set?: InputMaybe<Language_Isocodes_Set_Input>;
  where: Language_Isocodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Language_Isocodes_By_PkArgs = {
  _set?: InputMaybe<Language_Isocodes_Set_Input>;
  pk_columns: Language_Isocodes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Language_Isocodes_ManyArgs = {
  updates: Array<Language_Isocodes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Api_Access_TokensArgs = {
  _set?: InputMaybe<Lens_Api_Access_Tokens_Set_Input>;
  where: Lens_Api_Access_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Api_Access_Tokens_By_PkArgs = {
  _set?: InputMaybe<Lens_Api_Access_Tokens_Set_Input>;
  pk_columns: Lens_Api_Access_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Api_Access_Tokens_ManyArgs = {
  updates: Array<Lens_Api_Access_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Api_Refresh_TokensArgs = {
  _set?: InputMaybe<Lens_Api_Refresh_Tokens_Set_Input>;
  where: Lens_Api_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Api_Refresh_Tokens_By_PkArgs = {
  _set?: InputMaybe<Lens_Api_Refresh_Tokens_Set_Input>;
  pk_columns: Lens_Api_Refresh_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Api_Refresh_Tokens_ManyArgs = {
  updates: Array<Lens_Api_Refresh_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Appid_MappingsArgs = {
  _set?: InputMaybe<Lens_Appid_Mappings_Set_Input>;
  where: Lens_Appid_Mappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Appid_Mappings_By_PkArgs = {
  _set?: InputMaybe<Lens_Appid_Mappings_Set_Input>;
  pk_columns: Lens_Appid_Mappings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Appid_Mappings_ManyArgs = {
  updates: Array<Lens_Appid_Mappings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_CollectsArgs = {
  _inc?: InputMaybe<Lens_Collects_Inc_Input>;
  _set?: InputMaybe<Lens_Collects_Set_Input>;
  where: Lens_Collects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Collects_By_PkArgs = {
  _inc?: InputMaybe<Lens_Collects_Inc_Input>;
  _set?: InputMaybe<Lens_Collects_Set_Input>;
  pk_columns: Lens_Collects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Collects_ManyArgs = {
  updates: Array<Lens_Collects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Custodial_AccountsArgs = {
  _set?: InputMaybe<Lens_Custodial_Accounts_Set_Input>;
  where: Lens_Custodial_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Custodial_Accounts_By_PkArgs = {
  _set?: InputMaybe<Lens_Custodial_Accounts_Set_Input>;
  pk_columns: Lens_Custodial_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Custodial_Accounts_ManyArgs = {
  updates: Array<Lens_Custodial_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_EventsArgs = {
  _inc?: InputMaybe<Lens_Events_Inc_Input>;
  _set?: InputMaybe<Lens_Events_Set_Input>;
  where: Lens_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Events_By_PkArgs = {
  _inc?: InputMaybe<Lens_Events_Inc_Input>;
  _set?: InputMaybe<Lens_Events_Set_Input>;
  pk_columns: Lens_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Events_ManyArgs = {
  updates: Array<Lens_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_FollowersArgs = {
  _inc?: InputMaybe<Lens_Followers_Inc_Input>;
  _set?: InputMaybe<Lens_Followers_Set_Input>;
  where: Lens_Followers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Followers_By_PkArgs = {
  _inc?: InputMaybe<Lens_Followers_Inc_Input>;
  _set?: InputMaybe<Lens_Followers_Set_Input>;
  pk_columns: Lens_Followers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Followers_ManyArgs = {
  updates: Array<Lens_Followers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Followers_V2Args = {
  _set?: InputMaybe<Lens_Followers_V2_Set_Input>;
  where: Lens_Followers_V2_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Followers_V2_By_PkArgs = {
  _set?: InputMaybe<Lens_Followers_V2_Set_Input>;
  pk_columns: Lens_Followers_V2_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Followers_V2_ManyArgs = {
  updates: Array<Lens_Followers_V2_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_IndexerArgs = {
  _inc?: InputMaybe<Lens_Indexer_Inc_Input>;
  _set?: InputMaybe<Lens_Indexer_Set_Input>;
  where: Lens_Indexer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Indexer_By_PkArgs = {
  _inc?: InputMaybe<Lens_Indexer_Inc_Input>;
  _set?: InputMaybe<Lens_Indexer_Set_Input>;
  pk_columns: Lens_Indexer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Indexer_ManyArgs = {
  updates: Array<Lens_Indexer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_InvitesArgs = {
  _inc?: InputMaybe<Lens_Invites_Inc_Input>;
  _set?: InputMaybe<Lens_Invites_Set_Input>;
  where: Lens_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Invites_By_PkArgs = {
  _inc?: InputMaybe<Lens_Invites_Inc_Input>;
  _set?: InputMaybe<Lens_Invites_Set_Input>;
  pk_columns: Lens_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Invites_ManyArgs = {
  updates: Array<Lens_Invites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Post_Lens_Pub_TypesArgs = {
  _set?: InputMaybe<Lens_Post_Lens_Pub_Types_Set_Input>;
  where: Lens_Post_Lens_Pub_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Post_Lens_Pub_Types_By_PkArgs = {
  _set?: InputMaybe<Lens_Post_Lens_Pub_Types_Set_Input>;
  pk_columns: Lens_Post_Lens_Pub_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Post_Lens_Pub_Types_ManyArgs = {
  updates: Array<Lens_Post_Lens_Pub_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_PostsArgs = {
  _inc?: InputMaybe<Lens_Posts_Inc_Input>;
  _set?: InputMaybe<Lens_Posts_Set_Input>;
  where: Lens_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Posts_By_PkArgs = {
  _inc?: InputMaybe<Lens_Posts_Inc_Input>;
  _set?: InputMaybe<Lens_Posts_Set_Input>;
  pk_columns: Lens_Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Posts_ManyArgs = {
  updates: Array<Lens_Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_ProfilesArgs = {
  _inc?: InputMaybe<Lens_Profiles_Inc_Input>;
  _set?: InputMaybe<Lens_Profiles_Set_Input>;
  where: Lens_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Profiles_By_PkArgs = {
  _inc?: InputMaybe<Lens_Profiles_Inc_Input>;
  _set?: InputMaybe<Lens_Profiles_Set_Input>;
  pk_columns: Lens_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Profiles_ManyArgs = {
  updates: Array<Lens_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Reaction_TypesArgs = {
  _set?: InputMaybe<Lens_Reaction_Types_Set_Input>;
  where: Lens_Reaction_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Reaction_Types_By_PkArgs = {
  _set?: InputMaybe<Lens_Reaction_Types_Set_Input>;
  pk_columns: Lens_Reaction_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Reaction_Types_ManyArgs = {
  updates: Array<Lens_Reaction_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_ReactionsArgs = {
  _set?: InputMaybe<Lens_Reactions_Set_Input>;
  where: Lens_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Reactions_By_PkArgs = {
  _set?: InputMaybe<Lens_Reactions_Set_Input>;
  pk_columns: Lens_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Reactions_ManyArgs = {
  updates: Array<Lens_Reactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Tx_Status_EnumArgs = {
  _set?: InputMaybe<Lens_Tx_Status_Enum_Set_Input>;
  where: Lens_Tx_Status_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Tx_Status_Enum_By_PkArgs = {
  _set?: InputMaybe<Lens_Tx_Status_Enum_Set_Input>;
  pk_columns: Lens_Tx_Status_Enum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lens_Tx_Status_Enum_ManyArgs = {
  updates: Array<Lens_Tx_Status_Enum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LevelsArgs = {
  _inc?: InputMaybe<Levels_Inc_Input>;
  _set?: InputMaybe<Levels_Set_Input>;
  where: Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Levels_By_PkArgs = {
  _inc?: InputMaybe<Levels_Inc_Input>;
  _set?: InputMaybe<Levels_Set_Input>;
  pk_columns: Levels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Levels_ManyArgs = {
  updates: Array<Levels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LinksArgs = {
  _set?: InputMaybe<Links_Set_Input>;
  where: Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Links_By_PkArgs = {
  _set?: InputMaybe<Links_Set_Input>;
  pk_columns: Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Links_ManyArgs = {
  updates: Array<Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Locked_BalancesArgs = {
  _inc?: InputMaybe<Locked_Balances_Inc_Input>;
  _set?: InputMaybe<Locked_Balances_Set_Input>;
  where: Locked_Balances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locked_Balances_By_PkArgs = {
  _inc?: InputMaybe<Locked_Balances_Inc_Input>;
  _set?: InputMaybe<Locked_Balances_Set_Input>;
  pk_columns: Locked_Balances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locked_Balances_ManyArgs = {
  updates: Array<Locked_Balances_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MediasArgs = {
  _inc?: InputMaybe<Medias_Inc_Input>;
  _set?: InputMaybe<Medias_Set_Input>;
  where: Medias_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Medias_By_PkArgs = {
  _inc?: InputMaybe<Medias_Inc_Input>;
  _set?: InputMaybe<Medias_Set_Input>;
  pk_columns: Medias_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Medias_ManyArgs = {
  updates: Array<Medias_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Moca_IdsArgs = {
  _set?: InputMaybe<Moca_Ids_Set_Input>;
  where: Moca_Ids_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Moca_Ids_By_PkArgs = {
  _set?: InputMaybe<Moca_Ids_Set_Input>;
  pk_columns: Moca_Ids_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Moca_Ids_ManyArgs = {
  updates: Array<Moca_Ids_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Setting_TypesArgs = {
  _set?: InputMaybe<Notification_Setting_Types_Set_Input>;
  where: Notification_Setting_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Setting_Types_By_PkArgs = {
  _set?: InputMaybe<Notification_Setting_Types_Set_Input>;
  pk_columns: Notification_Setting_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Setting_Types_ManyArgs = {
  updates: Array<Notification_Setting_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_SettingsArgs = {
  _set?: InputMaybe<Notification_Settings_Set_Input>;
  where: Notification_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Settings_By_PkArgs = {
  _set?: InputMaybe<Notification_Settings_Set_Input>;
  pk_columns: Notification_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Settings_ManyArgs = {
  updates: Array<Notification_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PerksArgs = {
  _inc?: InputMaybe<Perks_Inc_Input>;
  _set?: InputMaybe<Perks_Set_Input>;
  where: Perks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Perks_By_PkArgs = {
  _inc?: InputMaybe<Perks_Inc_Input>;
  _set?: InputMaybe<Perks_Set_Input>;
  pk_columns: Perks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Perks_ClaimedArgs = {
  _inc?: InputMaybe<Perks_Claimed_Inc_Input>;
  _set?: InputMaybe<Perks_Claimed_Set_Input>;
  where: Perks_Claimed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Perks_Claimed_By_PkArgs = {
  _inc?: InputMaybe<Perks_Claimed_Inc_Input>;
  _set?: InputMaybe<Perks_Claimed_Set_Input>;
  pk_columns: Perks_Claimed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Perks_Claimed_ManyArgs = {
  updates: Array<Perks_Claimed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Perks_ManyArgs = {
  updates: Array<Perks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_AggregatesArgs = {
  _inc?: InputMaybe<Post_Aggregates_Inc_Input>;
  _set?: InputMaybe<Post_Aggregates_Set_Input>;
  where: Post_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Aggregates_By_PkArgs = {
  _inc?: InputMaybe<Post_Aggregates_Inc_Input>;
  _set?: InputMaybe<Post_Aggregates_Set_Input>;
  pk_columns: Post_Aggregates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Aggregates_ManyArgs = {
  updates: Array<Post_Aggregates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Profile_TagsArgs = {
  _inc?: InputMaybe<Post_Profile_Tags_Inc_Input>;
  _set?: InputMaybe<Post_Profile_Tags_Set_Input>;
  where: Post_Profile_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Profile_Tags_By_PkArgs = {
  _inc?: InputMaybe<Post_Profile_Tags_Inc_Input>;
  _set?: InputMaybe<Post_Profile_Tags_Set_Input>;
  pk_columns: Post_Profile_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Profile_Tags_ManyArgs = {
  updates: Array<Post_Profile_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Reaction_TypesArgs = {
  _set?: InputMaybe<Post_Reaction_Types_Set_Input>;
  where: Post_Reaction_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Reaction_Types_By_PkArgs = {
  _set?: InputMaybe<Post_Reaction_Types_Set_Input>;
  pk_columns: Post_Reaction_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Reaction_Types_ManyArgs = {
  updates: Array<Post_Reaction_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_ReactionsArgs = {
  _set?: InputMaybe<Post_Reactions_Set_Input>;
  where: Post_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Reactions_By_PkArgs = {
  _set?: InputMaybe<Post_Reactions_Set_Input>;
  pk_columns: Post_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Reactions_ManyArgs = {
  updates: Array<Post_Reactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_AggregatesArgs = {
  _inc?: InputMaybe<Profile_Aggregates_Inc_Input>;
  _set?: InputMaybe<Profile_Aggregates_Set_Input>;
  where: Profile_Aggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Aggregates_By_PkArgs = {
  _inc?: InputMaybe<Profile_Aggregates_Inc_Input>;
  _set?: InputMaybe<Profile_Aggregates_Set_Input>;
  pk_columns: Profile_Aggregates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Aggregates_ManyArgs = {
  updates: Array<Profile_Aggregates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProfilesArgs = {
  _set?: InputMaybe<Profiles_Set_Input>;
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_By_PkArgs = {
  _set?: InputMaybe<Profiles_Set_Input>;
  pk_columns: Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_ManyArgs = {
  updates: Array<Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Push_TokensArgs = {
  _set?: InputMaybe<Push_Tokens_Set_Input>;
  where: Push_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Push_Tokens_By_PkArgs = {
  _set?: InputMaybe<Push_Tokens_Set_Input>;
  pk_columns: Push_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Push_Tokens_ManyArgs = {
  updates: Array<Push_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_CommentsArgs = {
  _set?: InputMaybe<Recommendation_Comments_Set_Input>;
  where: Recommendation_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Comments_By_PkArgs = {
  _set?: InputMaybe<Recommendation_Comments_Set_Input>;
  pk_columns: Recommendation_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Comments_ManyArgs = {
  updates: Array<Recommendation_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_VotesArgs = {
  _inc?: InputMaybe<Recommendation_Votes_Inc_Input>;
  _set?: InputMaybe<Recommendation_Votes_Set_Input>;
  where: Recommendation_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Votes_By_PkArgs = {
  _inc?: InputMaybe<Recommendation_Votes_Inc_Input>;
  _set?: InputMaybe<Recommendation_Votes_Set_Input>;
  pk_columns: Recommendation_Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Votes_ManyArgs = {
  updates: Array<Recommendation_Votes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RecommendationsArgs = {
  _inc?: InputMaybe<Recommendations_Inc_Input>;
  _set?: InputMaybe<Recommendations_Set_Input>;
  where: Recommendations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendations_By_PkArgs = {
  _inc?: InputMaybe<Recommendations_Inc_Input>;
  _set?: InputMaybe<Recommendations_Set_Input>;
  pk_columns: Recommendations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendations_ManyArgs = {
  updates: Array<Recommendations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reported_ContentArgs = {
  _set?: InputMaybe<Reported_Content_Set_Input>;
  where: Reported_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reported_Content_By_PkArgs = {
  _set?: InputMaybe<Reported_Content_Set_Input>;
  pk_columns: Reported_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reported_Content_ManyArgs = {
  updates: Array<Reported_Content_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_BrandsArgs = {
  _set?: InputMaybe<Reward_Brands_Set_Input>;
  where: Reward_Brands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Brands_By_PkArgs = {
  _set?: InputMaybe<Reward_Brands_Set_Input>;
  pk_columns: Reward_Brands_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Brands_ManyArgs = {
  updates: Array<Reward_Brands_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_ClaimsArgs = {
  _set?: InputMaybe<Reward_Claims_Set_Input>;
  where: Reward_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Claims_By_PkArgs = {
  _set?: InputMaybe<Reward_Claims_Set_Input>;
  pk_columns: Reward_Claims_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Claims_ManyArgs = {
  updates: Array<Reward_Claims_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_TypesArgs = {
  _inc?: InputMaybe<Reward_Types_Inc_Input>;
  _set?: InputMaybe<Reward_Types_Set_Input>;
  where: Reward_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Types_By_PkArgs = {
  _inc?: InputMaybe<Reward_Types_Inc_Input>;
  _set?: InputMaybe<Reward_Types_Set_Input>;
  pk_columns: Reward_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Types_ManyArgs = {
  updates: Array<Reward_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_TypesArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_By_PkArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  pk_columns: Role_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_ManyArgs = {
  updates: Array<Role_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rss_FeedsArgs = {
  _set?: InputMaybe<Rss_Feeds_Set_Input>;
  where: Rss_Feeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rss_Feeds_By_PkArgs = {
  _set?: InputMaybe<Rss_Feeds_Set_Input>;
  pk_columns: Rss_Feeds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rss_Feeds_ManyArgs = {
  updates: Array<Rss_Feeds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staking_Reward_PostersArgs = {
  _inc?: InputMaybe<Staking_Reward_Posters_Inc_Input>;
  _set?: InputMaybe<Staking_Reward_Posters_Set_Input>;
  where: Staking_Reward_Posters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staking_Reward_Posters_By_PkArgs = {
  _inc?: InputMaybe<Staking_Reward_Posters_Inc_Input>;
  _set?: InputMaybe<Staking_Reward_Posters_Set_Input>;
  pk_columns: Staking_Reward_Posters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staking_Reward_Posters_ManyArgs = {
  updates: Array<Staking_Reward_Posters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staking_Reward_StakersArgs = {
  _inc?: InputMaybe<Staking_Reward_Stakers_Inc_Input>;
  _set?: InputMaybe<Staking_Reward_Stakers_Set_Input>;
  where: Staking_Reward_Stakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staking_Reward_Stakers_By_PkArgs = {
  _inc?: InputMaybe<Staking_Reward_Stakers_Inc_Input>;
  _set?: InputMaybe<Staking_Reward_Stakers_Set_Input>;
  pk_columns: Staking_Reward_Stakers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staking_Reward_Stakers_ManyArgs = {
  updates: Array<Staking_Reward_Stakers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubdivisionsArgs = {
  _set?: InputMaybe<Subdivisions_Set_Input>;
  where: Subdivisions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subdivisions_By_PkArgs = {
  _set?: InputMaybe<Subdivisions_Set_Input>;
  pk_columns: Subdivisions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subdivisions_ManyArgs = {
  updates: Array<Subdivisions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_SectionsArgs = {
  _set?: InputMaybe<Task_Sections_Set_Input>;
  where: Task_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Sections_By_PkArgs = {
  _set?: InputMaybe<Task_Sections_Set_Input>;
  pk_columns: Task_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Sections_ManyArgs = {
  updates: Array<Task_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_TypesArgs = {
  _set?: InputMaybe<Task_Types_Set_Input>;
  where: Task_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Types_By_PkArgs = {
  _set?: InputMaybe<Task_Types_Set_Input>;
  pk_columns: Task_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_Types_ManyArgs = {
  updates: Array<Task_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_DoneArgs = {
  _inc?: InputMaybe<Tasks_Done_Inc_Input>;
  _set?: InputMaybe<Tasks_Done_Set_Input>;
  where: Tasks_Done_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_Done_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Done_Inc_Input>;
  _set?: InputMaybe<Tasks_Done_Set_Input>;
  pk_columns: Tasks_Done_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_Done_ManyArgs = {
  updates: Array<Tasks_Done_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_TypesArgs = {
  _set?: InputMaybe<Verification_Types_Set_Input>;
  where: Verification_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Types_By_PkArgs = {
  _set?: InputMaybe<Verification_Types_Set_Input>;
  pk_columns: Verification_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Types_ManyArgs = {
  updates: Array<Verification_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Verified_Nft_Project_KindsArgs = {
  _set?: InputMaybe<Verified_Nft_Project_Kinds_Set_Input>;
  where: Verified_Nft_Project_Kinds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verified_Nft_Project_Kinds_By_PkArgs = {
  _set?: InputMaybe<Verified_Nft_Project_Kinds_Set_Input>;
  pk_columns: Verified_Nft_Project_Kinds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verified_Nft_Project_Kinds_ManyArgs = {
  updates: Array<Verified_Nft_Project_Kinds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Verified_Nft_ProjectsArgs = {
  _inc?: InputMaybe<Verified_Nft_Projects_Inc_Input>;
  _set?: InputMaybe<Verified_Nft_Projects_Set_Input>;
  where: Verified_Nft_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verified_Nft_Projects_By_PkArgs = {
  _inc?: InputMaybe<Verified_Nft_Projects_Inc_Input>;
  _set?: InputMaybe<Verified_Nft_Projects_Set_Input>;
  pk_columns: Verified_Nft_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verified_Nft_Projects_ManyArgs = {
  updates: Array<Verified_Nft_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VideosArgs = {
  _inc?: InputMaybe<Videos_Inc_Input>;
  _set?: InputMaybe<Videos_Set_Input>;
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_By_PkArgs = {
  _inc?: InputMaybe<Videos_Inc_Input>;
  _set?: InputMaybe<Videos_Set_Input>;
  pk_columns: Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_ManyArgs = {
  updates: Array<Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WalletsArgs = {
  _set?: InputMaybe<Wallets_Set_Input>;
  where: Wallets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_By_PkArgs = {
  _set?: InputMaybe<Wallets_Set_Input>;
  pk_columns: Wallets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_ManyArgs = {
  updates: Array<Wallets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_ResolvesArgs = {
  _inc?: InputMaybe<Wallets_Resolves_Inc_Input>;
  _set?: InputMaybe<Wallets_Resolves_Set_Input>;
  where: Wallets_Resolves_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_Resolves_By_PkArgs = {
  _inc?: InputMaybe<Wallets_Resolves_Inc_Input>;
  _set?: InputMaybe<Wallets_Resolves_Set_Input>;
  pk_columns: Wallets_Resolves_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_Resolves_ManyArgs = {
  updates: Array<Wallets_Resolves_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Yield_SourcesArgs = {
  _inc?: InputMaybe<Yield_Sources_Inc_Input>;
  _set?: InputMaybe<Yield_Sources_Set_Input>;
  where: Yield_Sources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Yield_Sources_By_PkArgs = {
  _inc?: InputMaybe<Yield_Sources_Inc_Input>;
  _set?: InputMaybe<Yield_Sources_Set_Input>;
  pk_columns: Yield_Sources_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Yield_Sources_ManyArgs = {
  updates: Array<Yield_Sources_Updates>;
};


/** mutation root */
export type Mutation_RootUploadImageFromUrlArgs = {
  imageUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootVoteCommentArgs = {
  commentId: Scalars['String'];
  vote: Vote;
};


/** mutation root */
export type Mutation_RootVotePostArgs = {
  postId: Scalars['String'];
  vote: Vote;
};

/** columns and relationships of "notification_setting_types" */
export type Notification_Setting_Types = {
  __typename?: 'notification_setting_types';
  /** An array relationship */
  notification_settings: Array<Notification_Settings>;
  /** An aggregate relationship */
  notification_settings_aggregate: Notification_Settings_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "notification_setting_types" */
export type Notification_Setting_TypesNotification_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


/** columns and relationships of "notification_setting_types" */
export type Notification_Setting_TypesNotification_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};

/** aggregated selection of "notification_setting_types" */
export type Notification_Setting_Types_Aggregate = {
  __typename?: 'notification_setting_types_aggregate';
  aggregate?: Maybe<Notification_Setting_Types_Aggregate_Fields>;
  nodes: Array<Notification_Setting_Types>;
};

/** aggregate fields of "notification_setting_types" */
export type Notification_Setting_Types_Aggregate_Fields = {
  __typename?: 'notification_setting_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Setting_Types_Max_Fields>;
  min?: Maybe<Notification_Setting_Types_Min_Fields>;
};


/** aggregate fields of "notification_setting_types" */
export type Notification_Setting_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Setting_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_setting_types". All fields are combined with a logical 'AND'. */
export type Notification_Setting_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Setting_Types_Bool_Exp>>;
  _not?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Setting_Types_Bool_Exp>>;
  notification_settings?: InputMaybe<Notification_Settings_Bool_Exp>;
  notification_settings_aggregate?: InputMaybe<Notification_Settings_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_setting_types" */
export enum Notification_Setting_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationSettingTypesPkey = 'notification_setting_types_pkey'
}

export enum Notification_Setting_Types_Enum {
  CollectionContribution = 'collection_contribution',
  CommentSame = 'comment_same',
  CommentUpvote = 'comment_upvote',
  CommunityCollection = 'community_collection',
  CommunityMember = 'community_member',
  CommunityQuestion = 'community_question',
  CustomPushNotification = 'custom_push_notification',
  DailyStakeReceived = 'daily_stake_received',
  DailyStakeReminder = 'daily_stake_reminder',
  LensCollectRejected = 'lens_collect_rejected',
  LensCommentRejected = 'lens_comment_rejected',
  LensFollowsRejected = 'lens_follows_rejected',
  LensPostsRejected = 'lens_posts_rejected',
  LensProfilesFulfilled = 'lens_profiles_fulfilled',
  LensProfilesPending = 'lens_profiles_pending',
  LensProfilesRejected = 'lens_profiles_rejected',
  NewBadgeAdded = 'new_badge_added',
  NewFollower = 'new_follower',
  NewFrenAdded = 'new_fren_added',
  NewUpvote = 'new_upvote',
  NewVerification = 'new_verification',
  PhaverAppVersion = 'phaver_app_version',
  PhaverNewFeature = 'phaver_new_feature',
  QuestionAnswer = 'question_answer',
  ReachedLevelTwoReferralBonus = 'reached_level_two_referral_bonus',
  RecommendationReply = 'recommendation_reply',
  RecommendationWishlisted = 'recommendation_wishlisted',
  ReferredUserReachedLevelTwoBonus = 'referred_user_reached_level_two_bonus',
  RepliedToComment = 'replied_to_comment',
  SuggestionCommunities = 'suggestion_communities',
  SuggestionFollow = 'suggestion_follow',
  TaggedInComment = 'tagged_in_comment',
  TaggedInPost = 'tagged_in_post'
}

/** Boolean expression to compare columns of type "notification_setting_types_enum". All fields are combined with logical 'AND'. */
export type Notification_Setting_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Setting_Types_Enum>;
  _in?: InputMaybe<Array<Notification_Setting_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Setting_Types_Enum>;
  _nin?: InputMaybe<Array<Notification_Setting_Types_Enum>>;
};

/** input type for inserting data into table "notification_setting_types" */
export type Notification_Setting_Types_Insert_Input = {
  notification_settings?: InputMaybe<Notification_Settings_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Setting_Types_Max_Fields = {
  __typename?: 'notification_setting_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Setting_Types_Min_Fields = {
  __typename?: 'notification_setting_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_setting_types" */
export type Notification_Setting_Types_Mutation_Response = {
  __typename?: 'notification_setting_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Setting_Types>;
};

/** input type for inserting object relation for remote table "notification_setting_types" */
export type Notification_Setting_Types_Obj_Rel_Insert_Input = {
  data: Notification_Setting_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Setting_Types_On_Conflict>;
};

/** on_conflict condition type for table "notification_setting_types" */
export type Notification_Setting_Types_On_Conflict = {
  constraint: Notification_Setting_Types_Constraint;
  update_columns?: Array<Notification_Setting_Types_Update_Column>;
  where?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_setting_types". */
export type Notification_Setting_Types_Order_By = {
  notification_settings_aggregate?: InputMaybe<Notification_Settings_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_setting_types */
export type Notification_Setting_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "notification_setting_types" */
export enum Notification_Setting_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_setting_types" */
export type Notification_Setting_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notification_setting_types" */
export type Notification_Setting_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Setting_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Setting_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_setting_types" */
export enum Notification_Setting_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Notification_Setting_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Setting_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Setting_Types_Bool_Exp;
};

/** columns and relationships of "notification_settings" */
export type Notification_Settings = {
  __typename?: 'notification_settings';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  enabled: Scalars['Boolean'];
  eventType: Notification_Setting_Types_Enum;
  /** An object relationship */
  notification_setting_type: Notification_Setting_Types;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "notification_settings" */
export type Notification_Settings_Aggregate = {
  __typename?: 'notification_settings_aggregate';
  aggregate?: Maybe<Notification_Settings_Aggregate_Fields>;
  nodes: Array<Notification_Settings>;
};

export type Notification_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Notification_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Notification_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Notification_Settings_Aggregate_Bool_Exp_Count>;
};

export type Notification_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Settings_Select_Column_Notification_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notification_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notification_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Settings_Select_Column_Notification_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notification_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notification_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notification_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notification_settings" */
export type Notification_Settings_Aggregate_Fields = {
  __typename?: 'notification_settings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Settings_Max_Fields>;
  min?: Maybe<Notification_Settings_Min_Fields>;
};


/** aggregate fields of "notification_settings" */
export type Notification_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notification_settings" */
export type Notification_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Settings_Max_Order_By>;
  min?: InputMaybe<Notification_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_settings" */
export type Notification_Settings_Arr_Rel_Insert_Input = {
  data: Array<Notification_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_settings". All fields are combined with a logical 'AND'. */
export type Notification_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Settings_Bool_Exp>>;
  _not?: InputMaybe<Notification_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Settings_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  eventType?: InputMaybe<Notification_Setting_Types_Enum_Comparison_Exp>;
  notification_setting_type?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_settings" */
export enum Notification_Settings_Constraint {
  /** unique or primary key constraint on columns "event_type", "account_id" */
  NotificationSettingsPkey = 'notification_settings_pkey'
}

/** input type for inserting data into table "notification_settings" */
export type Notification_Settings_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  eventType?: InputMaybe<Notification_Setting_Types_Enum>;
  notification_setting_type?: InputMaybe<Notification_Setting_Types_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Notification_Settings_Max_Fields = {
  __typename?: 'notification_settings_max_fields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "notification_settings" */
export type Notification_Settings_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Settings_Min_Fields = {
  __typename?: 'notification_settings_min_fields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "notification_settings" */
export type Notification_Settings_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_settings" */
export type Notification_Settings_Mutation_Response = {
  __typename?: 'notification_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Settings>;
};

/** on_conflict condition type for table "notification_settings" */
export type Notification_Settings_On_Conflict = {
  constraint: Notification_Settings_Constraint;
  update_columns?: Array<Notification_Settings_Update_Column>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_settings". */
export type Notification_Settings_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  eventType?: InputMaybe<Order_By>;
  notification_setting_type?: InputMaybe<Notification_Setting_Types_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_settings */
export type Notification_Settings_Pk_Columns_Input = {
  accountId: Scalars['String'];
  eventType: Notification_Setting_Types_Enum;
};

/** select columns of table "notification_settings" */
export enum Notification_Settings_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  EventType = 'eventType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "notification_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_settings" */
export enum Notification_Settings_Select_Column_Notification_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** select "notification_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_settings" */
export enum Notification_Settings_Select_Column_Notification_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** input type for updating data in table "notification_settings" */
export type Notification_Settings_Set_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  eventType?: InputMaybe<Notification_Setting_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "notification_settings" */
export type Notification_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Settings_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  eventType?: InputMaybe<Notification_Setting_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "notification_settings" */
export enum Notification_Settings_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  EventType = 'eventType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Notification_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Settings_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "perks" */
export type Perks = {
  __typename?: 'perks';
  availability?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['timestamptz']>;
  externalLink?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageId?: Maybe<Scalars['String']>;
  isHidden: Scalars['Boolean'];
  minCredLevel?: Maybe<Scalars['Int']>;
  pointCost?: Maybe<Scalars['Int']>;
  requiredSocialHolding?: Maybe<Scalars['numeric']>;
  startsAt?: Maybe<Scalars['timestamptz']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  verifiedNftProjectsId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "perks" */
export type Perks_Aggregate = {
  __typename?: 'perks_aggregate';
  aggregate?: Maybe<Perks_Aggregate_Fields>;
  nodes: Array<Perks>;
};

/** aggregate fields of "perks" */
export type Perks_Aggregate_Fields = {
  __typename?: 'perks_aggregate_fields';
  avg?: Maybe<Perks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Perks_Max_Fields>;
  min?: Maybe<Perks_Min_Fields>;
  stddev?: Maybe<Perks_Stddev_Fields>;
  stddev_pop?: Maybe<Perks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Perks_Stddev_Samp_Fields>;
  sum?: Maybe<Perks_Sum_Fields>;
  var_pop?: Maybe<Perks_Var_Pop_Fields>;
  var_samp?: Maybe<Perks_Var_Samp_Fields>;
  variance?: Maybe<Perks_Variance_Fields>;
};


/** aggregate fields of "perks" */
export type Perks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Perks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Perks_Avg_Fields = {
  __typename?: 'perks_avg_fields';
  availability?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minCredLevel?: Maybe<Scalars['Float']>;
  pointCost?: Maybe<Scalars['Float']>;
  requiredSocialHolding?: Maybe<Scalars['Float']>;
  verifiedNftProjectsId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "perks". All fields are combined with a logical 'AND'. */
export type Perks_Bool_Exp = {
  _and?: InputMaybe<Array<Perks_Bool_Exp>>;
  _not?: InputMaybe<Perks_Bool_Exp>;
  _or?: InputMaybe<Array<Perks_Bool_Exp>>;
  availability?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  endsAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalLink?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  isHidden?: InputMaybe<Boolean_Comparison_Exp>;
  minCredLevel?: InputMaybe<Int_Comparison_Exp>;
  pointCost?: InputMaybe<Int_Comparison_Exp>;
  requiredSocialHolding?: InputMaybe<Numeric_Comparison_Exp>;
  startsAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  verifiedNftProjectsId?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "perks_claimed" */
export type Perks_Claimed = {
  __typename?: 'perks_claimed';
  createdAt: Scalars['timestamptz'];
  perkId: Scalars['Int'];
  profileId: Scalars['String'];
};

/** aggregated selection of "perks_claimed" */
export type Perks_Claimed_Aggregate = {
  __typename?: 'perks_claimed_aggregate';
  aggregate?: Maybe<Perks_Claimed_Aggregate_Fields>;
  nodes: Array<Perks_Claimed>;
};

/** aggregate fields of "perks_claimed" */
export type Perks_Claimed_Aggregate_Fields = {
  __typename?: 'perks_claimed_aggregate_fields';
  avg?: Maybe<Perks_Claimed_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Perks_Claimed_Max_Fields>;
  min?: Maybe<Perks_Claimed_Min_Fields>;
  stddev?: Maybe<Perks_Claimed_Stddev_Fields>;
  stddev_pop?: Maybe<Perks_Claimed_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Perks_Claimed_Stddev_Samp_Fields>;
  sum?: Maybe<Perks_Claimed_Sum_Fields>;
  var_pop?: Maybe<Perks_Claimed_Var_Pop_Fields>;
  var_samp?: Maybe<Perks_Claimed_Var_Samp_Fields>;
  variance?: Maybe<Perks_Claimed_Variance_Fields>;
};


/** aggregate fields of "perks_claimed" */
export type Perks_Claimed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Perks_Claimed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Perks_Claimed_Avg_Fields = {
  __typename?: 'perks_claimed_avg_fields';
  perkId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "perks_claimed". All fields are combined with a logical 'AND'. */
export type Perks_Claimed_Bool_Exp = {
  _and?: InputMaybe<Array<Perks_Claimed_Bool_Exp>>;
  _not?: InputMaybe<Perks_Claimed_Bool_Exp>;
  _or?: InputMaybe<Array<Perks_Claimed_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  perkId?: InputMaybe<Int_Comparison_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "perks_claimed" */
export enum Perks_Claimed_Constraint {
  /** unique or primary key constraint on columns "profile_id", "perk_id" */
  PerksClaimedPkey = 'perks_claimed_pkey'
}

/** input type for incrementing numeric columns in table "perks_claimed" */
export type Perks_Claimed_Inc_Input = {
  perkId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "perks_claimed" */
export type Perks_Claimed_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  perkId?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Perks_Claimed_Max_Fields = {
  __typename?: 'perks_claimed_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  perkId?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Perks_Claimed_Min_Fields = {
  __typename?: 'perks_claimed_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  perkId?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "perks_claimed" */
export type Perks_Claimed_Mutation_Response = {
  __typename?: 'perks_claimed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Perks_Claimed>;
};

/** on_conflict condition type for table "perks_claimed" */
export type Perks_Claimed_On_Conflict = {
  constraint: Perks_Claimed_Constraint;
  update_columns?: Array<Perks_Claimed_Update_Column>;
  where?: InputMaybe<Perks_Claimed_Bool_Exp>;
};

/** Ordering options when selecting data from "perks_claimed". */
export type Perks_Claimed_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  perkId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: perks_claimed */
export type Perks_Claimed_Pk_Columns_Input = {
  perkId: Scalars['Int'];
  profileId: Scalars['String'];
};

/** select columns of table "perks_claimed" */
export enum Perks_Claimed_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PerkId = 'perkId',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "perks_claimed" */
export type Perks_Claimed_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  perkId?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Perks_Claimed_Stddev_Fields = {
  __typename?: 'perks_claimed_stddev_fields';
  perkId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Perks_Claimed_Stddev_Pop_Fields = {
  __typename?: 'perks_claimed_stddev_pop_fields';
  perkId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Perks_Claimed_Stddev_Samp_Fields = {
  __typename?: 'perks_claimed_stddev_samp_fields';
  perkId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "perks_claimed" */
export type Perks_Claimed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Perks_Claimed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Perks_Claimed_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  perkId?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Perks_Claimed_Sum_Fields = {
  __typename?: 'perks_claimed_sum_fields';
  perkId?: Maybe<Scalars['Int']>;
};

/** update columns of table "perks_claimed" */
export enum Perks_Claimed_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PerkId = 'perkId',
  /** column name */
  ProfileId = 'profileId'
}

export type Perks_Claimed_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Perks_Claimed_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Perks_Claimed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Perks_Claimed_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Perks_Claimed_Var_Pop_Fields = {
  __typename?: 'perks_claimed_var_pop_fields';
  perkId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Perks_Claimed_Var_Samp_Fields = {
  __typename?: 'perks_claimed_var_samp_fields';
  perkId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Perks_Claimed_Variance_Fields = {
  __typename?: 'perks_claimed_variance_fields';
  perkId?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "perks" */
export enum Perks_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerksPkey = 'perks_pkey',
  /** unique or primary key constraint on columns "title" */
  PerksTitleKey = 'perks_title_key'
}

/** input type for incrementing numeric columns in table "perks" */
export type Perks_Inc_Input = {
  availability?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  minCredLevel?: InputMaybe<Scalars['Int']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  requiredSocialHolding?: InputMaybe<Scalars['numeric']>;
  verifiedNftProjectsId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "perks" */
export type Perks_Insert_Input = {
  availability?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['timestamptz']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  minCredLevel?: InputMaybe<Scalars['Int']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  requiredSocialHolding?: InputMaybe<Scalars['numeric']>;
  startsAt?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjectsId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Perks_Max_Fields = {
  __typename?: 'perks_max_fields';
  availability?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['timestamptz']>;
  externalLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageId?: Maybe<Scalars['String']>;
  minCredLevel?: Maybe<Scalars['Int']>;
  pointCost?: Maybe<Scalars['Int']>;
  requiredSocialHolding?: Maybe<Scalars['numeric']>;
  startsAt?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  verifiedNftProjectsId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Perks_Min_Fields = {
  __typename?: 'perks_min_fields';
  availability?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['timestamptz']>;
  externalLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageId?: Maybe<Scalars['String']>;
  minCredLevel?: Maybe<Scalars['Int']>;
  pointCost?: Maybe<Scalars['Int']>;
  requiredSocialHolding?: Maybe<Scalars['numeric']>;
  startsAt?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  verifiedNftProjectsId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "perks" */
export type Perks_Mutation_Response = {
  __typename?: 'perks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Perks>;
};

/** on_conflict condition type for table "perks" */
export type Perks_On_Conflict = {
  constraint: Perks_Constraint;
  update_columns?: Array<Perks_Update_Column>;
  where?: InputMaybe<Perks_Bool_Exp>;
};

/** Ordering options when selecting data from "perks". */
export type Perks_Order_By = {
  availability?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endsAt?: InputMaybe<Order_By>;
  externalLink?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  isHidden?: InputMaybe<Order_By>;
  minCredLevel?: InputMaybe<Order_By>;
  pointCost?: InputMaybe<Order_By>;
  requiredSocialHolding?: InputMaybe<Order_By>;
  startsAt?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  verifiedNftProjectsId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: perks */
export type Perks_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "perks" */
export enum Perks_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  ExternalLink = 'externalLink',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  MinCredLevel = 'minCredLevel',
  /** column name */
  PointCost = 'pointCost',
  /** column name */
  RequiredSocialHolding = 'requiredSocialHolding',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VerifiedNftProjectsId = 'verifiedNftProjectsId'
}

/** input type for updating data in table "perks" */
export type Perks_Set_Input = {
  availability?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['timestamptz']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  minCredLevel?: InputMaybe<Scalars['Int']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  requiredSocialHolding?: InputMaybe<Scalars['numeric']>;
  startsAt?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjectsId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Perks_Stddev_Fields = {
  __typename?: 'perks_stddev_fields';
  availability?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minCredLevel?: Maybe<Scalars['Float']>;
  pointCost?: Maybe<Scalars['Float']>;
  requiredSocialHolding?: Maybe<Scalars['Float']>;
  verifiedNftProjectsId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Perks_Stddev_Pop_Fields = {
  __typename?: 'perks_stddev_pop_fields';
  availability?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minCredLevel?: Maybe<Scalars['Float']>;
  pointCost?: Maybe<Scalars['Float']>;
  requiredSocialHolding?: Maybe<Scalars['Float']>;
  verifiedNftProjectsId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Perks_Stddev_Samp_Fields = {
  __typename?: 'perks_stddev_samp_fields';
  availability?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minCredLevel?: Maybe<Scalars['Float']>;
  pointCost?: Maybe<Scalars['Float']>;
  requiredSocialHolding?: Maybe<Scalars['Float']>;
  verifiedNftProjectsId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "perks" */
export type Perks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Perks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Perks_Stream_Cursor_Value_Input = {
  availability?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['timestamptz']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  minCredLevel?: InputMaybe<Scalars['Int']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  requiredSocialHolding?: InputMaybe<Scalars['numeric']>;
  startsAt?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedNftProjectsId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Perks_Sum_Fields = {
  __typename?: 'perks_sum_fields';
  availability?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  minCredLevel?: Maybe<Scalars['Int']>;
  pointCost?: Maybe<Scalars['Int']>;
  requiredSocialHolding?: Maybe<Scalars['numeric']>;
  verifiedNftProjectsId?: Maybe<Scalars['Int']>;
};

/** update columns of table "perks" */
export enum Perks_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  ExternalLink = 'externalLink',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  MinCredLevel = 'minCredLevel',
  /** column name */
  PointCost = 'pointCost',
  /** column name */
  RequiredSocialHolding = 'requiredSocialHolding',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VerifiedNftProjectsId = 'verifiedNftProjectsId'
}

export type Perks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Perks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Perks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Perks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Perks_Var_Pop_Fields = {
  __typename?: 'perks_var_pop_fields';
  availability?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minCredLevel?: Maybe<Scalars['Float']>;
  pointCost?: Maybe<Scalars['Float']>;
  requiredSocialHolding?: Maybe<Scalars['Float']>;
  verifiedNftProjectsId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Perks_Var_Samp_Fields = {
  __typename?: 'perks_var_samp_fields';
  availability?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minCredLevel?: Maybe<Scalars['Float']>;
  pointCost?: Maybe<Scalars['Float']>;
  requiredSocialHolding?: Maybe<Scalars['Float']>;
  verifiedNftProjectsId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Perks_Variance_Fields = {
  __typename?: 'perks_variance_fields';
  availability?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minCredLevel?: Maybe<Scalars['Float']>;
  pointCost?: Maybe<Scalars['Float']>;
  requiredSocialHolding?: Maybe<Scalars['Float']>;
  verifiedNftProjectsId?: Maybe<Scalars['Float']>;
};

export type PointDropResult = {
  __typename?: 'pointDropResult';
  count: Scalars['Int'];
  status: Scalars['String'];
};

export type PointEventResult = {
  __typename?: 'pointEventResult';
  count: Scalars['Int'];
  status: Scalars['String'];
};

/** columns and relationships of "post_aggregates" */
export type Post_Aggregates = {
  __typename?: 'post_aggregates';
  collects: Scalars['Int'];
  comments: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  dailyStakes: Scalars['Int'];
  id: Scalars['String'];
  mirrors: Scalars['Int'];
  /** An object relationship */
  recommendation: Recommendations;
  updated_at?: Maybe<Scalars['timestamptz']>;
  votes: Scalars['Int'];
};

/** aggregated selection of "post_aggregates" */
export type Post_Aggregates_Aggregate = {
  __typename?: 'post_aggregates_aggregate';
  aggregate?: Maybe<Post_Aggregates_Aggregate_Fields>;
  nodes: Array<Post_Aggregates>;
};

/** aggregate fields of "post_aggregates" */
export type Post_Aggregates_Aggregate_Fields = {
  __typename?: 'post_aggregates_aggregate_fields';
  avg?: Maybe<Post_Aggregates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Post_Aggregates_Max_Fields>;
  min?: Maybe<Post_Aggregates_Min_Fields>;
  stddev?: Maybe<Post_Aggregates_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Aggregates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Aggregates_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Aggregates_Sum_Fields>;
  var_pop?: Maybe<Post_Aggregates_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Aggregates_Var_Samp_Fields>;
  variance?: Maybe<Post_Aggregates_Variance_Fields>;
};


/** aggregate fields of "post_aggregates" */
export type Post_Aggregates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Aggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Post_Aggregates_Avg_Fields = {
  __typename?: 'post_aggregates_avg_fields';
  collects?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  dailyStakes?: Maybe<Scalars['Float']>;
  mirrors?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "post_aggregates". All fields are combined with a logical 'AND'. */
export type Post_Aggregates_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Aggregates_Bool_Exp>>;
  _not?: InputMaybe<Post_Aggregates_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Aggregates_Bool_Exp>>;
  collects?: InputMaybe<Int_Comparison_Exp>;
  comments?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dailyStakes?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  mirrors?: InputMaybe<Int_Comparison_Exp>;
  recommendation?: InputMaybe<Recommendations_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  votes?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_aggregates" */
export enum Post_Aggregates_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostAggregatesPkey = 'post_aggregates_pkey'
}

/** input type for incrementing numeric columns in table "post_aggregates" */
export type Post_Aggregates_Inc_Input = {
  collects?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['Int']>;
  dailyStakes?: InputMaybe<Scalars['Int']>;
  mirrors?: InputMaybe<Scalars['Int']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "post_aggregates" */
export type Post_Aggregates_Insert_Input = {
  collects?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dailyStakes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  mirrors?: InputMaybe<Scalars['Int']>;
  recommendation?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Post_Aggregates_Max_Fields = {
  __typename?: 'post_aggregates_max_fields';
  collects?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dailyStakes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mirrors?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  votes?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Post_Aggregates_Min_Fields = {
  __typename?: 'post_aggregates_min_fields';
  collects?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dailyStakes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mirrors?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  votes?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "post_aggregates" */
export type Post_Aggregates_Mutation_Response = {
  __typename?: 'post_aggregates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Aggregates>;
};

/** input type for inserting object relation for remote table "post_aggregates" */
export type Post_Aggregates_Obj_Rel_Insert_Input = {
  data: Post_Aggregates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Aggregates_On_Conflict>;
};

/** on_conflict condition type for table "post_aggregates" */
export type Post_Aggregates_On_Conflict = {
  constraint: Post_Aggregates_Constraint;
  update_columns?: Array<Post_Aggregates_Update_Column>;
  where?: InputMaybe<Post_Aggregates_Bool_Exp>;
};

/** Ordering options when selecting data from "post_aggregates". */
export type Post_Aggregates_Order_By = {
  collects?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dailyStakes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mirrors?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Recommendations_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  votes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_aggregates */
export type Post_Aggregates_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "post_aggregates" */
export enum Post_Aggregates_Select_Column {
  /** column name */
  Collects = 'collects',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DailyStakes = 'dailyStakes',
  /** column name */
  Id = 'id',
  /** column name */
  Mirrors = 'mirrors',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Votes = 'votes'
}

/** input type for updating data in table "post_aggregates" */
export type Post_Aggregates_Set_Input = {
  collects?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dailyStakes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  mirrors?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Post_Aggregates_Stddev_Fields = {
  __typename?: 'post_aggregates_stddev_fields';
  collects?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  dailyStakes?: Maybe<Scalars['Float']>;
  mirrors?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Post_Aggregates_Stddev_Pop_Fields = {
  __typename?: 'post_aggregates_stddev_pop_fields';
  collects?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  dailyStakes?: Maybe<Scalars['Float']>;
  mirrors?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Post_Aggregates_Stddev_Samp_Fields = {
  __typename?: 'post_aggregates_stddev_samp_fields';
  collects?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  dailyStakes?: Maybe<Scalars['Float']>;
  mirrors?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "post_aggregates" */
export type Post_Aggregates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Aggregates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Aggregates_Stream_Cursor_Value_Input = {
  collects?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dailyStakes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  mirrors?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  votes?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Post_Aggregates_Sum_Fields = {
  __typename?: 'post_aggregates_sum_fields';
  collects?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  dailyStakes?: Maybe<Scalars['Int']>;
  mirrors?: Maybe<Scalars['Int']>;
  votes?: Maybe<Scalars['Int']>;
};

/** update columns of table "post_aggregates" */
export enum Post_Aggregates_Update_Column {
  /** column name */
  Collects = 'collects',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DailyStakes = 'dailyStakes',
  /** column name */
  Id = 'id',
  /** column name */
  Mirrors = 'mirrors',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Votes = 'votes'
}

export type Post_Aggregates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Post_Aggregates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Aggregates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Aggregates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Post_Aggregates_Var_Pop_Fields = {
  __typename?: 'post_aggregates_var_pop_fields';
  collects?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  dailyStakes?: Maybe<Scalars['Float']>;
  mirrors?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Post_Aggregates_Var_Samp_Fields = {
  __typename?: 'post_aggregates_var_samp_fields';
  collects?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  dailyStakes?: Maybe<Scalars['Float']>;
  mirrors?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Post_Aggregates_Variance_Fields = {
  __typename?: 'post_aggregates_variance_fields';
  collects?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  dailyStakes?: Maybe<Scalars['Float']>;
  mirrors?: Maybe<Scalars['Float']>;
  votes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "post_profile_tags" */
export type Post_Profile_Tags = {
  __typename?: 'post_profile_tags';
  extFcName?: Maybe<Scalars['String']>;
  extFcTokenId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  matchedText: Scalars['String'];
  originalText: Scalars['String'];
  /** An object relationship */
  post: Recommendations;
  postId: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profiles>;
  profileId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "post_profile_tags" */
export type Post_Profile_Tags_Aggregate = {
  __typename?: 'post_profile_tags_aggregate';
  aggregate?: Maybe<Post_Profile_Tags_Aggregate_Fields>;
  nodes: Array<Post_Profile_Tags>;
};

export type Post_Profile_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Profile_Tags_Aggregate_Bool_Exp_Count>;
};

export type Post_Profile_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_profile_tags" */
export type Post_Profile_Tags_Aggregate_Fields = {
  __typename?: 'post_profile_tags_aggregate_fields';
  avg?: Maybe<Post_Profile_Tags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Post_Profile_Tags_Max_Fields>;
  min?: Maybe<Post_Profile_Tags_Min_Fields>;
  stddev?: Maybe<Post_Profile_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Profile_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Profile_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Profile_Tags_Sum_Fields>;
  var_pop?: Maybe<Post_Profile_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Profile_Tags_Var_Samp_Fields>;
  variance?: Maybe<Post_Profile_Tags_Variance_Fields>;
};


/** aggregate fields of "post_profile_tags" */
export type Post_Profile_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_profile_tags" */
export type Post_Profile_Tags_Aggregate_Order_By = {
  avg?: InputMaybe<Post_Profile_Tags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Profile_Tags_Max_Order_By>;
  min?: InputMaybe<Post_Profile_Tags_Min_Order_By>;
  stddev?: InputMaybe<Post_Profile_Tags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Post_Profile_Tags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Post_Profile_Tags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Post_Profile_Tags_Sum_Order_By>;
  var_pop?: InputMaybe<Post_Profile_Tags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Post_Profile_Tags_Var_Samp_Order_By>;
  variance?: InputMaybe<Post_Profile_Tags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "post_profile_tags" */
export type Post_Profile_Tags_Arr_Rel_Insert_Input = {
  data: Array<Post_Profile_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Profile_Tags_On_Conflict>;
};

/** aggregate avg on columns */
export type Post_Profile_Tags_Avg_Fields = {
  __typename?: 'post_profile_tags_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_profile_tags". All fields are combined with a logical 'AND'. */
export type Post_Profile_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Profile_Tags_Bool_Exp>>;
  _not?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Profile_Tags_Bool_Exp>>;
  extFcName?: InputMaybe<String_Comparison_Exp>;
  extFcTokenId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  matchedText?: InputMaybe<String_Comparison_Exp>;
  originalText?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_profile_tags" */
export enum Post_Profile_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostProfileTagsPkey = 'post_profile_tags_pkey'
}

/** input type for incrementing numeric columns in table "post_profile_tags" */
export type Post_Profile_Tags_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "post_profile_tags" */
export type Post_Profile_Tags_Insert_Input = {
  extFcName?: InputMaybe<Scalars['String']>;
  extFcTokenId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  matchedText?: InputMaybe<Scalars['String']>;
  originalText?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Post_Profile_Tags_Max_Fields = {
  __typename?: 'post_profile_tags_max_fields';
  extFcName?: Maybe<Scalars['String']>;
  extFcTokenId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  matchedText?: Maybe<Scalars['String']>;
  originalText?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Max_Order_By = {
  extFcName?: InputMaybe<Order_By>;
  extFcTokenId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matchedText?: InputMaybe<Order_By>;
  originalText?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Profile_Tags_Min_Fields = {
  __typename?: 'post_profile_tags_min_fields';
  extFcName?: Maybe<Scalars['String']>;
  extFcTokenId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  matchedText?: Maybe<Scalars['String']>;
  originalText?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Min_Order_By = {
  extFcName?: InputMaybe<Order_By>;
  extFcTokenId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matchedText?: InputMaybe<Order_By>;
  originalText?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_profile_tags" */
export type Post_Profile_Tags_Mutation_Response = {
  __typename?: 'post_profile_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Profile_Tags>;
};

/** on_conflict condition type for table "post_profile_tags" */
export type Post_Profile_Tags_On_Conflict = {
  constraint: Post_Profile_Tags_Constraint;
  update_columns?: Array<Post_Profile_Tags_Update_Column>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "post_profile_tags". */
export type Post_Profile_Tags_Order_By = {
  extFcName?: InputMaybe<Order_By>;
  extFcTokenId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matchedText?: InputMaybe<Order_By>;
  originalText?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_profile_tags */
export type Post_Profile_Tags_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "post_profile_tags" */
export enum Post_Profile_Tags_Select_Column {
  /** column name */
  ExtFcName = 'extFcName',
  /** column name */
  ExtFcTokenId = 'extFcTokenId',
  /** column name */
  Id = 'id',
  /** column name */
  MatchedText = 'matchedText',
  /** column name */
  OriginalText = 'originalText',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "post_profile_tags" */
export type Post_Profile_Tags_Set_Input = {
  extFcName?: InputMaybe<Scalars['String']>;
  extFcTokenId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  matchedText?: InputMaybe<Scalars['String']>;
  originalText?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Post_Profile_Tags_Stddev_Fields = {
  __typename?: 'post_profile_tags_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Post_Profile_Tags_Stddev_Pop_Fields = {
  __typename?: 'post_profile_tags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Post_Profile_Tags_Stddev_Samp_Fields = {
  __typename?: 'post_profile_tags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "post_profile_tags" */
export type Post_Profile_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Profile_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Profile_Tags_Stream_Cursor_Value_Input = {
  extFcName?: InputMaybe<Scalars['String']>;
  extFcTokenId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  matchedText?: InputMaybe<Scalars['String']>;
  originalText?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Post_Profile_Tags_Sum_Fields = {
  __typename?: 'post_profile_tags_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "post_profile_tags" */
export enum Post_Profile_Tags_Update_Column {
  /** column name */
  ExtFcName = 'extFcName',
  /** column name */
  ExtFcTokenId = 'extFcTokenId',
  /** column name */
  Id = 'id',
  /** column name */
  MatchedText = 'matchedText',
  /** column name */
  OriginalText = 'originalText',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId'
}

export type Post_Profile_Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Post_Profile_Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Profile_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Profile_Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Post_Profile_Tags_Var_Pop_Fields = {
  __typename?: 'post_profile_tags_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Post_Profile_Tags_Var_Samp_Fields = {
  __typename?: 'post_profile_tags_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Post_Profile_Tags_Variance_Fields = {
  __typename?: 'post_profile_tags_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "post_profile_tags" */
export type Post_Profile_Tags_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "post_reaction_counts" */
export type Post_Reaction_Counts = {
  __typename?: 'post_reaction_counts';
  count?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  reactionType?: Maybe<Scalars['String']>;
};

/** aggregated selection of "post_reaction_counts" */
export type Post_Reaction_Counts_Aggregate = {
  __typename?: 'post_reaction_counts_aggregate';
  aggregate?: Maybe<Post_Reaction_Counts_Aggregate_Fields>;
  nodes: Array<Post_Reaction_Counts>;
};

export type Post_Reaction_Counts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Reaction_Counts_Aggregate_Bool_Exp_Count>;
};

export type Post_Reaction_Counts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_reaction_counts" */
export type Post_Reaction_Counts_Aggregate_Fields = {
  __typename?: 'post_reaction_counts_aggregate_fields';
  avg?: Maybe<Post_Reaction_Counts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Post_Reaction_Counts_Max_Fields>;
  min?: Maybe<Post_Reaction_Counts_Min_Fields>;
  stddev?: Maybe<Post_Reaction_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Reaction_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Reaction_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Reaction_Counts_Sum_Fields>;
  var_pop?: Maybe<Post_Reaction_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Reaction_Counts_Var_Samp_Fields>;
  variance?: Maybe<Post_Reaction_Counts_Variance_Fields>;
};


/** aggregate fields of "post_reaction_counts" */
export type Post_Reaction_Counts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_reaction_counts" */
export type Post_Reaction_Counts_Aggregate_Order_By = {
  avg?: InputMaybe<Post_Reaction_Counts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Reaction_Counts_Max_Order_By>;
  min?: InputMaybe<Post_Reaction_Counts_Min_Order_By>;
  stddev?: InputMaybe<Post_Reaction_Counts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Post_Reaction_Counts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Post_Reaction_Counts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Post_Reaction_Counts_Sum_Order_By>;
  var_pop?: InputMaybe<Post_Reaction_Counts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Post_Reaction_Counts_Var_Samp_Order_By>;
  variance?: InputMaybe<Post_Reaction_Counts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "post_reaction_counts" */
export type Post_Reaction_Counts_Arr_Rel_Insert_Input = {
  data: Array<Post_Reaction_Counts_Insert_Input>;
};

/** aggregate avg on columns */
export type Post_Reaction_Counts_Avg_Fields = {
  __typename?: 'post_reaction_counts_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_reaction_counts". All fields are combined with a logical 'AND'. */
export type Post_Reaction_Counts_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Reaction_Counts_Bool_Exp>>;
  _not?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Reaction_Counts_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  reactionType?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "post_reaction_counts" */
export type Post_Reaction_Counts_Insert_Input = {
  count?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Post_Reaction_Counts_Max_Fields = {
  __typename?: 'post_reaction_counts_max_fields';
  count?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  reactionType?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Max_Order_By = {
  count?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  reactionType?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Reaction_Counts_Min_Fields = {
  __typename?: 'post_reaction_counts_min_fields';
  count?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  reactionType?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Min_Order_By = {
  count?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  reactionType?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "post_reaction_counts". */
export type Post_Reaction_Counts_Order_By = {
  count?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  reactionType?: InputMaybe<Order_By>;
};

/** select columns of table "post_reaction_counts" */
export enum Post_Reaction_Counts_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  PostId = 'postId',
  /** column name */
  ReactionType = 'reactionType'
}

/** aggregate stddev on columns */
export type Post_Reaction_Counts_Stddev_Fields = {
  __typename?: 'post_reaction_counts_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Post_Reaction_Counts_Stddev_Pop_Fields = {
  __typename?: 'post_reaction_counts_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Post_Reaction_Counts_Stddev_Samp_Fields = {
  __typename?: 'post_reaction_counts_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "post_reaction_counts" */
export type Post_Reaction_Counts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Reaction_Counts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Reaction_Counts_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Post_Reaction_Counts_Sum_Fields = {
  __typename?: 'post_reaction_counts_sum_fields';
  count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Post_Reaction_Counts_Var_Pop_Fields = {
  __typename?: 'post_reaction_counts_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Post_Reaction_Counts_Var_Samp_Fields = {
  __typename?: 'post_reaction_counts_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Post_Reaction_Counts_Variance_Fields = {
  __typename?: 'post_reaction_counts_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "post_reaction_counts" */
export type Post_Reaction_Counts_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** columns and relationships of "post_reaction_types" */
export type Post_Reaction_Types = {
  __typename?: 'post_reaction_types';
  /** An array relationship */
  post_reactions: Array<Post_Reactions>;
  /** An aggregate relationship */
  post_reactions_aggregate: Post_Reactions_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "post_reaction_types" */
export type Post_Reaction_TypesPost_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


/** columns and relationships of "post_reaction_types" */
export type Post_Reaction_TypesPost_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};

/** aggregated selection of "post_reaction_types" */
export type Post_Reaction_Types_Aggregate = {
  __typename?: 'post_reaction_types_aggregate';
  aggregate?: Maybe<Post_Reaction_Types_Aggregate_Fields>;
  nodes: Array<Post_Reaction_Types>;
};

/** aggregate fields of "post_reaction_types" */
export type Post_Reaction_Types_Aggregate_Fields = {
  __typename?: 'post_reaction_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Reaction_Types_Max_Fields>;
  min?: Maybe<Post_Reaction_Types_Min_Fields>;
};


/** aggregate fields of "post_reaction_types" */
export type Post_Reaction_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Reaction_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "post_reaction_types". All fields are combined with a logical 'AND'. */
export type Post_Reaction_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Reaction_Types_Bool_Exp>>;
  _not?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Reaction_Types_Bool_Exp>>;
  post_reactions?: InputMaybe<Post_Reactions_Bool_Exp>;
  post_reactions_aggregate?: InputMaybe<Post_Reactions_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_reaction_types" */
export enum Post_Reaction_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  PostReactionTypesPkey = 'post_reaction_types_pkey'
}

export enum Post_Reaction_Types_Enum {
  Facepalm = 'facepalm',
  Gm = 'gm',
  Lol = 'lol',
  LoveIt = 'love_it',
  Nsfw = 'nsfw',
  Sad = 'sad',
  Spam = 'spam',
  ToTheMoon = 'to_the_moon',
  Wagmi = 'wagmi',
  WrongTopic = 'wrong_topic'
}

/** Boolean expression to compare columns of type "post_reaction_types_enum". All fields are combined with logical 'AND'. */
export type Post_Reaction_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Post_Reaction_Types_Enum>;
  _in?: InputMaybe<Array<Post_Reaction_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Post_Reaction_Types_Enum>;
  _nin?: InputMaybe<Array<Post_Reaction_Types_Enum>>;
};

/** input type for inserting data into table "post_reaction_types" */
export type Post_Reaction_Types_Insert_Input = {
  post_reactions?: InputMaybe<Post_Reactions_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Post_Reaction_Types_Max_Fields = {
  __typename?: 'post_reaction_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Post_Reaction_Types_Min_Fields = {
  __typename?: 'post_reaction_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "post_reaction_types" */
export type Post_Reaction_Types_Mutation_Response = {
  __typename?: 'post_reaction_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Reaction_Types>;
};

/** input type for inserting object relation for remote table "post_reaction_types" */
export type Post_Reaction_Types_Obj_Rel_Insert_Input = {
  data: Post_Reaction_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Reaction_Types_On_Conflict>;
};

/** on_conflict condition type for table "post_reaction_types" */
export type Post_Reaction_Types_On_Conflict = {
  constraint: Post_Reaction_Types_Constraint;
  update_columns?: Array<Post_Reaction_Types_Update_Column>;
  where?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "post_reaction_types". */
export type Post_Reaction_Types_Order_By = {
  post_reactions_aggregate?: InputMaybe<Post_Reactions_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_reaction_types */
export type Post_Reaction_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "post_reaction_types" */
export enum Post_Reaction_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "post_reaction_types" */
export type Post_Reaction_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "post_reaction_types" */
export type Post_Reaction_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Reaction_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Reaction_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "post_reaction_types" */
export enum Post_Reaction_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Post_Reaction_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Reaction_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Reaction_Types_Bool_Exp;
};

/** columns and relationships of "post_reactions" */
export type Post_Reactions = {
  __typename?: 'post_reactions';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  post: Recommendations;
  postId: Scalars['String'];
  /** An object relationship */
  post_reaction_type: Post_Reaction_Types;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  reactionType: Post_Reaction_Types_Enum;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "post_reactions" */
export type Post_Reactions_Aggregate = {
  __typename?: 'post_reactions_aggregate';
  aggregate?: Maybe<Post_Reactions_Aggregate_Fields>;
  nodes: Array<Post_Reactions>;
};

export type Post_Reactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Reactions_Aggregate_Bool_Exp_Count>;
};

export type Post_Reactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Post_Reactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_reactions" */
export type Post_Reactions_Aggregate_Fields = {
  __typename?: 'post_reactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Reactions_Max_Fields>;
  min?: Maybe<Post_Reactions_Min_Fields>;
};


/** aggregate fields of "post_reactions" */
export type Post_Reactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_reactions" */
export type Post_Reactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Reactions_Max_Order_By>;
  min?: InputMaybe<Post_Reactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_reactions" */
export type Post_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Post_Reactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Reactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_reactions". All fields are combined with a logical 'AND'. */
export type Post_Reactions_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Reactions_Bool_Exp>>;
  _not?: InputMaybe<Post_Reactions_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Reactions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  post_reaction_type?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  reactionType?: InputMaybe<Post_Reaction_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_reactions" */
export enum Post_Reactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostReactionsPkey = 'post_reactions_pkey',
  /** unique or primary key constraint on columns "profile_id", "post_id" */
  PostReactionsPostIdProfileIdUnique = 'post_reactions_post_id_profile_id_unique'
}

/** input type for inserting data into table "post_reactions" */
export type Post_Reactions_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  post_reaction_type?: InputMaybe<Post_Reaction_Types_Obj_Rel_Insert_Input>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Post_Reaction_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Post_Reactions_Max_Fields = {
  __typename?: 'post_reactions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "post_reactions" */
export type Post_Reactions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Reactions_Min_Fields = {
  __typename?: 'post_reactions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "post_reactions" */
export type Post_Reactions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_reactions" */
export type Post_Reactions_Mutation_Response = {
  __typename?: 'post_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Reactions>;
};

/** on_conflict condition type for table "post_reactions" */
export type Post_Reactions_On_Conflict = {
  constraint: Post_Reactions_Constraint;
  update_columns?: Array<Post_Reactions_Update_Column>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};

/** Ordering options when selecting data from "post_reactions". */
export type Post_Reactions_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  post_reaction_type?: InputMaybe<Post_Reaction_Types_Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  reactionType?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_reactions */
export type Post_Reactions_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "post_reactions" */
export enum Post_Reactions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  ReactionType = 'reactionType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "post_reactions" */
export type Post_Reactions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Post_Reaction_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "post_reactions" */
export type Post_Reactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Reactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Reactions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Post_Reaction_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "post_reactions" */
export enum Post_Reactions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  ReactionType = 'reactionType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Post_Reactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Reactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Reactions_Bool_Exp;
};

/** columns and relationships of "profile_aggregates" */
export type Profile_Aggregates = {
  __typename?: 'profile_aggregates';
  followerCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
};

/** aggregated selection of "profile_aggregates" */
export type Profile_Aggregates_Aggregate = {
  __typename?: 'profile_aggregates_aggregate';
  aggregate?: Maybe<Profile_Aggregates_Aggregate_Fields>;
  nodes: Array<Profile_Aggregates>;
};

/** aggregate fields of "profile_aggregates" */
export type Profile_Aggregates_Aggregate_Fields = {
  __typename?: 'profile_aggregates_aggregate_fields';
  avg?: Maybe<Profile_Aggregates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Aggregates_Max_Fields>;
  min?: Maybe<Profile_Aggregates_Min_Fields>;
  stddev?: Maybe<Profile_Aggregates_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Aggregates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Aggregates_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Aggregates_Sum_Fields>;
  var_pop?: Maybe<Profile_Aggregates_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Aggregates_Var_Samp_Fields>;
  variance?: Maybe<Profile_Aggregates_Variance_Fields>;
};


/** aggregate fields of "profile_aggregates" */
export type Profile_Aggregates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Aggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Aggregates_Avg_Fields = {
  __typename?: 'profile_aggregates_avg_fields';
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "profile_aggregates". All fields are combined with a logical 'AND'. */
export type Profile_Aggregates_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Aggregates_Bool_Exp>>;
  _not?: InputMaybe<Profile_Aggregates_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Aggregates_Bool_Exp>>;
  followerCount?: InputMaybe<Int_Comparison_Exp>;
  followingCount?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_aggregates" */
export enum Profile_Aggregates_Constraint {
  /** unique or primary key constraint on columns "profile_id" */
  ProfileAggregatesPkey = 'profile_aggregates_pkey'
}

/** input type for incrementing numeric columns in table "profile_aggregates" */
export type Profile_Aggregates_Inc_Input = {
  followerCount?: InputMaybe<Scalars['Int']>;
  followingCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "profile_aggregates" */
export type Profile_Aggregates_Insert_Input = {
  followerCount?: InputMaybe<Scalars['Int']>;
  followingCount?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Aggregates_Max_Fields = {
  __typename?: 'profile_aggregates_max_fields';
  followerCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Profile_Aggregates_Min_Fields = {
  __typename?: 'profile_aggregates_min_fields';
  followerCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "profile_aggregates" */
export type Profile_Aggregates_Mutation_Response = {
  __typename?: 'profile_aggregates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Aggregates>;
};

/** input type for inserting object relation for remote table "profile_aggregates" */
export type Profile_Aggregates_Obj_Rel_Insert_Input = {
  data: Profile_Aggregates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_Aggregates_On_Conflict>;
};

/** on_conflict condition type for table "profile_aggregates" */
export type Profile_Aggregates_On_Conflict = {
  constraint: Profile_Aggregates_Constraint;
  update_columns?: Array<Profile_Aggregates_Update_Column>;
  where?: InputMaybe<Profile_Aggregates_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_aggregates". */
export type Profile_Aggregates_Order_By = {
  followerCount?: InputMaybe<Order_By>;
  followingCount?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile_aggregates */
export type Profile_Aggregates_Pk_Columns_Input = {
  profileId: Scalars['String'];
};

/** select columns of table "profile_aggregates" */
export enum Profile_Aggregates_Select_Column {
  /** column name */
  FollowerCount = 'followerCount',
  /** column name */
  FollowingCount = 'followingCount',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "profile_aggregates" */
export type Profile_Aggregates_Set_Input = {
  followerCount?: InputMaybe<Scalars['Int']>;
  followingCount?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Profile_Aggregates_Stddev_Fields = {
  __typename?: 'profile_aggregates_stddev_fields';
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Aggregates_Stddev_Pop_Fields = {
  __typename?: 'profile_aggregates_stddev_pop_fields';
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Aggregates_Stddev_Samp_Fields = {
  __typename?: 'profile_aggregates_stddev_samp_fields';
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "profile_aggregates" */
export type Profile_Aggregates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profile_Aggregates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profile_Aggregates_Stream_Cursor_Value_Input = {
  followerCount?: InputMaybe<Scalars['Int']>;
  followingCount?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Profile_Aggregates_Sum_Fields = {
  __typename?: 'profile_aggregates_sum_fields';
  followerCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "profile_aggregates" */
export enum Profile_Aggregates_Update_Column {
  /** column name */
  FollowerCount = 'followerCount',
  /** column name */
  FollowingCount = 'followingCount',
  /** column name */
  ProfileId = 'profileId'
}

export type Profile_Aggregates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Profile_Aggregates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Profile_Aggregates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Profile_Aggregates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Profile_Aggregates_Var_Pop_Fields = {
  __typename?: 'profile_aggregates_var_pop_fields';
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Aggregates_Var_Samp_Fields = {
  __typename?: 'profile_aggregates_var_samp_fields';
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Aggregates_Variance_Fields = {
  __typename?: 'profile_aggregates_variance_fields';
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "profiles" */
export type Profiles = {
  __typename?: 'profiles';
  /** An object relationship */
  account: Accounts;
  /** An object relationship */
  airdrop?: Maybe<Airdrop>;
  bAt?: Maybe<Scalars['timestamptz']>;
  badge?: Maybe<Badge_Types_Enum>;
  /** An array relationship */
  blockedProfilesByProfileId: Array<Blocked_Profiles>;
  /** An aggregate relationship */
  blockedProfilesByProfileId_aggregate: Blocked_Profiles_Aggregate;
  branchLink: Scalars['String'];
  /** An array relationship */
  ccProfiles: Array<Cc_Profiles>;
  /** An aggregate relationship */
  ccProfiles_aggregate: Cc_Profiles_Aggregate;
  /** An object relationship */
  city: Cities;
  cityId: Scalars['String'];
  /** An array relationship */
  communityMemberships: Array<Community_Members>;
  /** An aggregate relationship */
  communityMemberships_aggregate: Community_Members_Aggregate;
  /** An object relationship */
  coverImage?: Maybe<Images>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  discord: Scalars['String'];
  facebook: Scalars['String'];
  /** An object relationship */
  farcasterProfile?: Maybe<Farcaster_Profiles>;
  /** An array relationship */
  followedBy: Array<Followers>;
  /** An aggregate relationship */
  followedBy_aggregate: Followers_Aggregate;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followers_aggregate: Followers_Aggregate;
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  /** An array relationship */
  images: Array<Images>;
  /** An aggregate relationship */
  images_aggregate: Images_Aggregate;
  instagram: Scalars['String'];
  isUserFollowing?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  items: Array<Items>;
  /** An aggregate relationship */
  items_aggregate: Items_Aggregate;
  /** An object relationship */
  lensProfile?: Maybe<Lens_Profiles>;
  linkedin: Scalars['String'];
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  nft?: Maybe<Connected_Nfts>;
  nftId?: Maybe<Scalars['String']>;
  phaverFrens?: Maybe<Scalars['Boolean']>;
  pinterest: Scalars['String'];
  /** An object relationship */
  profileAggregates?: Maybe<Profile_Aggregates>;
  publicCityId?: Maybe<Scalars['String']>;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: Recommendations_Aggregate;
  /** An array relationship */
  reportedContentsByReportedProfileId: Array<Reported_Content>;
  /** An aggregate relationship */
  reportedContentsByReportedProfileId_aggregate: Reported_Content_Aggregate;
  snapchat: Scalars['String'];
  telegram: Scalars['String'];
  tiktok: Scalars['String'];
  timeoutUntil?: Maybe<Scalars['timestamptz']>;
  twitter: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  username: Scalars['String'];
  verification?: Maybe<Verification_Types_Enum>;
  verified: Scalars['Boolean'];
  website: Scalars['String'];
  /** A computed field, executes function "get_xmtp_wallet" */
  xmtpWallet?: Maybe<Scalars['String']>;
  youtube: Scalars['String'];
};


/** columns and relationships of "profiles" */
export type ProfilesBlockedProfilesByProfileIdArgs = {
  distinct_on?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blocked_Profiles_Order_By>>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesBlockedProfilesByProfileId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blocked_Profiles_Order_By>>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesCcProfilesArgs = {
  distinct_on?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cc_Profiles_Order_By>>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesCcProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cc_Profiles_Order_By>>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesCommunityMembershipsArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesCommunityMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesFollowedByArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesFollowedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesFollowersArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesFollowers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesRecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesRecommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesReportedContentsByReportedProfileIdArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type ProfilesReportedContentsByReportedProfileId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};

/** aggregated selection of "profiles" */
export type Profiles_Aggregate = {
  __typename?: 'profiles_aggregate';
  aggregate?: Maybe<Profiles_Aggregate_Fields>;
  nodes: Array<Profiles>;
};

export type Profiles_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Profiles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Profiles_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Profiles_Aggregate_Bool_Exp_Count>;
};

export type Profiles_Aggregate_Bool_Exp_Bool_And = {
  arguments: Profiles_Select_Column_Profiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Profiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Profiles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Profiles_Select_Column_Profiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Profiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "profiles" */
export type Profiles_Aggregate_Fields = {
  __typename?: 'profiles_aggregate_fields';
  avg?: Maybe<Profiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profiles_Max_Fields>;
  min?: Maybe<Profiles_Min_Fields>;
  stddev?: Maybe<Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Profiles_Sum_Fields>;
  var_pop?: Maybe<Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Profiles_Var_Samp_Fields>;
  variance?: Maybe<Profiles_Variance_Fields>;
};


/** aggregate fields of "profiles" */
export type Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profiles" */
export type Profiles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Profiles_Max_Order_By>;
  min?: InputMaybe<Profiles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "profiles" */
export type Profiles_Arr_Rel_Insert_Input = {
  data: Array<Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Profiles_Avg_Fields = {
  __typename?: 'profiles_avg_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "profiles". All fields are combined with a logical 'AND'. */
export type Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Profiles_Bool_Exp>>;
  _not?: InputMaybe<Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Profiles_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  airdrop?: InputMaybe<Airdrop_Bool_Exp>;
  bAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  badge?: InputMaybe<Badge_Types_Enum_Comparison_Exp>;
  blockedProfilesByProfileId?: InputMaybe<Blocked_Profiles_Bool_Exp>;
  blockedProfilesByProfileId_aggregate?: InputMaybe<Blocked_Profiles_Aggregate_Bool_Exp>;
  branchLink?: InputMaybe<String_Comparison_Exp>;
  ccProfiles?: InputMaybe<Cc_Profiles_Bool_Exp>;
  ccProfiles_aggregate?: InputMaybe<Cc_Profiles_Aggregate_Bool_Exp>;
  city?: InputMaybe<Cities_Bool_Exp>;
  cityId?: InputMaybe<String_Comparison_Exp>;
  communityMemberships?: InputMaybe<Community_Members_Bool_Exp>;
  communityMemberships_aggregate?: InputMaybe<Community_Members_Aggregate_Bool_Exp>;
  coverImage?: InputMaybe<Images_Bool_Exp>;
  coverImageId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credLevel?: InputMaybe<Int_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discord?: InputMaybe<String_Comparison_Exp>;
  facebook?: InputMaybe<String_Comparison_Exp>;
  farcasterProfile?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
  followedBy?: InputMaybe<Followers_Bool_Exp>;
  followedBy_aggregate?: InputMaybe<Followers_Aggregate_Bool_Exp>;
  followers?: InputMaybe<Followers_Bool_Exp>;
  followers_aggregate?: InputMaybe<Followers_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  images?: InputMaybe<Images_Bool_Exp>;
  images_aggregate?: InputMaybe<Images_Aggregate_Bool_Exp>;
  instagram?: InputMaybe<String_Comparison_Exp>;
  isUserFollowing?: InputMaybe<Boolean_Comparison_Exp>;
  items?: InputMaybe<Items_Bool_Exp>;
  items_aggregate?: InputMaybe<Items_Aggregate_Bool_Exp>;
  lensProfile?: InputMaybe<Lens_Profiles_Bool_Exp>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  medias?: InputMaybe<Medias_Bool_Exp>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nft?: InputMaybe<Connected_Nfts_Bool_Exp>;
  nftId?: InputMaybe<String_Comparison_Exp>;
  phaverFrens?: InputMaybe<Boolean_Comparison_Exp>;
  pinterest?: InputMaybe<String_Comparison_Exp>;
  profileAggregates?: InputMaybe<Profile_Aggregates_Bool_Exp>;
  publicCityId?: InputMaybe<String_Comparison_Exp>;
  recommendations?: InputMaybe<Recommendations_Bool_Exp>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Bool_Exp>;
  reportedContentsByReportedProfileId?: InputMaybe<Reported_Content_Bool_Exp>;
  reportedContentsByReportedProfileId_aggregate?: InputMaybe<Reported_Content_Aggregate_Bool_Exp>;
  snapchat?: InputMaybe<String_Comparison_Exp>;
  telegram?: InputMaybe<String_Comparison_Exp>;
  tiktok?: InputMaybe<String_Comparison_Exp>;
  timeoutUntil?: InputMaybe<Timestamptz_Comparison_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  verification?: InputMaybe<Verification_Types_Enum_Comparison_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
  xmtpWallet?: InputMaybe<String_Comparison_Exp>;
  youtube?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "profiles" */
export enum Profiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfilesPkey = 'profiles_pkey',
  /** unique or primary key constraint on columns  */
  ProfilesUsernameUnique = 'profiles_username_unique'
}

/** input type for inserting data into table "profiles" */
export type Profiles_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  airdrop?: InputMaybe<Airdrop_Obj_Rel_Insert_Input>;
  bAt?: InputMaybe<Scalars['timestamptz']>;
  badge?: InputMaybe<Badge_Types_Enum>;
  blockedProfilesByProfileId?: InputMaybe<Blocked_Profiles_Arr_Rel_Insert_Input>;
  branchLink?: InputMaybe<Scalars['String']>;
  ccProfiles?: InputMaybe<Cc_Profiles_Arr_Rel_Insert_Input>;
  city?: InputMaybe<Cities_Obj_Rel_Insert_Input>;
  cityId?: InputMaybe<Scalars['String']>;
  communityMemberships?: InputMaybe<Community_Members_Arr_Rel_Insert_Input>;
  coverImage?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  discord?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  farcasterProfile?: InputMaybe<Farcaster_Profiles_Obj_Rel_Insert_Input>;
  followedBy?: InputMaybe<Followers_Arr_Rel_Insert_Input>;
  followers?: InputMaybe<Followers_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Images_Arr_Rel_Insert_Input>;
  instagram?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Items_Arr_Rel_Insert_Input>;
  lensProfile?: InputMaybe<Lens_Profiles_Obj_Rel_Insert_Input>;
  linkedin?: InputMaybe<Scalars['String']>;
  medias?: InputMaybe<Medias_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  nft?: InputMaybe<Connected_Nfts_Obj_Rel_Insert_Input>;
  nftId?: InputMaybe<Scalars['String']>;
  phaverFrens?: InputMaybe<Scalars['Boolean']>;
  pinterest?: InputMaybe<Scalars['String']>;
  profileAggregates?: InputMaybe<Profile_Aggregates_Obj_Rel_Insert_Input>;
  publicCityId?: InputMaybe<Scalars['String']>;
  recommendations?: InputMaybe<Recommendations_Arr_Rel_Insert_Input>;
  reportedContentsByReportedProfileId?: InputMaybe<Reported_Content_Arr_Rel_Insert_Input>;
  snapchat?: InputMaybe<Scalars['String']>;
  telegram?: InputMaybe<Scalars['String']>;
  tiktok?: InputMaybe<Scalars['String']>;
  timeoutUntil?: InputMaybe<Scalars['timestamptz']>;
  twitter?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  verification?: InputMaybe<Verification_Types_Enum>;
  verified?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profiles_Max_Fields = {
  __typename?: 'profiles_max_fields';
  bAt?: Maybe<Scalars['timestamptz']>;
  branchLink?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nftId?: Maybe<Scalars['String']>;
  pinterest?: Maybe<Scalars['String']>;
  publicCityId?: Maybe<Scalars['String']>;
  snapchat?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  timeoutUntil?: Maybe<Scalars['timestamptz']>;
  twitter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_xmtp_wallet" */
  xmtpWallet?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "profiles" */
export type Profiles_Max_Order_By = {
  bAt?: InputMaybe<Order_By>;
  branchLink?: InputMaybe<Order_By>;
  cityId?: InputMaybe<Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discord?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nftId?: InputMaybe<Order_By>;
  pinterest?: InputMaybe<Order_By>;
  publicCityId?: InputMaybe<Order_By>;
  snapchat?: InputMaybe<Order_By>;
  telegram?: InputMaybe<Order_By>;
  tiktok?: InputMaybe<Order_By>;
  timeoutUntil?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  youtube?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Profiles_Min_Fields = {
  __typename?: 'profiles_min_fields';
  bAt?: Maybe<Scalars['timestamptz']>;
  branchLink?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nftId?: Maybe<Scalars['String']>;
  pinterest?: Maybe<Scalars['String']>;
  publicCityId?: Maybe<Scalars['String']>;
  snapchat?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  timeoutUntil?: Maybe<Scalars['timestamptz']>;
  twitter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_xmtp_wallet" */
  xmtpWallet?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "profiles" */
export type Profiles_Min_Order_By = {
  bAt?: InputMaybe<Order_By>;
  branchLink?: InputMaybe<Order_By>;
  cityId?: InputMaybe<Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discord?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nftId?: InputMaybe<Order_By>;
  pinterest?: InputMaybe<Order_By>;
  publicCityId?: InputMaybe<Order_By>;
  snapchat?: InputMaybe<Order_By>;
  telegram?: InputMaybe<Order_By>;
  tiktok?: InputMaybe<Order_By>;
  timeoutUntil?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  youtube?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profiles" */
export type Profiles_Mutation_Response = {
  __typename?: 'profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profiles>;
};

/** input type for inserting object relation for remote table "profiles" */
export type Profiles_Obj_Rel_Insert_Input = {
  data: Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};

/** on_conflict condition type for table "profiles" */
export type Profiles_On_Conflict = {
  constraint: Profiles_Constraint;
  update_columns?: Array<Profiles_Update_Column>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "profiles". */
export type Profiles_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  airdrop?: InputMaybe<Airdrop_Order_By>;
  bAt?: InputMaybe<Order_By>;
  badge?: InputMaybe<Order_By>;
  blockedProfilesByProfileId_aggregate?: InputMaybe<Blocked_Profiles_Aggregate_Order_By>;
  branchLink?: InputMaybe<Order_By>;
  ccProfiles_aggregate?: InputMaybe<Cc_Profiles_Aggregate_Order_By>;
  city?: InputMaybe<Cities_Order_By>;
  cityId?: InputMaybe<Order_By>;
  communityMemberships_aggregate?: InputMaybe<Community_Members_Aggregate_Order_By>;
  coverImage?: InputMaybe<Images_Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credLevel?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discord?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  farcasterProfile?: InputMaybe<Farcaster_Profiles_Order_By>;
  followedBy_aggregate?: InputMaybe<Followers_Aggregate_Order_By>;
  followers_aggregate?: InputMaybe<Followers_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  images_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  instagram?: InputMaybe<Order_By>;
  isUserFollowing?: InputMaybe<Order_By>;
  items_aggregate?: InputMaybe<Items_Aggregate_Order_By>;
  lensProfile?: InputMaybe<Lens_Profiles_Order_By>;
  linkedin?: InputMaybe<Order_By>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  nft?: InputMaybe<Connected_Nfts_Order_By>;
  nftId?: InputMaybe<Order_By>;
  phaverFrens?: InputMaybe<Order_By>;
  pinterest?: InputMaybe<Order_By>;
  profileAggregates?: InputMaybe<Profile_Aggregates_Order_By>;
  publicCityId?: InputMaybe<Order_By>;
  recommendations_aggregate?: InputMaybe<Recommendations_Aggregate_Order_By>;
  reportedContentsByReportedProfileId_aggregate?: InputMaybe<Reported_Content_Aggregate_Order_By>;
  snapchat?: InputMaybe<Order_By>;
  telegram?: InputMaybe<Order_By>;
  tiktok?: InputMaybe<Order_By>;
  timeoutUntil?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  verification?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  xmtpWallet?: InputMaybe<Order_By>;
  youtube?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profiles */
export type Profiles_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "profiles" */
export enum Profiles_Select_Column {
  /** column name */
  BAt = 'bAt',
  /** column name */
  Badge = 'badge',
  /** column name */
  BranchLink = 'branchLink',
  /** column name */
  CityId = 'cityId',
  /** column name */
  CoverImageId = 'coverImageId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Discord = 'discord',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Name = 'name',
  /** column name */
  NftId = 'nftId',
  /** column name */
  PhaverFrens = 'phaverFrens',
  /** column name */
  Pinterest = 'pinterest',
  /** column name */
  PublicCityId = 'publicCityId',
  /** column name */
  Snapchat = 'snapchat',
  /** column name */
  Telegram = 'telegram',
  /** column name */
  Tiktok = 'tiktok',
  /** column name */
  TimeoutUntil = 'timeoutUntil',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  Verification = 'verification',
  /** column name */
  Verified = 'verified',
  /** column name */
  Website = 'website',
  /** column name */
  Youtube = 'youtube'
}

/** select "profiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "profiles" */
export enum Profiles_Select_Column_Profiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PhaverFrens = 'phaverFrens',
  /** column name */
  Verified = 'verified'
}

/** select "profiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "profiles" */
export enum Profiles_Select_Column_Profiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PhaverFrens = 'phaverFrens',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "profiles" */
export type Profiles_Set_Input = {
  bAt?: InputMaybe<Scalars['timestamptz']>;
  badge?: InputMaybe<Badge_Types_Enum>;
  branchLink?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['String']>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  discord?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nftId?: InputMaybe<Scalars['String']>;
  phaverFrens?: InputMaybe<Scalars['Boolean']>;
  pinterest?: InputMaybe<Scalars['String']>;
  publicCityId?: InputMaybe<Scalars['String']>;
  snapchat?: InputMaybe<Scalars['String']>;
  telegram?: InputMaybe<Scalars['String']>;
  tiktok?: InputMaybe<Scalars['String']>;
  timeoutUntil?: InputMaybe<Scalars['timestamptz']>;
  twitter?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  verification?: InputMaybe<Verification_Types_Enum>;
  verified?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Profiles_Stddev_Fields = {
  __typename?: 'profiles_stddev_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_pop on columns */
export type Profiles_Stddev_Pop_Fields = {
  __typename?: 'profiles_stddev_pop_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_samp on columns */
export type Profiles_Stddev_Samp_Fields = {
  __typename?: 'profiles_stddev_samp_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** Streaming cursor of the table "profiles" */
export type Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profiles_Stream_Cursor_Value_Input = {
  bAt?: InputMaybe<Scalars['timestamptz']>;
  badge?: InputMaybe<Badge_Types_Enum>;
  branchLink?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['String']>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  discord?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nftId?: InputMaybe<Scalars['String']>;
  phaverFrens?: InputMaybe<Scalars['Boolean']>;
  pinterest?: InputMaybe<Scalars['String']>;
  publicCityId?: InputMaybe<Scalars['String']>;
  snapchat?: InputMaybe<Scalars['String']>;
  telegram?: InputMaybe<Scalars['String']>;
  tiktok?: InputMaybe<Scalars['String']>;
  timeoutUntil?: InputMaybe<Scalars['timestamptz']>;
  twitter?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  verification?: InputMaybe<Verification_Types_Enum>;
  verified?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Profiles_Sum_Fields = {
  __typename?: 'profiles_sum_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** update columns of table "profiles" */
export enum Profiles_Update_Column {
  /** column name */
  BAt = 'bAt',
  /** column name */
  Badge = 'badge',
  /** column name */
  BranchLink = 'branchLink',
  /** column name */
  CityId = 'cityId',
  /** column name */
  CoverImageId = 'coverImageId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Discord = 'discord',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Name = 'name',
  /** column name */
  NftId = 'nftId',
  /** column name */
  PhaverFrens = 'phaverFrens',
  /** column name */
  Pinterest = 'pinterest',
  /** column name */
  PublicCityId = 'publicCityId',
  /** column name */
  Snapchat = 'snapchat',
  /** column name */
  Telegram = 'telegram',
  /** column name */
  Tiktok = 'tiktok',
  /** column name */
  TimeoutUntil = 'timeoutUntil',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  Verification = 'verification',
  /** column name */
  Verified = 'verified',
  /** column name */
  Website = 'website',
  /** column name */
  Youtube = 'youtube'
}

export type Profiles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Profiles_Var_Pop_Fields = {
  __typename?: 'profiles_var_pop_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** aggregate var_samp on columns */
export type Profiles_Var_Samp_Fields = {
  __typename?: 'profiles_var_samp_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type Profiles_Variance_Fields = {
  __typename?: 'profiles_variance_fields';
  /** A computed field, executes function "get_cred_level" */
  credLevel?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "push_tokens" */
export type Push_Tokens = {
  __typename?: 'push_tokens';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deviceType?: Maybe<Scalars['String']>;
  expoPushToken: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "push_tokens" */
export type Push_Tokens_Aggregate = {
  __typename?: 'push_tokens_aggregate';
  aggregate?: Maybe<Push_Tokens_Aggregate_Fields>;
  nodes: Array<Push_Tokens>;
};

export type Push_Tokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<Push_Tokens_Aggregate_Bool_Exp_Count>;
};

export type Push_Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Push_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "push_tokens" */
export type Push_Tokens_Aggregate_Fields = {
  __typename?: 'push_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Push_Tokens_Max_Fields>;
  min?: Maybe<Push_Tokens_Min_Fields>;
};


/** aggregate fields of "push_tokens" */
export type Push_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "push_tokens" */
export type Push_Tokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Push_Tokens_Max_Order_By>;
  min?: InputMaybe<Push_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "push_tokens" */
export type Push_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Push_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Push_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "push_tokens". All fields are combined with a logical 'AND'. */
export type Push_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Push_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Push_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Push_Tokens_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deviceType?: InputMaybe<String_Comparison_Exp>;
  expoPushToken?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "push_tokens" */
export enum Push_Tokens_Constraint {
  /** unique or primary key constraint on columns "account_id", "expo_push_token" */
  PushTokensPkey = 'push_tokens_pkey'
}

/** input type for inserting data into table "push_tokens" */
export type Push_Tokens_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceType?: InputMaybe<Scalars['String']>;
  expoPushToken?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Push_Tokens_Max_Fields = {
  __typename?: 'push_tokens_max_fields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceType?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "push_tokens" */
export type Push_Tokens_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deviceType?: InputMaybe<Order_By>;
  expoPushToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Push_Tokens_Min_Fields = {
  __typename?: 'push_tokens_min_fields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceType?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "push_tokens" */
export type Push_Tokens_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deviceType?: InputMaybe<Order_By>;
  expoPushToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "push_tokens" */
export type Push_Tokens_Mutation_Response = {
  __typename?: 'push_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Push_Tokens>;
};

/** on_conflict condition type for table "push_tokens" */
export type Push_Tokens_On_Conflict = {
  constraint: Push_Tokens_Constraint;
  update_columns?: Array<Push_Tokens_Update_Column>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "push_tokens". */
export type Push_Tokens_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deviceType?: InputMaybe<Order_By>;
  expoPushToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: push_tokens */
export type Push_Tokens_Pk_Columns_Input = {
  accountId: Scalars['String'];
  expoPushToken: Scalars['String'];
};

/** select columns of table "push_tokens" */
export enum Push_Tokens_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceType = 'deviceType',
  /** column name */
  ExpoPushToken = 'expoPushToken',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "push_tokens" */
export type Push_Tokens_Set_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceType?: InputMaybe<Scalars['String']>;
  expoPushToken?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "push_tokens" */
export type Push_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Push_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Push_Tokens_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceType?: InputMaybe<Scalars['String']>;
  expoPushToken?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "push_tokens" */
export enum Push_Tokens_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceType = 'deviceType',
  /** column name */
  ExpoPushToken = 'expoPushToken',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Push_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Push_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Push_Tokens_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** accountReferralAggregation */
  accountReferralAggregation?: Maybe<AccountReferralAggregationOutput>;
  /** accountReferrals */
  accountReferrals: Array<AccountReferralsResult>;
  /** fetch data from the table: "account_types" */
  account_types: Array<Account_Types>;
  /** fetch aggregated fields from the table: "account_types" */
  account_types_aggregate: Account_Types_Aggregate;
  /** fetch data from the table: "account_types" using primary key columns */
  account_types_by_pk?: Maybe<Account_Types>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "admin_portal_whitelist" */
  admin_portal_whitelist: Array<Admin_Portal_Whitelist>;
  /** fetch aggregated fields from the table: "admin_portal_whitelist" */
  admin_portal_whitelist_aggregate: Admin_Portal_Whitelist_Aggregate;
  /** fetch data from the table: "admin_portal_whitelist" using primary key columns */
  admin_portal_whitelist_by_pk?: Maybe<Admin_Portal_Whitelist>;
  /** fetch data from the table: "airdrop" */
  airdrop: Array<Airdrop>;
  /** fetch aggregated fields from the table: "airdrop" */
  airdrop_aggregate: Airdrop_Aggregate;
  /** fetch data from the table: "airdrop" using primary key columns */
  airdrop_by_pk?: Maybe<Airdrop>;
  /** fetch data from the table: "async_status" */
  async_status: Array<Async_Status>;
  /** fetch aggregated fields from the table: "async_status" */
  async_status_aggregate: Async_Status_Aggregate;
  /** fetch data from the table: "async_status" using primary key columns */
  async_status_by_pk?: Maybe<Async_Status>;
  /** fetch data from the table: "audios" */
  audios: Array<Audios>;
  /** fetch aggregated fields from the table: "audios" */
  audios_aggregate: Audios_Aggregate;
  /** fetch data from the table: "audios" using primary key columns */
  audios_by_pk?: Maybe<Audios>;
  /** fetch data from the table: "badge_types" */
  badge_types: Array<Badge_Types>;
  /** fetch aggregated fields from the table: "badge_types" */
  badge_types_aggregate: Badge_Types_Aggregate;
  /** fetch data from the table: "badge_types" using primary key columns */
  badge_types_by_pk?: Maybe<Badge_Types>;
  /** fetch data from the table: "blocked_profiles" */
  blocked_profiles: Array<Blocked_Profiles>;
  /** fetch aggregated fields from the table: "blocked_profiles" */
  blocked_profiles_aggregate: Blocked_Profiles_Aggregate;
  /** fetch data from the table: "blocked_profiles" using primary key columns */
  blocked_profiles_by_pk?: Maybe<Blocked_Profiles>;
  /** fetch data from the table: "campaign_signatures" */
  campaign_signatures: Array<Campaign_Signatures>;
  /** fetch aggregated fields from the table: "campaign_signatures" */
  campaign_signatures_aggregate: Campaign_Signatures_Aggregate;
  /** fetch data from the table: "campaign_signatures" using primary key columns */
  campaign_signatures_by_pk?: Maybe<Campaign_Signatures>;
  ccFollows?: Maybe<Array<Maybe<CcProfile>>>;
  ccProfilesOwnedByAccount: Array<CcProfilesOwnedByAccountOutput>;
  /** fetch data from the table: "cc_profiles" */
  cc_profiles: Array<Cc_Profiles>;
  /** fetch aggregated fields from the table: "cc_profiles" */
  cc_profiles_aggregate: Cc_Profiles_Aggregate;
  /** fetch data from the table: "cc_profiles" using primary key columns */
  cc_profiles_by_pk?: Maybe<Cc_Profiles>;
  checkAnimaStatus?: Maybe<AnimaStatus>;
  checkLensHandleAvailability: CheckLensHandleAvailabilityOutput;
  /** An array relationship */
  cities: Array<Cities>;
  /** An aggregate relationship */
  cities_aggregate: Cities_Aggregate;
  /** fetch data from the table: "cities" using primary key columns */
  cities_by_pk?: Maybe<Cities>;
  /** fetch data from the table: "claimed_yield" */
  claimed_yield: Array<Claimed_Yield>;
  /** fetch aggregated fields from the table: "claimed_yield" */
  claimed_yield_aggregate: Claimed_Yield_Aggregate;
  /** fetch data from the table: "claimed_yield" using primary key columns */
  claimed_yield_by_pk?: Maybe<Claimed_Yield>;
  /** fetch data from the table: "claimed_yield_by_project" */
  claimed_yield_by_project: Array<Claimed_Yield_By_Project>;
  /** fetch aggregated fields from the table: "claimed_yield_by_project" */
  claimed_yield_by_project_aggregate: Claimed_Yield_By_Project_Aggregate;
  closestCities?: Maybe<Array<CityList>>;
  /** fetch data from the table: "comment_aggregates" */
  comment_aggregates: Array<Comment_Aggregates>;
  /** fetch aggregated fields from the table: "comment_aggregates" */
  comment_aggregates_aggregate: Comment_Aggregates_Aggregate;
  /** fetch data from the table: "comment_aggregates" using primary key columns */
  comment_aggregates_by_pk?: Maybe<Comment_Aggregates>;
  /** fetch data from the table: "comment_profile_tags" */
  comment_profile_tags: Array<Comment_Profile_Tags>;
  /** fetch aggregated fields from the table: "comment_profile_tags" */
  comment_profile_tags_aggregate: Comment_Profile_Tags_Aggregate;
  /** fetch data from the table: "comment_profile_tags" using primary key columns */
  comment_profile_tags_by_pk?: Maybe<Comment_Profile_Tags>;
  /** An array relationship */
  comment_votes: Array<Comment_Votes>;
  /** An aggregate relationship */
  comment_votes_aggregate: Comment_Votes_Aggregate;
  /** fetch data from the table: "comment_votes" using primary key columns */
  comment_votes_by_pk?: Maybe<Comment_Votes>;
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  /** fetch data from the table: "communities" using primary key columns */
  communities_by_pk?: Maybe<Communities>;
  /** fetch data from the table: "community_aggregates" */
  community_aggregates: Array<Community_Aggregates>;
  /** fetch aggregated fields from the table: "community_aggregates" */
  community_aggregates_aggregate: Community_Aggregates_Aggregate;
  /** fetch data from the table: "community_aggregates" using primary key columns */
  community_aggregates_by_pk?: Maybe<Community_Aggregates>;
  /** An array relationship */
  community_categories: Array<Community_Categories>;
  /** An aggregate relationship */
  community_categories_aggregate: Community_Categories_Aggregate;
  /** fetch data from the table: "community_categories" using primary key columns */
  community_categories_by_pk?: Maybe<Community_Categories>;
  /** fetch data from the table: "community_content_collections" */
  community_content_collections: Array<Community_Content_Collections>;
  /** fetch aggregated fields from the table: "community_content_collections" */
  community_content_collections_aggregate: Community_Content_Collections_Aggregate;
  /** fetch data from the table: "community_content_collections" using primary key columns */
  community_content_collections_by_pk?: Maybe<Community_Content_Collections>;
  /** fetch data from the table: "community_content_questions" */
  community_content_questions: Array<Community_Content_Questions>;
  /** fetch aggregated fields from the table: "community_content_questions" */
  community_content_questions_aggregate: Community_Content_Questions_Aggregate;
  /** fetch data from the table: "community_content_questions" using primary key columns */
  community_content_questions_by_pk?: Maybe<Community_Content_Questions>;
  /** fetch data from the table: "community_content_vote_scores" */
  community_content_vote_scores: Array<Community_Content_Vote_Scores>;
  /** fetch aggregated fields from the table: "community_content_vote_scores" */
  community_content_vote_scores_aggregate: Community_Content_Vote_Scores_Aggregate;
  /** fetch data from the table: "community_content_votes" */
  community_content_votes: Array<Community_Content_Votes>;
  /** fetch aggregated fields from the table: "community_content_votes" */
  community_content_votes_aggregate: Community_Content_Votes_Aggregate;
  /** fetch data from the table: "community_content_votes" using primary key columns */
  community_content_votes_by_pk?: Maybe<Community_Content_Votes>;
  /** fetch data from the table: "community_contents" */
  community_contents: Array<Community_Contents>;
  /** fetch aggregated fields from the table: "community_contents" */
  community_contents_aggregate: Community_Contents_Aggregate;
  /** fetch data from the table: "community_contents" using primary key columns */
  community_contents_by_pk?: Maybe<Community_Contents>;
  /** An array relationship */
  community_members: Array<Community_Members>;
  /** An aggregate relationship */
  community_members_aggregate: Community_Members_Aggregate;
  /** fetch data from the table: "community_members" using primary key columns */
  community_members_by_pk?: Maybe<Community_Members>;
  /** fetch data from the table: "community_nft_projects" */
  community_nft_projects: Array<Community_Nft_Projects>;
  /** fetch aggregated fields from the table: "community_nft_projects" */
  community_nft_projects_aggregate: Community_Nft_Projects_Aggregate;
  /** fetch data from the table: "community_nft_projects" using primary key columns */
  community_nft_projects_by_pk?: Maybe<Community_Nft_Projects>;
  /** fetch data from the table: "community_staffpicks" */
  community_staffpicks: Array<Community_Staffpicks>;
  /** fetch aggregated fields from the table: "community_staffpicks" */
  community_staffpicks_aggregate: Community_Staffpicks_Aggregate;
  /** fetch data from the table: "community_staffpicks" using primary key columns */
  community_staffpicks_by_pk?: Maybe<Community_Staffpicks>;
  /** An array relationship */
  connected_nfts: Array<Connected_Nfts>;
  /** An aggregate relationship */
  connected_nfts_aggregate: Connected_Nfts_Aggregate;
  /** fetch data from the table: "connected_nfts" using primary key columns */
  connected_nfts_by_pk?: Maybe<Connected_Nfts>;
  /** fetch data from the table: "continents" */
  continents: Array<Continents>;
  /** fetch aggregated fields from the table: "continents" */
  continents_aggregate: Continents_Aggregate;
  /** fetch data from the table: "continents" using primary key columns */
  continents_by_pk?: Maybe<Continents>;
  /** An array relationship */
  countries: Array<Countries>;
  /** An aggregate relationship */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  countryByIp?: Maybe<CountryByIpOutput>;
  createFarcasterAccountBuildData?: Maybe<CreateFarcasterAccountBuildDataOutput>;
  /** An array relationship */
  cred_scores: Array<Cred_Scores>;
  /** An aggregate relationship */
  cred_scores_aggregate: Cred_Scores_Aggregate;
  /** fetch data from the table: "cred_scores" using primary key columns */
  cred_scores_by_pk?: Maybe<Cred_Scores>;
  /** fetch data from the table: "currency_isocodes" */
  currency_isocodes: Array<Currency_Isocodes>;
  /** fetch aggregated fields from the table: "currency_isocodes" */
  currency_isocodes_aggregate: Currency_Isocodes_Aggregate;
  /** fetch data from the table: "currency_isocodes" using primary key columns */
  currency_isocodes_by_pk?: Maybe<Currency_Isocodes>;
  dailyStakingQuota?: Maybe<DailyStakingQuotaReply>;
  /** fetch data from the table: "daily_staking" */
  daily_staking: Array<Daily_Staking>;
  /** fetch aggregated fields from the table: "daily_staking" */
  daily_staking_aggregate: Daily_Staking_Aggregate;
  /** fetch data from the table: "daily_staking" using primary key columns */
  daily_staking_by_pk?: Maybe<Daily_Staking>;
  /** Query used to extract hasura error codes in code generation. Should not be actually called. */
  dummyHasuraErrorCodeGeneratorQuery?: Maybe<DummyHasuraErrorCodeGeneratorOutput>;
  /** fetch data from the table: "embedded_urls" */
  embedded_urls: Array<Embedded_Urls>;
  /** fetch aggregated fields from the table: "embedded_urls" */
  embedded_urls_aggregate: Embedded_Urls_Aggregate;
  /** fetch data from the table: "embedded_urls" using primary key columns */
  embedded_urls_by_pk?: Maybe<Embedded_Urls>;
  eventLogs: Array<EventLog>;
  eventPoint?: Maybe<EventPoint>;
  /** An array relationship */
  farcaster_casts: Array<Farcaster_Casts>;
  /** An aggregate relationship */
  farcaster_casts_aggregate: Farcaster_Casts_Aggregate;
  /** fetch data from the table: "farcaster_casts" using primary key columns */
  farcaster_casts_by_pk?: Maybe<Farcaster_Casts>;
  /** fetch data from the table: "farcaster_invites" */
  farcaster_invites: Array<Farcaster_Invites>;
  /** fetch aggregated fields from the table: "farcaster_invites" */
  farcaster_invites_aggregate: Farcaster_Invites_Aggregate;
  /** fetch data from the table: "farcaster_invites" using primary key columns */
  farcaster_invites_by_pk?: Maybe<Farcaster_Invites>;
  /** fetch data from the table: "farcaster_power_badge_users" */
  farcaster_power_badge_users: Array<Farcaster_Power_Badge_Users>;
  /** fetch aggregated fields from the table: "farcaster_power_badge_users" */
  farcaster_power_badge_users_aggregate: Farcaster_Power_Badge_Users_Aggregate;
  /** fetch data from the table: "farcaster_power_badge_users" using primary key columns */
  farcaster_power_badge_users_by_pk?: Maybe<Farcaster_Power_Badge_Users>;
  /** fetch data from the table: "farcaster_profiles" */
  farcaster_profiles: Array<Farcaster_Profiles>;
  /** fetch aggregated fields from the table: "farcaster_profiles" */
  farcaster_profiles_aggregate: Farcaster_Profiles_Aggregate;
  /** fetch data from the table: "farcaster_profiles" using primary key columns */
  farcaster_profiles_by_pk?: Maybe<Farcaster_Profiles>;
  /** fetch data from the table: "farcaster_reactions" */
  farcaster_reactions: Array<Farcaster_Reactions>;
  /** fetch aggregated fields from the table: "farcaster_reactions" */
  farcaster_reactions_aggregate: Farcaster_Reactions_Aggregate;
  /** fetch data from the table: "farcaster_reactions" using primary key columns */
  farcaster_reactions_by_pk?: Maybe<Farcaster_Reactions>;
  /** fetch data from the table: "farcaster_signers" */
  farcaster_signers: Array<Farcaster_Signers>;
  /** fetch aggregated fields from the table: "farcaster_signers" */
  farcaster_signers_aggregate: Farcaster_Signers_Aggregate;
  /** fetch data from the table: "farcaster_signers" using primary key columns */
  farcaster_signers_by_pk?: Maybe<Farcaster_Signers>;
  /** fetch data from the table: "flag_types" */
  flag_types: Array<Flag_Types>;
  /** fetch aggregated fields from the table: "flag_types" */
  flag_types_aggregate: Flag_Types_Aggregate;
  /** fetch data from the table: "flag_types" using primary key columns */
  flag_types_by_pk?: Maybe<Flag_Types>;
  /** An array relationship */
  flags: Array<Flags>;
  /** An aggregate relationship */
  flags_aggregate: Flags_Aggregate;
  /** fetch data from the table: "flags" using primary key columns */
  flags_by_pk?: Maybe<Flags>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followers_aggregate: Followers_Aggregate;
  /** fetch data from the table: "followers" using primary key columns */
  followers_by_pk?: Maybe<Followers>;
  /** fetch data from the table: "frame_action" */
  frame_action: Array<Frame_Action>;
  /** fetch aggregated fields from the table: "frame_action" */
  frame_action_aggregate: Frame_Action_Aggregate;
  /** fetch data from the table: "frame_action" using primary key columns */
  frame_action_by_pk?: Maybe<Frame_Action>;
  /** fetch data from the table: "frame_buttons" */
  frame_buttons: Array<Frame_Buttons>;
  /** fetch aggregated fields from the table: "frame_buttons" */
  frame_buttons_aggregate: Frame_Buttons_Aggregate;
  /** fetch data from the table: "frame_buttons" using primary key columns */
  frame_buttons_by_pk?: Maybe<Frame_Buttons>;
  /** fetch data from the table: "frames" */
  frames: Array<Frames>;
  /** fetch aggregated fields from the table: "frames" */
  frames_aggregate: Frames_Aggregate;
  /** fetch data from the table: "frames" using primary key columns */
  frames_by_pk?: Maybe<Frames>;
  generatePostPreview?: Maybe<GeneratePostPreviewOutput>;
  /** An array relationship */
  images: Array<Images>;
  /** An aggregate relationship */
  images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<Images>;
  /** fetch data from the table: "item_others" */
  item_others: Array<Item_Others>;
  /** fetch aggregated fields from the table: "item_others" */
  item_others_aggregate: Item_Others_Aggregate;
  /** fetch data from the table: "item_others" using primary key columns */
  item_others_by_pk?: Maybe<Item_Others>;
  /** fetch data from the table: "item_types" */
  item_types: Array<Item_Types>;
  /** fetch aggregated fields from the table: "item_types" */
  item_types_aggregate: Item_Types_Aggregate;
  /** fetch data from the table: "item_types" using primary key columns */
  item_types_by_pk?: Maybe<Item_Types>;
  /** An array relationship */
  items: Array<Items>;
  /** An aggregate relationship */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** fetch data from the table: "knex_migrations" */
  knex_migrations: Array<Knex_Migrations>;
  /** fetch aggregated fields from the table: "knex_migrations" */
  knex_migrations_aggregate: Knex_Migrations_Aggregate;
  /** fetch data from the table: "knex_migrations" using primary key columns */
  knex_migrations_by_pk?: Maybe<Knex_Migrations>;
  /** fetch data from the table: "knex_migrations_lock" */
  knex_migrations_lock: Array<Knex_Migrations_Lock>;
  /** fetch aggregated fields from the table: "knex_migrations_lock" */
  knex_migrations_lock_aggregate: Knex_Migrations_Lock_Aggregate;
  /** fetch data from the table: "knex_migrations_lock" using primary key columns */
  knex_migrations_lock_by_pk?: Maybe<Knex_Migrations_Lock>;
  /** fetch data from the table: "language_isocodes" */
  language_isocodes: Array<Language_Isocodes>;
  /** fetch aggregated fields from the table: "language_isocodes" */
  language_isocodes_aggregate: Language_Isocodes_Aggregate;
  /** fetch data from the table: "language_isocodes" using primary key columns */
  language_isocodes_by_pk?: Maybe<Language_Isocodes>;
  lensApiChallenge: LensApiChallengeOutput;
  lensCheckRelay: LensCheckRelayOutput;
  lensProfilesOwnedByAccount: Array<LensProfilesOwnedByAccountOutput>;
  lensSetDispatcherBuildData: LensSetDispatcherBuildDataOutput;
  /** fetch data from the table: "lens_api_access_tokens" */
  lens_api_access_tokens: Array<Lens_Api_Access_Tokens>;
  /** fetch aggregated fields from the table: "lens_api_access_tokens" */
  lens_api_access_tokens_aggregate: Lens_Api_Access_Tokens_Aggregate;
  /** fetch data from the table: "lens_api_access_tokens" using primary key columns */
  lens_api_access_tokens_by_pk?: Maybe<Lens_Api_Access_Tokens>;
  /** fetch data from the table: "lens_api_refresh_tokens" */
  lens_api_refresh_tokens: Array<Lens_Api_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "lens_api_refresh_tokens" */
  lens_api_refresh_tokens_aggregate: Lens_Api_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "lens_api_refresh_tokens" using primary key columns */
  lens_api_refresh_tokens_by_pk?: Maybe<Lens_Api_Refresh_Tokens>;
  /** An array relationship */
  lens_appid_mappings: Array<Lens_Appid_Mappings>;
  /** An aggregate relationship */
  lens_appid_mappings_aggregate: Lens_Appid_Mappings_Aggregate;
  /** fetch data from the table: "lens_appid_mappings" using primary key columns */
  lens_appid_mappings_by_pk?: Maybe<Lens_Appid_Mappings>;
  /** An array relationship */
  lens_collects: Array<Lens_Collects>;
  /** An aggregate relationship */
  lens_collects_aggregate: Lens_Collects_Aggregate;
  /** fetch data from the table: "lens_collects" using primary key columns */
  lens_collects_by_pk?: Maybe<Lens_Collects>;
  /** fetch data from the table: "lens_custodial_accounts" */
  lens_custodial_accounts: Array<Lens_Custodial_Accounts>;
  /** fetch aggregated fields from the table: "lens_custodial_accounts" */
  lens_custodial_accounts_aggregate: Lens_Custodial_Accounts_Aggregate;
  /** fetch data from the table: "lens_custodial_accounts" using primary key columns */
  lens_custodial_accounts_by_pk?: Maybe<Lens_Custodial_Accounts>;
  /** fetch data from the table: "lens_events" */
  lens_events: Array<Lens_Events>;
  /** fetch aggregated fields from the table: "lens_events" */
  lens_events_aggregate: Lens_Events_Aggregate;
  /** fetch data from the table: "lens_events" using primary key columns */
  lens_events_by_pk?: Maybe<Lens_Events>;
  /** An array relationship */
  lens_followers: Array<Lens_Followers>;
  /** An aggregate relationship */
  lens_followers_aggregate: Lens_Followers_Aggregate;
  /** fetch data from the table: "lens_followers" using primary key columns */
  lens_followers_by_pk?: Maybe<Lens_Followers>;
  /** fetch data from the table: "lens_followers_v2" */
  lens_followers_v2: Array<Lens_Followers_V2>;
  /** fetch aggregated fields from the table: "lens_followers_v2" */
  lens_followers_v2_aggregate: Lens_Followers_V2_Aggregate;
  /** fetch data from the table: "lens_followers_v2" using primary key columns */
  lens_followers_v2_by_pk?: Maybe<Lens_Followers_V2>;
  /** fetch data from the table: "lens_indexer" */
  lens_indexer: Array<Lens_Indexer>;
  /** fetch aggregated fields from the table: "lens_indexer" */
  lens_indexer_aggregate: Lens_Indexer_Aggregate;
  /** fetch data from the table: "lens_indexer" using primary key columns */
  lens_indexer_by_pk?: Maybe<Lens_Indexer>;
  /** fetch data from the table: "lens_invites" */
  lens_invites: Array<Lens_Invites>;
  /** fetch aggregated fields from the table: "lens_invites" */
  lens_invites_aggregate: Lens_Invites_Aggregate;
  /** fetch data from the table: "lens_invites" using primary key columns */
  lens_invites_by_pk?: Maybe<Lens_Invites>;
  /** fetch data from the table: "lens_post_lens_pub_types" */
  lens_post_lens_pub_types: Array<Lens_Post_Lens_Pub_Types>;
  /** fetch aggregated fields from the table: "lens_post_lens_pub_types" */
  lens_post_lens_pub_types_aggregate: Lens_Post_Lens_Pub_Types_Aggregate;
  /** fetch data from the table: "lens_post_lens_pub_types" using primary key columns */
  lens_post_lens_pub_types_by_pk?: Maybe<Lens_Post_Lens_Pub_Types>;
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  /** fetch data from the table: "lens_posts" using primary key columns */
  lens_posts_by_pk?: Maybe<Lens_Posts>;
  /** An array relationship */
  lens_profiles: Array<Lens_Profiles>;
  /** An aggregate relationship */
  lens_profiles_aggregate: Lens_Profiles_Aggregate;
  /** fetch data from the table: "lens_profiles" using primary key columns */
  lens_profiles_by_pk?: Maybe<Lens_Profiles>;
  /** fetch data from the table: "lens_reaction_types" */
  lens_reaction_types: Array<Lens_Reaction_Types>;
  /** fetch aggregated fields from the table: "lens_reaction_types" */
  lens_reaction_types_aggregate: Lens_Reaction_Types_Aggregate;
  /** fetch data from the table: "lens_reaction_types" using primary key columns */
  lens_reaction_types_by_pk?: Maybe<Lens_Reaction_Types>;
  /** An array relationship */
  lens_reactions: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lens_reactions_aggregate: Lens_Reactions_Aggregate;
  /** fetch data from the table: "lens_reactions" using primary key columns */
  lens_reactions_by_pk?: Maybe<Lens_Reactions>;
  /** fetch data from the table: "lens_tx_status_enum" */
  lens_tx_status_enum: Array<Lens_Tx_Status_Enum>;
  /** fetch aggregated fields from the table: "lens_tx_status_enum" */
  lens_tx_status_enum_aggregate: Lens_Tx_Status_Enum_Aggregate;
  /** fetch data from the table: "lens_tx_status_enum" using primary key columns */
  lens_tx_status_enum_by_pk?: Maybe<Lens_Tx_Status_Enum>;
  /** fetch data from the table: "levels" */
  levels: Array<Levels>;
  /** fetch aggregated fields from the table: "levels" */
  levels_aggregate: Levels_Aggregate;
  /** fetch data from the table: "levels" using primary key columns */
  levels_by_pk?: Maybe<Levels>;
  /** An array relationship */
  links: Array<Links>;
  /** An aggregate relationship */
  links_aggregate: Links_Aggregate;
  /** fetch data from the table: "links" using primary key columns */
  links_by_pk?: Maybe<Links>;
  listNfts: Array<ContractWithOwnedNfts>;
  listYield: Array<ListYieldRow>;
  /** fetch data from the table: "locked_balances" */
  locked_balances: Array<Locked_Balances>;
  /** fetch aggregated fields from the table: "locked_balances" */
  locked_balances_aggregate: Locked_Balances_Aggregate;
  /** fetch data from the table: "locked_balances" using primary key columns */
  locked_balances_by_pk?: Maybe<Locked_Balances>;
  /** fetch data from the table: "matview_communities_trending" */
  matview_communities_trending: Array<Matview_Communities_Trending>;
  /** fetch aggregated fields from the table: "matview_communities_trending" */
  matview_communities_trending_aggregate: Matview_Communities_Trending_Aggregate;
  /** fetch data from the table: "matview_creators_active" */
  matview_creators_active: Array<Matview_Creators_Active>;
  /** fetch aggregated fields from the table: "matview_creators_active" */
  matview_creators_active_aggregate: Matview_Creators_Active_Aggregate;
  /** fetch data from the table: "matview_post_scores_7d" */
  matview_post_scores_7d: Array<Matview_Post_Scores_7d>;
  /** fetch aggregated fields from the table: "matview_post_scores_7d" */
  matview_post_scores_7d_aggregate: Matview_Post_Scores_7d_Aggregate;
  /** fetch data from the table: "matview_users_most_follow" */
  matview_users_most_follow: Array<Matview_Users_Most_Follow>;
  /** fetch aggregated fields from the table: "matview_users_most_follow" */
  matview_users_most_follow_aggregate: Matview_Users_Most_Follow_Aggregate;
  /** fetch data from the table: "matview_users_most_follow_lens" */
  matview_users_most_follow_lens: Array<Matview_Users_Most_Follow_Lens>;
  /** fetch aggregated fields from the table: "matview_users_most_follow_lens" */
  matview_users_most_follow_lens_aggregate: Matview_Users_Most_Follow_Lens_Aggregate;
  /** fetch data from the table: "matview_users_trending" */
  matview_users_trending: Array<Matview_Users_Trending>;
  /** fetch aggregated fields from the table: "matview_users_trending" */
  matview_users_trending_aggregate: Matview_Users_Trending_Aggregate;
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  /** fetch data from the table: "medias" using primary key columns */
  medias_by_pk?: Maybe<Medias>;
  /** fetch data from the table: "moca_ids" */
  moca_ids: Array<Moca_Ids>;
  /** fetch aggregated fields from the table: "moca_ids" */
  moca_ids_aggregate: Moca_Ids_Aggregate;
  /** fetch data from the table: "moca_ids" using primary key columns */
  moca_ids_by_pk?: Maybe<Moca_Ids>;
  /** fetch data from the table: "notification_setting_types" */
  notification_setting_types: Array<Notification_Setting_Types>;
  /** fetch aggregated fields from the table: "notification_setting_types" */
  notification_setting_types_aggregate: Notification_Setting_Types_Aggregate;
  /** fetch data from the table: "notification_setting_types" using primary key columns */
  notification_setting_types_by_pk?: Maybe<Notification_Setting_Types>;
  /** An array relationship */
  notification_settings: Array<Notification_Settings>;
  /** An aggregate relationship */
  notification_settings_aggregate: Notification_Settings_Aggregate;
  /** fetch data from the table: "notification_settings" using primary key columns */
  notification_settings_by_pk?: Maybe<Notification_Settings>;
  /** fetch data from the table: "perks" */
  perks: Array<Perks>;
  perksLoad?: Maybe<Array<Maybe<PerkOutput>>>;
  /** fetch aggregated fields from the table: "perks" */
  perks_aggregate: Perks_Aggregate;
  /** fetch data from the table: "perks" using primary key columns */
  perks_by_pk?: Maybe<Perks>;
  /** fetch data from the table: "perks_claimed" */
  perks_claimed: Array<Perks_Claimed>;
  /** fetch aggregated fields from the table: "perks_claimed" */
  perks_claimed_aggregate: Perks_Claimed_Aggregate;
  /** fetch data from the table: "perks_claimed" using primary key columns */
  perks_claimed_by_pk?: Maybe<Perks_Claimed>;
  phairdropEligibilityCheck: PhairDropEligibilityResponse;
  /** fetch data from the table: "post_aggregates" */
  post_aggregates: Array<Post_Aggregates>;
  /** fetch aggregated fields from the table: "post_aggregates" */
  post_aggregates_aggregate: Post_Aggregates_Aggregate;
  /** fetch data from the table: "post_aggregates" using primary key columns */
  post_aggregates_by_pk?: Maybe<Post_Aggregates>;
  /** fetch data from the table: "post_profile_tags" */
  post_profile_tags: Array<Post_Profile_Tags>;
  /** fetch aggregated fields from the table: "post_profile_tags" */
  post_profile_tags_aggregate: Post_Profile_Tags_Aggregate;
  /** fetch data from the table: "post_profile_tags" using primary key columns */
  post_profile_tags_by_pk?: Maybe<Post_Profile_Tags>;
  /** fetch data from the table: "post_reaction_counts" */
  post_reaction_counts: Array<Post_Reaction_Counts>;
  /** fetch aggregated fields from the table: "post_reaction_counts" */
  post_reaction_counts_aggregate: Post_Reaction_Counts_Aggregate;
  /** fetch data from the table: "post_reaction_types" */
  post_reaction_types: Array<Post_Reaction_Types>;
  /** fetch aggregated fields from the table: "post_reaction_types" */
  post_reaction_types_aggregate: Post_Reaction_Types_Aggregate;
  /** fetch data from the table: "post_reaction_types" using primary key columns */
  post_reaction_types_by_pk?: Maybe<Post_Reaction_Types>;
  /** An array relationship */
  post_reactions: Array<Post_Reactions>;
  /** An aggregate relationship */
  post_reactions_aggregate: Post_Reactions_Aggregate;
  /** fetch data from the table: "post_reactions" using primary key columns */
  post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** profileByUsername */
  profileByUsername: ProfileByUsernameOutput;
  /** Only returns communities user has joined. Returns non-posted to communities as well after the posted ones. */
  profileLatestCommunitiesPostedTo: Array<ProfileLatestCommunitiesPostedToReply>;
  /** fetch data from the table: "profile_aggregates" */
  profile_aggregates: Array<Profile_Aggregates>;
  /** fetch aggregated fields from the table: "profile_aggregates" */
  profile_aggregates_aggregate: Profile_Aggregates_Aggregate;
  /** fetch data from the table: "profile_aggregates" using primary key columns */
  profile_aggregates_by_pk?: Maybe<Profile_Aggregates>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table: "push_tokens" */
  push_tokens: Array<Push_Tokens>;
  /** fetch aggregated fields from the table: "push_tokens" */
  push_tokens_aggregate: Push_Tokens_Aggregate;
  /** fetch data from the table: "push_tokens" using primary key columns */
  push_tokens_by_pk?: Maybe<Push_Tokens>;
  /** quota */
  quota?: Maybe<QuotaReply>;
  /** An array relationship */
  recommendation_comments: Array<Recommendation_Comments>;
  /** An aggregate relationship */
  recommendation_comments_aggregate: Recommendation_Comments_Aggregate;
  /** fetch data from the table: "recommendation_comments" using primary key columns */
  recommendation_comments_by_pk?: Maybe<Recommendation_Comments>;
  /** An array relationship */
  recommendation_votes: Array<Recommendation_Votes>;
  /** An aggregate relationship */
  recommendation_votes_aggregate: Recommendation_Votes_Aggregate;
  /** fetch data from the table: "recommendation_votes" using primary key columns */
  recommendation_votes_by_pk?: Maybe<Recommendation_Votes>;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: Recommendations_Aggregate;
  /** fetch data from the table: "recommendations" using primary key columns */
  recommendations_by_pk?: Maybe<Recommendations>;
  /** fetch data from the table: "reported_content" */
  reported_content: Array<Reported_Content>;
  /** fetch aggregated fields from the table: "reported_content" */
  reported_content_aggregate: Reported_Content_Aggregate;
  /** fetch data from the table: "reported_content" using primary key columns */
  reported_content_by_pk?: Maybe<Reported_Content>;
  /** resolveWallets */
  resolveWallets?: Maybe<ResolveWalletsOutput>;
  /** An array relationship */
  reward_brands: Array<Reward_Brands>;
  /** An aggregate relationship */
  reward_brands_aggregate: Reward_Brands_Aggregate;
  /** fetch data from the table: "reward_brands" using primary key columns */
  reward_brands_by_pk?: Maybe<Reward_Brands>;
  /** An array relationship */
  reward_claims: Array<Reward_Claims>;
  /** An aggregate relationship */
  reward_claims_aggregate: Reward_Claims_Aggregate;
  /** fetch data from the table: "reward_claims" using primary key columns */
  reward_claims_by_pk?: Maybe<Reward_Claims>;
  /** An array relationship */
  reward_types: Array<Reward_Types>;
  /** An aggregate relationship */
  reward_types_aggregate: Reward_Types_Aggregate;
  /** fetch data from the table: "reward_types" using primary key columns */
  reward_types_by_pk?: Maybe<Reward_Types>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** An array relationship */
  rss_feeds: Array<Rss_Feeds>;
  /** An aggregate relationship */
  rss_feeds_aggregate: Rss_Feeds_Aggregate;
  /** fetch data from the table: "rss_feeds" using primary key columns */
  rss_feeds_by_pk?: Maybe<Rss_Feeds>;
  search?: Maybe<Array<ResultList>>;
  searchFarcasterChannels: Array<SearchFarcasterChannelsOutput>;
  sortedCommunities: Array<SortedCommunitiesResults>;
  sortedCommunityCategories: Array<SortedCommunityCategoryResult>;
  sortedLensAppTopics: Array<SortedLensAppTopic>;
  sortedPosts: Array<SortedPost>;
  sortedUsers: Array<SortedUsersResults>;
  squid?: Maybe<Squid_Query>;
  /** fetch data from the table: "staking_reward_posters" */
  staking_reward_posters: Array<Staking_Reward_Posters>;
  /** fetch aggregated fields from the table: "staking_reward_posters" */
  staking_reward_posters_aggregate: Staking_Reward_Posters_Aggregate;
  /** fetch data from the table: "staking_reward_posters" using primary key columns */
  staking_reward_posters_by_pk?: Maybe<Staking_Reward_Posters>;
  /** fetch data from the table: "staking_reward_stakers" */
  staking_reward_stakers: Array<Staking_Reward_Stakers>;
  /** fetch aggregated fields from the table: "staking_reward_stakers" */
  staking_reward_stakers_aggregate: Staking_Reward_Stakers_Aggregate;
  /** fetch data from the table: "staking_reward_stakers" using primary key columns */
  staking_reward_stakers_by_pk?: Maybe<Staking_Reward_Stakers>;
  /** fetch data from the table: "subdivisions" */
  subdivisions: Array<Subdivisions>;
  /** fetch aggregated fields from the table: "subdivisions" */
  subdivisions_aggregate: Subdivisions_Aggregate;
  /** fetch data from the table: "subdivisions" using primary key columns */
  subdivisions_by_pk?: Maybe<Subdivisions>;
  /** fetch data from the table: "task_sections" */
  task_sections: Array<Task_Sections>;
  /** fetch aggregated fields from the table: "task_sections" */
  task_sections_aggregate: Task_Sections_Aggregate;
  /** fetch data from the table: "task_sections" using primary key columns */
  task_sections_by_pk?: Maybe<Task_Sections>;
  /** fetch data from the table: "task_types" */
  task_types: Array<Task_Types>;
  /** fetch aggregated fields from the table: "task_types" */
  task_types_aggregate: Task_Types_Aggregate;
  /** fetch data from the table: "task_types" using primary key columns */
  task_types_by_pk?: Maybe<Task_Types>;
  /** fetch data from the table: "tasks" */
  tasks: Array<Tasks>;
  /** fetch aggregated fields from the table: "tasks" */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table: "tasks_done" */
  tasks_done: Array<Tasks_Done>;
  /** fetch aggregated fields from the table: "tasks_done" */
  tasks_done_aggregate: Tasks_Done_Aggregate;
  /** fetch data from the table: "tasks_done" using primary key columns */
  tasks_done_by_pk?: Maybe<Tasks_Done>;
  unreadNotificationsCount?: Maybe<UnreadNotificationsCount>;
  /** userRanking */
  userRanking?: Maybe<UserRankingOutput>;
  validateLink?: Maybe<ValidateLinkOutput>;
  /** fetch data from the table: "verification_types" */
  verification_types: Array<Verification_Types>;
  /** fetch aggregated fields from the table: "verification_types" */
  verification_types_aggregate: Verification_Types_Aggregate;
  /** fetch data from the table: "verification_types" using primary key columns */
  verification_types_by_pk?: Maybe<Verification_Types>;
  /** fetch data from the table: "verified_nft_project_kinds" */
  verified_nft_project_kinds: Array<Verified_Nft_Project_Kinds>;
  /** fetch aggregated fields from the table: "verified_nft_project_kinds" */
  verified_nft_project_kinds_aggregate: Verified_Nft_Project_Kinds_Aggregate;
  /** fetch data from the table: "verified_nft_project_kinds" using primary key columns */
  verified_nft_project_kinds_by_pk?: Maybe<Verified_Nft_Project_Kinds>;
  /** An array relationship */
  verified_nft_projects: Array<Verified_Nft_Projects>;
  /** An aggregate relationship */
  verified_nft_projects_aggregate: Verified_Nft_Projects_Aggregate;
  /** fetch data from the table: "verified_nft_projects" using primary key columns */
  verified_nft_projects_by_pk?: Maybe<Verified_Nft_Projects>;
  /** videoUrl */
  videoUrl?: Maybe<VideoUrlOutput>;
  /** fetch data from the table: "videos" */
  videos: Array<Videos>;
  /** fetch aggregated fields from the table: "videos" */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** An array relationship */
  wallets: Array<Wallets>;
  /** An aggregate relationship */
  wallets_aggregate: Wallets_Aggregate;
  /** fetch data from the table: "wallets" using primary key columns */
  wallets_by_pk?: Maybe<Wallets>;
  /** fetch data from the table: "wallets_resolves" */
  wallets_resolves: Array<Wallets_Resolves>;
  /** fetch aggregated fields from the table: "wallets_resolves" */
  wallets_resolves_aggregate: Wallets_Resolves_Aggregate;
  /** fetch data from the table: "wallets_resolves" using primary key columns */
  wallets_resolves_by_pk?: Maybe<Wallets_Resolves>;
  /** fetch data from the table: "yield_sources" */
  yield_sources: Array<Yield_Sources>;
  /** fetch aggregated fields from the table: "yield_sources" */
  yield_sources_aggregate: Yield_Sources_Aggregate;
  /** fetch data from the table: "yield_sources" using primary key columns */
  yield_sources_by_pk?: Maybe<Yield_Sources>;
};


export type Query_RootAccountReferralsArgs = {
  initialQueryTimestamp: Scalars['timestamptz'];
  limit?: InputMaybe<Scalars['Int']>;
  mode: AccountReferralMode;
  offset?: InputMaybe<Scalars['Int']>;
};


export type Query_RootAccount_TypesArgs = {
  distinct_on?: InputMaybe<Array<Account_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Types_Order_By>>;
  where?: InputMaybe<Account_Types_Bool_Exp>;
};


export type Query_RootAccount_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Types_Order_By>>;
  where?: InputMaybe<Account_Types_Bool_Exp>;
};


export type Query_RootAccount_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootAdmin_Portal_WhitelistArgs = {
  distinct_on?: InputMaybe<Array<Admin_Portal_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Portal_Whitelist_Order_By>>;
  where?: InputMaybe<Admin_Portal_Whitelist_Bool_Exp>;
};


export type Query_RootAdmin_Portal_Whitelist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admin_Portal_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Portal_Whitelist_Order_By>>;
  where?: InputMaybe<Admin_Portal_Whitelist_Bool_Exp>;
};


export type Query_RootAdmin_Portal_Whitelist_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAirdropArgs = {
  distinct_on?: InputMaybe<Array<Airdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrop_Order_By>>;
  where?: InputMaybe<Airdrop_Bool_Exp>;
};


export type Query_RootAirdrop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrop_Order_By>>;
  where?: InputMaybe<Airdrop_Bool_Exp>;
};


export type Query_RootAirdrop_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Query_RootAsync_StatusArgs = {
  distinct_on?: InputMaybe<Array<Async_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Async_Status_Order_By>>;
  where?: InputMaybe<Async_Status_Bool_Exp>;
};


export type Query_RootAsync_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Async_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Async_Status_Order_By>>;
  where?: InputMaybe<Async_Status_Bool_Exp>;
};


export type Query_RootAsync_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAudiosArgs = {
  distinct_on?: InputMaybe<Array<Audios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audios_Order_By>>;
  where?: InputMaybe<Audios_Bool_Exp>;
};


export type Query_RootAudios_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audios_Order_By>>;
  where?: InputMaybe<Audios_Bool_Exp>;
};


export type Query_RootAudios_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootBadge_TypesArgs = {
  distinct_on?: InputMaybe<Array<Badge_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Types_Order_By>>;
  where?: InputMaybe<Badge_Types_Bool_Exp>;
};


export type Query_RootBadge_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Types_Order_By>>;
  where?: InputMaybe<Badge_Types_Bool_Exp>;
};


export type Query_RootBadge_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootBlocked_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blocked_Profiles_Order_By>>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};


export type Query_RootBlocked_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blocked_Profiles_Order_By>>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};


export type Query_RootBlocked_Profiles_By_PkArgs = {
  blocked_profile_id: Scalars['String'];
  profile_id: Scalars['String'];
};


export type Query_RootCampaign_SignaturesArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Signatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Signatures_Order_By>>;
  where?: InputMaybe<Campaign_Signatures_Bool_Exp>;
};


export type Query_RootCampaign_Signatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Signatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Signatures_Order_By>>;
  where?: InputMaybe<Campaign_Signatures_Bool_Exp>;
};


export type Query_RootCampaign_Signatures_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCcFollowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  followType?: InputMaybe<CcFollowType>;
  handle: Scalars['String'];
  limit: Scalars['Int'];
};


export type Query_RootCcProfilesOwnedByAccountArgs = {
  account: Scalars['String'];
};


export type Query_RootCc_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cc_Profiles_Order_By>>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};


export type Query_RootCc_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cc_Profiles_Order_By>>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};


export type Query_RootCc_Profiles_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCheckAnimaStatusArgs = {
  profileId?: InputMaybe<Scalars['String']>;
  refresh?: InputMaybe<RefreshAnimaStatus>;
};


export type Query_RootCheckLensHandleAvailabilityArgs = {
  handle: Scalars['String'];
};


export type Query_RootCitiesArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Query_RootCities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Query_RootCities_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootClaimed_YieldArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_Order_By>>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};


export type Query_RootClaimed_Yield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_Order_By>>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};


export type Query_RootClaimed_Yield_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClaimed_Yield_By_ProjectArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_By_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_By_Project_Order_By>>;
  where?: InputMaybe<Claimed_Yield_By_Project_Bool_Exp>;
};


export type Query_RootClaimed_Yield_By_Project_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_By_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_By_Project_Order_By>>;
  where?: InputMaybe<Claimed_Yield_By_Project_Bool_Exp>;
};


export type Query_RootClosestCitiesArgs = {
  count?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['Float']>;
  long?: InputMaybe<Scalars['Float']>;
};


export type Query_RootComment_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Comment_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Aggregates_Order_By>>;
  where?: InputMaybe<Comment_Aggregates_Bool_Exp>;
};


export type Query_RootComment_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Aggregates_Order_By>>;
  where?: InputMaybe<Comment_Aggregates_Bool_Exp>;
};


export type Query_RootComment_Aggregates_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootComment_Profile_TagsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Profile_Tags_Order_By>>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};


export type Query_RootComment_Profile_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Profile_Tags_Order_By>>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};


export type Query_RootComment_Profile_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootComment_VotesArgs = {
  distinct_on?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Votes_Order_By>>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};


export type Query_RootComment_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Votes_Order_By>>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};


export type Query_RootComment_Votes_By_PkArgs = {
  commentId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Query_RootCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


export type Query_RootCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


export type Query_RootCommunities_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCommunity_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Community_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Aggregates_Order_By>>;
  where?: InputMaybe<Community_Aggregates_Bool_Exp>;
};


export type Query_RootCommunity_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Aggregates_Order_By>>;
  where?: InputMaybe<Community_Aggregates_Bool_Exp>;
};


export type Query_RootCommunity_Aggregates_By_PkArgs = {
  communityId: Scalars['String'];
};


export type Query_RootCommunity_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


export type Query_RootCommunity_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


export type Query_RootCommunity_Categories_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCommunity_Content_CollectionsArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Collections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Collections_Order_By>>;
  where?: InputMaybe<Community_Content_Collections_Bool_Exp>;
};


export type Query_RootCommunity_Content_Collections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Collections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Collections_Order_By>>;
  where?: InputMaybe<Community_Content_Collections_Bool_Exp>;
};


export type Query_RootCommunity_Content_Collections_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCommunity_Content_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Questions_Order_By>>;
  where?: InputMaybe<Community_Content_Questions_Bool_Exp>;
};


export type Query_RootCommunity_Content_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Questions_Order_By>>;
  where?: InputMaybe<Community_Content_Questions_Bool_Exp>;
};


export type Query_RootCommunity_Content_Questions_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCommunity_Content_Vote_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Vote_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Vote_Scores_Order_By>>;
  where?: InputMaybe<Community_Content_Vote_Scores_Bool_Exp>;
};


export type Query_RootCommunity_Content_Vote_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Vote_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Vote_Scores_Order_By>>;
  where?: InputMaybe<Community_Content_Vote_Scores_Bool_Exp>;
};


export type Query_RootCommunity_Content_VotesArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Votes_Order_By>>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};


export type Query_RootCommunity_Content_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Votes_Order_By>>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};


export type Query_RootCommunity_Content_Votes_By_PkArgs = {
  communityContentId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Query_RootCommunity_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Community_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Contents_Order_By>>;
  where?: InputMaybe<Community_Contents_Bool_Exp>;
};


export type Query_RootCommunity_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Contents_Order_By>>;
  where?: InputMaybe<Community_Contents_Bool_Exp>;
};


export type Query_RootCommunity_Contents_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCommunity_MembersArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


export type Query_RootCommunity_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


export type Query_RootCommunity_Members_By_PkArgs = {
  communityId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Query_RootCommunity_Nft_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


export type Query_RootCommunity_Nft_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


export type Query_RootCommunity_Nft_Projects_By_PkArgs = {
  communityId: Scalars['String'];
  verifiedNftProjectId: Scalars['Int'];
};


export type Query_RootCommunity_StaffpicksArgs = {
  distinct_on?: InputMaybe<Array<Community_Staffpicks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Staffpicks_Order_By>>;
  where?: InputMaybe<Community_Staffpicks_Bool_Exp>;
};


export type Query_RootCommunity_Staffpicks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Staffpicks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Staffpicks_Order_By>>;
  where?: InputMaybe<Community_Staffpicks_Bool_Exp>;
};


export type Query_RootCommunity_Staffpicks_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootConnected_NftsArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


export type Query_RootConnected_Nfts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


export type Query_RootConnected_Nfts_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootContinentsArgs = {
  distinct_on?: InputMaybe<Array<Continents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Continents_Order_By>>;
  where?: InputMaybe<Continents_Bool_Exp>;
};


export type Query_RootContinents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Continents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Continents_Order_By>>;
  where?: InputMaybe<Continents_Bool_Exp>;
};


export type Query_RootContinents_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCreateFarcasterAccountBuildDataArgs = {
  address: Scalars['String'];
  fname: Scalars['String'];
};


export type Query_RootCred_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cred_Scores_Order_By>>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};


export type Query_RootCred_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cred_Scores_Order_By>>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};


export type Query_RootCred_Scores_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Query_RootCurrency_IsocodesArgs = {
  distinct_on?: InputMaybe<Array<Currency_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Isocodes_Order_By>>;
  where?: InputMaybe<Currency_Isocodes_Bool_Exp>;
};


export type Query_RootCurrency_Isocodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Isocodes_Order_By>>;
  where?: InputMaybe<Currency_Isocodes_Bool_Exp>;
};


export type Query_RootCurrency_Isocodes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootDailyStakingQuotaArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};


export type Query_RootDaily_StakingArgs = {
  distinct_on?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Staking_Order_By>>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};


export type Query_RootDaily_Staking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Staking_Order_By>>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};


export type Query_RootDaily_Staking_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEmbedded_UrlsArgs = {
  distinct_on?: InputMaybe<Array<Embedded_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Embedded_Urls_Order_By>>;
  where?: InputMaybe<Embedded_Urls_Bool_Exp>;
};


export type Query_RootEmbedded_Urls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Embedded_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Embedded_Urls_Order_By>>;
  where?: InputMaybe<Embedded_Urls_Bool_Exp>;
};


export type Query_RootEmbedded_Urls_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEventLogsArgs = {
  initialQueryTimestamp: Scalars['timestamptz'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type Query_RootEventPointArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};


export type Query_RootFarcaster_CastsArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Casts_Order_By>>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};


export type Query_RootFarcaster_Casts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Casts_Order_By>>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};


export type Query_RootFarcaster_Casts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFarcaster_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Invites_Order_By>>;
  where?: InputMaybe<Farcaster_Invites_Bool_Exp>;
};


export type Query_RootFarcaster_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Invites_Order_By>>;
  where?: InputMaybe<Farcaster_Invites_Bool_Exp>;
};


export type Query_RootFarcaster_Invites_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Query_RootFarcaster_Power_Badge_UsersArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Power_Badge_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Power_Badge_Users_Order_By>>;
  where?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
};


export type Query_RootFarcaster_Power_Badge_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Power_Badge_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Power_Badge_Users_Order_By>>;
  where?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
};


export type Query_RootFarcaster_Power_Badge_Users_By_PkArgs = {
  fid: Scalars['String'];
};


export type Query_RootFarcaster_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Profiles_Order_By>>;
  where?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
};


export type Query_RootFarcaster_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Profiles_Order_By>>;
  where?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
};


export type Query_RootFarcaster_Profiles_By_PkArgs = {
  tokenId: Scalars['String'];
};


export type Query_RootFarcaster_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Reactions_Order_By>>;
  where?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
};


export type Query_RootFarcaster_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Reactions_Order_By>>;
  where?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
};


export type Query_RootFarcaster_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFarcaster_SignersArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Signers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Signers_Order_By>>;
  where?: InputMaybe<Farcaster_Signers_Bool_Exp>;
};


export type Query_RootFarcaster_Signers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Signers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Signers_Order_By>>;
  where?: InputMaybe<Farcaster_Signers_Bool_Exp>;
};


export type Query_RootFarcaster_Signers_By_PkArgs = {
  signerUuid: Scalars['String'];
};


export type Query_RootFlag_TypesArgs = {
  distinct_on?: InputMaybe<Array<Flag_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flag_Types_Order_By>>;
  where?: InputMaybe<Flag_Types_Bool_Exp>;
};


export type Query_RootFlag_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flag_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flag_Types_Order_By>>;
  where?: InputMaybe<Flag_Types_Bool_Exp>;
};


export type Query_RootFlag_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Query_RootFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Query_RootFlags_By_PkArgs = {
  accountId: Scalars['String'];
  flagType: Flag_Types_Enum;
};


export type Query_RootFollowersArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


export type Query_RootFollowers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


export type Query_RootFollowers_By_PkArgs = {
  followedProfileId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Query_RootFrame_ActionArgs = {
  distinct_on?: InputMaybe<Array<Frame_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Action_Order_By>>;
  where?: InputMaybe<Frame_Action_Bool_Exp>;
};


export type Query_RootFrame_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frame_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Action_Order_By>>;
  where?: InputMaybe<Frame_Action_Bool_Exp>;
};


export type Query_RootFrame_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFrame_ButtonsArgs = {
  distinct_on?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Buttons_Order_By>>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};


export type Query_RootFrame_Buttons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Buttons_Order_By>>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};


export type Query_RootFrame_Buttons_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFramesArgs = {
  distinct_on?: InputMaybe<Array<Frames_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frames_Order_By>>;
  where?: InputMaybe<Frames_Bool_Exp>;
};


export type Query_RootFrames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frames_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frames_Order_By>>;
  where?: InputMaybe<Frames_Bool_Exp>;
};


export type Query_RootFrames_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGeneratePostPreviewArgs = {
  description: Scalars['String'];
  link?: InputMaybe<LinkInput>;
};


export type Query_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootItem_OthersArgs = {
  distinct_on?: InputMaybe<Array<Item_Others_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Others_Order_By>>;
  where?: InputMaybe<Item_Others_Bool_Exp>;
};


export type Query_RootItem_Others_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Others_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Others_Order_By>>;
  where?: InputMaybe<Item_Others_Bool_Exp>;
};


export type Query_RootItem_Others_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootItem_TypesArgs = {
  distinct_on?: InputMaybe<Array<Item_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Types_Order_By>>;
  where?: InputMaybe<Item_Types_Bool_Exp>;
};


export type Query_RootItem_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Types_Order_By>>;
  where?: InputMaybe<Item_Types_Bool_Exp>;
};


export type Query_RootItem_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKnex_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Bool_Exp>;
};


export type Query_RootKnex_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Bool_Exp>;
};


export type Query_RootKnex_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootKnex_Migrations_LockArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Lock_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Lock_Bool_Exp>;
};


export type Query_RootKnex_Migrations_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Lock_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Lock_Bool_Exp>;
};


export type Query_RootKnex_Migrations_Lock_By_PkArgs = {
  index: Scalars['Int'];
};


export type Query_RootLanguage_IsocodesArgs = {
  distinct_on?: InputMaybe<Array<Language_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Isocodes_Order_By>>;
  where?: InputMaybe<Language_Isocodes_Bool_Exp>;
};


export type Query_RootLanguage_Isocodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Isocodes_Order_By>>;
  where?: InputMaybe<Language_Isocodes_Bool_Exp>;
};


export type Query_RootLanguage_Isocodes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLensApiChallengeArgs = {
  address: Scalars['String'];
  lensProfileId?: InputMaybe<Scalars['String']>;
};


export type Query_RootLensProfilesOwnedByAccountArgs = {
  account: Scalars['String'];
};


export type Query_RootLensSetDispatcherBuildDataArgs = {
  dispatcher?: InputMaybe<DispatcherTypes>;
  lensProfileId: Scalars['String'];
};


export type Query_RootLens_Api_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Access_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Access_Tokens_Bool_Exp>;
};


export type Query_RootLens_Api_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Access_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Access_Tokens_Bool_Exp>;
};


export type Query_RootLens_Api_Access_Tokens_By_PkArgs = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};


export type Query_RootLens_Api_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
};


export type Query_RootLens_Api_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
};


export type Query_RootLens_Api_Refresh_Tokens_By_PkArgs = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};


export type Query_RootLens_Appid_MappingsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Appid_Mappings_Order_By>>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};


export type Query_RootLens_Appid_Mappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Appid_Mappings_Order_By>>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};


export type Query_RootLens_Appid_Mappings_By_PkArgs = {
  app_id: Scalars['String'];
};


export type Query_RootLens_CollectsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


export type Query_RootLens_Collects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


export type Query_RootLens_Collects_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootLens_Custodial_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Custodial_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Custodial_Accounts_Order_By>>;
  where?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
};


export type Query_RootLens_Custodial_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Custodial_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Custodial_Accounts_Order_By>>;
  where?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
};


export type Query_RootLens_Custodial_Accounts_By_PkArgs = {
  address: Scalars['String'];
};


export type Query_RootLens_EventsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Events_Order_By>>;
  where?: InputMaybe<Lens_Events_Bool_Exp>;
};


export type Query_RootLens_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Events_Order_By>>;
  where?: InputMaybe<Lens_Events_Bool_Exp>;
};


export type Query_RootLens_Events_By_PkArgs = {
  log_index: Scalars['Int'];
  transaction_hash: Scalars['String'];
};


export type Query_RootLens_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_Order_By>>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};


export type Query_RootLens_Followers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_Order_By>>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};


export type Query_RootLens_Followers_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootLens_Followers_V2Args = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


export type Query_RootLens_Followers_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


export type Query_RootLens_Followers_V2_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootLens_IndexerArgs = {
  distinct_on?: InputMaybe<Array<Lens_Indexer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Indexer_Order_By>>;
  where?: InputMaybe<Lens_Indexer_Bool_Exp>;
};


export type Query_RootLens_Indexer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Indexer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Indexer_Order_By>>;
  where?: InputMaybe<Lens_Indexer_Bool_Exp>;
};


export type Query_RootLens_Indexer_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootLens_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Invites_Order_By>>;
  where?: InputMaybe<Lens_Invites_Bool_Exp>;
};


export type Query_RootLens_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Invites_Order_By>>;
  where?: InputMaybe<Lens_Invites_Bool_Exp>;
};


export type Query_RootLens_Invites_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Query_RootLens_Post_Lens_Pub_TypesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Order_By>>;
  where?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
};


export type Query_RootLens_Post_Lens_Pub_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Order_By>>;
  where?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
};


export type Query_RootLens_Post_Lens_Pub_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


export type Query_RootLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


export type Query_RootLens_Posts_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootLens_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


export type Query_RootLens_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


export type Query_RootLens_Profiles_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootLens_Reaction_TypesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reaction_Types_Order_By>>;
  where?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
};


export type Query_RootLens_Reaction_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reaction_Types_Order_By>>;
  where?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
};


export type Query_RootLens_Reaction_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLens_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


export type Query_RootLens_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


export type Query_RootLens_Reactions_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootLens_Tx_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Lens_Tx_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Tx_Status_Enum_Order_By>>;
  where?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
};


export type Query_RootLens_Tx_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Tx_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Tx_Status_Enum_Order_By>>;
  where?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
};


export type Query_RootLens_Tx_Status_Enum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLevelsArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Query_RootLevels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Query_RootLevels_By_PkArgs = {
  level: Scalars['Int'];
};


export type Query_RootLinksArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


export type Query_RootLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


export type Query_RootLinks_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootListNftsArgs = {
  address: Scalars['String'];
};


export type Query_RootListYieldArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  rewardDateEarliest?: InputMaybe<Scalars['String']>;
  rewardDateLatest: Scalars['String'];
  rewardType: ListYieldRewardType;
};


export type Query_RootLocked_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Locked_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locked_Balances_Order_By>>;
  where?: InputMaybe<Locked_Balances_Bool_Exp>;
};


export type Query_RootLocked_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locked_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locked_Balances_Order_By>>;
  where?: InputMaybe<Locked_Balances_Bool_Exp>;
};


export type Query_RootLocked_Balances_By_PkArgs = {
  accountId: Scalars['String'];
};


export type Query_RootMatview_Communities_TrendingArgs = {
  distinct_on?: InputMaybe<Array<Matview_Communities_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Communities_Trending_Order_By>>;
  where?: InputMaybe<Matview_Communities_Trending_Bool_Exp>;
};


export type Query_RootMatview_Communities_Trending_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Communities_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Communities_Trending_Order_By>>;
  where?: InputMaybe<Matview_Communities_Trending_Bool_Exp>;
};


export type Query_RootMatview_Creators_ActiveArgs = {
  distinct_on?: InputMaybe<Array<Matview_Creators_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Creators_Active_Order_By>>;
  where?: InputMaybe<Matview_Creators_Active_Bool_Exp>;
};


export type Query_RootMatview_Creators_Active_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Creators_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Creators_Active_Order_By>>;
  where?: InputMaybe<Matview_Creators_Active_Bool_Exp>;
};


export type Query_RootMatview_Post_Scores_7dArgs = {
  distinct_on?: InputMaybe<Array<Matview_Post_Scores_7d_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Post_Scores_7d_Order_By>>;
  where?: InputMaybe<Matview_Post_Scores_7d_Bool_Exp>;
};


export type Query_RootMatview_Post_Scores_7d_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Post_Scores_7d_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Post_Scores_7d_Order_By>>;
  where?: InputMaybe<Matview_Post_Scores_7d_Bool_Exp>;
};


export type Query_RootMatview_Users_Most_FollowArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Bool_Exp>;
};


export type Query_RootMatview_Users_Most_Follow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Bool_Exp>;
};


export type Query_RootMatview_Users_Most_Follow_LensArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Lens_Bool_Exp>;
};


export type Query_RootMatview_Users_Most_Follow_Lens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Lens_Bool_Exp>;
};


export type Query_RootMatview_Users_TrendingArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Trending_Order_By>>;
  where?: InputMaybe<Matview_Users_Trending_Bool_Exp>;
};


export type Query_RootMatview_Users_Trending_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Trending_Order_By>>;
  where?: InputMaybe<Matview_Users_Trending_Bool_Exp>;
};


export type Query_RootMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


export type Query_RootMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


export type Query_RootMedias_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootMoca_IdsArgs = {
  distinct_on?: InputMaybe<Array<Moca_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Moca_Ids_Order_By>>;
  where?: InputMaybe<Moca_Ids_Bool_Exp>;
};


export type Query_RootMoca_Ids_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Moca_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Moca_Ids_Order_By>>;
  where?: InputMaybe<Moca_Ids_Bool_Exp>;
};


export type Query_RootMoca_Ids_By_PkArgs = {
  mocaId: Scalars['String'];
};


export type Query_RootNotification_Setting_TypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Types_Order_By>>;
  where?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
};


export type Query_RootNotification_Setting_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Types_Order_By>>;
  where?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
};


export type Query_RootNotification_Setting_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootNotification_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


export type Query_RootNotification_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


export type Query_RootNotification_Settings_By_PkArgs = {
  accountId: Scalars['String'];
  eventType: Notification_Setting_Types_Enum;
};


export type Query_RootPerksArgs = {
  distinct_on?: InputMaybe<Array<Perks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Order_By>>;
  where?: InputMaybe<Perks_Bool_Exp>;
};


export type Query_RootPerks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Perks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Order_By>>;
  where?: InputMaybe<Perks_Bool_Exp>;
};


export type Query_RootPerks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPerks_ClaimedArgs = {
  distinct_on?: InputMaybe<Array<Perks_Claimed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Claimed_Order_By>>;
  where?: InputMaybe<Perks_Claimed_Bool_Exp>;
};


export type Query_RootPerks_Claimed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Perks_Claimed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Claimed_Order_By>>;
  where?: InputMaybe<Perks_Claimed_Bool_Exp>;
};


export type Query_RootPerks_Claimed_By_PkArgs = {
  perkId: Scalars['Int'];
  profileId: Scalars['String'];
};


export type Query_RootPhairdropEligibilityCheckArgs = {
  fid?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type Query_RootPost_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Post_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Aggregates_Order_By>>;
  where?: InputMaybe<Post_Aggregates_Bool_Exp>;
};


export type Query_RootPost_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Aggregates_Order_By>>;
  where?: InputMaybe<Post_Aggregates_Bool_Exp>;
};


export type Query_RootPost_Aggregates_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootPost_Profile_TagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Profile_Tags_Order_By>>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};


export type Query_RootPost_Profile_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Profile_Tags_Order_By>>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};


export type Query_RootPost_Profile_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPost_Reaction_CountsArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Counts_Order_By>>;
  where?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
};


export type Query_RootPost_Reaction_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Counts_Order_By>>;
  where?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
};


export type Query_RootPost_Reaction_TypesArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Types_Order_By>>;
  where?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
};


export type Query_RootPost_Reaction_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Types_Order_By>>;
  where?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
};


export type Query_RootPost_Reaction_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPost_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


export type Query_RootPost_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


export type Query_RootPost_Reactions_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProfileByUsernameArgs = {
  username: Scalars['String'];
};


export type Query_RootProfileLatestCommunitiesPostedToArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  profileId: Scalars['String'];
};


export type Query_RootProfile_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Aggregates_Order_By>>;
  where?: InputMaybe<Profile_Aggregates_Bool_Exp>;
};


export type Query_RootProfile_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Aggregates_Order_By>>;
  where?: InputMaybe<Profile_Aggregates_Bool_Exp>;
};


export type Query_RootProfile_Aggregates_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Query_RootProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootPush_TokensArgs = {
  distinct_on?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Push_Tokens_Order_By>>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};


export type Query_RootPush_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Push_Tokens_Order_By>>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};


export type Query_RootPush_Tokens_By_PkArgs = {
  accountId: Scalars['String'];
  expoPushToken: Scalars['String'];
};


export type Query_RootQuotaArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};


export type Query_RootRecommendation_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


export type Query_RootRecommendation_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


export type Query_RootRecommendation_Comments_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRecommendation_VotesArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};


export type Query_RootRecommendation_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};


export type Query_RootRecommendation_Votes_By_PkArgs = {
  profileId: Scalars['String'];
  recommendationId: Scalars['String'];
};


export type Query_RootRecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


export type Query_RootRecommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


export type Query_RootRecommendations_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootReported_ContentArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


export type Query_RootReported_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


export type Query_RootReported_Content_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootResolveWalletsArgs = {
  walletAddresses: Array<Scalars['String']>;
};


export type Query_RootReward_BrandsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


export type Query_RootReward_Brands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


export type Query_RootReward_Brands_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootReward_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


export type Query_RootReward_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


export type Query_RootReward_Claims_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootReward_TypesArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


export type Query_RootReward_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


export type Query_RootReward_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRss_FeedsArgs = {
  distinct_on?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rss_Feeds_Order_By>>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};


export type Query_RootRss_Feeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rss_Feeds_Order_By>>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};


export type Query_RootRss_Feeds_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootSearchArgs = {
  index: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type Query_RootSearchFarcasterChannelsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchTerm: Scalars['String'];
};


export type Query_RootSortedCommunitiesArgs = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  count?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  mode: SortedCommunitiesModes;
  offset?: InputMaybe<Scalars['Int']>;
};


export type Query_RootSortedLensAppTopicsArgs = {
  initialQueryTimestamp: Scalars['timestamptz'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type Query_RootSortedPostsArgs = {
  filters?: InputMaybe<Array<SortedPostsFilter>>;
  initialQueryTimestamp: Scalars['timestamptz'];
  limit: Scalars['Int'];
  mode: SortedPostsMode;
  offset: Scalars['Int'];
  profileId?: InputMaybe<Scalars['String']>;
  timeRange?: InputMaybe<TimeRangeOption>;
  topicIds: Array<Scalars['String']>;
};


export type Query_RootSortedUsersArgs = {
  filters?: InputMaybe<Array<SortedUsersFilter>>;
  initialQueryTimestamp?: InputMaybe<Scalars['timestamptz']>;
  limit?: InputMaybe<Scalars['Int']>;
  mode: SortedUsersModes;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortedUsersSort>;
};


export type Query_RootStaking_Reward_PostersArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Posters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Posters_Order_By>>;
  where?: InputMaybe<Staking_Reward_Posters_Bool_Exp>;
};


export type Query_RootStaking_Reward_Posters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Posters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Posters_Order_By>>;
  where?: InputMaybe<Staking_Reward_Posters_Bool_Exp>;
};


export type Query_RootStaking_Reward_Posters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStaking_Reward_StakersArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Stakers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Stakers_Order_By>>;
  where?: InputMaybe<Staking_Reward_Stakers_Bool_Exp>;
};


export type Query_RootStaking_Reward_Stakers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Stakers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Stakers_Order_By>>;
  where?: InputMaybe<Staking_Reward_Stakers_Bool_Exp>;
};


export type Query_RootStaking_Reward_Stakers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSubdivisionsArgs = {
  distinct_on?: InputMaybe<Array<Subdivisions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subdivisions_Order_By>>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};


export type Query_RootSubdivisions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subdivisions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subdivisions_Order_By>>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};


export type Query_RootSubdivisions_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootTask_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sections_Order_By>>;
  where?: InputMaybe<Task_Sections_Bool_Exp>;
};


export type Query_RootTask_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sections_Order_By>>;
  where?: InputMaybe<Task_Sections_Bool_Exp>;
};


export type Query_RootTask_Sections_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTask_TypesArgs = {
  distinct_on?: InputMaybe<Array<Task_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Types_Order_By>>;
  where?: InputMaybe<Task_Types_Bool_Exp>;
};


export type Query_RootTask_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Types_Order_By>>;
  where?: InputMaybe<Task_Types_Bool_Exp>;
};


export type Query_RootTask_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_By_PkArgs = {
  taskType: Task_Types_Enum;
};


export type Query_RootTasks_DoneArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Done_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Done_Order_By>>;
  where?: InputMaybe<Tasks_Done_Bool_Exp>;
};


export type Query_RootTasks_Done_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Done_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Done_Order_By>>;
  where?: InputMaybe<Tasks_Done_Bool_Exp>;
};


export type Query_RootTasks_Done_By_PkArgs = {
  profileId: Scalars['String'];
  taskType: Scalars['String'];
};


export type Query_RootUserRankingArgs = {
  profileId?: InputMaybe<Scalars['String']>;
};


export type Query_RootValidateLinkArgs = {
  link: Scalars['String'];
};


export type Query_RootVerification_TypesArgs = {
  distinct_on?: InputMaybe<Array<Verification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Types_Order_By>>;
  where?: InputMaybe<Verification_Types_Bool_Exp>;
};


export type Query_RootVerification_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Types_Order_By>>;
  where?: InputMaybe<Verification_Types_Bool_Exp>;
};


export type Query_RootVerification_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootVerified_Nft_Project_KindsArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Project_Kinds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Project_Kinds_Order_By>>;
  where?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
};


export type Query_RootVerified_Nft_Project_Kinds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Project_Kinds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Project_Kinds_Order_By>>;
  where?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
};


export type Query_RootVerified_Nft_Project_Kinds_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootVerified_Nft_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};


export type Query_RootVerified_Nft_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};


export type Query_RootVerified_Nft_Projects_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootWalletsArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Query_RootWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Query_RootWallets_By_PkArgs = {
  walletAddress: Scalars['String'];
};


export type Query_RootWallets_ResolvesArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Resolves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Resolves_Order_By>>;
  where?: InputMaybe<Wallets_Resolves_Bool_Exp>;
};


export type Query_RootWallets_Resolves_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Resolves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Resolves_Order_By>>;
  where?: InputMaybe<Wallets_Resolves_Bool_Exp>;
};


export type Query_RootWallets_Resolves_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootYield_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Yield_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yield_Sources_Order_By>>;
  where?: InputMaybe<Yield_Sources_Bool_Exp>;
};


export type Query_RootYield_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yield_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yield_Sources_Order_By>>;
  where?: InputMaybe<Yield_Sources_Bool_Exp>;
};


export type Query_RootYield_Sources_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "recommendation_comments" */
export type Recommendation_Comments = {
  __typename?: 'recommendation_comments';
  /** An array relationship */
  children: Array<Recommendation_Comments>;
  /** An aggregate relationship */
  children_aggregate: Recommendation_Comments_Aggregate;
  /** An object relationship */
  commentAggregates?: Maybe<Comment_Aggregates>;
  /** An array relationship */
  comment_votes: Array<Comment_Votes>;
  /** An aggregate relationship */
  comment_votes_aggregate: Comment_Votes_Aggregate;
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  farcasterCast?: Maybe<Farcaster_Casts>;
  /** An array relationship */
  farcaster_casts: Array<Farcaster_Casts>;
  /** An aggregate relationship */
  farcaster_casts_aggregate: Farcaster_Casts_Aggregate;
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  image_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  lensComment?: Maybe<Lens_Posts>;
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  /** An array relationship */
  lens_reactions: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lens_reactions_aggregate: Lens_Reactions_Aggregate;
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  /** An object relationship */
  parent?: Maybe<Recommendation_Comments>;
  parentId?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile?: Maybe<Profiles>;
  profileId?: Maybe<Scalars['String']>;
  /** An array relationship */
  profileTags: Array<Comment_Profile_Tags>;
  /** An aggregate relationship */
  profileTags_aggregate: Comment_Profile_Tags_Aggregate;
  publishedAt: Scalars['timestamptz'];
  /** An object relationship */
  recommendation: Recommendations;
  recommendationId: Scalars['String'];
  /** An array relationship */
  reported_contents: Array<Reported_Content>;
  /** An aggregate relationship */
  reported_contents_aggregate: Reported_Content_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  userVote?: Maybe<Comment_Votes>;
  userVoteScore?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsChildrenArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsComment_VotesArgs = {
  distinct_on?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Votes_Order_By>>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsComment_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Votes_Order_By>>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsFarcaster_CastsArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Casts_Order_By>>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsFarcaster_Casts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Casts_Order_By>>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsLens_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsLens_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsProfileTagsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Profile_Tags_Order_By>>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsProfileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Profile_Tags_Order_By>>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsReported_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


/** columns and relationships of "recommendation_comments" */
export type Recommendation_CommentsReported_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};

/** aggregated selection of "recommendation_comments" */
export type Recommendation_Comments_Aggregate = {
  __typename?: 'recommendation_comments_aggregate';
  aggregate?: Maybe<Recommendation_Comments_Aggregate_Fields>;
  nodes: Array<Recommendation_Comments>;
};

export type Recommendation_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Recommendation_Comments_Aggregate_Bool_Exp_Count>;
};

export type Recommendation_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recommendation_comments" */
export type Recommendation_Comments_Aggregate_Fields = {
  __typename?: 'recommendation_comments_aggregate_fields';
  avg?: Maybe<Recommendation_Comments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recommendation_Comments_Max_Fields>;
  min?: Maybe<Recommendation_Comments_Min_Fields>;
  stddev?: Maybe<Recommendation_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Recommendation_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recommendation_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Recommendation_Comments_Sum_Fields>;
  var_pop?: Maybe<Recommendation_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Recommendation_Comments_Var_Samp_Fields>;
  variance?: Maybe<Recommendation_Comments_Variance_Fields>;
};


/** aggregate fields of "recommendation_comments" */
export type Recommendation_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recommendation_comments" */
export type Recommendation_Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Recommendation_Comments_Max_Order_By>;
  min?: InputMaybe<Recommendation_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "recommendation_comments" */
export type Recommendation_Comments_Arr_Rel_Insert_Input = {
  data: Array<Recommendation_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Recommendation_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Recommendation_Comments_Avg_Fields = {
  __typename?: 'recommendation_comments_avg_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "recommendation_comments". All fields are combined with a logical 'AND'. */
export type Recommendation_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Recommendation_Comments_Bool_Exp>>;
  _not?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Recommendation_Comments_Bool_Exp>>;
  children?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  children_aggregate?: InputMaybe<Recommendation_Comments_Aggregate_Bool_Exp>;
  commentAggregates?: InputMaybe<Comment_Aggregates_Bool_Exp>;
  comment_votes?: InputMaybe<Comment_Votes_Bool_Exp>;
  comment_votes_aggregate?: InputMaybe<Comment_Votes_Aggregate_Bool_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  farcaster_casts?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  farcaster_casts_aggregate?: InputMaybe<Farcaster_Casts_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  image_id?: InputMaybe<String_Comparison_Exp>;
  lensComment?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp>;
  lens_reactions?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Bool_Exp>;
  medias?: InputMaybe<Medias_Bool_Exp>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Bool_Exp>;
  parent?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  parentId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  profileTags?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
  profileTags_aggregate?: InputMaybe<Comment_Profile_Tags_Aggregate_Bool_Exp>;
  publishedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  recommendation?: InputMaybe<Recommendations_Bool_Exp>;
  recommendationId?: InputMaybe<String_Comparison_Exp>;
  reported_contents?: InputMaybe<Reported_Content_Bool_Exp>;
  reported_contents_aggregate?: InputMaybe<Reported_Content_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userVote?: InputMaybe<Comment_Votes_Bool_Exp>;
  userVoteScore?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recommendation_comments" */
export enum Recommendation_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecommendationCommentsPkey = 'recommendation_comments_pkey'
}

/** input type for inserting data into table "recommendation_comments" */
export type Recommendation_Comments_Insert_Input = {
  children?: InputMaybe<Recommendation_Comments_Arr_Rel_Insert_Input>;
  commentAggregates?: InputMaybe<Comment_Aggregates_Obj_Rel_Insert_Input>;
  comment_votes?: InputMaybe<Comment_Votes_Arr_Rel_Insert_Input>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Obj_Rel_Insert_Input>;
  farcaster_casts?: InputMaybe<Farcaster_Casts_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  image_id?: InputMaybe<Scalars['String']>;
  lensComment?: InputMaybe<Lens_Posts_Obj_Rel_Insert_Input>;
  lens_posts?: InputMaybe<Lens_Posts_Arr_Rel_Insert_Input>;
  lens_reactions?: InputMaybe<Lens_Reactions_Arr_Rel_Insert_Input>;
  medias?: InputMaybe<Medias_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  parentId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  profileTags?: InputMaybe<Comment_Profile_Tags_Arr_Rel_Insert_Input>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  recommendation?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  recommendationId?: InputMaybe<Scalars['String']>;
  reported_contents?: InputMaybe<Reported_Content_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userVote?: InputMaybe<Comment_Votes_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recommendation_Comments_Max_Fields = {
  __typename?: 'recommendation_comments_max_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  recommendationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recommendation_comments" */
export type Recommendation_Comments_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recommendation_Comments_Min_Fields = {
  __typename?: 'recommendation_comments_min_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  recommendationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recommendation_comments" */
export type Recommendation_Comments_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "recommendation_comments" */
export type Recommendation_Comments_Mutation_Response = {
  __typename?: 'recommendation_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recommendation_Comments>;
};

/** input type for inserting object relation for remote table "recommendation_comments" */
export type Recommendation_Comments_Obj_Rel_Insert_Input = {
  data: Recommendation_Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Recommendation_Comments_On_Conflict>;
};

/** on_conflict condition type for table "recommendation_comments" */
export type Recommendation_Comments_On_Conflict = {
  constraint: Recommendation_Comments_Constraint;
  update_columns?: Array<Recommendation_Comments_Update_Column>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "recommendation_comments". */
export type Recommendation_Comments_Order_By = {
  children_aggregate?: InputMaybe<Recommendation_Comments_Aggregate_Order_By>;
  commentAggregates?: InputMaybe<Comment_Aggregates_Order_By>;
  comment_votes_aggregate?: InputMaybe<Comment_Votes_Aggregate_Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Order_By>;
  farcaster_casts_aggregate?: InputMaybe<Farcaster_Casts_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  image_id?: InputMaybe<Order_By>;
  lensComment?: InputMaybe<Lens_Posts_Order_By>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Order_By>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Order_By>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Order_By>;
  parent?: InputMaybe<Recommendation_Comments_Order_By>;
  parentId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  profileTags_aggregate?: InputMaybe<Comment_Profile_Tags_Aggregate_Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Recommendations_Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  reported_contents_aggregate?: InputMaybe<Reported_Content_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userVote?: InputMaybe<Comment_Votes_Order_By>;
  userVoteScore?: InputMaybe<Order_By>;
};

/** primary key columns input for table: recommendation_comments */
export type Recommendation_Comments_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "recommendation_comments" */
export enum Recommendation_Comments_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "recommendation_comments" */
export type Recommendation_Comments_Set_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  image_id?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  recommendationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recommendation_Comments_Stddev_Fields = {
  __typename?: 'recommendation_comments_stddev_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_pop on columns */
export type Recommendation_Comments_Stddev_Pop_Fields = {
  __typename?: 'recommendation_comments_stddev_pop_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_samp on columns */
export type Recommendation_Comments_Stddev_Samp_Fields = {
  __typename?: 'recommendation_comments_stddev_samp_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** Streaming cursor of the table "recommendation_comments" */
export type Recommendation_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recommendation_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recommendation_Comments_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  image_id?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  recommendationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recommendation_Comments_Sum_Fields = {
  __typename?: 'recommendation_comments_sum_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** update columns of table "recommendation_comments" */
export enum Recommendation_Comments_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Recommendation_Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recommendation_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recommendation_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recommendation_Comments_Var_Pop_Fields = {
  __typename?: 'recommendation_comments_var_pop_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** aggregate var_samp on columns */
export type Recommendation_Comments_Var_Samp_Fields = {
  __typename?: 'recommendation_comments_var_samp_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type Recommendation_Comments_Variance_Fields = {
  __typename?: 'recommendation_comments_variance_fields';
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "recommendation_votes" */
export type Recommendation_Votes = {
  __typename?: 'recommendation_votes';
  createdAt: Scalars['timestamptz'];
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  lensReaction?: Maybe<Lens_Reactions>;
  lens_reaction_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  /** An object relationship */
  recommendation: Recommendations;
  recommendationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value: Scalars['Int'];
};

/** aggregated selection of "recommendation_votes" */
export type Recommendation_Votes_Aggregate = {
  __typename?: 'recommendation_votes_aggregate';
  aggregate?: Maybe<Recommendation_Votes_Aggregate_Fields>;
  nodes: Array<Recommendation_Votes>;
};

export type Recommendation_Votes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Recommendation_Votes_Aggregate_Bool_Exp_Count>;
};

export type Recommendation_Votes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Recommendation_Votes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recommendation_votes" */
export type Recommendation_Votes_Aggregate_Fields = {
  __typename?: 'recommendation_votes_aggregate_fields';
  avg?: Maybe<Recommendation_Votes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recommendation_Votes_Max_Fields>;
  min?: Maybe<Recommendation_Votes_Min_Fields>;
  stddev?: Maybe<Recommendation_Votes_Stddev_Fields>;
  stddev_pop?: Maybe<Recommendation_Votes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recommendation_Votes_Stddev_Samp_Fields>;
  sum?: Maybe<Recommendation_Votes_Sum_Fields>;
  var_pop?: Maybe<Recommendation_Votes_Var_Pop_Fields>;
  var_samp?: Maybe<Recommendation_Votes_Var_Samp_Fields>;
  variance?: Maybe<Recommendation_Votes_Variance_Fields>;
};


/** aggregate fields of "recommendation_votes" */
export type Recommendation_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recommendation_votes" */
export type Recommendation_Votes_Aggregate_Order_By = {
  avg?: InputMaybe<Recommendation_Votes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Recommendation_Votes_Max_Order_By>;
  min?: InputMaybe<Recommendation_Votes_Min_Order_By>;
  stddev?: InputMaybe<Recommendation_Votes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Recommendation_Votes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Recommendation_Votes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Recommendation_Votes_Sum_Order_By>;
  var_pop?: InputMaybe<Recommendation_Votes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Recommendation_Votes_Var_Samp_Order_By>;
  variance?: InputMaybe<Recommendation_Votes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recommendation_votes" */
export type Recommendation_Votes_Arr_Rel_Insert_Input = {
  data: Array<Recommendation_Votes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Recommendation_Votes_On_Conflict>;
};

/** aggregate avg on columns */
export type Recommendation_Votes_Avg_Fields = {
  __typename?: 'recommendation_votes_avg_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Avg_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recommendation_votes". All fields are combined with a logical 'AND'. */
export type Recommendation_Votes_Bool_Exp = {
  _and?: InputMaybe<Array<Recommendation_Votes_Bool_Exp>>;
  _not?: InputMaybe<Recommendation_Votes_Bool_Exp>;
  _or?: InputMaybe<Array<Recommendation_Votes_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  farcaster_reaction_id?: InputMaybe<Int_Comparison_Exp>;
  lensReaction?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lens_reaction_id?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  recommendation?: InputMaybe<Recommendations_Bool_Exp>;
  recommendationId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recommendation_votes" */
export enum Recommendation_Votes_Constraint {
  /** unique or primary key constraint on columns "lens_reaction_id" */
  RecommendationVotesLensReactionId = 'recommendation_votes_lens_reaction_id',
  /** unique or primary key constraint on columns "profile_id", "recommendation_id" */
  RecommendationVotesPkey = 'recommendation_votes_pkey'
}

/** input type for incrementing numeric columns in table "recommendation_votes" */
export type Recommendation_Votes_Inc_Input = {
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recommendation_votes" */
export type Recommendation_Votes_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  lensReaction?: InputMaybe<Lens_Reactions_Obj_Rel_Insert_Input>;
  lens_reaction_id?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  recommendation?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  recommendationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recommendation_Votes_Max_Fields = {
  __typename?: 'recommendation_votes_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  lens_reaction_id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  recommendationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  farcaster_reaction_id?: InputMaybe<Order_By>;
  lens_reaction_id?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recommendation_Votes_Min_Fields = {
  __typename?: 'recommendation_votes_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  lens_reaction_id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  recommendationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  farcaster_reaction_id?: InputMaybe<Order_By>;
  lens_reaction_id?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "recommendation_votes" */
export type Recommendation_Votes_Mutation_Response = {
  __typename?: 'recommendation_votes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recommendation_Votes>;
};

/** input type for inserting object relation for remote table "recommendation_votes" */
export type Recommendation_Votes_Obj_Rel_Insert_Input = {
  data: Recommendation_Votes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Recommendation_Votes_On_Conflict>;
};

/** on_conflict condition type for table "recommendation_votes" */
export type Recommendation_Votes_On_Conflict = {
  constraint: Recommendation_Votes_Constraint;
  update_columns?: Array<Recommendation_Votes_Update_Column>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "recommendation_votes". */
export type Recommendation_Votes_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  farcaster_reaction_id?: InputMaybe<Order_By>;
  lensReaction?: InputMaybe<Lens_Reactions_Order_By>;
  lens_reaction_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Recommendations_Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: recommendation_votes */
export type Recommendation_Votes_Pk_Columns_Input = {
  profileId: Scalars['String'];
  recommendationId: Scalars['String'];
};

/** select columns of table "recommendation_votes" */
export enum Recommendation_Votes_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FarcasterReactionId = 'farcaster_reaction_id',
  /** column name */
  LensReactionId = 'lens_reaction_id',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "recommendation_votes" */
export type Recommendation_Votes_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  lens_reaction_id?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  recommendationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recommendation_Votes_Stddev_Fields = {
  __typename?: 'recommendation_votes_stddev_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Stddev_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recommendation_Votes_Stddev_Pop_Fields = {
  __typename?: 'recommendation_votes_stddev_pop_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Stddev_Pop_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recommendation_Votes_Stddev_Samp_Fields = {
  __typename?: 'recommendation_votes_stddev_samp_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Stddev_Samp_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recommendation_votes" */
export type Recommendation_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recommendation_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recommendation_Votes_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  farcaster_reaction_id?: InputMaybe<Scalars['Int']>;
  lens_reaction_id?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  recommendationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recommendation_Votes_Sum_Fields = {
  __typename?: 'recommendation_votes_sum_fields';
  farcaster_reaction_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Sum_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "recommendation_votes" */
export enum Recommendation_Votes_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FarcasterReactionId = 'farcaster_reaction_id',
  /** column name */
  LensReactionId = 'lens_reaction_id',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type Recommendation_Votes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recommendation_Votes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recommendation_Votes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recommendation_Votes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recommendation_Votes_Var_Pop_Fields = {
  __typename?: 'recommendation_votes_var_pop_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Var_Pop_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recommendation_Votes_Var_Samp_Fields = {
  __typename?: 'recommendation_votes_var_samp_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Var_Samp_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recommendation_Votes_Variance_Fields = {
  __typename?: 'recommendation_votes_variance_fields';
  farcaster_reaction_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recommendation_votes" */
export type Recommendation_Votes_Variance_Order_By = {
  farcaster_reaction_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "recommendations" */
export type Recommendations = {
  __typename?: 'recommendations';
  affiliateDisplayLink: Scalars['String'];
  affiliateLink: Scalars['String'];
  /** An array relationship */
  comments: Array<Recommendation_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Recommendation_Comments_Aggregate;
  /** An object relationship */
  community?: Maybe<Communities>;
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  dailyStakings: Array<Daily_Staking>;
  /** An aggregate relationship */
  dailyStakings_aggregate: Daily_Staking_Aggregate;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  discountCode: Scalars['String'];
  /** An object relationship */
  farcasterCast?: Maybe<Farcaster_Casts>;
  /** An object relationship */
  farcasterReaction?: Maybe<Farcaster_Reactions>;
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  isFarcasterRecast?: Maybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  /** An object relationship */
  item?: Maybe<Items>;
  itemId?: Maybe<Scalars['String']>;
  language?: Maybe<Language_Isocodes_Enum>;
  /** An object relationship */
  language_isocode?: Maybe<Language_Isocodes>;
  /** An object relationship */
  lensPost?: Maybe<Lens_Posts>;
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  /** An array relationship */
  lens_reactions: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lens_reactions_aggregate: Lens_Reactions_Aggregate;
  /** An array relationship */
  links: Array<Links>;
  /** An aggregate relationship */
  links_aggregate: Links_Aggregate;
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  /** An array relationship */
  mirrors: Array<Recommendations>;
  /** An aggregate relationship */
  mirrors_aggregate: Recommendations_Aggregate;
  pinnedOtherAt?: Maybe<Scalars['timestamptz']>;
  pinnedSocialAt?: Maybe<Scalars['timestamptz']>;
  pointCost: Scalars['Int'];
  /** An object relationship */
  postAggregates?: Maybe<Post_Aggregates>;
  postIdPointed?: Maybe<Scalars['String']>;
  /** An object relationship */
  postPointed?: Maybe<Recommendations>;
  /** An array relationship */
  post_reactions: Array<Post_Reactions>;
  /** An aggregate relationship */
  post_reactions_aggregate: Post_Reactions_Aggregate;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  /** An array relationship */
  profileTags: Array<Post_Profile_Tags>;
  /** An aggregate relationship */
  profileTags_aggregate: Post_Profile_Tags_Aggregate;
  publishedAt: Scalars['timestamptz'];
  /** An array relationship */
  reactionCounts: Array<Post_Reaction_Counts>;
  /** An aggregate relationship */
  reactionCounts_aggregate: Post_Reaction_Counts_Aggregate;
  /** An array relationship */
  reported_contents: Array<Reported_Content>;
  /** An aggregate relationship */
  reported_contents_aggregate: Reported_Content_Aggregate;
  status: Async_Status_Enum;
  updatedAt: Scalars['timestamptz'];
  userHasStaked?: Maybe<Scalars['Boolean']>;
  userReaction?: Maybe<Scalars['String']>;
  /** An object relationship */
  userVote?: Maybe<Recommendation_Votes>;
  userVoteScore?: Maybe<Scalars['Int']>;
  /** An array relationship */
  votes: Array<Recommendation_Votes>;
  /** An aggregate relationship */
  votes_aggregate: Recommendation_Votes_Aggregate;
};


/** columns and relationships of "recommendations" */
export type RecommendationsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsDailyStakingsArgs = {
  distinct_on?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Staking_Order_By>>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsDailyStakings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Staking_Order_By>>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsLens_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsLens_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsLinksArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsMirrorsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsMirrors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsPost_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsPost_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsProfileTagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Profile_Tags_Order_By>>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsProfileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Profile_Tags_Order_By>>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsReactionCountsArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Counts_Order_By>>;
  where?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsReactionCounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Counts_Order_By>>;
  where?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsReported_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsReported_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsVotesArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};


/** columns and relationships of "recommendations" */
export type RecommendationsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};

/** aggregated selection of "recommendations" */
export type Recommendations_Aggregate = {
  __typename?: 'recommendations_aggregate';
  aggregate?: Maybe<Recommendations_Aggregate_Fields>;
  nodes: Array<Recommendations>;
};

export type Recommendations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Recommendations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Recommendations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Recommendations_Aggregate_Bool_Exp_Count>;
};

export type Recommendations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Recommendations_Select_Column_Recommendations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Recommendations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Recommendations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Recommendations_Select_Column_Recommendations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Recommendations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Recommendations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Recommendations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Recommendations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recommendations" */
export type Recommendations_Aggregate_Fields = {
  __typename?: 'recommendations_aggregate_fields';
  avg?: Maybe<Recommendations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recommendations_Max_Fields>;
  min?: Maybe<Recommendations_Min_Fields>;
  stddev?: Maybe<Recommendations_Stddev_Fields>;
  stddev_pop?: Maybe<Recommendations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recommendations_Stddev_Samp_Fields>;
  sum?: Maybe<Recommendations_Sum_Fields>;
  var_pop?: Maybe<Recommendations_Var_Pop_Fields>;
  var_samp?: Maybe<Recommendations_Var_Samp_Fields>;
  variance?: Maybe<Recommendations_Variance_Fields>;
};


/** aggregate fields of "recommendations" */
export type Recommendations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recommendations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recommendations" */
export type Recommendations_Aggregate_Order_By = {
  avg?: InputMaybe<Recommendations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Recommendations_Max_Order_By>;
  min?: InputMaybe<Recommendations_Min_Order_By>;
  stddev?: InputMaybe<Recommendations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Recommendations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Recommendations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Recommendations_Sum_Order_By>;
  var_pop?: InputMaybe<Recommendations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Recommendations_Var_Samp_Order_By>;
  variance?: InputMaybe<Recommendations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recommendations" */
export type Recommendations_Arr_Rel_Insert_Input = {
  data: Array<Recommendations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Recommendations_On_Conflict>;
};

/** aggregate avg on columns */
export type Recommendations_Avg_Fields = {
  __typename?: 'recommendations_avg_fields';
  pointCost?: Maybe<Scalars['Float']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by avg() on columns of table "recommendations" */
export type Recommendations_Avg_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recommendations". All fields are combined with a logical 'AND'. */
export type Recommendations_Bool_Exp = {
  _and?: InputMaybe<Array<Recommendations_Bool_Exp>>;
  _not?: InputMaybe<Recommendations_Bool_Exp>;
  _or?: InputMaybe<Array<Recommendations_Bool_Exp>>;
  affiliateDisplayLink?: InputMaybe<String_Comparison_Exp>;
  affiliateLink?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Recommendation_Comments_Aggregate_Bool_Exp>;
  community?: InputMaybe<Communities_Bool_Exp>;
  communityId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dailyStakings?: InputMaybe<Daily_Staking_Bool_Exp>;
  dailyStakings_aggregate?: InputMaybe<Daily_Staking_Aggregate_Bool_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discountCode?: InputMaybe<String_Comparison_Exp>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Bool_Exp>;
  farcasterReaction?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  isFarcasterRecast?: InputMaybe<Boolean_Comparison_Exp>;
  isPrivate?: InputMaybe<Boolean_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  itemId?: InputMaybe<String_Comparison_Exp>;
  language?: InputMaybe<Language_Isocodes_Enum_Comparison_Exp>;
  language_isocode?: InputMaybe<Language_Isocodes_Bool_Exp>;
  lensPost?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts?: InputMaybe<Lens_Posts_Bool_Exp>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Bool_Exp>;
  lens_reactions?: InputMaybe<Lens_Reactions_Bool_Exp>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Bool_Exp>;
  links?: InputMaybe<Links_Bool_Exp>;
  links_aggregate?: InputMaybe<Links_Aggregate_Bool_Exp>;
  medias?: InputMaybe<Medias_Bool_Exp>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Bool_Exp>;
  mirrors?: InputMaybe<Recommendations_Bool_Exp>;
  mirrors_aggregate?: InputMaybe<Recommendations_Aggregate_Bool_Exp>;
  pinnedOtherAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  pinnedSocialAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  pointCost?: InputMaybe<Int_Comparison_Exp>;
  postAggregates?: InputMaybe<Post_Aggregates_Bool_Exp>;
  postIdPointed?: InputMaybe<String_Comparison_Exp>;
  postPointed?: InputMaybe<Recommendations_Bool_Exp>;
  post_reactions?: InputMaybe<Post_Reactions_Bool_Exp>;
  post_reactions_aggregate?: InputMaybe<Post_Reactions_Aggregate_Bool_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  profileTags?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
  profileTags_aggregate?: InputMaybe<Post_Profile_Tags_Aggregate_Bool_Exp>;
  publishedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  reactionCounts?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
  reactionCounts_aggregate?: InputMaybe<Post_Reaction_Counts_Aggregate_Bool_Exp>;
  reported_contents?: InputMaybe<Reported_Content_Bool_Exp>;
  reported_contents_aggregate?: InputMaybe<Reported_Content_Aggregate_Bool_Exp>;
  status?: InputMaybe<Async_Status_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userHasStaked?: InputMaybe<Boolean_Comparison_Exp>;
  userReaction?: InputMaybe<String_Comparison_Exp>;
  userVote?: InputMaybe<Recommendation_Votes_Bool_Exp>;
  userVoteScore?: InputMaybe<Int_Comparison_Exp>;
  votes?: InputMaybe<Recommendation_Votes_Bool_Exp>;
  votes_aggregate?: InputMaybe<Recommendation_Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "recommendations" */
export enum Recommendations_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecommendationsPkey = 'recommendations_pkey'
}

/** input type for incrementing numeric columns in table "recommendations" */
export type Recommendations_Inc_Input = {
  pointCost?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recommendations" */
export type Recommendations_Insert_Input = {
  affiliateDisplayLink?: InputMaybe<Scalars['String']>;
  affiliateLink?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Recommendation_Comments_Arr_Rel_Insert_Input>;
  community?: InputMaybe<Communities_Obj_Rel_Insert_Input>;
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dailyStakings?: InputMaybe<Daily_Staking_Arr_Rel_Insert_Input>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  discountCode?: InputMaybe<Scalars['String']>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Obj_Rel_Insert_Input>;
  farcasterReaction?: InputMaybe<Farcaster_Reactions_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  itemId?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language_Isocodes_Enum>;
  language_isocode?: InputMaybe<Language_Isocodes_Obj_Rel_Insert_Input>;
  lensPost?: InputMaybe<Lens_Posts_Obj_Rel_Insert_Input>;
  lens_posts?: InputMaybe<Lens_Posts_Arr_Rel_Insert_Input>;
  lens_reactions?: InputMaybe<Lens_Reactions_Arr_Rel_Insert_Input>;
  links?: InputMaybe<Links_Arr_Rel_Insert_Input>;
  medias?: InputMaybe<Medias_Arr_Rel_Insert_Input>;
  mirrors?: InputMaybe<Recommendations_Arr_Rel_Insert_Input>;
  pinnedOtherAt?: InputMaybe<Scalars['timestamptz']>;
  pinnedSocialAt?: InputMaybe<Scalars['timestamptz']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  postAggregates?: InputMaybe<Post_Aggregates_Obj_Rel_Insert_Input>;
  postIdPointed?: InputMaybe<Scalars['String']>;
  postPointed?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  post_reactions?: InputMaybe<Post_Reactions_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  profileTags?: InputMaybe<Post_Profile_Tags_Arr_Rel_Insert_Input>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  reactionCounts?: InputMaybe<Post_Reaction_Counts_Arr_Rel_Insert_Input>;
  reported_contents?: InputMaybe<Reported_Content_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Async_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userVote?: InputMaybe<Recommendation_Votes_Obj_Rel_Insert_Input>;
  votes?: InputMaybe<Recommendation_Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recommendations_Max_Fields = {
  __typename?: 'recommendations_max_fields';
  affiliateDisplayLink?: Maybe<Scalars['String']>;
  affiliateLink?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  pinnedOtherAt?: Maybe<Scalars['timestamptz']>;
  pinnedSocialAt?: Maybe<Scalars['timestamptz']>;
  pointCost?: Maybe<Scalars['Int']>;
  postIdPointed?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userReaction?: Maybe<Scalars['String']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recommendations" */
export type Recommendations_Max_Order_By = {
  affiliateDisplayLink?: InputMaybe<Order_By>;
  affiliateLink?: InputMaybe<Order_By>;
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discountCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  pinnedOtherAt?: InputMaybe<Order_By>;
  pinnedSocialAt?: InputMaybe<Order_By>;
  pointCost?: InputMaybe<Order_By>;
  postIdPointed?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recommendations_Min_Fields = {
  __typename?: 'recommendations_min_fields';
  affiliateDisplayLink?: Maybe<Scalars['String']>;
  affiliateLink?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  pinnedOtherAt?: Maybe<Scalars['timestamptz']>;
  pinnedSocialAt?: Maybe<Scalars['timestamptz']>;
  pointCost?: Maybe<Scalars['Int']>;
  postIdPointed?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userReaction?: Maybe<Scalars['String']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recommendations" */
export type Recommendations_Min_Order_By = {
  affiliateDisplayLink?: InputMaybe<Order_By>;
  affiliateLink?: InputMaybe<Order_By>;
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discountCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  pinnedOtherAt?: InputMaybe<Order_By>;
  pinnedSocialAt?: InputMaybe<Order_By>;
  pointCost?: InputMaybe<Order_By>;
  postIdPointed?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "recommendations" */
export type Recommendations_Mutation_Response = {
  __typename?: 'recommendations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recommendations>;
};

/** input type for inserting object relation for remote table "recommendations" */
export type Recommendations_Obj_Rel_Insert_Input = {
  data: Recommendations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Recommendations_On_Conflict>;
};

/** on_conflict condition type for table "recommendations" */
export type Recommendations_On_Conflict = {
  constraint: Recommendations_Constraint;
  update_columns?: Array<Recommendations_Update_Column>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};

/** Ordering options when selecting data from "recommendations". */
export type Recommendations_Order_By = {
  affiliateDisplayLink?: InputMaybe<Order_By>;
  affiliateLink?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Recommendation_Comments_Aggregate_Order_By>;
  community?: InputMaybe<Communities_Order_By>;
  communityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dailyStakings_aggregate?: InputMaybe<Daily_Staking_Aggregate_Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discountCode?: InputMaybe<Order_By>;
  farcasterCast?: InputMaybe<Farcaster_Casts_Order_By>;
  farcasterReaction?: InputMaybe<Farcaster_Reactions_Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  isFarcasterRecast?: InputMaybe<Order_By>;
  isPrivate?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  itemId?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  language_isocode?: InputMaybe<Language_Isocodes_Order_By>;
  lensPost?: InputMaybe<Lens_Posts_Order_By>;
  lens_posts_aggregate?: InputMaybe<Lens_Posts_Aggregate_Order_By>;
  lens_reactions_aggregate?: InputMaybe<Lens_Reactions_Aggregate_Order_By>;
  links_aggregate?: InputMaybe<Links_Aggregate_Order_By>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Order_By>;
  mirrors_aggregate?: InputMaybe<Recommendations_Aggregate_Order_By>;
  pinnedOtherAt?: InputMaybe<Order_By>;
  pinnedSocialAt?: InputMaybe<Order_By>;
  pointCost?: InputMaybe<Order_By>;
  postAggregates?: InputMaybe<Post_Aggregates_Order_By>;
  postIdPointed?: InputMaybe<Order_By>;
  postPointed?: InputMaybe<Recommendations_Order_By>;
  post_reactions_aggregate?: InputMaybe<Post_Reactions_Aggregate_Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  profileTags_aggregate?: InputMaybe<Post_Profile_Tags_Aggregate_Order_By>;
  publishedAt?: InputMaybe<Order_By>;
  reactionCounts_aggregate?: InputMaybe<Post_Reaction_Counts_Aggregate_Order_By>;
  reported_contents_aggregate?: InputMaybe<Reported_Content_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userHasStaked?: InputMaybe<Order_By>;
  userReaction?: InputMaybe<Order_By>;
  userVote?: InputMaybe<Recommendation_Votes_Order_By>;
  userVoteScore?: InputMaybe<Order_By>;
  votes_aggregate?: InputMaybe<Recommendation_Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: recommendations */
export type Recommendations_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "recommendations" */
export enum Recommendations_Select_Column {
  /** column name */
  AffiliateDisplayLink = 'affiliateDisplayLink',
  /** column name */
  AffiliateLink = 'affiliateLink',
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscountCode = 'discountCode',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsPrivate = 'isPrivate',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  Language = 'language',
  /** column name */
  PinnedOtherAt = 'pinnedOtherAt',
  /** column name */
  PinnedSocialAt = 'pinnedSocialAt',
  /** column name */
  PointCost = 'pointCost',
  /** column name */
  PostIdPointed = 'postIdPointed',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "recommendations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recommendations" */
export enum Recommendations_Select_Column_Recommendations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrivate = 'isPrivate'
}

/** select "recommendations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recommendations" */
export enum Recommendations_Select_Column_Recommendations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrivate = 'isPrivate'
}

/** input type for updating data in table "recommendations" */
export type Recommendations_Set_Input = {
  affiliateDisplayLink?: InputMaybe<Scalars['String']>;
  affiliateLink?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  discountCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language_Isocodes_Enum>;
  pinnedOtherAt?: InputMaybe<Scalars['timestamptz']>;
  pinnedSocialAt?: InputMaybe<Scalars['timestamptz']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  postIdPointed?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Async_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recommendations_Stddev_Fields = {
  __typename?: 'recommendations_stddev_fields';
  pointCost?: Maybe<Scalars['Float']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "recommendations" */
export type Recommendations_Stddev_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recommendations_Stddev_Pop_Fields = {
  __typename?: 'recommendations_stddev_pop_fields';
  pointCost?: Maybe<Scalars['Float']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by stddev_pop() on columns of table "recommendations" */
export type Recommendations_Stddev_Pop_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recommendations_Stddev_Samp_Fields = {
  __typename?: 'recommendations_stddev_samp_fields';
  pointCost?: Maybe<Scalars['Float']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by stddev_samp() on columns of table "recommendations" */
export type Recommendations_Stddev_Samp_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recommendations" */
export type Recommendations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recommendations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recommendations_Stream_Cursor_Value_Input = {
  affiliateDisplayLink?: InputMaybe<Scalars['String']>;
  affiliateLink?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  discountCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language_Isocodes_Enum>;
  pinnedOtherAt?: InputMaybe<Scalars['timestamptz']>;
  pinnedSocialAt?: InputMaybe<Scalars['timestamptz']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  postIdPointed?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Async_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recommendations_Sum_Fields = {
  __typename?: 'recommendations_sum_fields';
  pointCost?: Maybe<Scalars['Int']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recommendations" */
export type Recommendations_Sum_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** update columns of table "recommendations" */
export enum Recommendations_Update_Column {
  /** column name */
  AffiliateDisplayLink = 'affiliateDisplayLink',
  /** column name */
  AffiliateLink = 'affiliateLink',
  /** column name */
  CommunityId = 'communityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscountCode = 'discountCode',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsPrivate = 'isPrivate',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  Language = 'language',
  /** column name */
  PinnedOtherAt = 'pinnedOtherAt',
  /** column name */
  PinnedSocialAt = 'pinnedSocialAt',
  /** column name */
  PointCost = 'pointCost',
  /** column name */
  PostIdPointed = 'postIdPointed',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Recommendations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recommendations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recommendations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recommendations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recommendations_Var_Pop_Fields = {
  __typename?: 'recommendations_var_pop_fields';
  pointCost?: Maybe<Scalars['Float']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by var_pop() on columns of table "recommendations" */
export type Recommendations_Var_Pop_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recommendations_Var_Samp_Fields = {
  __typename?: 'recommendations_var_samp_fields';
  pointCost?: Maybe<Scalars['Float']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by var_samp() on columns of table "recommendations" */
export type Recommendations_Var_Samp_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recommendations_Variance_Fields = {
  __typename?: 'recommendations_variance_fields';
  pointCost?: Maybe<Scalars['Float']>;
  userVoteScore?: Maybe<Scalars['Int']>;
};

/** order by variance() on columns of table "recommendations" */
export type Recommendations_Variance_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

export type RefetchMissingFarcasterNamesOutput = {
  __typename?: 'refetchMissingFarcasterNamesOutput';
  fixedCount: Scalars['Int'];
  missingCount: Scalars['Int'];
};

/** columns and relationships of "reported_content" */
export type Reported_Content = {
  __typename?: 'reported_content';
  /** An object relationship */
  comment?: Maybe<Recommendation_Comments>;
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  handledAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  message: Scalars['String'];
  /** An object relationship */
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  /** An object relationship */
  reportedProfile?: Maybe<Profiles>;
  reportedProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "reported_content" */
export type Reported_Content_Aggregate = {
  __typename?: 'reported_content_aggregate';
  aggregate?: Maybe<Reported_Content_Aggregate_Fields>;
  nodes: Array<Reported_Content>;
};

export type Reported_Content_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reported_Content_Aggregate_Bool_Exp_Count>;
};

export type Reported_Content_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reported_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reported_Content_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reported_content" */
export type Reported_Content_Aggregate_Fields = {
  __typename?: 'reported_content_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reported_Content_Max_Fields>;
  min?: Maybe<Reported_Content_Min_Fields>;
};


/** aggregate fields of "reported_content" */
export type Reported_Content_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reported_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reported_content" */
export type Reported_Content_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reported_Content_Max_Order_By>;
  min?: InputMaybe<Reported_Content_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reported_content" */
export type Reported_Content_Arr_Rel_Insert_Input = {
  data: Array<Reported_Content_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reported_Content_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reported_content". All fields are combined with a logical 'AND'. */
export type Reported_Content_Bool_Exp = {
  _and?: InputMaybe<Array<Reported_Content_Bool_Exp>>;
  _not?: InputMaybe<Reported_Content_Bool_Exp>;
  _or?: InputMaybe<Array<Reported_Content_Bool_Exp>>;
  comment?: InputMaybe<Recommendation_Comments_Bool_Exp>;
  commentId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  handledAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  reportedProfile?: InputMaybe<Profiles_Bool_Exp>;
  reportedProfileId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reported_content" */
export enum Reported_Content_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportedContentPkey = 'reported_content_pkey'
}

/** input type for inserting data into table "reported_content" */
export type Reported_Content_Insert_Input = {
  comment?: InputMaybe<Recommendation_Comments_Obj_Rel_Insert_Input>;
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  handledAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  reportedProfile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  reportedProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reported_Content_Max_Fields = {
  __typename?: 'reported_content_max_fields';
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  handledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  reportedProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reported_content" */
export type Reported_Content_Max_Order_By = {
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  handledAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  reportedProfileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reported_Content_Min_Fields = {
  __typename?: 'reported_content_min_fields';
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  handledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  reportedProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reported_content" */
export type Reported_Content_Min_Order_By = {
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  handledAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  reportedProfileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reported_content" */
export type Reported_Content_Mutation_Response = {
  __typename?: 'reported_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reported_Content>;
};

/** on_conflict condition type for table "reported_content" */
export type Reported_Content_On_Conflict = {
  constraint: Reported_Content_Constraint;
  update_columns?: Array<Reported_Content_Update_Column>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "reported_content". */
export type Reported_Content_Order_By = {
  comment?: InputMaybe<Recommendation_Comments_Order_By>;
  commentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  handledAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  reportedProfile?: InputMaybe<Profiles_Order_By>;
  reportedProfileId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reported_content */
export type Reported_Content_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "reported_content" */
export enum Reported_Content_Select_Column {
  /** column name */
  CommentId = 'commentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HandledAt = 'handledAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  ReportedProfileId = 'reportedProfileId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "reported_content" */
export type Reported_Content_Set_Input = {
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  handledAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  reportedProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "reported_content" */
export type Reported_Content_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reported_Content_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reported_Content_Stream_Cursor_Value_Input = {
  commentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  handledAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  reportedProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "reported_content" */
export enum Reported_Content_Update_Column {
  /** column name */
  CommentId = 'commentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HandledAt = 'handledAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  ReportedProfileId = 'reportedProfileId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Reported_Content_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reported_Content_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reported_Content_Bool_Exp;
};

export type RewardRedeemResult = {
  __typename?: 'rewardRedeemResult';
  status: RewardRedeemStatus;
};

export enum RewardRedeemStatus {
  Fail = 'fail',
  Ok = 'ok'
}

/** columns and relationships of "reward_brands" */
export type Reward_Brands = {
  __typename?: 'reward_brands';
  bgColor: Scalars['String'];
  color: Scalars['String'];
  /** An object relationship */
  coverImage?: Maybe<Images>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  descriptionText: Scalars['String'];
  descriptionTitle: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  link: Scalars['String'];
  /** An object relationship */
  logoImage?: Maybe<Images>;
  logoImageId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  rewardTypes: Array<Reward_Types>;
  /** An aggregate relationship */
  rewardTypes_aggregate: Reward_Types_Aggregate;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "reward_brands" */
export type Reward_BrandsRewardTypesArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


/** columns and relationships of "reward_brands" */
export type Reward_BrandsRewardTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};

/** aggregated selection of "reward_brands" */
export type Reward_Brands_Aggregate = {
  __typename?: 'reward_brands_aggregate';
  aggregate?: Maybe<Reward_Brands_Aggregate_Fields>;
  nodes: Array<Reward_Brands>;
};

export type Reward_Brands_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Reward_Brands_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Reward_Brands_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Reward_Brands_Aggregate_Bool_Exp_Count>;
};

export type Reward_Brands_Aggregate_Bool_Exp_Bool_And = {
  arguments: Reward_Brands_Select_Column_Reward_Brands_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reward_Brands_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reward_Brands_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Reward_Brands_Select_Column_Reward_Brands_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reward_Brands_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reward_Brands_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reward_Brands_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reward_brands" */
export type Reward_Brands_Aggregate_Fields = {
  __typename?: 'reward_brands_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reward_Brands_Max_Fields>;
  min?: Maybe<Reward_Brands_Min_Fields>;
};


/** aggregate fields of "reward_brands" */
export type Reward_Brands_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reward_brands" */
export type Reward_Brands_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reward_Brands_Max_Order_By>;
  min?: InputMaybe<Reward_Brands_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reward_brands" */
export type Reward_Brands_Arr_Rel_Insert_Input = {
  data: Array<Reward_Brands_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reward_Brands_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reward_brands". All fields are combined with a logical 'AND'. */
export type Reward_Brands_Bool_Exp = {
  _and?: InputMaybe<Array<Reward_Brands_Bool_Exp>>;
  _not?: InputMaybe<Reward_Brands_Bool_Exp>;
  _or?: InputMaybe<Array<Reward_Brands_Bool_Exp>>;
  bgColor?: InputMaybe<String_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  coverImage?: InputMaybe<Images_Bool_Exp>;
  coverImageId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  descriptionText?: InputMaybe<String_Comparison_Exp>;
  descriptionTitle?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isHidden?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  logoImage?: InputMaybe<Images_Bool_Exp>;
  logoImageId?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rewardTypes?: InputMaybe<Reward_Types_Bool_Exp>;
  rewardTypes_aggregate?: InputMaybe<Reward_Types_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reward_brands" */
export enum Reward_Brands_Constraint {
  /** unique or primary key constraint on columns "id" */
  RewardBrandsPkey = 'reward_brands_pkey'
}

/** input type for inserting data into table "reward_brands" */
export type Reward_Brands_Insert_Input = {
  bgColor?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  descriptionText?: InputMaybe<Scalars['String']>;
  descriptionTitle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  logoImage?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  logoImageId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rewardTypes?: InputMaybe<Reward_Types_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reward_Brands_Max_Fields = {
  __typename?: 'reward_brands_max_fields';
  bgColor?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionText?: Maybe<Scalars['String']>;
  descriptionTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  logoImageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reward_brands" */
export type Reward_Brands_Max_Order_By = {
  bgColor?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  descriptionText?: InputMaybe<Order_By>;
  descriptionTitle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  logoImageId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reward_Brands_Min_Fields = {
  __typename?: 'reward_brands_min_fields';
  bgColor?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionText?: Maybe<Scalars['String']>;
  descriptionTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  logoImageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reward_brands" */
export type Reward_Brands_Min_Order_By = {
  bgColor?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  descriptionText?: InputMaybe<Order_By>;
  descriptionTitle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  logoImageId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reward_brands" */
export type Reward_Brands_Mutation_Response = {
  __typename?: 'reward_brands_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reward_Brands>;
};

/** input type for inserting object relation for remote table "reward_brands" */
export type Reward_Brands_Obj_Rel_Insert_Input = {
  data: Reward_Brands_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reward_Brands_On_Conflict>;
};

/** on_conflict condition type for table "reward_brands" */
export type Reward_Brands_On_Conflict = {
  constraint: Reward_Brands_Constraint;
  update_columns?: Array<Reward_Brands_Update_Column>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};

/** Ordering options when selecting data from "reward_brands". */
export type Reward_Brands_Order_By = {
  bgColor?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  coverImage?: InputMaybe<Images_Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  descriptionText?: InputMaybe<Order_By>;
  descriptionTitle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isHidden?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  logoImage?: InputMaybe<Images_Order_By>;
  logoImageId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rewardTypes_aggregate?: InputMaybe<Reward_Types_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reward_brands */
export type Reward_Brands_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "reward_brands" */
export enum Reward_Brands_Select_Column {
  /** column name */
  BgColor = 'bgColor',
  /** column name */
  Color = 'color',
  /** column name */
  CoverImageId = 'coverImageId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionText = 'descriptionText',
  /** column name */
  DescriptionTitle = 'descriptionTitle',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  Link = 'link',
  /** column name */
  LogoImageId = 'logoImageId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "reward_brands_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reward_brands" */
export enum Reward_Brands_Select_Column_Reward_Brands_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsHidden = 'isHidden'
}

/** select "reward_brands_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reward_brands" */
export enum Reward_Brands_Select_Column_Reward_Brands_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsHidden = 'isHidden'
}

/** input type for updating data in table "reward_brands" */
export type Reward_Brands_Set_Input = {
  bgColor?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  descriptionText?: InputMaybe<Scalars['String']>;
  descriptionTitle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  logoImageId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "reward_brands" */
export type Reward_Brands_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reward_Brands_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reward_Brands_Stream_Cursor_Value_Input = {
  bgColor?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  descriptionText?: InputMaybe<Scalars['String']>;
  descriptionTitle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  logoImageId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "reward_brands" */
export enum Reward_Brands_Update_Column {
  /** column name */
  BgColor = 'bgColor',
  /** column name */
  Color = 'color',
  /** column name */
  CoverImageId = 'coverImageId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionText = 'descriptionText',
  /** column name */
  DescriptionTitle = 'descriptionTitle',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  Link = 'link',
  /** column name */
  LogoImageId = 'logoImageId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Reward_Brands_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reward_Brands_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reward_Brands_Bool_Exp;
};

/** columns and relationships of "reward_claims" */
export type Reward_Claims = {
  __typename?: 'reward_claims';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['String']>;
  claimedAt?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  rewardType: Reward_Types;
  rewardTypeId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "reward_claims" */
export type Reward_Claims_Aggregate = {
  __typename?: 'reward_claims_aggregate';
  aggregate?: Maybe<Reward_Claims_Aggregate_Fields>;
  nodes: Array<Reward_Claims>;
};

export type Reward_Claims_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reward_Claims_Aggregate_Bool_Exp_Count>;
};

export type Reward_Claims_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reward_Claims_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reward_claims" */
export type Reward_Claims_Aggregate_Fields = {
  __typename?: 'reward_claims_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reward_Claims_Max_Fields>;
  min?: Maybe<Reward_Claims_Min_Fields>;
};


/** aggregate fields of "reward_claims" */
export type Reward_Claims_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reward_claims" */
export type Reward_Claims_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reward_Claims_Max_Order_By>;
  min?: InputMaybe<Reward_Claims_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reward_claims" */
export type Reward_Claims_Arr_Rel_Insert_Input = {
  data: Array<Reward_Claims_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reward_Claims_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reward_claims". All fields are combined with a logical 'AND'. */
export type Reward_Claims_Bool_Exp = {
  _and?: InputMaybe<Array<Reward_Claims_Bool_Exp>>;
  _not?: InputMaybe<Reward_Claims_Bool_Exp>;
  _or?: InputMaybe<Array<Reward_Claims_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  claimedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  rewardType?: InputMaybe<Reward_Types_Bool_Exp>;
  rewardTypeId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reward_claims" */
export enum Reward_Claims_Constraint {
  /** unique or primary key constraint on columns "id" */
  RewardClaimsPkey = 'reward_claims_pkey'
}

/** input type for inserting data into table "reward_claims" */
export type Reward_Claims_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']>;
  claimedAt?: InputMaybe<Scalars['timestamptz']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  rewardType?: InputMaybe<Reward_Types_Obj_Rel_Insert_Input>;
  rewardTypeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reward_Claims_Max_Fields = {
  __typename?: 'reward_claims_max_fields';
  accountId?: Maybe<Scalars['String']>;
  claimedAt?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rewardTypeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reward_claims" */
export type Reward_Claims_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  claimedAt?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rewardTypeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reward_Claims_Min_Fields = {
  __typename?: 'reward_claims_min_fields';
  accountId?: Maybe<Scalars['String']>;
  claimedAt?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rewardTypeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reward_claims" */
export type Reward_Claims_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  claimedAt?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rewardTypeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reward_claims" */
export type Reward_Claims_Mutation_Response = {
  __typename?: 'reward_claims_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reward_Claims>;
};

/** on_conflict condition type for table "reward_claims" */
export type Reward_Claims_On_Conflict = {
  constraint: Reward_Claims_Constraint;
  update_columns?: Array<Reward_Claims_Update_Column>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};

/** Ordering options when selecting data from "reward_claims". */
export type Reward_Claims_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  claimedAt?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rewardType?: InputMaybe<Reward_Types_Order_By>;
  rewardTypeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reward_claims */
export type Reward_Claims_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "reward_claims" */
export enum Reward_Claims_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClaimedAt = 'claimedAt',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RewardTypeId = 'rewardTypeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "reward_claims" */
export type Reward_Claims_Set_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  claimedAt?: InputMaybe<Scalars['timestamptz']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  rewardTypeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "reward_claims" */
export type Reward_Claims_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reward_Claims_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reward_Claims_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  claimedAt?: InputMaybe<Scalars['timestamptz']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  rewardTypeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "reward_claims" */
export enum Reward_Claims_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClaimedAt = 'claimedAt',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RewardTypeId = 'rewardTypeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Reward_Claims_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reward_Claims_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reward_Claims_Bool_Exp;
};

/** columns and relationships of "reward_types" */
export type Reward_Types = {
  __typename?: 'reward_types';
  color: Scalars['String'];
  /** An object relationship */
  coverImage?: Maybe<Images>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  name: Scalars['String'];
  pointCost: Scalars['Int'];
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
  /** An object relationship */
  rewardBrand: Reward_Brands;
  rewardBrandId: Scalars['String'];
  /** An array relationship */
  rewardClaims: Array<Reward_Claims>;
  /** An aggregate relationship */
  rewardClaims_aggregate: Reward_Claims_Aggregate;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "reward_types" */
export type Reward_TypesRewardClaimsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


/** columns and relationships of "reward_types" */
export type Reward_TypesRewardClaims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};

/** aggregated selection of "reward_types" */
export type Reward_Types_Aggregate = {
  __typename?: 'reward_types_aggregate';
  aggregate?: Maybe<Reward_Types_Aggregate_Fields>;
  nodes: Array<Reward_Types>;
};

export type Reward_Types_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Reward_Types_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Reward_Types_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Reward_Types_Aggregate_Bool_Exp_Count>;
};

export type Reward_Types_Aggregate_Bool_Exp_Bool_And = {
  arguments: Reward_Types_Select_Column_Reward_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reward_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reward_Types_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Reward_Types_Select_Column_Reward_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reward_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reward_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reward_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reward_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reward_types" */
export type Reward_Types_Aggregate_Fields = {
  __typename?: 'reward_types_aggregate_fields';
  avg?: Maybe<Reward_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reward_Types_Max_Fields>;
  min?: Maybe<Reward_Types_Min_Fields>;
  stddev?: Maybe<Reward_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Reward_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reward_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Reward_Types_Sum_Fields>;
  var_pop?: Maybe<Reward_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Reward_Types_Var_Samp_Fields>;
  variance?: Maybe<Reward_Types_Variance_Fields>;
};


/** aggregate fields of "reward_types" */
export type Reward_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reward_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reward_types" */
export type Reward_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Reward_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reward_Types_Max_Order_By>;
  min?: InputMaybe<Reward_Types_Min_Order_By>;
  stddev?: InputMaybe<Reward_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reward_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reward_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reward_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Reward_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reward_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Reward_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reward_types" */
export type Reward_Types_Arr_Rel_Insert_Input = {
  data: Array<Reward_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reward_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Reward_Types_Avg_Fields = {
  __typename?: 'reward_types_avg_fields';
  pointCost?: Maybe<Scalars['Float']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by avg() on columns of table "reward_types" */
export type Reward_Types_Avg_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reward_types". All fields are combined with a logical 'AND'. */
export type Reward_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Reward_Types_Bool_Exp>>;
  _not?: InputMaybe<Reward_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Reward_Types_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  coverImage?: InputMaybe<Images_Bool_Exp>;
  coverImageId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isHidden?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pointCost?: InputMaybe<Int_Comparison_Exp>;
  quotaLeft?: InputMaybe<Int_Comparison_Exp>;
  quotaTotal?: InputMaybe<Int_Comparison_Exp>;
  rewardBrand?: InputMaybe<Reward_Brands_Bool_Exp>;
  rewardBrandId?: InputMaybe<String_Comparison_Exp>;
  rewardClaims?: InputMaybe<Reward_Claims_Bool_Exp>;
  rewardClaims_aggregate?: InputMaybe<Reward_Claims_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reward_types" */
export enum Reward_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  RewardTypesPkey = 'reward_types_pkey'
}

/** input type for incrementing numeric columns in table "reward_types" */
export type Reward_Types_Inc_Input = {
  pointCost?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reward_types" */
export type Reward_Types_Insert_Input = {
  color?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  rewardBrand?: InputMaybe<Reward_Brands_Obj_Rel_Insert_Input>;
  rewardBrandId?: InputMaybe<Scalars['String']>;
  rewardClaims?: InputMaybe<Reward_Claims_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reward_Types_Max_Fields = {
  __typename?: 'reward_types_max_fields';
  color?: Maybe<Scalars['String']>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
  rewardBrandId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reward_types" */
export type Reward_Types_Max_Order_By = {
  color?: InputMaybe<Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pointCost?: InputMaybe<Order_By>;
  rewardBrandId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reward_Types_Min_Fields = {
  __typename?: 'reward_types_min_fields';
  color?: Maybe<Scalars['String']>;
  coverImageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
  rewardBrandId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reward_types" */
export type Reward_Types_Min_Order_By = {
  color?: InputMaybe<Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pointCost?: InputMaybe<Order_By>;
  rewardBrandId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reward_types" */
export type Reward_Types_Mutation_Response = {
  __typename?: 'reward_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reward_Types>;
};

/** input type for inserting object relation for remote table "reward_types" */
export type Reward_Types_Obj_Rel_Insert_Input = {
  data: Reward_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reward_Types_On_Conflict>;
};

/** on_conflict condition type for table "reward_types" */
export type Reward_Types_On_Conflict = {
  constraint: Reward_Types_Constraint;
  update_columns?: Array<Reward_Types_Update_Column>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "reward_types". */
export type Reward_Types_Order_By = {
  color?: InputMaybe<Order_By>;
  coverImage?: InputMaybe<Images_Order_By>;
  coverImageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isHidden?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pointCost?: InputMaybe<Order_By>;
  quotaLeft?: InputMaybe<Order_By>;
  quotaTotal?: InputMaybe<Order_By>;
  rewardBrand?: InputMaybe<Reward_Brands_Order_By>;
  rewardBrandId?: InputMaybe<Order_By>;
  rewardClaims_aggregate?: InputMaybe<Reward_Claims_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reward_types */
export type Reward_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "reward_types" */
export enum Reward_Types_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CoverImageId = 'coverImageId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  Name = 'name',
  /** column name */
  PointCost = 'pointCost',
  /** column name */
  RewardBrandId = 'rewardBrandId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "reward_types_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reward_types" */
export enum Reward_Types_Select_Column_Reward_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsHidden = 'isHidden'
}

/** select "reward_types_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reward_types" */
export enum Reward_Types_Select_Column_Reward_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsHidden = 'isHidden'
}

/** input type for updating data in table "reward_types" */
export type Reward_Types_Set_Input = {
  color?: InputMaybe<Scalars['String']>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  rewardBrandId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Reward_Types_Stddev_Fields = {
  __typename?: 'reward_types_stddev_fields';
  pointCost?: Maybe<Scalars['Float']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "reward_types" */
export type Reward_Types_Stddev_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reward_Types_Stddev_Pop_Fields = {
  __typename?: 'reward_types_stddev_pop_fields';
  pointCost?: Maybe<Scalars['Float']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by stddev_pop() on columns of table "reward_types" */
export type Reward_Types_Stddev_Pop_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reward_Types_Stddev_Samp_Fields = {
  __typename?: 'reward_types_stddev_samp_fields';
  pointCost?: Maybe<Scalars['Float']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by stddev_samp() on columns of table "reward_types" */
export type Reward_Types_Stddev_Samp_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reward_types" */
export type Reward_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reward_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reward_Types_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  coverImageId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pointCost?: InputMaybe<Scalars['Int']>;
  rewardBrandId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Reward_Types_Sum_Fields = {
  __typename?: 'reward_types_sum_fields';
  pointCost?: Maybe<Scalars['Int']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "reward_types" */
export type Reward_Types_Sum_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** update columns of table "reward_types" */
export enum Reward_Types_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CoverImageId = 'coverImageId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  Name = 'name',
  /** column name */
  PointCost = 'pointCost',
  /** column name */
  RewardBrandId = 'rewardBrandId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Reward_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reward_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reward_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reward_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reward_Types_Var_Pop_Fields = {
  __typename?: 'reward_types_var_pop_fields';
  pointCost?: Maybe<Scalars['Float']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by var_pop() on columns of table "reward_types" */
export type Reward_Types_Var_Pop_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reward_Types_Var_Samp_Fields = {
  __typename?: 'reward_types_var_samp_fields';
  pointCost?: Maybe<Scalars['Float']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by var_samp() on columns of table "reward_types" */
export type Reward_Types_Var_Samp_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reward_Types_Variance_Fields = {
  __typename?: 'reward_types_variance_fields';
  pointCost?: Maybe<Scalars['Float']>;
  quotaLeft?: Maybe<Scalars['Int']>;
  quotaTotal?: Maybe<Scalars['Int']>;
};

/** order by variance() on columns of table "reward_types" */
export type Reward_Types_Variance_Order_By = {
  pointCost?: InputMaybe<Order_By>;
};

/** columns and relationships of "role_types" */
export type Role_Types = {
  __typename?: 'role_types';
  /** An array relationship */
  community_members: Array<Community_Members>;
  /** An aggregate relationship */
  community_members_aggregate: Community_Members_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "role_types" */
export type Role_TypesCommunity_MembersArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


/** columns and relationships of "role_types" */
export type Role_TypesCommunity_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};

/** aggregated selection of "role_types" */
export type Role_Types_Aggregate = {
  __typename?: 'role_types_aggregate';
  aggregate?: Maybe<Role_Types_Aggregate_Fields>;
  nodes: Array<Role_Types>;
};

/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_Fields = {
  __typename?: 'role_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Types_Max_Fields>;
  min?: Maybe<Role_Types_Min_Fields>;
};


/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role_types". All fields are combined with a logical 'AND'. */
export type Role_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  _not?: InputMaybe<Role_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  community_members?: InputMaybe<Community_Members_Bool_Exp>;
  community_members_aggregate?: InputMaybe<Community_Members_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_types" */
export enum Role_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  RoleTypesPkey = 'role_types_pkey'
}

export enum Role_Types_Enum {
  Moderator = 'moderator',
  Owner = 'owner',
  User = 'user'
}

/** Boolean expression to compare columns of type "role_types_enum". All fields are combined with logical 'AND'. */
export type Role_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Role_Types_Enum>;
  _in?: InputMaybe<Array<Role_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Role_Types_Enum>;
  _nin?: InputMaybe<Array<Role_Types_Enum>>;
};

/** input type for inserting data into table "role_types" */
export type Role_Types_Insert_Input = {
  community_members?: InputMaybe<Community_Members_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Role_Types_Max_Fields = {
  __typename?: 'role_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Types_Min_Fields = {
  __typename?: 'role_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role_types" */
export type Role_Types_Mutation_Response = {
  __typename?: 'role_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Types>;
};

/** input type for inserting object relation for remote table "role_types" */
export type Role_Types_Obj_Rel_Insert_Input = {
  data: Role_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};

/** on_conflict condition type for table "role_types" */
export type Role_Types_On_Conflict = {
  constraint: Role_Types_Constraint;
  update_columns?: Array<Role_Types_Update_Column>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "role_types". */
export type Role_Types_Order_By = {
  community_members_aggregate?: InputMaybe<Community_Members_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_types */
export type Role_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "role_types" */
export enum Role_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "role_types" */
export type Role_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "role_types" */
export type Role_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "role_types" */
export enum Role_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Role_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Types_Bool_Exp;
};

/** columns and relationships of "rss_feeds" */
export type Rss_Feeds = {
  __typename?: 'rss_feeds';
  created_at?: Maybe<Scalars['timestamptz']>;
  feed_url: Scalars['String'];
  id: Scalars['String'];
  last_fetch_at?: Maybe<Scalars['timestamptz']>;
  post_on_farcaster: Scalars['Boolean'];
  post_on_lens: Scalars['Boolean'];
  /** An object relationship */
  profile: Profiles;
  profile_id: Scalars['String'];
  topic_id: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  use_title_for_description: Scalars['Boolean'];
  user_agent?: Maybe<Scalars['String']>;
};

/** aggregated selection of "rss_feeds" */
export type Rss_Feeds_Aggregate = {
  __typename?: 'rss_feeds_aggregate';
  aggregate?: Maybe<Rss_Feeds_Aggregate_Fields>;
  nodes: Array<Rss_Feeds>;
};

export type Rss_Feeds_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Rss_Feeds_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Rss_Feeds_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Rss_Feeds_Aggregate_Bool_Exp_Count>;
};

export type Rss_Feeds_Aggregate_Bool_Exp_Bool_And = {
  arguments: Rss_Feeds_Select_Column_Rss_Feeds_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rss_Feeds_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rss_Feeds_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Rss_Feeds_Select_Column_Rss_Feeds_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rss_Feeds_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rss_Feeds_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rss_Feeds_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rss_feeds" */
export type Rss_Feeds_Aggregate_Fields = {
  __typename?: 'rss_feeds_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rss_Feeds_Max_Fields>;
  min?: Maybe<Rss_Feeds_Min_Fields>;
};


/** aggregate fields of "rss_feeds" */
export type Rss_Feeds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rss_feeds" */
export type Rss_Feeds_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rss_Feeds_Max_Order_By>;
  min?: InputMaybe<Rss_Feeds_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rss_feeds" */
export type Rss_Feeds_Arr_Rel_Insert_Input = {
  data: Array<Rss_Feeds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rss_Feeds_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rss_feeds". All fields are combined with a logical 'AND'. */
export type Rss_Feeds_Bool_Exp = {
  _and?: InputMaybe<Array<Rss_Feeds_Bool_Exp>>;
  _not?: InputMaybe<Rss_Feeds_Bool_Exp>;
  _or?: InputMaybe<Array<Rss_Feeds_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feed_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  last_fetch_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  post_on_farcaster?: InputMaybe<Boolean_Comparison_Exp>;
  post_on_lens?: InputMaybe<Boolean_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profile_id?: InputMaybe<String_Comparison_Exp>;
  topic_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  use_title_for_description?: InputMaybe<Boolean_Comparison_Exp>;
  user_agent?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rss_feeds" */
export enum Rss_Feeds_Constraint {
  /** unique or primary key constraint on columns "id" */
  RssFeedsPkey = 'rss_feeds_pkey'
}

/** input type for inserting data into table "rss_feeds" */
export type Rss_Feeds_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feed_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_fetch_at?: InputMaybe<Scalars['timestamptz']>;
  post_on_farcaster?: InputMaybe<Scalars['Boolean']>;
  post_on_lens?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['String']>;
  topic_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  use_title_for_description?: InputMaybe<Scalars['Boolean']>;
  user_agent?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Rss_Feeds_Max_Fields = {
  __typename?: 'rss_feeds_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  feed_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_fetch_at?: Maybe<Scalars['timestamptz']>;
  profile_id?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "rss_feeds" */
export type Rss_Feeds_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feed_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_fetch_at?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rss_Feeds_Min_Fields = {
  __typename?: 'rss_feeds_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  feed_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_fetch_at?: Maybe<Scalars['timestamptz']>;
  profile_id?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "rss_feeds" */
export type Rss_Feeds_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feed_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_fetch_at?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rss_feeds" */
export type Rss_Feeds_Mutation_Response = {
  __typename?: 'rss_feeds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rss_Feeds>;
};

/** on_conflict condition type for table "rss_feeds" */
export type Rss_Feeds_On_Conflict = {
  constraint: Rss_Feeds_Constraint;
  update_columns?: Array<Rss_Feeds_Update_Column>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};

/** Ordering options when selecting data from "rss_feeds". */
export type Rss_Feeds_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feed_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_fetch_at?: InputMaybe<Order_By>;
  post_on_farcaster?: InputMaybe<Order_By>;
  post_on_lens?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  use_title_for_description?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rss_feeds */
export type Rss_Feeds_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "rss_feeds" */
export enum Rss_Feeds_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedUrl = 'feed_url',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetchAt = 'last_fetch_at',
  /** column name */
  PostOnFarcaster = 'post_on_farcaster',
  /** column name */
  PostOnLens = 'post_on_lens',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UseTitleForDescription = 'use_title_for_description',
  /** column name */
  UserAgent = 'user_agent'
}

/** select "rss_feeds_aggregate_bool_exp_bool_and_arguments_columns" columns of table "rss_feeds" */
export enum Rss_Feeds_Select_Column_Rss_Feeds_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PostOnFarcaster = 'post_on_farcaster',
  /** column name */
  PostOnLens = 'post_on_lens',
  /** column name */
  UseTitleForDescription = 'use_title_for_description'
}

/** select "rss_feeds_aggregate_bool_exp_bool_or_arguments_columns" columns of table "rss_feeds" */
export enum Rss_Feeds_Select_Column_Rss_Feeds_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PostOnFarcaster = 'post_on_farcaster',
  /** column name */
  PostOnLens = 'post_on_lens',
  /** column name */
  UseTitleForDescription = 'use_title_for_description'
}

/** input type for updating data in table "rss_feeds" */
export type Rss_Feeds_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feed_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_fetch_at?: InputMaybe<Scalars['timestamptz']>;
  post_on_farcaster?: InputMaybe<Scalars['Boolean']>;
  post_on_lens?: InputMaybe<Scalars['Boolean']>;
  profile_id?: InputMaybe<Scalars['String']>;
  topic_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  use_title_for_description?: InputMaybe<Scalars['Boolean']>;
  user_agent?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "rss_feeds" */
export type Rss_Feeds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rss_Feeds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rss_Feeds_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feed_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_fetch_at?: InputMaybe<Scalars['timestamptz']>;
  post_on_farcaster?: InputMaybe<Scalars['Boolean']>;
  post_on_lens?: InputMaybe<Scalars['Boolean']>;
  profile_id?: InputMaybe<Scalars['String']>;
  topic_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  use_title_for_description?: InputMaybe<Scalars['Boolean']>;
  user_agent?: InputMaybe<Scalars['String']>;
};

/** update columns of table "rss_feeds" */
export enum Rss_Feeds_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedUrl = 'feed_url',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetchAt = 'last_fetch_at',
  /** column name */
  PostOnFarcaster = 'post_on_farcaster',
  /** column name */
  PostOnLens = 'post_on_lens',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UseTitleForDescription = 'use_title_for_description',
  /** column name */
  UserAgent = 'user_agent'
}

export type Rss_Feeds_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rss_Feeds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rss_Feeds_Bool_Exp;
};

export type SetFollowOutput = {
  __typename?: 'setFollowOutput';
  farcasterQuota: QuotaFollows;
  lensQuota: QuotaFollows;
  phaverQuota: QuotaFollows;
  profile?: Maybe<Profiles>;
  profileId: Scalars['String'];
  status: Scalars['String'];
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export enum SortedCommunitiesModes {
  Gated = 'gated',
  Hidden = 'hidden',
  Open = 'open',
  Trending = 'trending'
}

export type SortedCommunitiesResults = {
  __typename?: 'sortedCommunitiesResults';
  community?: Maybe<Communities>;
  id: Scalars['String'];
  sorting: Scalars['Int'];
};

export enum SortedUsersModes {
  HighestSocialBalance = 'highestSocialBalance',
  Latest = 'latest',
  LevelSixActiveRandomized = 'levelSixActiveRandomized',
  MostFollowed = 'mostFollowed',
  MostFollowedOnLens = 'mostFollowedOnLens',
  Trending = 'trending',
  TrendingRandomized = 'trendingRandomized'
}

export type SortedUsersResults = {
  __typename?: 'sortedUsersResults';
  id: Scalars['String'];
  profile?: Maybe<Profiles>;
  sorting: Scalars['Int'];
};

export type Squid_Mutation_Frontend = {
  __typename?: 'squid_mutation_frontend';
  /** delete data from the table: "account" */
  delete_account?: Maybe<Account_Mutation_Response>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<Account>;
  /** insert data into the table: "account" */
  insert_account?: Maybe<Account_Mutation_Response>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<Account>;
  /** update data of the table: "account" */
  update_account?: Maybe<Account_Mutation_Response>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<Account>;
  /** update multiples rows of table: "account" */
  update_account_many?: Maybe<Array<Maybe<Account_Mutation_Response>>>;
};


export type Squid_Mutation_FrontendDelete_AccountArgs = {
  where: Account_Bool_Exp;
};


export type Squid_Mutation_FrontendDelete_Account_By_PkArgs = {
  id: Scalars['String'];
};


export type Squid_Mutation_FrontendInsert_AccountArgs = {
  objects: Array<Account_Insert_Input>;
  on_conflict?: InputMaybe<Account_On_Conflict>;
};


export type Squid_Mutation_FrontendInsert_Account_OneArgs = {
  object: Account_Insert_Input;
  on_conflict?: InputMaybe<Account_On_Conflict>;
};


export type Squid_Mutation_FrontendUpdate_AccountArgs = {
  _set?: InputMaybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};


export type Squid_Mutation_FrontendUpdate_Account_By_PkArgs = {
  _set?: InputMaybe<Account_Set_Input>;
  pk_columns: Account_Pk_Columns_Input;
};


export type Squid_Mutation_FrontendUpdate_Account_ManyArgs = {
  updates: Array<Account_Updates>;
};

export type Squid_Query = {
  __typename?: 'squid_query';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
};


export type Squid_QueryAccountArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Squid_QueryAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Squid_QueryAccount_By_PkArgs = {
  id: Scalars['String'];
};

export type Squid_Subscription = {
  __typename?: 'squid_subscription';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table in a streaming manner: "account" */
  account_stream: Array<Account>;
};


export type Squid_SubscriptionAccountArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Squid_SubscriptionAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Squid_SubscriptionAccount_By_PkArgs = {
  id: Scalars['String'];
};


export type Squid_SubscriptionAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Bool_Exp>;
};

/** columns and relationships of "staking_reward_posters" */
export type Staking_Reward_Posters = {
  __typename?: 'staking_reward_posters';
  id: Scalars['uuid'];
  pointReward: Scalars['Int'];
  postId?: Maybe<Scalars['String']>;
  profileId: Scalars['String'];
  rewardDate: Scalars['timestamptz'];
  storedInBq: Scalars['Boolean'];
};

/** aggregated selection of "staking_reward_posters" */
export type Staking_Reward_Posters_Aggregate = {
  __typename?: 'staking_reward_posters_aggregate';
  aggregate?: Maybe<Staking_Reward_Posters_Aggregate_Fields>;
  nodes: Array<Staking_Reward_Posters>;
};

/** aggregate fields of "staking_reward_posters" */
export type Staking_Reward_Posters_Aggregate_Fields = {
  __typename?: 'staking_reward_posters_aggregate_fields';
  avg?: Maybe<Staking_Reward_Posters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Staking_Reward_Posters_Max_Fields>;
  min?: Maybe<Staking_Reward_Posters_Min_Fields>;
  stddev?: Maybe<Staking_Reward_Posters_Stddev_Fields>;
  stddev_pop?: Maybe<Staking_Reward_Posters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Staking_Reward_Posters_Stddev_Samp_Fields>;
  sum?: Maybe<Staking_Reward_Posters_Sum_Fields>;
  var_pop?: Maybe<Staking_Reward_Posters_Var_Pop_Fields>;
  var_samp?: Maybe<Staking_Reward_Posters_Var_Samp_Fields>;
  variance?: Maybe<Staking_Reward_Posters_Variance_Fields>;
};


/** aggregate fields of "staking_reward_posters" */
export type Staking_Reward_Posters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staking_Reward_Posters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Staking_Reward_Posters_Avg_Fields = {
  __typename?: 'staking_reward_posters_avg_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "staking_reward_posters". All fields are combined with a logical 'AND'. */
export type Staking_Reward_Posters_Bool_Exp = {
  _and?: InputMaybe<Array<Staking_Reward_Posters_Bool_Exp>>;
  _not?: InputMaybe<Staking_Reward_Posters_Bool_Exp>;
  _or?: InputMaybe<Array<Staking_Reward_Posters_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pointReward?: InputMaybe<Int_Comparison_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  rewardDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  storedInBq?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "staking_reward_posters" */
export enum Staking_Reward_Posters_Constraint {
  /** unique or primary key constraint on columns "id" */
  StakingRewardPostersPkey = 'staking_reward_posters_pkey',
  /** unique or primary key constraint on columns "profile_id", "reward_date", "post_id" */
  StakingRewardPostersUnique = 'staking_reward_posters_unique'
}

/** input type for incrementing numeric columns in table "staking_reward_posters" */
export type Staking_Reward_Posters_Inc_Input = {
  pointReward?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staking_reward_posters" */
export type Staking_Reward_Posters_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  pointReward?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  rewardDate?: InputMaybe<Scalars['timestamptz']>;
  storedInBq?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Staking_Reward_Posters_Max_Fields = {
  __typename?: 'staking_reward_posters_max_fields';
  id?: Maybe<Scalars['uuid']>;
  pointReward?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  rewardDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Staking_Reward_Posters_Min_Fields = {
  __typename?: 'staking_reward_posters_min_fields';
  id?: Maybe<Scalars['uuid']>;
  pointReward?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  rewardDate?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "staking_reward_posters" */
export type Staking_Reward_Posters_Mutation_Response = {
  __typename?: 'staking_reward_posters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staking_Reward_Posters>;
};

/** on_conflict condition type for table "staking_reward_posters" */
export type Staking_Reward_Posters_On_Conflict = {
  constraint: Staking_Reward_Posters_Constraint;
  update_columns?: Array<Staking_Reward_Posters_Update_Column>;
  where?: InputMaybe<Staking_Reward_Posters_Bool_Exp>;
};

/** Ordering options when selecting data from "staking_reward_posters". */
export type Staking_Reward_Posters_Order_By = {
  id?: InputMaybe<Order_By>;
  pointReward?: InputMaybe<Order_By>;
  postId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  rewardDate?: InputMaybe<Order_By>;
  storedInBq?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staking_reward_posters */
export type Staking_Reward_Posters_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "staking_reward_posters" */
export enum Staking_Reward_Posters_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PointReward = 'pointReward',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  RewardDate = 'rewardDate',
  /** column name */
  StoredInBq = 'storedInBq'
}

/** input type for updating data in table "staking_reward_posters" */
export type Staking_Reward_Posters_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  pointReward?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  rewardDate?: InputMaybe<Scalars['timestamptz']>;
  storedInBq?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Staking_Reward_Posters_Stddev_Fields = {
  __typename?: 'staking_reward_posters_stddev_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Staking_Reward_Posters_Stddev_Pop_Fields = {
  __typename?: 'staking_reward_posters_stddev_pop_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Staking_Reward_Posters_Stddev_Samp_Fields = {
  __typename?: 'staking_reward_posters_stddev_samp_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "staking_reward_posters" */
export type Staking_Reward_Posters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staking_Reward_Posters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staking_Reward_Posters_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  pointReward?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  rewardDate?: InputMaybe<Scalars['timestamptz']>;
  storedInBq?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Staking_Reward_Posters_Sum_Fields = {
  __typename?: 'staking_reward_posters_sum_fields';
  pointReward?: Maybe<Scalars['Int']>;
};

/** update columns of table "staking_reward_posters" */
export enum Staking_Reward_Posters_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PointReward = 'pointReward',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  RewardDate = 'rewardDate',
  /** column name */
  StoredInBq = 'storedInBq'
}

export type Staking_Reward_Posters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Staking_Reward_Posters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staking_Reward_Posters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staking_Reward_Posters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staking_Reward_Posters_Var_Pop_Fields = {
  __typename?: 'staking_reward_posters_var_pop_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Staking_Reward_Posters_Var_Samp_Fields = {
  __typename?: 'staking_reward_posters_var_samp_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Staking_Reward_Posters_Variance_Fields = {
  __typename?: 'staking_reward_posters_variance_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "staking_reward_stakers" */
export type Staking_Reward_Stakers = {
  __typename?: 'staking_reward_stakers';
  id: Scalars['uuid'];
  pointReward: Scalars['Int'];
  /** An object relationship */
  post?: Maybe<Recommendations>;
  postId?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profiles;
  profileId: Scalars['String'];
  rewardDate: Scalars['timestamptz'];
  /** An object relationship */
  stake: Daily_Staking;
  stakeId: Scalars['String'];
  storedInBq: Scalars['Boolean'];
};

/** aggregated selection of "staking_reward_stakers" */
export type Staking_Reward_Stakers_Aggregate = {
  __typename?: 'staking_reward_stakers_aggregate';
  aggregate?: Maybe<Staking_Reward_Stakers_Aggregate_Fields>;
  nodes: Array<Staking_Reward_Stakers>;
};

/** aggregate fields of "staking_reward_stakers" */
export type Staking_Reward_Stakers_Aggregate_Fields = {
  __typename?: 'staking_reward_stakers_aggregate_fields';
  avg?: Maybe<Staking_Reward_Stakers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Staking_Reward_Stakers_Max_Fields>;
  min?: Maybe<Staking_Reward_Stakers_Min_Fields>;
  stddev?: Maybe<Staking_Reward_Stakers_Stddev_Fields>;
  stddev_pop?: Maybe<Staking_Reward_Stakers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Staking_Reward_Stakers_Stddev_Samp_Fields>;
  sum?: Maybe<Staking_Reward_Stakers_Sum_Fields>;
  var_pop?: Maybe<Staking_Reward_Stakers_Var_Pop_Fields>;
  var_samp?: Maybe<Staking_Reward_Stakers_Var_Samp_Fields>;
  variance?: Maybe<Staking_Reward_Stakers_Variance_Fields>;
};


/** aggregate fields of "staking_reward_stakers" */
export type Staking_Reward_Stakers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staking_Reward_Stakers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Staking_Reward_Stakers_Avg_Fields = {
  __typename?: 'staking_reward_stakers_avg_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "staking_reward_stakers". All fields are combined with a logical 'AND'. */
export type Staking_Reward_Stakers_Bool_Exp = {
  _and?: InputMaybe<Array<Staking_Reward_Stakers_Bool_Exp>>;
  _not?: InputMaybe<Staking_Reward_Stakers_Bool_Exp>;
  _or?: InputMaybe<Array<Staking_Reward_Stakers_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pointReward?: InputMaybe<Int_Comparison_Exp>;
  post?: InputMaybe<Recommendations_Bool_Exp>;
  postId?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  rewardDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  stake?: InputMaybe<Daily_Staking_Bool_Exp>;
  stakeId?: InputMaybe<String_Comparison_Exp>;
  storedInBq?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "staking_reward_stakers" */
export enum Staking_Reward_Stakers_Constraint {
  /** unique or primary key constraint on columns "id" */
  StakingRewardStakersPkey = 'staking_reward_stakers_pkey',
  /** unique or primary key constraint on columns "stake_id", "profile_id", "reward_date" */
  StakingRewardStakersUnique = 'staking_reward_stakers_unique'
}

/** input type for incrementing numeric columns in table "staking_reward_stakers" */
export type Staking_Reward_Stakers_Inc_Input = {
  pointReward?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staking_reward_stakers" */
export type Staking_Reward_Stakers_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  pointReward?: InputMaybe<Scalars['Int']>;
  post?: InputMaybe<Recommendations_Obj_Rel_Insert_Input>;
  postId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['String']>;
  rewardDate?: InputMaybe<Scalars['timestamptz']>;
  stake?: InputMaybe<Daily_Staking_Obj_Rel_Insert_Input>;
  stakeId?: InputMaybe<Scalars['String']>;
  storedInBq?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Staking_Reward_Stakers_Max_Fields = {
  __typename?: 'staking_reward_stakers_max_fields';
  id?: Maybe<Scalars['uuid']>;
  pointReward?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  rewardDate?: Maybe<Scalars['timestamptz']>;
  stakeId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Staking_Reward_Stakers_Min_Fields = {
  __typename?: 'staking_reward_stakers_min_fields';
  id?: Maybe<Scalars['uuid']>;
  pointReward?: Maybe<Scalars['Int']>;
  postId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  rewardDate?: Maybe<Scalars['timestamptz']>;
  stakeId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "staking_reward_stakers" */
export type Staking_Reward_Stakers_Mutation_Response = {
  __typename?: 'staking_reward_stakers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staking_Reward_Stakers>;
};

/** on_conflict condition type for table "staking_reward_stakers" */
export type Staking_Reward_Stakers_On_Conflict = {
  constraint: Staking_Reward_Stakers_Constraint;
  update_columns?: Array<Staking_Reward_Stakers_Update_Column>;
  where?: InputMaybe<Staking_Reward_Stakers_Bool_Exp>;
};

/** Ordering options when selecting data from "staking_reward_stakers". */
export type Staking_Reward_Stakers_Order_By = {
  id?: InputMaybe<Order_By>;
  pointReward?: InputMaybe<Order_By>;
  post?: InputMaybe<Recommendations_Order_By>;
  postId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  profileId?: InputMaybe<Order_By>;
  rewardDate?: InputMaybe<Order_By>;
  stake?: InputMaybe<Daily_Staking_Order_By>;
  stakeId?: InputMaybe<Order_By>;
  storedInBq?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staking_reward_stakers */
export type Staking_Reward_Stakers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "staking_reward_stakers" */
export enum Staking_Reward_Stakers_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PointReward = 'pointReward',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  RewardDate = 'rewardDate',
  /** column name */
  StakeId = 'stakeId',
  /** column name */
  StoredInBq = 'storedInBq'
}

/** input type for updating data in table "staking_reward_stakers" */
export type Staking_Reward_Stakers_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  pointReward?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  rewardDate?: InputMaybe<Scalars['timestamptz']>;
  stakeId?: InputMaybe<Scalars['String']>;
  storedInBq?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Staking_Reward_Stakers_Stddev_Fields = {
  __typename?: 'staking_reward_stakers_stddev_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Staking_Reward_Stakers_Stddev_Pop_Fields = {
  __typename?: 'staking_reward_stakers_stddev_pop_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Staking_Reward_Stakers_Stddev_Samp_Fields = {
  __typename?: 'staking_reward_stakers_stddev_samp_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "staking_reward_stakers" */
export type Staking_Reward_Stakers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staking_Reward_Stakers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staking_Reward_Stakers_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  pointReward?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  rewardDate?: InputMaybe<Scalars['timestamptz']>;
  stakeId?: InputMaybe<Scalars['String']>;
  storedInBq?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Staking_Reward_Stakers_Sum_Fields = {
  __typename?: 'staking_reward_stakers_sum_fields';
  pointReward?: Maybe<Scalars['Int']>;
};

/** update columns of table "staking_reward_stakers" */
export enum Staking_Reward_Stakers_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PointReward = 'pointReward',
  /** column name */
  PostId = 'postId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  RewardDate = 'rewardDate',
  /** column name */
  StakeId = 'stakeId',
  /** column name */
  StoredInBq = 'storedInBq'
}

export type Staking_Reward_Stakers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Staking_Reward_Stakers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staking_Reward_Stakers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staking_Reward_Stakers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staking_Reward_Stakers_Var_Pop_Fields = {
  __typename?: 'staking_reward_stakers_var_pop_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Staking_Reward_Stakers_Var_Samp_Fields = {
  __typename?: 'staking_reward_stakers_var_samp_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Staking_Reward_Stakers_Variance_Fields = {
  __typename?: 'staking_reward_stakers_variance_fields';
  pointReward?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "subdivisions" */
export type Subdivisions = {
  __typename?: 'subdivisions';
  /** An array relationship */
  cities: Array<Cities>;
  /** An aggregate relationship */
  cities_aggregate: Cities_Aggregate;
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  /** An object relationship */
  country: Countries;
  countryId: Scalars['String'];
  id: Scalars['String'];
  isoCode: Scalars['String'];
  nameEn: Scalars['String'];
};


/** columns and relationships of "subdivisions" */
export type SubdivisionsCitiesArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


/** columns and relationships of "subdivisions" */
export type SubdivisionsCities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


/** columns and relationships of "subdivisions" */
export type SubdivisionsCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


/** columns and relationships of "subdivisions" */
export type SubdivisionsCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};

/** aggregated selection of "subdivisions" */
export type Subdivisions_Aggregate = {
  __typename?: 'subdivisions_aggregate';
  aggregate?: Maybe<Subdivisions_Aggregate_Fields>;
  nodes: Array<Subdivisions>;
};

export type Subdivisions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subdivisions_Aggregate_Bool_Exp_Count>;
};

export type Subdivisions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subdivisions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subdivisions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subdivisions" */
export type Subdivisions_Aggregate_Fields = {
  __typename?: 'subdivisions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subdivisions_Max_Fields>;
  min?: Maybe<Subdivisions_Min_Fields>;
};


/** aggregate fields of "subdivisions" */
export type Subdivisions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subdivisions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subdivisions" */
export type Subdivisions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subdivisions_Max_Order_By>;
  min?: InputMaybe<Subdivisions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subdivisions" */
export type Subdivisions_Arr_Rel_Insert_Input = {
  data: Array<Subdivisions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subdivisions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subdivisions". All fields are combined with a logical 'AND'. */
export type Subdivisions_Bool_Exp = {
  _and?: InputMaybe<Array<Subdivisions_Bool_Exp>>;
  _not?: InputMaybe<Subdivisions_Bool_Exp>;
  _or?: InputMaybe<Array<Subdivisions_Bool_Exp>>;
  cities?: InputMaybe<Cities_Bool_Exp>;
  cities_aggregate?: InputMaybe<Cities_Aggregate_Bool_Exp>;
  communities?: InputMaybe<Communities_Bool_Exp>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Bool_Exp>;
  country?: InputMaybe<Countries_Bool_Exp>;
  countryId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isoCode?: InputMaybe<String_Comparison_Exp>;
  nameEn?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subdivisions" */
export enum Subdivisions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubdivisionsPkey = 'subdivisions_pkey'
}

/** input type for inserting data into table "subdivisions" */
export type Subdivisions_Insert_Input = {
  cities?: InputMaybe<Cities_Arr_Rel_Insert_Input>;
  communities?: InputMaybe<Communities_Arr_Rel_Insert_Input>;
  country?: InputMaybe<Countries_Obj_Rel_Insert_Input>;
  countryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Subdivisions_Max_Fields = {
  __typename?: 'subdivisions_max_fields';
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "subdivisions" */
export type Subdivisions_Max_Order_By = {
  countryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subdivisions_Min_Fields = {
  __typename?: 'subdivisions_min_fields';
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "subdivisions" */
export type Subdivisions_Min_Order_By = {
  countryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subdivisions" */
export type Subdivisions_Mutation_Response = {
  __typename?: 'subdivisions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subdivisions>;
};

/** input type for inserting object relation for remote table "subdivisions" */
export type Subdivisions_Obj_Rel_Insert_Input = {
  data: Subdivisions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subdivisions_On_Conflict>;
};

/** on_conflict condition type for table "subdivisions" */
export type Subdivisions_On_Conflict = {
  constraint: Subdivisions_Constraint;
  update_columns?: Array<Subdivisions_Update_Column>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};

/** Ordering options when selecting data from "subdivisions". */
export type Subdivisions_Order_By = {
  cities_aggregate?: InputMaybe<Cities_Aggregate_Order_By>;
  communities_aggregate?: InputMaybe<Communities_Aggregate_Order_By>;
  country?: InputMaybe<Countries_Order_By>;
  countryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  nameEn?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subdivisions */
export type Subdivisions_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "subdivisions" */
export enum Subdivisions_Select_Column {
  /** column name */
  CountryId = 'countryId',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn'
}

/** input type for updating data in table "subdivisions" */
export type Subdivisions_Set_Input = {
  countryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "subdivisions" */
export type Subdivisions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subdivisions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subdivisions_Stream_Cursor_Value_Input = {
  countryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
};

/** update columns of table "subdivisions" */
export enum Subdivisions_Update_Column {
  /** column name */
  CountryId = 'countryId',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  NameEn = 'nameEn'
}

export type Subdivisions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subdivisions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subdivisions_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account_types" */
  account_types: Array<Account_Types>;
  /** fetch aggregated fields from the table: "account_types" */
  account_types_aggregate: Account_Types_Aggregate;
  /** fetch data from the table: "account_types" using primary key columns */
  account_types_by_pk?: Maybe<Account_Types>;
  /** fetch data from the table in a streaming manner: "account_types" */
  account_types_stream: Array<Account_Types>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table in a streaming manner: "accounts" */
  accounts_stream: Array<Accounts>;
  /** fetch data from the table: "admin_portal_whitelist" */
  admin_portal_whitelist: Array<Admin_Portal_Whitelist>;
  /** fetch aggregated fields from the table: "admin_portal_whitelist" */
  admin_portal_whitelist_aggregate: Admin_Portal_Whitelist_Aggregate;
  /** fetch data from the table: "admin_portal_whitelist" using primary key columns */
  admin_portal_whitelist_by_pk?: Maybe<Admin_Portal_Whitelist>;
  /** fetch data from the table in a streaming manner: "admin_portal_whitelist" */
  admin_portal_whitelist_stream: Array<Admin_Portal_Whitelist>;
  /** fetch data from the table: "airdrop" */
  airdrop: Array<Airdrop>;
  /** fetch aggregated fields from the table: "airdrop" */
  airdrop_aggregate: Airdrop_Aggregate;
  /** fetch data from the table: "airdrop" using primary key columns */
  airdrop_by_pk?: Maybe<Airdrop>;
  /** fetch data from the table in a streaming manner: "airdrop" */
  airdrop_stream: Array<Airdrop>;
  /** fetch data from the table: "async_status" */
  async_status: Array<Async_Status>;
  /** fetch aggregated fields from the table: "async_status" */
  async_status_aggregate: Async_Status_Aggregate;
  /** fetch data from the table: "async_status" using primary key columns */
  async_status_by_pk?: Maybe<Async_Status>;
  /** fetch data from the table in a streaming manner: "async_status" */
  async_status_stream: Array<Async_Status>;
  /** fetch data from the table: "audios" */
  audios: Array<Audios>;
  /** fetch aggregated fields from the table: "audios" */
  audios_aggregate: Audios_Aggregate;
  /** fetch data from the table: "audios" using primary key columns */
  audios_by_pk?: Maybe<Audios>;
  /** fetch data from the table in a streaming manner: "audios" */
  audios_stream: Array<Audios>;
  /** fetch data from the table: "badge_types" */
  badge_types: Array<Badge_Types>;
  /** fetch aggregated fields from the table: "badge_types" */
  badge_types_aggregate: Badge_Types_Aggregate;
  /** fetch data from the table: "badge_types" using primary key columns */
  badge_types_by_pk?: Maybe<Badge_Types>;
  /** fetch data from the table in a streaming manner: "badge_types" */
  badge_types_stream: Array<Badge_Types>;
  /** fetch data from the table: "blocked_profiles" */
  blocked_profiles: Array<Blocked_Profiles>;
  /** fetch aggregated fields from the table: "blocked_profiles" */
  blocked_profiles_aggregate: Blocked_Profiles_Aggregate;
  /** fetch data from the table: "blocked_profiles" using primary key columns */
  blocked_profiles_by_pk?: Maybe<Blocked_Profiles>;
  /** fetch data from the table in a streaming manner: "blocked_profiles" */
  blocked_profiles_stream: Array<Blocked_Profiles>;
  /** fetch data from the table: "campaign_signatures" */
  campaign_signatures: Array<Campaign_Signatures>;
  /** fetch aggregated fields from the table: "campaign_signatures" */
  campaign_signatures_aggregate: Campaign_Signatures_Aggregate;
  /** fetch data from the table: "campaign_signatures" using primary key columns */
  campaign_signatures_by_pk?: Maybe<Campaign_Signatures>;
  /** fetch data from the table in a streaming manner: "campaign_signatures" */
  campaign_signatures_stream: Array<Campaign_Signatures>;
  /** fetch data from the table: "cc_profiles" */
  cc_profiles: Array<Cc_Profiles>;
  /** fetch aggregated fields from the table: "cc_profiles" */
  cc_profiles_aggregate: Cc_Profiles_Aggregate;
  /** fetch data from the table: "cc_profiles" using primary key columns */
  cc_profiles_by_pk?: Maybe<Cc_Profiles>;
  /** fetch data from the table in a streaming manner: "cc_profiles" */
  cc_profiles_stream: Array<Cc_Profiles>;
  /** An array relationship */
  cities: Array<Cities>;
  /** An aggregate relationship */
  cities_aggregate: Cities_Aggregate;
  /** fetch data from the table: "cities" using primary key columns */
  cities_by_pk?: Maybe<Cities>;
  /** fetch data from the table in a streaming manner: "cities" */
  cities_stream: Array<Cities>;
  /** fetch data from the table: "claimed_yield" */
  claimed_yield: Array<Claimed_Yield>;
  /** fetch aggregated fields from the table: "claimed_yield" */
  claimed_yield_aggregate: Claimed_Yield_Aggregate;
  /** fetch data from the table: "claimed_yield" using primary key columns */
  claimed_yield_by_pk?: Maybe<Claimed_Yield>;
  /** fetch data from the table: "claimed_yield_by_project" */
  claimed_yield_by_project: Array<Claimed_Yield_By_Project>;
  /** fetch aggregated fields from the table: "claimed_yield_by_project" */
  claimed_yield_by_project_aggregate: Claimed_Yield_By_Project_Aggregate;
  /** fetch data from the table in a streaming manner: "claimed_yield_by_project" */
  claimed_yield_by_project_stream: Array<Claimed_Yield_By_Project>;
  /** fetch data from the table in a streaming manner: "claimed_yield" */
  claimed_yield_stream: Array<Claimed_Yield>;
  /** fetch data from the table: "comment_aggregates" */
  comment_aggregates: Array<Comment_Aggregates>;
  /** fetch aggregated fields from the table: "comment_aggregates" */
  comment_aggregates_aggregate: Comment_Aggregates_Aggregate;
  /** fetch data from the table: "comment_aggregates" using primary key columns */
  comment_aggregates_by_pk?: Maybe<Comment_Aggregates>;
  /** fetch data from the table in a streaming manner: "comment_aggregates" */
  comment_aggregates_stream: Array<Comment_Aggregates>;
  /** fetch data from the table: "comment_profile_tags" */
  comment_profile_tags: Array<Comment_Profile_Tags>;
  /** fetch aggregated fields from the table: "comment_profile_tags" */
  comment_profile_tags_aggregate: Comment_Profile_Tags_Aggregate;
  /** fetch data from the table: "comment_profile_tags" using primary key columns */
  comment_profile_tags_by_pk?: Maybe<Comment_Profile_Tags>;
  /** fetch data from the table in a streaming manner: "comment_profile_tags" */
  comment_profile_tags_stream: Array<Comment_Profile_Tags>;
  /** An array relationship */
  comment_votes: Array<Comment_Votes>;
  /** An aggregate relationship */
  comment_votes_aggregate: Comment_Votes_Aggregate;
  /** fetch data from the table: "comment_votes" using primary key columns */
  comment_votes_by_pk?: Maybe<Comment_Votes>;
  /** fetch data from the table in a streaming manner: "comment_votes" */
  comment_votes_stream: Array<Comment_Votes>;
  /** An array relationship */
  communities: Array<Communities>;
  /** An aggregate relationship */
  communities_aggregate: Communities_Aggregate;
  /** fetch data from the table: "communities" using primary key columns */
  communities_by_pk?: Maybe<Communities>;
  /** fetch data from the table in a streaming manner: "communities" */
  communities_stream: Array<Communities>;
  /** fetch data from the table: "community_aggregates" */
  community_aggregates: Array<Community_Aggregates>;
  /** fetch aggregated fields from the table: "community_aggregates" */
  community_aggregates_aggregate: Community_Aggregates_Aggregate;
  /** fetch data from the table: "community_aggregates" using primary key columns */
  community_aggregates_by_pk?: Maybe<Community_Aggregates>;
  /** fetch data from the table in a streaming manner: "community_aggregates" */
  community_aggregates_stream: Array<Community_Aggregates>;
  /** An array relationship */
  community_categories: Array<Community_Categories>;
  /** An aggregate relationship */
  community_categories_aggregate: Community_Categories_Aggregate;
  /** fetch data from the table: "community_categories" using primary key columns */
  community_categories_by_pk?: Maybe<Community_Categories>;
  /** fetch data from the table in a streaming manner: "community_categories" */
  community_categories_stream: Array<Community_Categories>;
  /** fetch data from the table: "community_content_collections" */
  community_content_collections: Array<Community_Content_Collections>;
  /** fetch aggregated fields from the table: "community_content_collections" */
  community_content_collections_aggregate: Community_Content_Collections_Aggregate;
  /** fetch data from the table: "community_content_collections" using primary key columns */
  community_content_collections_by_pk?: Maybe<Community_Content_Collections>;
  /** fetch data from the table in a streaming manner: "community_content_collections" */
  community_content_collections_stream: Array<Community_Content_Collections>;
  /** fetch data from the table: "community_content_questions" */
  community_content_questions: Array<Community_Content_Questions>;
  /** fetch aggregated fields from the table: "community_content_questions" */
  community_content_questions_aggregate: Community_Content_Questions_Aggregate;
  /** fetch data from the table: "community_content_questions" using primary key columns */
  community_content_questions_by_pk?: Maybe<Community_Content_Questions>;
  /** fetch data from the table in a streaming manner: "community_content_questions" */
  community_content_questions_stream: Array<Community_Content_Questions>;
  /** fetch data from the table: "community_content_vote_scores" */
  community_content_vote_scores: Array<Community_Content_Vote_Scores>;
  /** fetch aggregated fields from the table: "community_content_vote_scores" */
  community_content_vote_scores_aggregate: Community_Content_Vote_Scores_Aggregate;
  /** fetch data from the table in a streaming manner: "community_content_vote_scores" */
  community_content_vote_scores_stream: Array<Community_Content_Vote_Scores>;
  /** fetch data from the table: "community_content_votes" */
  community_content_votes: Array<Community_Content_Votes>;
  /** fetch aggregated fields from the table: "community_content_votes" */
  community_content_votes_aggregate: Community_Content_Votes_Aggregate;
  /** fetch data from the table: "community_content_votes" using primary key columns */
  community_content_votes_by_pk?: Maybe<Community_Content_Votes>;
  /** fetch data from the table in a streaming manner: "community_content_votes" */
  community_content_votes_stream: Array<Community_Content_Votes>;
  /** fetch data from the table: "community_contents" */
  community_contents: Array<Community_Contents>;
  /** fetch aggregated fields from the table: "community_contents" */
  community_contents_aggregate: Community_Contents_Aggregate;
  /** fetch data from the table: "community_contents" using primary key columns */
  community_contents_by_pk?: Maybe<Community_Contents>;
  /** fetch data from the table in a streaming manner: "community_contents" */
  community_contents_stream: Array<Community_Contents>;
  /** An array relationship */
  community_members: Array<Community_Members>;
  /** An aggregate relationship */
  community_members_aggregate: Community_Members_Aggregate;
  /** fetch data from the table: "community_members" using primary key columns */
  community_members_by_pk?: Maybe<Community_Members>;
  /** fetch data from the table in a streaming manner: "community_members" */
  community_members_stream: Array<Community_Members>;
  /** fetch data from the table: "community_nft_projects" */
  community_nft_projects: Array<Community_Nft_Projects>;
  /** fetch aggregated fields from the table: "community_nft_projects" */
  community_nft_projects_aggregate: Community_Nft_Projects_Aggregate;
  /** fetch data from the table: "community_nft_projects" using primary key columns */
  community_nft_projects_by_pk?: Maybe<Community_Nft_Projects>;
  /** fetch data from the table in a streaming manner: "community_nft_projects" */
  community_nft_projects_stream: Array<Community_Nft_Projects>;
  /** fetch data from the table: "community_staffpicks" */
  community_staffpicks: Array<Community_Staffpicks>;
  /** fetch aggregated fields from the table: "community_staffpicks" */
  community_staffpicks_aggregate: Community_Staffpicks_Aggregate;
  /** fetch data from the table: "community_staffpicks" using primary key columns */
  community_staffpicks_by_pk?: Maybe<Community_Staffpicks>;
  /** fetch data from the table in a streaming manner: "community_staffpicks" */
  community_staffpicks_stream: Array<Community_Staffpicks>;
  /** An array relationship */
  connected_nfts: Array<Connected_Nfts>;
  /** An aggregate relationship */
  connected_nfts_aggregate: Connected_Nfts_Aggregate;
  /** fetch data from the table: "connected_nfts" using primary key columns */
  connected_nfts_by_pk?: Maybe<Connected_Nfts>;
  /** fetch data from the table in a streaming manner: "connected_nfts" */
  connected_nfts_stream: Array<Connected_Nfts>;
  /** fetch data from the table: "continents" */
  continents: Array<Continents>;
  /** fetch aggregated fields from the table: "continents" */
  continents_aggregate: Continents_Aggregate;
  /** fetch data from the table: "continents" using primary key columns */
  continents_by_pk?: Maybe<Continents>;
  /** fetch data from the table in a streaming manner: "continents" */
  continents_stream: Array<Continents>;
  /** An array relationship */
  countries: Array<Countries>;
  /** An aggregate relationship */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "countries" */
  countries_stream: Array<Countries>;
  /** An array relationship */
  cred_scores: Array<Cred_Scores>;
  /** An aggregate relationship */
  cred_scores_aggregate: Cred_Scores_Aggregate;
  /** fetch data from the table: "cred_scores" using primary key columns */
  cred_scores_by_pk?: Maybe<Cred_Scores>;
  /** fetch data from the table in a streaming manner: "cred_scores" */
  cred_scores_stream: Array<Cred_Scores>;
  /** fetch data from the table: "currency_isocodes" */
  currency_isocodes: Array<Currency_Isocodes>;
  /** fetch aggregated fields from the table: "currency_isocodes" */
  currency_isocodes_aggregate: Currency_Isocodes_Aggregate;
  /** fetch data from the table: "currency_isocodes" using primary key columns */
  currency_isocodes_by_pk?: Maybe<Currency_Isocodes>;
  /** fetch data from the table in a streaming manner: "currency_isocodes" */
  currency_isocodes_stream: Array<Currency_Isocodes>;
  /** fetch data from the table: "daily_staking" */
  daily_staking: Array<Daily_Staking>;
  /** fetch aggregated fields from the table: "daily_staking" */
  daily_staking_aggregate: Daily_Staking_Aggregate;
  /** fetch data from the table: "daily_staking" using primary key columns */
  daily_staking_by_pk?: Maybe<Daily_Staking>;
  /** fetch data from the table in a streaming manner: "daily_staking" */
  daily_staking_stream: Array<Daily_Staking>;
  /** fetch data from the table: "embedded_urls" */
  embedded_urls: Array<Embedded_Urls>;
  /** fetch aggregated fields from the table: "embedded_urls" */
  embedded_urls_aggregate: Embedded_Urls_Aggregate;
  /** fetch data from the table: "embedded_urls" using primary key columns */
  embedded_urls_by_pk?: Maybe<Embedded_Urls>;
  /** fetch data from the table in a streaming manner: "embedded_urls" */
  embedded_urls_stream: Array<Embedded_Urls>;
  /** An array relationship */
  farcaster_casts: Array<Farcaster_Casts>;
  /** An aggregate relationship */
  farcaster_casts_aggregate: Farcaster_Casts_Aggregate;
  /** fetch data from the table: "farcaster_casts" using primary key columns */
  farcaster_casts_by_pk?: Maybe<Farcaster_Casts>;
  /** fetch data from the table in a streaming manner: "farcaster_casts" */
  farcaster_casts_stream: Array<Farcaster_Casts>;
  /** fetch data from the table: "farcaster_invites" */
  farcaster_invites: Array<Farcaster_Invites>;
  /** fetch aggregated fields from the table: "farcaster_invites" */
  farcaster_invites_aggregate: Farcaster_Invites_Aggregate;
  /** fetch data from the table: "farcaster_invites" using primary key columns */
  farcaster_invites_by_pk?: Maybe<Farcaster_Invites>;
  /** fetch data from the table in a streaming manner: "farcaster_invites" */
  farcaster_invites_stream: Array<Farcaster_Invites>;
  /** fetch data from the table: "farcaster_power_badge_users" */
  farcaster_power_badge_users: Array<Farcaster_Power_Badge_Users>;
  /** fetch aggregated fields from the table: "farcaster_power_badge_users" */
  farcaster_power_badge_users_aggregate: Farcaster_Power_Badge_Users_Aggregate;
  /** fetch data from the table: "farcaster_power_badge_users" using primary key columns */
  farcaster_power_badge_users_by_pk?: Maybe<Farcaster_Power_Badge_Users>;
  /** fetch data from the table in a streaming manner: "farcaster_power_badge_users" */
  farcaster_power_badge_users_stream: Array<Farcaster_Power_Badge_Users>;
  /** fetch data from the table: "farcaster_profiles" */
  farcaster_profiles: Array<Farcaster_Profiles>;
  /** fetch aggregated fields from the table: "farcaster_profiles" */
  farcaster_profiles_aggregate: Farcaster_Profiles_Aggregate;
  /** fetch data from the table: "farcaster_profiles" using primary key columns */
  farcaster_profiles_by_pk?: Maybe<Farcaster_Profiles>;
  /** fetch data from the table in a streaming manner: "farcaster_profiles" */
  farcaster_profiles_stream: Array<Farcaster_Profiles>;
  /** fetch data from the table: "farcaster_reactions" */
  farcaster_reactions: Array<Farcaster_Reactions>;
  /** fetch aggregated fields from the table: "farcaster_reactions" */
  farcaster_reactions_aggregate: Farcaster_Reactions_Aggregate;
  /** fetch data from the table: "farcaster_reactions" using primary key columns */
  farcaster_reactions_by_pk?: Maybe<Farcaster_Reactions>;
  /** fetch data from the table in a streaming manner: "farcaster_reactions" */
  farcaster_reactions_stream: Array<Farcaster_Reactions>;
  /** fetch data from the table: "farcaster_signers" */
  farcaster_signers: Array<Farcaster_Signers>;
  /** fetch aggregated fields from the table: "farcaster_signers" */
  farcaster_signers_aggregate: Farcaster_Signers_Aggregate;
  /** fetch data from the table: "farcaster_signers" using primary key columns */
  farcaster_signers_by_pk?: Maybe<Farcaster_Signers>;
  /** fetch data from the table in a streaming manner: "farcaster_signers" */
  farcaster_signers_stream: Array<Farcaster_Signers>;
  /** fetch data from the table: "flag_types" */
  flag_types: Array<Flag_Types>;
  /** fetch aggregated fields from the table: "flag_types" */
  flag_types_aggregate: Flag_Types_Aggregate;
  /** fetch data from the table: "flag_types" using primary key columns */
  flag_types_by_pk?: Maybe<Flag_Types>;
  /** fetch data from the table in a streaming manner: "flag_types" */
  flag_types_stream: Array<Flag_Types>;
  /** An array relationship */
  flags: Array<Flags>;
  /** An aggregate relationship */
  flags_aggregate: Flags_Aggregate;
  /** fetch data from the table: "flags" using primary key columns */
  flags_by_pk?: Maybe<Flags>;
  /** fetch data from the table in a streaming manner: "flags" */
  flags_stream: Array<Flags>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followers_aggregate: Followers_Aggregate;
  /** fetch data from the table: "followers" using primary key columns */
  followers_by_pk?: Maybe<Followers>;
  /** fetch data from the table in a streaming manner: "followers" */
  followers_stream: Array<Followers>;
  /** fetch data from the table: "frame_action" */
  frame_action: Array<Frame_Action>;
  /** fetch aggregated fields from the table: "frame_action" */
  frame_action_aggregate: Frame_Action_Aggregate;
  /** fetch data from the table: "frame_action" using primary key columns */
  frame_action_by_pk?: Maybe<Frame_Action>;
  /** fetch data from the table in a streaming manner: "frame_action" */
  frame_action_stream: Array<Frame_Action>;
  /** fetch data from the table: "frame_buttons" */
  frame_buttons: Array<Frame_Buttons>;
  /** fetch aggregated fields from the table: "frame_buttons" */
  frame_buttons_aggregate: Frame_Buttons_Aggregate;
  /** fetch data from the table: "frame_buttons" using primary key columns */
  frame_buttons_by_pk?: Maybe<Frame_Buttons>;
  /** fetch data from the table in a streaming manner: "frame_buttons" */
  frame_buttons_stream: Array<Frame_Buttons>;
  /** fetch data from the table: "frames" */
  frames: Array<Frames>;
  /** fetch aggregated fields from the table: "frames" */
  frames_aggregate: Frames_Aggregate;
  /** fetch data from the table: "frames" using primary key columns */
  frames_by_pk?: Maybe<Frames>;
  /** fetch data from the table in a streaming manner: "frames" */
  frames_stream: Array<Frames>;
  /** An array relationship */
  images: Array<Images>;
  /** An aggregate relationship */
  images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<Images>;
  /** fetch data from the table in a streaming manner: "images" */
  images_stream: Array<Images>;
  /** fetch data from the table: "item_others" */
  item_others: Array<Item_Others>;
  /** fetch aggregated fields from the table: "item_others" */
  item_others_aggregate: Item_Others_Aggregate;
  /** fetch data from the table: "item_others" using primary key columns */
  item_others_by_pk?: Maybe<Item_Others>;
  /** fetch data from the table in a streaming manner: "item_others" */
  item_others_stream: Array<Item_Others>;
  /** fetch data from the table: "item_types" */
  item_types: Array<Item_Types>;
  /** fetch aggregated fields from the table: "item_types" */
  item_types_aggregate: Item_Types_Aggregate;
  /** fetch data from the table: "item_types" using primary key columns */
  item_types_by_pk?: Maybe<Item_Types>;
  /** fetch data from the table in a streaming manner: "item_types" */
  item_types_stream: Array<Item_Types>;
  /** An array relationship */
  items: Array<Items>;
  /** An aggregate relationship */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** fetch data from the table in a streaming manner: "items" */
  items_stream: Array<Items>;
  /** fetch data from the table: "knex_migrations" */
  knex_migrations: Array<Knex_Migrations>;
  /** fetch aggregated fields from the table: "knex_migrations" */
  knex_migrations_aggregate: Knex_Migrations_Aggregate;
  /** fetch data from the table: "knex_migrations" using primary key columns */
  knex_migrations_by_pk?: Maybe<Knex_Migrations>;
  /** fetch data from the table: "knex_migrations_lock" */
  knex_migrations_lock: Array<Knex_Migrations_Lock>;
  /** fetch aggregated fields from the table: "knex_migrations_lock" */
  knex_migrations_lock_aggregate: Knex_Migrations_Lock_Aggregate;
  /** fetch data from the table: "knex_migrations_lock" using primary key columns */
  knex_migrations_lock_by_pk?: Maybe<Knex_Migrations_Lock>;
  /** fetch data from the table in a streaming manner: "knex_migrations_lock" */
  knex_migrations_lock_stream: Array<Knex_Migrations_Lock>;
  /** fetch data from the table in a streaming manner: "knex_migrations" */
  knex_migrations_stream: Array<Knex_Migrations>;
  /** fetch data from the table: "language_isocodes" */
  language_isocodes: Array<Language_Isocodes>;
  /** fetch aggregated fields from the table: "language_isocodes" */
  language_isocodes_aggregate: Language_Isocodes_Aggregate;
  /** fetch data from the table: "language_isocodes" using primary key columns */
  language_isocodes_by_pk?: Maybe<Language_Isocodes>;
  /** fetch data from the table in a streaming manner: "language_isocodes" */
  language_isocodes_stream: Array<Language_Isocodes>;
  /** fetch data from the table: "lens_api_access_tokens" */
  lens_api_access_tokens: Array<Lens_Api_Access_Tokens>;
  /** fetch aggregated fields from the table: "lens_api_access_tokens" */
  lens_api_access_tokens_aggregate: Lens_Api_Access_Tokens_Aggregate;
  /** fetch data from the table: "lens_api_access_tokens" using primary key columns */
  lens_api_access_tokens_by_pk?: Maybe<Lens_Api_Access_Tokens>;
  /** fetch data from the table in a streaming manner: "lens_api_access_tokens" */
  lens_api_access_tokens_stream: Array<Lens_Api_Access_Tokens>;
  /** fetch data from the table: "lens_api_refresh_tokens" */
  lens_api_refresh_tokens: Array<Lens_Api_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "lens_api_refresh_tokens" */
  lens_api_refresh_tokens_aggregate: Lens_Api_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "lens_api_refresh_tokens" using primary key columns */
  lens_api_refresh_tokens_by_pk?: Maybe<Lens_Api_Refresh_Tokens>;
  /** fetch data from the table in a streaming manner: "lens_api_refresh_tokens" */
  lens_api_refresh_tokens_stream: Array<Lens_Api_Refresh_Tokens>;
  /** An array relationship */
  lens_appid_mappings: Array<Lens_Appid_Mappings>;
  /** An aggregate relationship */
  lens_appid_mappings_aggregate: Lens_Appid_Mappings_Aggregate;
  /** fetch data from the table: "lens_appid_mappings" using primary key columns */
  lens_appid_mappings_by_pk?: Maybe<Lens_Appid_Mappings>;
  /** fetch data from the table in a streaming manner: "lens_appid_mappings" */
  lens_appid_mappings_stream: Array<Lens_Appid_Mappings>;
  /** An array relationship */
  lens_collects: Array<Lens_Collects>;
  /** An aggregate relationship */
  lens_collects_aggregate: Lens_Collects_Aggregate;
  /** fetch data from the table: "lens_collects" using primary key columns */
  lens_collects_by_pk?: Maybe<Lens_Collects>;
  /** fetch data from the table in a streaming manner: "lens_collects" */
  lens_collects_stream: Array<Lens_Collects>;
  /** fetch data from the table: "lens_custodial_accounts" */
  lens_custodial_accounts: Array<Lens_Custodial_Accounts>;
  /** fetch aggregated fields from the table: "lens_custodial_accounts" */
  lens_custodial_accounts_aggregate: Lens_Custodial_Accounts_Aggregate;
  /** fetch data from the table: "lens_custodial_accounts" using primary key columns */
  lens_custodial_accounts_by_pk?: Maybe<Lens_Custodial_Accounts>;
  /** fetch data from the table in a streaming manner: "lens_custodial_accounts" */
  lens_custodial_accounts_stream: Array<Lens_Custodial_Accounts>;
  /** fetch data from the table: "lens_events" */
  lens_events: Array<Lens_Events>;
  /** fetch aggregated fields from the table: "lens_events" */
  lens_events_aggregate: Lens_Events_Aggregate;
  /** fetch data from the table: "lens_events" using primary key columns */
  lens_events_by_pk?: Maybe<Lens_Events>;
  /** fetch data from the table in a streaming manner: "lens_events" */
  lens_events_stream: Array<Lens_Events>;
  /** An array relationship */
  lens_followers: Array<Lens_Followers>;
  /** An aggregate relationship */
  lens_followers_aggregate: Lens_Followers_Aggregate;
  /** fetch data from the table: "lens_followers" using primary key columns */
  lens_followers_by_pk?: Maybe<Lens_Followers>;
  /** fetch data from the table in a streaming manner: "lens_followers" */
  lens_followers_stream: Array<Lens_Followers>;
  /** fetch data from the table: "lens_followers_v2" */
  lens_followers_v2: Array<Lens_Followers_V2>;
  /** fetch aggregated fields from the table: "lens_followers_v2" */
  lens_followers_v2_aggregate: Lens_Followers_V2_Aggregate;
  /** fetch data from the table: "lens_followers_v2" using primary key columns */
  lens_followers_v2_by_pk?: Maybe<Lens_Followers_V2>;
  /** fetch data from the table in a streaming manner: "lens_followers_v2" */
  lens_followers_v2_stream: Array<Lens_Followers_V2>;
  /** fetch data from the table: "lens_indexer" */
  lens_indexer: Array<Lens_Indexer>;
  /** fetch aggregated fields from the table: "lens_indexer" */
  lens_indexer_aggregate: Lens_Indexer_Aggregate;
  /** fetch data from the table: "lens_indexer" using primary key columns */
  lens_indexer_by_pk?: Maybe<Lens_Indexer>;
  /** fetch data from the table in a streaming manner: "lens_indexer" */
  lens_indexer_stream: Array<Lens_Indexer>;
  /** fetch data from the table: "lens_invites" */
  lens_invites: Array<Lens_Invites>;
  /** fetch aggregated fields from the table: "lens_invites" */
  lens_invites_aggregate: Lens_Invites_Aggregate;
  /** fetch data from the table: "lens_invites" using primary key columns */
  lens_invites_by_pk?: Maybe<Lens_Invites>;
  /** fetch data from the table in a streaming manner: "lens_invites" */
  lens_invites_stream: Array<Lens_Invites>;
  /** fetch data from the table: "lens_post_lens_pub_types" */
  lens_post_lens_pub_types: Array<Lens_Post_Lens_Pub_Types>;
  /** fetch aggregated fields from the table: "lens_post_lens_pub_types" */
  lens_post_lens_pub_types_aggregate: Lens_Post_Lens_Pub_Types_Aggregate;
  /** fetch data from the table: "lens_post_lens_pub_types" using primary key columns */
  lens_post_lens_pub_types_by_pk?: Maybe<Lens_Post_Lens_Pub_Types>;
  /** fetch data from the table in a streaming manner: "lens_post_lens_pub_types" */
  lens_post_lens_pub_types_stream: Array<Lens_Post_Lens_Pub_Types>;
  /** An array relationship */
  lens_posts: Array<Lens_Posts>;
  /** An aggregate relationship */
  lens_posts_aggregate: Lens_Posts_Aggregate;
  /** fetch data from the table: "lens_posts" using primary key columns */
  lens_posts_by_pk?: Maybe<Lens_Posts>;
  /** fetch data from the table in a streaming manner: "lens_posts" */
  lens_posts_stream: Array<Lens_Posts>;
  /** An array relationship */
  lens_profiles: Array<Lens_Profiles>;
  /** An aggregate relationship */
  lens_profiles_aggregate: Lens_Profiles_Aggregate;
  /** fetch data from the table: "lens_profiles" using primary key columns */
  lens_profiles_by_pk?: Maybe<Lens_Profiles>;
  /** fetch data from the table in a streaming manner: "lens_profiles" */
  lens_profiles_stream: Array<Lens_Profiles>;
  /** fetch data from the table: "lens_reaction_types" */
  lens_reaction_types: Array<Lens_Reaction_Types>;
  /** fetch aggregated fields from the table: "lens_reaction_types" */
  lens_reaction_types_aggregate: Lens_Reaction_Types_Aggregate;
  /** fetch data from the table: "lens_reaction_types" using primary key columns */
  lens_reaction_types_by_pk?: Maybe<Lens_Reaction_Types>;
  /** fetch data from the table in a streaming manner: "lens_reaction_types" */
  lens_reaction_types_stream: Array<Lens_Reaction_Types>;
  /** An array relationship */
  lens_reactions: Array<Lens_Reactions>;
  /** An aggregate relationship */
  lens_reactions_aggregate: Lens_Reactions_Aggregate;
  /** fetch data from the table: "lens_reactions" using primary key columns */
  lens_reactions_by_pk?: Maybe<Lens_Reactions>;
  /** fetch data from the table in a streaming manner: "lens_reactions" */
  lens_reactions_stream: Array<Lens_Reactions>;
  /** fetch data from the table: "lens_tx_status_enum" */
  lens_tx_status_enum: Array<Lens_Tx_Status_Enum>;
  /** fetch aggregated fields from the table: "lens_tx_status_enum" */
  lens_tx_status_enum_aggregate: Lens_Tx_Status_Enum_Aggregate;
  /** fetch data from the table: "lens_tx_status_enum" using primary key columns */
  lens_tx_status_enum_by_pk?: Maybe<Lens_Tx_Status_Enum>;
  /** fetch data from the table in a streaming manner: "lens_tx_status_enum" */
  lens_tx_status_enum_stream: Array<Lens_Tx_Status_Enum>;
  /** fetch data from the table: "levels" */
  levels: Array<Levels>;
  /** fetch aggregated fields from the table: "levels" */
  levels_aggregate: Levels_Aggregate;
  /** fetch data from the table: "levels" using primary key columns */
  levels_by_pk?: Maybe<Levels>;
  /** fetch data from the table in a streaming manner: "levels" */
  levels_stream: Array<Levels>;
  /** An array relationship */
  links: Array<Links>;
  /** An aggregate relationship */
  links_aggregate: Links_Aggregate;
  /** fetch data from the table: "links" using primary key columns */
  links_by_pk?: Maybe<Links>;
  /** fetch data from the table in a streaming manner: "links" */
  links_stream: Array<Links>;
  /** fetch data from the table: "locked_balances" */
  locked_balances: Array<Locked_Balances>;
  /** fetch aggregated fields from the table: "locked_balances" */
  locked_balances_aggregate: Locked_Balances_Aggregate;
  /** fetch data from the table: "locked_balances" using primary key columns */
  locked_balances_by_pk?: Maybe<Locked_Balances>;
  /** fetch data from the table in a streaming manner: "locked_balances" */
  locked_balances_stream: Array<Locked_Balances>;
  /** fetch data from the table: "matview_communities_trending" */
  matview_communities_trending: Array<Matview_Communities_Trending>;
  /** fetch aggregated fields from the table: "matview_communities_trending" */
  matview_communities_trending_aggregate: Matview_Communities_Trending_Aggregate;
  /** fetch data from the table in a streaming manner: "matview_communities_trending" */
  matview_communities_trending_stream: Array<Matview_Communities_Trending>;
  /** fetch data from the table: "matview_creators_active" */
  matview_creators_active: Array<Matview_Creators_Active>;
  /** fetch aggregated fields from the table: "matview_creators_active" */
  matview_creators_active_aggregate: Matview_Creators_Active_Aggregate;
  /** fetch data from the table in a streaming manner: "matview_creators_active" */
  matview_creators_active_stream: Array<Matview_Creators_Active>;
  /** fetch data from the table: "matview_post_scores_7d" */
  matview_post_scores_7d: Array<Matview_Post_Scores_7d>;
  /** fetch aggregated fields from the table: "matview_post_scores_7d" */
  matview_post_scores_7d_aggregate: Matview_Post_Scores_7d_Aggregate;
  /** fetch data from the table in a streaming manner: "matview_post_scores_7d" */
  matview_post_scores_7d_stream: Array<Matview_Post_Scores_7d>;
  /** fetch data from the table: "matview_users_most_follow" */
  matview_users_most_follow: Array<Matview_Users_Most_Follow>;
  /** fetch aggregated fields from the table: "matview_users_most_follow" */
  matview_users_most_follow_aggregate: Matview_Users_Most_Follow_Aggregate;
  /** fetch data from the table: "matview_users_most_follow_lens" */
  matview_users_most_follow_lens: Array<Matview_Users_Most_Follow_Lens>;
  /** fetch aggregated fields from the table: "matview_users_most_follow_lens" */
  matview_users_most_follow_lens_aggregate: Matview_Users_Most_Follow_Lens_Aggregate;
  /** fetch data from the table in a streaming manner: "matview_users_most_follow_lens" */
  matview_users_most_follow_lens_stream: Array<Matview_Users_Most_Follow_Lens>;
  /** fetch data from the table in a streaming manner: "matview_users_most_follow" */
  matview_users_most_follow_stream: Array<Matview_Users_Most_Follow>;
  /** fetch data from the table: "matview_users_trending" */
  matview_users_trending: Array<Matview_Users_Trending>;
  /** fetch aggregated fields from the table: "matview_users_trending" */
  matview_users_trending_aggregate: Matview_Users_Trending_Aggregate;
  /** fetch data from the table in a streaming manner: "matview_users_trending" */
  matview_users_trending_stream: Array<Matview_Users_Trending>;
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  /** fetch data from the table: "medias" using primary key columns */
  medias_by_pk?: Maybe<Medias>;
  /** fetch data from the table in a streaming manner: "medias" */
  medias_stream: Array<Medias>;
  /** fetch data from the table: "moca_ids" */
  moca_ids: Array<Moca_Ids>;
  /** fetch aggregated fields from the table: "moca_ids" */
  moca_ids_aggregate: Moca_Ids_Aggregate;
  /** fetch data from the table: "moca_ids" using primary key columns */
  moca_ids_by_pk?: Maybe<Moca_Ids>;
  /** fetch data from the table in a streaming manner: "moca_ids" */
  moca_ids_stream: Array<Moca_Ids>;
  /** fetch data from the table: "notification_setting_types" */
  notification_setting_types: Array<Notification_Setting_Types>;
  /** fetch aggregated fields from the table: "notification_setting_types" */
  notification_setting_types_aggregate: Notification_Setting_Types_Aggregate;
  /** fetch data from the table: "notification_setting_types" using primary key columns */
  notification_setting_types_by_pk?: Maybe<Notification_Setting_Types>;
  /** fetch data from the table in a streaming manner: "notification_setting_types" */
  notification_setting_types_stream: Array<Notification_Setting_Types>;
  /** An array relationship */
  notification_settings: Array<Notification_Settings>;
  /** An aggregate relationship */
  notification_settings_aggregate: Notification_Settings_Aggregate;
  /** fetch data from the table: "notification_settings" using primary key columns */
  notification_settings_by_pk?: Maybe<Notification_Settings>;
  /** fetch data from the table in a streaming manner: "notification_settings" */
  notification_settings_stream: Array<Notification_Settings>;
  /** fetch data from the table: "perks" */
  perks: Array<Perks>;
  /** fetch aggregated fields from the table: "perks" */
  perks_aggregate: Perks_Aggregate;
  /** fetch data from the table: "perks" using primary key columns */
  perks_by_pk?: Maybe<Perks>;
  /** fetch data from the table: "perks_claimed" */
  perks_claimed: Array<Perks_Claimed>;
  /** fetch aggregated fields from the table: "perks_claimed" */
  perks_claimed_aggregate: Perks_Claimed_Aggregate;
  /** fetch data from the table: "perks_claimed" using primary key columns */
  perks_claimed_by_pk?: Maybe<Perks_Claimed>;
  /** fetch data from the table in a streaming manner: "perks_claimed" */
  perks_claimed_stream: Array<Perks_Claimed>;
  /** fetch data from the table in a streaming manner: "perks" */
  perks_stream: Array<Perks>;
  /** fetch data from the table: "post_aggregates" */
  post_aggregates: Array<Post_Aggregates>;
  /** fetch aggregated fields from the table: "post_aggregates" */
  post_aggregates_aggregate: Post_Aggregates_Aggregate;
  /** fetch data from the table: "post_aggregates" using primary key columns */
  post_aggregates_by_pk?: Maybe<Post_Aggregates>;
  /** fetch data from the table in a streaming manner: "post_aggregates" */
  post_aggregates_stream: Array<Post_Aggregates>;
  /** fetch data from the table: "post_profile_tags" */
  post_profile_tags: Array<Post_Profile_Tags>;
  /** fetch aggregated fields from the table: "post_profile_tags" */
  post_profile_tags_aggregate: Post_Profile_Tags_Aggregate;
  /** fetch data from the table: "post_profile_tags" using primary key columns */
  post_profile_tags_by_pk?: Maybe<Post_Profile_Tags>;
  /** fetch data from the table in a streaming manner: "post_profile_tags" */
  post_profile_tags_stream: Array<Post_Profile_Tags>;
  /** fetch data from the table: "post_reaction_counts" */
  post_reaction_counts: Array<Post_Reaction_Counts>;
  /** fetch aggregated fields from the table: "post_reaction_counts" */
  post_reaction_counts_aggregate: Post_Reaction_Counts_Aggregate;
  /** fetch data from the table in a streaming manner: "post_reaction_counts" */
  post_reaction_counts_stream: Array<Post_Reaction_Counts>;
  /** fetch data from the table: "post_reaction_types" */
  post_reaction_types: Array<Post_Reaction_Types>;
  /** fetch aggregated fields from the table: "post_reaction_types" */
  post_reaction_types_aggregate: Post_Reaction_Types_Aggregate;
  /** fetch data from the table: "post_reaction_types" using primary key columns */
  post_reaction_types_by_pk?: Maybe<Post_Reaction_Types>;
  /** fetch data from the table in a streaming manner: "post_reaction_types" */
  post_reaction_types_stream: Array<Post_Reaction_Types>;
  /** An array relationship */
  post_reactions: Array<Post_Reactions>;
  /** An aggregate relationship */
  post_reactions_aggregate: Post_Reactions_Aggregate;
  /** fetch data from the table: "post_reactions" using primary key columns */
  post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** fetch data from the table in a streaming manner: "post_reactions" */
  post_reactions_stream: Array<Post_Reactions>;
  /** fetch data from the table: "profile_aggregates" */
  profile_aggregates: Array<Profile_Aggregates>;
  /** fetch aggregated fields from the table: "profile_aggregates" */
  profile_aggregates_aggregate: Profile_Aggregates_Aggregate;
  /** fetch data from the table: "profile_aggregates" using primary key columns */
  profile_aggregates_by_pk?: Maybe<Profile_Aggregates>;
  /** fetch data from the table in a streaming manner: "profile_aggregates" */
  profile_aggregates_stream: Array<Profile_Aggregates>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table in a streaming manner: "profiles" */
  profiles_stream: Array<Profiles>;
  /** fetch data from the table: "push_tokens" */
  push_tokens: Array<Push_Tokens>;
  /** fetch aggregated fields from the table: "push_tokens" */
  push_tokens_aggregate: Push_Tokens_Aggregate;
  /** fetch data from the table: "push_tokens" using primary key columns */
  push_tokens_by_pk?: Maybe<Push_Tokens>;
  /** fetch data from the table in a streaming manner: "push_tokens" */
  push_tokens_stream: Array<Push_Tokens>;
  /** An array relationship */
  recommendation_comments: Array<Recommendation_Comments>;
  /** An aggregate relationship */
  recommendation_comments_aggregate: Recommendation_Comments_Aggregate;
  /** fetch data from the table: "recommendation_comments" using primary key columns */
  recommendation_comments_by_pk?: Maybe<Recommendation_Comments>;
  /** fetch data from the table in a streaming manner: "recommendation_comments" */
  recommendation_comments_stream: Array<Recommendation_Comments>;
  /** An array relationship */
  recommendation_votes: Array<Recommendation_Votes>;
  /** An aggregate relationship */
  recommendation_votes_aggregate: Recommendation_Votes_Aggregate;
  /** fetch data from the table: "recommendation_votes" using primary key columns */
  recommendation_votes_by_pk?: Maybe<Recommendation_Votes>;
  /** fetch data from the table in a streaming manner: "recommendation_votes" */
  recommendation_votes_stream: Array<Recommendation_Votes>;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: Recommendations_Aggregate;
  /** fetch data from the table: "recommendations" using primary key columns */
  recommendations_by_pk?: Maybe<Recommendations>;
  /** fetch data from the table in a streaming manner: "recommendations" */
  recommendations_stream: Array<Recommendations>;
  /** fetch data from the table: "reported_content" */
  reported_content: Array<Reported_Content>;
  /** fetch aggregated fields from the table: "reported_content" */
  reported_content_aggregate: Reported_Content_Aggregate;
  /** fetch data from the table: "reported_content" using primary key columns */
  reported_content_by_pk?: Maybe<Reported_Content>;
  /** fetch data from the table in a streaming manner: "reported_content" */
  reported_content_stream: Array<Reported_Content>;
  /** An array relationship */
  reward_brands: Array<Reward_Brands>;
  /** An aggregate relationship */
  reward_brands_aggregate: Reward_Brands_Aggregate;
  /** fetch data from the table: "reward_brands" using primary key columns */
  reward_brands_by_pk?: Maybe<Reward_Brands>;
  /** fetch data from the table in a streaming manner: "reward_brands" */
  reward_brands_stream: Array<Reward_Brands>;
  /** An array relationship */
  reward_claims: Array<Reward_Claims>;
  /** An aggregate relationship */
  reward_claims_aggregate: Reward_Claims_Aggregate;
  /** fetch data from the table: "reward_claims" using primary key columns */
  reward_claims_by_pk?: Maybe<Reward_Claims>;
  /** fetch data from the table in a streaming manner: "reward_claims" */
  reward_claims_stream: Array<Reward_Claims>;
  /** An array relationship */
  reward_types: Array<Reward_Types>;
  /** An aggregate relationship */
  reward_types_aggregate: Reward_Types_Aggregate;
  /** fetch data from the table: "reward_types" using primary key columns */
  reward_types_by_pk?: Maybe<Reward_Types>;
  /** fetch data from the table in a streaming manner: "reward_types" */
  reward_types_stream: Array<Reward_Types>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** fetch data from the table in a streaming manner: "role_types" */
  role_types_stream: Array<Role_Types>;
  /** An array relationship */
  rss_feeds: Array<Rss_Feeds>;
  /** An aggregate relationship */
  rss_feeds_aggregate: Rss_Feeds_Aggregate;
  /** fetch data from the table: "rss_feeds" using primary key columns */
  rss_feeds_by_pk?: Maybe<Rss_Feeds>;
  /** fetch data from the table in a streaming manner: "rss_feeds" */
  rss_feeds_stream: Array<Rss_Feeds>;
  squid?: Maybe<Squid_Subscription>;
  /** fetch data from the table: "staking_reward_posters" */
  staking_reward_posters: Array<Staking_Reward_Posters>;
  /** fetch aggregated fields from the table: "staking_reward_posters" */
  staking_reward_posters_aggregate: Staking_Reward_Posters_Aggregate;
  /** fetch data from the table: "staking_reward_posters" using primary key columns */
  staking_reward_posters_by_pk?: Maybe<Staking_Reward_Posters>;
  /** fetch data from the table in a streaming manner: "staking_reward_posters" */
  staking_reward_posters_stream: Array<Staking_Reward_Posters>;
  /** fetch data from the table: "staking_reward_stakers" */
  staking_reward_stakers: Array<Staking_Reward_Stakers>;
  /** fetch aggregated fields from the table: "staking_reward_stakers" */
  staking_reward_stakers_aggregate: Staking_Reward_Stakers_Aggregate;
  /** fetch data from the table: "staking_reward_stakers" using primary key columns */
  staking_reward_stakers_by_pk?: Maybe<Staking_Reward_Stakers>;
  /** fetch data from the table in a streaming manner: "staking_reward_stakers" */
  staking_reward_stakers_stream: Array<Staking_Reward_Stakers>;
  /** fetch data from the table: "subdivisions" */
  subdivisions: Array<Subdivisions>;
  /** fetch aggregated fields from the table: "subdivisions" */
  subdivisions_aggregate: Subdivisions_Aggregate;
  /** fetch data from the table: "subdivisions" using primary key columns */
  subdivisions_by_pk?: Maybe<Subdivisions>;
  /** fetch data from the table in a streaming manner: "subdivisions" */
  subdivisions_stream: Array<Subdivisions>;
  /** fetch data from the table: "task_sections" */
  task_sections: Array<Task_Sections>;
  /** fetch aggregated fields from the table: "task_sections" */
  task_sections_aggregate: Task_Sections_Aggregate;
  /** fetch data from the table: "task_sections" using primary key columns */
  task_sections_by_pk?: Maybe<Task_Sections>;
  /** fetch data from the table in a streaming manner: "task_sections" */
  task_sections_stream: Array<Task_Sections>;
  /** fetch data from the table: "task_types" */
  task_types: Array<Task_Types>;
  /** fetch aggregated fields from the table: "task_types" */
  task_types_aggregate: Task_Types_Aggregate;
  /** fetch data from the table: "task_types" using primary key columns */
  task_types_by_pk?: Maybe<Task_Types>;
  /** fetch data from the table in a streaming manner: "task_types" */
  task_types_stream: Array<Task_Types>;
  /** fetch data from the table: "tasks" */
  tasks: Array<Tasks>;
  /** fetch aggregated fields from the table: "tasks" */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table: "tasks_done" */
  tasks_done: Array<Tasks_Done>;
  /** fetch aggregated fields from the table: "tasks_done" */
  tasks_done_aggregate: Tasks_Done_Aggregate;
  /** fetch data from the table: "tasks_done" using primary key columns */
  tasks_done_by_pk?: Maybe<Tasks_Done>;
  /** fetch data from the table in a streaming manner: "tasks_done" */
  tasks_done_stream: Array<Tasks_Done>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasks_stream: Array<Tasks>;
  /** fetch data from the table: "verification_types" */
  verification_types: Array<Verification_Types>;
  /** fetch aggregated fields from the table: "verification_types" */
  verification_types_aggregate: Verification_Types_Aggregate;
  /** fetch data from the table: "verification_types" using primary key columns */
  verification_types_by_pk?: Maybe<Verification_Types>;
  /** fetch data from the table in a streaming manner: "verification_types" */
  verification_types_stream: Array<Verification_Types>;
  /** fetch data from the table: "verified_nft_project_kinds" */
  verified_nft_project_kinds: Array<Verified_Nft_Project_Kinds>;
  /** fetch aggregated fields from the table: "verified_nft_project_kinds" */
  verified_nft_project_kinds_aggregate: Verified_Nft_Project_Kinds_Aggregate;
  /** fetch data from the table: "verified_nft_project_kinds" using primary key columns */
  verified_nft_project_kinds_by_pk?: Maybe<Verified_Nft_Project_Kinds>;
  /** fetch data from the table in a streaming manner: "verified_nft_project_kinds" */
  verified_nft_project_kinds_stream: Array<Verified_Nft_Project_Kinds>;
  /** An array relationship */
  verified_nft_projects: Array<Verified_Nft_Projects>;
  /** An aggregate relationship */
  verified_nft_projects_aggregate: Verified_Nft_Projects_Aggregate;
  /** fetch data from the table: "verified_nft_projects" using primary key columns */
  verified_nft_projects_by_pk?: Maybe<Verified_Nft_Projects>;
  /** fetch data from the table in a streaming manner: "verified_nft_projects" */
  verified_nft_projects_stream: Array<Verified_Nft_Projects>;
  /** fetch data from the table: "videos" */
  videos: Array<Videos>;
  /** fetch aggregated fields from the table: "videos" */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** fetch data from the table in a streaming manner: "videos" */
  videos_stream: Array<Videos>;
  /** An array relationship */
  wallets: Array<Wallets>;
  /** An aggregate relationship */
  wallets_aggregate: Wallets_Aggregate;
  /** fetch data from the table: "wallets" using primary key columns */
  wallets_by_pk?: Maybe<Wallets>;
  /** fetch data from the table: "wallets_resolves" */
  wallets_resolves: Array<Wallets_Resolves>;
  /** fetch aggregated fields from the table: "wallets_resolves" */
  wallets_resolves_aggregate: Wallets_Resolves_Aggregate;
  /** fetch data from the table: "wallets_resolves" using primary key columns */
  wallets_resolves_by_pk?: Maybe<Wallets_Resolves>;
  /** fetch data from the table in a streaming manner: "wallets_resolves" */
  wallets_resolves_stream: Array<Wallets_Resolves>;
  /** fetch data from the table in a streaming manner: "wallets" */
  wallets_stream: Array<Wallets>;
  /** fetch data from the table: "yield_sources" */
  yield_sources: Array<Yield_Sources>;
  /** fetch aggregated fields from the table: "yield_sources" */
  yield_sources_aggregate: Yield_Sources_Aggregate;
  /** fetch data from the table: "yield_sources" using primary key columns */
  yield_sources_by_pk?: Maybe<Yield_Sources>;
  /** fetch data from the table in a streaming manner: "yield_sources" */
  yield_sources_stream: Array<Yield_Sources>;
};


export type Subscription_RootAccount_TypesArgs = {
  distinct_on?: InputMaybe<Array<Account_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Types_Order_By>>;
  where?: InputMaybe<Account_Types_Bool_Exp>;
};


export type Subscription_RootAccount_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Types_Order_By>>;
  where?: InputMaybe<Account_Types_Bool_Exp>;
};


export type Subscription_RootAccount_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAccount_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Types_Bool_Exp>;
};


export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootAccounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAdmin_Portal_WhitelistArgs = {
  distinct_on?: InputMaybe<Array<Admin_Portal_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Portal_Whitelist_Order_By>>;
  where?: InputMaybe<Admin_Portal_Whitelist_Bool_Exp>;
};


export type Subscription_RootAdmin_Portal_Whitelist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admin_Portal_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Portal_Whitelist_Order_By>>;
  where?: InputMaybe<Admin_Portal_Whitelist_Bool_Exp>;
};


export type Subscription_RootAdmin_Portal_Whitelist_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAdmin_Portal_Whitelist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Admin_Portal_Whitelist_Stream_Cursor_Input>>;
  where?: InputMaybe<Admin_Portal_Whitelist_Bool_Exp>;
};


export type Subscription_RootAirdropArgs = {
  distinct_on?: InputMaybe<Array<Airdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrop_Order_By>>;
  where?: InputMaybe<Airdrop_Bool_Exp>;
};


export type Subscription_RootAirdrop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrop_Order_By>>;
  where?: InputMaybe<Airdrop_Bool_Exp>;
};


export type Subscription_RootAirdrop_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Subscription_RootAirdrop_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Airdrop_Stream_Cursor_Input>>;
  where?: InputMaybe<Airdrop_Bool_Exp>;
};


export type Subscription_RootAsync_StatusArgs = {
  distinct_on?: InputMaybe<Array<Async_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Async_Status_Order_By>>;
  where?: InputMaybe<Async_Status_Bool_Exp>;
};


export type Subscription_RootAsync_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Async_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Async_Status_Order_By>>;
  where?: InputMaybe<Async_Status_Bool_Exp>;
};


export type Subscription_RootAsync_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAsync_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Async_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Async_Status_Bool_Exp>;
};


export type Subscription_RootAudiosArgs = {
  distinct_on?: InputMaybe<Array<Audios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audios_Order_By>>;
  where?: InputMaybe<Audios_Bool_Exp>;
};


export type Subscription_RootAudios_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audios_Order_By>>;
  where?: InputMaybe<Audios_Bool_Exp>;
};


export type Subscription_RootAudios_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootAudios_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audios_Stream_Cursor_Input>>;
  where?: InputMaybe<Audios_Bool_Exp>;
};


export type Subscription_RootBadge_TypesArgs = {
  distinct_on?: InputMaybe<Array<Badge_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Types_Order_By>>;
  where?: InputMaybe<Badge_Types_Bool_Exp>;
};


export type Subscription_RootBadge_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Types_Order_By>>;
  where?: InputMaybe<Badge_Types_Bool_Exp>;
};


export type Subscription_RootBadge_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootBadge_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Badge_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Badge_Types_Bool_Exp>;
};


export type Subscription_RootBlocked_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blocked_Profiles_Order_By>>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};


export type Subscription_RootBlocked_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blocked_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blocked_Profiles_Order_By>>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};


export type Subscription_RootBlocked_Profiles_By_PkArgs = {
  blocked_profile_id: Scalars['String'];
  profile_id: Scalars['String'];
};


export type Subscription_RootBlocked_Profiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Blocked_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Blocked_Profiles_Bool_Exp>;
};


export type Subscription_RootCampaign_SignaturesArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Signatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Signatures_Order_By>>;
  where?: InputMaybe<Campaign_Signatures_Bool_Exp>;
};


export type Subscription_RootCampaign_Signatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Signatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Signatures_Order_By>>;
  where?: InputMaybe<Campaign_Signatures_Bool_Exp>;
};


export type Subscription_RootCampaign_Signatures_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCampaign_Signatures_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Campaign_Signatures_Stream_Cursor_Input>>;
  where?: InputMaybe<Campaign_Signatures_Bool_Exp>;
};


export type Subscription_RootCc_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cc_Profiles_Order_By>>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};


export type Subscription_RootCc_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cc_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cc_Profiles_Order_By>>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};


export type Subscription_RootCc_Profiles_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCc_Profiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cc_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Cc_Profiles_Bool_Exp>;
};


export type Subscription_RootCitiesArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Subscription_RootCities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Subscription_RootCities_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cities_Stream_Cursor_Input>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Subscription_RootClaimed_YieldArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_Order_By>>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};


export type Subscription_RootClaimed_Yield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_Order_By>>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};


export type Subscription_RootClaimed_Yield_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClaimed_Yield_By_ProjectArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_By_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_By_Project_Order_By>>;
  where?: InputMaybe<Claimed_Yield_By_Project_Bool_Exp>;
};


export type Subscription_RootClaimed_Yield_By_Project_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Claimed_Yield_By_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claimed_Yield_By_Project_Order_By>>;
  where?: InputMaybe<Claimed_Yield_By_Project_Bool_Exp>;
};


export type Subscription_RootClaimed_Yield_By_Project_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Claimed_Yield_By_Project_Stream_Cursor_Input>>;
  where?: InputMaybe<Claimed_Yield_By_Project_Bool_Exp>;
};


export type Subscription_RootClaimed_Yield_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Claimed_Yield_Stream_Cursor_Input>>;
  where?: InputMaybe<Claimed_Yield_Bool_Exp>;
};


export type Subscription_RootComment_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Comment_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Aggregates_Order_By>>;
  where?: InputMaybe<Comment_Aggregates_Bool_Exp>;
};


export type Subscription_RootComment_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Aggregates_Order_By>>;
  where?: InputMaybe<Comment_Aggregates_Bool_Exp>;
};


export type Subscription_RootComment_Aggregates_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootComment_Aggregates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comment_Aggregates_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Aggregates_Bool_Exp>;
};


export type Subscription_RootComment_Profile_TagsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Profile_Tags_Order_By>>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};


export type Subscription_RootComment_Profile_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Profile_Tags_Order_By>>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};


export type Subscription_RootComment_Profile_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootComment_Profile_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comment_Profile_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Profile_Tags_Bool_Exp>;
};


export type Subscription_RootComment_VotesArgs = {
  distinct_on?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Votes_Order_By>>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};


export type Subscription_RootComment_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Votes_Order_By>>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};


export type Subscription_RootComment_Votes_By_PkArgs = {
  commentId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Subscription_RootComment_Votes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comment_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Votes_Bool_Exp>;
};


export type Subscription_RootCommunitiesArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


export type Subscription_RootCommunities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communities_Order_By>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


export type Subscription_RootCommunities_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCommunities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Communities_Stream_Cursor_Input>>;
  where?: InputMaybe<Communities_Bool_Exp>;
};


export type Subscription_RootCommunity_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Community_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Aggregates_Order_By>>;
  where?: InputMaybe<Community_Aggregates_Bool_Exp>;
};


export type Subscription_RootCommunity_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Aggregates_Order_By>>;
  where?: InputMaybe<Community_Aggregates_Bool_Exp>;
};


export type Subscription_RootCommunity_Aggregates_By_PkArgs = {
  communityId: Scalars['String'];
};


export type Subscription_RootCommunity_Aggregates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Aggregates_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Aggregates_Bool_Exp>;
};


export type Subscription_RootCommunity_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


export type Subscription_RootCommunity_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Categories_Order_By>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


export type Subscription_RootCommunity_Categories_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCommunity_Categories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Categories_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_CollectionsArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Collections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Collections_Order_By>>;
  where?: InputMaybe<Community_Content_Collections_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Collections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Collections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Collections_Order_By>>;
  where?: InputMaybe<Community_Content_Collections_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Collections_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCommunity_Content_Collections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Content_Collections_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Content_Collections_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Questions_Order_By>>;
  where?: InputMaybe<Community_Content_Questions_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Questions_Order_By>>;
  where?: InputMaybe<Community_Content_Questions_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Questions_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCommunity_Content_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Content_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Content_Questions_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Vote_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Vote_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Vote_Scores_Order_By>>;
  where?: InputMaybe<Community_Content_Vote_Scores_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Vote_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Vote_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Vote_Scores_Order_By>>;
  where?: InputMaybe<Community_Content_Vote_Scores_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Vote_Scores_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Content_Vote_Scores_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Content_Vote_Scores_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_VotesArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Votes_Order_By>>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Content_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Content_Votes_Order_By>>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};


export type Subscription_RootCommunity_Content_Votes_By_PkArgs = {
  communityContentId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Subscription_RootCommunity_Content_Votes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Content_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Content_Votes_Bool_Exp>;
};


export type Subscription_RootCommunity_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Community_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Contents_Order_By>>;
  where?: InputMaybe<Community_Contents_Bool_Exp>;
};


export type Subscription_RootCommunity_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Contents_Order_By>>;
  where?: InputMaybe<Community_Contents_Bool_Exp>;
};


export type Subscription_RootCommunity_Contents_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCommunity_Contents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Contents_Bool_Exp>;
};


export type Subscription_RootCommunity_MembersArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


export type Subscription_RootCommunity_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Members_Order_By>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


export type Subscription_RootCommunity_Members_By_PkArgs = {
  communityId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Subscription_RootCommunity_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Members_Bool_Exp>;
};


export type Subscription_RootCommunity_Nft_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


export type Subscription_RootCommunity_Nft_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Nft_Projects_Order_By>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


export type Subscription_RootCommunity_Nft_Projects_By_PkArgs = {
  communityId: Scalars['String'];
  verifiedNftProjectId: Scalars['Int'];
};


export type Subscription_RootCommunity_Nft_Projects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Nft_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Nft_Projects_Bool_Exp>;
};


export type Subscription_RootCommunity_StaffpicksArgs = {
  distinct_on?: InputMaybe<Array<Community_Staffpicks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Staffpicks_Order_By>>;
  where?: InputMaybe<Community_Staffpicks_Bool_Exp>;
};


export type Subscription_RootCommunity_Staffpicks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_Staffpicks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_Staffpicks_Order_By>>;
  where?: InputMaybe<Community_Staffpicks_Bool_Exp>;
};


export type Subscription_RootCommunity_Staffpicks_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCommunity_Staffpicks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Community_Staffpicks_Stream_Cursor_Input>>;
  where?: InputMaybe<Community_Staffpicks_Bool_Exp>;
};


export type Subscription_RootConnected_NftsArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


export type Subscription_RootConnected_Nfts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


export type Subscription_RootConnected_Nfts_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootConnected_Nfts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Connected_Nfts_Stream_Cursor_Input>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


export type Subscription_RootContinentsArgs = {
  distinct_on?: InputMaybe<Array<Continents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Continents_Order_By>>;
  where?: InputMaybe<Continents_Bool_Exp>;
};


export type Subscription_RootContinents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Continents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Continents_Order_By>>;
  where?: InputMaybe<Continents_Bool_Exp>;
};


export type Subscription_RootContinents_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootContinents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Continents_Stream_Cursor_Input>>;
  where?: InputMaybe<Continents_Bool_Exp>;
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCountries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Countries_Stream_Cursor_Input>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCred_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cred_Scores_Order_By>>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};


export type Subscription_RootCred_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cred_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cred_Scores_Order_By>>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};


export type Subscription_RootCred_Scores_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Subscription_RootCred_Scores_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cred_Scores_Stream_Cursor_Input>>;
  where?: InputMaybe<Cred_Scores_Bool_Exp>;
};


export type Subscription_RootCurrency_IsocodesArgs = {
  distinct_on?: InputMaybe<Array<Currency_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Isocodes_Order_By>>;
  where?: InputMaybe<Currency_Isocodes_Bool_Exp>;
};


export type Subscription_RootCurrency_Isocodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Isocodes_Order_By>>;
  where?: InputMaybe<Currency_Isocodes_Bool_Exp>;
};


export type Subscription_RootCurrency_Isocodes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCurrency_Isocodes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currency_Isocodes_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_Isocodes_Bool_Exp>;
};


export type Subscription_RootDaily_StakingArgs = {
  distinct_on?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Staking_Order_By>>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};


export type Subscription_RootDaily_Staking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Staking_Order_By>>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};


export type Subscription_RootDaily_Staking_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootDaily_Staking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Daily_Staking_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Staking_Bool_Exp>;
};


export type Subscription_RootEmbedded_UrlsArgs = {
  distinct_on?: InputMaybe<Array<Embedded_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Embedded_Urls_Order_By>>;
  where?: InputMaybe<Embedded_Urls_Bool_Exp>;
};


export type Subscription_RootEmbedded_Urls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Embedded_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Embedded_Urls_Order_By>>;
  where?: InputMaybe<Embedded_Urls_Bool_Exp>;
};


export type Subscription_RootEmbedded_Urls_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmbedded_Urls_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Embedded_Urls_Stream_Cursor_Input>>;
  where?: InputMaybe<Embedded_Urls_Bool_Exp>;
};


export type Subscription_RootFarcaster_CastsArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Casts_Order_By>>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};


export type Subscription_RootFarcaster_Casts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Casts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Casts_Order_By>>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};


export type Subscription_RootFarcaster_Casts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFarcaster_Casts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Farcaster_Casts_Stream_Cursor_Input>>;
  where?: InputMaybe<Farcaster_Casts_Bool_Exp>;
};


export type Subscription_RootFarcaster_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Invites_Order_By>>;
  where?: InputMaybe<Farcaster_Invites_Bool_Exp>;
};


export type Subscription_RootFarcaster_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Invites_Order_By>>;
  where?: InputMaybe<Farcaster_Invites_Bool_Exp>;
};


export type Subscription_RootFarcaster_Invites_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Subscription_RootFarcaster_Invites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Farcaster_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<Farcaster_Invites_Bool_Exp>;
};


export type Subscription_RootFarcaster_Power_Badge_UsersArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Power_Badge_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Power_Badge_Users_Order_By>>;
  where?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
};


export type Subscription_RootFarcaster_Power_Badge_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Power_Badge_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Power_Badge_Users_Order_By>>;
  where?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
};


export type Subscription_RootFarcaster_Power_Badge_Users_By_PkArgs = {
  fid: Scalars['String'];
};


export type Subscription_RootFarcaster_Power_Badge_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Farcaster_Power_Badge_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Farcaster_Power_Badge_Users_Bool_Exp>;
};


export type Subscription_RootFarcaster_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Profiles_Order_By>>;
  where?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
};


export type Subscription_RootFarcaster_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Profiles_Order_By>>;
  where?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
};


export type Subscription_RootFarcaster_Profiles_By_PkArgs = {
  tokenId: Scalars['String'];
};


export type Subscription_RootFarcaster_Profiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Farcaster_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Farcaster_Profiles_Bool_Exp>;
};


export type Subscription_RootFarcaster_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Reactions_Order_By>>;
  where?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
};


export type Subscription_RootFarcaster_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Reactions_Order_By>>;
  where?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
};


export type Subscription_RootFarcaster_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFarcaster_Reactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Farcaster_Reactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Farcaster_Reactions_Bool_Exp>;
};


export type Subscription_RootFarcaster_SignersArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Signers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Signers_Order_By>>;
  where?: InputMaybe<Farcaster_Signers_Bool_Exp>;
};


export type Subscription_RootFarcaster_Signers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farcaster_Signers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Farcaster_Signers_Order_By>>;
  where?: InputMaybe<Farcaster_Signers_Bool_Exp>;
};


export type Subscription_RootFarcaster_Signers_By_PkArgs = {
  signerUuid: Scalars['String'];
};


export type Subscription_RootFarcaster_Signers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Farcaster_Signers_Stream_Cursor_Input>>;
  where?: InputMaybe<Farcaster_Signers_Bool_Exp>;
};


export type Subscription_RootFlag_TypesArgs = {
  distinct_on?: InputMaybe<Array<Flag_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flag_Types_Order_By>>;
  where?: InputMaybe<Flag_Types_Bool_Exp>;
};


export type Subscription_RootFlag_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flag_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flag_Types_Order_By>>;
  where?: InputMaybe<Flag_Types_Bool_Exp>;
};


export type Subscription_RootFlag_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFlag_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Flag_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Flag_Types_Bool_Exp>;
};


export type Subscription_RootFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Subscription_RootFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Subscription_RootFlags_By_PkArgs = {
  accountId: Scalars['String'];
  flagType: Flag_Types_Enum;
};


export type Subscription_RootFlags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Subscription_RootFollowersArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


export type Subscription_RootFollowers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Followers_Order_By>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


export type Subscription_RootFollowers_By_PkArgs = {
  followedProfileId: Scalars['String'];
  profileId: Scalars['String'];
};


export type Subscription_RootFollowers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Followers_Stream_Cursor_Input>>;
  where?: InputMaybe<Followers_Bool_Exp>;
};


export type Subscription_RootFrame_ActionArgs = {
  distinct_on?: InputMaybe<Array<Frame_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Action_Order_By>>;
  where?: InputMaybe<Frame_Action_Bool_Exp>;
};


export type Subscription_RootFrame_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frame_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Action_Order_By>>;
  where?: InputMaybe<Frame_Action_Bool_Exp>;
};


export type Subscription_RootFrame_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFrame_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Frame_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Frame_Action_Bool_Exp>;
};


export type Subscription_RootFrame_ButtonsArgs = {
  distinct_on?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Buttons_Order_By>>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};


export type Subscription_RootFrame_Buttons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frame_Buttons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frame_Buttons_Order_By>>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};


export type Subscription_RootFrame_Buttons_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFrame_Buttons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Frame_Buttons_Stream_Cursor_Input>>;
  where?: InputMaybe<Frame_Buttons_Bool_Exp>;
};


export type Subscription_RootFramesArgs = {
  distinct_on?: InputMaybe<Array<Frames_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frames_Order_By>>;
  where?: InputMaybe<Frames_Bool_Exp>;
};


export type Subscription_RootFrames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frames_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frames_Order_By>>;
  where?: InputMaybe<Frames_Bool_Exp>;
};


export type Subscription_RootFrames_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFrames_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Frames_Stream_Cursor_Input>>;
  where?: InputMaybe<Frames_Bool_Exp>;
};


export type Subscription_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootImages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootItem_OthersArgs = {
  distinct_on?: InputMaybe<Array<Item_Others_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Others_Order_By>>;
  where?: InputMaybe<Item_Others_Bool_Exp>;
};


export type Subscription_RootItem_Others_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Others_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Others_Order_By>>;
  where?: InputMaybe<Item_Others_Bool_Exp>;
};


export type Subscription_RootItem_Others_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootItem_Others_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Item_Others_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Others_Bool_Exp>;
};


export type Subscription_RootItem_TypesArgs = {
  distinct_on?: InputMaybe<Array<Item_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Types_Order_By>>;
  where?: InputMaybe<Item_Types_Bool_Exp>;
};


export type Subscription_RootItem_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Types_Order_By>>;
  where?: InputMaybe<Item_Types_Bool_Exp>;
};


export type Subscription_RootItem_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootItem_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Item_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Types_Bool_Exp>;
};


export type Subscription_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootKnex_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Bool_Exp>;
};


export type Subscription_RootKnex_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Bool_Exp>;
};


export type Subscription_RootKnex_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootKnex_Migrations_LockArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Lock_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Lock_Bool_Exp>;
};


export type Subscription_RootKnex_Migrations_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knex_Migrations_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knex_Migrations_Lock_Order_By>>;
  where?: InputMaybe<Knex_Migrations_Lock_Bool_Exp>;
};


export type Subscription_RootKnex_Migrations_Lock_By_PkArgs = {
  index: Scalars['Int'];
};


export type Subscription_RootKnex_Migrations_Lock_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Knex_Migrations_Lock_Stream_Cursor_Input>>;
  where?: InputMaybe<Knex_Migrations_Lock_Bool_Exp>;
};


export type Subscription_RootKnex_Migrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Knex_Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Knex_Migrations_Bool_Exp>;
};


export type Subscription_RootLanguage_IsocodesArgs = {
  distinct_on?: InputMaybe<Array<Language_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Isocodes_Order_By>>;
  where?: InputMaybe<Language_Isocodes_Bool_Exp>;
};


export type Subscription_RootLanguage_Isocodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Isocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Isocodes_Order_By>>;
  where?: InputMaybe<Language_Isocodes_Bool_Exp>;
};


export type Subscription_RootLanguage_Isocodes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLanguage_Isocodes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Language_Isocodes_Stream_Cursor_Input>>;
  where?: InputMaybe<Language_Isocodes_Bool_Exp>;
};


export type Subscription_RootLens_Api_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Access_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootLens_Api_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Access_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootLens_Api_Access_Tokens_By_PkArgs = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};


export type Subscription_RootLens_Api_Access_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Api_Access_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Api_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootLens_Api_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootLens_Api_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Api_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootLens_Api_Refresh_Tokens_By_PkArgs = {
  address: Scalars['String'];
  lens_profile_id: Scalars['String'];
};


export type Subscription_RootLens_Api_Refresh_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Api_Refresh_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Api_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootLens_Appid_MappingsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Appid_Mappings_Order_By>>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};


export type Subscription_RootLens_Appid_Mappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Appid_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Appid_Mappings_Order_By>>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};


export type Subscription_RootLens_Appid_Mappings_By_PkArgs = {
  app_id: Scalars['String'];
};


export type Subscription_RootLens_Appid_Mappings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Appid_Mappings_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Appid_Mappings_Bool_Exp>;
};


export type Subscription_RootLens_CollectsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


export type Subscription_RootLens_Collects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Collects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Collects_Order_By>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


export type Subscription_RootLens_Collects_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLens_Collects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Collects_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Collects_Bool_Exp>;
};


export type Subscription_RootLens_Custodial_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Custodial_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Custodial_Accounts_Order_By>>;
  where?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
};


export type Subscription_RootLens_Custodial_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Custodial_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Custodial_Accounts_Order_By>>;
  where?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
};


export type Subscription_RootLens_Custodial_Accounts_By_PkArgs = {
  address: Scalars['String'];
};


export type Subscription_RootLens_Custodial_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Custodial_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Custodial_Accounts_Bool_Exp>;
};


export type Subscription_RootLens_EventsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Events_Order_By>>;
  where?: InputMaybe<Lens_Events_Bool_Exp>;
};


export type Subscription_RootLens_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Events_Order_By>>;
  where?: InputMaybe<Lens_Events_Bool_Exp>;
};


export type Subscription_RootLens_Events_By_PkArgs = {
  log_index: Scalars['Int'];
  transaction_hash: Scalars['String'];
};


export type Subscription_RootLens_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Events_Bool_Exp>;
};


export type Subscription_RootLens_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_Order_By>>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};


export type Subscription_RootLens_Followers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_Order_By>>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};


export type Subscription_RootLens_Followers_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLens_Followers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Followers_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Followers_Bool_Exp>;
};


export type Subscription_RootLens_Followers_V2Args = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


export type Subscription_RootLens_Followers_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Followers_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Followers_V2_Order_By>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


export type Subscription_RootLens_Followers_V2_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLens_Followers_V2_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Followers_V2_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Followers_V2_Bool_Exp>;
};


export type Subscription_RootLens_IndexerArgs = {
  distinct_on?: InputMaybe<Array<Lens_Indexer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Indexer_Order_By>>;
  where?: InputMaybe<Lens_Indexer_Bool_Exp>;
};


export type Subscription_RootLens_Indexer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Indexer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Indexer_Order_By>>;
  where?: InputMaybe<Lens_Indexer_Bool_Exp>;
};


export type Subscription_RootLens_Indexer_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootLens_Indexer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Indexer_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Indexer_Bool_Exp>;
};


export type Subscription_RootLens_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Invites_Order_By>>;
  where?: InputMaybe<Lens_Invites_Bool_Exp>;
};


export type Subscription_RootLens_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Invites_Order_By>>;
  where?: InputMaybe<Lens_Invites_Bool_Exp>;
};


export type Subscription_RootLens_Invites_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Subscription_RootLens_Invites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Invites_Bool_Exp>;
};


export type Subscription_RootLens_Post_Lens_Pub_TypesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Order_By>>;
  where?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
};


export type Subscription_RootLens_Post_Lens_Pub_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Post_Lens_Pub_Types_Order_By>>;
  where?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
};


export type Subscription_RootLens_Post_Lens_Pub_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLens_Post_Lens_Pub_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Post_Lens_Pub_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Post_Lens_Pub_Types_Bool_Exp>;
};


export type Subscription_RootLens_PostsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


export type Subscription_RootLens_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Posts_Order_By>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


export type Subscription_RootLens_Posts_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLens_Posts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Posts_Bool_Exp>;
};


export type Subscription_RootLens_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


export type Subscription_RootLens_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Profiles_Order_By>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


export type Subscription_RootLens_Profiles_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLens_Profiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Profiles_Bool_Exp>;
};


export type Subscription_RootLens_Reaction_TypesArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reaction_Types_Order_By>>;
  where?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
};


export type Subscription_RootLens_Reaction_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reaction_Types_Order_By>>;
  where?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
};


export type Subscription_RootLens_Reaction_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLens_Reaction_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Reaction_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Reaction_Types_Bool_Exp>;
};


export type Subscription_RootLens_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


export type Subscription_RootLens_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Reactions_Order_By>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


export type Subscription_RootLens_Reactions_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLens_Reactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Reactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Reactions_Bool_Exp>;
};


export type Subscription_RootLens_Tx_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Lens_Tx_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Tx_Status_Enum_Order_By>>;
  where?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
};


export type Subscription_RootLens_Tx_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lens_Tx_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lens_Tx_Status_Enum_Order_By>>;
  where?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
};


export type Subscription_RootLens_Tx_Status_Enum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLens_Tx_Status_Enum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lens_Tx_Status_Enum_Stream_Cursor_Input>>;
  where?: InputMaybe<Lens_Tx_Status_Enum_Bool_Exp>;
};


export type Subscription_RootLevelsArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLevels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLevels_By_PkArgs = {
  level: Scalars['Int'];
};


export type Subscription_RootLevels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLinksArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


export type Subscription_RootLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Links_Order_By>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


export type Subscription_RootLinks_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLinks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Links_Bool_Exp>;
};


export type Subscription_RootLocked_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Locked_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locked_Balances_Order_By>>;
  where?: InputMaybe<Locked_Balances_Bool_Exp>;
};


export type Subscription_RootLocked_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locked_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locked_Balances_Order_By>>;
  where?: InputMaybe<Locked_Balances_Bool_Exp>;
};


export type Subscription_RootLocked_Balances_By_PkArgs = {
  accountId: Scalars['String'];
};


export type Subscription_RootLocked_Balances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Locked_Balances_Stream_Cursor_Input>>;
  where?: InputMaybe<Locked_Balances_Bool_Exp>;
};


export type Subscription_RootMatview_Communities_TrendingArgs = {
  distinct_on?: InputMaybe<Array<Matview_Communities_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Communities_Trending_Order_By>>;
  where?: InputMaybe<Matview_Communities_Trending_Bool_Exp>;
};


export type Subscription_RootMatview_Communities_Trending_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Communities_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Communities_Trending_Order_By>>;
  where?: InputMaybe<Matview_Communities_Trending_Bool_Exp>;
};


export type Subscription_RootMatview_Communities_Trending_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matview_Communities_Trending_Stream_Cursor_Input>>;
  where?: InputMaybe<Matview_Communities_Trending_Bool_Exp>;
};


export type Subscription_RootMatview_Creators_ActiveArgs = {
  distinct_on?: InputMaybe<Array<Matview_Creators_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Creators_Active_Order_By>>;
  where?: InputMaybe<Matview_Creators_Active_Bool_Exp>;
};


export type Subscription_RootMatview_Creators_Active_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Creators_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Creators_Active_Order_By>>;
  where?: InputMaybe<Matview_Creators_Active_Bool_Exp>;
};


export type Subscription_RootMatview_Creators_Active_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matview_Creators_Active_Stream_Cursor_Input>>;
  where?: InputMaybe<Matview_Creators_Active_Bool_Exp>;
};


export type Subscription_RootMatview_Post_Scores_7dArgs = {
  distinct_on?: InputMaybe<Array<Matview_Post_Scores_7d_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Post_Scores_7d_Order_By>>;
  where?: InputMaybe<Matview_Post_Scores_7d_Bool_Exp>;
};


export type Subscription_RootMatview_Post_Scores_7d_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Post_Scores_7d_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Post_Scores_7d_Order_By>>;
  where?: InputMaybe<Matview_Post_Scores_7d_Bool_Exp>;
};


export type Subscription_RootMatview_Post_Scores_7d_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matview_Post_Scores_7d_Stream_Cursor_Input>>;
  where?: InputMaybe<Matview_Post_Scores_7d_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Most_FollowArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Most_Follow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Most_Follow_LensArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Lens_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Most_Follow_Lens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Most_Follow_Lens_Order_By>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Lens_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Most_Follow_Lens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matview_Users_Most_Follow_Lens_Stream_Cursor_Input>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Lens_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Most_Follow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matview_Users_Most_Follow_Stream_Cursor_Input>>;
  where?: InputMaybe<Matview_Users_Most_Follow_Bool_Exp>;
};


export type Subscription_RootMatview_Users_TrendingArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Trending_Order_By>>;
  where?: InputMaybe<Matview_Users_Trending_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Trending_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matview_Users_Trending_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matview_Users_Trending_Order_By>>;
  where?: InputMaybe<Matview_Users_Trending_Bool_Exp>;
};


export type Subscription_RootMatview_Users_Trending_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matview_Users_Trending_Stream_Cursor_Input>>;
  where?: InputMaybe<Matview_Users_Trending_Bool_Exp>;
};


export type Subscription_RootMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


export type Subscription_RootMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


export type Subscription_RootMedias_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootMedias_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Medias_Stream_Cursor_Input>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


export type Subscription_RootMoca_IdsArgs = {
  distinct_on?: InputMaybe<Array<Moca_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Moca_Ids_Order_By>>;
  where?: InputMaybe<Moca_Ids_Bool_Exp>;
};


export type Subscription_RootMoca_Ids_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Moca_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Moca_Ids_Order_By>>;
  where?: InputMaybe<Moca_Ids_Bool_Exp>;
};


export type Subscription_RootMoca_Ids_By_PkArgs = {
  mocaId: Scalars['String'];
};


export type Subscription_RootMoca_Ids_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Moca_Ids_Stream_Cursor_Input>>;
  where?: InputMaybe<Moca_Ids_Bool_Exp>;
};


export type Subscription_RootNotification_Setting_TypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Types_Order_By>>;
  where?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
};


export type Subscription_RootNotification_Setting_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Types_Order_By>>;
  where?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
};


export type Subscription_RootNotification_Setting_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNotification_Setting_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notification_Setting_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Setting_Types_Bool_Exp>;
};


export type Subscription_RootNotification_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


export type Subscription_RootNotification_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Settings_Order_By>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


export type Subscription_RootNotification_Settings_By_PkArgs = {
  accountId: Scalars['String'];
  eventType: Notification_Setting_Types_Enum;
};


export type Subscription_RootNotification_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notification_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Settings_Bool_Exp>;
};


export type Subscription_RootPerksArgs = {
  distinct_on?: InputMaybe<Array<Perks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Order_By>>;
  where?: InputMaybe<Perks_Bool_Exp>;
};


export type Subscription_RootPerks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Perks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Order_By>>;
  where?: InputMaybe<Perks_Bool_Exp>;
};


export type Subscription_RootPerks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPerks_ClaimedArgs = {
  distinct_on?: InputMaybe<Array<Perks_Claimed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Claimed_Order_By>>;
  where?: InputMaybe<Perks_Claimed_Bool_Exp>;
};


export type Subscription_RootPerks_Claimed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Perks_Claimed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Perks_Claimed_Order_By>>;
  where?: InputMaybe<Perks_Claimed_Bool_Exp>;
};


export type Subscription_RootPerks_Claimed_By_PkArgs = {
  perkId: Scalars['Int'];
  profileId: Scalars['String'];
};


export type Subscription_RootPerks_Claimed_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Perks_Claimed_Stream_Cursor_Input>>;
  where?: InputMaybe<Perks_Claimed_Bool_Exp>;
};


export type Subscription_RootPerks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Perks_Stream_Cursor_Input>>;
  where?: InputMaybe<Perks_Bool_Exp>;
};


export type Subscription_RootPost_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Post_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Aggregates_Order_By>>;
  where?: InputMaybe<Post_Aggregates_Bool_Exp>;
};


export type Subscription_RootPost_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Aggregates_Order_By>>;
  where?: InputMaybe<Post_Aggregates_Bool_Exp>;
};


export type Subscription_RootPost_Aggregates_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootPost_Aggregates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Aggregates_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Aggregates_Bool_Exp>;
};


export type Subscription_RootPost_Profile_TagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Profile_Tags_Order_By>>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};


export type Subscription_RootPost_Profile_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Profile_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Profile_Tags_Order_By>>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};


export type Subscription_RootPost_Profile_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPost_Profile_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Profile_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Profile_Tags_Bool_Exp>;
};


export type Subscription_RootPost_Reaction_CountsArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Counts_Order_By>>;
  where?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
};


export type Subscription_RootPost_Reaction_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Counts_Order_By>>;
  where?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
};


export type Subscription_RootPost_Reaction_Counts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Reaction_Counts_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Reaction_Counts_Bool_Exp>;
};


export type Subscription_RootPost_Reaction_TypesArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Types_Order_By>>;
  where?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
};


export type Subscription_RootPost_Reaction_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reaction_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reaction_Types_Order_By>>;
  where?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
};


export type Subscription_RootPost_Reaction_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPost_Reaction_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Reaction_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Reaction_Types_Bool_Exp>;
};


export type Subscription_RootPost_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


export type Subscription_RootPost_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Reactions_Order_By>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


export type Subscription_RootPost_Reactions_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootPost_Reactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Reactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Reactions_Bool_Exp>;
};


export type Subscription_RootProfile_AggregatesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Aggregates_Order_By>>;
  where?: InputMaybe<Profile_Aggregates_Bool_Exp>;
};


export type Subscription_RootProfile_Aggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Aggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Aggregates_Order_By>>;
  where?: InputMaybe<Profile_Aggregates_Bool_Exp>;
};


export type Subscription_RootProfile_Aggregates_By_PkArgs = {
  profileId: Scalars['String'];
};


export type Subscription_RootProfile_Aggregates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Profile_Aggregates_Stream_Cursor_Input>>;
  where?: InputMaybe<Profile_Aggregates_Bool_Exp>;
};


export type Subscription_RootProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootProfiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootPush_TokensArgs = {
  distinct_on?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Push_Tokens_Order_By>>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};


export type Subscription_RootPush_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Push_Tokens_Order_By>>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};


export type Subscription_RootPush_Tokens_By_PkArgs = {
  accountId: Scalars['String'];
  expoPushToken: Scalars['String'];
};


export type Subscription_RootPush_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Push_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Push_Tokens_Bool_Exp>;
};


export type Subscription_RootRecommendation_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


export type Subscription_RootRecommendation_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Comments_Order_By>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


export type Subscription_RootRecommendation_Comments_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRecommendation_Comments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Recommendation_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Recommendation_Comments_Bool_Exp>;
};


export type Subscription_RootRecommendation_VotesArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};


export type Subscription_RootRecommendation_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Votes_Order_By>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};


export type Subscription_RootRecommendation_Votes_By_PkArgs = {
  profileId: Scalars['String'];
  recommendationId: Scalars['String'];
};


export type Subscription_RootRecommendation_Votes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Recommendation_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Recommendation_Votes_Bool_Exp>;
};


export type Subscription_RootRecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


export type Subscription_RootRecommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendations_Order_By>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


export type Subscription_RootRecommendations_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRecommendations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Recommendations_Stream_Cursor_Input>>;
  where?: InputMaybe<Recommendations_Bool_Exp>;
};


export type Subscription_RootReported_ContentArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


export type Subscription_RootReported_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reported_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reported_Content_Order_By>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


export type Subscription_RootReported_Content_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootReported_Content_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reported_Content_Stream_Cursor_Input>>;
  where?: InputMaybe<Reported_Content_Bool_Exp>;
};


export type Subscription_RootReward_BrandsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


export type Subscription_RootReward_Brands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Brands_Order_By>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


export type Subscription_RootReward_Brands_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootReward_Brands_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reward_Brands_Stream_Cursor_Input>>;
  where?: InputMaybe<Reward_Brands_Bool_Exp>;
};


export type Subscription_RootReward_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


export type Subscription_RootReward_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Claims_Order_By>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


export type Subscription_RootReward_Claims_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootReward_Claims_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reward_Claims_Stream_Cursor_Input>>;
  where?: InputMaybe<Reward_Claims_Bool_Exp>;
};


export type Subscription_RootReward_TypesArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


export type Subscription_RootReward_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reward_Types_Order_By>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


export type Subscription_RootReward_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootReward_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reward_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Reward_Types_Bool_Exp>;
};


export type Subscription_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootRole_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRss_FeedsArgs = {
  distinct_on?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rss_Feeds_Order_By>>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};


export type Subscription_RootRss_Feeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rss_Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rss_Feeds_Order_By>>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};


export type Subscription_RootRss_Feeds_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRss_Feeds_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rss_Feeds_Stream_Cursor_Input>>;
  where?: InputMaybe<Rss_Feeds_Bool_Exp>;
};


export type Subscription_RootStaking_Reward_PostersArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Posters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Posters_Order_By>>;
  where?: InputMaybe<Staking_Reward_Posters_Bool_Exp>;
};


export type Subscription_RootStaking_Reward_Posters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Posters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Posters_Order_By>>;
  where?: InputMaybe<Staking_Reward_Posters_Bool_Exp>;
};


export type Subscription_RootStaking_Reward_Posters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStaking_Reward_Posters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staking_Reward_Posters_Stream_Cursor_Input>>;
  where?: InputMaybe<Staking_Reward_Posters_Bool_Exp>;
};


export type Subscription_RootStaking_Reward_StakersArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Stakers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Stakers_Order_By>>;
  where?: InputMaybe<Staking_Reward_Stakers_Bool_Exp>;
};


export type Subscription_RootStaking_Reward_Stakers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staking_Reward_Stakers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Reward_Stakers_Order_By>>;
  where?: InputMaybe<Staking_Reward_Stakers_Bool_Exp>;
};


export type Subscription_RootStaking_Reward_Stakers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStaking_Reward_Stakers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staking_Reward_Stakers_Stream_Cursor_Input>>;
  where?: InputMaybe<Staking_Reward_Stakers_Bool_Exp>;
};


export type Subscription_RootSubdivisionsArgs = {
  distinct_on?: InputMaybe<Array<Subdivisions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subdivisions_Order_By>>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};


export type Subscription_RootSubdivisions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subdivisions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subdivisions_Order_By>>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};


export type Subscription_RootSubdivisions_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootSubdivisions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Subdivisions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subdivisions_Bool_Exp>;
};


export type Subscription_RootTask_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sections_Order_By>>;
  where?: InputMaybe<Task_Sections_Bool_Exp>;
};


export type Subscription_RootTask_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sections_Order_By>>;
  where?: InputMaybe<Task_Sections_Bool_Exp>;
};


export type Subscription_RootTask_Sections_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTask_Sections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Sections_Bool_Exp>;
};


export type Subscription_RootTask_TypesArgs = {
  distinct_on?: InputMaybe<Array<Task_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Types_Order_By>>;
  where?: InputMaybe<Task_Types_Bool_Exp>;
};


export type Subscription_RootTask_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Types_Order_By>>;
  where?: InputMaybe<Task_Types_Bool_Exp>;
};


export type Subscription_RootTask_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTask_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Types_Bool_Exp>;
};


export type Subscription_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_By_PkArgs = {
  taskType: Task_Types_Enum;
};


export type Subscription_RootTasks_DoneArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Done_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Done_Order_By>>;
  where?: InputMaybe<Tasks_Done_Bool_Exp>;
};


export type Subscription_RootTasks_Done_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Done_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Done_Order_By>>;
  where?: InputMaybe<Tasks_Done_Bool_Exp>;
};


export type Subscription_RootTasks_Done_By_PkArgs = {
  profileId: Scalars['String'];
  taskType: Scalars['String'];
};


export type Subscription_RootTasks_Done_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Done_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Done_Bool_Exp>;
};


export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Subscription_RootVerification_TypesArgs = {
  distinct_on?: InputMaybe<Array<Verification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Types_Order_By>>;
  where?: InputMaybe<Verification_Types_Bool_Exp>;
};


export type Subscription_RootVerification_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Types_Order_By>>;
  where?: InputMaybe<Verification_Types_Bool_Exp>;
};


export type Subscription_RootVerification_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootVerification_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Verification_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Verification_Types_Bool_Exp>;
};


export type Subscription_RootVerified_Nft_Project_KindsArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Project_Kinds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Project_Kinds_Order_By>>;
  where?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
};


export type Subscription_RootVerified_Nft_Project_Kinds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Project_Kinds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Project_Kinds_Order_By>>;
  where?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
};


export type Subscription_RootVerified_Nft_Project_Kinds_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootVerified_Nft_Project_Kinds_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Verified_Nft_Project_Kinds_Stream_Cursor_Input>>;
  where?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
};


export type Subscription_RootVerified_Nft_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};


export type Subscription_RootVerified_Nft_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};


export type Subscription_RootVerified_Nft_Projects_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVerified_Nft_Projects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Verified_Nft_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};


export type Subscription_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootVideos_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootWalletsArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Subscription_RootWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Subscription_RootWallets_By_PkArgs = {
  walletAddress: Scalars['String'];
};


export type Subscription_RootWallets_ResolvesArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Resolves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Resolves_Order_By>>;
  where?: InputMaybe<Wallets_Resolves_Bool_Exp>;
};


export type Subscription_RootWallets_Resolves_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Resolves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Resolves_Order_By>>;
  where?: InputMaybe<Wallets_Resolves_Bool_Exp>;
};


export type Subscription_RootWallets_Resolves_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWallets_Resolves_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallets_Resolves_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallets_Resolves_Bool_Exp>;
};


export type Subscription_RootWallets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallets_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Subscription_RootYield_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Yield_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yield_Sources_Order_By>>;
  where?: InputMaybe<Yield_Sources_Bool_Exp>;
};


export type Subscription_RootYield_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yield_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yield_Sources_Order_By>>;
  where?: InputMaybe<Yield_Sources_Bool_Exp>;
};


export type Subscription_RootYield_Sources_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootYield_Sources_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Yield_Sources_Stream_Cursor_Input>>;
  where?: InputMaybe<Yield_Sources_Bool_Exp>;
};

export type SyncUserNfTsOutput = {
  __typename?: 'syncUserNFTsOutput';
  added?: Maybe<Scalars['Int']>;
  disconnected?: Maybe<Scalars['Int']>;
  disconnectedPreviousOwners?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updated?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "task_sections" */
export type Task_Sections = {
  __typename?: 'task_sections';
  value: Scalars['String'];
};

/** aggregated selection of "task_sections" */
export type Task_Sections_Aggregate = {
  __typename?: 'task_sections_aggregate';
  aggregate?: Maybe<Task_Sections_Aggregate_Fields>;
  nodes: Array<Task_Sections>;
};

/** aggregate fields of "task_sections" */
export type Task_Sections_Aggregate_Fields = {
  __typename?: 'task_sections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Sections_Max_Fields>;
  min?: Maybe<Task_Sections_Min_Fields>;
};


/** aggregate fields of "task_sections" */
export type Task_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_sections". All fields are combined with a logical 'AND'. */
export type Task_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Sections_Bool_Exp>>;
  _not?: InputMaybe<Task_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Sections_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_sections" */
export enum Task_Sections_Constraint {
  /** unique or primary key constraint on columns "value" */
  TaskSectionsPkey = 'task_sections_pkey'
}

export enum Task_Sections_Enum {
  BuildUpYourProfile = 'buildUpYourProfile',
  GetConnected = 'getConnected',
  KeepPhavering = 'keepPhavering',
  StartPhavingAround = 'startPhavingAround'
}

/** Boolean expression to compare columns of type "task_sections_enum". All fields are combined with logical 'AND'. */
export type Task_Sections_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Task_Sections_Enum>;
  _in?: InputMaybe<Array<Task_Sections_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Task_Sections_Enum>;
  _nin?: InputMaybe<Array<Task_Sections_Enum>>;
};

/** input type for inserting data into table "task_sections" */
export type Task_Sections_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Sections_Max_Fields = {
  __typename?: 'task_sections_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Sections_Min_Fields = {
  __typename?: 'task_sections_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_sections" */
export type Task_Sections_Mutation_Response = {
  __typename?: 'task_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Sections>;
};

/** on_conflict condition type for table "task_sections" */
export type Task_Sections_On_Conflict = {
  constraint: Task_Sections_Constraint;
  update_columns?: Array<Task_Sections_Update_Column>;
  where?: InputMaybe<Task_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "task_sections". */
export type Task_Sections_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_sections */
export type Task_Sections_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "task_sections" */
export enum Task_Sections_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "task_sections" */
export type Task_Sections_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_sections" */
export type Task_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Sections_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "task_sections" */
export enum Task_Sections_Update_Column {
  /** column name */
  Value = 'value'
}

export type Task_Sections_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Sections_Bool_Exp;
};

/** columns and relationships of "task_types" */
export type Task_Types = {
  __typename?: 'task_types';
  value: Scalars['String'];
};

/** aggregated selection of "task_types" */
export type Task_Types_Aggregate = {
  __typename?: 'task_types_aggregate';
  aggregate?: Maybe<Task_Types_Aggregate_Fields>;
  nodes: Array<Task_Types>;
};

/** aggregate fields of "task_types" */
export type Task_Types_Aggregate_Fields = {
  __typename?: 'task_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Types_Max_Fields>;
  min?: Maybe<Task_Types_Min_Fields>;
};


/** aggregate fields of "task_types" */
export type Task_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_types". All fields are combined with a logical 'AND'. */
export type Task_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Types_Bool_Exp>>;
  _not?: InputMaybe<Task_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Types_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_types" */
export enum Task_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  TaskTypesPkey = 'task_types_pkey'
}

export enum Task_Types_Enum {
  AddABioText = 'addABioText',
  AddYourOtherSocials = 'addYourOtherSocials',
  ConfirmYourLocation = 'confirmYourLocation',
  ConnectALensProfile = 'connectALensProfile',
  ConnectAMocaId = 'connectAMocaID',
  ConnectFarcaster = 'connectFarcaster',
  ConnectYourFirstNft = 'connectYourFirstNFT',
  ConnectYourFirstWallet = 'connectYourFirstWallet',
  FindTheFirst5AccountsToFollow = 'findTheFirst5AccountsToFollow',
  GiveYourFirstPhave = 'giveYourFirstPhave',
  JoinYourFirstCommunity = 'joinYourFirstCommunity',
  MakeYourFirstComment = 'makeYourFirstComment',
  MakeYourFirstPost = 'makeYourFirstPost',
  MakeYourFirstPremiumPost = 'makeYourFirstPremiumPost',
  ProveYouAreAHumanWithAnima = 'proveYouAreAHumanWithAnima',
  SendYourFirstChatMessage = 'sendYourFirstChatMessage',
  SetAnNftProfilePicture = 'setAnNFTProfilePicture',
  SetUpMessagingWithXmtp = 'setUpMessagingWithXMTP'
}

/** Boolean expression to compare columns of type "task_types_enum". All fields are combined with logical 'AND'. */
export type Task_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Task_Types_Enum>;
  _in?: InputMaybe<Array<Task_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Task_Types_Enum>;
  _nin?: InputMaybe<Array<Task_Types_Enum>>;
};

/** input type for inserting data into table "task_types" */
export type Task_Types_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Types_Max_Fields = {
  __typename?: 'task_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Types_Min_Fields = {
  __typename?: 'task_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_types" */
export type Task_Types_Mutation_Response = {
  __typename?: 'task_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Types>;
};

/** on_conflict condition type for table "task_types" */
export type Task_Types_On_Conflict = {
  constraint: Task_Types_Constraint;
  update_columns?: Array<Task_Types_Update_Column>;
  where?: InputMaybe<Task_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "task_types". */
export type Task_Types_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_types */
export type Task_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "task_types" */
export enum Task_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "task_types" */
export type Task_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_types" */
export type Task_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "task_types" */
export enum Task_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Task_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Types_Bool_Exp;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: 'tasks';
  callableByMobile: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  isEnabled: Scalars['Boolean'];
  points: Scalars['Int'];
  sorting: Scalars['Int'];
  taskSection: Task_Sections_Enum;
  taskType: Task_Types_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  avg?: Maybe<Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
  stddev?: Maybe<Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Sum_Fields>;
  var_pop?: Maybe<Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Variance_Fields>;
};


/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tasks_Avg_Fields = {
  __typename?: 'tasks_avg_fields';
  points?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Bool_Exp>>;
  callableByMobile?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  points?: InputMaybe<Int_Comparison_Exp>;
  sorting?: InputMaybe<Int_Comparison_Exp>;
  taskSection?: InputMaybe<Task_Sections_Enum_Comparison_Exp>;
  taskType?: InputMaybe<Task_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "task_type" */
  TasksPkey = 'tasks_pkey'
}

/** columns and relationships of "tasks_done" */
export type Tasks_Done = {
  __typename?: 'tasks_done';
  createdAt: Scalars['timestamptz'];
  pointsEarned: Scalars['Int'];
  profileId: Scalars['String'];
  taskType: Scalars['String'];
};

/** aggregated selection of "tasks_done" */
export type Tasks_Done_Aggregate = {
  __typename?: 'tasks_done_aggregate';
  aggregate?: Maybe<Tasks_Done_Aggregate_Fields>;
  nodes: Array<Tasks_Done>;
};

/** aggregate fields of "tasks_done" */
export type Tasks_Done_Aggregate_Fields = {
  __typename?: 'tasks_done_aggregate_fields';
  avg?: Maybe<Tasks_Done_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Done_Max_Fields>;
  min?: Maybe<Tasks_Done_Min_Fields>;
  stddev?: Maybe<Tasks_Done_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Done_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Done_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Done_Sum_Fields>;
  var_pop?: Maybe<Tasks_Done_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Done_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Done_Variance_Fields>;
};


/** aggregate fields of "tasks_done" */
export type Tasks_Done_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Done_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tasks_Done_Avg_Fields = {
  __typename?: 'tasks_done_avg_fields';
  pointsEarned?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tasks_done". All fields are combined with a logical 'AND'. */
export type Tasks_Done_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Done_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Done_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Done_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  pointsEarned?: InputMaybe<Int_Comparison_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  taskType?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_done" */
export enum Tasks_Done_Constraint {
  /** unique or primary key constraint on columns "profile_id", "task_type" */
  TasksDonePkey = 'tasks_done_pkey'
}

/** input type for incrementing numeric columns in table "tasks_done" */
export type Tasks_Done_Inc_Input = {
  pointsEarned?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tasks_done" */
export type Tasks_Done_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  pointsEarned?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  taskType?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tasks_Done_Max_Fields = {
  __typename?: 'tasks_done_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  pointsEarned?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tasks_Done_Min_Fields = {
  __typename?: 'tasks_done_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  pointsEarned?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tasks_done" */
export type Tasks_Done_Mutation_Response = {
  __typename?: 'tasks_done_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Done>;
};

/** on_conflict condition type for table "tasks_done" */
export type Tasks_Done_On_Conflict = {
  constraint: Tasks_Done_Constraint;
  update_columns?: Array<Tasks_Done_Update_Column>;
  where?: InputMaybe<Tasks_Done_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_done". */
export type Tasks_Done_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  pointsEarned?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  taskType?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_done */
export type Tasks_Done_Pk_Columns_Input = {
  profileId: Scalars['String'];
  taskType: Scalars['String'];
};

/** select columns of table "tasks_done" */
export enum Tasks_Done_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PointsEarned = 'pointsEarned',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  TaskType = 'taskType'
}

/** input type for updating data in table "tasks_done" */
export type Tasks_Done_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  pointsEarned?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  taskType?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tasks_Done_Stddev_Fields = {
  __typename?: 'tasks_done_stddev_fields';
  pointsEarned?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Done_Stddev_Pop_Fields = {
  __typename?: 'tasks_done_stddev_pop_fields';
  pointsEarned?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Done_Stddev_Samp_Fields = {
  __typename?: 'tasks_done_stddev_samp_fields';
  pointsEarned?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tasks_done" */
export type Tasks_Done_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Done_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Done_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  pointsEarned?: InputMaybe<Scalars['Int']>;
  profileId?: InputMaybe<Scalars['String']>;
  taskType?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tasks_Done_Sum_Fields = {
  __typename?: 'tasks_done_sum_fields';
  pointsEarned?: Maybe<Scalars['Int']>;
};

/** update columns of table "tasks_done" */
export enum Tasks_Done_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PointsEarned = 'pointsEarned',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  TaskType = 'taskType'
}

export type Tasks_Done_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Done_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Done_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Done_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Done_Var_Pop_Fields = {
  __typename?: 'tasks_done_var_pop_fields';
  pointsEarned?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tasks_Done_Var_Samp_Fields = {
  __typename?: 'tasks_done_var_samp_fields';
  pointsEarned?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tasks_Done_Variance_Fields = {
  __typename?: 'tasks_done_variance_fields';
  pointsEarned?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "tasks" */
export type Tasks_Inc_Input = {
  points?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  callableByMobile?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<Scalars['Int']>;
  taskSection?: InputMaybe<Task_Sections_Enum>;
  taskType?: InputMaybe<Task_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  points?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  points?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  callableByMobile?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  isEnabled?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  sorting?: InputMaybe<Order_By>;
  taskSection?: InputMaybe<Order_By>;
  taskType?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  taskType: Task_Types_Enum;
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  CallableByMobile = 'callableByMobile',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  Points = 'points',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  TaskSection = 'taskSection',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  callableByMobile?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<Scalars['Int']>;
  taskSection?: InputMaybe<Task_Sections_Enum>;
  taskType?: InputMaybe<Task_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Stddev_Fields = {
  __typename?: 'tasks_stddev_fields';
  points?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Stddev_Pop_Fields = {
  __typename?: 'tasks_stddev_pop_fields';
  points?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Stddev_Samp_Fields = {
  __typename?: 'tasks_stddev_samp_fields';
  points?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  callableByMobile?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<Scalars['Int']>;
  taskSection?: InputMaybe<Task_Sections_Enum>;
  taskType?: InputMaybe<Task_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Sum_Fields = {
  __typename?: 'tasks_sum_fields';
  points?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Scalars['Int']>;
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  CallableByMobile = 'callableByMobile',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  Points = 'points',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  TaskSection = 'taskSection',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Var_Pop_Fields = {
  __typename?: 'tasks_var_pop_fields';
  points?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tasks_Var_Samp_Fields = {
  __typename?: 'tasks_var_samp_fields';
  points?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tasks_Variance_Fields = {
  __typename?: 'tasks_variance_fields';
  points?: Maybe<Scalars['Float']>;
  sorting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type UpdatePostResult = {
  __typename?: 'updatePostResult';
  postId: Scalars['String'];
  status: Scalars['String'];
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "verification_types" */
export type Verification_Types = {
  __typename?: 'verification_types';
  value: Scalars['String'];
};

/** aggregated selection of "verification_types" */
export type Verification_Types_Aggregate = {
  __typename?: 'verification_types_aggregate';
  aggregate?: Maybe<Verification_Types_Aggregate_Fields>;
  nodes: Array<Verification_Types>;
};

/** aggregate fields of "verification_types" */
export type Verification_Types_Aggregate_Fields = {
  __typename?: 'verification_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Verification_Types_Max_Fields>;
  min?: Maybe<Verification_Types_Min_Fields>;
};


/** aggregate fields of "verification_types" */
export type Verification_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verification_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "verification_types". All fields are combined with a logical 'AND'. */
export type Verification_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Verification_Types_Bool_Exp>>;
  _not?: InputMaybe<Verification_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Verification_Types_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "verification_types" */
export enum Verification_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  VerificationTypesPkey = 'verification_types_pkey'
}

export enum Verification_Types_Enum {
  Creator = 'creator',
  Organization = 'organization',
  TeamMember = 'team_member',
  User = 'user'
}

/** Boolean expression to compare columns of type "verification_types_enum". All fields are combined with logical 'AND'. */
export type Verification_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Verification_Types_Enum>;
  _in?: InputMaybe<Array<Verification_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Verification_Types_Enum>;
  _nin?: InputMaybe<Array<Verification_Types_Enum>>;
};

/** input type for inserting data into table "verification_types" */
export type Verification_Types_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Verification_Types_Max_Fields = {
  __typename?: 'verification_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Verification_Types_Min_Fields = {
  __typename?: 'verification_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "verification_types" */
export type Verification_Types_Mutation_Response = {
  __typename?: 'verification_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Verification_Types>;
};

/** on_conflict condition type for table "verification_types" */
export type Verification_Types_On_Conflict = {
  constraint: Verification_Types_Constraint;
  update_columns?: Array<Verification_Types_Update_Column>;
  where?: InputMaybe<Verification_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "verification_types". */
export type Verification_Types_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: verification_types */
export type Verification_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "verification_types" */
export enum Verification_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "verification_types" */
export type Verification_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "verification_types" */
export type Verification_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verification_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verification_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "verification_types" */
export enum Verification_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Verification_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verification_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verification_Types_Bool_Exp;
};

/** columns and relationships of "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds = {
  __typename?: 'verified_nft_project_kinds';
  value: Scalars['String'];
  /** An array relationship */
  verified_nft_projects: Array<Verified_Nft_Projects>;
  /** An aggregate relationship */
  verified_nft_projects_aggregate: Verified_Nft_Projects_Aggregate;
};


/** columns and relationships of "verified_nft_project_kinds" */
export type Verified_Nft_Project_KindsVerified_Nft_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};


/** columns and relationships of "verified_nft_project_kinds" */
export type Verified_Nft_Project_KindsVerified_Nft_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verified_Nft_Projects_Order_By>>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};

/** aggregated selection of "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Aggregate = {
  __typename?: 'verified_nft_project_kinds_aggregate';
  aggregate?: Maybe<Verified_Nft_Project_Kinds_Aggregate_Fields>;
  nodes: Array<Verified_Nft_Project_Kinds>;
};

/** aggregate fields of "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Aggregate_Fields = {
  __typename?: 'verified_nft_project_kinds_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Verified_Nft_Project_Kinds_Max_Fields>;
  min?: Maybe<Verified_Nft_Project_Kinds_Min_Fields>;
};


/** aggregate fields of "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verified_Nft_Project_Kinds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "verified_nft_project_kinds". All fields are combined with a logical 'AND'. */
export type Verified_Nft_Project_Kinds_Bool_Exp = {
  _and?: InputMaybe<Array<Verified_Nft_Project_Kinds_Bool_Exp>>;
  _not?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
  _or?: InputMaybe<Array<Verified_Nft_Project_Kinds_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
  verified_nft_projects?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  verified_nft_projects_aggregate?: InputMaybe<Verified_Nft_Projects_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "verified_nft_project_kinds" */
export enum Verified_Nft_Project_Kinds_Constraint {
  /** unique or primary key constraint on columns "value" */
  VerifiedNftProjectKindsPkey = 'verified_nft_project_kinds_pkey'
}

export enum Verified_Nft_Project_Kinds_Enum {
  Achievement = 'achievement',
  Credential = 'credential',
  Pfp = 'pfp'
}

/** Boolean expression to compare columns of type "verified_nft_project_kinds_enum". All fields are combined with logical 'AND'. */
export type Verified_Nft_Project_Kinds_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  _in?: InputMaybe<Array<Verified_Nft_Project_Kinds_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  _nin?: InputMaybe<Array<Verified_Nft_Project_Kinds_Enum>>;
};

/** input type for inserting data into table "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
  verified_nft_projects?: InputMaybe<Verified_Nft_Projects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Verified_Nft_Project_Kinds_Max_Fields = {
  __typename?: 'verified_nft_project_kinds_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Verified_Nft_Project_Kinds_Min_Fields = {
  __typename?: 'verified_nft_project_kinds_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Mutation_Response = {
  __typename?: 'verified_nft_project_kinds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Verified_Nft_Project_Kinds>;
};

/** input type for inserting object relation for remote table "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Obj_Rel_Insert_Input = {
  data: Verified_Nft_Project_Kinds_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Verified_Nft_Project_Kinds_On_Conflict>;
};

/** on_conflict condition type for table "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_On_Conflict = {
  constraint: Verified_Nft_Project_Kinds_Constraint;
  update_columns?: Array<Verified_Nft_Project_Kinds_Update_Column>;
  where?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
};

/** Ordering options when selecting data from "verified_nft_project_kinds". */
export type Verified_Nft_Project_Kinds_Order_By = {
  value?: InputMaybe<Order_By>;
  verified_nft_projects_aggregate?: InputMaybe<Verified_Nft_Projects_Aggregate_Order_By>;
};

/** primary key columns input for table: verified_nft_project_kinds */
export type Verified_Nft_Project_Kinds_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "verified_nft_project_kinds" */
export enum Verified_Nft_Project_Kinds_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "verified_nft_project_kinds" */
export type Verified_Nft_Project_Kinds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verified_Nft_Project_Kinds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verified_Nft_Project_Kinds_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "verified_nft_project_kinds" */
export enum Verified_Nft_Project_Kinds_Update_Column {
  /** column name */
  Value = 'value'
}

export type Verified_Nft_Project_Kinds_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verified_Nft_Project_Kinds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verified_Nft_Project_Kinds_Bool_Exp;
};

/** columns and relationships of "verified_nft_projects" */
export type Verified_Nft_Projects = {
  __typename?: 'verified_nft_projects';
  chainId: Scalars['Int'];
  /** An array relationship */
  connectedNfts: Array<Connected_Nfts>;
  /** An aggregate relationship */
  connectedNfts_aggregate: Connected_Nfts_Aggregate;
  contractAddress: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYieldRatio: Scalars['Float'];
  cred_yield: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  kind: Verified_Nft_Project_Kinds_Enum;
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pointsYieldRatio: Scalars['Float'];
  points_yield: Scalars['Int'];
  sbt: Scalars['Boolean'];
  token_id?: Maybe<Scalars['String']>;
  traitType?: Maybe<Scalars['String']>;
  traitValue?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  verified_nft_project_kind: Verified_Nft_Project_Kinds;
};


/** columns and relationships of "verified_nft_projects" */
export type Verified_Nft_ProjectsConnectedNftsArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


/** columns and relationships of "verified_nft_projects" */
export type Verified_Nft_ProjectsConnectedNfts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};

/** aggregated selection of "verified_nft_projects" */
export type Verified_Nft_Projects_Aggregate = {
  __typename?: 'verified_nft_projects_aggregate';
  aggregate?: Maybe<Verified_Nft_Projects_Aggregate_Fields>;
  nodes: Array<Verified_Nft_Projects>;
};

export type Verified_Nft_Projects_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Verified_Nft_Projects_Aggregate_Bool_Exp_Count>;
};

export type Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_And = {
  arguments: Verified_Nft_Projects_Select_Column_Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Verified_Nft_Projects_Select_Column_Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Verified_Nft_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "verified_nft_projects" */
export type Verified_Nft_Projects_Aggregate_Fields = {
  __typename?: 'verified_nft_projects_aggregate_fields';
  avg?: Maybe<Verified_Nft_Projects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Verified_Nft_Projects_Max_Fields>;
  min?: Maybe<Verified_Nft_Projects_Min_Fields>;
  stddev?: Maybe<Verified_Nft_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Verified_Nft_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Verified_Nft_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Verified_Nft_Projects_Sum_Fields>;
  var_pop?: Maybe<Verified_Nft_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Verified_Nft_Projects_Var_Samp_Fields>;
  variance?: Maybe<Verified_Nft_Projects_Variance_Fields>;
};


/** aggregate fields of "verified_nft_projects" */
export type Verified_Nft_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verified_Nft_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "verified_nft_projects" */
export type Verified_Nft_Projects_Aggregate_Order_By = {
  avg?: InputMaybe<Verified_Nft_Projects_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Verified_Nft_Projects_Max_Order_By>;
  min?: InputMaybe<Verified_Nft_Projects_Min_Order_By>;
  stddev?: InputMaybe<Verified_Nft_Projects_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Verified_Nft_Projects_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Verified_Nft_Projects_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Verified_Nft_Projects_Sum_Order_By>;
  var_pop?: InputMaybe<Verified_Nft_Projects_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Verified_Nft_Projects_Var_Samp_Order_By>;
  variance?: InputMaybe<Verified_Nft_Projects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "verified_nft_projects" */
export type Verified_Nft_Projects_Arr_Rel_Insert_Input = {
  data: Array<Verified_Nft_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Verified_Nft_Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Verified_Nft_Projects_Avg_Fields = {
  __typename?: 'verified_nft_projects_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Avg_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "verified_nft_projects". All fields are combined with a logical 'AND'. */
export type Verified_Nft_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Verified_Nft_Projects_Bool_Exp>>;
  _not?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Verified_Nft_Projects_Bool_Exp>>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  connectedNfts?: InputMaybe<Connected_Nfts_Bool_Exp>;
  connectedNfts_aggregate?: InputMaybe<Connected_Nfts_Aggregate_Bool_Exp>;
  contractAddress?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credYieldRatio?: InputMaybe<Float_Comparison_Exp>;
  cred_yield?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pointsYieldRatio?: InputMaybe<Float_Comparison_Exp>;
  points_yield?: InputMaybe<Int_Comparison_Exp>;
  sbt?: InputMaybe<Boolean_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  traitType?: InputMaybe<String_Comparison_Exp>;
  traitValue?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  verified_nft_project_kind?: InputMaybe<Verified_Nft_Project_Kinds_Bool_Exp>;
};

/** unique or primary key constraints on table "verified_nft_projects" */
export enum Verified_Nft_Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  VerifiedNftProjectsPkey = 'verified_nft_projects_pkey',
  /** unique or primary key constraint on columns "chain_id", "contract_address" */
  VerifiedNftProjectsUnique = 'verified_nft_projects_unique'
}

/** input type for incrementing numeric columns in table "verified_nft_projects" */
export type Verified_Nft_Projects_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  cred_yield?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  points_yield?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "verified_nft_projects" */
export type Verified_Nft_Projects_Insert_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  connectedNfts?: InputMaybe<Connected_Nfts_Arr_Rel_Insert_Input>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  cred_yield?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  points_yield?: InputMaybe<Scalars['Int']>;
  sbt?: InputMaybe<Scalars['Boolean']>;
  token_id?: InputMaybe<Scalars['String']>;
  traitType?: InputMaybe<Scalars['String']>;
  traitValue?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verified_nft_project_kind?: InputMaybe<Verified_Nft_Project_Kinds_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Verified_Nft_Projects_Max_Fields = {
  __typename?: 'verified_nft_projects_max_fields';
  chainId?: Maybe<Scalars['Int']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  imageId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Int']>;
  token_id?: Maybe<Scalars['String']>;
  traitType?: Maybe<Scalars['String']>;
  traitValue?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Max_Order_By = {
  chainId?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  traitType?: InputMaybe<Order_By>;
  traitValue?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Verified_Nft_Projects_Min_Fields = {
  __typename?: 'verified_nft_projects_min_fields';
  chainId?: Maybe<Scalars['Int']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  imageId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Int']>;
  token_id?: Maybe<Scalars['String']>;
  traitType?: Maybe<Scalars['String']>;
  traitValue?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Min_Order_By = {
  chainId?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageId?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  traitType?: InputMaybe<Order_By>;
  traitValue?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "verified_nft_projects" */
export type Verified_Nft_Projects_Mutation_Response = {
  __typename?: 'verified_nft_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Verified_Nft_Projects>;
};

/** input type for inserting object relation for remote table "verified_nft_projects" */
export type Verified_Nft_Projects_Obj_Rel_Insert_Input = {
  data: Verified_Nft_Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Verified_Nft_Projects_On_Conflict>;
};

/** on_conflict condition type for table "verified_nft_projects" */
export type Verified_Nft_Projects_On_Conflict = {
  constraint: Verified_Nft_Projects_Constraint;
  update_columns?: Array<Verified_Nft_Projects_Update_Column>;
  where?: InputMaybe<Verified_Nft_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "verified_nft_projects". */
export type Verified_Nft_Projects_Order_By = {
  chainId?: InputMaybe<Order_By>;
  connectedNfts_aggregate?: InputMaybe<Connected_Nfts_Aggregate_Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
  sbt?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  traitType?: InputMaybe<Order_By>;
  traitValue?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  verified_nft_project_kind?: InputMaybe<Verified_Nft_Project_Kinds_Order_By>;
};

/** primary key columns input for table: verified_nft_projects */
export type Verified_Nft_Projects_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "verified_nft_projects" */
export enum Verified_Nft_Projects_Select_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredYieldRatio = 'credYieldRatio',
  /** column name */
  CredYield = 'cred_yield',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Kind = 'kind',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  PointsYieldRatio = 'pointsYieldRatio',
  /** column name */
  PointsYield = 'points_yield',
  /** column name */
  Sbt = 'sbt',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TraitType = 'traitType',
  /** column name */
  TraitValue = 'traitValue',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "verified_nft_projects_aggregate_bool_exp_bool_and_arguments_columns" columns of table "verified_nft_projects" */
export enum Verified_Nft_Projects_Select_Column_Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Sbt = 'sbt'
}

/** select "verified_nft_projects_aggregate_bool_exp_bool_or_arguments_columns" columns of table "verified_nft_projects" */
export enum Verified_Nft_Projects_Select_Column_Verified_Nft_Projects_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Sbt = 'sbt'
}

/** input type for updating data in table "verified_nft_projects" */
export type Verified_Nft_Projects_Set_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  cred_yield?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  points_yield?: InputMaybe<Scalars['Int']>;
  sbt?: InputMaybe<Scalars['Boolean']>;
  token_id?: InputMaybe<Scalars['String']>;
  traitType?: InputMaybe<Scalars['String']>;
  traitValue?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Verified_Nft_Projects_Stddev_Fields = {
  __typename?: 'verified_nft_projects_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Stddev_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Verified_Nft_Projects_Stddev_Pop_Fields = {
  __typename?: 'verified_nft_projects_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Stddev_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Verified_Nft_Projects_Stddev_Samp_Fields = {
  __typename?: 'verified_nft_projects_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Stddev_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "verified_nft_projects" */
export type Verified_Nft_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verified_Nft_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verified_Nft_Projects_Stream_Cursor_Value_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  cred_yield?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  points_yield?: InputMaybe<Scalars['Int']>;
  sbt?: InputMaybe<Scalars['Boolean']>;
  token_id?: InputMaybe<Scalars['String']>;
  traitType?: InputMaybe<Scalars['String']>;
  traitValue?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Verified_Nft_Projects_Sum_Fields = {
  __typename?: 'verified_nft_projects_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Sum_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** update columns of table "verified_nft_projects" */
export enum Verified_Nft_Projects_Update_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredYieldRatio = 'credYieldRatio',
  /** column name */
  CredYield = 'cred_yield',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Kind = 'kind',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  PointsYieldRatio = 'pointsYieldRatio',
  /** column name */
  PointsYield = 'points_yield',
  /** column name */
  Sbt = 'sbt',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TraitType = 'traitType',
  /** column name */
  TraitValue = 'traitValue',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Verified_Nft_Projects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Verified_Nft_Projects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verified_Nft_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verified_Nft_Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Verified_Nft_Projects_Var_Pop_Fields = {
  __typename?: 'verified_nft_projects_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Var_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Verified_Nft_Projects_Var_Samp_Fields = {
  __typename?: 'verified_nft_projects_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Var_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Verified_Nft_Projects_Variance_Fields = {
  __typename?: 'verified_nft_projects_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  cred_yield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  points_yield?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "verified_nft_projects" */
export type Verified_Nft_Projects_Variance_Order_By = {
  chainId?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  cred_yield?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  points_yield?: InputMaybe<Order_By>;
};

/** columns and relationships of "videos" */
export type Videos = {
  __typename?: 'videos';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dash: Scalars['String'];
  downloadUrl?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  errorReasonCode: Scalars['String'];
  errorReasonText: Scalars['String'];
  height: Scalars['Int'];
  hls: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  medias: Array<Medias>;
  /** An aggregate relationship */
  medias_aggregate: Medias_Aggregate;
  mimeType?: Maybe<Scalars['String']>;
  playbackId?: Maybe<Scalars['String']>;
  preview: Scalars['String'];
  processingProgress?: Maybe<Scalars['float8']>;
  profileId: Scalars['String'];
  readyToStream: Scalars['Boolean'];
  size: Scalars['Int'];
  sourceUrl?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  thumbnail: Scalars['String'];
  uid: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  width: Scalars['Int'];
};


/** columns and relationships of "videos" */
export type VideosMediasArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosMedias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medias_Order_By>>;
  where?: InputMaybe<Medias_Bool_Exp>;
};

/** aggregated selection of "videos" */
export type Videos_Aggregate = {
  __typename?: 'videos_aggregate';
  aggregate?: Maybe<Videos_Aggregate_Fields>;
  nodes: Array<Videos>;
};

/** aggregate fields of "videos" */
export type Videos_Aggregate_Fields = {
  __typename?: 'videos_aggregate_fields';
  avg?: Maybe<Videos_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Videos_Max_Fields>;
  min?: Maybe<Videos_Min_Fields>;
  stddev?: Maybe<Videos_Stddev_Fields>;
  stddev_pop?: Maybe<Videos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Videos_Stddev_Samp_Fields>;
  sum?: Maybe<Videos_Sum_Fields>;
  var_pop?: Maybe<Videos_Var_Pop_Fields>;
  var_samp?: Maybe<Videos_Var_Samp_Fields>;
  variance?: Maybe<Videos_Variance_Fields>;
};


/** aggregate fields of "videos" */
export type Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Videos_Avg_Fields = {
  __typename?: 'videos_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  processingProgress?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "videos". All fields are combined with a logical 'AND'. */
export type Videos_Bool_Exp = {
  _and?: InputMaybe<Array<Videos_Bool_Exp>>;
  _not?: InputMaybe<Videos_Bool_Exp>;
  _or?: InputMaybe<Array<Videos_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dash?: InputMaybe<String_Comparison_Exp>;
  downloadUrl?: InputMaybe<String_Comparison_Exp>;
  duration?: InputMaybe<Float_Comparison_Exp>;
  errorReasonCode?: InputMaybe<String_Comparison_Exp>;
  errorReasonText?: InputMaybe<String_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  hls?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  medias?: InputMaybe<Medias_Bool_Exp>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Bool_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  playbackId?: InputMaybe<String_Comparison_Exp>;
  preview?: InputMaybe<String_Comparison_Exp>;
  processingProgress?: InputMaybe<Float8_Comparison_Exp>;
  profileId?: InputMaybe<String_Comparison_Exp>;
  readyToStream?: InputMaybe<Boolean_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  sourceUrl?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  width?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "videos" */
export enum Videos_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideosPkey = 'videos_pkey',
  /** unique or primary key constraint on columns "uid" */
  VideosUidUnique = 'videos_uid_unique'
}

/** input type for incrementing numeric columns in table "videos" */
export type Videos_Inc_Input = {
  duration?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Int']>;
  processingProgress?: InputMaybe<Scalars['float8']>;
  size?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "videos" */
export type Videos_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dash?: InputMaybe<Scalars['String']>;
  downloadUrl?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  errorReasonCode?: InputMaybe<Scalars['String']>;
  errorReasonText?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  hls?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  medias?: InputMaybe<Medias_Arr_Rel_Insert_Input>;
  mimeType?: InputMaybe<Scalars['String']>;
  playbackId?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['String']>;
  processingProgress?: InputMaybe<Scalars['float8']>;
  profileId?: InputMaybe<Scalars['String']>;
  readyToStream?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Videos_Max_Fields = {
  __typename?: 'videos_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dash?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  errorReasonCode?: Maybe<Scalars['String']>;
  errorReasonText?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  hls?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  playbackId?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  processingProgress?: Maybe<Scalars['float8']>;
  profileId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  sourceUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Videos_Min_Fields = {
  __typename?: 'videos_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dash?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  errorReasonCode?: Maybe<Scalars['String']>;
  errorReasonText?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  hls?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  playbackId?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  processingProgress?: Maybe<Scalars['float8']>;
  profileId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  sourceUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "videos" */
export type Videos_Mutation_Response = {
  __typename?: 'videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Videos>;
};

/** input type for inserting object relation for remote table "videos" */
export type Videos_Obj_Rel_Insert_Input = {
  data: Videos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};

/** on_conflict condition type for table "videos" */
export type Videos_On_Conflict = {
  constraint: Videos_Constraint;
  update_columns?: Array<Videos_Update_Column>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "videos". */
export type Videos_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dash?: InputMaybe<Order_By>;
  downloadUrl?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  errorReasonCode?: InputMaybe<Order_By>;
  errorReasonText?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  hls?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medias_aggregate?: InputMaybe<Medias_Aggregate_Order_By>;
  mimeType?: InputMaybe<Order_By>;
  playbackId?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  processingProgress?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
  readyToStream?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  sourceUrl?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: videos */
export type Videos_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "videos" */
export enum Videos_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dash = 'dash',
  /** column name */
  DownloadUrl = 'downloadUrl',
  /** column name */
  Duration = 'duration',
  /** column name */
  ErrorReasonCode = 'errorReasonCode',
  /** column name */
  ErrorReasonText = 'errorReasonText',
  /** column name */
  Height = 'height',
  /** column name */
  Hls = 'hls',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  PlaybackId = 'playbackId',
  /** column name */
  Preview = 'preview',
  /** column name */
  ProcessingProgress = 'processingProgress',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  ReadyToStream = 'readyToStream',
  /** column name */
  Size = 'size',
  /** column name */
  SourceUrl = 'sourceUrl',
  /** column name */
  State = 'state',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "videos" */
export type Videos_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dash?: InputMaybe<Scalars['String']>;
  downloadUrl?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  errorReasonCode?: InputMaybe<Scalars['String']>;
  errorReasonText?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  hls?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  playbackId?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['String']>;
  processingProgress?: InputMaybe<Scalars['float8']>;
  profileId?: InputMaybe<Scalars['String']>;
  readyToStream?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Videos_Stddev_Fields = {
  __typename?: 'videos_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  processingProgress?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Videos_Stddev_Pop_Fields = {
  __typename?: 'videos_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  processingProgress?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Videos_Stddev_Samp_Fields = {
  __typename?: 'videos_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  processingProgress?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "videos" */
export type Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Videos_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dash?: InputMaybe<Scalars['String']>;
  downloadUrl?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  errorReasonCode?: InputMaybe<Scalars['String']>;
  errorReasonText?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  hls?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  playbackId?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['String']>;
  processingProgress?: InputMaybe<Scalars['float8']>;
  profileId?: InputMaybe<Scalars['String']>;
  readyToStream?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Videos_Sum_Fields = {
  __typename?: 'videos_sum_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  processingProgress?: Maybe<Scalars['float8']>;
  size?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** update columns of table "videos" */
export enum Videos_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dash = 'dash',
  /** column name */
  DownloadUrl = 'downloadUrl',
  /** column name */
  Duration = 'duration',
  /** column name */
  ErrorReasonCode = 'errorReasonCode',
  /** column name */
  ErrorReasonText = 'errorReasonText',
  /** column name */
  Height = 'height',
  /** column name */
  Hls = 'hls',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  PlaybackId = 'playbackId',
  /** column name */
  Preview = 'preview',
  /** column name */
  ProcessingProgress = 'processingProgress',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  ReadyToStream = 'readyToStream',
  /** column name */
  Size = 'size',
  /** column name */
  SourceUrl = 'sourceUrl',
  /** column name */
  State = 'state',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Width = 'width'
}

export type Videos_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Videos_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Videos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Videos_Var_Pop_Fields = {
  __typename?: 'videos_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  processingProgress?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Videos_Var_Samp_Fields = {
  __typename?: 'videos_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  processingProgress?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Videos_Variance_Fields = {
  __typename?: 'videos_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  processingProgress?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "wallets" */
export type Wallets = {
  __typename?: 'wallets';
  accountId: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isXmtpWallet?: Maybe<Scalars['Boolean']>;
  kind: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profiles>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validated_with_tokenproof: Scalars['Boolean'];
  walletAddress: Scalars['String'];
  walletBalances: Array<Account>;
  walletBalances_aggregate: Account_Aggregate;
};


/** columns and relationships of "wallets" */
export type WalletsWalletBalancesArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


/** columns and relationships of "wallets" */
export type WalletsWalletBalances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};

/** aggregated selection of "wallets" */
export type Wallets_Aggregate = {
  __typename?: 'wallets_aggregate';
  aggregate?: Maybe<Wallets_Aggregate_Fields>;
  nodes: Array<Wallets>;
};

export type Wallets_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Wallets_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Wallets_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Wallets_Aggregate_Bool_Exp_Count>;
};

export type Wallets_Aggregate_Bool_Exp_Bool_And = {
  arguments: Wallets_Select_Column_Wallets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wallets_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Wallets_Select_Column_Wallets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Wallets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wallets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "wallets" */
export type Wallets_Aggregate_Fields = {
  __typename?: 'wallets_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wallets_Max_Fields>;
  min?: Maybe<Wallets_Min_Fields>;
};


/** aggregate fields of "wallets" */
export type Wallets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wallets" */
export type Wallets_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wallets_Max_Order_By>;
  min?: InputMaybe<Wallets_Min_Order_By>;
};

/** input type for inserting array relation for remote table "wallets" */
export type Wallets_Arr_Rel_Insert_Input = {
  data: Array<Wallets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};

/** Boolean expression to filter rows from the table "wallets". All fields are combined with a logical 'AND'. */
export type Wallets_Bool_Exp = {
  _and?: InputMaybe<Array<Wallets_Bool_Exp>>;
  _not?: InputMaybe<Wallets_Bool_Exp>;
  _or?: InputMaybe<Array<Wallets_Bool_Exp>>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  isPrimary?: InputMaybe<Boolean_Comparison_Exp>;
  isXmtpWallet?: InputMaybe<Boolean_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  validated_with_tokenproof?: InputMaybe<Boolean_Comparison_Exp>;
  walletAddress?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "wallets" */
export enum Wallets_Constraint {
  /** unique or primary key constraint on columns "account_id" */
  IdxUniquePrimaryWalletPerAccount = 'idx_unique_primary_wallet_per_account',
  /** unique or primary key constraint on columns "wallet_address" */
  WalletsPk = 'wallets_pk'
}

/** input type for inserting data into table "wallets" */
export type Wallets_Insert_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isXmtpWallet?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validated_with_tokenproof?: InputMaybe<Scalars['Boolean']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wallets_Max_Fields = {
  __typename?: 'wallets_max_fields';
  accountId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  kind?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "wallets" */
export type Wallets_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  walletAddress?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wallets_Min_Fields = {
  __typename?: 'wallets_min_fields';
  accountId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  kind?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "wallets" */
export type Wallets_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  walletAddress?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "wallets" */
export type Wallets_Mutation_Response = {
  __typename?: 'wallets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallets>;
};

/** input type for inserting object relation for remote table "wallets" */
export type Wallets_Obj_Rel_Insert_Input = {
  data: Wallets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};

/** on_conflict condition type for table "wallets" */
export type Wallets_On_Conflict = {
  constraint: Wallets_Constraint;
  update_columns?: Array<Wallets_Update_Column>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};

/** Ordering options when selecting data from "wallets". */
export type Wallets_Order_By = {
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  isPrimary?: InputMaybe<Order_By>;
  isXmtpWallet?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validated_with_tokenproof?: InputMaybe<Order_By>;
  walletAddress?: InputMaybe<Order_By>;
};

/** primary key columns input for table: wallets */
export type Wallets_Pk_Columns_Input = {
  walletAddress: Scalars['String'];
};

/** columns and relationships of "wallets_resolves" */
export type Wallets_Resolves = {
  __typename?: 'wallets_resolves';
  /** An array relationship */
  connectedNfts: Array<Connected_Nfts>;
  /** An aggregate relationship */
  connectedNfts_aggregate: Connected_Nfts_Aggregate;
  contract?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  kind: Scalars['String'];
  ownerAddress: Scalars['String'];
  tokenId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  wallet: Wallets;
  walletAddress: Scalars['String'];
};


/** columns and relationships of "wallets_resolves" */
export type Wallets_ResolvesConnectedNftsArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};


/** columns and relationships of "wallets_resolves" */
export type Wallets_ResolvesConnectedNfts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connected_Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connected_Nfts_Order_By>>;
  where?: InputMaybe<Connected_Nfts_Bool_Exp>;
};

/** aggregated selection of "wallets_resolves" */
export type Wallets_Resolves_Aggregate = {
  __typename?: 'wallets_resolves_aggregate';
  aggregate?: Maybe<Wallets_Resolves_Aggregate_Fields>;
  nodes: Array<Wallets_Resolves>;
};

/** aggregate fields of "wallets_resolves" */
export type Wallets_Resolves_Aggregate_Fields = {
  __typename?: 'wallets_resolves_aggregate_fields';
  avg?: Maybe<Wallets_Resolves_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wallets_Resolves_Max_Fields>;
  min?: Maybe<Wallets_Resolves_Min_Fields>;
  stddev?: Maybe<Wallets_Resolves_Stddev_Fields>;
  stddev_pop?: Maybe<Wallets_Resolves_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wallets_Resolves_Stddev_Samp_Fields>;
  sum?: Maybe<Wallets_Resolves_Sum_Fields>;
  var_pop?: Maybe<Wallets_Resolves_Var_Pop_Fields>;
  var_samp?: Maybe<Wallets_Resolves_Var_Samp_Fields>;
  variance?: Maybe<Wallets_Resolves_Variance_Fields>;
};


/** aggregate fields of "wallets_resolves" */
export type Wallets_Resolves_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallets_Resolves_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wallets_Resolves_Avg_Fields = {
  __typename?: 'wallets_resolves_avg_fields';
  tokenId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wallets_resolves". All fields are combined with a logical 'AND'. */
export type Wallets_Resolves_Bool_Exp = {
  _and?: InputMaybe<Array<Wallets_Resolves_Bool_Exp>>;
  _not?: InputMaybe<Wallets_Resolves_Bool_Exp>;
  _or?: InputMaybe<Array<Wallets_Resolves_Bool_Exp>>;
  connectedNfts?: InputMaybe<Connected_Nfts_Bool_Exp>;
  connectedNfts_aggregate?: InputMaybe<Connected_Nfts_Aggregate_Bool_Exp>;
  contract?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  ownerAddress?: InputMaybe<String_Comparison_Exp>;
  tokenId?: InputMaybe<Int_Comparison_Exp>;
  wallet?: InputMaybe<Wallets_Bool_Exp>;
  walletAddress?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "wallets_resolves" */
export enum Wallets_Resolves_Constraint {
  /** unique or primary key constraint on columns "id" */
  WalletsResolvesPkey = 'wallets_resolves_pkey'
}

/** input type for incrementing numeric columns in table "wallets_resolves" */
export type Wallets_Resolves_Inc_Input = {
  tokenId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "wallets_resolves" */
export type Wallets_Resolves_Insert_Input = {
  connectedNfts?: InputMaybe<Connected_Nfts_Arr_Rel_Insert_Input>;
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['Int']>;
  wallet?: InputMaybe<Wallets_Obj_Rel_Insert_Input>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wallets_Resolves_Max_Fields = {
  __typename?: 'wallets_resolves_max_fields';
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wallets_Resolves_Min_Fields = {
  __typename?: 'wallets_resolves_min_fields';
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  walletAddress?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wallets_resolves" */
export type Wallets_Resolves_Mutation_Response = {
  __typename?: 'wallets_resolves_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallets_Resolves>;
};

/** input type for inserting object relation for remote table "wallets_resolves" */
export type Wallets_Resolves_Obj_Rel_Insert_Input = {
  data: Wallets_Resolves_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallets_Resolves_On_Conflict>;
};

/** on_conflict condition type for table "wallets_resolves" */
export type Wallets_Resolves_On_Conflict = {
  constraint: Wallets_Resolves_Constraint;
  update_columns?: Array<Wallets_Resolves_Update_Column>;
  where?: InputMaybe<Wallets_Resolves_Bool_Exp>;
};

/** Ordering options when selecting data from "wallets_resolves". */
export type Wallets_Resolves_Order_By = {
  connectedNfts_aggregate?: InputMaybe<Connected_Nfts_Aggregate_Order_By>;
  contract?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Wallets_Order_By>;
  walletAddress?: InputMaybe<Order_By>;
};

/** primary key columns input for table: wallets_resolves */
export type Wallets_Resolves_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "wallets_resolves" */
export enum Wallets_Resolves_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  WalletAddress = 'walletAddress'
}

/** input type for updating data in table "wallets_resolves" */
export type Wallets_Resolves_Set_Input = {
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['Int']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wallets_Resolves_Stddev_Fields = {
  __typename?: 'wallets_resolves_stddev_fields';
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wallets_Resolves_Stddev_Pop_Fields = {
  __typename?: 'wallets_resolves_stddev_pop_fields';
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wallets_Resolves_Stddev_Samp_Fields = {
  __typename?: 'wallets_resolves_stddev_samp_fields';
  tokenId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "wallets_resolves" */
export type Wallets_Resolves_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallets_Resolves_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallets_Resolves_Stream_Cursor_Value_Input = {
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['Int']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wallets_Resolves_Sum_Fields = {
  __typename?: 'wallets_resolves_sum_fields';
  tokenId?: Maybe<Scalars['Int']>;
};

/** update columns of table "wallets_resolves" */
export enum Wallets_Resolves_Update_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  WalletAddress = 'walletAddress'
}

export type Wallets_Resolves_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wallets_Resolves_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallets_Resolves_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wallets_Resolves_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wallets_Resolves_Var_Pop_Fields = {
  __typename?: 'wallets_resolves_var_pop_fields';
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wallets_Resolves_Var_Samp_Fields = {
  __typename?: 'wallets_resolves_var_samp_fields';
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wallets_Resolves_Variance_Fields = {
  __typename?: 'wallets_resolves_variance_fields';
  tokenId?: Maybe<Scalars['Float']>;
};

/** select columns of table "wallets" */
export enum Wallets_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  IsXmtpWallet = 'isXmtpWallet',
  /** column name */
  Kind = 'kind',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidatedWithTokenproof = 'validated_with_tokenproof',
  /** column name */
  WalletAddress = 'walletAddress'
}

/** select "wallets_aggregate_bool_exp_bool_and_arguments_columns" columns of table "wallets" */
export enum Wallets_Select_Column_Wallets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  IsXmtpWallet = 'isXmtpWallet',
  /** column name */
  ValidatedWithTokenproof = 'validated_with_tokenproof'
}

/** select "wallets_aggregate_bool_exp_bool_or_arguments_columns" columns of table "wallets" */
export enum Wallets_Select_Column_Wallets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  IsXmtpWallet = 'isXmtpWallet',
  /** column name */
  ValidatedWithTokenproof = 'validated_with_tokenproof'
}

/** input type for updating data in table "wallets" */
export type Wallets_Set_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isXmtpWallet?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validated_with_tokenproof?: InputMaybe<Scalars['Boolean']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "wallets" */
export type Wallets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallets_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isXmtpWallet?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validated_with_tokenproof?: InputMaybe<Scalars['Boolean']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** update columns of table "wallets" */
export enum Wallets_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  IsXmtpWallet = 'isXmtpWallet',
  /** column name */
  Kind = 'kind',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidatedWithTokenproof = 'validated_with_tokenproof',
  /** column name */
  WalletAddress = 'walletAddress'
}

export type Wallets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wallets_Bool_Exp;
};

/** columns and relationships of "yield_sources" */
export type Yield_Sources = {
  __typename?: 'yield_sources';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYield: Scalars['Int'];
  credYieldRatio: Scalars['Float'];
  id: Scalars['Int'];
  /** An object relationship */
  image?: Maybe<Images>;
  imageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  kind: Verified_Nft_Project_Kinds_Enum;
  name: Scalars['String'];
  pointsYield: Scalars['Int'];
  pointsYieldRatio: Scalars['Float'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "yield_sources" */
export type Yield_Sources_Aggregate = {
  __typename?: 'yield_sources_aggregate';
  aggregate?: Maybe<Yield_Sources_Aggregate_Fields>;
  nodes: Array<Yield_Sources>;
};

/** aggregate fields of "yield_sources" */
export type Yield_Sources_Aggregate_Fields = {
  __typename?: 'yield_sources_aggregate_fields';
  avg?: Maybe<Yield_Sources_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Yield_Sources_Max_Fields>;
  min?: Maybe<Yield_Sources_Min_Fields>;
  stddev?: Maybe<Yield_Sources_Stddev_Fields>;
  stddev_pop?: Maybe<Yield_Sources_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Yield_Sources_Stddev_Samp_Fields>;
  sum?: Maybe<Yield_Sources_Sum_Fields>;
  var_pop?: Maybe<Yield_Sources_Var_Pop_Fields>;
  var_samp?: Maybe<Yield_Sources_Var_Samp_Fields>;
  variance?: Maybe<Yield_Sources_Variance_Fields>;
};


/** aggregate fields of "yield_sources" */
export type Yield_Sources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Yield_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Yield_Sources_Avg_Fields = {
  __typename?: 'yield_sources_avg_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "yield_sources". All fields are combined with a logical 'AND'. */
export type Yield_Sources_Bool_Exp = {
  _and?: InputMaybe<Array<Yield_Sources_Bool_Exp>>;
  _not?: InputMaybe<Yield_Sources_Bool_Exp>;
  _or?: InputMaybe<Array<Yield_Sources_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credYield?: InputMaybe<Int_Comparison_Exp>;
  credYieldRatio?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  imageId?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pointsYield?: InputMaybe<Int_Comparison_Exp>;
  pointsYieldRatio?: InputMaybe<Float_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "yield_sources" */
export enum Yield_Sources_Constraint {
  /** unique or primary key constraint on columns "id" */
  YieldSourcesPkey = 'yield_sources_pkey'
}

/** input type for incrementing numeric columns in table "yield_sources" */
export type Yield_Sources_Inc_Input = {
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "yield_sources" */
export type Yield_Sources_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  imageId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  name?: InputMaybe<Scalars['String']>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Yield_Sources_Max_Fields = {
  __typename?: 'yield_sources_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYield?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  imageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointsYield?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Yield_Sources_Min_Fields = {
  __typename?: 'yield_sources_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  credYield?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  imageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointsYield?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "yield_sources" */
export type Yield_Sources_Mutation_Response = {
  __typename?: 'yield_sources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Yield_Sources>;
};

/** input type for inserting object relation for remote table "yield_sources" */
export type Yield_Sources_Obj_Rel_Insert_Input = {
  data: Yield_Sources_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Yield_Sources_On_Conflict>;
};

/** on_conflict condition type for table "yield_sources" */
export type Yield_Sources_On_Conflict = {
  constraint: Yield_Sources_Constraint;
  update_columns?: Array<Yield_Sources_Update_Column>;
  where?: InputMaybe<Yield_Sources_Bool_Exp>;
};

/** Ordering options when selecting data from "yield_sources". */
export type Yield_Sources_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  credYield?: InputMaybe<Order_By>;
  credYieldRatio?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Images_Order_By>;
  imageId?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pointsYield?: InputMaybe<Order_By>;
  pointsYieldRatio?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: yield_sources */
export type Yield_Sources_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "yield_sources" */
export enum Yield_Sources_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredYield = 'credYield',
  /** column name */
  CredYieldRatio = 'credYieldRatio',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  PointsYield = 'pointsYield',
  /** column name */
  PointsYieldRatio = 'pointsYieldRatio',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "yield_sources" */
export type Yield_Sources_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  name?: InputMaybe<Scalars['String']>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Yield_Sources_Stddev_Fields = {
  __typename?: 'yield_sources_stddev_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Yield_Sources_Stddev_Pop_Fields = {
  __typename?: 'yield_sources_stddev_pop_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Yield_Sources_Stddev_Samp_Fields = {
  __typename?: 'yield_sources_stddev_samp_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "yield_sources" */
export type Yield_Sources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Yield_Sources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Yield_Sources_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credYield?: InputMaybe<Scalars['Int']>;
  credYieldRatio?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Verified_Nft_Project_Kinds_Enum>;
  name?: InputMaybe<Scalars['String']>;
  pointsYield?: InputMaybe<Scalars['Int']>;
  pointsYieldRatio?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Yield_Sources_Sum_Fields = {
  __typename?: 'yield_sources_sum_fields';
  credYield?: Maybe<Scalars['Int']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  pointsYield?: Maybe<Scalars['Int']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

/** update columns of table "yield_sources" */
export enum Yield_Sources_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredYield = 'credYield',
  /** column name */
  CredYieldRatio = 'credYieldRatio',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  PointsYield = 'pointsYield',
  /** column name */
  PointsYieldRatio = 'pointsYieldRatio',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Yield_Sources_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Yield_Sources_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Yield_Sources_Set_Input>;
  /** filter the rows which have to be updated */
  where: Yield_Sources_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Yield_Sources_Var_Pop_Fields = {
  __typename?: 'yield_sources_var_pop_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Yield_Sources_Var_Samp_Fields = {
  __typename?: 'yield_sources_var_samp_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Yield_Sources_Variance_Fields = {
  __typename?: 'yield_sources_variance_fields';
  credYield?: Maybe<Scalars['Float']>;
  credYieldRatio?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointsYield?: Maybe<Scalars['Float']>;
  pointsYieldRatio?: Maybe<Scalars['Float']>;
};

export type ImageFragment = { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null };

export type LensProfileFragment = { __typename?: 'lens_profiles', id: string, lensProfileId: string, lensHandle: string, status: Lens_Tx_Status_Enum_Enum, txId?: string | null, dispatcherAddress?: string | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null };

export type ProfileFragment = { __typename?: 'profiles', id: string, name: string, username: string, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null };

export type AccountFragment = { __typename?: 'accounts', type: Account_Types_Enum, profile?: { __typename?: 'profiles', id: string, name: string, username: string, lensProfile?: { __typename?: 'lens_profiles', id: string, lensProfileId: string, lensHandle: string, status: Lens_Tx_Status_Enum_Enum, txId?: string | null, dispatcherAddress?: string | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null } | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null } | null };

export type NftFragment = { __typename?: 'Nft', chainId: number, contractAddress: string, imageUrl: string, title: string, tokenId: string };

export type ContractWithOwnedNftFragment = { __typename?: 'ContractWithOwnedNfts', chainId: number, contractAddress: string, ownedNfts: Array<{ __typename?: 'Nft', chainId: number, contractAddress: string, imageUrl: string, title: string, tokenId: string }>, verifiedNftProject?: { __typename?: 'verified_nft_projects', chainId: number, contractAddress: string, name: string, createdAt?: any | null, updatedAt?: any | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null } | null };

export type VerifiedNftProjectFragment = { __typename?: 'verified_nft_projects', chainId: number, contractAddress: string, name: string, createdAt?: any | null, updatedAt?: any | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null };

export type LensCheckRelayFragment = { __typename?: 'lensCheckRelayOutput', apiLogin: boolean, connectedLensProfile: boolean, isLensApiDispatcherSet: boolean, isPhaverDispatcherSet: boolean, sponsoredByLens: boolean };

export type LensSetDispatcherExecuteMutationVariables = Exact<{
  lensProfileId: Scalars['String'];
  sig: Scalars['String'];
}>;


export type LensSetDispatcherExecuteMutation = { lensSetDispatcherExecute: { __typename?: 'lensSetDispatcherExecuteOutput', status: string } };

export type ConnectNfTsBuildDataMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type ConnectNfTsBuildDataMutation = { connectNftsBuildData: { __typename?: 'ConnectNftsBuildDataOutput', status: string, message: string } };

export type ConnectNfTsExecuteMutationVariables = Exact<{
  sig: Scalars['String'];
}>;


export type ConnectNfTsExecuteMutation = { connectNftsExecute: { __typename?: 'ConnectNftsExecuteOutput', status: string } };

export type LensApiAuthenticateMutationVariables = Exact<{
  address: Scalars['String'];
  sig: Scalars['String'];
}>;


export type LensApiAuthenticateMutation = { lensApiAuthenticate: { __typename?: 'lensApiAuthenticateOutput', status: string } };

export type LensSetDispatcherBuildDataQueryVariables = Exact<{
  lensProfileId: Scalars['String'];
  dispatcher?: InputMaybe<DispatcherTypes>;
}>;


export type LensSetDispatcherBuildDataQuery = { lensSetDispatcherBuildData: { __typename?: 'lensSetDispatcherBuildDataOutput', status: string, message: string } };

export type LensProfilesOwnedByAccountQueryQueryVariables = Exact<{
  account: Scalars['String'];
}>;


export type LensProfilesOwnedByAccountQueryQuery = { lensProfilesOwnedByAccount: Array<{ __typename?: 'lensProfilesOwnedByAccountOutput', lensProfileId: string, lensProfile?: { __typename?: 'lens_profiles', id: string, lensProfileId: string, lensHandle: string, status: Lens_Tx_Status_Enum_Enum, txId?: string | null, dispatcherAddress?: string | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null } | null }> };

export type CurrentUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CurrentUserQuery = { account?: { __typename?: 'accounts', type: Account_Types_Enum, profile?: { __typename?: 'profiles', id: string, name: string, username: string, lensProfile?: { __typename?: 'lens_profiles', id: string, lensProfileId: string, lensHandle: string, status: Lens_Tx_Status_Enum_Enum, txId?: string | null, dispatcherAddress?: string | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null } | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null } | null } | null, lensCheckRelay: { __typename?: 'lensCheckRelayOutput', apiLogin: boolean, connectedLensProfile: boolean, isLensApiDispatcherSet: boolean, isPhaverDispatcherSet: boolean, sponsoredByLens: boolean } };

export type ListNfTsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type ListNfTsQuery = { listNfts: Array<{ __typename?: 'ContractWithOwnedNfts', chainId: number, contractAddress: string, ownedNfts: Array<{ __typename?: 'Nft', chainId: number, contractAddress: string, imageUrl: string, title: string, tokenId: string }>, verifiedNftProject?: { __typename?: 'verified_nft_projects', chainId: number, contractAddress: string, name: string, createdAt?: any | null, updatedAt?: any | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null } | null }> };

export type VerifiedNftProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type VerifiedNftProjectQuery = { verified_nft_projects: Array<{ __typename?: 'verified_nft_projects', chainId: number, contractAddress: string, name: string, createdAt?: any | null, updatedAt?: any | null, image?: { __typename?: 'images', id: string, filename: string, blurhash: string, bucket: string, contentType: string, createdAt: any, deletedAt?: any | null, height: number, profileId: string, size: number, updatedAt: any, width: number, source_url?: string | null } | null }> };

export type LensApiChallengeQueryVariables = Exact<{
  address: Scalars['String'];
  lensProfileId: Scalars['String'];
}>;


export type LensApiChallengeQuery = { lensApiChallenge: { __typename?: 'lensApiChallengeOutput', challenge: string } };

export const ImageFragmentDoc = gql`
    fragment Image on images {
  id
  filename
  blurhash
  bucket
  contentType
  createdAt
  deletedAt
  height
  profileId
  size
  updatedAt
  width
  source_url
}
    `;
export const ProfileFragmentDoc = gql`
    fragment Profile on profiles {
  id
  name
  username
  image {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const LensProfileFragmentDoc = gql`
    fragment LensProfile on lens_profiles {
  id
  lensProfileId
  lensHandle
  status
  txId
  dispatcherAddress
  image {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const AccountFragmentDoc = gql`
    fragment Account on accounts {
  type
  profile {
    ...Profile
    lensProfile {
      ...LensProfile
    }
  }
}
    ${ProfileFragmentDoc}
${LensProfileFragmentDoc}`;
export const NftFragmentDoc = gql`
    fragment NFT on Nft {
  chainId
  contractAddress
  imageUrl
  title
  tokenId
}
    `;
export const VerifiedNftProjectFragmentDoc = gql`
    fragment VerifiedNftProject on verified_nft_projects {
  chainId
  contractAddress
  name
  createdAt
  updatedAt
  image {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const ContractWithOwnedNftFragmentDoc = gql`
    fragment ContractWithOwnedNFT on ContractWithOwnedNfts {
  chainId
  contractAddress
  ownedNfts {
    ...NFT
  }
  verifiedNftProject {
    ...VerifiedNftProject
  }
}
    ${NftFragmentDoc}
${VerifiedNftProjectFragmentDoc}`;
export const LensCheckRelayFragmentDoc = gql`
    fragment LensCheckRelay on lensCheckRelayOutput {
  apiLogin
  connectedLensProfile
  isLensApiDispatcherSet
  isPhaverDispatcherSet
  sponsoredByLens
}
    `;
export const LensSetDispatcherExecuteDocument = gql`
    mutation LensSetDispatcherExecute($lensProfileId: String!, $sig: String!) {
  lensSetDispatcherExecute(lensProfileId: $lensProfileId, sig: $sig) {
    status
  }
}
    `;
export type LensSetDispatcherExecuteMutationFn = Apollo.MutationFunction<LensSetDispatcherExecuteMutation, LensSetDispatcherExecuteMutationVariables>;

/**
 * __useLensSetDispatcherExecuteMutation__
 *
 * To run a mutation, you first call `useLensSetDispatcherExecuteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLensSetDispatcherExecuteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lensSetDispatcherExecuteMutation, { data, loading, error }] = useLensSetDispatcherExecuteMutation({
 *   variables: {
 *      lensProfileId: // value for 'lensProfileId'
 *      sig: // value for 'sig'
 *   },
 * });
 */
export function useLensSetDispatcherExecuteMutation(baseOptions?: Apollo.MutationHookOptions<LensSetDispatcherExecuteMutation, LensSetDispatcherExecuteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LensSetDispatcherExecuteMutation, LensSetDispatcherExecuteMutationVariables>(LensSetDispatcherExecuteDocument, options);
      }
export type LensSetDispatcherExecuteMutationHookResult = ReturnType<typeof useLensSetDispatcherExecuteMutation>;
export type LensSetDispatcherExecuteMutationResult = Apollo.MutationResult<LensSetDispatcherExecuteMutation>;
export type LensSetDispatcherExecuteMutationOptions = Apollo.BaseMutationOptions<LensSetDispatcherExecuteMutation, LensSetDispatcherExecuteMutationVariables>;
export const ConnectNfTsBuildDataDocument = gql`
    mutation ConnectNFTsBuildData($address: String!) {
  connectNftsBuildData(address: $address) {
    status
    message
  }
}
    `;
export type ConnectNfTsBuildDataMutationFn = Apollo.MutationFunction<ConnectNfTsBuildDataMutation, ConnectNfTsBuildDataMutationVariables>;

/**
 * __useConnectNfTsBuildDataMutation__
 *
 * To run a mutation, you first call `useConnectNfTsBuildDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectNfTsBuildDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectNfTsBuildDataMutation, { data, loading, error }] = useConnectNfTsBuildDataMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useConnectNfTsBuildDataMutation(baseOptions?: Apollo.MutationHookOptions<ConnectNfTsBuildDataMutation, ConnectNfTsBuildDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectNfTsBuildDataMutation, ConnectNfTsBuildDataMutationVariables>(ConnectNfTsBuildDataDocument, options);
      }
export type ConnectNfTsBuildDataMutationHookResult = ReturnType<typeof useConnectNfTsBuildDataMutation>;
export type ConnectNfTsBuildDataMutationResult = Apollo.MutationResult<ConnectNfTsBuildDataMutation>;
export type ConnectNfTsBuildDataMutationOptions = Apollo.BaseMutationOptions<ConnectNfTsBuildDataMutation, ConnectNfTsBuildDataMutationVariables>;
export const ConnectNfTsExecuteDocument = gql`
    mutation ConnectNFTsExecute($sig: String!) {
  connectNftsExecute(sig: $sig) {
    status
  }
}
    `;
export type ConnectNfTsExecuteMutationFn = Apollo.MutationFunction<ConnectNfTsExecuteMutation, ConnectNfTsExecuteMutationVariables>;

/**
 * __useConnectNfTsExecuteMutation__
 *
 * To run a mutation, you first call `useConnectNfTsExecuteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectNfTsExecuteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectNfTsExecuteMutation, { data, loading, error }] = useConnectNfTsExecuteMutation({
 *   variables: {
 *      sig: // value for 'sig'
 *   },
 * });
 */
export function useConnectNfTsExecuteMutation(baseOptions?: Apollo.MutationHookOptions<ConnectNfTsExecuteMutation, ConnectNfTsExecuteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectNfTsExecuteMutation, ConnectNfTsExecuteMutationVariables>(ConnectNfTsExecuteDocument, options);
      }
export type ConnectNfTsExecuteMutationHookResult = ReturnType<typeof useConnectNfTsExecuteMutation>;
export type ConnectNfTsExecuteMutationResult = Apollo.MutationResult<ConnectNfTsExecuteMutation>;
export type ConnectNfTsExecuteMutationOptions = Apollo.BaseMutationOptions<ConnectNfTsExecuteMutation, ConnectNfTsExecuteMutationVariables>;
export const LensApiAuthenticateDocument = gql`
    mutation LensApiAuthenticate($address: String!, $sig: String!) {
  lensApiAuthenticate(address: $address, sig: $sig) {
    status
  }
}
    `;
export type LensApiAuthenticateMutationFn = Apollo.MutationFunction<LensApiAuthenticateMutation, LensApiAuthenticateMutationVariables>;

/**
 * __useLensApiAuthenticateMutation__
 *
 * To run a mutation, you first call `useLensApiAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLensApiAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lensApiAuthenticateMutation, { data, loading, error }] = useLensApiAuthenticateMutation({
 *   variables: {
 *      address: // value for 'address'
 *      sig: // value for 'sig'
 *   },
 * });
 */
export function useLensApiAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<LensApiAuthenticateMutation, LensApiAuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LensApiAuthenticateMutation, LensApiAuthenticateMutationVariables>(LensApiAuthenticateDocument, options);
      }
export type LensApiAuthenticateMutationHookResult = ReturnType<typeof useLensApiAuthenticateMutation>;
export type LensApiAuthenticateMutationResult = Apollo.MutationResult<LensApiAuthenticateMutation>;
export type LensApiAuthenticateMutationOptions = Apollo.BaseMutationOptions<LensApiAuthenticateMutation, LensApiAuthenticateMutationVariables>;
export const LensSetDispatcherBuildDataDocument = gql`
    query LensSetDispatcherBuildData($lensProfileId: String!, $dispatcher: dispatcherTypes) {
  lensSetDispatcherBuildData(
    lensProfileId: $lensProfileId
    dispatcher: $dispatcher
  ) {
    status
    message
  }
}
    `;

/**
 * __useLensSetDispatcherBuildDataQuery__
 *
 * To run a query within a React component, call `useLensSetDispatcherBuildDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLensSetDispatcherBuildDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLensSetDispatcherBuildDataQuery({
 *   variables: {
 *      lensProfileId: // value for 'lensProfileId'
 *      dispatcher: // value for 'dispatcher'
 *   },
 * });
 */
export function useLensSetDispatcherBuildDataQuery(baseOptions: Apollo.QueryHookOptions<LensSetDispatcherBuildDataQuery, LensSetDispatcherBuildDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LensSetDispatcherBuildDataQuery, LensSetDispatcherBuildDataQueryVariables>(LensSetDispatcherBuildDataDocument, options);
      }
export function useLensSetDispatcherBuildDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LensSetDispatcherBuildDataQuery, LensSetDispatcherBuildDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LensSetDispatcherBuildDataQuery, LensSetDispatcherBuildDataQueryVariables>(LensSetDispatcherBuildDataDocument, options);
        }
export type LensSetDispatcherBuildDataQueryHookResult = ReturnType<typeof useLensSetDispatcherBuildDataQuery>;
export type LensSetDispatcherBuildDataLazyQueryHookResult = ReturnType<typeof useLensSetDispatcherBuildDataLazyQuery>;
export type LensSetDispatcherBuildDataQueryResult = Apollo.QueryResult<LensSetDispatcherBuildDataQuery, LensSetDispatcherBuildDataQueryVariables>;
export const LensProfilesOwnedByAccountQueryDocument = gql`
    query LensProfilesOwnedByAccountQuery($account: String!) {
  lensProfilesOwnedByAccount(account: $account) {
    lensProfileId
    lensProfile {
      ...LensProfile
    }
  }
}
    ${LensProfileFragmentDoc}`;

/**
 * __useLensProfilesOwnedByAccountQueryQuery__
 *
 * To run a query within a React component, call `useLensProfilesOwnedByAccountQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLensProfilesOwnedByAccountQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLensProfilesOwnedByAccountQueryQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useLensProfilesOwnedByAccountQueryQuery(baseOptions: Apollo.QueryHookOptions<LensProfilesOwnedByAccountQueryQuery, LensProfilesOwnedByAccountQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LensProfilesOwnedByAccountQueryQuery, LensProfilesOwnedByAccountQueryQueryVariables>(LensProfilesOwnedByAccountQueryDocument, options);
      }
export function useLensProfilesOwnedByAccountQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LensProfilesOwnedByAccountQueryQuery, LensProfilesOwnedByAccountQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LensProfilesOwnedByAccountQueryQuery, LensProfilesOwnedByAccountQueryQueryVariables>(LensProfilesOwnedByAccountQueryDocument, options);
        }
export type LensProfilesOwnedByAccountQueryQueryHookResult = ReturnType<typeof useLensProfilesOwnedByAccountQueryQuery>;
export type LensProfilesOwnedByAccountQueryLazyQueryHookResult = ReturnType<typeof useLensProfilesOwnedByAccountQueryLazyQuery>;
export type LensProfilesOwnedByAccountQueryQueryResult = Apollo.QueryResult<LensProfilesOwnedByAccountQueryQuery, LensProfilesOwnedByAccountQueryQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser($id: String!) {
  account: accounts_by_pk(id: $id) {
    ...Account
  }
  lensCheckRelay {
    ...LensCheckRelay
  }
}
    ${AccountFragmentDoc}
${LensCheckRelayFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const ListNfTsDocument = gql`
    query ListNFTs($address: String!) {
  listNfts(address: $address) {
    ...ContractWithOwnedNFT
  }
}
    ${ContractWithOwnedNftFragmentDoc}`;

/**
 * __useListNfTsQuery__
 *
 * To run a query within a React component, call `useListNfTsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNfTsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNfTsQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useListNfTsQuery(baseOptions: Apollo.QueryHookOptions<ListNfTsQuery, ListNfTsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListNfTsQuery, ListNfTsQueryVariables>(ListNfTsDocument, options);
      }
export function useListNfTsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListNfTsQuery, ListNfTsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListNfTsQuery, ListNfTsQueryVariables>(ListNfTsDocument, options);
        }
export type ListNfTsQueryHookResult = ReturnType<typeof useListNfTsQuery>;
export type ListNfTsLazyQueryHookResult = ReturnType<typeof useListNfTsLazyQuery>;
export type ListNfTsQueryResult = Apollo.QueryResult<ListNfTsQuery, ListNfTsQueryVariables>;
export const VerifiedNftProjectDocument = gql`
    query VerifiedNFTProject {
  verified_nft_projects(order_by: {name: asc}) {
    ...VerifiedNftProject
  }
}
    ${VerifiedNftProjectFragmentDoc}`;

/**
 * __useVerifiedNftProjectQuery__
 *
 * To run a query within a React component, call `useVerifiedNftProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifiedNftProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifiedNftProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifiedNftProjectQuery(baseOptions?: Apollo.QueryHookOptions<VerifiedNftProjectQuery, VerifiedNftProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifiedNftProjectQuery, VerifiedNftProjectQueryVariables>(VerifiedNftProjectDocument, options);
      }
export function useVerifiedNftProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifiedNftProjectQuery, VerifiedNftProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifiedNftProjectQuery, VerifiedNftProjectQueryVariables>(VerifiedNftProjectDocument, options);
        }
export type VerifiedNftProjectQueryHookResult = ReturnType<typeof useVerifiedNftProjectQuery>;
export type VerifiedNftProjectLazyQueryHookResult = ReturnType<typeof useVerifiedNftProjectLazyQuery>;
export type VerifiedNftProjectQueryResult = Apollo.QueryResult<VerifiedNftProjectQuery, VerifiedNftProjectQueryVariables>;
export const LensApiChallengeDocument = gql`
    query LensApiChallenge($address: String!, $lensProfileId: String!) {
  lensApiChallenge(address: $address, lensProfileId: $lensProfileId) {
    challenge
  }
}
    `;

/**
 * __useLensApiChallengeQuery__
 *
 * To run a query within a React component, call `useLensApiChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLensApiChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLensApiChallengeQuery({
 *   variables: {
 *      address: // value for 'address'
 *      lensProfileId: // value for 'lensProfileId'
 *   },
 * });
 */
export function useLensApiChallengeQuery(baseOptions: Apollo.QueryHookOptions<LensApiChallengeQuery, LensApiChallengeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LensApiChallengeQuery, LensApiChallengeQueryVariables>(LensApiChallengeDocument, options);
      }
export function useLensApiChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LensApiChallengeQuery, LensApiChallengeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LensApiChallengeQuery, LensApiChallengeQueryVariables>(LensApiChallengeDocument, options);
        }
export type LensApiChallengeQueryHookResult = ReturnType<typeof useLensApiChallengeQuery>;
export type LensApiChallengeLazyQueryHookResult = ReturnType<typeof useLensApiChallengeLazyQuery>;
export type LensApiChallengeQueryResult = Apollo.QueryResult<LensApiChallengeQuery, LensApiChallengeQueryVariables>;