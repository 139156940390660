import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { IdTokenResult } from "@firebase/auth";
import { getAppCheckToken, getAuth } from "src/lib/firebase";
import * as z from "zod";
import {
  NEXT_PUBLIC_CONNECT_DEV_TOOLS,
  NEXT_PUBLIC_GQL_ENDPOINT,
} from "../config";

export const ValidUserTypeSchema = z.enum(["user", "moderator", "admin"]);

type ValidUserType = z.infer<typeof ValidUserTypeSchema>;

export const ValidCustomClaims = z
  .object({
    type: ValidUserTypeSchema,
    "https://hasura.io/jwt/claims": z.object({
      "x-hasura-allowed-roles": ValidUserTypeSchema.array(),
      "x-hasura-default-role": ValidUserTypeSchema,
      "x-hasura-user-id": z.string(),
    }),
  })
  .passthrough();

const allowedUserTypes: ReadonlyArray<ValidUserType> = [
  "user",
  "moderator",
  "admin",
];

export const isAllowedToAccess = (tokenResult: IdTokenResult) => {
  const result = ValidCustomClaims.safeParse(tokenResult.claims);
  if (!result.success) return false;

  const userType = result.data.type;
  return (allowedUserTypes as ReadonlyArray<string>).includes(userType);
};

const getAuthHeaders = (tokenResult: IdTokenResult | undefined) => {
  if (!tokenResult) return {};
  if (!isAllowedToAccess(tokenResult)) return {};
  return { authorization: `Bearer ${tokenResult.token}` };
};

type PreviousContext = {
  role?: ValidUserType;
  headers?: Record<string, string>;
};

const authLink = setContext(async (_, { role, headers }: PreviousContext) => {
  const appCheckToken = await getAppCheckToken();
  const appCheckHeaders = appCheckToken
    ? { "X-Firebase-AppCheck": appCheckToken }
    : {};
  const tokenResult = await getAuth().currentUser?.getIdTokenResult();
  const roleHeaders = role ? { "X-Hasura-Role": role } : {};
  const authHeaders = getAuthHeaders(tokenResult);

  return {
    headers: {
      ...appCheckHeaders,
      ...authHeaders,
      ...roleHeaders,
      ...headers,
    },
  };
});

const httpLink = new BatchHttpLink({
  uri: NEXT_PUBLIC_GQL_ENDPOINT,
  batchMax: 10,
  batchInterval: 20,
});

const link = ApolloLink.from([authLink, httpLink]);

export const client = new ApolloClient({
  uri: NEXT_PUBLIC_GQL_ENDPOINT,
  cache: new InMemoryCache(),
  link,
  connectToDevTools: NEXT_PUBLIC_CONNECT_DEV_TOOLS,
});
