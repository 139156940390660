import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import * as React from "react";
import { textStyles } from "src/lib/theme/textStyles";

export type LinkProps = Omit<ChakraLinkProps, "fontSize" | "textStyle"> &
  Omit<NextLinkProps, "passHref"> & {
    textStyle?: keyof typeof textStyles;
  };

export const Link: React.FC<LinkProps> = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  locale,
  children,
  textStyle = "p1",
  ...props
}) => {
  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
      locale={locale}
      passHref
    >
      <ChakraLink textStyle={textStyle} {...props}>
        {children}
      </ChakraLink>
    </NextLink>
  );
};
