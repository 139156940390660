import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import * as React from "react";
import { textStyles } from "src/lib/theme/textStyles";

type InputProps = Omit<ChakraInputProps, "fontSize" | "textStyles"> & {
  fontSize?: keyof typeof textStyles;
};

export const Input: React.FC<InputProps> = ({ fontSize = "p1", ...props }) => {
  return (
    <ChakraInput
      variant="filled"
      bg="componentBg"
      color="white"
      fontSize={textStyles[fontSize].fontSize}
      rounded="xl"
      padding={6}
      {...props}
    />
  );
};
