import { Grid, Link } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Txt } from "src/components/atoms/Txt";
import { METAMASK_LOGIN_GUIDE_URL } from "src/config";

export const MetamaskBrowserDisclaimer: FC = () => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Txt textStyle="p2">{t("Login.FacebookAndGoogleLoginsAreDisabled")}</Txt>
      <Link
        textAlign="center"
        color="primaryGreen"
        href={METAMASK_LOGIN_GUIDE_URL}
      >
        {t("Login.LearnHowToLoginInHere")}
      </Link>
    </Grid>
  );
};
