import { Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Heading } from "src/components/atoms/Heading";
import { Image } from "src/components/atoms/Image";
import { Link } from "src/components/atoms/Link";
import { Txt } from "src/components/atoms/Txt";
import { NEXT_PUBLIC_PHAVER_DISCORD_URL } from "src/config";
import { useLogEvent } from "src/lib/tracking";

export const SetDispatcherSuccess: FC = () => {
  const { t } = useTranslation();
  useLogEvent({ action: "set_dispatcher_success" });

  return (
    <Stack px={8} py={4} gap={8} alignItems="center" maxW="600px" mx="auto">
      <Image
        color="white"
        src="/images/astronaut.svg"
        alt={t("Phaver")}
        width={200}
        height={200}
      />
      <Heading>{t("SetPhaverDispatcherSuccess.Title")}</Heading>
      <Txt textStyle="p1">
        {t("SetPhaverDispatcherSuccess.Description1")}
        <br />
        <br />
        {t("SetPhaverDispatcherSuccess.Description2")}{" "}
        <Link
          target="_blank"
          href={NEXT_PUBLIC_PHAVER_DISCORD_URL}
          color="lensLightgreen"
        >
          {t("Discord")}
        </Link>
      </Txt>
    </Stack>
  );
};
