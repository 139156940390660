import { Global } from "@emotion/react";

export const Fonts: React.FC = () => (
  <Global
    styles={`
      @font-face {
        font-family: Gilroy;
        font-weight: 700;
        src: url('/fonts/Gilroy-Bold.otf') format("opentype");
      }
      @font-face {
        font-family: Gilroy;
        font-weight: 500;
        src: url('/fonts/Gilroy-SemiBold.otf') format("opentype");
      }
      `}
  />
);
