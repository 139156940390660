import axios from "axios";
import { DependencyList, useEffect } from "react";
import { NEXT_PUBLIC_PHAVER_API_BASE_URL } from "src/config";
import { v4 as uuidv4 } from "uuid";
import { getIdToken } from "./firebase";
import { onError } from "./onError";
import { event as GAEvent } from "nextjs-google-analytics";

const defaultOptions = {
  baseURL: NEXT_PUBLIC_PHAVER_API_BASE_URL,
};

const axiosClient = axios.create(defaultOptions);

axiosClient.interceptors.request.use(async (config) => {
  const token = await getIdToken();
  if (token) {
    const authHeader = { Authorization: `Bearer ${token}` };
    config.headers = { ...config.headers, ...authHeader };
  }
  const requestIdHeader = { "x-request-id": uuidv4() };
  config.headers = { ...requestIdHeader, ...config.headers };
  return config;
});

const eventCategories = {
  connect_wallets: "connect",
  select_profile: "connect",
  set_dispatcher: "connect",
  set_dispatcher_success: "connect",
  verify_nft: "verify_nft",
  all_set_step: "sign_in_lens",
  connect_wallet_step: "sign_in_lens",
  initial_step: "sign_in_lens",
  select_lens_profile_step: "sign_in_lens",
  set_lens_dispatcher_step: "sign_in_lens",
  sign_in_lens_api: "sign_in_lens",
} as const;

export type EventCategoryAction = keyof typeof eventCategories;

type LogEventApiParams = {
  action: EventCategoryAction;
  payload?: Record<string, unknown>;
};

export const logEventApi = ({ action, payload }: LogEventApiParams) => {
  const category = eventCategories[action];

  axiosClient
    .post<unknown>("/tr", { action, category, payload })
    .catch(onError);
};

export const useLogEvent = (
  params: LogEventApiParams,
  deps: DependencyList = []
) => {
  useEffect(() => {
    logEventApi(params);
    GAEvent(params.action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
