import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Txt } from "src/components/atoms/Txt";
import { unawaited } from "src/lib/unawaited";
import { isMobile } from "src/utils/isMobile";
import { Chain } from "thirdweb/chains";
import { useActiveAccount, useActiveWalletChain, useSwitchActiveWalletChain } from "thirdweb/react";
import { Button } from "../atoms/Button";

type SelectedChainDisclaimerProps = {
  expectectedChainId: Chain;
}

export const SelectedChainDisclaimer: FC<SelectedChainDisclaimerProps> = ({ expectectedChainId }) => {
  const { t } = useTranslation();
  const switchChain = useSwitchActiveWalletChain()
  const chainId = useActiveWalletChain()?.id;
  const address = useActiveAccount()?.address;
  if (!chainId || !address)
    return null;

  const isRightChainId = expectectedChainId.id === chainId

  if (!isRightChainId) {
    return (
      <>
        {switchChain && (
          <Button
            onClick={() => {
              unawaited(switchChain(expectectedChainId));
            }}
          >
            {t("AccountAddressBox.SwitchNetworkButtonText")}
          </Button>
        )}
        <Txt textStyle="p2" color="red">
          {t("AccountAddressBox.SelectNetworkToContinue", {
            chainId: expectectedChainId.id,
          })}
        </Txt>
      </>
    );
  }

  // Hide as in app browsers of wallets open all pages in same view which causes auth session to disappear
  if (isMobile())
    return null;

  return null;
};
